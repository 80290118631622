/* eslint-disable @typescript-eslint/camelcase */
import Vue from "vue";
import qs from "qs";
import router from "@/router";
import store from "@/store";
import CommonServer from "@/api/common";
import wechatAuth from "@/plugins/wechatAuth";
import { getWechatAppIdFromUrl } from "@/utils";

Vue.use(wechatAuth, {
  appid: getWechatAppIdFromUrl()
});

/**
 * 处理url链接
 * @returns {string}
 */
function processUrl() {
  const url = window.location.href;
  // 解决多次登录url添加重复的code与state问题
  const urlParams = qs.parse(url.split("?")[1]);
  let redirectUrl = url;
  if (urlParams.code && urlParams.state) {
    delete urlParams.code;
    delete urlParams.state;
    delete urlParams.appid;
    const query = qs.stringify(urlParams);
    if (query.length) {
      redirectUrl = `${url.split("?")[0]}?${query}`;
    } else {
      redirectUrl = `${url.split("?")[0]}`;
    }
  }
  return redirectUrl;
}

/**
 * 处理登录
 * @param code
 * @returns {Promise<any>}
 */
function processLogin(code, state, appId, schoolId) {
  const data = {
    code,
    state,
    appId,
    schoolId
  };
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      // console.log(data)
      const loginRet = await CommonServer.login(data);
      // console.log(loginRet.meta)
      await store.dispatch("setLoginStatus", 2);
      if (loginRet.meta.needactivity == "false") {
        await store.dispatch("setApiToken", loginRet.bearerToken);
      }
      await store.dispatch("setAccessToken", loginRet.meta.accesstoken);
      await store.dispatch("setOpenId", loginRet.meta.openid);
      //await store.dispatch('setUserInfo', userInfo)

      resolve({ status: 1, data: "登录成功" });
    } catch (err) {
      reject(err);
    }
  });
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.wechatAuth)) {
    const { loginStatus } = store.state;
    switch (loginStatus) {
      case 0:
        wechatAuth.redirect_uri = processUrl();
        await store.dispatch("setLoginStatus", 1);
        window.location.href = wechatAuth.authUrl;
        break;
      case 1:
        try {
          wechatAuth.returnFromWechat(to.fullPath);
          await processLogin(
            wechatAuth.code,
            wechatAuth.state2,
            wechatAuth.appId,
            wechatAuth.schoolId
          );
          next();
        } catch (err) {
          console.log(err, "setLoginStatus 0");
          await store.dispatch("setLoginStatus", 0);
          next();
        }
        break;
      case 2:
        next();
        break;
      default:
        break;
    }
  } else {
    next();
  }
});

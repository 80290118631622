/* Options:
Date: 2022-08-04 11:16:04
Version: 6.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5000/api/educrm

//GlobalNamespace: 
MakePropertiesOptional: True
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: IReturn`1,BaseResponse,AddPublicityReq,GetPublicityListReq,FindPublicityReq,PublicityDTO,PublicityContent,PUBLICITY_CONTENT_TYPE,TeacherBaseInfoDTO,PublicityContentCSS,SchoolInfoDTO,GeoCoordinates,STUDENT_MEMBER_BUY_TYPE,CheckUrlReq,GetUploadTaskReq,UploadTaskItem,UPLOAD_TASK_STATUS,GetUploadTaskSignedUrlReq,PolicyToken,GetManQuKidNoteBySignReq,EVENT_CREATE_SOURCE,ManQuStudyFeatureDTO,ManQuEYFSTargetDTO,OBSERVE_FROM,ManQuReportTemplateGroupDTO,ManQuReportExpectationItemDTO,ManQuNoteExtraDTO,ManQuNoteBaseDTO,ManQuReportTemplateQuestionDTO,ManQuReportTemplateItemDTO,MANQU_EVALUATE_QUESION_TYPE,MANQU_NOTE_TYPE,SCHOOL_STATE,SCHOOL_LICENSE_TYPE
//ExcludeTypes: 
DefaultImports: SchoolNameDTO:./common.dtos,StudentBaseInfoDTO:./babycare.dtos,OssInfoDTO:./babycare.dtos,EventAttachDTO:./babycare.dtos
*/

import { SchoolNameDTO } from "./common.dtos";
import { StudentBaseInfoDTO } from "./babycare.dtos";
import { OssInfoDTO } from "./babycare.dtos";
import { EventAttachDTO } from "./babycare.dtos";

export interface IReturn<T>
{
    createResponse(): T;
}

export class GeoCoordinates
{
    public lng?: number;
    public lat?: number;

    public constructor(init?: Partial<GeoCoordinates>) { (Object as any).assign(this, init); }
}

export enum PUBLICITY_CONTENT_TYPE
{
    textarea = 'textarea',
    subTitle = 'subTitle',
    image = 'image',
    video = 'video',
    link = 'link',
}

export class PublicityContentCSS
{
    public index?: number;
    public left?: string;
    public center?: string;
    public right?: string;
    public color?: string;

    public constructor(init?: Partial<PublicityContentCSS>) { (Object as any).assign(this, init); }
}

export class PublicityContent
{
    public type?: PUBLICITY_CONTENT_TYPE;
    public text?: string;
    public fileurl?: string;
    public thumburl?: string;
    public css?: PublicityContentCSS;

    public constructor(init?: Partial<PublicityContent>) { (Object as any).assign(this, init); }
}

export class PublicityDTO
{
    public id?: string;
    public schoolId?: string;
    public coverUrl?: string;
    public title?: string;
    public content?: PublicityContent[];
    public backgroundImageUrl?: string;
    public backgroundColor?: string;
    public fontColor?: string;
    public titleColor?: string;
    public backgroundMusicUrl?: string;
    public createByTeacherUserInfoId?: string;
    public isDraft?: boolean;
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public deleteOn?: string;
    public createOn?: string;

    public constructor(init?: Partial<PublicityDTO>) { (Object as any).assign(this, init); }
}

export enum MANQU_NOTE_TYPE
{
    NOTE = 'NOTE',
    OBSERVE = 'OBSERVE',
    EVALUATE_FIRST = 'EVALUATE_FIRST',
    EVALUATE_SETTLING_IN = 'EVALUATE_SETTLING_IN',
    EVALUATE_SEMESTER = 'EVALUATE_SEMESTER',
    EVALUATE_YEAR = 'EVALUATE_YEAR',
    HIGHLIGHT = 'HIGHLIGHT',
}

export enum OBSERVE_FROM
{
    KID = 'KID',
    TEACHER = 'TEACHER',
}

export enum EVENT_CREATE_SOURCE
{
    DailyCareDaliy = 'DailyCareDaliy',
    DailyCareWeek = 'DailyCareWeek',
    DailyCareDaliyTeacher = 'DailyCareDaliyTeacher',
    DailyCareWeekTeacher = 'DailyCareWeekTeacher',
    ManquNote = 'ManquNote',
    ManquObserve = 'ManquObserve',
    ManquHighlight = 'ManquHighlight',
    ManquEvaluateFirst = 'ManquEvaluateFirst',
    ManquEvaluateSettlingIn = 'ManquEvaluateSettlingIn',
    ManquEvaluateSemester = 'ManquEvaluateSemester',
    ManquEvaluateYear = 'ManquEvaluateYear',
}

export enum STUDENT_MEMBER_BUY_TYPE
{
    BUY_BY_PARENT = 'BUY_BY_PARENT',
    BUY_BY_SCHOOL = 'BUY_BY_SCHOOL',
}

export enum SCHOOL_LICENSE_TYPE
{
    UNLIMIT_NUMBER_OF_STUDENTS = 'UNLIMIT_NUMBER_OF_STUDENTS',
    FIXED_NUMBER_OF_STUDENTS = 'FIXED_NUMBER_OF_STUDENTS',
    ELASTIC_NUMBER_OF_STUDENTS = 'ELASTIC_NUMBER_OF_STUDENTS',
}

export enum SCHOOL_STATE
{
    NORMAL = 0,
    SUSPAND = 1,
    EXPIRE = 2,
    TRIAL = 3,
    WAIT_APPROVE = -1,
}

export enum UPLOAD_TASK_STATUS
{
    WAIT_UPLOAD = 'WAIT_UPLOAD',
    UPLOAD_FINISH = 'UPLOAD_FINISH',
}

export class UploadTaskItem
{
    public url?: string;
    public ossId?: string;

    public constructor(init?: Partial<UploadTaskItem>) { (Object as any).assign(this, init); }
}

export class PolicyToken
{
    public accessid?: string;
    public policy?: string;
    public signature?: string;
    public dir?: string;
    public host?: string;
    public expire?: string;
    public callback?: string;
    public key?: string;

    public constructor(init?: Partial<PolicyToken>) { (Object as any).assign(this, init); }
}

export class TeacherBaseInfoDTO
{
    public id?: string;
    public fullName?: string;
    public phoneNumber?: string;
    public logoUrl?: string;
    public userProfileId?: string;

    public constructor(init?: Partial<TeacherBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class SchoolInfoDTO
{
    public id?: string;
    public name?: string;
    public eName?: string;
    public remark?: string;
    public createOn?: string;
    public masterFullName?: string;
    public masterUserName?: string;
    public masterUserId?: number;
    public logoUrl?: string;
    public schoolImageUrl?: string;
    public desc?: string;
    public schoolType?: string;
    public province?: string;
    public city?: string;
    public district?: string;
    public phoneNumber?: string;
    public address?: string;
    public location?: GeoCoordinates;
    public memberBuyType?: STUDENT_MEMBER_BUY_TYPE;
    public isEnforceMember?: boolean;
    public schoolMemberExpireOn?: string;
    public serviceModules?: string[];
    public privateServiceModules?: string[];
    public excludeServiceModules?: string[];
    public disabledServiceModules?: string[];
    public miniSiteUrl?: string;
    public licenseType?: SCHOOL_LICENSE_TYPE;
    public schoolState?: SCHOOL_STATE;
    public avaliableLicenseNumber?: number;
    public schoolKidCount?: number;

    public constructor(init?: Partial<SchoolInfoDTO>) { (Object as any).assign(this, init); }
}

export class ManQuEYFSTargetDTO
{
    public id?: string;
    public schoolId?: string;
    public title?: string;
    public eTitle?: string;
    public orderIndex?: number;
    public isDeleted?: boolean;

    public constructor(init?: Partial<ManQuEYFSTargetDTO>) { (Object as any).assign(this, init); }
}

export class ManQuStudyFeatureDTO
{
    public id?: string;
    public schoolId?: string;
    public title?: string;
    public groupName?: string;
    public orderIndex?: number;
    public isDeleted?: boolean;

    public constructor(init?: Partial<ManQuStudyFeatureDTO>) { (Object as any).assign(this, init); }
}

export class ManQuNoteBaseDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public birthDate?: string;
    public kidMonth?: number;
    public note_Type?: MANQU_NOTE_TYPE;
    public title?: string;
    public content?: string;
    public attachment?: EventAttachDTO;
    public createByTeacherUserInfoId?: string;
    public isPublished?: boolean;
    public publishedOn?: string;
    public dateOn?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public isDeleted?: boolean;
    public deletedOn?: string;

    public constructor(init?: Partial<ManQuNoteBaseDTO>) { (Object as any).assign(this, init); }
}

export enum MANQU_EVALUATE_QUESION_TYPE
{
    SINGLE = 'SINGLE',
    MULTI = 'MULTI',
    ATTACH = 'ATTACH',
    TEXT = 'TEXT',
    BOA = 'BOA',
}

export class ManQuReportTemplateItemDTO
{
    public title?: string;
    public eTitle?: string;
    public checked?: boolean;

    public constructor(init?: Partial<ManQuReportTemplateItemDTO>) { (Object as any).assign(this, init); }
}

export class ManQuReportTemplateQuestionDTO
{
    public subTitle?: string;
    public title?: string;
    public eTitle?: string;
    public isRequired?: boolean;
    public type?: MANQU_EVALUATE_QUESION_TYPE;
    public items?: ManQuReportTemplateItemDTO[];
    public content?: string;
    public attachment?: EventAttachDTO;

    public constructor(init?: Partial<ManQuReportTemplateQuestionDTO>) { (Object as any).assign(this, init); }
}

export class ManQuReportTemplateGroupDTO
{
    public title?: string;
    public eTitle?: string;
    public questions?: ManQuReportTemplateQuestionDTO[];

    public constructor(init?: Partial<ManQuReportTemplateGroupDTO>) { (Object as any).assign(this, init); }
}

export class ManQuReportExpectationItemDTO
{
    public manQuEYFSTargetId?: string;
    public title?: string;
    public eTitle?: string;
    public value?: number;

    public constructor(init?: Partial<ManQuReportExpectationItemDTO>) { (Object as any).assign(this, init); }
}

export class ManQuNoteExtraDTO extends ManQuNoteBaseDTO
{
    public observeFrom?: OBSERVE_FROM;
    public highLight?: string;
    public attachmentHighLight?: EventAttachDTO;
    public nextPlan?: string;
    public manQuEYFSTargetIds?: string[];
    public manQuStudyFeatureIds?: string[];
    public beginDate?: string;
    public eTitle?: string;
    public ageRange?: string;
    public ageTitle?: string;
    public groups?: ManQuReportTemplateGroupDTO[];
    public expectations?: ManQuReportExpectationItemDTO[];
    public attachmentClass?: EventAttachDTO;
    public memo?: string;

    public constructor(init?: Partial<ManQuNoteExtraDTO>) { super(init); (Object as any).assign(this, init); }
}

export class BaseResponse
{
    public code?: number;
    public message?: string;

    public constructor(init?: Partial<BaseResponse>) { (Object as any).assign(this, init); }
}

export class GetUploadTaskRes extends BaseResponse
{
    public maxCount?: number;
    public existsCount?: number;
    public status?: UPLOAD_TASK_STATUS;
    public data?: UploadTaskItem[];

    public constructor(init?: Partial<GetUploadTaskRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetUploadTaskSignedUrlRes extends BaseResponse
{
    public data?: PolicyToken;

    public constructor(init?: Partial<GetUploadTaskSignedUrlRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddPublicityRes extends BaseResponse
{
    public id?: string;

    public constructor(init?: Partial<AddPublicityRes>) { super(init); (Object as any).assign(this, init); }
}

export class FindPublicityRes extends BaseResponse
{
    public data?: PublicityDTO;
    public teacherInfo?: TeacherBaseInfoDTO;
    public schoolInfo?: SchoolNameDTO;

    public constructor(init?: Partial<FindPublicityRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPublicityListRes extends BaseResponse
{
    public total?: number;
    public data?: PublicityDTO[];
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };
    public schoolInfo?: SchoolInfoDTO;

    public constructor(init?: Partial<GetPublicityListRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetManQuKidNoteRes extends BaseResponse
{
    public schoolName?: string;
    public schoolIcon?: string;
    public data?: ManQuNoteExtraDTO;
    public kidInfo?: StudentBaseInfoDTO;
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public targets?: { [index: string]: ManQuEYFSTargetDTO; };
    public features?: { [index: string]: ManQuStudyFeatureDTO; };

    public constructor(init?: Partial<GetManQuKidNoteRes>) { super(init); (Object as any).assign(this, init); }
}

export class CheckUrlRes
{
    public pageUrl?: string;
    public pageTitle?: string;
    public pageIcon?: string;

    public constructor(init?: Partial<CheckUrlRes>) { (Object as any).assign(this, init); }
}

/**
* 获取上传任务信息
*/
// @Route("/qrupload/uploadtask", "GET, OPTIONS")
// @Api(Description="获取上传任务信息")
export class GetUploadTaskReq implements IReturn<GetUploadTaskRes>
{
    public schoolId?: string;
    public taskId?: string;

    public constructor(init?: Partial<GetUploadTaskReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadTaskReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetUploadTaskRes(); }
}

/**
* 获取上传任务签名url
*/
// @Route("/qrupload/uploadtaskurl", "GET, OPTIONS")
// @Api(Description="获取上传任务签名url")
export class GetUploadTaskSignedUrlReq implements IReturn<GetUploadTaskSignedUrlRes>
{
    public schoolId?: string;
    public taskId?: string;

    public constructor(init?: Partial<GetUploadTaskSignedUrlReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadTaskSignedUrlReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetUploadTaskSignedUrlRes(); }
}

/**
* 创建更新公众号文章
*/
// @Route("/school/{SchoolId}/Publicity/update", "POST, OPTIONS")
// @Api(Description="创建更新公众号文章")
export class AddPublicityReq implements IReturn<AddPublicityRes>
{
    public schoolId?: string;
    public data?: PublicityDTO;
    public isPublish?: boolean;

    public constructor(init?: Partial<AddPublicityReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddPublicityReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddPublicityRes(); }
}

/**
* 获取公众号文章
*/
// @Route("/school/{SchoolId}/Publicity/{Id}", "GET, OPTIONS")
// @Api(Description="获取公众号文章")
export class FindPublicityReq implements IReturn<FindPublicityRes>
{
    public id?: string;
    public schoolId?: string;

    public constructor(init?: Partial<FindPublicityReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FindPublicityReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FindPublicityRes(); }
}

/**
* 获取公众号文章列表
*/
// @Route("/school/{SchoolId}/Publicity/list", "GET, OPTIONS")
// @Api(Description="获取公众号文章列表")
export class GetPublicityListReq implements IReturn<GetPublicityListRes>
{
    public schoolId?: string;
    public createOn?: string[];
    public createByTeacherUserInfoId?: string;
    public title?: string;
    public isDraft?: boolean;
    public lastId?: string;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetPublicityListReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPublicityListReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPublicityListRes(); }
}

/**
* 家长获取满趣观察
*/
// @Route("/manqu/kidnote", "GET")
// @Api(Description="家长获取满趣观察")
export class GetManQuKidNoteBySignReq implements IReturn<GetManQuKidNoteRes>
{
    public type?: EVENT_CREATE_SOURCE;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="KidId 不可为空")
    public kidId?: string;

    // @Validate(Validator="NotEmpty", Message="NoteId 不可为空")
    public noteId?: string;

    // @Validate(Validator="NotEmpty", Message="Nonce 不可为空")
    public nonce?: string;

    // @Validate(Validator="NotEmpty", Message="Sign 不可为空")
    public sign?: string;

    public constructor(init?: Partial<GetManQuKidNoteBySignReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetManQuKidNoteBySignReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetManQuKidNoteRes(); }
}

// @Route("/system/checkurl", "POST, OPTIONS")
export class CheckUrlReq implements IReturn<CheckUrlRes>
{
    public url?: string;

    public constructor(init?: Partial<CheckUrlReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckUrlReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CheckUrlRes(); }
}


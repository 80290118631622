export const loginStatus = state => state.loginStatus;

export const accessToken = state => state.accessToken;

export const openId = state => state.openId;

export const userInfo = state => state.userInfo;

export const apiToken = state => state.apiToken;

export const pageAttrs = state => state.pageAttrs;

export const pageData = state => state.pageData;

export const sessionData = state => state.sessionData;
export const cacheData = state => state.cacheData;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

import store from "./store";
import wechatAuth from "./plugins/wechatAuth";
import "@/configs/interceptor";
import { optionalChaining } from "@/utils";

import vueWechatTitlePlugin from "vue-wechat-title";
// <div v-wechat-title="$route.meta.title"></div>

import VueMeta from "vue-meta";
// <script>
//   export default {
//     metaInfo: {
//       title: 'My Example App',
//       titleTemplate: '%s - Yay!',
//       htmlAttrs: {
//         lang: 'en',
//         amp: true
//       }
//     }
//   }
// </script>

// 引入全局样式
import "@/assets/css/index.scss";
// 全局引入按需引入UI库 vant
// import '@/plugins/vant'
// 移动端适配
// import 'lib-flexible/flexible.js'

Vue.config.productionTip = false;

Vue.prototype.$$ = optionalChaining;

Vue.use(vueWechatTitlePlugin);
Vue.use(VueMeta);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to?.meta?.title) {
    document.title = to.meta.title;
  } else {
    if (to?.matched?.length) {
      const [layout] = to.matched;
      if (layout?.meta?.title) {
        document.title = layout.meta.title;
      }
    }
  }
  next();
});

Vue.directive("title", {
  // 单个修改标题
  // <div v-title :data-title="$route.meta.title"></div>
  inserted: function(el) {
    if (el?.dataset?.title) {
      document.title = el.dataset.title;
    }
  }
});

Sentry.init({
  Vue,
  dsn: "https://bba4bf865157486a8eae2af8aebda3a0@sentry.yban.co/10",
  environment: process.env.NODE_ENV,
  release: process.env.VUE_APP_SENTRY_RELEASE
  // integrations: [
  //   new Integrations.BrowserTracing({
  //     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //     tracingOrigins: ["localhost", "yban.co", /^\//]
  //   })
  // ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

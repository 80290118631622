/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
import { JsonServiceClient } from "@servicestack/client";
import { isKeyExists } from "@/utils";
import { loadStorage, API_TOKEN } from "../utils/cache";

export const APPID = {
  TEACHER: 1,
  PARENT: 2
};
// const codeMessage = {
//   Unauthorized: '无效的用户名或密码',
//   ValidationException: '输入无效',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   405: '请求的方法不存在。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// };

export const apiClient = appid => {
  const client = new JsonServiceClient(
    `${process.env.VUE_APP_API_URL}api/educrm/`
  );
  client.credentials = "omit";
  const token = loadStorage(API_TOKEN);
  if (token) {
    client.bearerToken = token;
  }
  client.requestFilter = req => {
    req.headers.append("x-appid", appid);
    // console.info(req);
    // if (req.url.indexOf('GetTeacherClassesReq') > 0) {
    //   throw Object.create({
    //     responseStatus: { message: '自定义报错' }
    //   });
    // }
  };
  return client;
};

export const apiGet = async (req, appid = APPID.PARENT) => {
  const isExpired = function isExpired(milliseconds = 0) {
    const timeout = Math.abs(new Date().getTime() - this.time);
    // console.log(this, milliseconds, timeout);
    if (!this.ret && timeout > 1000) {
      return true;
    }
    if (milliseconds > 0 && milliseconds < timeout) {
      return true;
    }
    return false;
  };

  try {
    const res = await apiClient(appid).get(req);
    if (!Object.prototype.hasOwnProperty.call(res, "ret")) {
      res.ret = true;
    } else {
      res.code = res.ret ? 200 : 500;
    }
    if (!Object.prototype.hasOwnProperty.call(res, "code")) {
      res.code = 200;
    } else {
      res.ret = res.code === 200 || res.code === 0;
    }
    if (res.ret) {
      res.code = 200;
    }
    res.time = new Date().getTime();
    res.isExpired = isExpired;
    return res;
  } catch (err) {
    // console.log(err);
    let message = "发生网络异常，请稍候再试";
    if (isKeyExists(err, "responseStatus.message")) {
      message = err.responseStatus.message;
      // antdMessage.error(message)
    }
    return {
      ret: false,
      data: [],
      message,
      time: new Date().getTime(),
      isExpired
    };
  }
};

export const apiPost = async (req, appid = APPID.PARENT) => {
  try {
    const res = await apiClient(appid).post(req);
    if (!Object.prototype.hasOwnProperty.call(res, "ret")) {
      res.ret = true;
    } else {
      res.code = res.ret ? 200 : 500;
    }
    if (!Object.prototype.hasOwnProperty.call(res, "code")) {
      res.code = 200;
    } else {
      res.ret = res.code === 200 || res.code === 0;
    }
    if (res.ret) {
      res.code = 200;
    }
    res.time = new Date().getTime();
    return res;
  } catch (err) {
    // console.log(err);
    let message = "发生网络异常";
    if (isKeyExists(err, "responseStatus.errorCode")) {
      message = err.responseStatus.errorCode;
      // antdMessage.error(message);
    }
    return {
      ret: false,
      message,
      time: new Date().getTime()
    };
  }
};

export const api0Client = {
  client() {
    const client = new JsonServiceClient(process.env.VUE_APP_API_URL);

    // client.requestFilter = req => {
    //   req.headers.append('x-appid', 1);
    //   // console.info(req);
    //   // if (req.url.indexOf('GetTeacherClassesReq') > 0) {
    //   //   throw Object.create({
    //   //     responseStatus: { message: '自定义报错' }
    //   //   });
    //   // }
    // };

    return client;
  },

  *downBlob(req) {
    try {
      const res = yield apiClient.client().get(req);
      return res;
    } catch (err) {
      // console.log(err);
      // let message = '发布网络异常'
      // if (isKeyExists(err, 'responseStatus.message')) {
      //   message = err.responseStatus.message
      //   // antdMessage.error(message)
      // }
      return null;
    }
  },

  *get(req) {
    const isExpired = function isExpired(milliseconds = 0) {
      const timeout = Math.abs(new Date().getTime() - this.time);
      // console.log(this, milliseconds, timeout);
      if (!this.ret && timeout > 1000) {
        return true;
      }
      if (milliseconds > 0 && milliseconds < timeout) {
        return true;
      }
      return false;
    };

    try {
      const res = yield apiClient.client().get(req);
      const hasProperty = Object.prototype.hasOwnProperty.call(res, "ret");
      if (!hasProperty) {
        res.ret = true;
      }
      res.time = new Date().getTime();
      res.isExpired = isExpired;
      // res.isExpired = function isExpired(milliseconds = 0) {
      //   const timeout = Math.abs(new Date().getTime() - this.time);
      //   console.log(this, milliseconds, timeout);
      //   if (!this.ret && timeout > 1000) {
      //     return true;
      //   }
      //   if (milliseconds > 0 && milliseconds < timeout) {
      //     return true;
      //   }
      //   return false;
      // };

      return res;
    } catch (err) {
      // console.log(err);
      let message = "发生网络异常，请稍候再试";
      if (isKeyExists(err, "responseStatus.message")) {
        message = err.responseStatus.message;
        // antdMessage.error(message)
      }
      return {
        ret: false,
        data: [],
        message,
        time: new Date().getTime(),
        isExpired
        // isExpired(milliseconds = 0) {
        //   const timeout = Math.abs(new Date().getTime() - this.time);
        //   console.log(this, milliseconds, timeout);
        //   if (!this.ret && timeout > 1000) {
        //     return true;
        //   }
        //   if (milliseconds > 0 && milliseconds < timeout) {
        //     return true;
        //   }
        //   return false;
        // }
      };
    }
  },

  *post(req) {
    try {
      const res = yield apiClient.client().post(req);
      const hasProperty = Object.prototype.hasOwnProperty.call(res, "ret");
      if (!hasProperty) {
        res.ret = true;
      }
      res.time = new Date().getTime();
      return res;
    } catch (err) {
      // console.log(err);
      let message = "发生网络异常";
      if (isKeyExists(err, "responseStatus.message")) {
        message = err.responseStatus.message;
        // antdMessage.error(message);
      }
      return {
        ret: false,
        message,
        time: new Date().getTime()
      };
    }
  },

  *delete(req) {
    try {
      const res = yield apiClient.client().delete(req);
      const hasProperty = Object.prototype.hasOwnProperty.call(res, "ret");
      if (!hasProperty) {
        res.ret = true;
      }
      res.time = new Date().getTime();
      return res;
    } catch (err) {
      // console.log(err);
      let message = "发生网络异常";
      if (isKeyExists(err, "responseStatus.message")) {
        message = err.responseStatus.message;
        // antdMessage.error(message);
      }
      return {
        ret: false,
        message,
        time: new Date().getTime()
      };
    }
  },

  *postBody(req, formData) {
    try {
      // client.postBody(req, data);
      const res = yield apiClient.client().postBody(req, formData);
      const hasProperty = Object.prototype.hasOwnProperty.call(res, "ret");
      if (!hasProperty) {
        res.ret = true;
      }
      res.time = new Date().getTime();
      return res;
    } catch (err) {
      // console.log(err);
      let message = "发生网络异常";
      if (isKeyExists(err, "responseStatus.message")) {
        message = err.responseStatus.message;
        // antdMessage.error(message);
      }
      return {
        ret: false,
        message,
        time: new Date().getTime()
      };
    }
  }
};

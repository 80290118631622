export const SET_LOGIN_STATUS = "SET_LOGIN_STATUS";

export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

export const SET_USER_INFO = "SET_USER_INFO";

export const SET_OPEN_ID = "SET_OPEN_ID";

export const SET_API_TOKEN = "SET_API_TOKEN";

export const SET_PAGE_ATTRS = "SET_PAGE_ATTRS";

export const SET_PAGE_DATA = "SET_PAGE_DATA";

export const SET_SESSION_DATA = "SET_SESSION_DATA";
export const SET_CACHE_DATA = "SET_CACHE_DATA";

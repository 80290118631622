/* Options:
Date: 2022-05-30 10:17:56
Version: 6.02
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://apigwtest.yban.co/api/ex

//GlobalNamespace:
MakePropertiesOptional: True
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion:
//AddDescriptionAsComments: True
//IncludeTypes:
//ExcludeTypes:
//DefaultImports:
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId?: string;
}

export interface IHasBearerToken
{
    bearerToken?: string;
}

export interface IPost
{
}

export class RongCloudOnlineStatus
{
    public userid?: string;
    public status?: string;
    public os?: string;
    public time: number;
    public clientIp?: string;

    public constructor(init?: Partial<RongCloudOnlineStatus>) { (Object as any).assign(this, init); }
}

export class AttachImageItem
{
    public rotate: number;
    public imageUri?: string;
    public thumbnilUri?: string;
    public resId?: string;

    public constructor(init?: Partial<AttachImageItem>) { (Object as any).assign(this, init); }
}

export class AttachVideoItem
{
    public thumbnilUri?: string;
    public videoUri?: string;
    public resId?: string;
    public playSeconds: number;
    public aspectRatio?: string;

    public constructor(init?: Partial<AttachVideoItem>) { (Object as any).assign(this, init); }
}

export class AttachVoiceItem
{
    public resId?: string;
    public publisher?: string;
    public publishTime?: string;
    public voiceUri?: string;
    public voiceSeconds: number;
    public fileName?: string;

    public constructor(init?: Partial<AttachVoiceItem>) { (Object as any).assign(this, init); }
}

export class AttachFileItem
{
    public resId?: string;
    public fileUri?: string;
    public fileType?: string;
    public fileName?: string;

    public constructor(init?: Partial<AttachFileItem>) { (Object as any).assign(this, init); }
}

export class AttachLinkItem
{
    public linkUri?: string;
    public linkTitle?: string;
    public linkIcon?: string;

    public constructor(init?: Partial<AttachLinkItem>) { (Object as any).assign(this, init); }
}

export class AttachTingItem
{
    public albumId?: string;
    public trackId?: string;
    public albumTitle?: string;
    public albumDesc?: string;
    public albumImageUrl?: string;

    public constructor(init?: Partial<AttachTingItem>) { (Object as any).assign(this, init); }
}

export class EventAttachItem
{
    public attachImages: AttachImageItem[];
    public attachVideos: AttachVideoItem[];
    public attachVoices: AttachVoiceItem[];
    public attachFiles: AttachFileItem[];
    public attachLinks: AttachLinkItem[];
    public attachTings: AttachTingItem[];
    public attachVideo: AttachVideoItem;
    public attachVoice: AttachVoiceItem;
    public attachFile: AttachFileItem;
    public attachLink: AttachLinkItem;
    public attachTing: AttachTingItem;

    public constructor(init?: Partial<EventAttachItem>) { (Object as any).assign(this, init); }
}

export class EventItemCommentItemDTO
{
    public id?: string;
    public isAudio: boolean;
    public audioItem: AttachVoiceItem;
    public content?: string;
    public createBy?: string;
    public createByName?: string;
    public avatarUrl?: string;
    public createOn?: string;
    public createIPAddr?: string;
    public replyTo?: string;
    public replyToName?: string;

    public constructor(init?: Partial<EventItemCommentItemDTO>) { (Object as any).assign(this, init); }
}

export class EventLikeUserDTO
{
    public isTeacher: boolean;
    public userId: number;
    public userProfileId?: string;
    public showName?: string;
    public avatarUrl?: string;

    public constructor(init?: Partial<EventLikeUserDTO>) { (Object as any).assign(this, init); }
}

export enum TASKATTACHTYPE
{
    ALL = 'ALL',
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
}

export enum SEMESTERTYPE
{
    SEMESTER_PKFIRST = 'SEMESTER_PKFIRST',
    SEMESTER_PKSECOND = 'SEMESTER_PKSECOND',
    SEMESTER_K1FIRST = 'SEMESTER_K1FIRST',
    SEMESTER_K1SECOND = 'SEMESTER_K1SECOND',
    SEMESTER_K2FIRST = 'SEMESTER_K2FIRST',
    SEMESTER_K2SECOND = 'SEMESTER_K2SECOND',
    SEMESTER_K3FIRST = 'SEMESTER_K3FIRST',
    SEMESTER_K3SECOND = 'SEMESTER_K3SECOND',
    SEMESTER_OTHER = 'SEMESTER_OTHER',
    SEMESTER_UNKNOW = 'SEMESTER_UNKNOW',
}

export enum EVENT_HOMETIME_SCOPE
{
    SCOPE_ALL = 'SCOPE_ALL',
    SCOPE_FAMILY = 'SCOPE_FAMILY',
    SCOPE_FAMILY_TEACHER = 'SCOPE_FAMILY_TEACHER',
}

export class ClassEventItem
{
    public userName?: string;
    public userProfileId?: string;
    public classId?: string;
    public schoolId?: string;
    public schoolName?: string;
    public className?: string;
    public userLogoUrl?: string;
    public eventId?: string;
    public eventType?: string;
    public timeString?: string;
    public timeStamp: number;
    public createOn?: string;
    public updateOn?: string;
    public publishTime?: string;
    public title?: string;
    public content?: string;
    public attachItem: EventAttachItem;
    public targetKids: string[];
    public isPartialPublish: boolean;
    public studentTotalCount: number;
    public isTruncate: boolean;
    public imageUri?: string;
    public linkUri?: string;
    public pageView: number;
    public themeId?: string;
    public themeTitle?: string;
    public kidId?: string;
    public kidName?: string;
    public parentId?: string;
    public parentRoleName?: string;
    public comments: EventItemCommentItemDTO[];
    public likes: EventLikeUserDTO[];
    public commentType: TASKATTACHTYPE;
    public isVisited: boolean;
    public isAcked: boolean;
    public termId: number;
    public semesterType: SEMESTERTYPE;
    public extraFields: { [index: string]: Object; };
    public homeTime_Scope: EVENT_HOMETIME_SCOPE;
    public needPayment: boolean;
    public isPayed: boolean;
    public paymentCourseId?: string;
    public paymentPrice?: number;
    public showBeforeTimestamp?: number;
    public myAiOssIds: string[];

    public constructor(init?: Partial<ClassEventItem>) { (Object as any).assign(this, init); }
}

export class EventTaskItemDTO
{
    public id?: string;
    public content?: string;
    public createOn?: string;
    public createIPAddr?: string;
    public attachment: EventAttachItem;
    public likeUids: number[];
    public kidName?: string;
    public kidId?: string;
    public parentRole?: string;
    public parentId?: string;
    public kidAvatarUrl?: string;
    public commentItems: EventItemCommentItemDTO[];

    public constructor(init?: Partial<EventTaskItemDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventTaskItem extends ClassEventItem
{
    public taskItems: EventTaskItemDTO[];
    public canSeeOther: boolean;

    public constructor(init?: Partial<ClassEventTaskItem>) { super(init); (Object as any).assign(this, init); }
}

export class SchoolSiteContentItemDTO
{
    public id?: string;
    public title?: string;
    public content?: string;
    public imageUrl?: string;
    public sortIndex: number;

    public constructor(init?: Partial<SchoolSiteContentItemDTO>) { (Object as any).assign(this, init); }
}

export class SchoolSiteTitle
{
    public schoolTitle?: string;
    public teacherTitle?: string;
    public studentTitle?: string;
    public honorTitle?: string;
    public schoolEnvTitle?: string;
    public classTitle?: string;
    public activityTitle?: string;
    public newsTitle?: string;
    public footerButtonTitle?: string;

    public constructor(init?: Partial<SchoolSiteTitle>) { (Object as any).assign(this, init); }
}

export class SchoolSiteDTO
{
    public id?: string;
    public honorDesc?: string;
    public honorItems: SchoolSiteContentItemDTO[];
    public schoolEnvDesc?: string;
    public schoolEnvImageUrls: string[];
    public classItems: SchoolSiteContentItemDTO[];
    public teacherDesc?: string;
    public title: SchoolSiteTitle;
    public teacherItems: SchoolSiteContentItemDTO[];
    public studentItems: SchoolSiteContentItemDTO[];
    public newsItems: SchoolSiteContentItemDTO[];
    public activityItems: SchoolSiteContentItemDTO[];

    public constructor(init?: Partial<SchoolSiteDTO>) { (Object as any).assign(this, init); }
}

export class TongduibaCreateOrderResData
{
    public bizId?: string;

    public constructor(init?: Partial<TongduibaCreateOrderResData>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointData
{
    public unionId?: string;
    public pointsAmount: number;

    public constructor(init?: Partial<TongduibaQueryPointData>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointLogDataItem
{
    public unionId?: string;
    public pointsAmount: number;
    public subject?: string;
    public body?: string;
    public createdAt?: string;
    public transferType: number;
    public id?: string;

    public constructor(init?: Partial<TongduibaQueryPointLogDataItem>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointLogData
{
    public list: TongduibaQueryPointLogDataItem[];
    public totalPage: number;
    public page: number;
    public pageSize: number;
    public totalCount: number;

    public constructor(init?: Partial<TongduibaQueryPointLogData>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public fieldName?: string;

    // @DataMember(Order=3)
    public message?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public message?: string;

    // @DataMember(Order=3)
    public stackTrace?: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class BaseResponse
{
    public code: number;
    public message?: string;

    public constructor(init?: Partial<BaseResponse>) { (Object as any).assign(this, init); }
}

export class ClassEventShareRes
{
    public code: number;
    public message?: string;
    public qrUrl?: string;
    public data: ClassEventItem;

    public constructor(init?: Partial<ClassEventShareRes>) { (Object as any).assign(this, init); }
}

export class ClassEventTaskItemRes
{
    public code: number;
    public message?: string;
    public url?: string;
    public qrUrl?: string;
    public data: ClassEventTaskItem;

    public constructor(init?: Partial<ClassEventTaskItemRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolMiniSiteRes extends BaseResponse
{
    public data: SchoolSiteDTO;

    public constructor(init?: Partial<GetSchoolMiniSiteRes>) { super(init); (Object as any).assign(this, init); }
}

export class TongduibaCreateOrderRes
{
    public code: number;
    public msg?: string;
    public data: TongduibaCreateOrderResData;

    public constructor(init?: Partial<TongduibaCreateOrderRes>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointRes
{
    public code: number;
    public msg?: string;
    public data: TongduibaQueryPointData;

    public constructor(init?: Partial<TongduibaQueryPointRes>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointLogRes
{
    public code: number;
    public msg?: string;
    public data: TongduibaQueryPointLogData;

    public constructor(init?: Partial<TongduibaQueryPointLogRes>) { (Object as any).assign(this, init); }
}

export class YouzanNotifyRes
{
    public code: number;
    public msg?: string;

    public constructor(init?: Partial<YouzanNotifyRes>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId?: string;

    // @DataMember(Order=2)
    public sessionId?: string;

    // @DataMember(Order=3)
    public userName?: string;

    // @DataMember(Order=4)
    public displayName?: string;

    // @DataMember(Order=5)
    public referrerUrl?: string;

    // @DataMember(Order=6)
    public bearerToken?: string;

    // @DataMember(Order=7)
    public refreshToken?: string;

    // @DataMember(Order=8)
    public profileUrl?: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

/**
* 美洽推送通知
*/
// @Route("/meiqia/push/{app}", "POST, OPTIONS")
// @Api(Description="美洽推送通知")
export class MeiQiaPushReq
{
    public app?: string;

    public constructor(init?: Partial<MeiQiaPushReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MeiQiaPushReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 融云在线状态
*/
// @Route("/notify/rongcloud/onlinestatus", "POST")
export class RongCloudOnlineStatusReq extends Array<RongCloudOnlineStatus> implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<RongCloudOnlineStatusReq>) { super(); (Object as any).assign(this, init); }
    public getTypeName() { return 'RongCloudOnlineStatusReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class RongCloudMsgRouteQueue
{
    public fromUserId?: string;
    public toUserId?: string;
    public objectName?: string;
    public content?: string;
    public channelType?: string;
    public msgTimestamp?: string;
    public msgUID?: string;
    public sensitiveType: number;
    public source?: string;
    public groupUserIds: string[];

    public constructor(init?: Partial<RongCloudMsgRouteQueue>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RongCloudMsgRouteQueue'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 融云消息路由
*/
// @Route("/notify/rongcloud/recvmsg", "POST")
// @Api(Description="融云消息路由")
export class RongCloudMsgRouteReq implements IReturn<BaseResponse>
{
    public nonce?: string;
    public signTimestamp?: string;
    public signature?: string;
    public fromUserId?: string;
    public toUserId?: string;
    public objectName?: string;
    public content?: string;
    public channelType?: string;
    public msgTimestamp?: string;
    public msgUID?: string;
    public sensitiveType: number;
    public source?: string;
    public groupUserIds: string[];

    public constructor(init?: Partial<RongCloudMsgRouteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RongCloudMsgRouteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取用户分享的数据
*/
// @Route("/event/sharedata/{EventId}/{KidId}", "GET, OPTIONS")
// @Api(Description="获取用户分享的数据")
export class GetKidEventShareDataReq implements IReturn<ClassEventShareRes>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * ItemId
    */
    // @ApiMember(DataType="string", Description="ItemId", Name="ItemId", ParameterType="query")
    public itemId?: string;

    /**
    * sign
    */
    // @ApiMember(DataType="string", Description="sign", IsRequired=true, Name="sign", ParameterType="query")
    public sign?: string;

    /**
    * nonce
    */
    // @ApiMember(DataType="string", Description="nonce", IsRequired=true, Name="nonce", ParameterType="query")
    public nonce?: string;

    public constructor(init?: Partial<GetKidEventShareDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidEventShareDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClassEventShareRes(); }
}

/**
* 获取用户分享的成长任务数据
*/
// @Route("/eventtask/sharedata/{TaskId}/{TaskItemId}", "GET, OPTIONS")
// @Api(Description="获取用户分享的成长任务数据")
export class GetKidEventTaskShareDataReq implements IReturn<ClassEventTaskItemRes>
{
    /**
    * TaskId
    */
    // @ApiMember(DataType="string", Description="TaskId", IsRequired=true, Name="TaskId", ParameterType="path")
    public taskId?: string;

    /**
    * TaskItemId
    */
    // @ApiMember(DataType="string", Description="TaskItemId", IsRequired=true, Name="TaskItemId", ParameterType="path")
    public taskItemId?: string;

    /**
    * sign
    */
    // @ApiMember(DataType="string", Description="sign", IsRequired=true, Name="sign", ParameterType="query")
    public sign?: string;

    /**
    * nonce
    */
    // @ApiMember(DataType="string", Description="nonce", IsRequired=true, Name="nonce", ParameterType="query")
    public nonce?: string;

    public constructor(init?: Partial<GetKidEventTaskShareDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidEventTaskShareDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClassEventTaskItemRes(); }
}

/**
* 获得学校微官网数据
*/
// @Route("/minisite/{SchoolId}", "GET, OPTIONS")
// @Api(Description="获得学校微官网数据")
export class GetSchoolMiniSiteReq implements IReturn<GetSchoolMiniSiteRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolMiniSiteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolMiniSiteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolMiniSiteRes(); }
}

/**
* 通兑吧加积分
*/
// @Route("/tongduiba/addpoint", "GET, OPTIONS")
// @Api(Description="通兑吧加积分")
export class TongduibaAddPointReq implements IReturn<TongduibaCreateOrderRes>
{
    public appKey?: string;
    public timestamp: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;
    public pointsAmount: number;
    public body?: string;
    public subject?: string;
    public orderId?: string;

    public constructor(init?: Partial<TongduibaAddPointReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaAddPointReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TongduibaCreateOrderRes(); }
}

/**
* 通兑吧下单
*/
// @Route("/tongduiba/createorder", "GET, OPTIONS")
// @Api(Description="通兑吧下单")
export class TongduibaCreateOrderReq implements IReturn<TongduibaCreateOrderRes>
{
    public appKey?: string;
    public timestamp: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;
    public pointsAmount: number;
    public body?: string;
    public subject?: string;
    public orderId?: string;

    public constructor(init?: Partial<TongduibaCreateOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaCreateOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TongduibaCreateOrderRes(); }
}

/**
* 通兑吧下单结果通知
*/
// @Route("/tongduiba/notifyorder", "GET, OPTIONS")
// @Api(Description="通兑吧下单结果通知")
export class TongduibaNotifyOrderReq
{
    public appKey?: string;
    public timestamp: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;
    public status: number;
    public bizId?: string;
    public errorMessage?: string;
    public orderId?: string;

    public constructor(init?: Partial<TongduibaNotifyOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaNotifyOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 通兑吧查用户积分
*/
// @Route("/tongduiba/querypoint", "GET, OPTIONS")
// @Api(Description="通兑吧查用户积分")
export class TongduibaQueryPointReq implements IReturn<TongduibaQueryPointRes>
{
    public appKey?: string;
    public timestamp: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;

    public constructor(init?: Partial<TongduibaQueryPointReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaQueryPointReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TongduibaQueryPointRes(); }
}

/**
* 通兑吧查用户积分记录
*/
// @Route("/tongduiba/querypointlog", "GET, OPTIONS")
// @Api(Description="通兑吧查用户积分记录")
export class TongduibaQueryPointLogReq implements IReturn<TongduibaQueryPointLogRes>
{
    public appKey?: string;
    public timestamp: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;
    public page: number;
    public pageSize: number;

    public constructor(init?: Partial<TongduibaQueryPointLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaQueryPointLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TongduibaQueryPointLogRes(); }
}

/**
* 有赞通知
*/
// @Route("/notify/youzan", "POST")
// @Api(Description="有赞通知")
export class YouzanNotifyReq implements IReturn<YouzanNotifyRes>
{

    public constructor(init?: Partial<YouzanNotifyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'YouzanNotifyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new YouzanNotifyRes(); }
}

/**
* Sign In
*/
// @Route("/auth")
// @Route("/auth/{provider}")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="AuthProvider, e.g. credentials")
    public provider?: string;

    // @DataMember(Order=2)
    public state?: string;

    // @DataMember(Order=3)
    public oauth_token?: string;

    // @DataMember(Order=4)
    public oauth_verifier?: string;

    // @DataMember(Order=5)
    public userName?: string;

    // @DataMember(Order=6)
    public password?: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView?: string;

    // @DataMember(Order=10)
    public nonce?: string;

    // @DataMember(Order=11)
    public uri?: string;

    // @DataMember(Order=12)
    public response?: string;

    // @DataMember(Order=13)
    public qop?: string;

    // @DataMember(Order=14)
    public nc?: string;

    // @DataMember(Order=15)
    public cnonce?: string;

    // @DataMember(Order=17)
    public accessToken?: string;

    // @DataMember(Order=18)
    public accessTokenSecret?: string;

    // @DataMember(Order=19)
    public scope?: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName?: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName?: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}


/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return timeStr;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, " ") +
      '"}'
  );
}

export const isKeyExists = (object, path) => {
  const pathList = path.split(".");
  let obj = object;

  if (!obj) {
    return false;
  }

  for (let i = 0; i < pathList.length; i += 1) {
    const key = pathList[i];
    const hasProperty = Object.prototype.hasOwnProperty.call(obj, key);
    if (!hasProperty) {
      return false;
    }
    obj = obj[key];
    if (obj === undefined || obj === null) return false;
  }

  return true;
};

/**
 * 生成uuid
 * 由於加上 Performance.now() (亞毫秒級的時間戳記)，很難產生 collision。主流瀏覽器皆支援，但 IE 只支援 10 以上或 Edge。
 */
export const uuid = () => {
  let d = Date.now();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); // use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    // eslint-disable-next-line no-bitwise
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const getWechatAppIdFromUrl = () => {
  const hostName = window.location.host;
  let wxAppid = hostName.split(".")[0];
  if (wxAppid.length <= 10) {
    wxAppid = process.env.VUE_APP_WX_AUTHORIZER_APPID;
  }

  return wxAppid;
};

/**
 * 解决Vue Template模板中无法使用可选链的问题
 * @param obj
 * @param rest
 * @returns {*}
 */
export const optionalChaining = (obj, ...rest) => {
  let tmp = obj;
  let keys = [];
  for (const key in rest) {
    if (typeof rest[key] === "string") {
      keys = keys.concat(rest[key].split("."));
    } else {
      keys.push(rest[key]);
    }
  }
  for (const key in keys) {
    const name = keys[key];
    tmp = tmp?.[name];
  }
  return tmp;
};

export const wxGetUserInfo = async () => {
  if (!window.checkLoginRes)
    throw new Error("获取登录信息失败，请确认授权方式以及是否保存了登录信息");
  try {
    if (window.checkLoginRes.cloudID) {
      const res = await window.instance.callFunction({
        name: "getToken",
        data: {
          userInfoData: new window.instance.CloudID(
            window.checkLoginRes.cloudID
          )
        }
      });
      //   console.log(`getUserInfo`, res)
      return res;
    } else {
      throw new Error("找不到 cloudID，请确认网页授权方式为 snsnapi_userinfo");
    }
  } catch (e) {
    console.error(`error: ${e} ${e.stack}`);
  }
};

/*
export const wxDoLogin = async ({
  WX_OFFICIAL_ACCOUNT_APPID,
  WX_RESOURCE_APPID,
  WX_RESOURCE_ENVID,
  WX_AUTH_TYPE,
}) => {
  try {
    const checkLoginOptions = {
      provider: "OfficialAccount",
      appid: WX_OFFICIAL_ACCOUNT_APPID,
    };

    const urlSearch = new URLSearchParams(location.search);
    const accessToken = urlSearch.get("access_token");
    const refreshToken = urlSearch.get("refresh_token");
    // console.log("accessToken:" + accessToken);
    // console.log("refreshToken:" + refreshToken);
    if (urlSearch.get("oauthredirect") === "1") {
      // console.log("oauthredirect EQUAL 1");
      checkLoginOptions.accessToken = accessToken;
      checkLoginOptions.refreshToken = refreshToken;
    }

    // console.log(`checkLogin options: `, checkLoginOptions)
    const result = await window.wx.cloud.checkLogin(checkLoginOptions);
    // console.log(`checkLogin result: `, result)
    window.checkLoginRes = result;
    // console.log("checklogin ret", result);
    if (result.errCode === 0 && result.loggedIn) {
      // console.log(`checkLogin success`)

      const instance = (window.instance = new window.wx.cloud.Cloud({
        appid: WX_OFFICIAL_ACCOUNT_APPID,
        resourceAppid: WX_RESOURCE_APPID,
        resourceEnv: WX_RESOURCE_ENVID,
      }));

      await instance.init();
      // const initResult = await instance.init()
      //   console.log(`instance inited`, initResult)
      //   console.log(`can use cloud instance to access resource now !`)

      const res = await wxGetUserInfo();
      if (res && res.result) {
        // 保存登陆 token
        window.doLoginRes = res;
        saveWeiXinUserInfo(res);
        const { token } = res.result;
        saveApiToken(token);
      }
      return res;

      //   const els = [...document.getElementsByClassName('display-none')]
      //   els.forEach((el) => el.classList.remove('display-none'))
    } else {
      //   console.log(
      //     `checkLogin with sdk errCode ${result.errCode} errMsg ${result.errMsg}, will start oauth in 10s`
      //   )
      setTimeout(() => {
        try {
          const { origin, pathname, search, hash } = location;
          const searchParams = new URLSearchParams(search);
          searchParams.delete("access_token");
          searchParams.delete("refresh_token");
          const redirectUri = `${origin}${pathname}?${searchParams.toString()}${hash}`;
          window.wx.cloud.startLogin({
            provider: "OfficialAccount",
            appid: WX_OFFICIAL_ACCOUNT_APPID,
            scope: WX_AUTH_TYPE,
            redirectUri,
          });
        } catch (e) {
          console.error(`startLogin fail: ${e}`);
          // console.warn(`will start OfficialAccount OAuth login in 10s.`)
        }
      }, 200);
    }
  } catch (e) {
    console.error(e);
  }
};

*/

export const checkMiniProgram = () => {
  return new Promise(resolve => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == "micromessenger") {
      //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
      window.wx.miniProgram.getEnv(res => {
        resolve(res);
        // resolve({ miniprogram: true });
      });
    } else {
      resolve();
    }
  });
};

export const checkAlipay = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isAlipay = ua.match(/alipay/i) == "alipay";
  return isAlipay;
};

export const checkWeiXin = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isWXWork = ua.match(/wxwork/i) == "wxwork";
  const isWeixin = !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
  let isMobile = false;
  let isDesktop = false;
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i
    )
  ) {
    isMobile = true;
  } else {
    isDesktop = true;
  }
  return {
    isWeixin,
    isMobile,
    isDesktop
  };
};

export const wxShare = async ({
  WX_OFFICIAL_ACCOUNT_APPID,
  WX_RESOURCE_APPID,
  WX_RESOURCE_ENVID,
  title,
  desc,
  link,
  imgUrl,
  success
}) => {
  if (!checkWeiXin().isWeixin) {
    return;
  }

  try {
    const checkLoginOptions = {
      provider: "OfficialAccount",
      appid: WX_OFFICIAL_ACCOUNT_APPID
    };

    const urlSearch = new URLSearchParams(location.search);
    const accessToken = urlSearch.get("access_token");
    const refreshToken = urlSearch.get("refresh_token");
    // console.log("accessToken:" + accessToken);
    // console.log("refreshToken:" + refreshToken);
    if (urlSearch.get("oauthredirect") === "1") {
      // console.log("oauthredirect EQUAL 1");
      checkLoginOptions.accessToken = accessToken;
      checkLoginOptions.refreshToken = refreshToken;
    }

    // console.log(`checkLogin options: `, checkLoginOptions)
    const result = await window.wx.cloud.checkLogin(checkLoginOptions);
    // console.log(`checkLogin result: `, result)
    window.checkLoginRes = result;

    if (result.errCode === 0 && result.loggedIn) {
      const instance = (window.instance = new window.wx.cloud.Cloud({
        appid: WX_OFFICIAL_ACCOUNT_APPID,
        resourceAppid: WX_RESOURCE_APPID,
        resourceEnv: WX_RESOURCE_ENVID
      }));

      const initResult = await instance.init();
      console.log(`instance inited`, initResult);
      console.log(`can use cloud instance to access resource now !`);
      // const res = await wxGetUserInfo();
      //   if (res && res.result) {
      //     // 保存登陆 token
      //     window.doLoginRes = res;
      //     saveWeiXinUserInfo(res);
      //     const { token } = res.result;
      //     saveApiToken(token);
      //   }

      // const instance = window.instance;
      // await instance.init();

      const res = await instance.getJSSDKSignature({
        url: link
      });

      window.wx.config({
        // debug: true, // 调试时可开启
        appId: WX_OFFICIAL_ACCOUNT_APPID, // 必填，公众号的唯一标识
        timestamp: res.timestamp + "", // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature: res.signature, // 必填，签名
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"] // 必填，需要使用的JS接口列表
      });

      window.wx.ready(() => {
        // 分享给好友
        window.wx.updateAppMessageShareData({
          title: title, // 分享标题
          desc: desc, // 分享描述
          link: link, // 分享链接
          imgUrl: imgUrl, // 分享图标
          success: e => {
            if (typeof success === "function") {
              success(e);
            }
          },
          cancel: () => {
            console.log("cancel");
          }
        });

        // 分享到朋友圈
        window.wx.updateTimelineShareData({
          title: title, // 分享标题
          link: link, // 分享链接
          imgUrl: imgUrl, // 分享图标
          success: e => {
            if (typeof success === "function") {
              success(e);
            }
          },
          cancel: () => {
            console.log("cancel");
          }
        });
      });
    } else {
      console.log(
        `checkLogin with sdk errCode ${result.errCode} errMsg ${result.errMsg}, will start oauth in 10s`
      );
      setTimeout(() => {
        try {
          const { origin, pathname, search, hash } = location;
          const searchParams = new URLSearchParams(search);
          searchParams.delete("access_token");
          searchParams.delete("refresh_token");
          const redirectUri = `${origin}${pathname}?${searchParams.toString()}${hash}`;
          console.log("redirect uri:" + redirectUri);
          window.wx.cloud.startLogin({
            provider: "OfficialAccount",
            appid: WX_OFFICIAL_ACCOUNT_APPID,
            scope: "snsapi_base",
            redirectUri
          });
        } catch (e) {
          console.error(`startLogin fail: ${e}`);
          // console.warn(`will start OfficialAccount OAuth login in 10s.`)
        }
      }, 200);
    }
  } catch (error) {
    console.log("wx cloud error");
    console.log(error);
    return;
  }
};

// 获取光标位置
export const getCursortPosition = textDom => {
  let cursorPos = 0;
  if (document.selection) {
    // IE Support
    textDom.focus();
    const selectRange = document.selection.createRange();
    selectRange.moveStart("character", -textDom.value.length);
    cursorPos = selectRange.text.length;
  } else if (textDom.selectionStart || textDom.selectionStart == "0") {
    // Firefox support
    cursorPos = textDom.selectionStart;
  }
  return cursorPos;
};

// 设置 光标选中
export const setSelectionRange = (textDom, selectionStart, selectionEnd) => {
  if (textDom.setSelectionRange) {
    textDom.setSelectionRange(selectionStart, selectionEnd);
  } else if (textDom.createTextRange) {
    const range = textDom.createTextRange();
    range.collapse(true);
    range.moveEnd("character", selectionEnd);
    range.moveStart("character", selectionStart);
    range.select();
  }
  textDom.focus({ preventScroll: false });
};

/* Options:
Date: 2023-02-22 11:28:38
Version: 6.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5000/api/educrm

//GlobalNamespace: 
MakePropertiesOptional: True
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: IReturn`1,BaseResponse,CashOrderDTO,HUIFU_PAY_STATE,CASHORDER_VENDOR,CASHORDER_SOURCE,GetCashOrderReq,HuifuCreateCashOrderPayReq,GetSchoolInfoByHuifuDeviceIdReq,HuifuCreateJsPayReq,HuifuJsPayPayload,GetLiveClassRoomShareDataReq,LiveClassRoomDTO,GetPageViewCountReq,GetCourseConsumLogsForParentReq,GetKidCourseBalanceReq,GetClassTimesForParentReq,GetWechatComponentAppIdBySchoolReq,WechatComponentBindReq,GetMarketingPagesReq,MarketingPageDTO,ActivityProdectBaseDTO,MarketingPageTemplateDTO,MarketingFeedExtraItem,MARKETING_FEEDBACK_ITEMTYPE,WeiXinShareConfig,SpreaderConfig,MARKETING_ACTIVITY_TYPE,SPREADER_PRIVATE_GIFT_TYPE,ClassTimeDTO,ClassBaseInfoDTO,ClassTimeSignInfoDTO,ClassTimeState,ClassTimeSignState,ClassTimeStudentType,KidCourseBalanceItem,CourseSaleItemType,CourseConsumLogDTO,ClassTimeSignInfoKidDTO,COURSE_LOG_SOURCE,EventAttachDTO,LIVECLASSROOMSTATUS,EVENTPUBLISHSTATE,GetClassTimesCanAppointmentReq,AppointmentClassTimeReq,AppointmentCancelReq,AddSchoolApplicantReq,FindSchoolApplicantReq,SchoolApplicantDTO,SCHOOL_STATE,SCHOOL_TYPE,ClassTimeTeacherInfoMapDTO,TEACHER_CLASS_ROLE,AppointmentLogDTO,AppointmentState,WechatComponentUnBindReq
//ExcludeTypes: 
DefaultImports: TeacherBaseInfoDTO:./common.dtos,EventAttachImageItemDTO:./common.dtos,EventAttachVideoItemDTO:./common.dtos,EventAttachAudioItemDTO:./common.dtos,EventAttachFileItemDTO:./common.dtos,EventAttachLinkItemDTO:./common.dtos,EventAttachTingItemDTO:./common.dtos,OssInfoDTO:./babycare.dtos,CommonRequest:./common.dtos
*/

import { TeacherBaseInfoDTO } from "./common.dtos";
import { EventAttachImageItemDTO } from "./common.dtos";
import { EventAttachVideoItemDTO } from "./common.dtos";
import { EventAttachAudioItemDTO } from "./common.dtos";
import { EventAttachFileItemDTO } from "./common.dtos";
import { EventAttachLinkItemDTO } from "./common.dtos";
import { EventAttachTingItemDTO } from "./common.dtos";
import { OssInfoDTO } from "./babycare.dtos";
import { CommonRequest } from "./common.dtos";

export interface IReturn<T>
{
    createResponse(): T;
}

export enum TEACHER_CLASS_ROLE
{
    MASTER_TEACHER = 'MASTER_TEACHER',
    ASSISTANT_TEACHER = 'ASSISTANT_TEACHER',
    CARE_TEACHER = 'CARE_TEACHER',
    OTHER_TEACHER = 'OTHER_TEACHER',
}

export enum AppointmentState
{
    SUCCESS = 'SUCCESS',
    IN_LINE = 'IN_LINE',
    CANCELED = 'CANCELED',
}

export enum ClassTimeState
{
    UNSIGN = 'UNSIGN',
    SIGNED = 'SIGNED',
    CANCELLED = 'CANCELLED',
}

export enum ClassTimeSignState
{
    UNSIGN = 'UNSIGN',
    OK = 'OK',
    LATE = 'LATE',
    LEAVE = 'LEAVE',
    ABSENCE = 'ABSENCE',
}

export enum ClassTimeStudentType
{
    FIXED = 'FIXED',
    TEMPORARY = 'TEMPORARY',
    DEMO = 'DEMO',
}

export class ClassTimeSignInfoDTO
{
    public kidId?: string;
    public state?: ClassTimeSignState;
    public usedCourseHour?: number;
    public studentType?: ClassTimeStudentType;
    public remark?: string;
    public isAppointment?: boolean;
    public isMakeUp?: boolean;
    public makeUpClassTimeId?: string;
    public orgStudentCourseId?: string;

    public constructor(init?: Partial<ClassTimeSignInfoDTO>) { (Object as any).assign(this, init); }
}

export class ClassTimeTeacherInfoMapDTO
{
    public id?: string;
    public classTimeId?: string;
    public schoolId?: string;
    public branchId?: string;
    public classId?: string;
    public userId?: number;
    public teacherUserInfoId?: string;
    public roleType?: TEACHER_CLASS_ROLE;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<ClassTimeTeacherInfoMapDTO>) { (Object as any).assign(this, init); }
}

export enum COURSE_LOG_SOURCE
{
    SIGN = 'SIGN',
    MODIFY_SIGN = 'MODIFY_SIGN',
    VOID = 'VOID',
    BUYCONTENT = 'BUYCONTENT',
    SYSTEM_SIGN = 'SYSTEM_SIGN',
    REVERSAL = 'REVERSAL',
    MODIFY = 'MODIFY',
    REFUND = 'REFUND',
    TRANSFEROUT = 'TRANSFEROUT',
}

export class EventAttachDTO
{
    public imageItems?: EventAttachImageItemDTO[];
    public videoItems?: EventAttachVideoItemDTO[];
    public audioItems?: EventAttachAudioItemDTO[];
    public fileItems?: EventAttachFileItemDTO[];
    public linkItems?: EventAttachLinkItemDTO[];
    public tingItems?: EventAttachTingItemDTO[];
    public videoItem?: EventAttachVideoItemDTO;
    public audioItem?: EventAttachAudioItemDTO;
    public fileItem?: EventAttachFileItemDTO;
    public linkItem?: EventAttachLinkItemDTO;
    public tingItem?: EventAttachTingItemDTO;

    public constructor(init?: Partial<EventAttachDTO>) { (Object as any).assign(this, init); }
}

export enum CourseSaleItemType
{
    SIGN = 'SIGN',
    DAY = 'DAY',
    MONTH = 'MONTH',
    MONTHDAY = 'MONTHDAY',
}

export enum LIVECLASSROOMSTATUS
{
    WAIT_SCHEDULE = 'WAIT_SCHEDULE',
    OPENING = 'OPENING',
    CLOSED = 'CLOSED',
    CANCELED = 'CANCELED',
}

export enum EVENTPUBLISHSTATE
{
    STATE_ALLSEE = 'STATE_ALLSEE',
    STATE_INIT = 'STATE_INIT',
    STATE_OWNERSEE = 'STATE_OWNERSEE',
    STATE_WAITAUDIT = 'STATE_WAITAUDIT',
    STATE_DELETE = 'STATE_DELETE',
}

export enum SCHOOL_TYPE
{
    KINDERGARTEN = 'KINDERGARTEN',
    EDUAFFAIR = 'EDUAFFAIR',
    SCHOOL = 'SCHOOL',
    ALL = 'ALL',
}

// @DataContract
export enum SCHOOL_STATE
{
    NORMAL = 0,
    SUSPAND = 1,
    EXPIRE = 2,
    TRIAL = 3,
    WAIT_APPROVE = -1,
}

export class SchoolApplicantDTO
{
    public id?: string;
    public name?: string;
    public eName?: string;
    public remark?: string;
    public desc?: string;
    public province?: string;
    public city?: string;
    public district?: string;
    public address?: string;
    public phoneNumber?: string;
    public masterPhoneNumber?: string;
    public masterFullName?: string;
    public logoOssId?: string;
    public schoolImageOssId?: string;
    public source?: string[];
    public schoolState?: SCHOOL_STATE;
    public schoolType?: SCHOOL_TYPE;
    public createIPAddress?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<SchoolApplicantDTO>) { (Object as any).assign(this, init); }
}

export class WeiXinShareConfig
{
    public coverImage?: string;
    public title?: string;
    public description?: string;
    public shareWx?: boolean;

    public constructor(init?: Partial<WeiXinShareConfig>) { (Object as any).assign(this, init); }
}

export enum SPREADER_PRIVATE_GIFT_TYPE
{
    POINT = 'POINT',
    CARD = 'CARD',
}

export class SpreaderConfig
{
    public giftType?: SPREADER_PRIVATE_GIFT_TYPE;
    public amount?: number;
    public expiredDays?: number;
    public minPrice?: number;

    public constructor(init?: Partial<SpreaderConfig>) { (Object as any).assign(this, init); }
}

export enum MARKETING_ACTIVITY_TYPE
{
    NONE = 'NONE',
    ACTIVITY = 'ACTIVITY',
    PINTUAN = 'PINTUAN',
    KANJIA = 'KANJIA',
    QIANGGOU = 'QIANGGOU',
}

export class MarketingPageTemplateDTO
{
    public id?: string;
    public title?: string;
    public coverImage?: string;
    public description?: string;
    public script?: string;
    public author?: string;
    public width?: number;
    public height?: number;
    public pageMode?: string;
    public pages?: string;
    public config?: string;
    public flipType?: number;
    public sliderNumber?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public status?: number;
    public isPublish?: boolean;
    public isDelete?: boolean;
    public tags?: string[];
    public shareConfig?: WeiXinShareConfig;
    public spreaderConfig?: SpreaderConfig;
    public activityType?: MARKETING_ACTIVITY_TYPE;
    public orderIndex?: number;

    public constructor(init?: Partial<MarketingPageTemplateDTO>) { (Object as any).assign(this, init); }
}

export enum MARKETING_FEEDBACK_ITEMTYPE
{
    INPUT = 'INPUT',
    INPUTNUMBER = 'INPUTNUMBER',
    PHONENUMBER = 'PHONENUMBER',
    TEXT = 'TEXT',
    CHECKBOX = 'CHECKBOX',
    DROPDOWN = 'DROPDOWN',
    DATEPICK = 'DATEPICK',
}

export class MarketingFeedExtraItem
{
    public itemType?: MARKETING_FEEDBACK_ITEMTYPE;
    public title?: string;
    public value?: string;
    public options?: string[];

    public constructor(init?: Partial<MarketingFeedExtraItem>) { (Object as any).assign(this, init); }
}

export class MarketingPageDTO extends MarketingPageTemplateDTO
{
    public schoolId?: string;
    public templateId?: string;
    public beginAt?: string;
    public endAt?: string;
    public needPayment?: boolean;
    public fee?: number;
    public formStudentNameEnabled?: boolean;
    public formStudentBirthEnabled?: boolean;
    public formPhoneNumberEnabled?: boolean;
    public formGenderEnabled?: boolean;
    public formParentRoleEnabled?: boolean;
    public formParentNameEnabled?: boolean;
    public formContactAddressEnabled?: boolean;
    public formXiaoquNameEnabled?: boolean;
    public formSchoolNameEnabled?: boolean;
    public formIsMemberEnabled?: boolean;
    public formExtraDatas?: MarketingFeedExtraItem[];
    public virtualVisitorAmount?: number;
    public virtualOrdersAmount?: number;
    public virtualShareAmount?: number;

    public constructor(init?: Partial<MarketingPageDTO>) { super(init); (Object as any).assign(this, init); }
}

export enum CASHORDER_SOURCE
{
    COURSE_ORDER = 'COURSE_ORDER',
    STUDENT_SCAN = 'STUDENT_SCAN',
    TEACHER_SCAN = 'TEACHER_SCAN',
    TP_SCAN = 'TP_SCAN',
}

export enum CASHORDER_VENDOR
{
    HUIFU = 'HUIFU',
}

export enum HUIFU_PAY_STATE
{
    WAIT_PAY = 'WAIT_PAY',
    PAID_PROCESSING = 'PAID_PROCESSING',
    PAID_SUCCESS = 'PAID_SUCCESS',
    PAID_FAIL = 'PAID_FAIL',
}

export class ClassBaseInfoDTO
{
    public id?: string;
    public branchId?: string;
    public className?: string;
    public alias?: string;
    public colorId?: number;
    public kidCount?: number;
    public courseId?: string;
    public courseHour?: number;
    public capacity?: number;
    public mustCapacity?: boolean;

    public constructor(init?: Partial<ClassBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class ClassTimeDTO
{
    public id?: string;
    public scheduleId?: string;
    public schoolId?: string;
    public classId?: string;
    public courseSubject?: string[];
    public beginTime?: string;
    public endTime?: string;
    public startMinute?: number;
    public endMinute?: number;
    public index?: number;
    public signTime?: string;
    public signInfos?: ClassTimeSignInfoDTO[];
    public createOn?: string;
    public lastModifyOn?: string;
    public fixedStudentIds?: string[];
    public temporaryStudentIds?: string[];
    public demoStudentIds?: string[];
    public signTeacherUserInfoId?: string;
    public teacherUserInfoIds?: string[];
    public classTimeTeacherMaps?: ClassTimeTeacherInfoMapDTO[];
    public state?: ClassTimeState;
    public branchId?: string;
    public totalCourseHour?: number;
    public totalPrice?: number;
    public canAppointmentValue?: boolean;
    public appointmentCountValue?: number;
    public canAppointment?: boolean;
    public isBeforeAppointmentTime?: boolean;
    public appointmentCount?: number;
    public appointmentKidCount?: number;

    public constructor(init?: Partial<ClassTimeDTO>) { (Object as any).assign(this, init); }
}

export class KidCourseBalanceItem
{
    public branchId?: string;
    public orgStudentCourseId?: string;
    public studentId?: string;
    public courseOrderId?: string;
    public courseId?: string;
    public type?: CourseSaleItemType;
    public unit?: string;
    public amount?: number;
    public giftAmount?: number;
    public transferOutAmount?: number;
    public usedAmount?: number;
    public usedGiftAmount?: number;
    public totalPrice?: number;
    public price?: number;
    public auditPrice?: number;
    public balanceGiftAmount?: number;
    public balanceAmount?: number;
    public balance?: number;
    public createOn?: string;
    public expireOn?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<KidCourseBalanceItem>) { (Object as any).assign(this, init); }
}

export class AppointmentLogDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public courseId?: string;
    public classTimeId?: string;
    public kidId?: string;
    public state?: AppointmentState;
    public cancelOn?: string;
    public createOn?: string;

    public constructor(init?: Partial<AppointmentLogDTO>) { (Object as any).assign(this, init); }
}

export class ClassTimeSignInfoKidDTO extends ClassTimeSignInfoDTO
{
    public classTimeId?: string;
    public schoolId?: string;
    public index?: number;
    public scheduleId?: string;
    public beginTime?: string;
    public classId?: string;
    public createOn?: string;
    public endMinute?: number;
    public startMinute?: number;

    public constructor(init?: Partial<ClassTimeSignInfoKidDTO>) { super(init); (Object as any).assign(this, init); }
}

export class CourseConsumLogDTO
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public studentId?: string;
    public courseId?: string;
    public classTimeId?: string;
    public courseSubject?: string[];
    public eventId?: string;
    public orgStudentCourseId?: string;
    public modifyAmount?: number;
    public useGift?: boolean;
    public price?: string;
    public remark?: string;
    public paymentReceiptUrl?: string;
    public createOn?: string;
    public userIPAddr?: string;
    public source?: COURSE_LOG_SOURCE;
    public transferOutCourseOrderId?: string;
    public classId?: string;
    public signDate?: string;
    public isReversal?: boolean;

    public constructor(init?: Partial<CourseConsumLogDTO>) { (Object as any).assign(this, init); }
}

export class LiveClassRoomDTO
{
    public id?: string;
    public schoolId?: string;
    public roomId?: string;
    public createByTeacherId?: string;
    public roomMasterTeacherId?: string;
    public roomMasterAgoraUId?: number;
    public createOn?: string;
    public openOn?: string;
    public closeOn?: string;
    public lastModifyOn?: string;
    public title?: string;
    public content?: string;
    public totalSeconds?: number;
    public planSeconds?: number;
    public attachment?: EventAttachDTO;
    public kidMembers?: string[];
    public teacherMembers?: string[];
    public playbackUrl?: string;
    public agoraRecordUrl?: string;
    public userIPAddr?: string;
    public roomStatus?: LIVECLASSROOMSTATUS;
    public publishState?: EVENTPUBLISHSTATE;

    public constructor(init?: Partial<LiveClassRoomDTO>) { (Object as any).assign(this, init); }
}

export class ActivityProdectBaseDTO
{
    public id?: string;
    public marketingPageId?: string;
    public name?: string;
    public desc?: string;
    public images?: string[];
    public productNumber?: number;
    public originalPrice?: number;
    public orderCount?: number;
    public availableCount?: number;
    public qiangGouPrice?: number;
    public needZhuliCount?: number;
    public memberCount?: number;
    public leaderPrice?: number;
    public memberPrice?: number;

    public constructor(init?: Partial<ActivityProdectBaseDTO>) { (Object as any).assign(this, init); }
}

export class HuifuJsPayPayload
{
    public trade_type?: string;
    public pay_info?: string;
    public qr_code?: string;
    public trade_id?: string;

    public constructor(init?: Partial<HuifuJsPayPayload>) { (Object as any).assign(this, init); }
}

export class CashOrderDTO
{
    public id?: string;
    public orderNo?: string;
    public orderName?: string;
    public schoolId?: string;
    public branchId?: string;
    public vendor?: CASHORDER_VENDOR;
    public source?: CASHORDER_SOURCE;
    public sourceId?: string;
    public amount?: number;
    public fee_Amount?: number;
    public settlement_amt?: number;
    public trade_type?: string;
    public out_trans_id?: string;
    public order_finish_time?: string;
    public order_time?: string;
    public pay_Stat?: HUIFU_PAY_STATE;
    public end_time?: string;
    public remark?: string;
    public huifuTradeId?: string;
    public userIPAddr?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<CashOrderDTO>) { (Object as any).assign(this, init); }
}

export class BaseResponse
{
    public code?: number;
    public message?: string;

    public constructor(init?: Partial<BaseResponse>) { (Object as any).assign(this, init); }
}

export class GetCourseConsumLogsForParentRes extends BaseResponse
{
    public data?: CourseConsumLogDTO[];
    public classTimes?: { [index: string]: ClassTimeSignInfoKidDTO; };
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetCourseConsumLogsForParentRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveClassRoomShareDataRes extends BaseResponse
{
    public data?: LiveClassRoomDTO;
    public teacherInfo?: TeacherBaseInfoDTO;

    public constructor(init?: Partial<GetLiveClassRoomShareDataRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimesForParentRes extends BaseResponse
{
    public data?: ClassTimeDTO[];
    public courseData?: { [index: string]: string; };
    public classRoomData?: { [index: string]: string; };
    public classData?: { [index: string]: ClassBaseInfoDTO; };
    public classTimeTeacher?: { [index: string]: ClassTimeTeacherInfoMapDTO[]; };
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };
    public appointmentLog?: { [index: string]: AppointmentLogDTO; };

    public constructor(init?: Partial<GetClassTimesForParentRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidCourseBalanceRes extends BaseResponse
{
    public amount?: number;
    public data?: KidCourseBalanceItem[];
    public courseData?: { [index: string]: string; };

    public constructor(init?: Partial<GetKidCourseBalanceRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetWechatComponentAppIdBySchoolRes extends BaseResponse
{
    public component_appid?: string;
    public authorizer_appid?: string;
    public isShareMP?: boolean;
    public mpNickName?: string;
    public qrCodeUrl?: string;
    public headImageUrl?: string;
    public memberSiteUrl?: string;
    public mpBindUrl?: string;

    public constructor(init?: Partial<GetWechatComponentAppIdBySchoolRes>) { super(init); (Object as any).assign(this, init); }
}

export class WechatComponentBindRes extends BaseResponse
{
    public needBindKid?: boolean;
    public needBindSchoolId?: string;
    public bindKidToken?: string;
    public mToken?: string;
    public bearerToken?: string;

    public constructor(init?: Partial<WechatComponentBindRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolApplicantsRes extends BaseResponse
{
    public total?: number;
    public data?: SchoolApplicantDTO[];
    public ossInfo?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetSchoolApplicantsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingPagesRes extends BaseResponse
{
    public data?: MarketingPageDTO[];
    public totalCount?: number;
    public productDatas?: { [index: string]: ActivityProdectBaseDTO; };

    public constructor(init?: Partial<GetMarketingPagesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPageViewCountRes extends BaseResponse
{
    public totalpv?: number;
    public dailypv?: { [index: string]: number; };

    public constructor(init?: Partial<GetPageViewCountRes>) { super(init); (Object as any).assign(this, init); }
}

export class HuifuCreateCashOrderPayRes extends BaseResponse
{
    public data?: HuifuJsPayPayload;

    public constructor(init?: Partial<HuifuCreateCashOrderPayRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetCashOrderRes extends BaseResponse
{
    public data?: CashOrderDTO;

    public constructor(init?: Partial<GetCashOrderRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolInfoByHuifuDeviceIdRes extends BaseResponse
{
    public schoolId?: string;
    public schoolName?: string;
    public branchId?: string;
    public branchName?: string;

    public constructor(init?: Partial<GetSchoolInfoByHuifuDeviceIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class HuifuCreateJsPayRes extends BaseResponse
{
    public data?: HuifuJsPayPayload;

    public constructor(init?: Partial<HuifuCreateJsPayRes>) { super(init); (Object as any).assign(this, init); }
}

/**
* 家长约课
*/
// @Route("/eduaffair/{SchoolId}/classtime/appointmentcancel", "POST")
// @Api(Description="家长约课")
export class AppointmentCancelReq extends CommonRequest implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="ClassTimeId 不可为空")
    public classTimeId?: string;

    // @Validate(Validator="NotEmpty", Message="KidId 不可为空")
    public kidId?: string;

    // @Validate(Validator="NotEmpty", Message="LogId 不可为空")
    public logId?: string;

    public constructor(init?: Partial<AppointmentCancelReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppointmentCancelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 家长约课
*/
// @Route("/eduaffair/{SchoolId}/classtime/appointmentforkid", "POST")
// @Api(Description="家长约课")
export class AppointmentClassTimeReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="ClassTimeId 不可为空")
    public classTimeId?: string;

    // @Validate(Validator="NotEmpty", Message="KidId 不可为空")
    public kidId?: string;

    public constructor(init?: Partial<AppointmentClassTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AppointmentClassTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取课消记录列表
*/
// @Route("/eduaffair/{SchoolId}/courseconsumlogsforparent", "GET, OPTIONS")
// @Api(Description="获取课消记录列表")
export class GetCourseConsumLogsForParentReq implements IReturn<GetCourseConsumLogsForParentRes>
{
    public schoolId?: string;
    public studentId?: string;
    public orgStudentCourseId?: string;
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetCourseConsumLogsForParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCourseConsumLogsForParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCourseConsumLogsForParentRes(); }
}

/**
* 获取直播课程分享数据
*/
// @Route("/liveclass/room/sharedata", "GET, OPTIONS")
// @Api(Description="获取直播课程分享数据")
export class GetLiveClassRoomShareDataReq implements IReturn<GetLiveClassRoomShareDataRes>
{
    public schoolId?: string;
    public liveRoomId?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetLiveClassRoomShareDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveClassRoomShareDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveClassRoomShareDataRes(); }
}

/**
* 家长获取可约课的课时
*/
// @Route("/eduaffair/{SchoolId}/{KidId}/classtimes/canappointment", "GET")
// @Api(Description="家长获取可约课的课时")
export class GetClassTimesCanAppointmentReq implements IReturn<GetClassTimesForParentRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public kidId?: string;
    // @Validate(Validator="NotEmpty", Message="StartTime 不可为空")
    public startTime?: string;

    // @Validate(Validator="NotEmpty", Message="EndTime 不可为空")
    public endTime?: string;

    public constructor(init?: Partial<GetClassTimesCanAppointmentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimesCanAppointmentReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetClassTimesForParentRes(); }
}

/**
* 家长获取学生的所有课时
*/
// @Route("/eduaffair/{SchoolId}/{KidId}/classtimes", "GET, OPTIONS")
// @Api(Description="家长获取学生的所有课时")
export class GetClassTimesForParentReq implements IReturn<GetClassTimesForParentRes>
{
    public schoolId?: string;
    public kidId?: string;
    public startTime?: string;
    public endTime?: string;

    public constructor(init?: Partial<GetClassTimesForParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimesForParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassTimesForParentRes(); }
}

/**
* 获取学生课程的课时剩余信息
*/
// @Route("/school/{SchoolId}/kid/{KidId}/coursebalance", "GET, OPTIONS")
// @Api(Description="获取学生课程的课时剩余信息")
export class GetKidCourseBalanceReq implements IReturn<GetKidCourseBalanceRes>
{
    public schoolId?: string;
    public kidId?: string;
    public isH5?: boolean;

    public constructor(init?: Partial<GetKidCourseBalanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidCourseBalanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidCourseBalanceRes(); }
}

/**
* 获取授权方的appid
*/
// @Route("/wechatmp/authorizerappid", "GET, OPTIONS")
// @Api(Description="获取授权方的appid")
export class GetWechatComponentAppIdBySchoolReq implements IReturn<GetWechatComponentAppIdBySchoolRes>
{
    public component_appid?: string;
    public schoolId?: string;

    public constructor(init?: Partial<GetWechatComponentAppIdBySchoolReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWechatComponentAppIdBySchoolReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetWechatComponentAppIdBySchoolRes(); }
}

/**
* 微信H5用户激活绑定
*/
// @Route("/wechatmp/userbind", "POST, OPTIONS")
// @Api(Description="微信H5用户激活绑定")
export class WechatComponentBindReq implements IReturn<WechatComponentBindRes>
{
    public schoolId?: string;
    public phoneNumber?: string;
    public verifyCode?: string;
    public authorizer_OpenId?: string;
    public component_appid?: string;
    public authorizer_appid?: string;
    public needToken?: boolean;

    public constructor(init?: Partial<WechatComponentBindReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WechatComponentBindReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WechatComponentBindRes(); }
}

/**
* 微信H5用户取消绑定
*/
// @Route("/wechatmp/unbind", "POST, OPTIONS")
// @Api(Description="微信H5用户取消绑定")
export class WechatComponentUnBindReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public authorizer_OpenId?: string;

    public constructor(init?: Partial<WechatComponentUnBindReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WechatComponentUnBindReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

// @Route("/schoolapplicant/add", "POST")
export class AddSchoolApplicantReq implements IReturn<BaseResponse>
{
    public id?: string;
    public mToken?: string;
    public name?: string;
    public eName?: string;
    public desc?: string;
    public address?: string;
    public phoneNumber?: string;
    public masterPhoneNumber?: string;
    public masterFullName?: string;
    public logoOssId?: string;
    public schoolImageOssId?: string;
    public source?: string[];

    public constructor(init?: Partial<AddSchoolApplicantReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolApplicantReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

// @Route("/schoolapplicant/find", "GET")
export class FindSchoolApplicantReq implements IReturn<GetSchoolApplicantsRes>
{
    public masterFullName?: string;
    public masterPhoneNumber?: string;
    public mToken?: string;

    public constructor(init?: Partial<FindSchoolApplicantReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FindSchoolApplicantReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolApplicantsRes(); }
}

/**
* 获取学校营销列表
*/
// @Route("/marketing/pages", "GET, OPTIONS")
// @Api(Description="获取学校营销列表")
export class GetMarketingPagesReq implements IReturn<GetMarketingPagesRes>
{
    public schoolId?: string;
    public title?: string;
    public pageMode?: string;
    public isPublish?: boolean;
    public isDelete?: boolean;
    public beginAt?: string[];
    public endAt?: string[];
    public needPayment?: boolean;
    public fee?: number[];
    public tags?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetMarketingPagesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPagesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPagesRes(); }
}

/**
* 页面计数
*/
// @Route("/common/page/count", "GET, OPTIONS")
// @Api(Description="页面计数")
export class GetPageViewCountReq implements IReturn<GetPageViewCountRes>
{
    public pageId?: string;
    public realTime?: number;

    public constructor(init?: Partial<GetPageViewCountReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPageViewCountReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPageViewCountRes(); }
}

/**
* CashOrder下单
*/
// @Route("/payment/huifucashorderjspay", "POST, OPTIONS")
// @Api(Description="CashOrder下单")
export class HuifuCreateCashOrderPayReq implements IReturn<HuifuCreateCashOrderPayRes>
{
    public orderId?: string;
    public trade_type?: string;
    public wx_appid?: string;
    public wx_openid?: string;
    public wx_sub_openid?: string;

    public constructor(init?: Partial<HuifuCreateCashOrderPayReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HuifuCreateCashOrderPayReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HuifuCreateCashOrderPayRes(); }
}

/**
* 获取收银订单信息
*/
// @Route("/payment/cashorder", "GET, OPTIONS")
// @Api(Description="获取收银订单信息")
export class GetCashOrderReq implements IReturn<GetCashOrderRes>
{
    public orderId?: string;

    public constructor(init?: Partial<GetCashOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCashOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCashOrderRes(); }
}

/**
* 查询设备id所在学校信息
*/
// @Route("/payment/schoolinfobydeviceid", "GET, OPTIONS")
// @Api(Description="查询设备id所在学校信息")
export class GetSchoolInfoByHuifuDeviceIdReq implements IReturn<GetSchoolInfoByHuifuDeviceIdRes>
{
    public deviceId?: string;
    public schoolId?: string;
    public teacherId?: string;

    public constructor(init?: Partial<GetSchoolInfoByHuifuDeviceIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolInfoByHuifuDeviceIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolInfoByHuifuDeviceIdRes(); }
}

/**
* 聚合正扫
*/
// @Route("/payment/huifujspay", "POST, OPTIONS")
// @Api(Description="聚合正扫")
export class HuifuCreateJsPayReq implements IReturn<HuifuCreateJsPayRes>
{
    public deviceId?: string;
    public teacherId?: string;
    public schoolId?: string;
    public amount?: number;
    public trade_type?: string;
    public goods_Desc?: string;
    public wx_appid?: string;
    public wx_openid?: string;
    public wx_sub_openid?: string;
    public remark?: string;

    public constructor(init?: Partial<HuifuCreateJsPayReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HuifuCreateJsPayReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HuifuCreateJsPayRes(); }
}


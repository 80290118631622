import * as types from "./mutationTypes";

const mutations = {
  [types.SET_LOGIN_STATUS](state, loginStatus) {
    state.loginStatus = loginStatus;
  },
  [types.SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken;
  },
  [types.SET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo;
  },
  [types.SET_OPEN_ID](state, openId) {
    state.openId = openId;
  },
  [types.SET_API_TOKEN](state, apiToken) {
    state.apiToken = apiToken;
  },
  [types.SET_PAGE_ATTRS](state, pageAttrs) {
    state.pageAttrs = pageAttrs;
  },
  [types.SET_PAGE_DATA](state, pageData) {
    state.pageData = pageData;
  },

  [types.SET_SESSION_DATA](state, payload) {
    state.sessionData = payload;
  },
  [types.SET_CACHE_DATA](state, payload) {
    state.cacheData = payload;
  }
};

export default mutations;

/* Options:
Date: 2022-05-30 17:47:17
Version: 6.02
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5000/api/educrm

//GlobalNamespace: 
MakePropertiesOptional: True
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
IncludeTypes: {babycare},IReturn`1,GENDERTYPE,ParentInfoDTO,PARENT_ROLE_TYPE,DAILYCARE_DEVICE_TYPE,GeoCoordinates,STUDENT_MEMBER_BUY_TYPE,MyDayOfWeek,BabyDailyReportItem,SCHOOL_SERVICE_MODULE,SCHOOL_TYPE
//ExcludeTypes: 
DefaultImports: AttachImageItem:./common.dtos,AttachVideoItem:./common.dtos,AttachVoiceItem:./common.dtos,AttachFileItem:./common.dtos,AttachLinkItem:./common.dtos,AttachTingItem:./common.dtos
*/

import { AttachImageItem } from "./common.dtos";
import { AttachVideoItem } from "./common.dtos";
import { AttachVoiceItem } from "./common.dtos";
import { AttachFileItem } from "./common.dtos";
import { AttachLinkItem } from "./common.dtos";
import { AttachTingItem } from "./common.dtos";

export interface IReturn<T>
{
    createResponse(): T;
}

export enum OSTYPE
{
    ANDROID = 'ANDROID',
    IOS = 'IOS',
}

export enum DAILYCARE_TOILET_TYPE
{
    NONE = 'NONE',
    PEE = 'PEE',
    POO = 'POO',
}

export enum DAILYCARE_EAT_STATUS
{
    NOT_EAT = 'NOT_EAT',
    EAT_HALF = 'EAT_HALF',
    EAT_ALL = 'EAT_ALL',
}

export enum DAILYCARE_WORK_STATUS
{
    INCOMPLETE = 'INCOMPLETE',
    COMPLETED = 'COMPLETED',
    WAIT_INSPECT = 'WAIT_INSPECT',
    INSPECTED = 'INSPECTED',
}

export enum MyDayOfWeek
{
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
}

export class BabyCareTaskFlowDTO
{
    public id?: string;
    public schoolId?: string;
    public babyCareTaskId?: string;
    public timeBegin?: string;
    public timeEnd?: string;
    public weekDayOn?: MyDayOfWeek[];
    public flowName?: string;
    public teacherRoles?: string[];
    public needVideoRecord?: boolean;
    public isEnabled?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public studyId?: string;

    public constructor(init?: Partial<BabyCareTaskFlowDTO>) { (Object as any).assign(this, init); }
}

export enum GENDERTYPE
{
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    UNKNOW = 'UNKNOW',
}

export enum PARENT_ROLE_TYPE
{
    MAMA = 'MAMA',
    BABA = 'BABA',
    YEYE = 'YEYE',
    NAINAI = 'NAINAI',
    WAIGONG = 'WAIGONG',
    WAIPO = 'WAIPO',
    GEGE = 'GEGE',
    JIEJIE = 'JIEJIE',
    QITA = 'QITA',
}

export class ParentInfoDTO
{
    public parentRoleType?: PARENT_ROLE_TYPE;
    public phoneNumber?: string;

    public constructor(init?: Partial<ParentInfoDTO>) { (Object as any).assign(this, init); }
}

export enum LEAVE_REQUEST_TYPE
{
    LEAVE = 'LEAVE',
    ATTENDANCE = 'ATTENDANCE',
}

export enum LEAVESTATE
{
    WAIT_APPROVE = 'WAIT_APPROVE',
    APPROVE = 'APPROVE',
    REFUSE = 'REFUSE',
    SELF_CANCEL = 'SELF_CANCEL',
}

export class EventAttachImageItemDTO
{
    public ossInfoId?: string;
    public orderIndex?: number;
    public rotate?: number;

    public constructor(init?: Partial<EventAttachImageItemDTO>) { (Object as any).assign(this, init); }
}

export class GeoCoordinates
{
    public lng?: number;
    public lat?: number;

    public constructor(init?: Partial<GeoCoordinates>) { (Object as any).assign(this, init); }
}

export enum DishType
{
    OTHER = 'OTHER',
    STAPLE = 'STAPLE',
    CANDY = 'CANDY',
    NOODLE = 'NOODLE',
    GRUEL = 'GRUEL',
    COLD_DISH = 'COLD_DISH',
    VEGETABLE = 'VEGETABLE',
    MEAT = 'MEAT',
    SOUP = 'SOUP',
    DRINK = 'DRINK',
    FRUIT = 'FRUIT',
    STAPLE_BABY = 'STAPLE_BABY',
    CANDY_BABY = 'CANDY_BABY',
    NOODLE_BABY = 'NOODLE_BABY',
    COLD_DISH_BABY = 'COLD_DISH_BABY',
    GRUEL_BABY = 'GRUEL_BABY',
    VEGETABLE_BABY = 'VEGETABLE_BABY',
    MEAT_BABY = 'MEAT_BABY',
    SOUP_BABY = 'SOUP_BABY',
    DRINK_BABY = 'DRINK_BABY',
    FRUIT_BABY = 'FRUIT_BABY',
    WESTERN_FOOD = 'WESTERN_FOOD',
    QINGZHEN = 'QINGZHEN',
}

export class NutritionBase
{
    public energy?: number;
    public fat?: number;
    public protein?: number;
    public fiber?: number;
    public carbohydrate?: number;

    public constructor(init?: Partial<NutritionBase>) { (Object as any).assign(this, init); }
}

export class Nutrition extends NutritionBase
{
    public va?: number;
    public carotene?: number;
    public vb1?: number;
    public vb2?: number;
    public vb3?: number;
    public vc?: number;
    public ve?: number;
    public ca?: number;
    public p?: number;
    public k?: number;
    public na?: number;
    public mg?: number;
    public fe?: number;
    public zn?: number;
    public se?: number;
    public cu?: number;
    public mn?: number;
    public i?: number;

    public constructor(init?: Partial<Nutrition>) { super(init); (Object as any).assign(this, init); }
}

export class DishItemDTO
{
    public id?: string;
    public dishName?: string;
    public nutritions?: Nutrition;
    public dishType?: DishType;
    public ingredientAmount?: { [index: string]: number; };
    public isDelete?: boolean;
    public schoolId?: string;
    public createByTeacherId?: string;
    public createOn?: string;

    public constructor(init?: Partial<DishItemDTO>) { (Object as any).assign(this, init); }
}

export enum SCHOOL_TYPE
{
    KINDERGARTEN = 'KINDERGARTEN',
    EDUAFFAIR = 'EDUAFFAIR',
    SCHOOL = 'SCHOOL',
    ALL = 'ALL',
}

export class DishMenuDishDTO
{
    public id?: string;
    public ingredientAmount?: { [index: string]: number; };

    public constructor(init?: Partial<DishMenuDishDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachVideoItemDTO
{
    public ossInfoId?: string;

    public constructor(init?: Partial<EventAttachVideoItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachAudioItemDTO
{
    public ossInfoId?: string;

    public constructor(init?: Partial<EventAttachAudioItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachFileItemDTO
{
    public ossInfoId?: string;

    public constructor(init?: Partial<EventAttachFileItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachLinkItemDTO
{
    public linkUrl?: string;
    public linkTitle?: string;
    public linkIcon?: string;

    public constructor(init?: Partial<EventAttachLinkItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachTingItemDTO
{
    public albumId?: string;
    public trackId?: string;
    public albumTitle?: string;
    public albumDesc?: string;
    public albumImageUrl?: string;

    public constructor(init?: Partial<EventAttachTingItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachDTO
{
    public imageItems?: EventAttachImageItemDTO[];
    public videoItems?: EventAttachVideoItemDTO[];
    public audioItems?: EventAttachAudioItemDTO[];
    public fileItems?: EventAttachFileItemDTO[];
    public linkItems?: EventAttachLinkItemDTO[];
    public tingItems?: EventAttachTingItemDTO[];
    public videoItem?: EventAttachVideoItemDTO;
    public audioItem?: EventAttachAudioItemDTO;
    public fileItem?: EventAttachFileItemDTO;
    public linkItem?: EventAttachLinkItemDTO;
    public tingItem?: EventAttachTingItemDTO;

    public constructor(init?: Partial<EventAttachDTO>) { (Object as any).assign(this, init); }
}

export class DishMenuContentDTO
{
    public title?: string;
    public dishs?: DishMenuDishDTO[];
    public attachment?: EventAttachDTO;

    public constructor(init?: Partial<DishMenuContentDTO>) { (Object as any).assign(this, init); }
}

export enum STUDENT_MEMBER_BUY_TYPE
{
    BUY_BY_PARENT = 'BUY_BY_PARENT',
    BUY_BY_SCHOOL = 'BUY_BY_SCHOOL',
}

export enum MEDICSTAT
{
    WAIT = 'WAIT',
    FINISH = 'FINISH',
    CANCEL = 'CANCEL',
}

export class MedicCareLogDTO
{
    public id?: string;
    public createOn?: string;
    public logContent?: string;

    public constructor(init?: Partial<MedicCareLogDTO>) { (Object as any).assign(this, init); }
}

export class TeacherBaseInfoDTO
{
    public id?: string;
    public fullName?: string;
    public phoneNumber?: string;
    public logoUrl?: string;
    public userProfileId?: string;

    public constructor(init?: Partial<TeacherBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class OssInfoDTO
{
    public resId?: string;
    public fileUri?: string;
    public thumbnilUri?: string;
    public playSeconds?: number;
    public aspectRatio?: string;
    public fileExt?: string;
    public fileName?: string;

    public constructor(init?: Partial<OssInfoDTO>) { (Object as any).assign(this, init); }
}

export class ClassBaseInfoDTO
{
    public id?: string;
    public branchId?: string;
    public className?: string;
    public alias?: string;
    public kidCount?: number;
    public courseId?: string;

    public constructor(init?: Partial<ClassBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class StudentBaseInfoDTO
{
    public branchId?: string;
    public id?: string;
    public name?: string;
    public gender?: GENDERTYPE;
    public birthDate?: string;
    public logoUrl?: string;
    public primaryContact?: ParentInfoDTO;
    public secondaryContact?: ParentInfoDTO;
    public classIds?: string[];
    public parentRelationSchoolIds?: string[];

    public constructor(init?: Partial<StudentBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class DailyCareSchoolConfigDTO
{
    public id?: string;
    public schoolId?: string;
    public sleepOn?: string;
    public wakeupOn?: string;
    public disableDailyCareDrinkWater?: boolean;
    public disableDailyCareBreakfast?: boolean;
    public disableDailyCareLunch?: boolean;
    public disableDailyCareSnack?: boolean;
    public disableDailyCareToilet?: boolean;
    public disableDailyCareDiaper?: boolean;
    public disableDailyCareSleep?: boolean;
    public disableDailyCareMilk?: boolean;
    public disableDailyCareTemperature?: boolean;
    public disableDailyCareNote?: boolean;
    public remindDrinkWater?: boolean;
    public remindDrinkWaterPeriod?: number;
    public remindDiaper?: boolean;
    public remindDiaperPeriod?: number;
    public remindToilet?: boolean;
    public remindToiletPeriod?: number;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<DailyCareSchoolConfigDTO>) { (Object as any).assign(this, init); }
}

export class SchoolSettingDTO
{
    public id?: string;
    public schoolType?: SCHOOL_TYPE;
    public lastClassTimeNotifyOn?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public isSendEventDailyHealthy?: boolean;
    public isSendEventDailyHealthyBySms?: boolean;
    public isSendEventDailyHealthyByWechat?: boolean;
    public isSendEventAttendance?: boolean;
    public isSendEventAttendanceBySms?: boolean;
    public isSendEventAttendanceByWechat?: boolean;
    public isSendAuditLeaveRequestByApp?: boolean;
    public isSendAuditLeaveRequestBySms?: boolean;
    public isSendAuditLeaveRequestByWechat?: boolean;
    public isSendClassBillByApp?: boolean;
    public isSendClassBillBySms?: boolean;
    public isSendClassBillByWechat?: boolean;
    public isSendAddClassTimeStudentDemoByApp?: boolean;
    public isSendAddClassTimeStudentDemoBySms?: boolean;
    public isSendAddClassTimeStudentDemoByWechat?: boolean;
    public sendWishNotifyHour?: number;
    public isSendWishBirthdayByApp?: boolean;
    public isSendWishBirthdayBySms?: boolean;
    public isSendWishBirthdayByWechat?: boolean;
    public isSendWishHolidayByApp?: boolean;
    public isSendWishHolidayBySms?: boolean;
    public isSendWishHolidayByWechat?: boolean;
    public kidBlacklistSendNotify?: string[];
    public wechatMsgSuffix?: string;
    public classTimeNotifyHour?: number;
    public isSendClassTimeNotifyByApp?: boolean;
    public isSendClassTimeNotifyBySms?: boolean;
    public isSendClassTimeNotifyByWechat?: boolean;
    public sendClassTimeNotifyBlacklist?: string[];
    public isSendClassTimeSignByApp?: boolean;
    public isSendClassTimeSignBySms?: boolean;
    public isSendClassTimeSignByWechat?: boolean;
    public sendClassTimeSignBlacklist?: string[];
    public isSendClassTimeCommentApp?: boolean;
    public isSendClassTimeCommentBySms?: boolean;
    public isSendClassTimeCommentByWechat?: boolean;
    public sendClassTimeCommentBlacklist?: string[];
    public isSendEventKGTimeByApp?: boolean;
    public isSendEventKGTimeBySms?: boolean;
    public isSendEventKGTimeByWechat?: boolean;
    public isSendEventTaskByApp?: boolean;
    public isSendEventTaskBySms?: boolean;
    public isSendEventTaskByWechat?: boolean;
    public isSendEventLiveClassRoomByApp?: boolean;
    public isSendEventLiveClassRoomBySms?: boolean;
    public isSendEventLiveClassRoomByWechat?: boolean;
    public isSendEventNotifyByApp?: boolean;
    public isSendEventNotifyBySms?: boolean;
    public isSendEventNotifyByWechat?: boolean;
    public isSendEventShareByApp?: boolean;
    public isSendEventShareBySms?: boolean;
    public isSendEventShareByWechat?: boolean;
    public isSendEventEvaluateByApp?: boolean;
    public isSendEventEvaluateBySms?: boolean;
    public isSendEventEvaluateByWechat?: boolean;
    public isSendEventKidRemarkByApp?: boolean;
    public isSendEventKidRemarkBySms?: boolean;
    public isSendEventKidRemarkByWechat?: boolean;
    public isSendEventVoteByApp?: boolean;
    public isSendEventVoteBySms?: boolean;
    public isSendEventVoteByWechat?: boolean;
    public isSendEventClockInByApp?: boolean;
    public isSendEventClockInBySms?: boolean;
    public isSendEventClockInByWechat?: boolean;
    public isSendEventDailyMenuByApp?: boolean;
    public isSendEventDailyMenuBySms?: boolean;
    public isSendEventDailyMenuByWechat?: boolean;
    public isSendEventCommissionPickupByApp?: boolean;
    public isSendEventCommissionPickupBySms?: boolean;
    public isSendEventCommissionPickupByWechat?: boolean;
    public isSendEventKidCheckUpByApp?: boolean;
    public isSendEventKidCheckUpBySms?: boolean;
    public isSendEventKidCheckUpByWechat?: boolean;
    public isSendEventKGTimeUnreadNotifyByWechat?: boolean;
    public sendClassTimeSignModifyBlacklist?: string[];
    public isSendCLassTimeSignModifyBySms?: boolean;
    public isSendClassTimeSignModifyByWechat?: boolean;
    public isSendClassTimeModifyBySms?: boolean;
    public isSendClassTimeModifyByWechat?: boolean;
    public sendClassTimeModifyBlacklist?: string[];
    public isSendCourseOrderNotifyBySms?: boolean;
    public isSendCourseOrderNotifyByWechat?: boolean;
    public sendCourseOrderNotifyBlacklist?: string[];
    public sendEventTaskBlacklist?: string[];
    public isSendEventTaskUnreadNotifyByWechat?: boolean;
    public isSendTeacherCommentByWechat?: boolean;
    public isSendTeacherCommentUnreadNotifyByWechat?: boolean;
    public sendTeacherCommentBlacklist?: string[];
    public isSendTeacherCommentBySms?: boolean;
    public isSendEventNotifyUnreadNotifyByWechat?: boolean;
    public sendEventNotifBlacklist?: string[];
    public paymentType?: string[];
    public alarmKidCourseBalanceMin?: number;
    public alarmKidCourseBalanceMinDays?: number;
    public isClassTimeSignUseAttendance?: boolean;
    public isShowTeacherFullPhoneNumber?: boolean;
    public isShowParentFullPhoneNumber?: boolean;
    public dailyMaxFullPhoneNumberRequest?: number;
    public isCanSetKidFaceByTeacher?: boolean;
    public isCanAddKidOtherParent?: boolean;
    public isShowBalance?: boolean;
    public isShowWatch?: boolean;
    public appSplashImageUrl?: string;
    public appSplashJumpUrl?: string;
    public enableIMClassGroup?: boolean;
    public isTeacherCanRecallParentIM?: boolean;
    public babyCareNotifyHour?: number;
    public babyCareWeekNotifyHour?: number;
    public babyCareWeekNotifyWeekDay?: MyDayOfWeek;
    public isSendBabyCareNotify?: boolean;
    public isSendBabyCareNotifyBySms?: boolean;
    public isSendBabyCareNotifyByWechat?: boolean;
    public isAllowSubmitBabyCareTimeOut?: boolean;
    public checkTimeDailyCareDrinkWater?: number;
    public checkTimeDailyCareMilk?: number;
    public checkTimeDailyCareDiaper?: number;
    public checkTimeDailyCareToilet?: number;
    public babyCareNoteTemplateDaily?: string;
    public babyCareNoteTemplateWeek?: string;
    public disabledPermissionCode?: string[];
    public teacherStudy_WaterMask_IsShowSchoolName?: boolean;
    public teacherStudy_WaterMask_IsShowTeacherName?: boolean;
    public teacherStudy_WaterMask_IsShowTeacherPhoneNumber?: boolean;
    public patrolType?: string[];

    public constructor(init?: Partial<SchoolSettingDTO>) { (Object as any).assign(this, init); }
}

export class DailyCareBatchLogDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public kidIds?: string[];
    public dailyCareIds?: string[];
    public type?: string;
    public teacherUserInfoId?: string;
    public recordDateOn?: string;
    public createOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;

    public constructor(init?: Partial<DailyCareBatchLogDTO>) { (Object as any).assign(this, init); }
}

export class DailyCareItem
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public recordDateOn?: string;
    public teacherUserInfoId?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public dailyCareType?: string;
    public checkTime?: string;
    public waterContent?: string;
    public eatStatus?: DAILYCARE_EAT_STATUS;
    public memo?: string;
    public toiletType?: DAILYCARE_TOILET_TYPE;
    public sleepOn?: string;
    public wakeupOn?: string;
    public milkVol?: number;
    public temperature?: string;
    public note?: string;
    public isInspected?: boolean;
    public inspectedOn?: string;

    public constructor(init?: Partial<DailyCareItem>) { (Object as any).assign(this, init); }
}

export class DailayCareNoticeItem
{
    public kidId?: string;
    public careType?: string;
    public noticeMsg?: string;
    public showOnTimestamp?: number;

    public constructor(init?: Partial<DailayCareNoticeItem>) { (Object as any).assign(this, init); }
}

export class ClassInfoWithTeacherRoleDTO
{
    public classId?: string;
    public className?: string;
    public myTeacherRole?: string;

    public constructor(init?: Partial<ClassInfoWithTeacherRoleDTO>) { (Object as any).assign(this, init); }
}

export enum DAILYCARE_DEVICE_TYPE
{
    MODEL_ONE = 'MODEL_ONE',
}

export class DailyCareDeviceDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public serialNo?: string;
    public deviceType?: DAILYCARE_DEVICE_TYPE;
    public macAddr?: string;
    public registOn?: string;
    public lastHeartbeatOn?: string;
    public lastIPAddr?: string;
    public remark?: string;
    public lastModifyOn?: string;
    public liveChannelIds?: string[];
    public isBYOD?: boolean;
    public expireOn?: string;
    public osType?: OSTYPE;

    public constructor(init?: Partial<DailyCareDeviceDTO>) { (Object as any).assign(this, init); }
}

export class BabyCareBYODDeviceLicenseItem
{
    public id?: string;
    public schoolId?: string;
    public dailyCareDeviceId?: string;
    public createOn?: string;
    public dayCount?: number;
    public activeOn?: string;
    public expiredOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<BabyCareBYODDeviceLicenseItem>) { (Object as any).assign(this, init); }
}

export class BabyCareTaskDTO
{
    public id?: string;
    public schoolId?: string;
    public allocatedClassIds?: string[];
    public taskName?: string;
    public isEnabled?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public studyId?: string;
    public flows?: BabyCareTaskFlowDTO[];

    public constructor(init?: Partial<BabyCareTaskDTO>) { (Object as any).assign(this, init); }
}

export enum DAILYCARE_DVR_STATUS
{
    NONE = 'NONE',
    INIT = 'INIT',
    PROCESSING = 'PROCESSING',
    FINISH = 'FINISH',
    FAILED = 'FAILED',
}

export class BabyCareTaskWorkDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public dailyCareDeviceId?: string;
    public babyCareTaskId?: string;
    public babyCareTaskFlowId?: string;
    public teacherUserInfoId?: string;
    public dateOn?: string;
    public createOn?: string;
    public isInspected?: boolean;
    public status?: DAILYCARE_WORK_STATUS;
    public inspectedOn?: string;
    public videoOssId?: string;
    public videoUrl?: string;
    public videoDuration?: number;
    public dvrStatus?: DAILYCARE_DVR_STATUS;

    public constructor(init?: Partial<BabyCareTaskWorkDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachItem
{
    public attachImages?: AttachImageItem[];
    public attachVideos?: AttachVideoItem[];
    public attachVoices?: AttachVoiceItem[];
    public attachFiles?: AttachFileItem[];
    public attachLinks?: AttachLinkItem[];
    public attachTings?: AttachTingItem[];
    public attachVideo?: AttachVideoItem;
    public attachVoice?: AttachVoiceItem;
    public attachFile?: AttachFileItem;
    public attachLink?: AttachLinkItem;
    public attachTing?: AttachTingItem;

    public constructor(init?: Partial<EventAttachItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventAttachItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class DailyMenuDTO
{
    public id?: string;
    public schoolId?: string;
    public dateOn?: string;
    public title?: string;
    public content1?: string;
    public attachment1?: EventAttachDTO;
    public attachs1?: EventAttachItem;
    public content2?: string;
    public attachment2?: EventAttachDTO;
    public attachs2?: EventAttachItem;
    public content3?: string;
    public attachment3?: EventAttachDTO;
    public attachs3?: EventAttachItem;
    public isPublished?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public contents?: DishMenuContentDTO[];
    public nutritions?: Nutrition;
    public createByTeacherId?: string;

    public constructor(init?: Partial<DailyMenuDTO>) { (Object as any).assign(this, init); }
}

export class BabyDailySummaryItem
{
    public icon?: string;
    public name?: string;
    public maxValue?: number;
    public avgValue?: number;
    public value?: number;

    public constructor(init?: Partial<BabyDailySummaryItem>) { (Object as any).assign(this, init); }
}

export class DailyCareSchoolDailySummaryDTO
{
    public reportDate?: string;
    public schoolId?: string;
    public classId?: string;
    public classCount?: number;
    public kidCount?: number;
    public count?: number;

    public constructor(init?: Partial<DailyCareSchoolDailySummaryDTO>) { (Object as any).assign(this, init); }
}

export class BabyCareTaskWorkSchoolDailySummaryDTO
{
    public reportDate?: string;
    public schoolId?: string;
    public classId?: string;
    public classCount?: number;
    public onTimeCount?: number;
    public workCount?: number;
    public flowCount?: number;

    public constructor(init?: Partial<BabyCareTaskWorkSchoolDailySummaryDTO>) { (Object as any).assign(this, init); }
}

export class KidMyLeaveRequestItem
{
    public id?: string;
    public type?: LEAVE_REQUEST_TYPE;
    public isTeacher?: boolean;
    public classId?: string;
    public schoolId?: string;
    public leaveEntityId?: string;
    public leaveRequesterId?: string;
    public createOn?: string;
    public timeBegin?: string;
    public timeEnd?: string;
    public hours?: string;
    public userIPAddr?: string;
    public state?: LEAVESTATE;
    public auditOn?: string;
    public auditMsg?: string;
    public cancelOn?: string;
    public auditorId?: string;
    public auditUserIPAddr?: string;
    public lastModifyOn?: string;
    public leaveSubject?: string;
    public leaveDescribe?: string;

    public constructor(init?: Partial<KidMyLeaveRequestItem>) { (Object as any).assign(this, init); }
}

export class MedicCareDTO
{
    public id?: string;
    public schoolId?: string;
    public studentId?: string;
    public medicTimeOn?: string;
    public parentCreateOn?: string;
    public teacherSubmitOn?: string;
    public teacherActualTimeOn?: string;
    public lastModifyOn?: string;
    public stat?: MEDICSTAT;
    public parentImageItems?: EventAttachImageItemDTO[];
    public teacherImageItems?: EventAttachImageItemDTO[];
    public medicDesc?: string;
    public parentMemo?: string;
    public teacherMemo?: string;
    public parentRelationSchoolInfoId?: string;
    public teacherUserInfoId?: string;
    public logs?: MedicCareLogDTO[];

    public constructor(init?: Partial<MedicCareDTO>) { (Object as any).assign(this, init); }
}

export class AliyunOssSignItem
{
    public originalKey?: string;
    public key?: string;
    public policy?: string;
    public signature?: string;

    public constructor(init?: Partial<AliyunOssSignItem>) { (Object as any).assign(this, init); }
}

export class BabyDailyReportItem
{
    public icon?: string;
    public name?: string;
    public value?: string;
    public memo?: string;

    public constructor(init?: Partial<BabyDailyReportItem>) { (Object as any).assign(this, init); }
}

export class BaseResponse
{
    public code?: number;
    public message?: string;

    public constructor(init?: Partial<BaseResponse>) { (Object as any).assign(this, init); }
}

export class SchoolBabyCareConfigRes extends BaseResponse
{
    public data?: DailyCareSchoolConfigDTO;
    public schoolConfigs?: SchoolSettingDTO;

    public constructor(init?: Partial<SchoolBabyCareConfigRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyCareBatchLogsRes extends BaseResponse
{
    public data?: DailyCareBatchLogDTO[];

    public constructor(init?: Partial<GetDailyCareBatchLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyCareBasesRes extends BaseResponse
{
    public total?: number;
    public data?: DailyCareItem[];
    public kidInfos?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetDailyCareBasesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyCareNoticeRes extends BaseResponse
{
    public data?: DailayCareNoticeItem[];

    public constructor(init?: Partial<GetDailyCareNoticeRes>) { super(init); (Object as any).assign(this, init); }
}

export class BabyCareDeviceLoginByFaceRes extends BaseResponse
{
    public token?: string;
    public refreshToken?: string;
    public userName?: string;
    public userId?: number;
    public fullName?: string;
    public avatarUrl?: string;
    public schoolId?: string;
    public schoolLogoUrl?: string;
    public schoolName?: string;
    public classInfos?: ClassInfoWithTeacherRoleDTO[];
    public otpKey?: string;

    public constructor(init?: Partial<BabyCareDeviceLoginByFaceRes>) { super(init); (Object as any).assign(this, init); }
}

export class BabyCareDeviceLoginByTeacherRes extends BaseResponse
{

    public constructor(init?: Partial<BabyCareDeviceLoginByTeacherRes>) { super(init); (Object as any).assign(this, init); }
}

export class BabyCareGetLoginCodeRes extends BaseResponse
{
    public qrCode?: string;
    public ticket?: string;

    public constructor(init?: Partial<BabyCareGetLoginCodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class BabyCareCheckLoginTicketRes extends BaseResponse
{
    public status?: number;
    public isConfirm?: boolean;
    public userName?: string;
    public userId?: number;
    public fullName?: string;
    public avatarUrl?: string;
    public token?: string;
    public refreshToken?: string;
    public schoolLogoUrl?: string;
    public schoolId?: string;
    public schoolName?: string;
    public classInfos?: ClassInfoWithTeacherRoleDTO[];
    public otpKey?: string;

    public constructor(init?: Partial<BabyCareCheckLoginTicketRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareDeviceInfoRes extends BaseResponse
{
    public data?: DailyCareDeviceDTO;

    public constructor(init?: Partial<GetBabyCareDeviceInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareBYODDeviceLicenseRes extends BaseResponse
{
    public data?: BabyCareBYODDeviceLicenseItem[];
    public deviceImage?: string;
    public totalAmount?: number;
    public avaliableAmount?: number;
    public usedAmount?: number;

    public constructor(init?: Partial<GetBabyCareBYODDeviceLicenseRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareDevicesRes extends BaseResponse
{
    public total?: number;
    public data?: DailyCareDeviceDTO[];
    public liveChannel?: { [index: string]: string; };
    public licenses?: BabyCareBYODDeviceLicenseItem[];

    public constructor(init?: Partial<GetBabyCareDevicesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareTasksRes extends BaseResponse
{
    public data?: BabyCareTaskDTO[];
    public todayWorkHistory?: { [index: string]: BabyCareTaskWorkDTO; };
    public studyInfos?: { [index: string]: string; };

    public constructor(init?: Partial<GetBabyCareTasksRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareTaskWorksRes extends BaseResponse
{
    public total?: number;
    public data?: BabyCareTaskWorkDTO[];
    public teacherInfoData?: { [index: string]: TeacherBaseInfoDTO; };
    public taskData?: { [index: string]: BabyCareTaskDTO; };

    public constructor(init?: Partial<GetBabyCareTaskWorksRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyDailyReportClassRes extends BaseResponse
{
    public schoolName?: string;
    public schoolIcon?: string;
    public className?: string;
    public reportDate?: string;
    public data?: { [index:string]: Object; }[];
    public kidInfos?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetBabyDailyReportClassRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyWeekReportClassRes extends BaseResponse
{
    public schoolName?: string;
    public schoolIcon?: string;
    public className?: string;
    public reportDate?: string;
    public data?: { [index:string]: Object; }[];
    public kidInfos?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetBabyWeekReportClassRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyDailyReportRes extends BaseResponse
{
    public schoolName?: string;
    public schoolIcon?: string;
    public reportDate?: string;
    public kidName?: string;
    public kidAge?: string;
    public kidLogoUrl?: string;
    public reportItems?: { [index: string]: BabyDailyReportItem[]; };
    public todayNote?: string;
    public nutritions?: Nutrition;
    public daliyMenu?: DailyMenuDTO;
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public dishs?: { [index: string]: DishItemDTO; };

    public constructor(init?: Partial<GetBabyDailyReportRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyReportWeekRes extends BaseResponse
{
    public schoolName?: string;
    public schoolIcon?: string;
    public reportDate?: string;
    public kidName?: string;
    public kidAge?: string;
    public kidLogoUrl?: string;
    public summaryItems?: BabyDailySummaryItem[];
    public reportItems?: BabyDailyReportItem[];
    public weekNote?: string;
    public nutritions?: Nutrition;
    public weekNutritions?: Nutrition;

    public constructor(init?: Partial<GetBabyReportWeekRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyCareSchoolDailySummaryRes extends BaseResponse
{
    public data?: DailyCareSchoolDailySummaryDTO[];
    public schoolNames?: { [index: string]: string; };
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetDailyCareSchoolDailySummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareTaskWorkSchoolDailySummaryRes extends BaseResponse
{
    public data?: BabyCareTaskWorkSchoolDailySummaryDTO[];
    public schoolNames?: { [index: string]: string; };
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetBabyCareTaskWorkSchoolDailySummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLeaveRequestByTeacherRes extends BaseResponse
{
    public total?: number;
    public data?: KidMyLeaveRequestItem[];
    public parentRoles?: { [index: string]: string; };
    public studentInfos?: { [index: string]: StudentBaseInfoDTO; };
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetLeaveRequestByTeacherRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolMedicCaresRes extends BaseResponse
{
    public data?: MedicCareDTO[];
    public ossInfo?: { [index: string]: OssInfoDTO; };
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };
    public classInfo?: { [index: string]: ClassBaseInfoDTO; };
    public parentRole?: { [index: string]: string; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolMedicCaresRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetAliyunStsTokenV2Res
{
    public statusCode?: number;
    public accessKeyId?: string;
    public bucketName?: string;
    public uploadHost?: string;
    public expires?: string;
    public dir?: string;
    public callback?: string;
    public signItems?: AliyunOssSignItem[];

    public constructor(init?: Partial<GetAliyunStsTokenV2Res>) { (Object as any).assign(this, init); }
}

/**
* 添加babycare配置
*/
// @Route("/babycare/{SchoolId}/setconfig", "POST, OPTIONS")
// @Api(Description="添加babycare配置")
export class SetBabyCareConfigReq implements IReturn<SchoolBabyCareConfigRes>
{
    public schoolId?: string;
    public configKey?: string;
    public configValue?: string;

    public constructor(init?: Partial<SetBabyCareConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SchoolBabyCareConfigRes(); }
}

/**
* 获取学校babycare配置
*/
// @Route("/babycare/{SchoolId}/config", "GET, OPTIONS")
// @Api(Description="获取学校babycare配置")
export class GetBabyCareConfigReq implements IReturn<SchoolBabyCareConfigRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetBabyCareConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SchoolBabyCareConfigRes(); }
}

/**
* 获取批量操作日志
*/
// @Route("/babycare/{SchoolId}/batchlogs", "GET, OPTIONS")
// @Api(Description="获取批量操作日志")
export class GetDailyCareBatchLogsReq implements IReturn<GetDailyCareBatchLogsRes>
{
    public schoolId?: string;
    public classId?: string;

    public constructor(init?: Partial<GetDailyCareBatchLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyCareBatchLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyCareBatchLogsRes(); }
}

/**
* 删除批量操作
*/
// @Route("/babycare/{SchoolId}/batchlog/delete", "POST, OPTIONS")
// @Api(Description="删除批量操作")
export class DeleteDailyCareBatchLogReq implements IReturn<BaseResponse>
{
    public batchLogId?: string;
    public schoolId?: string;
    public classId?: string;

    public constructor(init?: Partial<DeleteDailyCareBatchLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDailyCareBatchLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare喝水
*/
// @Route("/babycare/{SchoolId}/watercares", "POST, OPTIONS")
// @Api(Description="添加babycare喝水")
export class AddBabyCareWaterReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareWaterReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareWaterReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare厕所
*/
// @Route("/babycare/{SchoolId}/toiletcares", "POST, OPTIONS")
// @Api(Description="添加babycare厕所")
export class AddBabyCareToiletReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public toiletType?: DAILYCARE_TOILET_TYPE;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareToiletReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareToiletReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare尿布
*/
// @Route("/babycare/{SchoolId}/diapercares", "POST, OPTIONS")
// @Api(Description="添加babycare尿布")
export class AddBabyCareDiaperReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public toiletType?: DAILYCARE_TOILET_TYPE;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareDiaperReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareDiaperReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare喝奶
*/
// @Route("/babycare/{SchoolId}/milkcares", "POST, OPTIONS")
// @Api(Description="添加babycare喝奶")
export class AddBabyCareMilkReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public milkVol?: number;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareMilkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareMilkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare体温
*/
// @Route("/babycare/{SchoolId}/tempcares", "POST, OPTIONS")
// @Api(Description="添加babycare体温")
export class AddBabyCareTempReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public temp?: string;
    public memo?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareTempReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareTempReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare早点
*/
// @Route("/babycare/{SchoolId}/breakfastcares", "POST, OPTIONS")
// @Api(Description="添加babycare早点")
export class SetBabyCareBreakfast implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public eatStatus?: DAILYCARE_EAT_STATUS;
    public memo?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareBreakfast>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareBreakfast'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare午餐
*/
// @Route("/babycare/{SchoolId}/lunchcares", "POST, OPTIONS")
// @Api(Description="添加babycare午餐")
export class SetBabyCareLunch implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public eatStatus?: DAILYCARE_EAT_STATUS;
    public memo?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareLunch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareLunch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare午点
*/
// @Route("/babycare/{SchoolId}/snackcares", "POST, OPTIONS")
// @Api(Description="添加babycare午点")
export class SetBabyCareSnack implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public eatStatus?: DAILYCARE_EAT_STATUS;
    public memo?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareSnack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareSnack'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare午睡
*/
// @Route("/babycare/{SchoolId}/sleepcares", "POST, OPTIONS")
// @Api(Description="添加babycare午睡")
export class SetBabyCareSleep implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public sleepOn?: string;
    public wakeupOn?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareSleep>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareSleep'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare 日点评
*/
// @Route("/babycare/{SchoolId}/notecares", "POST, OPTIONS")
// @Api(Description="添加babycare 日点评")
export class SetBabyCareNote implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public note?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareNote>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareNote'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare 周点评
*/
// @Route("/babycare/{SchoolId}/weeknotecares", "POST, OPTIONS")
// @Api(Description="添加babycare 周点评")
export class SetBabyCareNoteWeek implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public note?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareNoteWeek>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareNoteWeek'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校babycare单人数据
*/
// @Route("/babycare/{SchoolId}/kidcares", "GET, OPTIONS")
// @Api(Description="获取学校babycare单人数据")
export class GetKidDailyCareBasesReq implements IReturn<GetDailyCareBasesRes>
{
    public schoolId?: string;
    public classId?: string;
    public recordDateOn?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetKidDailyCareBasesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidDailyCareBasesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyCareBasesRes(); }
}

/**
* 设置学校babycare检查状态
*/
// @Route("/babycare/{SchoolId}/cares/inspected", "POST, OPTIONS")
// @Api(Description="设置学校babycare检查状态")
export class SetDailyCareInspectedReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public ids?: string[];
    public isInspected?: boolean;

    public constructor(init?: Partial<SetDailyCareInspectedReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDailyCareInspectedReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设置学校BabyCareTaskWork检查状态
*/
// @Route("/babycare/{SchoolId}/careworks/inspected", "POST, OPTIONS")
// @Api(Description="设置学校BabyCareTaskWork检查状态")
export class SetBabyCareTaskWorksInspectedReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public ids?: string[];
    public isInspected?: boolean;

    public constructor(init?: Partial<SetBabyCareTaskWorksInspectedReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareTaskWorksInspectedReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校babycare数据
*/
// @Route("/babycare/{SchoolId}/cares", "GET, OPTIONS")
// @Api(Description="获取学校babycare数据")
export class GetDailyCareBasesReq implements IReturn<GetDailyCareBasesRes>
{
    public schoolId?: string;
    public classId?: string;
    public recordDateOn?: string;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetDailyCareBasesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyCareBasesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyCareBasesRes(); }
}

/**
* 获取学校babycare定时提示信息
*/
// @Route("/babycare/{SchoolId}/carenotice", "GET, OPTIONS")
// @Api(Description="获取学校babycare定时提示信息")
export class GetDailyCareNoticeReq implements IReturn<GetDailyCareNoticeRes>
{
    public schoolId?: string;
    public classId?: string;

    public constructor(init?: Partial<GetDailyCareNoticeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyCareNoticeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyCareNoticeRes(); }
}

/**
* 人脸识别登录
*/
// @Route("/babycare/{SchoolId}/loginbyface", "POST, OPTIONS")
// @Api(Description="人脸识别登录")
export class BabyCareDeviceLoginByFaceReq implements IReturn<BabyCareDeviceLoginByFaceRes>
{
    public serialNo?: string;
    public featureData?: string;

    public constructor(init?: Partial<BabyCareDeviceLoginByFaceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BabyCareDeviceLoginByFaceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BabyCareDeviceLoginByFaceRes(); }
}

/**
* 教师在移动端授权登录care
*/
// @Route("/babycare/loginbymanager", "POST, OPTIONS")
// @Api(Description="教师在移动端授权登录care")
export class BabyCareDeviceLoginByTeacherReq implements IReturn<BabyCareDeviceLoginByTeacherRes>
{
    public schoolId?: string;
    public serialNo?: string;
    public ticket?: string;
    public act?: number;

    public constructor(init?: Partial<BabyCareDeviceLoginByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BabyCareDeviceLoginByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BabyCareDeviceLoginByTeacherRes(); }
}

/**
* 获取Babycare登录二维码
*/
// @Route("/babycare/getloginqrcode", "POST, OPTIONS")
// @Api(Description="获取Babycare登录二维码")
export class BabyCareGetLoginCodeReq implements IReturn<BabyCareGetLoginCodeRes>
{
    public serialNo?: string;

    public constructor(init?: Partial<BabyCareGetLoginCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BabyCareGetLoginCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BabyCareGetLoginCodeRes(); }
}

/**
* 刷新babycare登录二维码的状态
*/
// @Route("/babycare/getloginqrcodestatus", "POST, OPTIONS")
// @Api(Description="刷新babycare登录二维码的状态")
export class BabyCareCheckLoginTicketReq implements IReturn<BabyCareCheckLoginTicketRes>
{
    public serialNo?: string;
    public ticket?: string;

    public constructor(init?: Partial<BabyCareCheckLoginTicketReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BabyCareCheckLoginTicketReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BabyCareCheckLoginTicketRes(); }
}

/**
* 获取学校babycare设备信息
*/
// @Route("/babycare/{SchoolId}/device/{SerialNo}", "GET, OPTIONS")
// @Api(Description="获取学校babycare设备信息")
export class GetBabyCareDeviceInfoReq implements IReturn<GetBabyCareDeviceInfoRes>
{
    public schoolId?: string;
    public serialNo?: string;

    public constructor(init?: Partial<GetBabyCareDeviceInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareDeviceInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareDeviceInfoRes(); }
}

/**
* 获取学校babycare设备BYOD授权信息
*/
// @Route("/babycare/{SchoolId}/byodlicenses", "GET, OPTIONS")
// @Api(Description="获取学校babycare设备BYOD授权信息")
export class GetBabyCareBYODDeviceLicenseReq implements IReturn<GetBabyCareBYODDeviceLicenseRes>
{
    public schoolId?: string;
    public serialNo?: string;

    public constructor(init?: Partial<GetBabyCareBYODDeviceLicenseReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareBYODDeviceLicenseReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareBYODDeviceLicenseRes(); }
}

/**
* 添加babycare设备BYOD授权
*/
// @Route("/babycare/{SchoolId}/byodlicenses", "POST, OPTIONS")
// @Api(Description="添加babycare设备BYOD授权")
export class AddBabyCareBYODDeviceLicenseReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public deviceCount?: number;
    public dayCount?: number;

    public constructor(init?: Partial<AddBabyCareBYODDeviceLicenseReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareBYODDeviceLicenseReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 绑定babycare设备BYOD授权
*/
// @Route("/babycare/{SchoolId}/byoddevices", "POST, OPTIONS")
// @Api(Description="绑定babycare设备BYOD授权")
export class AddBabyCareBYODDeviceReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public licenseId?: string;
    public serialNo?: string;
    public macAddr?: string;

    public constructor(init?: Partial<AddBabyCareBYODDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareBYODDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare设备
*/
// @Route("/babycare/{SchoolId}/devices", "POST, OPTIONS")
// @Api(Description="添加babycare设备")
export class AddBabyCareDeviceReq implements IReturn<BaseResponse>
{
    public id?: string;
    public schoolId?: string;
    public serialNo?: string;
    public macAddr?: string;
    public remark?: string;
    public liveChannelIds?: string[];

    public constructor(init?: Partial<AddBabyCareDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取已注册的babycare设备
*/
// @Route("/babycare/devices", "GET, OPTIONS")
// @Api(Description="获取已注册的babycare设备")
export class GetBabyCareDevicesReq implements IReturn<GetBabyCareDevicesRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public serialNo?: string;
    public macAddr?: string;
    public remark?: string;
    public isBYOD?: boolean;
    public osType?: OSTYPE;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetBabyCareDevicesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareDevicesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareDevicesRes(); }
}

/**
* 导入学校babycare任务列表
*/
// @Route("/babycare/{SchoolId}/tasks/import", "POST, OPTIONS")
// @Api(Description="导入学校babycare任务列表")
export class ImportBabyCareTasksReq implements IReturn<BaseResponse>
{
    public schoolId?: string;

    public constructor(init?: Partial<ImportBabyCareTasksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportBabyCareTasksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校babycare任务列表
*/
// @Route("/babycare/{SchoolId}/tasks", "GET, OPTIONS")
// @Api(Description="获取学校babycare任务列表")
export class GetBabyCareTasksReq implements IReturn<GetBabyCareTasksRes>
{
    public schoolId?: string;
    public classId?: string;
    public showTodayHistory?: boolean;
    public dateOn?: string;
    public taskName?: string;
    public isEnabled?: boolean;

    public constructor(init?: Partial<GetBabyCareTasksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareTasksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareTasksRes(); }
}

/**
* 导出学校babycare任务结果
*/
// @Route("/babycare/{SchoolId}/tasks/excel", "GET, OPTIONS")
// @Api(Description="导出学校babycare任务结果")
export class ExportBabyCareTasksReq implements IReturn<Blob>
{
    public schoolId?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<ExportBabyCareTasksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportBabyCareTasksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取学校BabyCareTaskWork数据
*/
// @Route("/babycare/{SchoolId}/caretaskworks", "GET, OPTIONS")
// @Api(Description="获取学校BabyCareTaskWork数据")
export class GetBabyCareTaskWorksReq implements IReturn<GetBabyCareTaskWorksRes>
{
    public schoolId?: string;
    public classId?: string;
    public status?: DAILYCARE_WORK_STATUS;
    public dailyCareDeviceId?: string;
    public babyCareTaskId?: string;
    public babyCareTaskFlowId?: string;
    public babyCareTaskFlowIds?: string[];
    public teacherUserInfoId?: string;
    public dateOn?: string[];
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetBabyCareTaskWorksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareTaskWorksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareTaskWorksRes(); }
}

/**
* 添加学校babycare任务
*/
// @Route("/babycare/{SchoolId}/tasks", "POST, OPTIONS")
// @Api(Description="添加学校babycare任务")
export class AddBabyCareTaskReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public babyCareTaskId?: string;
    public isEnabled?: boolean;
    public classIds?: string[];
    public studyId?: string;
    public flows?: BabyCareTaskFlowDTO[];
    public taskName?: string;

    public constructor(init?: Partial<AddBabyCareTaskReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareTaskReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加学校babycare任务子流程
*/
// @Route("/babycare/{SchoolId}/task/{BabyCareTaskId}/flows", "POST, OPTIONS")
// @Api(Description="添加学校babycare任务子流程")
export class AddBabyCareFlowReq extends BaseResponse implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public babyCareTaskId?: string;
    public flow?: BabyCareTaskFlowDTO;

    public constructor(init?: Partial<AddBabyCareFlowReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareFlowReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 执行学校babycare任务子流程
*/
// @Route("/babycare/{SchoolId}/task/{BabyCareTaskId}/dowork", "POST, OPTIONS")
// @Api(Description="执行学校babycare任务子流程")
export class AddBabyCareTaskWorkReq extends BaseResponse implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public babyCareTaskId?: string;
    public babyCareFlowId?: string;
    public dailyCareDeviceId?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareTaskWorkReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareTaskWorkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除某项babycare
*/
// @Route("/babycare/{SchoolId}/care/delete", "DELETE, OPTIONS")
// @Api(Description="删除某项babycare")
export class DeleteBabyCareReq extends BaseResponse implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public babyCareId?: string;

    public constructor(init?: Partial<DeleteBabyCareReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteBabyCareReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校班级日报数据
*/
// @Route("/babycare/report/daily/class", "GET, OPTIONS")
// @Api(Description="获取学校班级日报数据")
export class GetBabyDailyReportClassReq implements IReturn<GetBabyDailyReportClassRes>
{
    public schoolId?: string;
    public classId?: string;
    public recordDateOn?: string;

    public constructor(init?: Partial<GetBabyDailyReportClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyDailyReportClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyDailyReportClassRes(); }
}

/**
* 获取学校班级日报数据
*/
// @Route("/babycare/report/daily/class/bysign", "GET, OPTIONS")
// @Api(Description="获取学校班级日报数据")
export class GetBabyDailyReportClassBySignReq implements IReturn<GetBabyDailyReportClassRes>
{
    public schoolId?: string;
    public classId?: string;
    public dateOn?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetBabyDailyReportClassBySignReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyDailyReportClassBySignReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyDailyReportClassRes(); }
}

/**
* 获取学校班级周报数据
*/
// @Route("/babycare/report/week/class", "GET, OPTIONS")
// @Api(Description="获取学校班级周报数据")
export class GetBabyWeekReportClassReq implements IReturn<GetBabyWeekReportClassRes>
{
    public schoolId?: string;
    public classId?: string;
    public recordDateOn?: string;

    public constructor(init?: Partial<GetBabyWeekReportClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyWeekReportClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyWeekReportClassRes(); }
}

/**
* 获取学校班级周报数据
*/
// @Route("/babycare/report/week/class/bysign", "GET, OPTIONS")
// @Api(Description="获取学校班级周报数据")
export class GetBabyWeekReportClassBySignReq implements IReturn<GetBabyWeekReportClassRes>
{
    public schoolId?: string;
    public classId?: string;
    public dateOn?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetBabyWeekReportClassBySignReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyWeekReportClassBySignReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyWeekReportClassRes(); }
}

/**
* 获取宝贝日报数据
*/
// @Route("/babycare/report/daily", "GET, OPTIONS")
// @Api(Description="获取宝贝日报数据")
export class GetBabyDailyReportReq implements IReturn<GetBabyDailyReportRes>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public dateOn?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetBabyDailyReportReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyDailyReportReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyDailyReportRes(); }
}

/**
* 获取宝贝日报数据
*/
// @Route("/babycare/report/week", "GET, OPTIONS")
// @Api(Description="获取宝贝日报数据")
export class GetBabyReportWeekReq implements IReturn<GetBabyReportWeekRes>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public dateOn?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetBabyReportWeekReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyReportWeekReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyReportWeekRes(); }
}

/**
* 获取学校每日照顾报表
*/
// @Route("/babycare/report/cares/daily", "GET")
// @Api(Description="获取学校每日照顾报表")
export class GetDailyCareSchoolDailySummaryReq implements IReturn<GetDailyCareSchoolDailySummaryRes>
{
    public schoolPartnerInfoId?: string;
    public schoolId?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetDailyCareSchoolDailySummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyCareSchoolDailySummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDailyCareSchoolDailySummaryRes(); }
}

/**
* 获取学校每日流程报表
*/
// @Route("/babycare/report/flows/daily", "GET")
// @Api(Description="获取学校每日流程报表")
export class GetBabyCareTaskWorkSchoolDailySummaryReq implements IReturn<GetBabyCareTaskWorkSchoolDailySummaryRes>
{
    public schoolPartnerInfoId?: string;
    public schoolId?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetBabyCareTaskWorkSchoolDailySummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareTaskWorkSchoolDailySummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBabyCareTaskWorkSchoolDailySummaryRes(); }
}

/**
* 教师审核请假
*/
// @Route("/leaverequest/audit", "POST, OPTIONS")
// @Api(Description="教师审核请假")
export class AuditLeaveRequestReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public leaveRequestId?: string;
    public teacherUserInfoId?: string;
    public auditMsg?: string;
    public isApprove?: boolean;

    public constructor(init?: Partial<AuditLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AuditLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 教师获取请假列表
*/
// @Route("/leaverequest/teacherrequests", "GET, OPTIONS")
// @Api(Description="教师获取请假列表")
export class GetLeaveRequestByTeacherReq implements IReturn<GetLeaveRequestByTeacherRes>
{
    public schoolId?: string;
    public classId?: string;
    public auditorId?: string;
    public kidName?: string;
    public leaveEntityId?: string;
    public isTeacher?: boolean;
    public requestDate?: string;
    public type?: LEAVE_REQUEST_TYPE;
    public state?: LEAVESTATE;
    public createOn?: string[];
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetLeaveRequestByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLeaveRequestByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLeaveRequestByTeacherRes(); }
}

/**
* 教师确认用药申请
*/
// @Route("/school/{SchoolId}/healthy/mediccare/{MedicCareId}/accept", "POST, OPTIONS")
// @Api(Description="教师确认用药申请")
export class AcceptMedicCardReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public teacherUserInfoId?: string;
    public medicCareId?: string;
    public studentId?: string;
    public actualTime?: string;
    public teacherMemo?: string;
    public teacherImageItems?: EventAttachImageItemDTO[];

    public constructor(init?: Partial<AcceptMedicCardReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AcceptMedicCardReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 教师列取本班用药申请
*/
// @Route("/school/{SchoolId}/healthy/mediccares/class", "GET, OPTIONS")
// @Api(Description="教师列取本班用药申请")
export class GetClassMediccaresReq implements IReturn<GetSchoolMedicCaresRes>
{
    public schoolId?: string;
    public classId?: string;
    public pageSize?: number;
    public lastId?: string;

    public constructor(init?: Partial<GetClassMediccaresReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassMediccaresReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolMedicCaresRes(); }
}

// @Route("/system/ststoken", "POST, OPTIONS")
export class GetAliyunStsTokenV2Req implements IReturn<GetAliyunStsTokenV2Res>
{
    public schoolId?: string;
    /**
    * Keys
    */
    // @ApiMember(DataType="array", Description="Keys", IsRequired=true, Name="Keys", ParameterType="query")
    public keys: string[];

    public constructor(init?: Partial<GetAliyunStsTokenV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAliyunStsTokenV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAliyunStsTokenV2Res(); }
}


/* Options:
Date: 2020-10-27 12:10:38
Version: 5.92
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5000/api/parent

//GlobalNamespace:
MakePropertiesOptional: True
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion:
//AddDescriptionAsComments: True
//IncludeTypes:
//ExcludeTypes:
//DefaultImports:
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export class KidAttendanceBindItem
{
    public cardSerialNo: string;
    public bindTime: string;
    public kidId: string;
    public parentRelationSchoolInfoId: string;
    public parentRole: string;
    public parentUserId: number;
    public isForStudent: boolean;

    public constructor(init?: Partial<KidAttendanceBindItem>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export enum OSTYPE
{
    ANDROID = 'ANDROID',
    IOS = 'IOS',
}

export class CommonRequest implements ICommonRequest
{
    public appId?: number;
    public platform?: number;
    public osType: OSTYPE;
    public version: string;
    public buildNumber: string;

    public constructor(init?: Partial<CommonRequest>) { (Object as any).assign(this, init); }
}

export interface ICommonRequest
{
    appId?: number;
    platform?: number;
    version: string;
    buildNumber: string;
    osType: OSTYPE;
}

export enum SEMESTERTYPE
{
    SEMESTER_PKFIRST = 'SEMESTER_PKFIRST',
    SEMESTER_PKSECOND = 'SEMESTER_PKSECOND',
    SEMESTER_K1FIRST = 'SEMESTER_K1FIRST',
    SEMESTER_K1SECOND = 'SEMESTER_K1SECOND',
    SEMESTER_K2FIRST = 'SEMESTER_K2FIRST',
    SEMESTER_K2SECOND = 'SEMESTER_K2SECOND',
    SEMESTER_K3FIRST = 'SEMESTER_K3FIRST',
    SEMESTER_K3SECOND = 'SEMESTER_K3SECOND',
    SEMESTER_OTHER = 'SEMESTER_OTHER',
    SEMESTER_UNKNOW = 'SEMESTER_UNKNOW',
}

export class KidClassInfoDTO
{
    public className: string;
    public originalClassName: string;
    public classId: string;
    public currentSemester: SEMESTERTYPE;
    public hasLoveVideo: boolean;

    public constructor(init?: Partial<KidClassInfoDTO>) { (Object as any).assign(this, init); }
}

export enum GENDERTYPE
{
    MALE = 'MALE',
    FEMALE = 'FEMALE',
}

export enum KIDACCOUNTSTATE
{
    STATE_WAITAPPROVE = 'STATE_WAITAPPROVE',
    STATE_WAITLOGIN = 'STATE_WAITLOGIN',
    STATE_NORMAL = 'STATE_NORMAL',
}

export class KidBaseInfoDTO
{
    public parentRelationId: string;
    public kidId: string;
    public kidName: string;
    public classInfos: KidClassInfoDTO[];
    public schoolName: string;
    public schoolId: string;
    public avatarUrl: string;
    public gender: GENDERTYPE;
    public hobby: string;
    public birthDate?: string;
    public parentRole: string;
    public kidEntityId: string;
    public kidEntityName: string;
    public state: KIDACCOUNTSTATE;

    public constructor(init?: Partial<KidBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class ParentMyUserInfo
{
    public uid: number;
    public displayname: string;
    public fullname: string;
    public nickname: string;
    public username: string;
    public logourl: string;
    public roles: string[];
    public profileId: string;
    public imClientId: string;
    public imLoginSign: string;
    public imLoginSignNonce: string;
    public imLoginSignTimestamp: number;
    public birthdate?: string;
    public hobby: string;
    public gender: GENDERTYPE;
    public classInfos: KidClassInfoDTO[];
    public schoolName: string;
    public rongCloudToken: string;
    public rongCloudUserId: string;

    public constructor(init?: Partial<ParentMyUserInfo>) { (Object as any).assign(this, init); }
}

export class TermInfoItem
{
    public termId: number;
    public startTime: string;
    public endTime: string;

    public constructor(init?: Partial<TermInfoItem>) { (Object as any).assign(this, init); }
}

export enum CLASSGRADETYPE
{
    GRADE_PRE = 'GRADE_PRE',
    GRADE_1 = 'GRADE_1',
    GRADE_2 = 'GRADE_2',
    GRADE_3 = 'GRADE_3',
    GRADE_OTHER = 'GRADE_OTHER',
}

export class TeacherClassInfoDTO
{
    public classId: string;
    public className: string;
    public teacherRole: string;
    public originalClassName: string;

    public constructor(init?: Partial<TeacherClassInfoDTO>) { (Object as any).assign(this, init); }
}

export class TeacherInfoDTO
{
    public id: string;
    public userId: number;
    public fullName: string;
    public logoUrl: string;
    public schoolName: string;
    public schoolId: string;
    public userName: string;
    public profileId: string;
    public imClientId: string;
    public rongImClientId: string;
    public classInfos: TeacherClassInfoDTO[];
    public needPublishAudit: boolean;
    public teacherRoleIds: string[];
    public timestamp: number;
    public attendanceTemplateId: string;
    public orderIndex: number;

    public constructor(init?: Partial<TeacherInfoDTO>) { (Object as any).assign(this, init); }
}

export enum PARENT_ROLE_TYPE
{
    MAMA = 'MAMA',
    BABA = 'BABA',
    YEYE = 'YEYE',
    NAINAI = 'NAINAI',
    WAIGONG = 'WAIGONG',
    WAIPO = 'WAIPO',
    GEGE = 'GEGE',
    JIEJIE = 'JIEJIE',
    QITA = 'QITA',
}

export class ParentInfoDTO
{
    public parentRoleType: PARENT_ROLE_TYPE;
    public phoneNumber: string;

    public constructor(init?: Partial<ParentInfoDTO>) { (Object as any).assign(this, init); }
}

export enum PARENTACCOUNTSTATUS
{
    STATUS_NONE = 'STATUS_NONE',
    STATUS_INVITE = 'STATUS_INVITE',
    STATUS_LOGINED = 'STATUS_LOGINED',
    STATUS_DISABLED = 'STATUS_DISABLED',
}

export class ParentRelationClassInfoDTO
{
    public parentRelationSchoolInfoId: string;
    public profileId: string;
    public isDefault: boolean;
    public roleName: string;
    public phoneNumber: string;
    public imClientId: string;
    public rongCloudUserId: string;
    public parentId: string;
    public inviteStatus: PARENTACCOUNTSTATUS;

    public constructor(init?: Partial<ParentRelationClassInfoDTO>) { (Object as any).assign(this, init); }
}

export class KidInfoDTO
{
    public id: string;
    public name: string;
    public gender: GENDERTYPE;
    public birthDate?: string;
    public logoUrl: string;
    public createOn: string;
    public state: KIDACCOUNTSTATE;
    public primaryContact: ParentInfoDTO;
    public secondaryContact: ParentInfoDTO;
    public classInfos: KidClassInfoDTO[];
    public schoolId: string;
    public hobby: string;
    public parents: ParentRelationClassInfoDTO[];

    public constructor(init?: Partial<KidInfoDTO>) { (Object as any).assign(this, init); }
}

export class ClassInfoItemDTO
{
    public classId: string;
    public gradeType: CLASSGRADETYPE;
    public gradeTypeOnCreate: CLASSGRADETYPE;
    public createOn: string;
    public currentTermId: number;
    public currentSemesterType: SEMESTERTYPE;
    public name: string;
    public originalClassName: string;
    public schoolId: string;
    public schoolName: string;
    public logoUrl: string;
    public teacherList: TeacherInfoDTO[];
    public kidList: KidInfoDTO[];
    public courseId: string;
    public classRoomId: string;
    public capacity: number;
    public courseHour: number;
    public remark: string;
    public liveChannelIds: string[];

    public constructor(init?: Partial<ClassInfoItemDTO>) { (Object as any).assign(this, init); }
}

export class KidRemarkItemCommentDTO
{
    public parentId: string;
    public parentRole: string;
    public content: string;
    public createOn: string;

    public constructor(init?: Partial<KidRemarkItemCommentDTO>) { (Object as any).assign(this, init); }
}

export class KidRemarkItemDTO
{
    public kidId: string;
    public kidInfo: KidInfoDTO;
    public content: string;
    public comments: KidRemarkItemCommentDTO[];
    public createOn: string;
    public modifyOn?: string;

    public constructor(init?: Partial<KidRemarkItemDTO>) { (Object as any).assign(this, init); }
}

export enum EVALUATESTATE
{
    WAIT_EDIT = 'WAIT_EDIT',
    WAIT_FILL = 'WAIT_FILL',
    FILLING = 'FILLING',
    FILLED = 'FILLED',
    PUBLISHED = 'PUBLISHED',
}

export class KidRemarkDTO
{
    public id: string;
    public classId: string;
    public schoolId: string;
    public title: string;
    public semesterType: SEMESTERTYPE;
    public authorName: string;
    public remarks: KidRemarkItemDTO[];
    public createOn: string;
    public lastModifyOn?: string;
    public state: EVALUATESTATE;

    public constructor(init?: Partial<KidRemarkDTO>) { (Object as any).assign(this, init); }
}

export class AttachImageItem
{
    public rotate: number;
    public imageUri: string;
    public thumbnilUri: string;
    public resId: string;

    public constructor(init?: Partial<AttachImageItem>) { (Object as any).assign(this, init); }
}

export class AttachVideoItem
{
    public thumbnilUri: string;
    public videoUri: string;
    public resId: string;
    public playSeconds: number;
    public aspectRatio: string;

    public constructor(init?: Partial<AttachVideoItem>) { (Object as any).assign(this, init); }
}

export class AttachLinkItem
{
    public linkUri: string;
    public linkTitle: string;
    public linkIcon: string;

    public constructor(init?: Partial<AttachLinkItem>) { (Object as any).assign(this, init); }
}

export class AttachVoiceItem
{
    public resId: string;
    public publisher: string;
    public publishTime?: string;
    public voiceUri: string;
    public voiceSeconds: number;

    public constructor(init?: Partial<AttachVoiceItem>) { (Object as any).assign(this, init); }
}

export class AttachFileItem
{
    public resId: string;
    public fileUri: string;
    public fileType: string;
    public fileName: string;

    public constructor(init?: Partial<AttachFileItem>) { (Object as any).assign(this, init); }
}

export class EventAttachItem
{
    public attachImages: AttachImageItem[];
    public attachVideo: AttachVideoItem;
    public attachLink: AttachLinkItem;
    public attachVoice: AttachVoiceItem;
    public attachFile: AttachFileItem;

    public constructor(init?: Partial<EventAttachItem>) { (Object as any).assign(this, init); }
}

export class EventItemCommentItemDTO
{
    public id: string;
    public isAudio: boolean;
    public audioItem: AttachVoiceItem;
    public content: string;
    public createBy: string;
    public createByName: string;
    public createOn: string;
    public createIPAddr: string;
    public replyTo: string;
    public replyToName: string;

    public constructor(init?: Partial<EventItemCommentItemDTO>) { (Object as any).assign(this, init); }
}

export class EventLikeUserDTO
{
    public isTeacher: boolean;
    public userId: number;
    public userProfileId: string;
    public showName: string;
    public avatarUrl: string;

    public constructor(init?: Partial<EventLikeUserDTO>) { (Object as any).assign(this, init); }
}

export enum TASKATTACHTYPE
{
    ALL = 'ALL',
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
}

export enum EVENT_HOMETIME_SCOPE
{
    SCOPE_ALL = 'SCOPE_ALL',
    SCOPE_FAMILY = 'SCOPE_FAMILY',
    SCOPE_FAMILY_TEACHER = 'SCOPE_FAMILY_TEACHER',
}

export class ClassEventItem
{
    public userName: string;
    public userProfileId: string;
    public classId: string;
    public schoolId: string;
    public schoolName: string;
    public className: string;
    public userLogoUrl: string;
    public eventId: string;
    public eventType: string;
    public timeString: string;
    public timeStamp: number;
    public createOn: string;
    public updateOn: string;
    public publishTime?: string;
    public title: string;
    public content: string;
    public attachItem: EventAttachItem;
    public targetKids: string[];
    public isPartialPublish: boolean;
    public studentTotalCount: number;
    public isTruncate: boolean;
    public imageUri: string;
    public linkUri: string;
    public pageView: number;
    public themeId: string;
    public themeTitle: string;
    public kidId: string;
    public kidName: string;
    public parentId: string;
    public parentRoleName: string;
    public comments: EventItemCommentItemDTO[];
    public likes: EventLikeUserDTO[];
    public commentType: TASKATTACHTYPE;
    public isVisited: boolean;
    public isAcked: boolean;
    public termId: number;
    public semesterType: SEMESTERTYPE;
    public extraFields: { [index: string]: Object; };
    public homeTime_Scope: EVENT_HOMETIME_SCOPE;
    public needPayment: boolean;
    public isPayed: boolean;
    public paymentCourseId: string;
    public paymentPrice?: number;

    public constructor(init?: Partial<ClassEventItem>) { (Object as any).assign(this, init); }
}

export class EventAttachImageItemDTO
{
    public ossInfoId: string;
    public orderIndex: number;
    public rotate: number;

    public constructor(init?: Partial<EventAttachImageItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachVideoItemDTO
{
    public ossInfoId: string;

    public constructor(init?: Partial<EventAttachVideoItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachAudioItemDTO
{
    public ossInfoId: string;

    public constructor(init?: Partial<EventAttachAudioItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachLinkItemDTO
{
    public linkUrl: string;
    public linkTitle: string;
    public linkIcon: string;

    public constructor(init?: Partial<EventAttachLinkItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachFileItemDTO
{
    public ossInfoId: string;

    public constructor(init?: Partial<EventAttachFileItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachDTO
{
    public imageItems: EventAttachImageItemDTO[];
    public videoItem: EventAttachVideoItemDTO;
    public audioItem: EventAttachAudioItemDTO;
    public linkItem: EventAttachLinkItemDTO;
    public fileItem: EventAttachFileItemDTO;

    public constructor(init?: Partial<EventAttachDTO>) { (Object as any).assign(this, init); }
}

export enum EVENTPUBLISHSTATE
{
    STATE_ALLSEE = 'STATE_ALLSEE',
    STATE_INIT = 'STATE_INIT',
    STATE_OWNERSEE = 'STATE_OWNERSEE',
    STATE_WAITAUDIT = 'STATE_WAITAUDIT',
}

export enum EVENTAUDITSTATE
{
    NONEED_AUDIT = 'NONEED_AUDIT',
    WAIT_AUDIT = 'WAIT_AUDIT',
    FINISH_AUDITED = 'FINISH_AUDITED',
}

export class EventBaseDTO
{
    public id: string;
    public schoolId: string;
    public classId: string;
    public createOn: string;
    public updatedOn: string;
    public timeStamp: number;
    public lastModifyOn: string;
    public isDelete: boolean;
    public createBy: number;
    public createIPAddr: string;
    public deleteBy?: number;
    public deleteOn?: string;
    public deleteIPAddr: string;
    public showOn: string;
    public title: string;
    public content: string;
    public attachment: EventAttachDTO;
    public targetKids: string[];
    public isPartialPublish: boolean;
    public syncAttach: boolean;
    public publishState: EVENTPUBLISHSTATE;
    public auditState: EVENTAUDITSTATE;
    public auditByUId?: number;
    public teachingThemeId: string;
    public commentType: TASKATTACHTYPE;
    public termId: number;
    public contentTime?: string;
    public needPayment: boolean;
    public paymentCourseId: string;
    public paymentPrice?: number;
    public isVisited: boolean;
    public isAcked: boolean;
    public eventType: string;
    public kidId: string;
    public parentRelationSchoolInfoId: string;
    public teacherUserInfoId: string;
    public extraFields: { [index: string]: Object; };
    public studentTotalCount: number;
    public isCanPublish: boolean;
    public isCanUpdate: boolean;

    public constructor(init?: Partial<EventBaseDTO>) { (Object as any).assign(this, init); }
}

export class OssInfoDTO
{
    public resId: string;
    public fileUri: string;
    public thumbnilUri: string;
    public playSeconds?: number;
    public aspectRatio: string;

    public constructor(init?: Partial<OssInfoDTO>) { (Object as any).assign(this, init); }
}

export class TeacherBaseInfoDTO
{
    public id: string;
    public fullName: string;
    public phoneNumber: string;
    public logoUrl: string;
    public userProfileId: string;

    public constructor(init?: Partial<TeacherBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class ParentRelationSchoolInfoDTO
{
    public id: string;
    public kidEntityUserAuthMapId: string;
    public userId: number;
    public schoolId: string;
    public userProfileId: string;
    public createOn: string;
    public createIPAddr: string;
    public kidId: string;
    public lastModifyOn: string;
    public isDelete: boolean;

    public constructor(init?: Partial<ParentRelationSchoolInfoDTO>) { (Object as any).assign(this, init); }
}

export class StudentBaseInfoDTO
{
    public id: string;
    public name: string;
    public gender: GENDERTYPE;
    public birthDate?: string;
    public logoUrl: string;
    public primaryContact: ParentInfoDTO;
    public secondaryContact: ParentInfoDTO;
    public classIds: string[];
    public parentRelationSchoolIds: string[];

    public constructor(init?: Partial<StudentBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class KidEntityUserAuthMapDTO
{
    public id: string;
    public userId: number;
    public kidEntityId: string;
    public isDefault: boolean;
    public parentRole: string;
    public isDelete: boolean;
    public createOn: string;
    public lastModifyOn: string;
    public rongCloudUserId: string;
    public phoneNumber: string;
    public isActive: boolean;

    public constructor(init?: Partial<KidEntityUserAuthMapDTO>) { (Object as any).assign(this, init); }
}

export class TeachingThemeDTO
{
    public id: string;
    public title: string;
    public content: string;
    public createBy: number;
    public createOn: string;
    public semesterType: SEMESTERTYPE;
    public orderIndex: number;
    public classId: string;
    public schoolId: string;
    public lastModifyOn: string;

    public constructor(init?: Partial<TeachingThemeDTO>) { (Object as any).assign(this, init); }
}

export class EventFeedDTO
{
    public eventId: string;
    public kidId: string;
    public classId: string;
    public schoolId: string;
    public eventType: string;
    public timestamp: number;
    public isViewed: boolean;
    public isAcked: boolean;

    public constructor(init?: Partial<EventFeedDTO>) { (Object as any).assign(this, init); }
}

export enum NOTIFY_FEEDBACK_STATUS
{
    FEEDBACK_YUE = 'FEEDBACK_YUE',
    FEEDBACK_ZAN = 'FEEDBACK_ZAN',
    FEEDBACK_YIHUO = 'FEEDBACK_YIHUO',
}

export class ClassEventKGTimeDTO extends ClassEventItem
{

    public constructor(init?: Partial<ClassEventKGTimeDTO>) { super(init); (Object as any).assign(this, init); }
}

export class EventTaskItemDTO
{
    public id: string;
    public content: string;
    public createOn: string;
    public createIPAddr: string;
    public attachment: EventAttachItem;
    public likeUids: number[];
    public kidName: string;
    public kidId: string;
    public parentRole: string;
    public parentId: string;
    public kidAvatarUrl: string;
    public commentItems: EventItemCommentItemDTO[];

    public constructor(init?: Partial<EventTaskItemDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventTaskItem extends ClassEventItem
{
    public taskItems: EventTaskItemDTO[];
    public canSeeOther: boolean;

    public constructor(init?: Partial<ClassEventTaskItem>) { super(init); (Object as any).assign(this, init); }
}

export enum EVENTCLOCKINSTATE
{
    UNBEGIN = 'UNBEGIN',
    BEGINING = 'BEGINING',
    FINISHED = 'FINISHED',
}

export enum CLOCKIN_FREQUENCY
{
    ONEDAY = 'ONEDAY',
    TWODAY = 'TWODAY',
    WORKDAY = 'WORKDAY',
    WEEKEND = 'WEEKEND',
    CUSTOM = 'CUSTOM',
}

export class EventClockInFrequency
{
    public frequency: CLOCKIN_FREQUENCY;
    public customWeekday: number[];
    public needRemind: boolean;
    public remindAt: string;

    public constructor(init?: Partial<EventClockInFrequency>) { (Object as any).assign(this, init); }
}

export class EventClockInGroupDTO
{
    public id: string;
    public clockInDate: string;
    public timestamp: number;
    public canSubmit: boolean;
    public isToday: number;
    public dakaCount: number;

    public constructor(init?: Partial<EventClockInGroupDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventClockInItem extends ClassEventItem
{
    public canSeeOther: boolean;
    public beginTime: string;
    public endTime: string;
    public state: EVENTCLOCKINSTATE;
    public tags: string[];
    public targetTotalNum: number;
    public frequencyConfig: EventClockInFrequency;
    public eventClockInGroups: EventClockInGroupDTO[];

    public constructor(init?: Partial<ClassEventClockInItem>) { super(init); (Object as any).assign(this, init); }
}

export class EventClockInItemDTO
{
    public id: string;
    public clockInGroupId: string;
    public kidName: string;
    public kidId: string;
    public parentRole: string;
    public parentId: string;
    public kidAvatarUrl: string;
    public content: string;
    public createOn: string;
    public isDelete: boolean;
    public createBy: number;
    public createIPAddr: string;
    public deleteBy?: number;
    public deleteOn?: string;
    public deleteIPAddr: string;
    public attachment: EventAttachItem;
    public likeUids: number[];
    public commentItems: EventItemCommentItemDTO[];
    public clockInCount: number;

    public constructor(init?: Partial<EventClockInItemDTO>) { (Object as any).assign(this, init); }
}

export class BindKidItem
{
    public kidId: string;
    public kidName: string;
    public birthDate: string;
    public gender: GENDERTYPE;
    public phoneNumbers: string[];

    public constructor(init?: Partial<BindKidItem>) { (Object as any).assign(this, init); }
}

export class KidEntityItem
{
    public kidEntityId: string;
    public kidEntityName: string;
    public avatarUrl: string;
    public birthDate?: string;
    public gender: GENDERTYPE;

    public constructor(init?: Partial<KidEntityItem>) { (Object as any).assign(this, init); }
}

export class DailyHealthyDTO
{
    public id: string;
    public schoolId: string;
    public kidId: string;
    public dateOn: string;
    public temperature: string;
    public mouthHaveProblem: boolean;
    public mouthProblem: string;
    public bodyHaveProblem: boolean;
    public bodyProblem: string;
    public remark: string;
    public teacherUserInfoId: string;
    public createOn: string;
    public lastModifyOn: string;

    public constructor(init?: Partial<DailyHealthyDTO>) { (Object as any).assign(this, init); }
}

export class ClassBaseInfoDTO
{
    public id: string;
    public className: string;
    public alias: string;

    public constructor(init?: Partial<ClassBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class DailyMenuDTO
{
    public id: string;
    public schoolId: string;
    public dateOn: string;
    public title: string;
    public content1: string;
    public attachs1: EventAttachItem;
    public content2: string;
    public attachs2: EventAttachItem;
    public content3: string;
    public attachs3: EventAttachItem;
    public isPublished: boolean;
    public createOn: string;
    public lastModifyOn: string;

    public constructor(init?: Partial<DailyMenuDTO>) { (Object as any).assign(this, init); }
}

export enum ATTENDANCETYPE
{
    FULL_DAY = 'FULL_DAY',
    FORENOON = 'FORENOON',
    AFTERNOON = 'AFTERNOON',
}

export enum ATTENDANCEMETHOD
{
    ICCARD = 'ICCARD',
    AIFACE = 'AIFACE',
    QRCODE = 'QRCODE',
}

export class AttendanceKidLogDTO
{
    public schoolId: string;
    public classId: string;
    public attendanceType: ATTENDANCETYPE;
    public attendanceMethod: ATTENDANCEMETHOD;
    public isCheckIn: boolean;
    public checkUUID: string;
    public createOn: string;
    public attendanceDeviceId: string;
    public attendanceUserMapId: string;

    public constructor(init?: Partial<AttendanceKidLogDTO>) { (Object as any).assign(this, init); }
}

export class LiveVideoItem
{
    public name: string;
    public liveUrl: string;

    public constructor(init?: Partial<LiveVideoItem>) { (Object as any).assign(this, init); }
}

export class LiveVideoVisitHistory
{
    public kidId: string;
    public kidName: string;
    public parentRole: string;
    public visitOn: string;

    public constructor(init?: Partial<LiveVideoVisitHistory>) { (Object as any).assign(this, init); }
}

export class LiveMemberDTO
{
    public expireOn?: string;
    public isTrial: boolean;
    public lastPaymentOn?: string;

    public constructor(init?: Partial<LiveMemberDTO>) { (Object as any).assign(this, init); }
}

export class BookExtraDTO
{
    public id: string;
    public schoolId: string;
    public classId: string;
    public kidId: string;
    public createOn: string;
    public updatedOn: string;
    public lastModifyOn: string;
    public isDelete: boolean;
    public createBy: number;
    public createIPAddr: string;
    public deleteBy?: number;
    public deleteOn?: string;
    public deleteIPAddr: string;
    public contentTime?: string;
    public title: string;
    public content: string;
    public attachItem: EventAttachItem;
    public bookExtraConfigId: string;
    public bookExtraConfigItemId: string;

    public constructor(init?: Partial<BookExtraDTO>) { (Object as any).assign(this, init); }
}

export class IMContactItem
{
    public name: string;
    public rongCloudUserId: string;
    public avatarUrl: string;
    public classId: string;
    public schoolId: string;
    public className: string;
    public schoolName: string;
    public isTeacher: boolean;
    public teacherRole: string;
    public parentRole: string;
    public phoneNumber: string;

    public constructor(init?: Partial<IMContactItem>) { (Object as any).assign(this, init); }
}

export enum BOOKORDER_STATS
{
    ORDER_INIT = 'ORDER_INIT',
    WAIT_BUYER_PAY = 'WAIT_BUYER_PAY',
    TRADE_PAID = 'TRADE_PAID',
    WAIT_CONFIRM = 'WAIT_CONFIRM',
    WAIT_SELLER_SEND_GOODS = 'WAIT_SELLER_SEND_GOODS',
    WAIT_BUYER_CONFIRM_GOODS = 'WAIT_BUYER_CONFIRM_GOODS',
    TRADE_SUCCESS = 'TRADE_SUCCESS',
    TRADE_CLOSED = 'TRADE_CLOSED',
}

export enum BOOKORDER_TYPE
{
    GROWBOOK = 'GROWBOOK',
    EXTRABOOK = 'EXTRABOOK',
}

export enum BOOKORDER_PDFSTATS
{
    NOTREADY = 'NOTREADY',
    PROCESSING = 'PROCESSING',
    PROCESSOK = 'PROCESSOK',
    PROCESSFAIL = 'PROCESSFAIL',
}

export enum BOOKORDER_EDITION
{
    PRO = 'PRO',
    DELUX = 'DELUX',
}

export class BookOrderItemDTO
{
    public bookOrderId: string;
    public youzanOrderId: string;
    public youzanTId: string;
    public createOn: string;
    public updateOn: string;
    public orderStats: BOOKORDER_STATS;
    public orderStatsStr: string;
    public orderType: BOOKORDER_TYPE;
    public orderTypeStr: string;
    public kidId: string;
    public kidName: string;
    public termId: number;
    public classId: string;
    public className: string;
    public schoolId: string;
    public schoolName: string;
    public orderCreateByUId: number;
    public orderCreateByUserName: string;
    public createIPAddr: string;
    public bookPageCount: number;
    public price: number;
    public productName: string;
    public productImageUrl: string;
    public deliveryAddress: string;
    public receiverName: string;
    public deliveryProvince: string;
    public deliveryCity: string;
    public receiverTel: string;
    public pdfStats: BOOKORDER_PDFSTATS;
    public pdfStatsStr: string;
    public lastPdfMakeTime?: string;
    public orderDesc: string;
    public num: number;
    public payment: string;
    public totalFee: string;
    public bookEdition: BOOKORDER_EDITION;
    public bookEditionStr: string;
    public bookUrl: string;
    public bookExtraConfigId: string;

    public constructor(init?: Partial<BookOrderItemDTO>) { (Object as any).assign(this, init); }
}

export class BookPdfInfo
{
    public fileurl: string;
    public expireAt: number;
    public tinyurl: string;

    public constructor(init?: Partial<BookPdfInfo>) { (Object as any).assign(this, init); }
}

export class BookOrderConfigItemDTO
{
    public bookEdition: BOOKORDER_EDITION;
    public bookEditionStr: string;
    public bookEditionTitle: string;
    public price: string;
    public pageNumber: number;
    public editionMainBackgroundUrl: string;
    public editionDetailImageUrl: string;
    public maxNum: number;

    public constructor(init?: Partial<BookOrderConfigItemDTO>) { (Object as any).assign(this, init); }
}

export enum ORDERPRODUCT_CATALOG
{
    CLASSLIVE = 'CLASSLIVE',
    COIN = 'COIN',
    MEMBER = 'MEMBER',
}

export class OrderProductDTO
{
    public id: string;
    public schoolId: string;
    public catalog: ORDERPRODUCT_CATALOG;
    public amount: number;
    public productName: string;
    public price: number;
    public iosPrice: number;
    public iapCode: string;
    public createOn: string;
    public lastModifyOn: string;
    public isDelete: boolean;
    public deleteOn: string;

    public constructor(init?: Partial<OrderProductDTO>) { (Object as any).assign(this, init); }
}

export class PrePayWechatData
{
    public appId: string;
    public partnerId: string;
    public prepayId: string;
    public package: string;
    public nonceStr: string;
    public timestamp: number;
    public sign: string;

    public constructor(init?: Partial<PrePayWechatData>) { (Object as any).assign(this, init); }
}

export class KeyValuePair<TKey, TValue>
{
    public key: TKey;
    public value: TValue;

    public constructor(init?: Partial<KeyValuePair<TKey, TValue>>) { (Object as any).assign(this, init); }
}

export class SchoolNameDTO
{
    public name: string;
    public logoUrl: string;
    public schoolType: string;
    public province: string;
    public city: string;
    public district: string;

    public constructor(init?: Partial<SchoolNameDTO>) { (Object as any).assign(this, init); }
}

export class BookExtraConfigItemDTO
{
    public itemId: string;
    public createOn: string;
    public updatedOn: string;
    public lastModifyOn: string;
    public deleteOn?: string;
    public name: string;
    public title: string;
    public content: string;
    public backgroundUrl: string;
    public fontColor: string;
    public sortIndex: number;
    public isDelete: boolean;

    public constructor(init?: Partial<BookExtraConfigItemDTO>) { (Object as any).assign(this, init); }
}

export class BookExtraConfigDTO
{
    public id: string;
    public createOn: string;
    public updatedOn: string;
    public lastModifyOn: string;
    public deleteOn?: string;
    public name: string;
    public desc: string;
    public frontCoverUrl: string;
    public backCoverUrl: string;
    public isDelete: boolean;
    public items: BookExtraConfigItemDTO[];

    public constructor(init?: Partial<BookExtraConfigDTO>) { (Object as any).assign(this, init); }
}

export class GrowBookGroupDTO
{
    public eventId: string;
    public eventTitle: string;
    public eventContent: string;
    public eventType: string;
    public createOn: string;
    public feedTime: string;
    public createBy: string;
    public feedAttachment: EventAttachItem;

    public constructor(init?: Partial<GrowBookGroupDTO>) { (Object as any).assign(this, init); }
}

export class GrowBookInfoDTO
{
    public kidId: string;
    public kidName: string;
    public termId: number;
    public totalAmount: number;
    public teacherAmount: number;
    public momAmount: number;
    public dadAmount: number;
    public otherAmount: number;
    public frontPageUrl: string;
    public semesterType: SEMESTERTYPE;
    public items: GrowBookGroupDTO[];
    public termDateStart: string;
    public termDateEnd: string;
    public albumUrl: string;
    public pageNumber: number;

    public constructor(init?: Partial<GrowBookInfoDTO>) { (Object as any).assign(this, init); }
}

export enum EVALUATETYPE
{
    DAILY_EVALUATE = 'DAILY_EVALUATE',
    SEMESTER_EVALUATE = 'SEMESTER_EVALUATE',
}

export class EvaluateItemBaseDTO
{
    public id: string;
    public title: string;
    public subTitle: string;
    public desc: string;
    public createBy: number;
    public createByUserName: string;
    public createOn: string;
    public isGlobal: boolean;

    public constructor(init?: Partial<EvaluateItemBaseDTO>) { (Object as any).assign(this, init); }
}

export class EvaluateItemKidScoreDTO
{
    public kidId: string;
    public kidInfo: KidInfoDTO;
    public score: number;

    public constructor(init?: Partial<EvaluateItemKidScoreDTO>) { (Object as any).assign(this, init); }
}

export class EvaluateItemDTO extends EvaluateItemBaseDTO
{
    public isFinish: boolean;
    public kidScores: EvaluateItemKidScoreDTO[];

    public constructor(init?: Partial<EvaluateItemDTO>) { super(init); (Object as any).assign(this, init); }
}

export class EvaluateDTO
{
    public id: string;
    public classId: string;
    public schoolId: string;
    public title: string;
    public evaluateType: EVALUATETYPE;
    public semesterType: SEMESTERTYPE;
    public finishTime?: string;
    public createBy: number;
    public createByUserName: string;
    public createOn: string;
    public evaluateState: EVALUATESTATE;
    public publishOn?: string;
    public items: EvaluateItemDTO[];

    public constructor(init?: Partial<EvaluateDTO>) { (Object as any).assign(this, init); }
}

export class VoteItemSerial
{
    public typeName: string;
    public jsonString: string;

    public constructor(init?: Partial<VoteItemSerial>) { (Object as any).assign(this, init); }
}

export class ClassEventVoteItem extends ClassEventItem
{
    public questions: VoteItemSerial[];
    public isAnonymous: boolean;
    public canSeeResult: boolean;
    public endTime?: string;

    public constructor(init?: Partial<ClassEventVoteItem>) { super(init); (Object as any).assign(this, init); }
}

export enum FILETYPE
{
    TYPE_UNKNOW = 'TYPE_UNKNOW',
    TYPE_PERSON = 'TYPE_PERSON',
    TYPE_CLASS = 'TYPE_CLASS',
    TYPE_USERLOGO = 'TYPE_USERLOGO',
    TYPE_CLASSLOGO = 'TYPE_CLASSLOGO',
    TYPE_EVENTATTACH = 'TYPE_EVENTATTACH',
    TYPE_CHATATTACH = 'TYPE_CHATATTACH',
    TYPE_KIDLOGO = 'TYPE_KIDLOGO',
    TYPE_SCHOOLLOGO = 'TYPE_SCHOOLLOGO',
    TYPE_SCHOOLDESC = 'TYPE_SCHOOLDESC',
    TYPE_KIDENTITYLOG = 'TYPE_KIDENTITYLOG',
    TYPE_SCHOOLSHAREDISK = 'TYPE_SCHOOLSHAREDISK',
}

export class UploadFileItem
{
    public originname: string;
    public filedata: string;

    public constructor(init?: Partial<UploadFileItem>) { (Object as any).assign(this, init); }
}

export class SystemNoticeItem
{
    public id: string;
    public title: string;
    public content: string;
    public isLink: boolean;
    public createBy: string;
    public createOn: string;

    public constructor(init?: Partial<SystemNoticeItem>) { (Object as any).assign(this, init); }
}

export class SchoolBaseInfo
{
    public id: string;
    public name: string;
    public logoUrl: string;
    public loginBgUrl: string;

    public constructor(init?: Partial<SchoolBaseInfo>) { (Object as any).assign(this, init); }
}

export class GetVersionCodeData
{
    public os: OSTYPE;
    public versionName: string;
    public versionCode: string;
    public releaseNote: string;
    public isDefault: boolean;
    public isApk: boolean;
    public downloadUrl: string;
    public packageSize: string;
    public releaseOn: string;

    public constructor(init?: Partial<GetVersionCodeData>) { (Object as any).assign(this, init); }
}

export class WeatherDTOS
{
    public cityid: string;
    public city: string;
    public update_time: string;
    public date: string;
    public tem: string;
    public tem1: string;
    public tem2: string;
    public humidity: string;
    public wea: string;
    public air: string;
    public air_level: string;
    public air_tips: string;
    public win: string;
    public win_speed: string;
    public sun_level: string;
    public sun_desc: string;
    public wear_level: string;
    public wear_desc: string;

    public constructor(init?: Partial<WeatherDTOS>) { (Object as any).assign(this, init); }
}

export class VoteItemSelectedUserDTO
{
    public kidId: string;
    public parentId: string;
    public parentRole: string;
    public createOn: string;

    public constructor(init?: Partial<VoteItemSelectedUserDTO>) { (Object as any).assign(this, init); }
}

export class VoteItemItemDTO
{
    public id: string;
    public itemContent: string;
    public itemAttachment: EventAttachItem;
    public selectedInfos: VoteItemSelectedUserDTO[];

    public constructor(init?: Partial<VoteItemItemDTO>) { (Object as any).assign(this, init); }
}

export class VoteTextContentDTO
{
    public kidId: string;
    public parentId: string;
    public parentRole: string;
    public content: string;
    public createOn: string;
    public userIPAddr: string;

    public constructor(init?: Partial<VoteTextContentDTO>) { (Object as any).assign(this, init); }
}

export class BaseResponse
{
    public code: number;
    public message: string;

    public constructor(init?: Partial<BaseResponse>) { (Object as any).assign(this, init); }
}

export class AttendanceFromQrcodeRes extends BaseResponse
{

    public constructor(init?: Partial<AttendanceFromQrcodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddAttendanceBindRes extends BaseResponse
{

    public constructor(init?: Partial<AddAttendanceBindRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidAttendanceBindsRes extends BaseResponse
{
    public data: KidAttendanceBindItem[];

    public constructor(init?: Partial<GetKidAttendanceBindsRes>) { super(init); (Object as any).assign(this, init); }
}

export class CommonReturn
{
    public ret: boolean;
    public responseStatus: ResponseStatus;
    public message: string;
    public classId: string;

    public constructor(init?: Partial<CommonReturn>) { (Object as any).assign(this, init); }
}

export class GetParentKidsRes
{
    public ret: boolean;
    public kidList: KidBaseInfoDTO[];

    public constructor(init?: Partial<GetParentKidsRes>) { (Object as any).assign(this, init); }
}

export class GetParentMyClassInfoRes
{
    public ret: boolean;
    public userId: number;
    public userProfileId: string;
    public data: KidBaseInfoDTO;

    public constructor(init?: Partial<GetParentMyClassInfoRes>) { (Object as any).assign(this, init); }
}

export class GetParentMyUserInfoRes
{
    public ret: boolean;
    public data: ParentMyUserInfo;
    public termInfos: TermInfoItem[];

    public constructor(init?: Partial<GetParentMyUserInfoRes>) { (Object as any).assign(this, init); }
}

export class GetRegInfoFromTokenRes
{
    public ret: boolean;
    public message: string;
    public logoUrl: string;
    public classId: string;
    public className: string;
    public teacherName: string;
    public schoolName: string;

    public constructor(init?: Partial<GetRegInfoFromTokenRes>) { (Object as any).assign(this, init); }
}

export class GetParentClassesRes
{
    public ret: boolean;
    public data: ClassInfoItemDTO;

    public constructor(init?: Partial<GetParentClassesRes>) { (Object as any).assign(this, init); }
}

export class AddKidRemarkCommentRes extends CommonReturn
{
    public data: KidRemarkDTO;

    public constructor(init?: Partial<AddKidRemarkCommentRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassEventRes
{
    public ret: boolean;
    public message: string;
    public data: ClassEventItem;

    public constructor(init?: Partial<GetClassEventRes>) { (Object as any).assign(this, init); }
}

export class GetMultiClassEventsForParentV2Res extends BaseResponse
{
    public data: EventBaseDTO[];
    public payedEventIds: string[];
    public ossinfos: { [index: string]: OssInfoDTO; };
    public classinfos: { [index: string]: KidClassInfoDTO; };
    public teacherinfos: { [index: string]: TeacherBaseInfoDTO; };
    public parentinfos: { [index: string]: ParentRelationSchoolInfoDTO; };
    public studentinfos: { [index: string]: StudentBaseInfoDTO; };
    public kidusermaps: { [index: string]: KidEntityUserAuthMapDTO; };
    public likeinfos: { [index: string]: EventLikeUserDTO[]; };
    public commentinfos: { [index: string]: EventItemCommentItemDTO[]; };
    public themeinfos: { [index: string]: TeachingThemeDTO; };

    public constructor(init?: Partial<GetMultiClassEventsForParentV2Res>) { super(init); (Object as any).assign(this, init); }
}

export class GetMultiClassEventsForParentRes
{
    public ret: boolean;
    public message: string;
    public data: ClassEventItem[];

    public constructor(init?: Partial<GetMultiClassEventsForParentRes>) { (Object as any).assign(this, init); }
}

export class GetClassEventsFeedRes
{
    public ret: boolean;
    public message: string;
    public data: EventFeedDTO[];

    public constructor(init?: Partial<GetClassEventsFeedRes>) { (Object as any).assign(this, init); }
}

export class UpdateEventCommonRes extends CommonReturn
{
    public data: ClassEventItem;

    public constructor(init?: Partial<UpdateEventCommonRes>) { super(init); (Object as any).assign(this, init); }
}

export class AnswerEventVoteRes extends CommonReturn
{
    public data: ClassEventItem;

    public constructor(init?: Partial<AnswerEventVoteRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddEventNotifyFeedbackRes extends CommonReturn
{
    public data: ClassEventItem;

    public constructor(init?: Partial<AddEventNotifyFeedbackRes>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateEventKGTimeRes extends CommonReturn
{
    public data: ClassEventKGTimeDTO;

    public constructor(init?: Partial<UpdateEventKGTimeRes>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateEventHomeTimeRes extends CommonReturn
{
    public data: ClassEventItem;

    public constructor(init?: Partial<UpdateEventHomeTimeRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidParentsRes extends BaseResponse
{
    public data: KidEntityUserAuthMapDTO[];

    public constructor(init?: Partial<GetKidParentsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidEventTinyShareLinkRes extends BaseResponse
{
    public url: string;

    public constructor(init?: Partial<GetKidEventTinyShareLinkRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetEventTaskByIdRes
{
    public ret: boolean;
    public message: string;
    public eventitem: ClassEventTaskItem;

    public constructor(init?: Partial<GetEventTaskByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventClockInByIdRes extends CommonReturn
{
    public data: ClassEventClockInItem;

    public constructor(init?: Partial<GetEventClockInByIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetEventClockInGroupItemsByIdRes extends CommonReturn
{
    public data: EventClockInItemDTO[];

    public constructor(init?: Partial<GetEventClockInGroupItemsByIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class CheckBindRes extends BaseResponse
{
    public kiddata: BindKidItem[];
    public kidentitydata: KidEntityItem[];

    public constructor(init?: Partial<CheckBindRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyHealthyRes extends BaseResponse
{
    public data: DailyHealthyDTO;

    public constructor(init?: Partial<GetDailyHealthyRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidDailyHealthiesRes extends BaseResponse
{
    public data: DailyHealthyDTO[];
    public studentData: { [index: string]: StudentBaseInfoDTO; };
    public teacherData: { [index: string]: TeacherBaseInfoDTO; };
    public classData: { [index: string]: ClassBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolKidDailyHealthiesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyMenuRes extends BaseResponse
{
    public data: DailyMenuDTO;

    public constructor(init?: Partial<GetDailyMenuRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyMenusRes extends BaseResponse
{
    public data: DailyMenuDTO[];

    public constructor(init?: Partial<GetDailyMenusRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidAttendanceFromParentRes extends BaseResponse
{
    public data: AttendanceKidLogDTO[];

    public constructor(init?: Partial<GetKidAttendanceFromParentRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPaymentContentInfoRes extends BaseResponse
{
    public isPayed: boolean;
    public courseId: string;
    public courseName: string;
    public price: number;
    public balance: number;

    public constructor(init?: Partial<GetPaymentContentInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetParentClassLiveVideosRes extends BaseResponse
{
    public data: LiveVideoItem[];
    public visitHistory: LiveVideoVisitHistory[];
    public memberinfo: LiveMemberDTO;

    public constructor(init?: Partial<GetParentClassLiveVideosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetExtrabookItemsRes
{
    public ret: boolean;
    public message: string;
    public albumUrl: string;
    public data: BookExtraDTO[];

    public constructor(init?: Partial<GetExtrabookItemsRes>) { (Object as any).assign(this, init); }
}

export class GetIMContactsRes extends CommonReturn
{
    public contacts: IMContactItem[];

    public constructor(init?: Partial<GetIMContactsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBookOrdersRes
{
    public totalCount: number;
    public data: BookOrderItemDTO[];

    public constructor(init?: Partial<GetBookOrdersRes>) { (Object as any).assign(this, init); }
}

export class GetPdfUrlRes
{
    public ret: boolean;
    public data: BookPdfInfo;

    public constructor(init?: Partial<GetPdfUrlRes>) { (Object as any).assign(this, init); }
}

export class PrepareBookOrderRes
{
    public ret: boolean;
    public message: string;
    public needPrepare: boolean;
    public pageCount: number;
    public pageMin: number;
    public pageMax: number;
    public data: BookOrderConfigItemDTO[];

    public constructor(init?: Partial<PrepareBookOrderRes>) { (Object as any).assign(this, init); }
}

export class CreateBookOrderRes
{
    public ret: boolean;
    public message: string;
    public shopurl: string;
    public miniProgramType: number;
    public isH5: boolean;
    public noticeMessage: string;
    public shopCode: string;

    public constructor(init?: Partial<CreateBookOrderRes>) { (Object as any).assign(this, init); }
}

export class GetOrderProductsRes extends BaseResponse
{
    public data: OrderProductDTO[];

    public constructor(init?: Partial<GetOrderProductsRes>) { super(init); (Object as any).assign(this, init); }
}

export class PrePayWechatRes extends BaseResponse
{
    public data: PrePayWechatData;

    public constructor(init?: Partial<PrePayWechatRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolNameRes extends CommonReturn
{
    public data: SchoolNameDTO;

    public constructor(init?: Partial<GetSchoolNameRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetExtrabookConfigRes
{
    public ret: boolean;
    public message: string;
    public data: BookExtraConfigDTO;

    public constructor(init?: Partial<GetExtrabookConfigRes>) { (Object as any).assign(this, init); }
}

export class GetKidGrowBooksRes
{
    public ret: boolean;
    public message: string;
    public data: GrowBookInfoDTO[];

    public constructor(init?: Partial<GetKidGrowBooksRes>) { (Object as any).assign(this, init); }
}

export class AddEventTaskItemRes extends CommonReturn
{
    public data: ClassEventTaskItem;

    public constructor(init?: Partial<AddEventTaskItemRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetEventKGTimeByIdRes
{
    public ret: boolean;
    public message: string;
    public eventdto: ClassEventKGTimeDTO;

    public constructor(init?: Partial<GetEventKGTimeByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventEvaluateByIdRes
{
    public ret: boolean;
    public message: string;
    public evaluateDTO: EvaluateDTO;

    public constructor(init?: Partial<GetEventEvaluateByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEvaluatesRes
{
    public ret: boolean;
    public message: string;
    public data: EvaluateDTO[];

    public constructor(init?: Partial<GetEvaluatesRes>) { (Object as any).assign(this, init); }
}

export class GetEventKidRemarkByIdRes
{
    public ret: boolean;
    public message: string;
    public kidremarkDTO: KidRemarkDTO;

    public constructor(init?: Partial<GetEventKidRemarkByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventNotifyByIdRes
{
    public ret: boolean;
    public message: string;
    public eventitem: ClassEventItem;

    public constructor(init?: Partial<GetEventNotifyByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventShareByIdRes
{
    public ret: boolean;
    public message: string;
    public eventitem: ClassEventItem;

    public constructor(init?: Partial<GetEventShareByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventVoteByIdRes
{
    public ret: boolean;
    public message: string;
    public eventitem: ClassEventVoteItem;

    public constructor(init?: Partial<GetEventVoteByIdRes>) { (Object as any).assign(this, init); }
}

export class GetSystemNoticeRes
{
    public data: SystemNoticeItem[];

    public constructor(init?: Partial<GetSystemNoticeRes>) { (Object as any).assign(this, init); }
}

export class GetTermInfoRes
{
    public ret: boolean;
    public message: string;
    public data: TermInfoItem[];

    public constructor(init?: Partial<GetTermInfoRes>) { (Object as any).assign(this, init); }
}

export class CheckVerifyCodeRes extends BaseResponse
{
    public token: string;

    public constructor(init?: Partial<CheckVerifyCodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class SendVerifyCodeRes
{
    public ret: boolean;
    public message: string;
    public schoolInfos: SchoolBaseInfo[];

    public constructor(init?: Partial<SendVerifyCodeRes>) { (Object as any).assign(this, init); }
}

export class GetVersionCodeRes
{
    public needUpgrade: boolean;
    public isForce: boolean;
    public data: GetVersionCodeData;

    public constructor(init?: Partial<GetVersionCodeRes>) { (Object as any).assign(this, init); }
}

export class SchoolWeatherRes
{
    public ret: boolean;
    public message: string;
    public fromcache: boolean;
    public data: WeatherDTOS;

    public constructor(init?: Partial<SchoolWeatherRes>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConvertSessionToTokenResponse
{
    // @DataMember(Order=1)
    public meta: { [index: string]: string; };

    // @DataMember(Order=2)
    public accessToken: string;

    // @DataMember(Order=3)
    public refreshToken: string;

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConvertSessionToTokenResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAccessTokenResponse
{
    // @DataMember(Order=1)
    public accessToken: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAccessTokenResponse>) { (Object as any).assign(this, init); }
}

/**
* 学生二维码考勤
*/
// @Route("/attendance/attendanceparentfromqrcode", "POST, OPTIONS")
// @Api(Description="学生二维码考勤")
export class AttendanceParentFromQrcodeReq implements IReturn<AttendanceFromQrcodeRes>
{
    public serial: string;
    public kidId: string;
    public otp: string;
    public uuid: string;

    public constructor(init?: Partial<AttendanceParentFromQrcodeReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AttendanceFromQrcodeRes(); }
    public getTypeName() { return 'AttendanceParentFromQrcodeReq'; }
}

/**
* 绑卡到学生
*/
// @Route("/attendance/bindtokid", "POST, OPTIONS")
// @Api(Description="绑卡到学生")
export class AddKidAttendanceBindKidReq implements IReturn<AddAttendanceBindRes>
{
    public schoolId: string;
    public kidId: string;
    public cardSerialNo: string;
    public isForStudent: boolean;

    public constructor(init?: Partial<AddKidAttendanceBindKidReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AddAttendanceBindRes(); }
    public getTypeName() { return 'AddKidAttendanceBindKidReq'; }
}

/**
* 更新绑卡到学生
*/
// @Route("/attendance/updatebindtokid", "POST, OPTIONS")
// @Api(Description="更新绑卡到学生")
export class UpdateKidAttendanceBindKidReq implements IReturn<AddAttendanceBindRes>
{
    public schoolId: string;
    public kidId: string;
    public newCardSerialNo: string;
    public isForStudent: boolean;

    public constructor(init?: Partial<UpdateKidAttendanceBindKidReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AddAttendanceBindRes(); }
    public getTypeName() { return 'UpdateKidAttendanceBindKidReq'; }
}

/**
* 获取学生的绑卡信息
*/
// @Route("/attendance/kidbindcards", "GET, OPTIONS")
// @Api(Description="获取学生的绑卡信息")
export class GetKidAttendanceBindsReq implements IReturn<GetKidAttendanceBindsRes>
{
    public schoolId: string;
    public kidId: string;

    public constructor(init?: Partial<GetKidAttendanceBindsReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetKidAttendanceBindsRes(); }
    public getTypeName() { return 'GetKidAttendanceBindsReq'; }
}

/**
* 首次登陆完善信息
*/
// @Route("/kid/{KidId}/initdata", "POST, OPTIONS")
// @Api(Description="首次登陆完善信息")
export class InitKidDataReq implements IReturn<CommonReturn>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    /**
    * 家长角色名
    */
    // @ApiMember(DataType="string", Description="家长角色名", IsRequired=true, Name="rolename", ParameterType="query")
    public rolename: string;

    /**
    * 生日
    */
    // @ApiMember(DataType="string", Description="生日", IsRequired=true, Name="birthdate", ParameterType="query")
    public birthdate: string;

    public constructor(init?: Partial<InitKidDataReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'InitKidDataReq'; }
}

/**
* 更新孩子的兴趣爱好
*/
// @Route("/kid/{KidId}/hobby", "POST, OPTIONS")
// @Api(Description="更新孩子的兴趣爱好")
export class UpdateKidHobbyReq extends CommonRequest implements IReturn<CommonReturn>, ICommonRequest
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    /**
    * 兴趣爱好
    */
    // @ApiMember(DataType="string", Description="兴趣爱好", IsRequired=true, Name="Hobby", ParameterType="query")
    public hobby: string;

    public constructor(init?: Partial<UpdateKidHobbyReq>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'UpdateKidHobbyReq'; }
}

/**
* 更新孩子的生日
*/
// @Route("/kid/{KidId}/birthdate", "POST, OPTIONS")
// @Api(Description="更新孩子的生日")
export class UpdateKidBirthDateReq implements IReturn<CommonReturn>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    /**
    * 生日
    */
    // @ApiMember(DataType="string", Description="生日", IsRequired=true, Name="birthdate", ParameterType="query")
    public birthdate: string;

    public constructor(init?: Partial<UpdateKidBirthDateReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'UpdateKidBirthDateReq'; }
}

/**
* 获取家长名下所有孩子信息
*/
// @Route("/parent/my/kidlist", "GET, OPTIONS")
// @Api(Description="获取家长名下所有孩子信息")
export class GetParentKidsReq implements IReturn<GetParentKidsRes>
{

    public constructor(init?: Partial<GetParentKidsReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParentKidsRes(); }
    public getTypeName() { return 'GetParentKidsReq'; }
}

/**
* 获取家长的用户信息
*/
// @Route("/parent/my/classinfo/{ClassId}", "GET, OPTIONS")
// @Api(Description="获取家长的用户信息")
export class GetParentMyClassInfoReq implements IReturn<GetParentMyClassInfoRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId: string;

    public kidId: string;

    public constructor(init?: Partial<GetParentMyClassInfoReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParentMyClassInfoRes(); }
    public getTypeName() { return 'GetParentMyClassInfoReq'; }
}

/**
* 获取家长的用户信息
*/
// @Route("/parent/my/userinfo/{KidId}", "GET, OPTIONS")
// @Api(Description="获取家长的用户信息")
export class GetParentMyUserInfoReq implements IReturn<GetParentMyUserInfoRes>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    /**
    * 极光AppKey
    */
    // @ApiMember(DataType="string", Description="极光AppKey", Name="JPushAppKey", ParameterType="query")
    public jPushAppKey: string;

    /**
    * 极光设备id
    */
    // @ApiMember(DataType="string", Description="极光设备id", Name="JPushRegId", ParameterType="query")
    public jPushRegId: string;

    /**
    * MobPushAppKey
    */
    // @ApiMember(DataType="string", Description="MobPushAppKey", Name="MobPushAppKey", ParameterType="query")
    public mobPushAppKey: string;

    /**
    * MobPush设备id
    */
    // @ApiMember(DataType="string", Description="MobPush设备id", Name="MobPushRegId", ParameterType="query")
    public mobPushRegId: string;

    public constructor(init?: Partial<GetParentMyUserInfoReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParentMyUserInfoRes(); }
    public getTypeName() { return 'GetParentMyUserInfoReq'; }
}

/**
* 根据注册token查询班级信息
*/
// @Route("/class/reginfo/{Token}", "GET, OPTIONS")
// @Api(Description="根据注册token查询班级信息")
export class GetRegInfoFromTokenReq implements IReturn<GetRegInfoFromTokenRes>
{
    public token: string;

    public constructor(init?: Partial<GetRegInfoFromTokenReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetRegInfoFromTokenRes(); }
    public getTypeName() { return 'GetRegInfoFromTokenReq'; }
}

/**
* 查询自己的申请信息
*/
// @Route("/class/{ClassId}/reginfo{Token}", "GET, OPTIONS")
// @Api(Description="查询自己的申请信息")
export class GetRegInfoReq implements IReturn<GetRegInfoFromTokenRes>
{
    public classId: string;
    public component_appid: string;
    public authorizer_appid: string;
    public userOpenId: string;
    public token: string;

    public constructor(init?: Partial<GetRegInfoReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetRegInfoFromTokenRes(); }
    public getTypeName() { return 'GetRegInfoReq'; }
}

/**
* 获取注册验证码
*/
// @Route("/class/sendregparentcode", "GET, OPTIONS")
// @Api(Description="获取注册验证码")
export class GetRegMobileVerifyCodeReq implements IReturn<CommonReturn>
{
    public phoneNumber: string;

    public constructor(init?: Partial<GetRegMobileVerifyCodeReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'GetRegMobileVerifyCodeReq'; }
}

/**
* 家长注册激活
*/
// @Route("/class/{ClassId}/registerparent", "POST, OPTIONS")
// @Api(Description="家长注册激活")
export class RegisterParentReq implements IReturn<CommonReturn>
{
    public classId: string;
    public roleName: string;
    public kidName: string;
    public birthDate: string;
    public gender: GENDERTYPE;
    public avatarUrl: string;
    public authorizer_appid: string;
    public userOpenId: string;
    public phoneNumber: string;
    public verifyCode: string;
    public token: string;
    public component_appid: string;

    public constructor(init?: Partial<RegisterParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'RegisterParentReq'; }
}

/**
* 获取班级的信息
*/
// @Route("/parent/{KidId}/class", "GET, OPTIONS")
// @Api(Description="获取班级的信息")
export class GetParentClassesReq implements IReturn<GetParentClassesRes>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    public constructor(init?: Partial<GetParentClassesReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParentClassesRes(); }
    public getTypeName() { return 'GetParentClassesReq'; }
}

/**
* 家长填写评语的评论
*/
// @Route("/class/{ClassId}/kidremark/{KidRemarkId}/comment", "POST, OPTIONS")
// @Api(Description="家长填写评语的评论")
export class AddKidRemarkCommentReq implements IReturn<AddKidRemarkCommentRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId: string;

    /**
    * 评语id
    */
    // @ApiMember(DataType="string", Description="评语id", IsRequired=true, Name="KidRemarkId", ParameterType="path")
    public kidRemarkId: string;

    /**
    * kidid
    */
    // @ApiMember(DataType="string", Description="kidid", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    /**
    * 评论内容
    */
    // @ApiMember(DataType="string", Description="评论内容", IsRequired=true, Name="Content", ParameterType="query")
    public content: string;

    public constructor(init?: Partial<AddKidRemarkCommentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AddKidRemarkCommentRes(); }
    public getTypeName() { return 'AddKidRemarkCommentReq'; }
}

/**
* 获取单条消息接口
*/
// @Route("/class/event/{EventId}", "GET, OPTIONS")
// @Api(Description="获取单条消息接口")
export class GetClassEventReq implements IReturn<GetClassEventRes>
{
    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId: string;

    public constructor(init?: Partial<GetClassEventReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClassEventRes(); }
    public getTypeName() { return 'GetClassEventReq'; }
}

/**
* 搜索动态
*/
// @Route("/parent/searchevents", "GET, OPTIONS")
// @Api(Description="搜索动态")
export class SearchEventReq implements IReturn<GetMultiClassEventsForParentV2Res>
{
    /**
    * 关键字
    */
    // @ApiMember(DataType="string", Description="关键字", IsRequired=true, Name="Keyword", ParameterType="query")
    public keyword: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId: string;

    public constructor(init?: Partial<SearchEventReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetMultiClassEventsForParentV2Res(); }
    public getTypeName() { return 'SearchEventReq'; }
}

/**
* 获取家长多条消息接口V2
*/
// @Route("/v2/parent/events", "POST, OPTIONS")
// @Api(Description="获取家长多条消息接口V2")
export class GetMultiClassEventsForParentV2Req implements IReturn<GetMultiClassEventsForParentV2Res>
{
    /**
    * EventId列表
    */
    // @ApiMember(DataType="array", Description="EventId列表", IsRequired=true, Name="EventIds", ParameterType="query")
    public eventIds: string[];

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId: string;

    public constructor(init?: Partial<GetMultiClassEventsForParentV2Req>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetMultiClassEventsForParentV2Res(); }
    public getTypeName() { return 'GetMultiClassEventsForParentV2Req'; }
}

/**
* 获取家长多条消息接口
*/
// @Route("/parent/events", "POST, OPTIONS")
// @Api(Description="获取家长多条消息接口")
export class GetMultiClassEventsForParentReq implements IReturn<GetMultiClassEventsForParentRes>
{
    /**
    * EventId列表
    */
    // @ApiMember(DataType="array", Description="EventId列表", IsRequired=true, Name="EventIds", ParameterType="query")
    public eventIds: string[];

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    public constructor(init?: Partial<GetMultiClassEventsForParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetMultiClassEventsForParentRes(); }
    public getTypeName() { return 'GetMultiClassEventsForParentReq'; }
}

/**
* 获取家长动态feed列表
*/
// @Route("/feeds/parentevent", "GET, OPTIONS")
// @Api(Description="获取家长动态feed列表")
export class GetClassEventsFeedByParentReq implements IReturn<GetClassEventsFeedRes>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    public constructor(init?: Partial<GetClassEventsFeedByParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClassEventsFeedRes(); }
    public getTypeName() { return 'GetClassEventsFeedByParentReq'; }
}

/**
* 更新家庭时光
*/
// @Route("/class/event/hometimes/{EventId}", "PUT, OPTIONS")
// @Api(Description="更新家庭时光")
export class UpdateEventHomeTimeReq implements IReturn<UpdateEventCommonRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content: string;

    /**
    * 发布时间
    */
    // @ApiMember(DataType="date", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    public attachItem: EventAttachItem;

    public constructor(init?: Partial<UpdateEventHomeTimeReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateEventCommonRes(); }
    public getTypeName() { return 'UpdateEventHomeTimeReq'; }
}

/**
* 发布家庭时光
*/
// @Route("/class/event/hometimes", "POST, OPTIONS")
// @Api(Description="发布家庭时光")
export class AddEventHomeTimeReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    /**
    * 班级Id,如果为空则为全园广播消息
    */
    // @ApiMember(DataType="string", Description="班级Id,如果为空则为全园广播消息", Name="ClassId", ParameterType="query")
    public classId: string;

    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content: string;

    /**
    * 发布时间
    */
    // @ApiMember(DataType="date", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    public attachItem: EventAttachItem;
    /**
    * 可见范围
    */
    // @ApiMember(DataType="string", Description="可见范围", IsRequired=true, Name="Scope", ParameterType="query")
    public scope: EVENT_HOMETIME_SCOPE;

    public constructor(init?: Partial<AddEventHomeTimeReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'AddEventHomeTimeReq'; }
}

/**
* 投票消息家长投票
*/
// @Route("/class/event/vote/{eventid}", "POST, OPTIONS")
// @Api(Description="投票消息家长投票")
export class AnswerEventVoteReq implements IReturn<AnswerEventVoteRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    public userVoteAnswer: { [index: string]: string; };

    public constructor(init?: Partial<AnswerEventVoteReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AnswerEventVoteRes(); }
    public getTypeName() { return 'AnswerEventVoteReq'; }
}

export class AddEventViewLogReq
{
    public userId: number;
    public kidId: string;
    public eventId: string;
    public userIPAddr: string;

    public constructor(init?: Partial<AddEventViewLogReq>) { (Object as any).assign(this, init); }
}

/**
* 添加分享类消息访问日志
*/
// @Route("/class/event/share/{eventid}/viewlog", "POST, OPTIONS")
// @Api(Description="添加分享类消息访问日志")
export class AddEventShareViewLogReq implements IReturn<CommonReturn>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid: string;

    public kidId: string;

    public constructor(init?: Partial<AddEventShareViewLogReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'AddEventShareViewLogReq'; }
}

/**
* 通知消息家长确认已阅
*/
// @Route("/class/event/notify/{eventid}/feedback", "POST, OPTIONS")
// @Api(Description="通知消息家长确认已阅")
export class AddEventNotifyFeedbackReq implements IReturn<AddEventNotifyFeedbackRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid: string;

    public kidId: string;
    public status: NOTIFY_FEEDBACK_STATUS;

    public constructor(init?: Partial<AddEventNotifyFeedbackReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AddEventNotifyFeedbackRes(); }
    public getTypeName() { return 'AddEventNotifyFeedbackReq'; }
}

/**
* 家长在园时光点赞
*/
// @Route("/class/event/kgtime/{EventId}/plike", "POST, OPTIONS")
// @Api(Description="家长在园时光点赞")
export class AddEventKGTimeLikeParentReq implements IReturn<UpdateEventKGTimeRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", Name="KidId", ParameterType="query")
    public kidId: string;

    /**
    * 是点赞还是取消点赞
    */
    // @ApiMember(DataType="boolean", Description="是点赞还是取消点赞", IsRequired=true, Name="IsLike", ParameterType="query")
    public isLike: boolean;

    public constructor(init?: Partial<AddEventKGTimeLikeParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateEventKGTimeRes(); }
    public getTypeName() { return 'AddEventKGTimeLikeParentReq'; }
}

/**
* 家长家庭时光点赞
*/
// @Route("/class/event/hometime/{EventId}/plike", "POST, OPTIONS")
// @Api(Description="家长家庭时光点赞")
export class AddEventHomeTimeLikeParentReq implements IReturn<UpdateEventHomeTimeRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId: string;

    public kidId: string;
    /**
    * 是点赞还是取消点赞
    */
    // @ApiMember(DataType="boolean", Description="是点赞还是取消点赞", IsRequired=true, Name="IsLike", ParameterType="query")
    public isLike: boolean;

    public constructor(init?: Partial<AddEventHomeTimeLikeParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateEventHomeTimeRes(); }
    public getTypeName() { return 'AddEventHomeTimeLikeParentReq'; }
}

/**
* 家长在园时光添加评论
*/
// @Route("/class/event/kgtime/{EventId}/pcomment", "POST, OPTIONS")
// @Api(Description="家长在园时光添加评论")
export class AddEventKGTimeCommentParentReq implements IReturn<UpdateEventKGTimeRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", Name="KidId", ParameterType="query")
    public kidId: string;

    /**
    * 回复哪个用户id，非回复留空
    */
    // @ApiMember(DataType="string", Description="回复哪个用户id，非回复留空", IsRequired=true, Name="ReplyTo", ParameterType="query")
    public replyTo: string;

    /**
    * 是否是语音回复
    */
    // @ApiMember(DataType="boolean", Description="是否是语音回复", IsRequired=true, Name="IsAudio", ParameterType="query")
    public isAudio: boolean;

    /**
    * 文字回复内容
    */
    // @ApiMember(DataType="string", Description="文字回复内容", Name="Content", ParameterType="query")
    public content: string;

    /**
    * 语音回复对象
    */
    // @ApiMember(Description="语音回复对象", Name="AttachItem", ParameterType="query")
    public attachItem: EventAttachItem;

    public constructor(init?: Partial<AddEventKGTimeCommentParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateEventKGTimeRes(); }
    public getTypeName() { return 'AddEventKGTimeCommentParentReq'; }
}

/**
* 添加学生的其他家人账号
*/
// @Route("/parent/kidentity/{KidEntityId}/addparent", "POST, OPTIONS")
// @Api(Description="添加学生的其他家人账号")
export class AddKidOtherParentReq implements IReturn<BaseResponse>
{
    /**
    * 小孩id
    */
    // @ApiMember(DataType="string", Description="小孩id", IsRequired=true, Name="KidEntityId", ParameterType="path")
    public kidEntityId: string;

    /**
    * 家人角色名
    */
    // @ApiMember(DataType="string", Description="家人角色名", IsRequired=true, Name="RoleName", ParameterType="query")
    public roleName: string;

    /**
    * 家人手机号
    */
    // @ApiMember(DataType="string", Description="家人手机号", IsRequired=true, Name="PhoneNumber", ParameterType="query")
    public phoneNumber: string;

    public constructor(init?: Partial<AddKidOtherParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaseResponse(); }
    public getTypeName() { return 'AddKidOtherParentReq'; }
}

/**
* 获取学生的所有家人账号
*/
// @Route("/parent/kidentity/{KidEntityId}/parents", "GET, OPTIONS")
// @Api(Description="获取学生的所有家人账号")
export class GetKidParentsReq implements IReturn<GetKidParentsRes>
{
    /**
    * 小孩id
    */
    // @ApiMember(DataType="string", Description="小孩id", IsRequired=true, Name="KidEntityId", ParameterType="path")
    public kidEntityId: string;

    public constructor(init?: Partial<GetKidParentsReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetKidParentsRes(); }
    public getTypeName() { return 'GetKidParentsReq'; }
}

/**
* 删除学生的其他家人账号
*/
// @Route("/parent/kidentity/{KidEntityId}/parent", "DELETE, OPTIONS")
// @Api(Description="删除学生的其他家人账号")
export class DeleteKidOtherParentReq implements IReturn<BaseResponse>
{
    /**
    * 小孩id
    */
    // @ApiMember(DataType="string", Description="小孩id", IsRequired=true, Name="KidEntityId", ParameterType="path")
    public kidEntityId: string;

    /**
    * 家人UserId
    */
    // @ApiMember(DataType="int", Description="家人UserId", IsRequired=true, Name="UserId", ParameterType="query")
    public userId: number;

    public constructor(init?: Partial<DeleteKidOtherParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaseResponse(); }
    public getTypeName() { return 'DeleteKidOtherParentReq'; }
}

/**
* 获取用户分享的成长任务数据链接
*/
// @Route("/eventtask/sharelink/{KidId}/{TaskId}/{TaskItemId}", "POST, OPTIONS")
// @Api(Description="获取用户分享的成长任务数据链接")
export class GetKidEventTaskShareLinkReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    /**
    * TaskId
    */
    // @ApiMember(DataType="string", Description="TaskId", IsRequired=true, Name="TaskId", ParameterType="path")
    public taskId: string;

    /**
    * TaskItemId
    */
    // @ApiMember(DataType="string", Description="TaskItemId", IsRequired=true, Name="TaskItemId", ParameterType="path")
    public taskItemId: string;

    public constructor(init?: Partial<GetKidEventTaskShareLinkReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'GetKidEventTaskShareLinkReq'; }
}

/**
* 获取用户分享的短链
*/
// @Route("/event/tinysharelink/{EventId}/{KidId}", "GET, OPTIONS")
// @Api(Description="获取用户分享的短链")
export class GetKidEventTinyShareLinkReq implements IReturn<GetKidEventTinyShareLinkRes>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId: string;

    /**
    * ItemId
    */
    // @ApiMember(DataType="string", Description="ItemId", Name="ItemId", ParameterType="query")
    public itemId: string;

    public constructor(init?: Partial<GetKidEventTinyShareLinkReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetKidEventTinyShareLinkRes(); }
    public getTypeName() { return 'GetKidEventTinyShareLinkReq'; }
}

/**
* 获取用户分享的数据链接
*/
// @Route("/event/sharelink/{EventId}/{KidId}", "POST, OPTIONS")
// @Api(Description="获取用户分享的数据链接")
export class GetKidEventShareLinkReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId: string;

    /**
    * ItemId
    */
    // @ApiMember(DataType="string", Description="ItemId", Name="ItemId", ParameterType="query")
    public itemId: string;

    public constructor(init?: Partial<GetKidEventShareLinkReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'GetKidEventShareLinkReq'; }
}

/**
* 获取一条成长任务
*/
// @Route("/class/{classId}/event/task/{eventid}", "GET, OPTIONS")
// @Api(Description="获取一条成长任务")
export class GetEventTaskByIdReq implements IReturn<GetEventTaskByIdRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="classId", ParameterType="path")
    public classId: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid: string;

    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="kidId", ParameterType="query")
    public kidId: string;

    public constructor(init?: Partial<GetEventTaskByIdReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventTaskByIdRes(); }
    public getTypeName() { return 'GetEventTaskByIdReq'; }
}

/**
* 在打卡任务中添加一条打卡记录
*/
// @Route("/class/event/clockin/{ClockInId}", "POST, OPTIONS")
// @Api(Description="在打卡任务中添加一条打卡记录")
export class AddEventClockInItemReq implements IReturn<CommonReturn>
{
    public eventId: string;
    public classId: string;
    public kidId: string;
    public content: string;
    public attachItem: EventAttachItem;

    public constructor(init?: Partial<AddEventClockInItemReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'AddEventClockInItemReq'; }
}

/**
* 读取一条打卡消息
*/
// @Route("/class/event/clockin/{eventid}", "GET, OPTIONS")
// @Api(Description="读取一条打卡消息")
export class GetEventClockInByIdReq implements IReturn<GetEventClockInByIdRes>
{
    public classId: string;
    public eventId: string;
    public kidId: string;

    public constructor(init?: Partial<GetEventClockInByIdReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventClockInByIdRes(); }
    public getTypeName() { return 'GetEventClockInByIdReq'; }
}

/**
* 读取某次打卡消息
*/
// @Route("/class/event/clockin/{eventid}/{groupid}", "GET, OPTIONS")
// @Api(Description="读取某次打卡消息")
export class GetEventClockInGroupByIdReq implements IReturn<GetEventClockInGroupItemsByIdRes>
{
    public classId: string;
    public eventId: string;
    public groupId: string;
    public kidId: string;

    public constructor(init?: Partial<GetEventClockInGroupByIdReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventClockInGroupItemsByIdRes(); }
    public getTypeName() { return 'GetEventClockInGroupByIdReq'; }
}

/**
* 获取kidid中的待注册小孩
*/
// @Route("/school/checkbindkidentity", "POST, OPTIONS")
// @Api(Description="获取kidid中的待注册小孩")
export class CheckBindReq implements IReturn<CheckBindRes>
{
    /**
    * PhoneNumber
    */
    // @ApiMember(DataType="string", Description="PhoneNumber", IsRequired=true, Name="PhoneNumber", ParameterType="query")
    public phoneNumber: string;

    /**
    * Token
    */
    // @ApiMember(DataType="string", Description="Token", IsRequired=true, Name="Token", ParameterType="query")
    public token: string;

    /**
    * MToken
    */
    // @ApiMember(DataType="string", Description="MToken", IsRequired=true, Name="MToken", ParameterType="query")
    public mToken: string;

    public constructor(init?: Partial<CheckBindReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CheckBindRes(); }
    public getTypeName() { return 'CheckBindReq'; }
}

/**
* 绑定kidid中的待注册小孩
*/
// @Route("/school/bindkidentity", "POST, OPTIONS")
// @Api(Description="绑定kidid中的待注册小孩")
export class KidBindReq implements IReturn<BaseResponse>
{
    /**
    * PhoneNumber
    */
    // @ApiMember(DataType="string", Description="PhoneNumber", IsRequired=true, Name="PhoneNumber", ParameterType="query")
    public phoneNumber: string;

    /**
    * Token
    */
    // @ApiMember(DataType="string", Description="Token", IsRequired=true, Name="Token", ParameterType="query")
    public token: string;

    /**
    * MToken
    */
    // @ApiMember(DataType="string", Description="MToken", IsRequired=true, Name="MToken", ParameterType="query")
    public mToken: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    /**
    * KidEntityId
    */
    // @ApiMember(DataType="string", Description="KidEntityId", Name="KidEntityId", ParameterType="query")
    public kidEntityId: string;

    /**
    * Name
    */
    // @ApiMember(DataType="string", Description="Name", Name="Name", ParameterType="query")
    public name: string;

    /**
    * AvatarUrl
    */
    // @ApiMember(DataType="string", Description="AvatarUrl", Name="AvatarUrl", ParameterType="query")
    public avatarUrl: string;

    /**
    * BirthDate
    */
    // @ApiMember(DataType="datetime", Description="BirthDate", Name="BirthDate", ParameterType="query")
    public birthDate?: string;

    /**
    * Gender
    */
    // @ApiMember(DataType="string", Description="Gender", Name="Gender", ParameterType="query")
    public gender: GENDERTYPE;

    /**
    * ParentRole
    */
    // @ApiMember(DataType="string", Description="ParentRole", Name="ParentRole", ParameterType="query")
    public parentRole: string;

    public constructor(init?: Partial<KidBindReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaseResponse(); }
    public getTypeName() { return 'KidBindReq'; }
}

/**
* 家长单条学生的晨检数据
*/
// @Route("/school/{SchoolId}/mykidhealthy/{DailyHealthyId}", "GET, OPTIONS")
// @Api(Description="家长单条学生的晨检数据")
export class GetParentDailyHealthyReq implements IReturn<GetDailyHealthyRes>
{
    public schoolId: string;
    public kidId: string;
    public dailyHealthyId: string;

    public constructor(init?: Partial<GetParentDailyHealthyReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDailyHealthyRes(); }
    public getTypeName() { return 'GetParentDailyHealthyReq'; }
}

/**
* 家长自己学生的晨检数据
*/
// @Route("/school/{SchoolId}/mykidhealthies", "GET, OPTIONS")
// @Api(Description="家长自己学生的晨检数据")
export class GetSchoolMyKidDailyHealthiesReq implements IReturn<GetSchoolKidDailyHealthiesRes>
{
    public schoolId: string;
    public kidId: string;
    public pageSize: number;
    public lastId: string;

    public constructor(init?: Partial<GetSchoolMyKidDailyHealthiesReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSchoolKidDailyHealthiesRes(); }
    public getTypeName() { return 'GetSchoolMyKidDailyHealthiesReq'; }
}

/**
* 家长读取单条食谱
*/
// @Route("/school/{SchoolId}/dailymenuparent/{MenuId}", "GET, OPTIONS")
// @Api(Description="家长读取单条食谱")
export class GetDailyMenuFromParentReq implements IReturn<GetDailyMenuRes>
{
    public schoolId: string;
    public menuId: string;
    public kidId: string;

    public constructor(init?: Partial<GetDailyMenuFromParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDailyMenuRes(); }
    public getTypeName() { return 'GetDailyMenuFromParentReq'; }
}

/**
* 家长读取食谱列表
*/
// @Route("/school/{SchoolId}/dailymenusparent", "GET, OPTIONS")
// @Api(Description="家长读取食谱列表")
export class GetDailyMenusFromParentReq implements IReturn<GetDailyMenusRes>
{
    public schoolId: string;
    public lastReadId: string;
    public pageSize?: number;

    public constructor(init?: Partial<GetDailyMenusFromParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDailyMenusRes(); }
    public getTypeName() { return 'GetDailyMenusFromParentReq'; }
}

/**
* 家长读取考勤详情
*/
// @Route("/school/{SchoolId}/kidattendance/{EventId}", "GET, OPTIONS")
// @Api(Description="家长读取考勤详情")
export class GetKidAttendanceFromParentReq implements IReturn<GetKidAttendanceFromParentRes>
{
    public schoolId: string;
    public eventId: string;
    public kidId: string;

    public constructor(init?: Partial<GetKidAttendanceFromParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetKidAttendanceFromParentRes(); }
    public getTypeName() { return 'GetKidAttendanceFromParentReq'; }
}

/**
* 家长端确认购买内容
*/
// @Route("/paymentcontent/event/{EventId}/confirm", "POST, OPTIONS")
// @Api(Description="家长端确认购买内容")
export class BuyPaymentContentReq implements IReturn<BaseResponse>
{
    public eventId: string;
    public studentId: string;

    public constructor(init?: Partial<BuyPaymentContentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaseResponse(); }
    public getTypeName() { return 'BuyPaymentContentReq'; }
}

/**
* 家长端查看内容购买信息
*/
// @Route("/paymentcontent/event/{EventId}/info", "POST, OPTIONS")
// @Api(Description="家长端查看内容购买信息")
export class GetPaymentContentInfoReq implements IReturn<GetPaymentContentInfoRes>
{
    public eventId: string;
    public studentId: string;

    public constructor(init?: Partial<GetPaymentContentInfoReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPaymentContentInfoRes(); }
    public getTypeName() { return 'GetPaymentContentInfoReq'; }
}

/**
* 家长获取爱心视频地址列表
*/
// @Route("/live/parentlives", "GET, OPTIONS")
// @Api(Description="家长获取爱心视频地址列表")
export class GetParentClassLiveVideosReq implements IReturn<GetParentClassLiveVideosRes>
{
    public schoolId: string;
    public classId: string;
    public kidId: string;
    public pageSize?: number;

    public constructor(init?: Partial<GetParentClassLiveVideosReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParentClassLiveVideosRes(); }
    public getTypeName() { return 'GetParentClassLiveVideosReq'; }
}

/**
* 家长修改资源的角度
*/
// @Route("/growbook/KidId/{KidId}/EventId/{EventId}/Rotate", "POST, OPTIONS")
// @Api(Description="家长修改资源的角度")
export class UpdateGrowBookItemRotateReq implements IReturn<CommonReturn>
{
    public kidId: string;
    public eventId: string;
    public ossId: string;
    public rotate: number;

    public constructor(init?: Partial<UpdateGrowBookItemRotateReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'UpdateGrowBookItemRotateReq'; }
}

/**
* 家长修改资源的排序
*/
// @Route("/growbook/KidId/{KidId}/EventId/{EventId}/OrderIndex", "POST, OPTIONS")
// @Api(Description="家长修改资源的排序")
export class UpdateGrowBookItemOrderIndexReq implements IReturn<CommonReturn>
{
    public kidId: string;
    public eventId: string;
    public ossId: string;
    public newPosition: number;

    public constructor(init?: Partial<UpdateGrowBookItemOrderIndexReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'UpdateGrowBookItemOrderIndexReq'; }
}

/**
* 家长添加资源进成长册
*/
// @Route("/growbook/KidId/{KidId}", "POST, OPTIONS")
// @Api(Description="家长添加资源进成长册")
export class AddItemToGrowBookByParentReq implements IReturn<CommonReturn>
{
    public kidId: string;
    public eventId: string;
    public ossId: string;

    public constructor(init?: Partial<AddItemToGrowBookByParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'AddItemToGrowBookByParentReq'; }
}

/**
* 家长删除成长册中整个动态
*/
// @Route("/growbook/KidId/{KidId}/{EventId}", "DELETE, OPTIONS")
// @Api(Description="家长删除成长册中整个动态")
export class RemoveEventFromGrowBookByParentReq implements IReturn<CommonReturn>
{
    public kidId: string;
    public eventId: string;

    public constructor(init?: Partial<RemoveEventFromGrowBookByParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'RemoveEventFromGrowBookByParentReq'; }
}

/**
* 家长删除成长册中资源
*/
// @Route("/growbook/KidId/{KidId}", "DELETE, OPTIONS")
// @Api(Description="家长删除成长册中资源")
export class RemoveItemFromGrowBookByParentReq implements IReturn<CommonReturn>
{
    public kidId: string;
    public eventId: string;
    public ossId: string;

    public constructor(init?: Partial<RemoveItemFromGrowBookByParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'RemoveItemFromGrowBookByParentReq'; }
}

/**
* 获取用户分享的相册链接
*/
// @Route("/album/kidid/{Kidid}/sharelink", "POST, OPTIONS")
// @Api(Description="获取用户分享的相册链接")
export class GetKidAlbumShareLinkReq implements IReturn<CommonReturn>
{
    /**
    * Kidid
    */
    // @ApiMember(DataType="string", Description="Kidid", IsRequired=true, Name="Kidid", ParameterType="path")
    public kidid: string;

    /**
    * Termid
    */
    // @ApiMember(DataType="int", Description="Termid", IsRequired=true, Name="Termid", ParameterType="query")
    public termid: number;

    public constructor(init?: Partial<GetKidAlbumShareLinkReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'GetKidAlbumShareLinkReq'; }
}

/**
* 获取内容集合
*/
// @Route("/extrabook/bookitem/{KidId}/{ConfigId}", "GET, OPTIONS")
// @Api(Description="获取内容集合")
export class GetExtrabookItemsReq implements IReturn<GetExtrabookItemsRes>
{
    public configId: string;
    public kidId: string;

    public constructor(init?: Partial<GetExtrabookItemsReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetExtrabookItemsRes(); }
    public getTypeName() { return 'GetExtrabookItemsReq'; }
}

/**
* 修改内容
*/
// @Route("/extrabook/bookitems/{BookId}", "PUT, OPTIONS")
// @Api(Description="修改内容")
export class UpdateExtrabookItemReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="BookId", ParameterType="path")
    public bookId: string;

    /**
    * 内容时间
    */
    // @ApiMember(DataType="date", Description="内容时间", Name="ContentTime", ParameterType="query")
    public contentTime?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content: string;

    public attachItem: EventAttachItem;

    public constructor(init?: Partial<UpdateExtrabookItemReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'UpdateExtrabookItemReq'; }
}

/**
* 添加内容
*/
// @Route("/extrabook/bookitems", "POST, OPTIONS")
// @Api(Description="添加内容")
export class AddExtrabookItemReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", Name="ClassId", ParameterType="query")
    public classId: string;

    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId: string;

    /**
    * 内容时间
    */
    // @ApiMember(DataType="date", Description="内容时间", Name="ContentTime", ParameterType="query")
    public contentTime?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content: string;

    public attachItem: EventAttachItem;
    /**
    * BookExtraConfigId
    */
    // @ApiMember(DataType="string", Description="BookExtraConfigId", IsRequired=true, Name="BookExtraConfigId", ParameterType="query")
    public bookExtraConfigId: string;

    /**
    * BookExtraConfigItemId
    */
    // @ApiMember(DataType="string", Description="BookExtraConfigItemId", IsRequired=true, Name="BookExtraConfigItemId", ParameterType="query")
    public bookExtraConfigItemId: string;

    public constructor(init?: Partial<AddExtrabookItemReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'AddExtrabookItemReq'; }
}

/**
* 家长端获取联系人
*/
// @Route("/parent/im/contacts", "GET, OPTIONS")
// @Api(Description="家长端获取联系人")
export class GetParentIMContactsReq implements IReturn<GetIMContactsRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId: string;

    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId: string;

    public constructor(init?: Partial<GetParentIMContactsReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetIMContactsRes(); }
    public getTypeName() { return 'GetParentIMContactsReq'; }
}

/**
* 获取成长集我的订单列表
*/
// @Route("/shop/bookkidorders", "GET, OPTIONS")
// @Api(Description="获取成长集我的订单列表")
export class GetKidBookOrdersReq implements IReturn<GetBookOrdersRes>
{
    public kidId: string;

    public constructor(init?: Partial<GetKidBookOrdersReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetBookOrdersRes(); }
    public getTypeName() { return 'GetKidBookOrdersReq'; }
}

/**
* 发送kid pdf地址到邮件
*/
// @Route("/shop/sendkidpdfmail", "POST, OPTIONS")
// @Api(Description="发送kid pdf地址到邮件")
export class SendKidPdfMailReq implements IReturn<CommonReturn>
{
    public bookOrderId: string;
    public targetMail: string;

    public constructor(init?: Partial<SendKidPdfMailReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'SendKidPdfMailReq'; }
}

/**
* 获取kid pdf地址
*/
// @Route("/shop/getkidpdfurl", "GET, OPTIONS")
// @Api(Description="获取kid pdf地址")
export class GetKidPdfUrlReq implements IReturn<GetPdfUrlRes>
{
    public bookOrderId: string;

    public constructor(init?: Partial<GetKidPdfUrlReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPdfUrlRes(); }
    public getTypeName() { return 'GetKidPdfUrlReq'; }
}

/**
* 用户下单准备接口
*/
// @Route("/shop/prepareorder", "POST, OPTIONS")
// @Api(Description="用户下单准备接口")
export class PrepareBookOrderReq implements IReturn<PrepareBookOrderRes>
{
    /**
    * Kidid
    */
    // @ApiMember(DataType="string", Description="Kidid", IsRequired=true, Name="Kidid", ParameterType="query")
    public kidid: string;

    /**
    * Termid
    */
    // @ApiMember(DataType="int", Description="Termid", IsRequired=true, Name="Termid", ParameterType="query")
    public termid: number;

    /**
    * ExtraBookConfigId
    */
    // @ApiMember(DataType="string", Description="ExtraBookConfigId", IsRequired=true, Name="ExtraBookConfigId", ParameterType="query")
    public extraBookConfigId: string;

    /**
    * 类型
    */
    // @ApiMember(DataType="string", Description="类型", IsRequired=true, Name="OrderType", ParameterType="query")
    public orderType: BOOKORDER_TYPE;

    public constructor(init?: Partial<PrepareBookOrderReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new PrepareBookOrderRes(); }
    public getTypeName() { return 'PrepareBookOrderReq'; }
}

/**
* 用户下单接口
*/
// @Route("/shop/createorder", "POST, OPTIONS")
// @Api(Description="用户下单接口")
export class CreateBookOrderReq implements IReturn<CreateBookOrderRes>
{
    /**
    * Kidid
    */
    // @ApiMember(DataType="string", Description="Kidid", IsRequired=true, Name="Kidid", ParameterType="query")
    public kidid: string;

    /**
    * Termid
    */
    // @ApiMember(DataType="int", Description="Termid", IsRequired=true, Name="Termid", ParameterType="query")
    public termid: number;

    /**
    * ExtraBookConfigId
    */
    // @ApiMember(DataType="string", Description="ExtraBookConfigId", IsRequired=true, Name="ExtraBookConfigId", ParameterType="query")
    public extraBookConfigId: string;

    /**
    * 类型
    */
    // @ApiMember(DataType="string", Description="类型", IsRequired=true, Name="OrderType", ParameterType="query")
    public orderType: BOOKORDER_TYPE;

    /**
    * 型号
    */
    // @ApiMember(DataType="string", Description="型号", IsRequired=true, Name="BookEdition", ParameterType="query")
    public bookEdition: BOOKORDER_EDITION;

    /**
    * 购买数量
    */
    // @ApiMember(DataType="string", Description="购买数量", IsRequired=true, Name="Num", ParameterType="query")
    public num: number;

    public constructor(init?: Partial<CreateBookOrderReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateBookOrderRes(); }
    public getTypeName() { return 'CreateBookOrderReq'; }
}

/**
* 获取家长端支付产品信息接口
*/
// @Route("/payment/parentproducts", "GET, OPTIONS")
// @Api(Description="获取家长端支付产品信息接口")
export class GetParentOrderProductsReq implements IReturn<GetOrderProductsRes>
{
    public schoolId: string;
    public catalog: ORDERPRODUCT_CATALOG;

    public constructor(init?: Partial<GetParentOrderProductsReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetOrderProductsRes(); }
    public getTypeName() { return 'GetParentOrderProductsReq'; }
}

/**
* 微信创建订单接口
*/
// @Route("/payment/prepaywechat", "POST, OPTIONS")
// @Api(Description="微信创建订单接口")
export class PrePayWechatReq extends CommonRequest implements IReturn<PrePayWechatRes>, ICommonRequest
{
    public productId: string;
    public schoolId: string;
    public kidId: string;

    public constructor(init?: Partial<PrePayWechatReq>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PrePayWechatRes(); }
    public getTypeName() { return 'PrePayWechatReq'; }
}

/**
* IOS验证支付结果
*/
// @Route("/payment/iosverifyreceipt", "POST, OPTIONS")
// @Api(Description="IOS验证支付结果")
export class AppstoreVerifyReceiptReq extends CommonRequest implements IReturn<BaseResponse>, ICommonRequest
{
    public schoolId: string;
    public kidId: string;
    public receiptData: string;

    public constructor(init?: Partial<AppstoreVerifyReceiptReq>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new BaseResponse(); }
    public getTypeName() { return 'AppstoreVerifyReceiptReq'; }
}

export class UNKnowEventItemCommentItemDTOList
{
    public commentItems: EventItemCommentItemDTO[];

    public constructor(init?: Partial<UNKnowEventItemCommentItemDTOList>) { (Object as any).assign(this, init); }
}

export class UnKnowListEventLikeUserDTO
{
    public data: EventLikeUserDTO[];

    public constructor(init?: Partial<UnKnowListEventLikeUserDTO>) { (Object as any).assign(this, init); }
}

export class UnKnowEnumTASKATTACHTYPE
{
    public data: TASKATTACHTYPE;

    public constructor(init?: Partial<UnKnowEnumTASKATTACHTYPE>) { (Object as any).assign(this, init); }
}

export class UnKnowEnumCLASSGRADETYPE
{
    /**
    * data
    */
    // @ApiMember(DataType="string", Description="data", IsRequired=true, Name="data", ParameterType="path")
    public data: CLASSGRADETYPE;

    public constructor(init?: Partial<UnKnowEnumCLASSGRADETYPE>) { (Object as any).assign(this, init); }
}

export class UnknowAttachVideoItem
{
    public data: AttachVideoItem;
    public data2: EventAttachItem;
    public data3: AttachLinkItem;

    public constructor(init?: Partial<UnknowAttachVideoItem>) { (Object as any).assign(this, init); }
}

export class UnknowListAttachImageItem
{
    public data: AttachImageItem[];
    public data2: TeacherInfoDTO[];
    public data3: TeacherClassInfoDTO[];
    public data4: KidInfoDTO[];

    public constructor(init?: Partial<UnknowListAttachImageItem>) { (Object as any).assign(this, init); }
}

export class UserClassEventsStatItem
{
    public eventType: string;
    public count: number;

    public constructor(init?: Partial<UserClassEventsStatItem>) { (Object as any).assign(this, init); }
}

export class EventTemplateItem
{
    public id: string;
    public title: string;
    public content: string;
    public useCount: number;
    public tags: string[];
    public imageUri: string;
    public shareUrl: string;
    public attachItem: EventAttachItem;
    public contentExs: KeyValuePair<string,string>[];

    public constructor(init?: Partial<EventTemplateItem>) { (Object as any).assign(this, init); }
}

export class TeachingThemeItemDTO
{
    public id: string;
    public classId: string;
    public schoolId: string;
    public title: string;
    public content: string;
    public createUserName: string;
    public createOn: string;
    public eventsCount: number;
    public semesterType: SEMESTERTYPE;
    public semesterStr: string;

    public constructor(init?: Partial<TeachingThemeItemDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventClockInItem_ExtraFields
{
    public canSeeOther: boolean;
    public beginTime: string;
    public endTime: string;
    public state: EVENTCLOCKINSTATE;
    public tags: string[];
    public targetTotalNum: number;
    public todayGroupId: string;
    public targetTodayNum: number;
    public times: number;
    public frequencyConfig: EventClockInFrequency;

    public constructor(init?: Partial<ClassEventClockInItem_ExtraFields>) { (Object as any).assign(this, init); }
}

/**
* 创建销售线索
*/
// @Route("/saleslead/{SchoolId}", "POST, OPTIONS")
// @Api(Description="创建销售线索")
export class CreateSalesLeadByParentReq implements IReturn<BaseResponse>
{
    public schoolId: string;
    public studentName: string;
    public gender: GENDERTYPE;
    public primaryContact: ParentInfoDTO;
    public birthDate: string;
    public remark: string;
    public source: string;
    public tags: string[];

    public constructor(init?: Partial<CreateSalesLeadByParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new BaseResponse(); }
    public getTypeName() { return 'CreateSalesLeadByParentReq'; }
}

/**
* 获取园所信息
*/
// @Route("/schoolname/{SchoolId}", "GET, OPTIONS")
// @Api(Description="获取园所信息")
export class GetSchoolNameByParentReq implements IReturn<GetSchoolNameRes>
{
    public schoolId: string;

    public constructor(init?: Partial<GetSchoolNameByParentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSchoolNameRes(); }
    public getTypeName() { return 'GetSchoolNameByParentReq'; }
}

/**
* 获取配置
*/
// @Route("/extrabook/bookconfig/{ConfigId}", "GET, OPTIONS")
// @Api(Description="获取配置")
export class GetExtrabookConfigReq implements IReturn<GetExtrabookConfigRes>
{
    public configId: string;

    public constructor(init?: Partial<GetExtrabookConfigReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetExtrabookConfigRes(); }
    public getTypeName() { return 'GetExtrabookConfigReq'; }
}

/**
* 获取某个学生所有成长册详情
*/
// @Route("/growbook/KidId/{KidId}/all", "GET, OPTIONS")
// @Api(Description="获取某个学生所有成长册详情")
export class GetKidGrowBooksReq implements IReturn<GetKidGrowBooksRes>
{
    public kidId: string;

    public constructor(init?: Partial<GetKidGrowBooksReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetKidGrowBooksRes(); }
    public getTypeName() { return 'GetKidGrowBooksReq'; }
}

/**
* 成长任务中删除一个任务
*/
// @Route("/class/event/task/{TaskId}/{ItemId}", "DELETE, OPTIONS")
// @Api(Description="成长任务中删除一个任务")
export class DeleteEventTaskItemReq implements IReturn<CommonReturn>
{
    public kidId: string;
    public taskId: string;
    public itemId: string;

    public constructor(init?: Partial<DeleteEventTaskItemReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'DeleteEventTaskItemReq'; }
}

/**
* 成长任务中更新一个任务
*/
// @Route("/class/event/task/{TaskId}/{ItemId}", "POST, OPTIONS")
// @Api(Description="成长任务中更新一个任务")
export class UpdateEventTaskItemReq implements IReturn<AddEventTaskItemRes>
{
    public kidId: string;
    public taskId: string;
    public itemId: string;
    public content: string;
    public attachItem: EventAttachItem;

    public constructor(init?: Partial<UpdateEventTaskItemReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AddEventTaskItemRes(); }
    public getTypeName() { return 'UpdateEventTaskItemReq'; }
}

/**
* 成长任务中发布一个任务
*/
// @Route("/class/event/task/{TaskId}", "POST, OPTIONS")
// @Api(Description="成长任务中发布一个任务")
export class AddEventTaskItemReq implements IReturn<AddEventTaskItemRes>
{
    public kidId: string;
    public taskId: string;
    public content: string;
    public attachItem: EventAttachItem;

    public constructor(init?: Partial<AddEventTaskItemReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AddEventTaskItemRes(); }
    public getTypeName() { return 'AddEventTaskItemReq'; }
}

/**
* 给成长任务中的某个任务点赞
*/
// @Route("/class/event/task/{TaskId}/{TaskItemId}/like", "POST, OPTIONS")
// @Api(Description="给成长任务中的某个任务点赞")
export class AddEventTaskItemLikeReq implements IReturn<AddEventTaskItemRes>
{
    public kidId: string;
    public taskId: string;
    public taskItemId: string;
    public isLike: boolean;

    public constructor(init?: Partial<AddEventTaskItemLikeReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AddEventTaskItemRes(); }
    public getTypeName() { return 'AddEventTaskItemLikeReq'; }
}

/**
* 给成长任务中某个任务评论
*/
// @Route("/class/event/task/{TaskId}/{TaskItemId}/comment", "POST, OPTIONS")
// @Api(Description="给成长任务中某个任务评论")
export class AddEventTaskItemCommentReq implements IReturn<AddEventTaskItemRes>
{
    public kidId: string;
    public taskId: string;
    public taskItemId: string;
    public replyTo?: number;
    public isAudio: boolean;
    public content: string;
    public attachItem: EventAttachItem;

    public constructor(init?: Partial<AddEventTaskItemCommentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new AddEventTaskItemRes(); }
    public getTypeName() { return 'AddEventTaskItemCommentReq'; }
}

/**
* 删除一条消息
*/
// @Route("/class/{ClassId}/event/{EventId}", "DELETE, OPTIONS")
// @Api(Description="删除一条消息")
export class DeleteClassEventReq implements IReturn<CommonReturn>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId: string;

    public constructor(init?: Partial<DeleteClassEventReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'DeleteClassEventReq'; }
}

/**
* 在园时光删除评论
*/
// @Route("/class/event/kgtime/{EventId}/comment/{CommentId}", "DELETE, OPTIONS")
// @Api(Description="在园时光删除评论")
export class DeleteEventKGTimeCommentReq implements IReturn<UpdateEventKGTimeRes>
{
    public kidId: string;
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId: string;

    /**
    * 评论id
    */
    // @ApiMember(DataType="string", Description="评论id", IsRequired=true, Name="CommentId", ParameterType="path")
    public commentId: string;

    public constructor(init?: Partial<DeleteEventKGTimeCommentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateEventKGTimeRes(); }
    public getTypeName() { return 'DeleteEventKGTimeCommentReq'; }
}

/**
* 获取在园时光单条详情
*/
// @Route("/class/event/kgtime/{eventid}", "GET, OPTIONS")
// @Api(Description="获取在园时光单条详情")
export class GetEventKGTimeByIdReq implements IReturn<GetEventKGTimeByIdRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid: string;

    public constructor(init?: Partial<GetEventKGTimeByIdReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventKGTimeByIdRes(); }
    public getTypeName() { return 'GetEventKGTimeByIdReq'; }
}

/**
* 获取发展评估单条详情
*/
// @Route("/class/{ClassId}/event/evaluate/{eventid}", "GET, OPTIONS")
// @Api(Description="获取发展评估单条详情")
export class GetEventEvaluateByIdReq implements IReturn<GetEventEvaluateByIdRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid: string;

    public kidId: string;

    public constructor(init?: Partial<GetEventEvaluateByIdReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventEvaluateByIdRes(); }
    public getTypeName() { return 'GetEventEvaluateByIdReq'; }
}

/**
* 获取评估项目
*/
// @Route("/class/{ClassId}/evaluates", "GET, OPTIONS")
// @Api(Description="获取评估项目")
export class GetEvaluatesReq implements IReturn<GetEvaluatesRes>
{
    public classId: string;

    public constructor(init?: Partial<GetEvaluatesReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEvaluatesRes(); }
    public getTypeName() { return 'GetEvaluatesReq'; }
}

/**
* 获取学生评语单条详情
*/
// @Route("/class/{ClassId}/event/kidremark/{eventid}", "GET, OPTIONS")
// @Api(Description="获取学生评语单条详情")
export class GetEventKidRemarkByIdReq implements IReturn<GetEventKidRemarkByIdRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid: string;

    public kidId: string;

    public constructor(init?: Partial<GetEventKidRemarkByIdReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventKidRemarkByIdRes(); }
    public getTypeName() { return 'GetEventKidRemarkByIdReq'; }
}

/**
* 读取一条通知
*/
// @Route("/class/event/notify/{eventid}", "GET, OPTIONS")
// @Api(Description="读取一条通知")
export class GetEventNotifyByIdReq implements IReturn<GetEventNotifyByIdRes>
{
    public eventid: string;

    public constructor(init?: Partial<GetEventNotifyByIdReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventNotifyByIdRes(); }
    public getTypeName() { return 'GetEventNotifyByIdReq'; }
}

/**
* 获取一条分享
*/
// @Route("/class/event/share/{eventid}", "GET, OPTIONS")
// @Api(Description="获取一条分享")
export class GetEventShareByIdReq implements IReturn<GetEventShareByIdRes>
{
    public eventid: string;

    public constructor(init?: Partial<GetEventShareByIdReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventShareByIdRes(); }
    public getTypeName() { return 'GetEventShareByIdReq'; }
}

/**
* 读取一条投票消息
*/
// @Route("/class/event/vote/{eventid}", "GET, OPTIONS")
// @Api(Description="读取一条投票消息")
export class GetEventVoteByIdReq implements IReturn<GetEventVoteByIdRes>
{
    public eventid: string;
    public kidId: string;

    public constructor(init?: Partial<GetEventVoteByIdReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEventVoteByIdRes(); }
    public getTypeName() { return 'GetEventVoteByIdReq'; }
}

/**
* 给打卡任务中某个打卡点赞
*/
// @Route("/class/event/clockin/{ClockInId}/{ClockInItemId}/like", "POST, OPTIONS")
// @Api(Description="给打卡任务中某个打卡点赞")
export class AddEventClockInItemLikeReq implements IReturn<CommonReturn>
{
    public kidId: string;
    public clockInId: string;
    public groupId: string;
    public clockInItemId: string;
    public isLike: boolean;

    public constructor(init?: Partial<AddEventClockInItemLikeReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'AddEventClockInItemLikeReq'; }
}

/**
* 给打卡任务中某个打卡评论
*/
// @Route("/class/event/clockin/{ClockInId}/{ClockInItemId}/comment", "POST, OPTIONS")
// @Api(Description="给打卡任务中某个打卡评论")
export class AddEventClockInItemCommentReq implements IReturn<CommonReturn>
{
    public clockInId: string;
    public groupId: string;
    public clockInItemId: string;
    public replyTo?: number;
    public isAudio: boolean;
    public content: string;
    public attachItem: EventAttachItem;

    public constructor(init?: Partial<AddEventClockInItemCommentReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'AddEventClockInItemCommentReq'; }
}

/**
* 更新孩子的姓名
*/
// @Route("/kid/{KidId}/name", "POST, OPTIONS")
// @Api(Description="更新孩子的姓名")
export class UpdateKidNameReq implements IReturn<CommonReturn>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    public fullname: string;

    public constructor(init?: Partial<UpdateKidNameReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'UpdateKidNameReq'; }
}

/**
* 更新学生的联系人信息
*/
// @Route("/kid/{KidId}/contact", "POST, OPTIONS")
// @Api(Description="更新学生的联系人信息")
export class UpdateKidContactReq extends CommonRequest implements IReturn<BaseResponse>, ICommonRequest
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    public primaryContact: ParentInfoDTO;
    public secondaryContact: ParentInfoDTO;

    public constructor(init?: Partial<UpdateKidContactReq>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new BaseResponse(); }
    public getTypeName() { return 'UpdateKidContactReq'; }
}

/**
* 更新孩子的性别
*/
// @Route("/kid/{KidId}/gender", "POST, OPTIONS")
// @Api(Description="更新孩子的性别")
export class UpdateKidGenderReq extends CommonRequest implements IReturn<CommonReturn>, ICommonRequest
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId: string;

    /**
    * 性别
    */
    // @ApiMember(DataType="string", Description="性别", IsRequired=true, Name="gender", ParameterType="query")
    public gender: GENDERTYPE;

    public constructor(init?: Partial<UpdateKidGenderReq>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'UpdateKidGenderReq'; }
}

// @Route("/file/uploadmulti", "POST, OPTIONS")
export class UploadMultiFileReq implements IReturn<CommonReturn>
{
    public filetype: FILETYPE;
    public classid: string;
    public kidid: string;
    public schoolid: string;
    public files: UploadFileItem[];

    public constructor(init?: Partial<UploadMultiFileReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'UploadMultiFileReq'; }
}

// @Route("/file/upload", "POST, OPTIONS")
export class UploadFileReq implements IReturn<CommonReturn>
{
    public filetype: FILETYPE;
    public classid: string;
    public parentid?: number;
    public originname: string;

    public constructor(init?: Partial<UploadFileReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'UploadFileReq'; }
}

// @Route("/system/systemnotices", "GET, OPTIONS")
export class GetSystemNoticeReq implements IReturn<GetSystemNoticeRes>
{

    public constructor(init?: Partial<GetSystemNoticeReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSystemNoticeRes(); }
    public getTypeName() { return 'GetSystemNoticeReq'; }
}

/**
* 获取学期信息
*/
// @Route("/system/terminfos", "GET, OPTIONS")
// @Api(Description="获取学期信息")
export class GetTermInfoReq implements IReturn<GetTermInfoRes>
{

    public constructor(init?: Partial<GetTermInfoReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTermInfoRes(); }
    public getTypeName() { return 'GetTermInfoReq'; }
}

// @Route("/class/event/{eventid}", "POST, OPTIONS")
export class EventViewLogReq implements IReturn<CommonReturn>
{
    public eventid: string;

    public constructor(init?: Partial<EventViewLogReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CommonReturn(); }
    public getTypeName() { return 'EventViewLogReq'; }
}

// @Route("/system/checkverifycode", "POST, OPTIONS")
export class CheckVerifyCodeReq implements IReturn<CheckVerifyCodeRes>
{
    public phonenumber: string;
    public codetype: string;
    public code: string;

    public constructor(init?: Partial<CheckVerifyCodeReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new CheckVerifyCodeRes(); }
    public getTypeName() { return 'CheckVerifyCodeReq'; }
}

// @Route("/system/sendverifycode", "POST, OPTIONS")
export class SendVerifyCodeReq extends CommonRequest implements IReturn<SendVerifyCodeRes>, ICommonRequest
{
    public phonenumber: string;
    public codetype: string;
    public nc: string;
    public sessionid: string;
    public sig: string;
    public token: string;
    public scene: string;

    public constructor(init?: Partial<SendVerifyCodeReq>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SendVerifyCodeRes(); }
    public getTypeName() { return 'SendVerifyCodeReq'; }
}

// @Route("/system/versioninfo", "GET, OPTIONS")
export class GetVersionCodeReq implements IReturn<GetVersionCodeRes>
{
    public appid: number;
    public os: string;
    public versionname: string;
    public versioncode: string;

    public constructor(init?: Partial<GetVersionCodeReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVersionCodeRes(); }
    public getTypeName() { return 'GetVersionCodeReq'; }
}

// @Route("/school/{SchoolId}/weather", "GET, OPTIONS")
export class SchoolWeatherReq implements IReturn<SchoolWeatherRes>
{
    public schoolId: string;

    public constructor(init?: Partial<SchoolWeatherReq>) { (Object as any).assign(this, init); }
    public createResponse() { return new SchoolWeatherRes(); }
    public getTypeName() { return 'SchoolWeatherReq'; }
}

export class NewEventNotify
{
    public eventType: string;
    public classId: string;
    public className: string;
    public schoolName: string;
    public teacherName: string;
    public title: string;
    public eventId: string;

    public constructor(init?: Partial<NewEventNotify>) { (Object as any).assign(this, init); }
}

export class ClassInfoUpdateNotify
{
    public classId: string;

    public constructor(init?: Partial<ClassInfoUpdateNotify>) { (Object as any).assign(this, init); }
}

export class VoteItemBaseDTO
{
    public id: string;
    public titleAttachment: EventAttachItem;
    public title: string;
    public isRequired: boolean;

    public constructor(init?: Partial<VoteItemBaseDTO>) { (Object as any).assign(this, init); }
}

export class VoteItemSingleDTO extends VoteItemBaseDTO
{
    public selectItems: VoteItemItemDTO[];

    public constructor(init?: Partial<VoteItemSingleDTO>) { super(init); (Object as any).assign(this, init); }
}

export class VoteItemMultiDTO extends VoteItemBaseDTO
{
    public selectItems: VoteItemItemDTO[];
    public maxNumbers?: number;

    public constructor(init?: Partial<VoteItemMultiDTO>) { super(init); (Object as any).assign(this, init); }
}

export class VoteItemTextDTO extends VoteItemBaseDTO
{
    public userInputed: VoteTextContentDTO[];

    public constructor(init?: Partial<VoteItemTextDTO>) { super(init); (Object as any).assign(this, init); }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/session-to-token")
// @DataContract
export class ConvertSessionToToken implements IReturn<ConvertSessionToTokenResponse>, IPost
{
    // @DataMember(Order=1)
    public preserveSession: boolean;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ConvertSessionToToken>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConvertSessionToTokenResponse(); }
    public getTypeName() { return 'ConvertSessionToToken'; }
}

// @Route("/access-token")
// @DataContract
export class GetAccessToken implements IReturn<GetAccessTokenResponse>, IPost
{
    // @DataMember(Order=1)
    public refreshToken: string;

    // @DataMember(Order=2)
    public useTokenCookie?: boolean;

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetAccessToken>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAccessTokenResponse(); }
    public getTypeName() { return 'GetAccessToken'; }
}


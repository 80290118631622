import {
  loadSessionStorage,
  loadStorage,
  // loadCookie,
  LOGIN_STATUS,
  ACCESS_TOKEN,
  USER_INFO,
  OPEN_ID,
  API_TOKEN,
  PAGE_ATTRS,
  PAGE_DATA,
  SESSION_DATA,
  CACHE_DATA
} from "../utils/cache.js";

const state = {
  loginStatus: Number(loadSessionStorage(LOGIN_STATUS, 0)),
  accessToken: loadStorage(ACCESS_TOKEN, ""),
  openId: loadStorage(OPEN_ID, ""),
  userInfo: loadStorage(USER_INFO, {}),
  apiToken: loadStorage(API_TOKEN, ""),
  pageAttrs: loadSessionStorage(PAGE_ATTRS, {}),
  pageData: loadSessionStorage(PAGE_DATA, {}),

  sessionData: loadSessionStorage(SESSION_DATA, {
    parentKids: [],
    parentSchoolKids: []
  }),
  cacheData: loadStorage(CACHE_DATA, {
    parentKidId: "",
    parentSchoolId: "",
    parentKidInfo: null
  })
};

export default state;

<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      vConsole: null,
    }
  },
  async created() {
    // console.log('app created');
    // const { debug } = this.$route.meta
    // if (debug) {
      // await this.initvConsole()
    // }
  },
  // mounted() {
  // },
  destroyed() {
    if (this.vConsole) {
      this.vConsole.destroy()
    }
  },
  methods: {
    initvConsole() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      return new Promise((resolve, reject) => {
        try {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = 'https://unpkg.com/vconsole/dist/vconsole.min.js'
          script.onload = function () {
            that.vConsole = new window.VConsole({ maxLogNumber: 1000 })
            resolve()
          }
          document.head.appendChild(script)
        } catch (err) {
          reject(err)
        }
      })
    },
  },
}
</script>
<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>

import { apiGet, apiPost } from "@/api/yoyo.client";
import {
  GetKidEventTaskShareDataReq,
  GetKidEventShareDataReq,
  GetSchoolMiniSiteReq
} from "@/api/ex.dtos";

import {
  GetAliyunStsTokenV2Req,
  GetAliyunStsTokenReq
} from "@/api/common.dtos";

import {
  NOTIFY_FEEDBACK_STATUS as _NOTIFY_FEEDBACK_STATUS,
  SendVerifyCodeReq,
  CheckVerifyCodeReq,
  GetRegMobileVerifyCodeReq,
  GetRegInfoFromTokenReq,
  GetRegInfoReq,
  RegisterParentReq,
  GetParentMyClassInfoReq,
  GetMultiClassEventsForParentReq,
  GetEventTaskByIdReq,
  AddEventTaskItemLikeReq,
  AddEventTaskItemReq,
  UpdateEventTaskItemReq,
  GetEventEvaluateByIdReq,
  GetEventKidRemarkByIdReq,
  GetEventVoteByIdReq,
  AnswerEventVoteReq,
  GetEventClockInByIdReq,
  GetEventClockInGroupByIdReq,
  AddEventClockInItemLikeReq,
  AddEventClockInItemReq,
  AddEventKGTimeLikeParentReq,
  AddEventKGTimeCommentParentReq,
  AddKidRemarkCommentReq,
  AddEventNotifyFeedbackReq,
  CheckBindReq,
  KidBindReq,
  CreateSalesLeadByParentReq,
  GetSchoolNameByParentReq,
  GetParentKidsReq,
  GetClassEventsFeedByParentReq,
  GetMultiClassEventsForParentV2Req,
  GetParentMyUserInfoReq,
  AddEventHomeTimeLikeParentReq,
  GetEventKGTimeByIdReq,
  GetKidGrowBooksReq,
  RemoveItemFromGrowBookByParentReq,
  AddItemToGrowBookByParentReq
} from "@/api/parent.dtos";

import {
  GetBabyDailyReportReq,
  GetBabyReportWeekReq,
  GetBabyDailyReportClassBySignReq,
  GetBabyWeekReportClassBySignReq
} from "@/api/babycare.dtos";

import {
  AddPublicityReq,
  FindPublicityReq,
  GetPublicityListReq,
  CheckUrlReq,
  GetUploadTaskReq,
  GetUploadTaskSignedUrlReq,
  GetManQuKidNoteBySignReq
} from "@/api/h5.dtos";
import {
  GetWechatComponentAppIdBySchoolReq,
  WechatComponentBindReq,
  GetMarketingPagesReq,
  GetClassTimesForParentReq,
  GetKidCourseBalanceReq,
  GetCourseConsumLogsForParentReq,
  GetPageViewCountReq,
  GetLiveClassRoomShareDataReq,
  HuifuCreateJsPayReq,
  GetSchoolInfoByHuifuDeviceIdReq,
  GetCashOrderReq,
  HuifuCreateCashOrderPayReq
} from "@/api/wechat.dtos";

export const NOTIFY_FEEDBACK_STATUS = _NOTIFY_FEEDBACK_STATUS;

export const SEMESTERTYPE = {
  SEMESTER_PKFIRST: "托班上学期",
  SEMESTER_PKSECOND: "托班下学期",
  SEMESTER_K1FIRST: "小班上学期",
  SEMESTER_K1SECOND: "小班下学期",
  SEMESTER_K2FIRST: "中班上学期",
  SEMESTER_K2SECOND: "中班下学期",
  SEMESTER_K3FIRST: "大班上学期",
  SEMESTER_K3SECOND: "大班下学期",
  SEMESTER_OTHER: "其他"
};

export const SendVerifyCode = async params => {
  const req = new SendVerifyCodeReq(params);
  const res = await apiPost(req);
  if (!res.ret && !res.message) {
    res.message = "发送失败";
  }
  return res;
};

export const CheckVerifyCode = async params => {
  const req = new CheckVerifyCodeReq(params);
  const res = await apiPost(req);
  res.ret = res.code === 200;
  if (!res.ret && !res.message) {
    res.message = "验证失败";
  }
  return res;
};

export const GetAliyunStsTokenV2 = async params => {
  const req = new GetAliyunStsTokenV2Req(params);
  const res = await apiPost(req);
  return res;
};

export const GetAliyunStsToken = async params => {
  const req = new GetAliyunStsTokenReq(params);
  const res = await apiPost(req);
  return res;
};

export const GetKidEventShareData = async params => {
  const req = new GetKidEventShareDataReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetKidEventTaskShareData = async params => {
  const req = new GetKidEventTaskShareDataReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetRegMobileVerifyCode = async params => {
  const req = new GetRegMobileVerifyCodeReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetRegInfoFromToken = async params => {
  const req = new GetRegInfoFromTokenReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetRegInfo = async params => {
  const req = new GetRegInfoReq(params);
  const res = await apiGet(req);
  return res;
};

export const RegisterParent = async params => {
  const req = new RegisterParentReq(params);
  const res = await apiPost(req);
  return res;
};

export const GetMultiClassEventsForParent = async params => {
  const req = new GetMultiClassEventsForParentReq(params);
  const res = await apiPost(req);
  return res;
};

export const GetParentMyClassInfo = async params => {
  const req = new GetParentMyClassInfoReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetEventTaskById = async params => {
  const req = new GetEventTaskByIdReq(params);
  const res = await apiGet(req);
  return res;
};
export const AddEventTaskItem = async params => {
  const req = new AddEventTaskItemReq(params);
  const res = await apiPost(req);
  return res;
};
export const UpdateEventTaskItem = async params => {
  const req = new UpdateEventTaskItemReq(params);
  const res = await apiPost(req);
  return res;
};

export const GetEventEvaluateById = async params => {
  const req = new GetEventEvaluateByIdReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetEventKidRemarkById = async params => {
  const req = new GetEventKidRemarkByIdReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetEventVoteById = async params => {
  const req = new GetEventVoteByIdReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetEventClockInById = async params => {
  const req = new GetEventClockInByIdReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetEventClockInGroupById = async params => {
  const req = new GetEventClockInGroupByIdReq(params);
  const res = await apiGet(req);
  return res;
};

export const AddEventKGTimeLikeParent = async params => {
  const req = new AddEventKGTimeLikeParentReq(params);
  const res = await apiPost(req);
  return res;
};

export const AddEventKGTimeCommentParent = async params => {
  const req = new AddEventKGTimeCommentParentReq(params);
  const res = await apiPost(req);
  return res;
};

export const AddEventTaskItemLike = async params => {
  const req = new AddEventTaskItemLikeReq(params);
  const res = await apiPost(req);
  return res;
};

export const AddEventClockInItemLike = async params => {
  const req = new AddEventClockInItemLikeReq(params);
  const res = await apiPost(req);
  return res;
};

export const AddKidRemarkComment = async params => {
  const req = new AddKidRemarkCommentReq(params);
  const res = await apiPost(req);
  return res;
};

export const AnswerEventVote = async params => {
  const req = new AnswerEventVoteReq(params);
  const res = await apiPost(req);
  return res;
};

export const AddEventNotifyFeedback = async params => {
  const req = new AddEventNotifyFeedbackReq(params);
  const res = await apiPost(req);
  return res;
};

export const AddEventClockInItem = async params => {
  const req = new AddEventClockInItemReq(params);
  const res = await apiPost(req);
  return res;
};

export const CheckBind = async params => {
  const req = new CheckBindReq(params);
  const res = await apiPost(req);
  res.ret = res.code === 200;
  if (!res.ret && !res.message) {
    res.message = "验证失败";
  }
  return res;
};
export const KidBind = async params => {
  const req = new KidBindReq(params);
  const res = await apiPost(req);
  res.ret = res.code === 200;
  if (!res.ret && !res.message) {
    res.message = "绑定失败";
  }
  return res;
};
export const CreateSalesLeadByParent = async params => {
  const req = new CreateSalesLeadByParentReq(params);
  const res = await apiPost(req);
  return res;
};

export const GetSchoolNameByParent = async params => {
  const req = new GetSchoolNameByParentReq(params);
  const res = await apiPost(req);
  return res;
};
export const GetParentKids = async params => {
  const { schoolId, ...rest } = params;
  const req = new GetParentKidsReq({
    schoolId: schoolId === "000000000000000000000000" ? null : schoolId,
    ...rest
  });
  const res = await apiPost(req);
  return res;
};
export const GetClassEventsFeedByParent = async params => {
  const req = new GetClassEventsFeedByParentReq(params);
  const res = await apiPost(req);
  return res;
};
export const GetMultiClassEventsForParentV2 = async params => {
  const req = new GetMultiClassEventsForParentV2Req(params);
  const res = await apiPost(req);
  return res;
};
export const GetParentMyUserInfo = async params => {
  const req = new GetParentMyUserInfoReq(params);
  const res = await apiPost(req);
  return res;
};
export const AddEventHomeTimeLikeParent = async params => {
  const req = new AddEventHomeTimeLikeParentReq(params);
  const res = await apiPost(req);
  return res;
};
export const GetEventKGTimeById = async params => {
  const req = new GetEventKGTimeByIdReq(params);
  const res = await apiPost(req);
  return res;
};
export const GetKidGrowBooks = async params => {
  const req = new GetKidGrowBooksReq(params);
  const res = await apiPost(req);
  return res;
};
export const RemoveItemFromGrowBookByParent = async params => {
  const req = new RemoveItemFromGrowBookByParentReq(params);
  const res = await apiPost(req);
  return res;
};
export const AddItemToGrowBookByParent = async params => {
  const req = new AddItemToGrowBookByParentReq(params);
  const res = await apiPost(req);
  return res;
};
export const GetSchoolMiniSite = async params => {
  const req = new GetSchoolMiniSiteReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetBabyDailyReport = async params => {
  const req = new GetBabyDailyReportReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetBabyReportWeek = async params => {
  const req = new GetBabyReportWeekReq(params);
  const res = await apiGet(req);
  return res;
};
export const GetBabyDailyReportClassBySign = async params => {
  const req = new GetBabyDailyReportClassBySignReq(params);
  const res = await apiGet(req);
  return res;
};
export const GetBabyWeekReportClassBySign = async params => {
  const req = new GetBabyWeekReportClassBySignReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetCashOrder = async params => {
  const req = new GetCashOrderReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetSchoolInfoByHuifuDeviceId = async params => {
  const req = new GetSchoolInfoByHuifuDeviceIdReq(params);
  const res = await apiGet(req);
  return res;
};

export const HuifuCreateCashOrderPay = async params => {
  const req = new HuifuCreateCashOrderPayReq(params);
  const res = await apiPost(req);
  return res;
};

export const HuifuCreateJsPay = async params => {
  const req = new HuifuCreateJsPayReq(params);
  const res = await apiPost(req);
  return res;
};

export const AddPublicity = async params => {
  // 统一处理本地缓存的pageId
  const { data, ...restParams } = params;
  const { id, draftId, ...restPage } = data;
  const playload = { ...restParams, data: { ...restPage, id: draftId || id } };
  const req = new AddPublicityReq(playload);
  const res = await apiPost(req);
  return res;
};

export const FindPublicity = async params => {
  const req = new FindPublicityReq(params);
  const res = await apiGet(req);
  return res;
};

export const GetPublicityList = async params => {
  const req = new GetPublicityListReq(params);
  const res = await apiGet(req);
  return res;
};
export const CheckUrl = async params => {
  const req = new CheckUrlReq(params);
  const res = await apiPost(req);
  return res;
};

export const GetWechatComponentAppIdBySchool = async params => {
  const req = new GetWechatComponentAppIdBySchoolReq(params);
  const res = await apiGet(req);
  return res;
};

export const WechatComponentBind = async params => {
  const req = new WechatComponentBindReq(params);
  const res = await apiPost(req);
  return res;
};

/**
 * 获取学校营销列表
 */
export const GetMarketingPages = async (payload, appId) => {
  const req = new GetMarketingPagesReq(payload);
  const res = await apiGet(req, appId);
  return res;
};

/**
 * 家长获取学生的所有课时
 */
export const GetClassTimesForParent = async payload => {
  const req = new GetClassTimesForParentReq(payload);
  const res = await apiGet(req);
  return res;
};

/**
 * 获取学生课程的课时剩余信息
 */
export const GetKidCourseBalance = async payload => {
  const req = new GetKidCourseBalanceReq(payload);
  const res = await apiGet(req);
  return res;
};

/**
 * 获取课消记录列表
 */
export const GetCourseConsumLogsForParent = async payload => {
  const req = new GetCourseConsumLogsForParentReq(payload);
  const res = await apiGet(req);
  return res;
};

/**
 * 页面计数
 */
export const GetPageViewCount = async payload => {
  const req = new GetPageViewCountReq(payload);
  const res = await apiGet(req);
  return res;
};

/**
 * 获取直播课程分享数据
 */
export const GetLiveClassRoomShareData = async payload => {
  const req = new GetLiveClassRoomShareDataReq(payload);
  const res = await apiGet(req);
  return res;
};

/**
 * 获取二维码上传的任务信息
 */
export const GetUploadTask = async payload => {
  const req = new GetUploadTaskReq(payload);
  const res = await apiGet(req);
  return res;
};

/**
 * 获取二维码上传的文件签名
 */
export const GetUploadTaskSignedUrl = async payload => {
  const req = new GetUploadTaskSignedUrlReq(payload);
  const res = await apiGet(req);
  return res;
};

export const GetManQuKidNoteBySign = async payload => {
  const req = new GetManQuKidNoteBySignReq(payload);
  const res = await apiGet(req);
  return res;
};

import axios from 'axios'
import store from '@/store'
import { Toast } from 'vant'

const service = axios.create({
  baseURL: process.env.VUE_APP_WECHAT_AUTH_API_URL,
  timeout: 10000
});

// http请求拦截器
service.interceptors.request.use(config => {
  config.headers["x-appid"] = 2 // 家长端请求
  const { accessToken } = store.getters
  if (accessToken) config.headers.Authorization = `JWT  ${accessToken}`
  return config
}, error => {
  return Promise.reject(error)
})

// http响应拦截器
service.interceptors.response.use(response => {
  const res = response.data
  if (response.status === 200) return res;
  // console.log(res)
  if (res.responseStatus.errorCode === '200') {
    return res
  } else {
    if (res.responseStatus.errorCode === '401' || res.responseStatus.errorCode === '403') {
      store.dispatch('setLoginStatus', 0)
      // 重新登录
      window.location.reload()
    } else {
      Toast({
        message: res.responseStatus.message
      })
    }
  }
}, error => {
  return Promise.reject(error)
})

export default service

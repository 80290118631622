import request from "@/utils/request";

export default class CommonServer {
  /**
   * 登录接口请求token与userInfo
   * @param data
   * 入参  code:"021gj0OV1om5PU0k9VNV1VMQNV1gj0OK"
   * 返回  {
   *          accessToken:'xxx',
   *          refreshToken:'xxx',
   *          userInfo:{}
   *       }
   */
  static login(data) {
    data.provider = "wechat";
    return request({
      //url: '/mp/api/auth',
      url:
        "/mp/api/auth?provider=wechat&code=" +
        data.code +
        "&state=" +
        data.state +
        "&appId=" +
        data.appId +
        "&schoolId=" +
        data.schoolId,
      method: "get"
    });
  }
}

export const STUDENT_STATUS = {
  /// <summary>
  /// 正常状态
  /// </summary>
  NORMAL: { value: "NORMAL", label: "正常" },
  /// <summary>
  /// 已转出
  /// </summary>
  TRANSFEROUT: { value: "TRANSFEROUT", label: "已转出" },
  /// <summary>
  /// 等待入学
  /// </summary>
  WAIT: { value: "WAIT", label: "等待入学" },
  /// <summary>
  /// 毕业
  /// </summary>
  GRADUATION: { value: "GRADUATION", label: "已毕业" },
  /// <summary>
  /// 冻结
  /// </summary>
  FREEZE: { value: "FREEZE", label: "冻结" }
};

export const Nutrition = {
  energy: { key: "energy", label: "能量(千卡)", recommended: 1175 },
  fat: { key: "fat", label: "脂肪(克)", recommended: 35 },
  protein: { key: "protein", label: "蛋白质(克)", recommended: 25 },
  fiber: { key: "fiber", label: "膳食纤维(克)" },
  carbohydrate: {
    key: "carbohydrate",
    label: "碳水化合物(克)",
    recommended: 120
  },
  va: { key: "va", label: "维生素A(微克)", recommended: 310 },
  carotene: { key: "carotene", label: "胡萝卜素(微克)" },
  vb1: { key: "vb1", label: "维生素B1(毫克)", recommended: 0.61 },
  vb2: { key: "vb2", label: "维生素B2(毫克)", recommended: 0.61 },
  vb3: { key: "vb3", label: "维生素B3(毫克)", recommended: 8.69 },
  vc: { key: "vc", label: "维生素C(毫克)", recommended: 40 },
  ve: { key: "ve", label: "维生素E(毫克)", recommended: 5.7 },
  ca: { key: "ca", label: "钙(毫克)", recommended: 600 },
  p: { key: "p", label: "磷(毫克)" },
  k: { key: "k", label: "钾(毫克)" },
  na: { key: "na", label: "钠(毫克)" },
  mg: { key: "mg", label: "镁(毫克)" },
  fe: { key: "fe", label: "铁(毫克)", recommended: 9 },
  zn: { key: "zn", label: "锌(毫克)", recommended: 4 },
  se: { key: "se", label: "硒(毫克)", recommended: 25 },
  cu: { key: "cu", label: "铜(毫克)" },
  mn: { key: "mn", label: "锰(毫克)" },
  i: { key: "i", label: "碘(毫克)" }
};

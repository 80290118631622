import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // {
  //   path: "/",
  //   name: "Home",
  //   meta: {
  //     wechatAuth: false
  //   },
  //   component: Home
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // },
  {
    path: "/applicant",
    name: "applicant",
    meta: {
      title: "申请开通学校"
    },
    component: () => import("@/views/Applicant/index.vue")
  },

  {
    path: "/reguser",
    name: "reguser",
    meta: {
      title: "邀请您加入学校"
      // wechatAuth: false
    },
    component: () => import("@/views/bindKid/index.vue")
  },
  {
    path: "/pay",
    name: "pay",
    meta: {
      title: "收银台"
    },
    component: () => import("@/views/pay/index.vue")
  },
  {
    path: "/payorder",
    name: "payorder",
    meta: {
      title: "订单支付"
    },
    component: () => import("@/views/pay/order.vue")
  },
  {
    path: "/qrupload",
    name: "qrupload",
    meta: {
      title: "文件上传"
    },
    component: () => import("@/views/qrupload/index.vue")
  },
  {
    path: "/qruploadsuccess",
    name: "UploadSuccess",
    meta: {
      title: "文件上传"
    },
    component: () => import("@/views/qrupload/uploadSuccess.vue")
  },

  // {
  //   path: "/reguser",
  //   name: "reguser",
  //   meta: {
  //     title: "邀请您加入班级",
  //     full: false, // 简单注册
  //     wechatAuth: true
  //   },
  //   component: () => import("@/views/regUser/index.vue")
  // },
  // {
  //   path: "/reguser/full",
  //   name: "reguser/full",
  //   meta: {
  //     title: "邀请您加入班级",
  //     full: true, // 完整注册
  //     wechatAuth: true
  //   },
  //   component: () => import("@/views/regUser/index.vue")
  // },
  // {   暂时不要了
  //   path: "/event/:eventtype",
  //   name: "event/eventtype",
  //   meta: {
  //     title: "动态",
  //     keepAlive: true,
  //     wechatAuth: true,

  //   },
  //   component: () => import("@/views/event/index.vue")
  // },

  // eventtask
  {
    path: "/eventtask",
    meta: {
      title: "幼幼家园"
    },
    component: () => import("@/views/Layout.vue"),
    children: [
      {
        path: "/eventtask",
        name: "eventtask",
        component: () => import("@/views/eventTask/index.vue"),
        meta: {
          title: "宝贝成就分享",
          keepAlive: false
        }
      },
      {
        path: "/eventtask/share",
        name: "eventtask/share",
        component: () => import("@/views/eventTask/share.vue"),
        meta: {
          title: "宝贝成就分享海报",
          keepAlive: false
        }
      },
      {
        path: "/eventtask/share2",
        name: "eventtask/share2",
        component: () => import("@/views/eventTask/share2.vue"),
        meta: {
          title: "宝贝成就分享海报",
          keepAlive: false
        }
      }
    ]
  },

  // event
  {
    path: "/event",
    meta: {
      title: "幼幼家园"
    },
    component: () => import("@/views/event/LayoutMobLink.vue"),
    children: [
      {
        path: "/event/:eventtype/publish",
        name: "/event/eventtype/publish",
        meta: {
          title: "发布",
          keepAlive: false,
          wechatAuth: false
        },
        component: () => import("@/views/event/EventAdd.vue")
      },
      {
        path: "/event/eventtask/detail",
        name: "/event/eventtask/detail",
        meta: {
          title: "任务详情",
          wechatAuth: true
        },
        component: () => import("@/views/event/EventTaskDetail.vue")
      },
      {
        path: "/event/eventclockin/detail",
        name: "/event/eventclockin/detail",
        meta: {
          title: "打卡详情",
          wechatAuth: true
        },
        component: () => import("@/views/event/EventClockInDetail.vue")
      },
      {
        path: "/event/eventclockin/detail/user",
        name: "/event/eventclockin/detail/user",
        meta: {
          title: "打卡详情",
          wechatAuth: true
        },
        component: () => import("@/views/event/EventClockInUserDetail.vue")
      },
      {
        path: "/event/eventshare/:eventtype/publish",
        name: "/event/eventshare/eventtype/publish",
        meta: {
          title: "发布",
          keepAlive: false,
          wechatAuth: false
        },
        component: () => import("@/views/event/EventAdd.vue")
      },
      {
        path: "/event/eventshare/eventtask/detail",
        name: "/event/eventshare/eventtask/detail",
        meta: {
          title: "任务详情"
          // wechatAuth: true
        },
        component: () => import("@/views/event/EventTaskDetail.vue")
      },
      {
        path: "/event/eventshare/eventclockin/detail",
        name: "/event/eventshare/eventclockin/detail",
        meta: {
          title: "打卡详情"
          // wechatAuth: true
        },
        component: () => import("@/views/event/EventClockInDetail.vue")
      },
      {
        path: "/event/eventshare/eventclockin/detail/user",
        name: "/event/eventshare/eventclockin/detail/user",
        meta: {
          title: "打卡详情"
          // wechatAuth: true
        },
        component: () => import("@/views/event/EventClockInUserDetail.vue")
      },

      {
        path: "/event/:eventtype", //"/parent/indexdetail/:eventtype/:classid/:eventid",
        name: "event/eventtype", //"/parent/indexdetail",
        meta: {
          title: "幼幼家园",
          wechatAuth: true
        },
        component: () => import("@/views/parent/IndexDetail.vue")
      }
    ]
  },

  // minisite
  {
    path: "/minisite",
    meta: {
      title: "幼幼家园"
    },
    component: () => import("@/views/Layout.vue"),
    children: [
      {
        path: "/minisite/:schoolid",
        name: "/minisite",
        meta: {
          title: "学校微官网",
          isReadOnly: true
        },
        component: () => import("@/views/organization/Index.vue")
      },
      {
        path: "/minisite/view/details",
        name: "/minisite/view/details",
        meta: {
          title: "详情",
          isReadOnly: true
        },
        component: () => import("@/views/organization/details.vue")
      },
      {
        path: "/minisite/view/classify",
        name: "/minisite/view/classify",
        meta: {
          title: "课程分类",
          isReadOnly: true
        },
        component: () => import("@/views/organization/classify.vue")
      },
      {
        path: "/minisite/view/list",
        name: "/minisite/view/list",
        meta: {
          title: "幼幼家园",
          isReadOnly: true
        },
        component: () => import("@/views/organization/list.vue")
      },
      {
        path: "/minisite/view/brand",
        name: "/minisite/view/brand",
        meta: {
          title: "品牌介绍",
          isReadOnly: true
        },
        component: () => import("@/views/organization/brand.vue")
      },
      {
        path: "/minisite/view/personnel",
        name: "/minisite/view/personnel",
        meta: {
          title: "师资团队",
          isReadOnly: true
        },
        component: () => import("@/views/organization/personnel.vue")
      },
      {
        path: "/minisite/view/honor",
        name: "/minisite/view/honor",
        meta: {
          title: "荣誉机构",
          isReadOnly: true
        },
        component: () => import("@/views/organization/honor.vue")
      },
      {
        path: "/minisite/view/newsdetails",
        name: "/minisite/view/newsdetails",
        meta: {
          title: "新闻详情",
          isReadOnly: true
        },
        component: () => import("@/views/organization/newsDetails.vue")
      },
      {
        path: "/minisite/view/newslist",
        name: "/minisite/view/newslist",
        meta: {
          title: "新闻详情",
          isReadOnly: true
        },
        component: () => import("@/views/organization/newsList.vue")
      }
    ]
  },

  // parent
  {
    path: "/parent",
    meta: {
      title: "幼幼家园"
    },
    component: () => import("@/views/Layout.vue"),
    children: [
      {
        path: "/parent/index",
        name: "/parent/index",
        meta: {
          title: "幼幼家园",
          keepAlive: true,
          currIndex: 0,
          wechatAuth: true
        },
        component: () => import("@/views/parent/index.vue")
      },
      {
        path: "/parent/details/:id",
        name: "/parent/details",
        meta: {
          title: "幼幼家园",
          wechatAuth: true
        },
        component: () => import("@/views/parent/Details.vue")
      },
      {
        path: "/parent/list/:id",
        name: "/parent/list",
        meta: {
          title: "幼幼家园",
          keepAlive: true,
          wechatAuth: true
        },
        component: () => import("@/views/parent/List.vue")
      },
      {
        path: "/parent/growup",
        name: "/parent/growup",
        meta: {
          title: "幼幼家园",
          currIndex: 1,
          wechatAuth: true
        },
        component: () => import("@/views/parent/GrowUp.vue")
      },
      {
        path: "/parent/mygowth/:id",
        name: "/parent/mygrowth",
        meta: {
          title: "幼幼家园",
          wechatAuth: true
        },
        component: () => import("@/views/parent/MyGrowth.vue")
      },
      {
        path: "/parent/:eventtype/publish",
        name: "/parent/eventtype/publish",
        meta: {
          title: "发布",
          keepAlive: false,
          wechatAuth: true
        },
        component: () => import("@/views/parent/EventAdd.vue")
      },
      {
        path: "/parent/eventclockin/detail",
        name: "/parent/eventclockin/detail",
        meta: {
          title: "打卡详情",
          wechatAuth: true
        },
        component: () => import("@/views/parent/EventClockInDetail.vue")
      },
      {
        path: "/parent/eventclockin/detail/user",
        name: "/parent/eventclockin/detail/user",
        meta: {
          title: "打卡详情",
          wechatAuth: true
        },
        component: () => import("@/views/parent/EventClockInUserDetail.vue")
      },
      {
        path: "/parent/eventtask/detail",
        name: "/parent/eventtask/detail",
        meta: {
          title: "任务详情",
          wechatAuth: true
        },
        component: () => import("@/views/parent/EventTaskDetail.vue")
      },
      {
        path: "/parent/my",
        name: "/parent/my",
        meta: {
          title: "幼幼家园",
          currIndex: 2,
          wechatAuth: true
        },
        component: () => import("@/views/parent/My.vue")
      }
    ]
  },

  // share 用户分享链接
  {
    path: "/share",
    meta: {
      title: "动态"
    },
    component: () => import("@/views/event/LayoutMobLink.vue"),
    children: [
      {
        path: "/share",
        component: () => import("@/views/event/Layout.vue"),
        children: [
          {
            path: "/share/liveclassroom",
            name: "share/liveclassroom",
            meta: {
              title: "空中课堂",
              hideOpenInstall: true,
              hideGetFreeLessons: true,
              keepAlive: true,
              isReadOnly: true
            },
            component: () => import("@/views/event/LiveClassRoom/index.vue"),
            children: []
          },
          {
            path: "/share/:eventtype",
            name: "share/eventtype",
            meta: {
              title: "动态",
              keepAlive: true,
              isReadOnly: true
            },
            component: () => import("@/views/event/index.vue"),
            children: []
          }
        ]
      }
    ]
  },

  // supercare
  {
    path: "/supercare",
    meta: {
      title: "幼幼家园"
    },
    component: () => import("@/views/supercare/Layout.vue"),
    children: [
      {
        path: "/supercare",
        name: "/supercare",
        meta: {
          title: "幼幼家园"
        },
        component: () => import("@/views/supercare/index.vue")
      },
      {
        path: "/supercare/week",
        name: "/supercare/week",
        meta: {
          title: "幼幼家园"
        },
        component: () => import("@/views/supercare/week.vue")
      },
      {
        path: "/supercare/list/day",
        name: "/supercare/list/day",
        meta: {
          title: "幼幼家园"
        },
        component: () => import("@/views/supercare/list/day.vue")
      },
      {
        path: "/supercare/list/week",
        name: "/supercare/list/week",
        meta: {
          title: "幼幼家园"
        },
        component: () => import("@/views/supercare/list/week.vue")
      }
    ]
  },

  //manqu
  {
    path: "/manqu",
    meta: {
      title: "幼幼家园"
    },
    component: () => import("@/views/Layout.vue"),
    children: [
      {
        path: "/manqu",
        name: "/manqu",
        meta: {
          title: "幼幼家园"
        },
        component: () => import("@/views/manqu/index.vue")
      }
    ]
  },

  // publicity
  {
    path: "/publicity",
    meta: {
      title: "校园公众号"
    },
    component: () => import("@/views/Layout.vue"),
    children: [
      {
        path: "/publicity/page",
        meta: {
          title: "校园公众号"
        },
        component: () => import("@/views/publicity/Layout.vue"),
        children: [
          {
            path: "/publicity/page/",
            name: "/publicity/page/",
            // meta: {
            //   title: "园所公众号文章详情"
            // },
            component: () => import("@/views/publicity/LayoutPage.vue"),
            children: [
              {
                path: "/publicity/page/:schoolId/:id",
                name: "/publicity/page/detail",
                component: () => import("@/views/publicity/View/index.vue")
              }
            ]
          }
        ]
      },
      {
        path: "/publicity/editor",
        meta: {
          title: "校园公众号"
        },
        component: () => import("@/views/publicity/LayoutApp.vue"),
        children: [
          {
            path: "/publicity/editor/list",
            name: "/publicity/editor/list",
            meta: {
              // isDebug: true
            },
            component: () => import("@/views/publicity/Editor/List.vue")
          },
          {
            path: "/publicity/editor/draft",
            name: "/publicity/editor/draft",
            meta: {
              // isDebug: true,
              isDraft: true
            },
            component: () => import("@/views/publicity/Editor/List.vue")
          },
          {
            path: "/publicity/editor/pageview",
            component: () => import("@/views/publicity/LayoutPage.vue"),
            children: [
              {
                path: "/publicity/editor/pageview/:id?/:from?",
                name: "/publicity/editor/pageview",
                meta: {
                  // isDebug: true
                },
                component: () => import("@/views/publicity/Editor/View.vue")
              }
            ]
          },
          {
            path: "/publicity/editor",
            component: () => import("@/views/publicity/LayoutPage.vue"),
            children: [
              {
                path: "/publicity/editor/:id?",
                name: "/publicity/editor",
                meta: {
                  // isDebug: true
                },
                component: () => import("@/views/publicity/Editor/index.vue")
              }
            ]
          }
        ]
      }
    ]
  },

  //  home
  {
    path: "/home",
    meta: {
      title: "公众号"
    },
    component: () => import("@/views/home/LayoutWeChat.vue"),
    children: [
      {
        path: "/home/:schoolId/activity",
        name: "/home/schoolId/activity",
        meta: {},
        component: () => import("@/views/home/activity.vue")
      },
      {
        path: "/home/:schoolId/bindkid",
        name: "/home/schoolId/bindkid",
        meta: {},
        component: () => import("@/views/home/bindKid.vue")
      },
      {
        path: "/home",
        component: () => import("@/views/home/Layout.vue"),
        children: [
          {
            path: "/home",
            name: "/home",
            meta: {
              isDebugNoWechatAuth: false
            },
            component: () => import("@/views/home/common.vue"),
            children: []
          },
          {
            path: "/home/:schoolId",
            name: "/home/schoolId",
            meta: {
              currIndex: 0,
              showFooter: true,
              isDebugNoWechatAuth: false
            },
            component: () => import("@/views/home/Index/index.vue"),
            children: []
          },
          {
            path: "/home/:schoolId/my",
            name: "/home/schoolId/my",
            meta: {
              currIndex: 1,
              showFooter: true,
              isDebugNoWechatAuth: false
            },
            component: () => import("@/views/home/My/index.vue"),
            children: []
          },
          {
            path: "/home/:schoolId/classtime",
            name: "/home/schoolId/classtime",
            meta: {
              showFooter: false,
              isDebugNoWechatAuth: false
            },
            component: () => import("@/views/home/ClassTime/index.vue"),
            children: []
          },
          {
            path: "/home/:schoolId/coursehour",
            name: "/home/schoolId/coursehour",
            meta: {
              showFooter: false,
              isDebugNoWechatAuth: false
            },
            component: () => import("@/views/home/CourseHour/index.vue"),
            children: []
          },
          {
            path: "/home/:schoolId/coursehour/:id",
            name: "/home/schoolId/coursehour/id",
            meta: {
              showFooter: false,
              isDebugNoWechatAuth: false
            },
            component: () => import("@/views/home/CourseHour/detail.vue"),
            children: []
          },
          {
            path: "/home/:schoolId/appointment",
            component: () => import("@/views/home/Appointment/Layout.vue"),
            children: [
              {
                path: "/home/:schoolId/appointment",
                name: "/home/schoolId/appointment",
                meta: {
                  title: "可预约课程",
                  keepAlive: true,
                  showFooter: true,
                  isDebugNoWechatAuth: false
                },
                component: () => import("@/views/home/Appointment/index.vue"),
                children: []
              },
              {
                path:
                  "/home/:schoolId/appointment/:startTime/:scheduleId/:index",
                name: "/home/schoolId/appointment/startTime/scheduleId/index",
                meta: {
                  title: "预约课程",
                  showFooter: false,
                  isDebugNoWechatAuth: false
                },
                component: () =>
                  import("@/views/home/Appointment/ClassTime.vue"),
                children: []
              }
            ]
          }
        ]
      }
    ]
  },

  // app
  {
    path: "/app",
    meta: {
      title: "公众号"
    },
    component: () => import("@/views/app/LayoutApp.vue"),
    children: [
      {
        path: "/app/appointment",
        name: "/app/appointment",
        meta: {
          title: "可预约课程",
          keepAlive: true
          // isDebug: true
        },
        component: () => import("@/views/home/Appointment/index.vue")
      },
      {
        path: "/app/appointment/:startTime/:scheduleId/:index",
        name: "/app/appointment/startTime/scheduleId/index",
        meta: {
          title: "预约课程"
          // isDebug: true
        },
        component: () => import("@/views/home/Appointment/ClassTime.vue"),
        children: []
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

import {
  removeStorage,
  // saveCookie,
  saveStorage,
  saveSessionStorage,
  ACCESS_TOKEN,
  API_TOKEN,
  LOGIN_STATUS,
  OPEN_ID,
  USER_INFO,
  PAGE_ATTRS,
  PAGE_DATA,
  SESSION_DATA,
  CACHE_DATA
} from "@/utils/cache";

import * as types from "./mutationTypes";

/**
 * 设置登录状态
 * @param commit
 * @param state
 * @param query
 */
export const setLoginStatus = ({ commit, state }, query) => {
  if (query === 0 || query === 1) {
    removeStorage(ACCESS_TOKEN);
    removeStorage(USER_INFO);
    removeStorage(API_TOKEN);
    removeStorage(OPEN_ID);
  }
  commit(
    types.SET_LOGIN_STATUS,
    saveSessionStorage(LOGIN_STATUS, query)
    // saveCookie(LOGIN_STATUS, query, {
    //   expires: 7
    // })
  );
};

/**
 * 设置用户信息
 * @param commit
 * @param state
 * @param query
 */
export const setUserInfo = ({ commit, state }, query) => {
  commit(types.SET_USER_INFO, saveStorage(USER_INFO, query));
};

/**
 * 设置token
 * @param commit
 * @param state
 * @param query
 */
export const setAccessToken = ({ commit, state }, query) => {
  commit(types.SET_ACCESS_TOKEN, saveStorage(ACCESS_TOKEN, query));
};

/**
 * 设置openid
 * @param commit
 * @param state
 * @param query
 */
export const setOpenId = ({ commit, state }, query) => {
  commit(types.SET_OPEN_ID, saveStorage(OPEN_ID, query));
};

export const setApiToken = ({ commit, state }, query) => {
  commit(types.SET_API_TOKEN, saveStorage(API_TOKEN, query));
};

export const setPageAttrs = ({ commit, state }, query) => {
  commit(types.SET_PAGE_ATTRS, saveSessionStorage(PAGE_ATTRS, query));
};

export const setPageData = ({ commit }, query) => {
  commit(types.SET_PAGE_DATA, saveSessionStorage(PAGE_DATA, query));
};

// get
import {
  GetMultiClassEventsForParent,
  GetEventTaskById,
  GetEventEvaluateById,
  GetEventKidRemarkById,
  GetEventVoteById,
  GetEventClockInById,
  GetEventClockInGroupById,
  GetKidEventShareData,
  GetParentKids
} from "@/api/data";

import { STUDENT_STATUS } from "@/api/common";

export const getPageData = async ({ commit, state }) => {
  const { pageAttrs, pageData } = state;
  const { classId, eventId, kidId } = pageAttrs;

  const result = {
    ret: true
  };

  if (!kidId) {
    result.ret = false;
    result.message = "您尚未在本班绑定学生信息";
    return result;
  }

  const _call = [];
  {
    if (pageAttrs.eventType.toLowerCase() === "eventevaluate") {
      _call.push(
        GetEventEvaluateById({
          classId,
          eventid: eventId
        })
      );
      _call.push(
        GetMultiClassEventsForParent({
          eventIds: [eventId],
          kidid: kidId
        })
      );
    } else if (pageAttrs.eventType.toLowerCase() === "eventkidremark") {
      _call.push(
        GetEventKidRemarkById({
          classId,
          eventid: eventId
        })
      );
      _call.push(
        GetMultiClassEventsForParent({
          eventIds: [eventId],
          kidid: kidId
        })
      );
    } else if (pageAttrs.eventType.toLowerCase() === "eventtask") {
      _call.push(
        GetEventTaskById({
          classId,
          eventid: eventId,
          kidId
        })
      );
    } else if (pageAttrs.eventType.toLowerCase() === "eventvote") {
      _call.push(
        GetEventVoteById({
          eventId
        })
      );
    } else if (pageAttrs.eventType.toLowerCase() === "eventclockin") {
      _call.push(
        GetEventClockInById({
          classId,
          eventId,
          kidId
        })
      );
    } else {
      _call.push(null);
      _call.push(
        GetMultiClassEventsForParent({
          eventIds: [eventId],
          kidid: kidId
        })
      );
    }
  }

  await Promise.all(_call)
    .then(([dto, evt]) => {
      const event = {
        eventDto: {},
        eventData: {}
      };
      if (dto) {
        if (dto.ret) {
          if (dto.eventitem) {
            // GetEventTaskById
            // GetEventVoteById
            event.eventDto = dto.eventitem;
          } else if (dto.data) {
            // GetEventClockInById
            event.eventDto = dto.data;
          } else if (dto.evaluateDTO) {
            event.eventDto = dto.evaluateDTO;
          } else if (dto.kidremarkDTO) {
            event.eventDto = dto.kidremarkDTO;
          }
        } else {
          result.ret = false;
          result.message = dto.message;
        }
      }

      if (evt) {
        if (evt.ret && evt.data) {
          // GetMultiClassEventsForParent
          const [data] = evt.data;
          event.eventData = data;
        } else {
          result.ret = false;
          result.message = dto.message;
        }
      }
      // 保存
      commit(
        types.SET_PAGE_DATA,
        saveSessionStorage(PAGE_DATA, {
          ...pageData,
          ...event
        })
      );
    })
    .catch(err => {
      // console.log(err)
      result.ret = false;
      result.message = err;
    });

  return result;
};

export const getSharePageData = async ({ commit, state }, payload) => {
  // console.log(payload);
  const { pageAttrs, pageData } = state;
  // console.log("pageData" + pageData);
  const { classId, eventId, kidId } = pageAttrs;
  const result = {
    ret: true
  };
  const _call = [];
  {
    _call.push(null);
    _call.push(
      GetKidEventShareData({
        // eventid: payload.eventid,
        // kidid: payload.kidid,
        eventId: payload.eventid,
        kidId: payload.kidid,
        itemId:payload.itemid,
        nonce: payload.nonce,
        sign: payload.sign
      })
    );
  }

  await Promise.all(_call)
    .then(([dto, evt]) => {
      const event = {
        eventDto: {},
        eventData: {}
      };
      if (dto) {
        // console.log("aaaaa" + dto);
        if (dto.ret) {
          if (dto.eventitem) {
            // GetEventTaskById
            // GetEventVoteById
            event.eventDto = dto.eventitem;
          } else if (dto.data) {
            // GetEventClockInById
            event.eventDto = dto.data;
          } else if (dto.evaluateDTO) {
            event.eventDto = dto.evaluateDTO;
          } else if (dto.kidremarkDTO) {
            event.eventDto = dto.kidremarkDTO;
          }
        } else {
          result.ret = false;
          result.message = dto.message;
        }
      }

      if (evt) {
        if (evt.ret && evt.data) {
          // GetMultiClassEventsForParent
          const { data } = evt;
          event.eventData = data;
        } else {
          result.ret = false;
          result.message = evt.message;
        }
      }
      // 保存
      commit(
        types.SET_PAGE_DATA,
        saveSessionStorage(PAGE_DATA, {
          ...pageData,
          ...event
        })
      );
    })
    .catch(err => {
      console.log(err);
      result.ret = false;
      result.message = "数据加载异常";
    });
  // console.log("yyyy" + result);
  return result;
};

export const getClockInGroup = async ({ commit, state }, payload) => {
  const { pageAttrs, pageData } = state;
  const { classId, eventId, kidId } = pageAttrs;
  const { groupId } = payload;
  const result = {
    ret: true
  };

  await GetEventClockInGroupById({
    classId,
    eventId,
    groupId,
    kidId
  })
    .then(res => {
      if (res.ret && res.data) {
        // 保存
        commit(
          types.SET_PAGE_DATA,
          saveSessionStorage(PAGE_DATA, {
            ...pageData,
            clockInGroup: res.data
          })
        );
      } else {
        result.ret = false;
        result.message = res.message;
      }
    })
    .catch(err => {
      result.ret = false;
      result.message = err;
    });

  return result;
};

export const getParentKids = async ({ commit, state }, payload) => {
  const { schoolId } = payload;
  const { sessionData } = state;

  return await GetParentKids({ schoolId })
    .then(res => {
      if (res.code !== 200) {
        // this.$toast({
        //   message: res?.message
        // });
        return res;
      }

      const parentKids = (res.kidList || []).filter(
        kid => kid.studentStatus !== STUDENT_STATUS.TRANSFEROUT.value // 排除已转出的
      );
      const parentSchoolKids = parentKids.filter(
        kid => kid.schoolId === schoolId // 只显示当前学校的
      );
      // 保存
      commit(
        types.SET_SESSION_DATA,
        saveSessionStorage(SESSION_DATA, {
          ...sessionData,
          parentKids,
          parentSchoolKids
        })
      );

      // // 检查当前选择的kidid 是否存在
      // let parentKidInfo = null;
      // let parentKidId = "";
      // parentKidInfo = parentSchoolKids.find(kid => kid.kidId === stateKidId);
      // if (!parentKidInfo) {
      //   // 优化取存在正在状态班级的学生
      //   parentKidInfo = parentSchoolKids.find(
      //     kid =>
      //       kid.classInfos?.length > 0 &&
      //       kid.classInfos.some(c => c.status === "NORMAL")
      //   );
      // }
      // if (!parentKidInfo) {
      //   // 取第一个
      //   [parentKidInfo] = parentSchoolKids;
      // }
      // if (parentKidInfo) {
      //   parentKidId = parentKidInfo.kidId;
      // }
      // commit(
      //   types.SET_CACHE_DATA,
      //   saveStorage(CACHE_DATA, {
      //     ...restCacheData,
      //     parentKidId,
      //     parentSchoolId: schoolId,
      //     parentKidInfo
      //   })
      // );
      return { ...res, parentSchoolKids, parentKids };
    })
    .catch(err => {
      console.log(err);
    });
};

export const changeParentKidInfo = async ({ commit, state }, payload) => {
  const { kidId, schoolId } = payload;
  const { cacheData } = state;

  commit(
    types.SET_CACHE_DATA,
    saveStorage(CACHE_DATA, {
      ...cacheData,
      parentKidId: kidId,
      parentSchoolId: schoolId,
      parentKidInfo: payload
    })
  );
};

/* Options:
Date: 2022-05-30 14:07:46
Version: 6.02
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5000/api/educrm

//GlobalNamespace: 
MakePropertiesOptional: True
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId?: string;
}

export interface IHasBearerToken
{
    bearerToken?: string;
}

export interface IPost
{
}

export enum OSTYPE
{
    ANDROID = 'ANDROID',
    IOS = 'IOS',
}

export class CommonRequest implements ICommonRequest
{
    public appId?: number;
    public platform?: number;
    public osType?: OSTYPE;
    public version?: string;
    public buildNumber?: string;

    public constructor(init?: Partial<CommonRequest>) { (Object as any).assign(this, init); }
}

export interface ICommonRequest
{
    appId?: number;
    platform?: number;
    version?: string;
    buildNumber?: string;
    osType?: OSTYPE;
}

export enum FILETYPE
{
    TYPE_UNKNOW = 0,
    TYPE_PERSON = 1,
    TYPE_CLASS = 2,
    TYPE_USERLOGO = 3,
    TYPE_CLASSLOGO = 4,
    TYPE_EVENTATTACH = 5,
    TYPE_CHATATTACH = 6,
    TYPE_KIDLOGO = 7,
    TYPE_SCHOOLLOGO = 8,
    TYPE_SCHOOLDESC = 9,
    TYPE_KIDENTITYLOG = 10,
    TYPE_SCHOOLSHAREDISK = 11,
    TYPE_STUDENTAIFACE = 12,
    TYPE_PARENTAIFACE = 13,
    TYPE_TEACHERAIFACE = 14,
    TYPE_ATTENDANCESNAP = 15,
    TYPE_BABYCAREVIDEO = 16,
    TYPE_TEACHERSTUDY = 17,
    TYPE_DATAVSNAPSHOT = 100,
}

export class ChannelVersionItem
{
    public channelName?: string;
    public downloadUrl?: string;
    public md5Hash?: string;
    public packageSize?: string;

    public constructor(init?: Partial<ChannelVersionItem>) { (Object as any).assign(this, init); }
}

export class EventAttachImageItemDTO
{
    public ossInfoId?: string;
    public orderIndex?: number;
    public rotate?: number;

    public constructor(init?: Partial<EventAttachImageItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachVideoItemDTO
{
    public ossInfoId?: string;

    public constructor(init?: Partial<EventAttachVideoItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachAudioItemDTO
{
    public ossInfoId?: string;

    public constructor(init?: Partial<EventAttachAudioItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachFileItemDTO
{
    public ossInfoId?: string;

    public constructor(init?: Partial<EventAttachFileItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachLinkItemDTO
{
    public linkUrl?: string;
    public linkTitle?: string;
    public linkIcon?: string;

    public constructor(init?: Partial<EventAttachLinkItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachTingItemDTO
{
    public albumId?: string;
    public trackId?: string;
    public albumTitle?: string;
    public albumDesc?: string;
    public albumImageUrl?: string;

    public constructor(init?: Partial<EventAttachTingItemDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachDTO
{
    public imageItems?: EventAttachImageItemDTO[];
    public videoItems?: EventAttachVideoItemDTO[];
    public audioItems?: EventAttachAudioItemDTO[];
    public fileItems?: EventAttachFileItemDTO[];
    public linkItems?: EventAttachLinkItemDTO[];
    public tingItems?: EventAttachTingItemDTO[];
    public videoItem?: EventAttachVideoItemDTO;
    public audioItem?: EventAttachAudioItemDTO;
    public fileItem?: EventAttachFileItemDTO;
    public linkItem?: EventAttachLinkItemDTO;
    public tingItem?: EventAttachTingItemDTO;

    public constructor(init?: Partial<EventAttachDTO>) { (Object as any).assign(this, init); }
}

export enum PosterConfigItemType
{
    TITLE = 'TITLE',
    TEXT = 'TEXT',
    TEXTAREA = 'TEXTAREA',
    DATE = 'DATE',
    DATE_RANGE = 'DATE_RANGE',
    KID_PICKER = 'KID_PICKER',
    IMAGE = 'IMAGE',
    QRCODE = 'QRCODE',
    TABLE = 'TABLE',
    TEXT_Y = 'TEXT_Y',
    TEXT_X = 'TEXT_X',
}

export class PosterConfigItemDTO
{
    public title?: string;
    public content?: string;
    public attachment?: EventAttachDTO;
    public type?: PosterConfigItemType;
    public editable?: boolean;
    public hidden?: boolean;
    public maxContentLength?: number;
    public maxImageCount?: number;
    public fontSize?: number;
    public fontColor?: number;
    public fontWeight?: number;
    public backgroundImageUrl?: string;
    public placeholder?: string;
    public extraDataTitle?: string;
    public description?: string;
    public extraDatas?: string[];
    public x?: number;
    public y?: number;
    public w?: number;
    public h?: number;

    public constructor(init?: Partial<PosterConfigItemDTO>) { (Object as any).assign(this, init); }
}

export enum PUBLICITY_CONTENT_TYPE
{
    textarea = 'textarea',
    subTitle = 'subTitle',
    image = 'image',
    video = 'video',
    link = 'link',
}

export class PublicityContentCSS
{
    public index?: number;
    public left?: string;
    public center?: string;
    public right?: string;
    public color?: string;

    public constructor(init?: Partial<PublicityContentCSS>) { (Object as any).assign(this, init); }
}

export class PublicityContent
{
    public type?: PUBLICITY_CONTENT_TYPE;
    public text?: string;
    public fileurl?: string;
    public thumburl?: string;
    public css?: PublicityContentCSS;

    public constructor(init?: Partial<PublicityContent>) { (Object as any).assign(this, init); }
}

export class PublicityDTO
{
    public id?: string;
    public schoolId?: string;
    public coverUrl?: string;
    public title?: string;
    public content?: PublicityContent[];
    public backgroundImageUrl?: string;
    public backgroundColor?: string;
    public fontColor?: string;
    public titleColor?: string;
    public backgroundMusicUrl?: string;
    public createByTeacherUserInfoId?: string;
    public isDraft?: boolean;
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public deleteOn?: string;
    public createOn?: string;

    public constructor(init?: Partial<PublicityDTO>) { (Object as any).assign(this, init); }
}

export enum REPORT_DIMENSION
{
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
    Month = 2,
    Yearly = 3,
}

export enum ATTENDANCECARDOWNERTYPE
{
    TEACHER = 'TEACHER',
    PARENT = 'PARENT',
    STUDENT = 'STUDENT',
}

export enum ATTENDANCEMETHOD
{
    ICCARD = 'ICCARD',
    AIFACE = 'AIFACE',
    QRCODE = 'QRCODE',
    MANUAL = 'MANUAL',
}

export enum ATTENDANCE_HEALTH_TEMPERATURE_STATUS
{
    UNKNOW = 'UNKNOW',
    OK = 'OK',
    NOTOK = 'NOTOK',
}

export enum MyDayOfWeek
{
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
}

export class AttendanceTimeConfigDTO
{
    public dayOfWeek?: MyDayOfWeek;
    public timeBegin?: string;
    public timeEnd?: string;

    public constructor(init?: Partial<AttendanceTimeConfigDTO>) { (Object as any).assign(this, init); }
}

export enum AttendanceAudioType
{
    TEACHER_IN = 'TEACHER_IN',
    TEACHER_OUT = 'TEACHER_OUT',
    PARENT_IN = 'PARENT_IN',
    PARENT_OUT = 'PARENT_OUT',
    STUDENT_IN = 'STUDENT_IN',
    STUDENT_OUT = 'STUDENT_OUT',
}

export enum CHANNEL_EVENTTYPE
{
    单卡识别 = 0,
    无效卡号 = 1,
    没有时区 = 2,
    无效时区 = 3,
    密码错误 = 4,
    双卡识别错误 = 5,
    重复进门 = 6,
    重复出门 = 7,
    门未开报警 = 8,
    提示确认 = 9,
    出门 = 10,
    进门 = 11,
    卡加密码开门 = 12,
    双卡开门 = 13,
    密码开门 = 14,
    无限制通行 = 15,
    无效卡进入 = 16,
    无效卡出 = 17,
    黑名单进 = 18,
    黑名单出 = 19,
    卡加密码进门 = 20,
    卡加密码出门 = 21,
    双卡进门 = 22,
    双卡出门 = 23,
    密码进门 = 24,
    密码出门 = 25,
    自由进门 = 26,
    自由出门 = 27,
    禁止进门 = 28,
    禁止出门 = 29,
    考勤补打卡 = 30,
    补卡确认 = 31,
    读卡器防拆报警 = 39,
    报警A = 40,
    报警B = 41,
    胁持报警 = 47,
    防拆报警 = 48,
    潜反报警 = 49,
    PANIC_ALARM = 50,
    DURESS_ALARM = 51,
    EMERGENCY = 52,
    硬件错误 = 53,
    没有连接 = 54,
    无效序列号 = 55,
    门报警 = 56,
    开门时间太长 = 57,
    门没锁 = 58,
    出门按钮打开 = 59,
    多人预备进门 = 60,
    多人预备出门 = 61,
    多人进门 = 62,
    多人出门 = 63,
    多人开门错误 = 64,
    多人开门超时 = 65,
    火警 = 72,
    互锁警报 = 73,
    控制器启动 = 75,
    无效门 = 76,
    连接 = 86,
    门被锁定 = 89,
    门1没有关 = 90,
    门2没有关 = 91,
    门3没有关 = 92,
    门4没有关 = 93,
    出门超时 = 97,
    门开 = 100,
    门关 = 101,
}

export enum HOLIDAY_TYPE
{
    YUANDAN = 'YUANDAN',
    CHUNJIE = 'CHUNJIE',
    QINGMING = 'QINGMING',
    LAODONG = 'LAODONG',
    DUANWU = 'DUANWU',
    ZHONGQIU = 'ZHONGQIU',
    GUOQING = 'GUOQING',
}

export class HolidayItemDTO
{
    public holidayType?: HOLIDAY_TYPE;
    public dayRange?: string[];

    public constructor(init?: Partial<HolidayItemDTO>) { (Object as any).assign(this, init); }
}

export class AttendanceDeviceSetConfigItem
{
    public devicePassword?: string;
    public ntpEnable?: string;
    public ntpAddress?: string;
    public enableIrLive?: string;
    public irLiveLevel?: string;
    public recogDistance?: string;
    public recogIntervalTime?: string;
    public onlyRecogMaxSizeFaceEnable?: string;
    public faceTemperatureDetectEnable?: string;
    public faceFeverTemperature?: string;
    public faceDetectBoxScore?: string;
    public faceDetectLandmardScore?: string;
    public faceRegisterConfidenceScore?: string;
    public strangerRecogEnable?: string;
    public strangerVoiceTime?: string;
    public alarmVoiceEnable?: string;
    public jpegEncodeSizeMode?: string;
    public playVoiceEnable?: string;
    public installCity?: string;
    public voiceVolume?: string;
    public entranceDirection?: string;
    public autoOpenDoorEnable?: string;
    public openDoorTimeDelayMs?: string;
    public overlayDeviceName?: string;
    public banner?: string;
    public mainWindowUiEnable?: string;
    public screenSaverMode?: string;
    public mainUiLogoMode?: string;
    public cloudOfflineRetransmission?: string;
    public faceBeauty?: string;
    public faceRFIDCardEnable?: string;

    public constructor(init?: Partial<AttendanceDeviceSetConfigItem>) { (Object as any).assign(this, init); }
}

export enum DAILYCARE_TOILET_TYPE
{
    NONE = 'NONE',
    PEE = 'PEE',
    POO = 'POO',
}

export enum DAILYCARE_EAT_STATUS
{
    NOT_EAT = 'NOT_EAT',
    EAT_HALF = 'EAT_HALF',
    EAT_ALL = 'EAT_ALL',
}

export enum DAILYCARE_WORK_STATUS
{
    INCOMPLETE = 'INCOMPLETE',
    COMPLETED = 'COMPLETED',
    WAIT_INSPECT = 'WAIT_INSPECT',
    INSPECTED = 'INSPECTED',
}

export class BabyCareTaskFlowDTO
{
    public id?: string;
    public schoolId?: string;
    public babyCareTaskId?: string;
    public timeBegin?: string;
    public timeEnd?: string;
    public weekDayOn?: MyDayOfWeek[];
    public flowName?: string;
    public teacherRoles?: string[];
    public needVideoRecord?: boolean;
    public isEnabled?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public studyId?: string;

    public constructor(init?: Partial<BabyCareTaskFlowDTO>) { (Object as any).assign(this, init); }
}

export enum STUDENT_STATUS
{
    NORMAL = 'NORMAL',
    TRANSFEROUT = 'TRANSFEROUT',
    WAIT = 'WAIT',
    GRADUATION = 'GRADUATION',
    FREEZE = 'FREEZE',
}

export enum GENDERTYPE
{
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    UNKNOW = 'UNKNOW',
}

export enum KIDACCOUNTSTATE
{
    STATE_WAITAPPROVE = 'STATE_WAITAPPROVE',
    STATE_WAITLOGIN = 'STATE_WAITLOGIN',
    STATE_NORMAL = 'STATE_NORMAL',
}

export enum SEMESTERTYPE
{
    SEMESTER_PKFIRST = 'SEMESTER_PKFIRST',
    SEMESTER_PKSECOND = 'SEMESTER_PKSECOND',
    SEMESTER_K1FIRST = 'SEMESTER_K1FIRST',
    SEMESTER_K1SECOND = 'SEMESTER_K1SECOND',
    SEMESTER_K2FIRST = 'SEMESTER_K2FIRST',
    SEMESTER_K2SECOND = 'SEMESTER_K2SECOND',
    SEMESTER_K3FIRST = 'SEMESTER_K3FIRST',
    SEMESTER_K3SECOND = 'SEMESTER_K3SECOND',
    SEMESTER_OTHER = 'SEMESTER_OTHER',
    SEMESTER_UNKNOW = 'SEMESTER_UNKNOW',
}

export enum KID_REMARK_TYPE
{
    SEMESTER_REMARK = 'SEMESTER_REMARK',
    DAILY_REMARK = 'DAILY_REMARK',
}

export enum PARENT_ROLE_TYPE
{
    MAMA = 'MAMA',
    BABA = 'BABA',
    YEYE = 'YEYE',
    NAINAI = 'NAINAI',
    WAIGONG = 'WAIGONG',
    WAIPO = 'WAIPO',
    GEGE = 'GEGE',
    JIEJIE = 'JIEJIE',
    QITA = 'QITA',
}

export class ParentInfoDTO
{
    public parentRoleType?: PARENT_ROLE_TYPE;
    public phoneNumber?: string;

    public constructor(init?: Partial<ParentInfoDTO>) { (Object as any).assign(this, init); }
}

export class KidClassInfoDTO
{
    public branchId?: string;
    public className?: string;
    public originalClassName?: string;
    public classId?: string;
    public currentSemester?: SEMESTERTYPE;
    public hasLoveVideo?: boolean;
    public status?: STUDENT_STATUS;

    public constructor(init?: Partial<KidClassInfoDTO>) { (Object as any).assign(this, init); }
}

export enum PARENTACCOUNTSTATUS
{
    STATUS_NONE = 'STATUS_NONE',
    STATUS_INVITE = 'STATUS_INVITE',
    STATUS_LOGINED = 'STATUS_LOGINED',
    STATUS_DISABLED = 'STATUS_DISABLED',
}

export class ParentRelationClassInfoDTO
{
    public parentRelationSchoolInfoId?: string;
    public profileId?: string;
    public isDefault?: boolean;
    public userId?: number;
    public roleName?: string;
    public phoneNumber?: string;
    public imClientId?: string;
    public rongCloudUserId?: string;
    public parentId?: string;
    public kidId?: string;
    public inviteStatus?: PARENTACCOUNTSTATUS;

    public constructor(init?: Partial<ParentRelationClassInfoDTO>) { (Object as any).assign(this, init); }
}

export class KidInfoDTO
{
    public id?: string;
    public name?: string;
    public gender?: GENDERTYPE;
    public birthDate?: string;
    public logoUrl?: string;
    public createOn?: string;
    public state?: KIDACCOUNTSTATE;
    public studentStatus?: STUDENT_STATUS;
    public primaryContact?: ParentInfoDTO;
    public secondaryContact?: ParentInfoDTO;
    public classInfos?: KidClassInfoDTO[];
    public schoolId?: string;
    public branchId?: string;
    public hobby?: string;
    public parents?: ParentRelationClassInfoDTO[];

    public constructor(init?: Partial<KidInfoDTO>) { (Object as any).assign(this, init); }
}

export class KidRemarkItemCommentDTO
{
    public parentId?: string;
    public parentRole?: string;
    public content?: string;
    public createOn?: string;

    public constructor(init?: Partial<KidRemarkItemCommentDTO>) { (Object as any).assign(this, init); }
}

export class KidRemarkItemDTO
{
    public kidId?: string;
    public kidInfo?: KidInfoDTO;
    public content?: string;
    public comments?: KidRemarkItemCommentDTO[];
    public createOn?: string;
    public modifyOn?: string;

    public constructor(init?: Partial<KidRemarkItemDTO>) { (Object as any).assign(this, init); }
}

export class TargetClassAndMember
{
    public classId?: string;
    public selectedTeacherId?: number[];
    public selectedKidId?: string[];

    public constructor(init?: Partial<TargetClassAndMember>) { (Object as any).assign(this, init); }
}

export enum TASKATTACHTYPE
{
    ALL = 'ALL',
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
}

export enum CLOCKIN_FREQUENCY
{
    ONEDAY = 'ONEDAY',
    TWODAY = 'TWODAY',
    WORKDAY = 'WORKDAY',
    WEEKEND = 'WEEKEND',
    CUSTOM = 'CUSTOM',
}

export class EventClockInFrequency
{
    public frequency?: CLOCKIN_FREQUENCY;
    public customWeekday?: number[];
    public needRemind?: boolean;
    public remindAt?: string;

    public constructor(init?: Partial<EventClockInFrequency>) { (Object as any).assign(this, init); }
}

export enum SCORE_TYPE
{
    NUMBER = 'NUMBER',
    TEXT = 'TEXT',
}

export class ScoreItem
{
    public name?: string;
    public valueType?: SCORE_TYPE;
    public unit?: string;
    public value?: string;

    public constructor(init?: Partial<ScoreItem>) { (Object as any).assign(this, init); }
}

export class KidCheckUpTemplateDTO
{
    public id?: string;
    public schoolId?: string;
    public name?: string;
    public scoreItems?: ScoreItem[];
    public isEnabled?: boolean;
    public isDelete?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<KidCheckUpTemplateDTO>) { (Object as any).assign(this, init); }
}

export enum EVALUATESTATE
{
    WAIT_EDIT = 'WAIT_EDIT',
    WAIT_FILL = 'WAIT_FILL',
    FILLING = 'FILLING',
    FILLED = 'FILLED',
    PUBLISHED = 'PUBLISHED',
}

export class KidCheckUpDTO
{
    public id?: string;
    public schoolId?: string;
    public kidId?: string;
    public reportOn?: string;
    public ageMonth?: number;
    public termId?: number;
    public semesterType?: SEMESTERTYPE;
    public state?: EVALUATESTATE;
    public scores?: ScoreItem[];
    public createByTeacherId?: string;
    public createOn?: string;
    public createUserIPAddr?: string;
    public publishOn?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public deleteOn?: string;

    public constructor(init?: Partial<KidCheckUpDTO>) { (Object as any).assign(this, init); }
}

export enum CourseSaleItemType
{
    SIGN = 'SIGN',
    DAY = 'DAY',
    MONTH = 'MONTH',
}

export class CourseSaleItemDTO
{
    public id?: string;
    public name?: string;
    public amount?: string;
    public totalPrice?: string;
    public price?: string;
    public type?: CourseSaleItemType;

    public constructor(init?: Partial<CourseSaleItemDTO>) { (Object as any).assign(this, init); }
}

export enum ClassTimeScheduleStepType
{
    EVERY_WEEK = 7,
    EVERY_OTHER_WEEK = 14,
}

export enum ClassTimeScheduleType
{
    FIXED_DATE = 'FIXED_DATE',
    PERIOD_DATE = 'PERIOD_DATE',
}

export enum ClassTimeState
{
    UNSIGN = 'UNSIGN',
    SIGNED = 'SIGNED',
    CANCELLED = 'CANCELLED',
}

export enum ClassTimeSignState
{
    UNSIGN = 'UNSIGN',
    OK = 'OK',
    LATE = 'LATE',
    LEAVE = 'LEAVE',
    ABSENCE = 'ABSENCE',
}

export enum ClassTimeStudentType
{
    FIXED = 'FIXED',
    TEMPORARY = 'TEMPORARY',
    DEMO = 'DEMO',
}

export class ClassTimeSignInfoDTO
{
    public kidId?: string;
    public state?: ClassTimeSignState;
    public usedCourseHour?: number;
    public studentType?: ClassTimeStudentType;
    public remark?: string;
    public isMakeUp?: boolean;
    public makeUpClassTimeId?: string;
    public orgStudentCourseId?: string;

    public constructor(init?: Partial<ClassTimeSignInfoDTO>) { (Object as any).assign(this, init); }
}

export enum COURSE_LOG_SOURCE
{
    SIGN = 'SIGN',
    MODIFY_SIGN = 'MODIFY_SIGN',
    VOID = 'VOID',
    BUYCONTENT = 'BUYCONTENT',
    SYSTEM_SIGN = 'SYSTEM_SIGN',
    REVERSAL = 'REVERSAL',
    MODIFY = 'MODIFY',
    REFUND = 'REFUND',
}

export class KidClassTimeChange
{
    public kidId?: string;
    public index?: number;
    public scheduleId?: string;

    public constructor(init?: Partial<KidClassTimeChange>) { (Object as any).assign(this, init); }
}

export enum SALESLEAD_FASTFILTER_TYPE
{
    All = 'All',
    ToDayFollow = 'ToDayFollow',
    WeekFollow = 'WeekFollow',
    MonthFollow = 'MonthFollow',
    ToDayNew = 'ToDayNew',
    WeekNew = 'WeekNew',
    MonthNew = 'MonthNew',
}

export enum CUSTOM_INTENT_TYPE
{
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    HIGHER = 'HIGHER',
}

export enum FOLLOWUP_STATE
{
    WAIT_ALLOCATE = 'WAIT_ALLOCATE',
    PRIVATE_ALLOCATE = 'PRIVATE_ALLOCATE',
    PUBLIC_ALLOCATE = 'PUBLIC_ALLOCATE',
    FOLLOWING = 'FOLLOWING',
    FINISHED = 'FINISHED',
}

export enum FOLLOWUP_RESULT
{
    JIANLILIANXI = 'JIANLILIANXI',
    QUERENYIXIANG = 'QUERENYIXIANG',
    CHENGNUODAOFANG = 'CHENGNUODAOFANG',
    YUYUESHITING = 'YUYUESHITING',
    WANCHENGSHITING = 'WANCHENGSHITING',
    FINISH_ORDER = 'FINISH_ORDER',
}

export enum FOLLOWUP_METHOD
{
    PHONE = 'PHONE',
    WECHAT = 'WECHAT',
    FACE2FACE = 'FACE2FACE',
    SMS = 'SMS',
    OTHER = 'OTHER',
}

export enum DEMO_STATE
{
    INCLASS = 'INCLASS',
    SIGN = 'SIGN',
    CANCEL = 'CANCEL',
}

export enum CourseOrderType
{
    BAOMING = 'BAOMING',
    SALELEAD = 'SALELEAD',
    CHONGZHI = 'CHONGZHI',
    ZHUANKE = 'ZHUANKE',
    TUIKE = 'TUIKE',
    TUIWUPING = 'TUIWUPING',
    TUIFEIYONG = 'TUIFEIYONG',
    TUIKUAN = 'TUIKUAN',
}

export class CourseOrderItem
{
    public productName?: string;
    public courseId?: string;
    public courseItemId?: string;

    public constructor(init?: Partial<CourseOrderItem>) { (Object as any).assign(this, init); }
}

export enum CourseOrderState
{
    WAIT_PAYMENT = 'WAIT_PAYMENT',
    FINISH_PAYMENT = 'FINISH_PAYMENT',
}

export class ClassTimeCommentScoreTemplateDTO
{
    public id?: string;
    public schoolId?: string;
    public name?: string;
    public dimension?: string[];
    public courseIds?: string[];
    public isEnabled?: boolean;
    public isDelete?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<ClassTimeCommentScoreTemplateDTO>) { (Object as any).assign(this, init); }
}

export class ClassTimeCommentKidDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public classTimeId?: string;
    public kidId?: string;
    public scores?: { [index: string]: number; };
    public content?: string;
    public attachment?: EventAttachDTO;
    public createByTeacherId?: string;
    public ipAddr?: string;
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public deleteOn?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<ClassTimeCommentKidDTO>) { (Object as any).assign(this, init); }
}

export class ClassTimeCommentTeacherDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public classTimeId?: string;
    public scores?: { [index: string]: number; };
    public content?: string;
    public kidId?: string;
    public createByParentId?: string;
    public ipAddr?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<ClassTimeCommentTeacherDTO>) { (Object as any).assign(this, init); }
}

export class ParentRelation
{
    public userProfileId?: string;
    public schoolId?: string;

    public constructor(init?: Partial<ParentRelation>) { (Object as any).assign(this, init); }
}

export enum USERROLE
{
    ROLE_TEACHER = 'ROLE_TEACHER',
    ROLE_PARENT = 'ROLE_PARENT',
    ROLE_STUDENT = 'ROLE_STUDENT',
}

export enum LEAVE_REQUEST_TYPE
{
    LEAVE = 'LEAVE',
    ATTENDANCE = 'ATTENDANCE',
}

export enum LEAVESTATE
{
    WAIT_APPROVE = 'WAIT_APPROVE',
    APPROVE = 'APPROVE',
    REFUSE = 'REFUSE',
    SELF_CANCEL = 'SELF_CANCEL',
}

export enum LIVECLASSROOMSTATUS
{
    WAIT_SCHEDULE = 'WAIT_SCHEDULE',
    OPENING = 'OPENING',
    CLOSED = 'CLOSED',
    CANCELED = 'CANCELED',
}

export enum SUBMCH_TYPE
{
    TYPE_SCHOOL = 'TYPE_SCHOOL',
    TYPE_AGENT = 'TYPE_AGENT',
}

export enum ORDERPAYMENT_TYPE
{
    WECHAT = 'WECHAT',
    IOSIAP = 'IOSIAP',
    ALIPAY = 'ALIPAY',
    SFT_WXLITE = 'SFT_WXLITE',
    SFT_WXJSAPI = 'SFT_WXJSAPI',
}

export enum ORDERPRODUCT_CATALOG
{
    CLASSLIVE = 'CLASSLIVE',
    COIN = 'COIN',
    MEMBER = 'MEMBER',
    MARKETING = 'MARKETING',
    CLASSBILL = 'CLASSBILL',
}

export class PaymentSubMchShareConfigDTO
{
    public productCatalog?: ORDERPRODUCT_CATALOG;
    public sharePercent?: number;
    public isEnabled?: boolean;

    public constructor(init?: Partial<PaymentSubMchShareConfigDTO>) { (Object as any).assign(this, init); }
}

export class PaymentSubMchInfoDTO
{
    public id?: string;
    public schoolId?: string;
    public subMchType?: SUBMCH_TYPE;
    public paymentType?: ORDERPAYMENT_TYPE;
    public createOn?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public shareConfigs?: PaymentSubMchShareConfigDTO[];
    public balanceWaitSharingAmount?: number;
    public balanceWaitDrawAmount?: number;
    public balanceAvaliableAmount?: number;
    public fullBalanceWaitSharingAmount?: number;
    public fullBalanceWaitDrawAmount?: number;
    public fullBalanceAvaliableAmount?: number;
    public fullWaitConfirmAmount?: number;

    public constructor(init?: Partial<PaymentSubMchInfoDTO>) { (Object as any).assign(this, init); }
}

export enum SUBMCH_STATE
{
    INIT = 'INIT',
    WAIT_LOCALCHECK = 'WAIT_LOCALCHECK',
    WAIT_BANKCHECK = 'WAIT_BANKCHECK',
    NORMAL = 'NORMAL',
    WAIT_UPLOADBIZIMG = 'WAIT_UPLOADBIZIMG',
    FINISH_UPLOADBIZIMG = 'FINISH_UPLOADBIZIMG',
    BANKCHECK_FAIL = 'BANKCHECK_FAIL',
}

export enum SUBJECT_TYPE
{
    PERSONAL = 'PERSONAL',
    ENTERPRISE = 'ENTERPRISE',
    INDIVIDUAL = 'INDIVIDUAL',
}

export enum APPLY_TYPE
{
    LEGAL = 'LEGAL',
    PRINCIPAL = 'PRINCIPAL',
}

export enum OCCUPATION_TYPE
{
    _10000 = 10000,
    _20000 = 20000,
    _20700 = 20700,
    _20800 = 20800,
    _30000 = 30000,
    _40000 = 40000,
    _40100 = 40100,
    _40300 = 40300,
    _40500 = 40500,
    _40700 = 40700,
    _41300 = 41300,
    _50000 = 50000,
    _60000 = 60000,
    _62700 = 62700,
    _70000 = 70000,
    _80000 = 80000,
}

export enum INDUSTRY_TYPE
{
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    I = 'I',
    J = 'J',
    K = 'K',
    L = 'L',
    M = 'M',
    N = 'N',
    O = 'O',
    P = 'P',
    Q = 'Q',
    R = 'R',
    S = 'S',
    T = 'T',
    X = 'X',
}

export class SubMchBaseInfoDTO
{
    public linkMan?: string;
    public phoneNo?: string;
    public occupation?: OCCUPATION_TYPE;
    public industry?: INDUSTRY_TYPE;

    public constructor(init?: Partial<SubMchBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class SubMchMediaInfoDTO
{
    public ossId?: string;
    public fileName?: string;

    public constructor(init?: Partial<SubMchMediaInfoDTO>) { (Object as any).assign(this, init); }
}

export class SubMchBusinessLicenseInfoDTO
{
    public merchantName?: string;
    public licenseNumber?: string;
    public licenseCopy?: SubMchMediaInfoDTO;
    public licensePeriodBegin?: string;
    public licensePeriodEnd?: string;

    public constructor(init?: Partial<SubMchBusinessLicenseInfoDTO>) { (Object as any).assign(this, init); }
}

export class SubMchOrganizationInfoDTO
{
    public organizationInfoNumber?: string;
    public orgCodeImg?: SubMchMediaInfoDTO;

    public constructor(init?: Partial<SubMchOrganizationInfoDTO>) { (Object as any).assign(this, init); }
}

export enum IDDOC_TYPE
{
    RESIDENT_ID_CARD = 'RESIDENT_ID_CARD',
    PROVISIONAL_RESIDENT_ID_CARD = 'PROVISIONAL_RESIDENT_ID_CARD',
    RESIDENCE_BOOKLET = 'RESIDENCE_BOOKLET',
    MAINLAND_TRAVEL_PERMIT_FOR_HK_AND_MAC = 'MAINLAND_TRAVEL_PERMIT_FOR_HK_AND_MAC',
    MAINLAND_TRAVEL_PERMIT_FOR_TW = 'MAINLAND_TRAVEL_PERMIT_FOR_TW',
    PASSPORT = 'PASSPORT',
    OTHERS = 'OTHERS',
}

export class SubMchIdentityInfoDTO
{
    public idDocType?: IDDOC_TYPE;
    public idDocName?: string;
    public idDocNumber?: string;
    public idDocCopy?: SubMchMediaInfoDTO;
    public idDocNational?: SubMchMediaInfoDTO;
    public docPeriodBegin?: string;
    public docPeriodEnd?: string;
    public handCertiImg?: SubMchMediaInfoDTO;

    public constructor(init?: Partial<SubMchIdentityInfoDTO>) { (Object as any).assign(this, init); }
}

export class SubMchBusinessInfoDTO
{
    public merchantShortName?: string;
    public merProvinceId?: number;
    public merProvinceName?: string;
    public merCityId?: number;
    public merCityName?: string;
    public merCountyId?: number;
    public merCountyName?: string;
    public address?: string;
    public businessScope?: string;

    public constructor(init?: Partial<SubMchBusinessInfoDTO>) { (Object as any).assign(this, init); }
}

export enum BANKACCOUNT_TYPE
{
    B = 'B',
    C = 'C',
}

export class SubMchBankAccountInfoDTO
{
    public bankAccountType?: BANKACCOUNT_TYPE;
    public bankAccount?: string;
    public bankName?: string;
    public bankCode?: string;
    public bankProvinceId?: number;
    public bankProvinceName?: string;
    public bankCityId?: number;
    public bankCityName?: string;
    public bankBranchName?: string;
    public bankReservePhoneNo?: string;
    public licenseCopy?: SubMchMediaInfoDTO;

    public constructor(init?: Partial<SubMchBankAccountInfoDTO>) { (Object as any).assign(this, init); }
}

export class ShengPaySubMchInfoDTO
{
    public id?: string;
    public applyState?: SUBMCH_STATE;
    public applyDesc?: string;
    public isShareReceiver?: boolean;
    public subMchId?: string;
    public applyId?: string;
    public bindCardStatus?: string;
    public subjectType?: SUBJECT_TYPE;
    public applyType?: APPLY_TYPE;
    public baseInfo?: SubMchBaseInfoDTO;
    public businessLicenseInfo?: SubMchBusinessLicenseInfoDTO;
    public organizationInfo?: SubMchOrganizationInfoDTO;
    public identityInfo?: SubMchIdentityInfoDTO;
    public businessInfo?: SubMchBusinessInfoDTO;
    public bankAccountInfo?: SubMchBankAccountInfoDTO;
    public isAutoWithdraw?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;

    public constructor(init?: Partial<ShengPaySubMchInfoDTO>) { (Object as any).assign(this, init); }
}

export enum ORDER_SHARING_STATE
{
    WAIT = 'WAIT',
    PROCESSED = 'PROCESSED',
    CANCELED = 'CANCELED',
}

export enum SUBMCH_DRAW_STATE
{
    INIT = 'INIT',
    WAIT_DEDUCT = 'WAIT_DEDUCT',
    FINISH_DEDUCT = 'FINISH_DEDUCT',
    WAIT_DRAW = 'WAIT_DRAW',
    ALL_FINISH = 'ALL_FINISH',
}

export enum SUBMCH_BIZ_TYPE
{
    INCOMING = 'INCOMING',
    REFUND = 'REFUND',
    DRAW = 'DRAW',
    SERVICE_FEE = 'SERVICE_FEE',
}

export enum REFUNDORDERSTATUS
{
    REFUND_INIT = 'REFUND_INIT',
    REFUND_WAITRETURN = 'REFUND_WAITRETURN',
    REFUND_ING = 'REFUND_ING',
    REFUND_SUCCESS = 'REFUND_SUCCESS',
    REFUND_FAIL = 'REFUND_FAIL',
}

export enum PERMISSION_TYPE
{
    partner_sales = 'partner_sales',
    partner_sales_saleslead = 'partner_sales_saleslead',
    partner_sales_saleslead_edit = 'partner_sales_saleslead_edit',
    partner_sales_followup = 'partner_sales_followup',
    partner_sales_followup_edit = 'partner_sales_followup_edit',
    partner_sales_setting = 'partner_sales_setting',
    partner_school_home = 'partner_school_home',
    partner_school_teacher = 'partner_school_teacher',
    partner_school_kid = 'partner_school_kid',
    partner_school_class = 'partner_school_class',
    partner_school_course = 'partner_school_course',
    partner_knowledge_teacherstudy = 'partner_knowledge_teacherstudy',
    partner_knowledge_teacherstudy_content_edit = 'partner_knowledge_teacherstudy_content_edit',
    partner_knowledge_teacherstudy_catalog_edit = 'partner_knowledge_teacherstudy_catalog_edit',
    partner_dashboard_school = 'partner_dashboard_school',
    partner_dashboard_babycare = 'partner_dashboard_babycare',
    partner_dashboard_schoolsaleslead = 'partner_dashboard_schoolsaleslead',
    partner_dashboard_coursehour = 'partner_dashboard_coursehour',
    partner_dashboard_class = 'partner_dashboard_class',
    partner_dashboard_applogin = 'partner_dashboard_applogin',
    partner_system_user = 'partner_system_user',
    partner_system_user_edit = 'partner_system_user_edit',
    admin_home_schools_getall = 'admin_home_schools_getall',
    school_admin = 'school_admin',
    school_admin_sensitive = 'school_admin_sensitive',
    classes_audit = 'classes_audit',
    educational_imcontacts_getall = 'educational_imcontacts_getall',
    school_kgtime_getall = 'school_kgtime_getall',
    school_kgtime_addallclass = 'school_kgtime_addallclass',
    school_classes_getall = 'school_classes_getall',
    school_medicare_getall = 'school_medicare_getall',
    school_leave_kid_getall = 'school_leave_kid_getall',
    school_leave_kid = 'school_leave_kid',
    school_leave_teacher = 'school_leave_teacher',
    classes_eventtemplates_edit = 'classes_eventtemplates_edit',
    educational_healthy_getall = 'educational_healthy_getall',
    educational_foodmenu_getall = 'educational_foodmenu_getall',
    educational_teacherstudy_modify = 'educational_teacherstudy_modify',
    school_settings_topictest = 'school_settings_topictest',
    school_live_channel_getall = 'school_live_channel_getall',
    teachorg_classtimecomment_getall = 'teachorg_classtimecomment_getall',
    sales_order_modify = 'sales_order_modify',
    sales_order_delete = 'sales_order_delete',
    sales_speakingart = 'sales_speakingart',
    sales_speakingart_edit = 'sales_speakingart_edit',
    services_patrol = 'services_patrol',
    services_patrol_area = 'services_patrol_area',
    services_patrol_areaitem = 'services_patrol_areaitem',
    services_patrollog = 'services_patrollog',
    services_patrol_edit = 'services_patrol_edit',
    services_patrollog_edit = 'services_patrollog_edit',
}

export enum ORDER_DIRECTION
{
    ascend = 'ascend',
    descend = 'descend',
}

export enum CLASS_STATUS
{
    NORMAL = 'NORMAL',
    GRADUATION = 'GRADUATION',
}

export enum CourseSignType
{
    SIGN = 'SIGN',
    TIME = 'TIME',
    MONTH = 'MONTH',
}

export class SchoolPartnerUserBaseDTO
{
    public id?: string;
    public userId?: number;
    public nickName?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<SchoolPartnerUserBaseDTO>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerPermissionDTO
{
    public permissionCode?: string;
    public permissionCodes?: string[];
    public schoolIds?: string[];

    public constructor(init?: Partial<SchoolPartnerPermissionDTO>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerUserDTO extends SchoolPartnerUserBaseDTO
{
    public schoolPartnerInfoId?: string;
    public permissions?: { [index: string]: SchoolPartnerPermissionDTO; };
    public createIPAddr?: string;
    public createBy?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public deleteBy?: number;
    public deleteOn?: string;

    public constructor(init?: Partial<SchoolPartnerUserDTO>) { super(init); (Object as any).assign(this, init); }
}

export class SchoolPartnerSaleConfig
{
    public isSystem?: boolean;
    public key?: string;
    public title?: string;
    public valueType?: string;
    public values?: string[];

    public constructor(init?: Partial<SchoolPartnerSaleConfig>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerSettingDTO
{
    public id?: string;
    public saleConfigs?: { [index: string]: SchoolPartnerSaleConfig; };
    public followUpMethods?: string[];
    public followUpTypes?: string[];
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<SchoolPartnerSettingDTO>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerSalesLeadLinkMan
{
    public jobTitle?: string;
    public linkMan?: string;
    public linkManPhone?: string;

    public constructor(init?: Partial<SchoolPartnerSalesLeadLinkMan>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerSalesLeadDTO
{
    public id?: string;
    public schoolPartnerInfoId?: string;
    public merchantName?: string;
    public merchantShortName?: string;
    public address?: string;
    public linkMans?: SchoolPartnerSalesLeadLinkMan[];
    public score?: number;
    public remark?: string;
    public tags?: string[];
    public source?: string;
    public type?: string;
    public merchantType?: string;
    public merchantLevel?: string;
    public state?: string;
    public extra?: { [index: string]: string; };
    public attachment?: EventAttachDTO;
    public userIPAddr?: string;
    public createBy?: number;
    public createOn?: string;
    public lastModifyBy?: number;
    public lastModifyOn?: string;
    public assignmentOn?: string;
    public followUpUserIds?: number[];
    public lastFollowUpId?: string;
    public lastFollowUpOn?: string;
    public nextFollowUpOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public deleteBy?: number;

    public constructor(init?: Partial<SchoolPartnerSalesLeadDTO>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerFollowUpInfoDTO
{
    public id?: string;
    public schoolPartnerInfoId?: string;
    public schoolPartnerSalesLeadId?: string;
    public linkMan?: string;
    public linkManPhone?: string;
    public followUpMethod?: string;
    public followUpType?: string;
    public followUpOn?: string;
    public nextFollowUpOn?: string;
    public followUpContent?: string;
    public attachment?: EventAttachDTO;
    public userIPAddr?: string;
    public createBy?: number;
    public createOn?: string;
    public lastModifyBy?: number;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public deleteBy?: number;

    public constructor(init?: Partial<SchoolPartnerFollowUpInfoDTO>) { (Object as any).assign(this, init); }
}

export enum CLASSGRADETYPE
{
    GRADE_PRE = 'GRADE_PRE',
    GRADE_1 = 'GRADE_1',
    GRADE_2 = 'GRADE_2',
    GRADE_3 = 'GRADE_3',
    GRADE_OTHER = 'GRADE_OTHER',
}

export class GradeNameMap
{
    public gradeType?: CLASSGRADETYPE;
    public gradeName?: string;

    public constructor(init?: Partial<GradeNameMap>) { (Object as any).assign(this, init); }
}

export enum SCHOOL_MINISITE_ITEMTYPE
{
    HONOR_DESC = 'HONOR_DESC',
    HONOR = 'HONOR',
    SCHOOL_ENV_DESC = 'SCHOOL_ENV_DESC',
    SCHOOL_ENV = 'SCHOOL_ENV',
    CLASS = 'CLASS',
    TEACHER_DESC = 'TEACHER_DESC',
    TEACHER = 'TEACHER',
    STUDENT = 'STUDENT',
    NEWS = 'NEWS',
    ACTIVITY = 'ACTIVITY',
}

export class SchoolSiteContentItemDTO
{
    public id?: string;
    public title?: string;
    public content?: string;
    public imageUrl?: string;
    public sortIndex?: number;

    public constructor(init?: Partial<SchoolSiteContentItemDTO>) { (Object as any).assign(this, init); }
}

export class SchoolSiteTitle
{
    public schoolTitle?: string;
    public teacherTitle?: string;
    public studentTitle?: string;
    public honorTitle?: string;
    public schoolEnvTitle?: string;
    public classTitle?: string;
    public activityTitle?: string;
    public newsTitle?: string;
    public footerButtonTitle?: string;

    public constructor(init?: Partial<SchoolSiteTitle>) { (Object as any).assign(this, init); }
}

export class SchoolSiteDTO
{
    public id?: string;
    public honorDesc?: string;
    public honorItems?: SchoolSiteContentItemDTO[];
    public schoolEnvDesc?: string;
    public schoolEnvImageUrls?: string[];
    public classItems?: SchoolSiteContentItemDTO[];
    public teacherDesc?: string;
    public title?: SchoolSiteTitle;
    public teacherItems?: SchoolSiteContentItemDTO[];
    public studentItems?: SchoolSiteContentItemDTO[];
    public newsItems?: SchoolSiteContentItemDTO[];
    public activityItems?: SchoolSiteContentItemDTO[];

    public constructor(init?: Partial<SchoolSiteDTO>) { (Object as any).assign(this, init); }
}

export enum SCHOOL_DEPT_TYPE
{
    HEADQUARTERS = 'HEADQUARTERS',
    DEPARTMENT = 'DEPARTMENT',
    BRANCH = 'BRANCH',
}

export class GeoCoordinates
{
    public lng?: number;
    public lat?: number;

    public constructor(init?: Partial<GeoCoordinates>) { (Object as any).assign(this, init); }
}

export enum TOPIC_TEST_SOURCE_TYPE
{
    EVENT = 'EVENT',
    EVENT_COMMENT = 'EVENT_COMMENT',
    EVENT_LIVECLASSROOM = 'EVENT_LIVECLASSROOM',
    EVENT_TASK_ITEM = 'EVENT_TASK_ITEM',
    EVENT_TASK_ITEM_COMMENT = 'EVENT_TASK_ITEM_COMMENT',
    EVENT_CLOCKIN_ITEM = 'EVENT_CLOCKIN_ITEM',
    EVENT_CLOCKIN_ITEM_COMMENT = 'EVENT_CLOCKIN_ITEM_COMMENT',
    IM = 'IM',
    EVENT_DAILYMENU = 'EVENT_DAILYMENU',
}

export class BaseSource
{
    public schoolId?: string;
    public isCreateByTeacher?: boolean;
    public createByUId?: number;
    public createByKidId?: string;
    public createOn?: string;
    public source?: TOPIC_TEST_SOURCE_TYPE;
    public eventId?: string;
    public eventIds?: string[];
    public liveClassRoomId?: string;
    public eventCommentId?: string;
    public taskItemId?: string;
    public clockInGroupId?: string;
    public clockInItemId?: string;
    public content?: string;
    public dailyMenuId?: string;

    public constructor(init?: Partial<BaseSource>) { (Object as any).assign(this, init); }
}

export enum TOPIC_TEST_KEYWORD_TYPE
{
    ANQUAN = 'ANQUAN',
    TOUSU = 'TOUSU',
    FEIYON = 'FEIYON',
    WEISHENG = 'WEISHENG',
    YUYAN = 'YUYAN',
    ZIDINGYI = 'ZIDINGYI',
}

export class TopicTestKeysWordsV2
{
    public data?: string[];

    public constructor(init?: Partial<TopicTestKeysWordsV2>) { (Object as any).assign(this, init); }
}

export class TopicTestSchoolKeyWordV2DTO
{
    public id?: string;
    public isEnabled?: boolean;
    public keysWords?: { [index: string]: TopicTestKeysWordsV2; };

    public constructor(init?: Partial<TopicTestSchoolKeyWordV2DTO>) { (Object as any).assign(this, init); }
}

export class TopicTestKeysWords extends Array<string>
{

    public constructor(init?: Partial<TopicTestKeysWords>) { super(); (Object as any).assign(this, init); }
}

export class TopicTestSchoolKeyWordDTO
{
    public id?: string;
    public isEnabled?: boolean;
    public keysWords?: { [index: string]: TopicTestKeysWords; };

    public constructor(init?: Partial<TopicTestSchoolKeyWordDTO>) { (Object as any).assign(this, init); }
}

export enum EVENTAUDITSTATE
{
    NONEED_AUDIT = 'NONEED_AUDIT',
    WAIT_AUDIT = 'WAIT_AUDIT',
    FINISH_AUDITED = 'FINISH_AUDITED',
}

export enum EVENTPUBLISHSTATE
{
    STATE_ALLSEE = 'STATE_ALLSEE',
    STATE_INIT = 'STATE_INIT',
    STATE_OWNERSEE = 'STATE_OWNERSEE',
    STATE_WAITAUDIT = 'STATE_WAITAUDIT',
    STATE_DELETE = 'STATE_DELETE',
}

export enum SCHOOL_AUDIT_OPERATER_TYPE
{
    DATA = 'DATA',
    VIEW = 'VIEW',
}

export enum SCHOOL_SERVICE_SUB_MODULE
{
    NONE = 0,
    BASE = 1100,
    ADMIN = 1150,
    WECHATMP = 1170,
    TEACHER = 1200,
    CLASS = 1250,
    KID = 1300,
    SCHOOL_NOTICE = 1350,
    ATTENDANCE = 1400,
    SMS = 1425,
    LEAVE = 1450,
    LEAVE_TEACHER = 1500,
    COMMISSION_PICKUP = 1550,
    MEDICARE = 1600,
    KIDACCOUNT_BALANCE = 1650,
    CLASSBILL = 1700,
    TEACHERSTUDY = 1750,
    EVENT_TEMPLATES = 1800,
    CLASSES_BAOKU = 1850,
    NETDISK = 1900,
    SALES = 2100,
    COURSE = 2150,
    COURSEORDER = 2200,
    CLASSTIMECOMMENT = 2250,
    TING = 2300,
    SPEAKING_ART = 2500,
    POSTER = 3100,
    PUBLICITY = 3150,
    MINISITE = 3200,
    MARKETING = 3250,
    TOPICTEST = 4100,
    WATCH = 4150,
    BABYCARE = 4200,
    LIVECLASSROOM = 4250,
    LIVECHANNEL = 4300,
    DAILYHEALTHY = 4350,
    KIDCHECKUP = 4360,
    DAILYMENU = 4400,
    IOTAIR = 4450,
    PATROL = 4500,
}

export enum SCHOOL_AUDIT_TYPE
{
    ADD = 'ADD',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export enum DishType
{
    OTHER = 'OTHER',
    STAPLE = 'STAPLE',
    CANDY = 'CANDY',
    NOODLE = 'NOODLE',
    GRUEL = 'GRUEL',
    COLD_DISH = 'COLD_DISH',
    VEGETABLE = 'VEGETABLE',
    MEAT = 'MEAT',
    SOUP = 'SOUP',
    DRINK = 'DRINK',
    FRUIT = 'FRUIT',
    STAPLE_BABY = 'STAPLE_BABY',
    CANDY_BABY = 'CANDY_BABY',
    NOODLE_BABY = 'NOODLE_BABY',
    COLD_DISH_BABY = 'COLD_DISH_BABY',
    GRUEL_BABY = 'GRUEL_BABY',
    VEGETABLE_BABY = 'VEGETABLE_BABY',
    MEAT_BABY = 'MEAT_BABY',
    SOUP_BABY = 'SOUP_BABY',
    DRINK_BABY = 'DRINK_BABY',
    FRUIT_BABY = 'FRUIT_BABY',
    WESTERN_FOOD = 'WESTERN_FOOD',
    QINGZHEN = 'QINGZHEN',
}

export enum IngredientType
{
    GRAIN = 1,
    MILK = 2,
    EGG = 3,
    FISH = 4,
    BABY = 5,
    SNACK = 6,
    FASTFOOD = 7,
    DRINK = 8,
    ALCOHOL = 9,
    SUGAR = 10,
    OIL = 11,
    RICE = 12,
    SPICE = 13,
    DRUG = 14,
    BEAN = 15,
    VEGETABLE = 16,
    BACTERIA = 17,
    FRUIT = 18,
    NUT = 19,
    MEAT = 20,
    POULTRY = 21,
}

export class NutritionBase
{
    public energy?: number;
    public fat?: number;
    public protein?: number;
    public fiber?: number;
    public carbohydrate?: number;

    public constructor(init?: Partial<NutritionBase>) { (Object as any).assign(this, init); }
}

export class Nutrition extends NutritionBase
{
    public va?: number;
    public carotene?: number;
    public vb1?: number;
    public vb2?: number;
    public vb3?: number;
    public vc?: number;
    public ve?: number;
    public ca?: number;
    public p?: number;
    public k?: number;
    public na?: number;
    public mg?: number;
    public fe?: number;
    public zn?: number;
    public se?: number;
    public cu?: number;
    public mn?: number;
    public i?: number;

    public constructor(init?: Partial<Nutrition>) { super(init); (Object as any).assign(this, init); }
}

export class DishItemDTO
{
    public id?: string;
    public dishName?: string;
    public nutritions?: Nutrition;
    public dishType?: DishType;
    public ingredientAmount?: { [index: string]: number; };
    public isDelete?: boolean;
    public schoolId?: string;
    public createByTeacherId?: string;
    public createOn?: string;

    public constructor(init?: Partial<DishItemDTO>) { (Object as any).assign(this, init); }
}

export class IngredientItemDTO
{
    public id?: string;
    public alias?: string;
    public ingredientName?: string;
    public ingredientType?: IngredientType;
    public nutritions?: Nutrition;
    public isDelete?: boolean;
    public schoolId?: string;
    public createByTeacherId?: string;
    public createOn?: string;

    public constructor(init?: Partial<IngredientItemDTO>) { (Object as any).assign(this, init); }
}

export class DishMenuDishDTO
{
    public id?: string;
    public ingredientAmount?: { [index: string]: number; };

    public constructor(init?: Partial<DishMenuDishDTO>) { (Object as any).assign(this, init); }
}

export class DishMenuContentDTO
{
    public title?: string;
    public dishs?: DishMenuDishDTO[];
    public attachment?: EventAttachDTO;

    public constructor(init?: Partial<DishMenuContentDTO>) { (Object as any).assign(this, init); }
}

export class DishMenuDTO
{
    public dateOn?: string;
    public nutritions?: Nutrition;
    public contents?: DishMenuContentDTO[];

    public constructor(init?: Partial<DishMenuDTO>) { (Object as any).assign(this, init); }
}

export class DishMenuWeeklyDTO
{
    public id?: string;
    public schoolId?: string;
    public dateOn?: string;
    public title?: string;
    public createByTeacherId?: string;
    public nutritions?: Nutrition;
    public dishMenus?: DishMenuDTO[];
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public deleteOn?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<DishMenuWeeklyDTO>) { (Object as any).assign(this, init); }
}

export class PatrolAreaDTO
{
    public id?: string;
    public schoolId?: string;
    public title?: string;
    public remark?: string;
    public isEnable?: boolean;
    public createOn?: string;
    public createByTeacherId?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public deleteByTeacherId?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<PatrolAreaDTO>) { (Object as any).assign(this, init); }
}

export enum PATRO_CHECK_RESULT
{
    OK = 'OK',
    WARN = 'WARN',
}

export class PatrolAreaItemDTO
{
    public id?: string;
    public schoolId?: string;
    public patrolAreaId?: string;
    public patrolType?: string;
    public title?: string;
    public subTilte?: string;
    public remark?: string;
    public isEnable?: boolean;
    public createOn?: string;
    public createByTeacherId?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public deleteByTeacherId?: string;
    public lastCheckId?: string;
    public lastCheckResult?: PATRO_CHECK_RESULT;
    public lastCheckOn?: string;
    public lastCheckByTeacherId?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<PatrolAreaItemDTO>) { (Object as any).assign(this, init); }
}

export class PatrolCheckLogDTO
{
    public id?: string;
    public schoolId?: string;
    public patrolAreaId?: string;
    public patrolAreaItemId?: string;
    public patrolType?: string;
    public remark?: string;
    public checkOn?: string;
    public checkResult?: PATRO_CHECK_RESULT;
    public attachment?: EventAttachDTO;
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public deleteOn?: string;
    public createOn?: string;
    public createByTeacherId?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<PatrolCheckLogDTO>) { (Object as any).assign(this, init); }
}

export class SpeakingArtCatalogDTO
{
    public id?: string;
    public schoolId?: string;
    public name?: string;
    public isDelete?: boolean;
    public deleteBy?: string;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public createBy?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<SpeakingArtCatalogDTO>) { (Object as any).assign(this, init); }
}

export class SpeakingArtItemDTO
{
    public id?: string;
    public schoolId?: string;
    public speakingArtCatalogId?: string;
    public title?: string;
    public content?: string;
    public attachment?: EventAttachDTO;
    public orderIndex?: number;
    public isDelete?: boolean;
    public deleteBy?: string;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public createBy?: string;
    public createIPAddr?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<SpeakingArtItemDTO>) { (Object as any).assign(this, init); }
}

export class EvaluateItemKidScoreDTO
{
    public kidId?: string;
    public kidInfo?: KidInfoDTO;
    public score?: number;

    public constructor(init?: Partial<EvaluateItemKidScoreDTO>) { (Object as any).assign(this, init); }
}

export class EvaluateItemBaseDTO
{
    public id?: string;
    public title?: string;
    public subTitle?: string;
    public desc?: string;
    public orderIndex?: number;
    public createBy?: number;
    public createByUserName?: string;
    public createOn?: string;
    public isGlobal?: boolean;

    public constructor(init?: Partial<EvaluateItemBaseDTO>) { (Object as any).assign(this, init); }
}

export enum EVALUATETYPE
{
    DAILY_EVALUATE = 'DAILY_EVALUATE',
    SEMESTER_EVALUATE = 'SEMESTER_EVALUATE',
}

export enum SCHOOL_TYPE
{
    KINDERGARTEN = 'KINDERGARTEN',
    EDUAFFAIR = 'EDUAFFAIR',
    SCHOOL = 'SCHOOL',
    ALL = 'ALL',
}

export enum KGLIB_SHARETYPE
{
    ONLYME = 'ONLYME',
    RANGE_CLASS = 'RANGE_CLASS',
    RANGE_SCHOOL = 'RANGE_SCHOOL',
    RANGE_ALL = 'RANGE_ALL',
}

export class VoteItemSerial
{
    public typeName?: string;
    public jsonString?: string;

    public constructor(init?: Partial<VoteItemSerial>) { (Object as any).assign(this, init); }
}

export class KeyValuePair<TKey, TValue>
{
    public key: TKey;
    public value: TValue;

    public constructor(init?: Partial<KeyValuePair<TKey, TValue>>) { (Object as any).assign(this, init); }
}

export class SchoolPageExtendDTO
{
    public ossId?: string;
    public imageUrl?: string;
    public title?: string;
    public pageIndex?: number;

    public constructor(init?: Partial<SchoolPageExtendDTO>) { (Object as any).assign(this, init); }
}

export class EvaluateItemWithScoreDTO
{
    public evaluateItemId?: string;
    public score?: number;

    public constructor(init?: Partial<EvaluateItemWithScoreDTO>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerOpenEndDTO
{
    public schoolId?: string;
    public openOn?: string;
    public closeOn?: string;

    public constructor(init?: Partial<SchoolPartnerOpenEndDTO>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerTeacherStudyCatalogDTO
{
    public id?: string;
    public schoolPartnerInfoId?: string;
    public openEnds?: SchoolPartnerOpenEndDTO[];
    public name?: string;
    public isDelete?: boolean;
    public deleteBy?: number;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public createBy?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<SchoolPartnerTeacherStudyCatalogDTO>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerTeacherStudyItemDTO
{
    public id?: string;
    public schoolPartnerInfoId?: string;
    public teacherStudyCatalogId?: string;
    public title?: string;
    public content?: string;
    public attachment?: EventAttachDTO;
    public publishState?: EVENTPUBLISHSTATE;
    public openOn?: string;
    public closeOn?: string;
    public orderIndex?: number;
    public likes?: string[];
    public views?: string[];
    public commentCount?: number;
    public isDelete?: boolean;
    public deleteBy?: number;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public createBy?: number;
    public createIPAddr?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public updateTimestamp?: number;

    public constructor(init?: Partial<SchoolPartnerTeacherStudyItemDTO>) { (Object as any).assign(this, init); }
}

export class FileItemDTO
{
    public name?: string;
    public fileId?: string;
    public fileOssId?: string;
    public createOn?: string;
    public fileSize?: number;
    public fileExt?: string;
    public fileUrl?: string;
    public playSeconds?: number;
    public thumbnilUrl?: string;
    public aspectRatio?: string;

    public constructor(init?: Partial<FileItemDTO>) { (Object as any).assign(this, init); }
}

export class FileDirDTO
{
    public id?: string;
    public dirName?: string;
    public level?: number;
    public fileItems?: FileItemDTO[];

    public constructor(init?: Partial<FileDirDTO>) { (Object as any).assign(this, init); }
}

export class FileDirHaveChildDTO
{
    public id?: string;
    public fileItems?: FileItemDTO[];
    public level?: number;
    public childDirs?: FileDirDTO[];

    public constructor(init?: Partial<FileDirHaveChildDTO>) { (Object as any).assign(this, init); }
}

export class VoteItemSelectedUserDTO
{
    public kidId?: string;
    public parentId?: string;
    public parentRole?: string;
    public createOn?: string;

    public constructor(init?: Partial<VoteItemSelectedUserDTO>) { (Object as any).assign(this, init); }
}

export class AttachVideoItem
{
    public thumbnilUri?: string;
    public videoUri?: string;
    public resId?: string;
    public playSeconds?: number;
    public aspectRatio?: string;

    public constructor(init?: Partial<AttachVideoItem>) { (Object as any).assign(this, init); }
}

export class AttachLinkItem
{
    public linkUri?: string;
    public linkTitle?: string;
    public linkIcon?: string;

    public constructor(init?: Partial<AttachLinkItem>) { (Object as any).assign(this, init); }
}

export class AttachImageItem
{
    public rotate?: number;
    public imageUri?: string;
    public thumbnilUri?: string;
    public resId?: string;

    public constructor(init?: Partial<AttachImageItem>) { (Object as any).assign(this, init); }
}

export class AttachFileItem
{
    public resId?: string;
    public fileUri?: string;
    public fileType?: string;
    public fileName?: string;

    public constructor(init?: Partial<AttachFileItem>) { (Object as any).assign(this, init); }
}

export class TeacherStudyCommentItemDTO
{
    public id?: string;
    public content?: string;
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public createByTeacherId?: string;
    public createOn?: string;
    public createIPAddr?: string;
    public replyToItemId?: string;

    public constructor(init?: Partial<TeacherStudyCommentItemDTO>) { (Object as any).assign(this, init); }
}

export class TeacherStudyItemDTO
{
    public id?: string;
    public schoolId?: string;
    public branchIds?: string[];
    public teacherStudyCatalogId?: string;
    public title?: string;
    public content?: string;
    public attachment?: EventAttachDTO;
    public publishState?: EVENTPUBLISHSTATE;
    public openOn?: string;
    public closeOn?: string;
    public orderIndex?: number;
    public likes?: string[];
    public views?: string[];
    public isViewed?: boolean;
    public commentCount?: number;
    public comments?: TeacherStudyCommentItemDTO[];
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public createByTeacherId?: string;
    public createIPAddr?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public updateTimestamp?: number;

    public constructor(init?: Partial<TeacherStudyItemDTO>) { (Object as any).assign(this, init); }
}

export class TeacherBaseInfoDTO
{
    public id?: string;
    public fullName?: string;
    public phoneNumber?: string;
    public logoUrl?: string;
    public userProfileId?: string;

    public constructor(init?: Partial<TeacherBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class OssInfoDTO
{
    public resId?: string;
    public fileUri?: string;
    public thumbnilUri?: string;
    public playSeconds?: number;
    public aspectRatio?: string;
    public fileExt?: string;
    public fileName?: string;

    public constructor(init?: Partial<OssInfoDTO>) { (Object as any).assign(this, init); }
}

export class TeacherStudyItemDTO_ExtraFields extends TeacherStudyItemDTO
{
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<TeacherStudyItemDTO_ExtraFields>) { super(init); (Object as any).assign(this, init); }
}

export enum EVENT_HOMETIME_SCOPE
{
    SCOPE_ALL = 'SCOPE_ALL',
    SCOPE_FAMILY = 'SCOPE_FAMILY',
    SCOPE_FAMILY_TEACHER = 'SCOPE_FAMILY_TEACHER',
}

export enum NOTIFY_FEEDBACK_STATUS
{
    FEEDBACK_YUE = 'FEEDBACK_YUE',
    FEEDBACK_ZAN = 'FEEDBACK_ZAN',
    FEEDBACK_YIHUO = 'FEEDBACK_YIHUO',
}

export enum BOOKORDER_TYPE
{
    GROWBOOK = 'GROWBOOK',
    EXTRABOOK = 'EXTRABOOK',
}

export enum BOOKORDER_EDITION
{
    PRO = 'PRO',
    DELUX = 'DELUX',
}

export class AttachVoiceItem
{
    public resId?: string;
    public publisher?: string;
    public publishTime?: string;
    public voiceUri?: string;
    public voiceSeconds?: number;
    public fileName?: string;

    public constructor(init?: Partial<AttachVoiceItem>) { (Object as any).assign(this, init); }
}

export class EventItemCommentItemDTO
{
    public id?: string;
    public isAudio?: boolean;
    public audioItem?: AttachVoiceItem;
    public content?: string;
    public createBy?: string;
    public createByName?: string;
    public avatarUrl?: string;
    public createOn?: string;
    public createIPAddr?: string;
    public replyTo?: string;
    public replyToName?: string;

    public constructor(init?: Partial<EventItemCommentItemDTO>) { (Object as any).assign(this, init); }
}

export class EventLikeUserDTO
{
    public isTeacher?: boolean;
    public userId?: number;
    public userProfileId?: string;
    public showName?: string;
    public avatarUrl?: string;

    public constructor(init?: Partial<EventLikeUserDTO>) { (Object as any).assign(this, init); }
}

export class TeacherClassInfoDTO
{
    public classId?: string;
    public className?: string;
    public teacherRole?: string;
    public originalClassName?: string;

    public constructor(init?: Partial<TeacherClassInfoDTO>) { (Object as any).assign(this, init); }
}

export class TeacherInfoDTO
{
    public id?: string;
    public userId?: number;
    public fullName?: string;
    public remark?: string;
    public logoUrl?: string;
    public schoolName?: string;
    public schoolId?: string;
    public branchRole?: { [index: string]: string; };
    public userName?: string;
    public profileId?: string;
    public teacherRole?: string;
    public imClientId?: string;
    public rongImClientId?: string;
    public classInfos?: TeacherClassInfoDTO[];
    public needPublishAudit?: boolean;
    public teacherRoleIds?: string[];
    public timestamp?: number;
    public attendanceTemplateId?: string;
    public orderIndex?: number;
    public isDelete?: boolean;
    public isHidden?: boolean;

    public constructor(init?: Partial<TeacherInfoDTO>) { (Object as any).assign(this, init); }
}

export enum EVENTCLOCKINSTATE
{
    UNBEGIN = 'UNBEGIN',
    BEGINING = 'BEGINING',
    FINISHED = 'FINISHED',
}

export enum WATCH_DEVICE_STATE
{
    INIT = 'INIT',
    WAIT_BIND = 'WAIT_BIND',
    BINDED = 'BINDED',
    UNBINDED = 'UNBINDED',
    SCRAPPED = 'SCRAPPED',
}

export class WatchMacInfo
{
    public macAddress?: string;
    public icNumber?: string;

    public constructor(init?: Partial<WatchMacInfo>) { (Object as any).assign(this, init); }
}

export enum AttendanceDevice_Type
{
    DEVICE_CHANNEL = 'DEVICE_CHANNEL',
    DEVICE_SHOWBOARD = 'DEVICE_SHOWBOARD',
    DEVICE_WALLBOX = 'DEVICE_WALLBOX',
    DEVICE_YITIJI = 'DEVICE_YITIJI',
    DEVICE_UNIUBI_FACE = 'DEVICE_UNIUBI_FACE',
    DEVICE_FUYIN_FACE = 'DEVICE_FUYIN_FACE',
}

export enum AttendanceDoor_Direction
{
    IN = 'IN',
    OUT = 'OUT',
}

export enum LIVE_NODE_TYPE
{
    CLOUD_NODE = 'CLOUD_NODE',
    NVR = 'NVR',
}

export enum DVR_DEVICE_TYPE
{
    HKVISION = 0,
    DAHUA = 1,
    TPLINK = 2,
    GB28181 = 3,
    OTHER = 99,
}

export class LiveScheduleItemMinute
{
    public beginMinute?: number;
    public endMinute?: number;

    public constructor(init?: Partial<LiveScheduleItemMinute>) { (Object as any).assign(this, init); }
}

export class LiveScheduleItemDTO
{
    public dayOfWeek?: number;
    public hour?: number[];
    public minute?: LiveScheduleItemMinute[];

    public constructor(init?: Partial<LiveScheduleItemDTO>) { (Object as any).assign(this, init); }
}

export enum GB_CLOUD_PROVIDER
{
    ALIYUN = 'ALIYUN',
    TENCENTCLOUD = 'TENCENTCLOUD',
    QINIU = 'QINIU',
}

export enum GB_CLOUD_REGION
{
    aliyun_cn_qingdao = 'aliyun_cn_qingdao',
    aliyun_cn_shenzhen = 'aliyun_cn_shenzhen',
    qiniu_z0_huadong = 'qiniu_z0_huadong',
    qiniu_z1_huabei = 'qiniu_z1_huabei',
    qiniu_z2_huanan = 'qiniu_z2_huanan',
    qiniu_cn_east_2_huadong_zhejiang_2 = 'qiniu_cn_east_2_huadong_zhejiang_2',
}

export class QiniuLiveCallbackDTO
{
    public oid?: string;
    public type?: string;
    public nsId?: string;
    public streamId?: string;
    public start?: number;
    public end?: number;
    public duration?: number;
    public fname?: string;
    public recordType?: number;
    public code?: number;
    public errmsg?: string;
    public streamState?: number;
    public timeSec?: number;
    public reqId?: string;
    public processId?: string;

    public constructor(init?: Partial<QiniuLiveCallbackDTO>) { (Object as any).assign(this, init); }
}

export enum ExcelBatchType
{
    Course = 'Course',
    Teacher = 'Teacher',
    Classes = 'Classes',
    Kid = 'Kid',
    Lead = 'Lead',
    Parent = 'Parent',
}

export class ExcelParserColumn
{
    public key?: string;
    public title?: string;
    public index?: number;
    public isRequired?: boolean;
    public isHidden?: boolean;

    public constructor(init?: Partial<ExcelParserColumn>) { (Object as any).assign(this, init); }
}

export class ExcelParserCell
{
    public key?: string;
    public value?: string;
    public original?: string;
    public dbValue?: string;
    public isValid?: boolean;
    public noWarning?: boolean;
    public errorMessage?: string[];

    public constructor(init?: Partial<ExcelParserCell>) { (Object as any).assign(this, init); }
}

export enum ExcelParserCreateType
{
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}

export class ParseFromJsonResult
{
    public key?: string;
    public index?: number;
    public success?: boolean;
    public message?: string;

    public constructor(init?: Partial<ParseFromJsonResult>) { (Object as any).assign(this, init); }
}

export class ExcelParserRow
{
    public id?: string;
    public index?: number;
    public isValid?: boolean;
    public noWarning?: boolean;
    public cell?: ExcelParserCell[];
    public type?: ExcelParserCreateType;
    public result?: ParseFromJsonResult[];

    public constructor(init?: Partial<ExcelParserRow>) { (Object as any).assign(this, init); }
}

export class ExcelParserSheet
{
    public key?: ExcelBatchType;
    public title?: string;
    public isValid?: boolean;
    public noWarning?: boolean;
    public column?: { [index: string]: ExcelParserColumn; };
    public row?: ExcelParserRow[];

    public constructor(init?: Partial<ExcelParserSheet>) { (Object as any).assign(this, init); }
}

export enum PERMISSION_USEAGE_AREA
{
    ONLY_KINDERGARTEN = 0,
    ONLY_EDUAFFAIR = 1,
    ONLY_SCHOOL = 2,
    ALL = 99,
}

export class SystemServiceSubModuleConfigDTO
{
    public id?: string;
    public moduleCode?: string;
    public subModuleCode?: string;
    public subModuleName?: string;
    public description?: string;
    public orderIndex?: number;
    public isRequired?: boolean;
    public roles?: string[];

    public constructor(init?: Partial<SystemServiceSubModuleConfigDTO>) { (Object as any).assign(this, init); }
}

export enum APP_TYPE
{
    APP_ALL = 0,
    APP_TEACHER = 1,
    APP_PARENT = 2,
    APP_ADMIN = 4,
    APP_PARTNER = 5,
}

export class RedDotNotifyTarget
{
    public entityId?: string;
    public userId?: number;
    public isTeacher?: boolean;

    public constructor(init?: Partial<RedDotNotifyTarget>) { (Object as any).assign(this, init); }
}

export enum POINT_TASK_PERIOD
{
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHY = 'MONTHY',
    TERMLY = 'TERMLY',
    YEARLY = 'YEARLY',
    FOREVER = 'FOREVER',
}

export class SchoolContractDTO
{
    public id?: string;
    public schoolId?: string;
    public merchantName?: string;
    public legalName?: string;
    public address?: string;
    public linkMan?: string;
    public linkManPhone?: string;
    public contractNo?: string;
    public contractValue?: number;
    public contractValueCN?: string;
    public firstYearValue?: number;
    public firstYearValueCN?: string;
    public nextYearValue?: number;
    public nextYearValueCN?: string;
    public userAmount?: number;
    public beginDate?: string;
    public endDate?: string;
    public myLinkMan?: string;
    public isDelete?: boolean;
    public deleteBy?: number;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public lastModifyOn?: string;
    public createOn?: string;

    public constructor(init?: Partial<SchoolContractDTO>) { (Object as any).assign(this, init); }
}

export enum STUDENT_MEMBER_BUY_TYPE
{
    BUY_BY_PARENT = 'BUY_BY_PARENT',
    BUY_BY_SCHOOL = 'BUY_BY_SCHOOL',
}

export enum POINT_SOURCE_TYPE
{
    TASK_ADD = 'TASK_ADD',
    SHOP_USE = 'SHOP_USE',
    SHOP_ROLLBACK = 'SHOP_ROLLBACK',
}

export enum POINT_SHOP_TYPE
{
    SELF = 'SELF',
    TONGDUIBA = 'TONGDUIBA',
}

export enum POINT_USE_ORDER_STATE
{
    INIT = 'INIT',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    CANCEL = 'CANCEL',
}

export enum KID_MEMBER_TYPE
{
    App = 1,
    Live = 2,
}

export class DataVVisual
{
    public id?: string;
    public category?: string;
    public title?: string;
    public backgroundUrl?: string;
    public password?: string;
    public createTime?: string;
    public isDeleted?: number;
    public status?: number;
    public updateTime?: string;

    public constructor(init?: Partial<DataVVisual>) { (Object as any).assign(this, init); }
}

export class DataVConfig
{
    public component?: string;
    public detail?: string;
    public id?: string;
    public visualId?: string;

    public constructor(init?: Partial<DataVConfig>) { (Object as any).assign(this, init); }
}

export class WeiXinShareConfig
{
    public coverImage?: string;
    public title?: string;
    public description?: string;
    public shareWx?: boolean;

    public constructor(init?: Partial<WeiXinShareConfig>) { (Object as any).assign(this, init); }
}

export enum SPREADER_PRIVATE_GIFT_TYPE
{
    POINT = 'POINT',
    CARD = 'CARD',
}

export class SpreaderConfig
{
    public giftType?: SPREADER_PRIVATE_GIFT_TYPE;
    public amount?: number;
    public expiredDays?: number;
    public minPrice?: number;

    public constructor(init?: Partial<SpreaderConfig>) { (Object as any).assign(this, init); }
}

export enum MARKETING_ACTIVITY_TYPE
{
    NONE = 'NONE',
    ACTIVITY = 'ACTIVITY',
    PINTUAN = 'PINTUAN',
    KANJIA = 'KANJIA',
    QIANGGOU = 'QIANGGOU',
}

export class MarketingPageTemplateDTO
{
    public id?: string;
    public title?: string;
    public coverImage?: string;
    public description?: string;
    public script?: string;
    public author?: string;
    public width?: number;
    public height?: number;
    public pageMode?: string;
    public pages?: string;
    public config?: string;
    public flipType?: number;
    public sliderNumber?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public status?: number;
    public isPublish?: boolean;
    public isDelete?: boolean;
    public tags?: string[];
    public shareConfig?: WeiXinShareConfig;
    public spreaderConfig?: SpreaderConfig;
    public activityType?: MARKETING_ACTIVITY_TYPE;
    public orderIndex?: number;

    public constructor(init?: Partial<MarketingPageTemplateDTO>) { (Object as any).assign(this, init); }
}

export enum MARKETING_FEEDBACK_ITEMTYPE
{
    INPUT = 'INPUT',
    INPUTNUMBER = 'INPUTNUMBER',
    PHONENUMBER = 'PHONENUMBER',
    TEXT = 'TEXT',
    CHECKBOX = 'CHECKBOX',
    DROPDOWN = 'DROPDOWN',
    DATEPICK = 'DATEPICK',
}

export class MarketingFeedExtraItem
{
    public itemType?: MARKETING_FEEDBACK_ITEMTYPE;
    public title?: string;
    public value?: string;
    public options?: string[];

    public constructor(init?: Partial<MarketingFeedExtraItem>) { (Object as any).assign(this, init); }
}

export class MarketingPageDTO extends MarketingPageTemplateDTO
{
    public schoolId?: string;
    public templateId?: string;
    public beginAt?: string;
    public endAt?: string;
    public needPayment?: boolean;
    public fee?: number;
    public formStudentNameEnabled?: boolean;
    public formStudentBirthEnabled?: boolean;
    public formPhoneNumberEnabled?: boolean;
    public formGenderEnabled?: boolean;
    public formParentRoleEnabled?: boolean;
    public formParentNameEnabled?: boolean;
    public formContactAddressEnabled?: boolean;
    public formXiaoquNameEnabled?: boolean;
    public formSchoolNameEnabled?: boolean;
    public formIsMemberEnabled?: boolean;
    public formExtraDatas?: MarketingFeedExtraItem[];
    public virtualVisitorAmount?: number;
    public virtualOrdersAmount?: number;
    public virtualShareAmount?: number;

    public constructor(init?: Partial<MarketingPageDTO>) { super(init); (Object as any).assign(this, init); }
}

export enum MARKETING_ACTIVITY_GROUP_STATUS
{
    WAIT_ZHULI = 'WAIT_ZHULI',
    WAIT_PAY = 'WAIT_PAY',
    PINING = 'PINING',
    WAIT_CONFIRM = 'WAIT_CONFIRM',
    CONFIRM_SUCCESS = 'CONFIRM_SUCCESS',
    CONFIRM_FAIL = 'CONFIRM_FAIL',
    ACTIVITY_TIMEOUT = 'ACTIVITY_TIMEOUT',
}

export enum BOOKORDER_STATS
{
    ORDER_INIT = 'ORDER_INIT',
    WAIT_BUYER_PAY = 'WAIT_BUYER_PAY',
    TRADE_PAID = 'TRADE_PAID',
    WAIT_CONFIRM = 'WAIT_CONFIRM',
    WAIT_SELLER_SEND_GOODS = 'WAIT_SELLER_SEND_GOODS',
    WAIT_BUYER_CONFIRM_GOODS = 'WAIT_BUYER_CONFIRM_GOODS',
    TRADE_SUCCESS = 'TRADE_SUCCESS',
    TRADE_CLOSED = 'TRADE_CLOSED',
}

export enum ORDER_SHARE_STATE
{
    NO_NEED = 'NO_NEED',
    INIT = 'INIT',
    PROCESSED = 'PROCESSED',
}

export class WeChatPayOrderDataV3Mock
{
    public return_code?: string;
    public return_msg?: string;
    public appid?: string;
    public mch_id?: string;
    public nonce_str?: string;
    public sign?: string;
    public result_code?: string;
    public err_code?: string;
    public err_code_des?: string;
    public openid?: string;
    public is_subscribe?: string;
    public trade_type?: string;
    public bank_type?: string;
    public total_fee?: number;
    public fee_type?: string;
    public cash_fee?: number;
    public cash_fee_type?: string;
    public transaction_id?: string;
    public out_trade_no?: string;
    public time_end?: string;
    public appId?: string;
    public timeStamp?: string;
    public nonceStr?: string;
    public openId?: string;
    public appSignature?: string;
    public isSubscribe?: string;

    public constructor(init?: Partial<WeChatPayOrderDataV3Mock>) { (Object as any).assign(this, init); }
}

export class SftWithDrawNotify
{
    public returnCode?: string;
    public resultCode?: string;
    public errorCode?: string;
    public errorCodeDes?: string;
    public bankAccountType?: string;
    public subMchId?: string;
    public orderNo?: string;
    public acquireOrderNo?: string;
    public amount?: number;
    public bankAccount?: string;
    public bankAccountName?: string;
    public bankName?: string;
    public nonceStr?: string;
    public signType?: string;
    public sign?: string;

    public constructor(init?: Partial<SftWithDrawNotify>) { (Object as any).assign(this, init); }
}

export class ShengPayOrderRefundOrderDTO
{
    public returnCode?: string;
    public returnMsg?: string;
    public resultCode?: string;
    public mchId?: string;
    public outTradeNo?: string;
    public transactionId?: string;
    public refundOrderNo?: string;
    public refundId?: string;
    public refundStatus?: string;
    public refundFee?: number;
    public timeEnd?: string;
    public attach?: string;
    public nonceStr?: string;
    public signType?: string;
    public sign?: string;

    public constructor(init?: Partial<ShengPayOrderRefundOrderDTO>) { (Object as any).assign(this, init); }
}

export class ShengPayOrderDTO
{
    public returnCode?: string;
    public returnMsg?: string;
    public resultCode?: string;
    public errorCode?: string;
    public errorCodeDes?: string;
    public mchId?: string;
    public subMchId?: string;
    public outTradeNo?: string;
    public transactionId?: string;
    public tradeType?: string;
    public status?: string;
    public totalFee?: number;
    public timeEnd?: string;
    public payerInfo?: string;
    public chargeInfo?: string;
    public attach?: string;
    public nonceStr?: string;
    public signType?: string;
    public sign?: string;

    public constructor(init?: Partial<ShengPayOrderDTO>) { (Object as any).assign(this, init); }
}

export enum KIDACCOUNT_BALANCE_BIZTYPE
{
    CHARGE_BY_ORDER = 'CHARGE_BY_ORDER',
    CHARGE_BY_MANUAL = 'CHARGE_BY_MANUAL',
    BUY_COURSE = 'BUY_COURSE',
    BUY_MANUAL = 'BUY_MANUAL',
}

export enum SCHOOLORDERPRODUCT_CATALOG
{
    PLATFORM_ORDER = 'PLATFORM_ORDER',
    SMS = 'SMS',
}

export enum ORDERSTAUS
{
    ORDER_INIT = 'ORDER_INIT',
    WAIT_BUYER_PAY = 'WAIT_BUYER_PAY',
    TRADE_PAID = 'TRADE_PAID',
    TRADE_SUCCESS = 'TRADE_SUCCESS',
    TRADE_CLOSED = 'TRADE_CLOSED',
}

export enum SMS_SEND_STATUS
{
    SUBMIT_OK = 'SUBMIT_OK',
    SUBMIT_FAIL = 'SUBMIT_FAIL',
    SEND_OK = 'SEND_OK',
    SEND_FAIL = 'SEND_FAIL',
}

export class AttachTingItem
{
    public albumId?: string;
    public trackId?: string;
    public albumTitle?: string;
    public albumDesc?: string;
    public albumImageUrl?: string;

    public constructor(init?: Partial<AttachTingItem>) { (Object as any).assign(this, init); }
}

export enum CONFIGTYPE
{
    FULL = 'FULL',
    DIFF = 'DIFF',
    DELETE = 'DELETE',
}

export enum BOOKORDER_PDFSTATS
{
    NOTREADY = 'NOTREADY',
    PROCESSING = 'PROCESSING',
    PROCESSOK = 'PROCESSOK',
    PROCESSFAIL = 'PROCESSFAIL',
}

export enum COMMISSIONSTATUS
{
    WAIT_CONFIRM = 'WAIT_CONFIRM',
    CONFIRMED = 'CONFIRMED',
    CANCELED = 'CANCELED',
}

export enum MEDICSTAT
{
    WAIT = 'WAIT',
    FINISH = 'FINISH',
    CANCEL = 'CANCEL',
}

export class EventAttachItem
{
    public attachImages?: AttachImageItem[];
    public attachVideos?: AttachVideoItem[];
    public attachVoices?: AttachVoiceItem[];
    public attachFiles?: AttachFileItem[];
    public attachLinks?: AttachLinkItem[];
    public attachTings?: AttachTingItem[];
    public attachVideo?: AttachVideoItem;
    public attachVoice?: AttachVoiceItem;
    public attachFile?: AttachFileItem;
    public attachLink?: AttachLinkItem;
    public attachTing?: AttachTingItem;

    public constructor(init?: Partial<EventAttachItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventAttachItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class EventTaskItemDTO
{
    public id?: string;
    public content?: string;
    public createOn?: string;
    public createIPAddr?: string;
    public attachment?: EventAttachItem;
    public likeUids?: number[];
    public kidName?: string;
    public kidId?: string;
    public parentRole?: string;
    public parentId?: string;
    public kidAvatarUrl?: string;
    public commentItems?: EventItemCommentItemDTO[];

    public constructor(init?: Partial<EventTaskItemDTO>) { (Object as any).assign(this, init); }
}

export class BookExtraConfigItemDTO
{
    public itemId?: string;
    public createOn?: string;
    public updatedOn?: string;
    public lastModifyOn?: string;
    public deleteOn?: string;
    public name?: string;
    public title?: string;
    public content?: string;
    public backgroundUrl?: string;
    public fontColor?: string;
    public sortIndex?: number;
    public isDelete?: boolean;

    public constructor(init?: Partial<BookExtraConfigItemDTO>) { (Object as any).assign(this, init); }
}

export class GrowBookGroupDTO
{
    public eventId?: string;
    public eventTitle?: string;
    public eventContent?: string;
    public eventType?: string;
    public createOn?: string;
    public feedTime?: string;
    public createBy?: string;
    public feedAttachment?: EventAttachItem;

    public constructor(init?: Partial<GrowBookGroupDTO>) { (Object as any).assign(this, init); }
}

export class EvaluateItemDTO extends EvaluateItemBaseDTO
{
    public isFinish?: boolean;
    public kidScores?: EvaluateItemKidScoreDTO[];

    public constructor(init?: Partial<EvaluateItemDTO>) { super(init); (Object as any).assign(this, init); }
}

export class MedicCareLogDTO
{
    public id?: string;
    public createOn?: string;
    public logContent?: string;

    public constructor(init?: Partial<MedicCareLogDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventItem
{
    public userName?: string;
    public userProfileId?: string;
    public classId?: string;
    public schoolId?: string;
    public schoolName?: string;
    public className?: string;
    public userLogoUrl?: string;
    public eventId?: string;
    public eventType?: string;
    public timeString?: string;
    public timeStamp?: number;
    public createOn?: string;
    public updateOn?: string;
    public publishTime?: string;
    public title?: string;
    public content?: string;
    public attachItem?: EventAttachItem;
    public targetKids?: string[];
    public isPartialPublish?: boolean;
    public studentTotalCount?: number;
    public isTruncate?: boolean;
    public imageUri?: string;
    public linkUri?: string;
    public pageView?: number;
    public themeId?: string;
    public themeTitle?: string;
    public kidId?: string;
    public kidName?: string;
    public parentId?: string;
    public parentRoleName?: string;
    public comments?: EventItemCommentItemDTO[];
    public likes?: EventLikeUserDTO[];
    public commentType?: TASKATTACHTYPE;
    public isVisited?: boolean;
    public isAcked?: boolean;
    public termId?: number;
    public semesterType?: SEMESTERTYPE;
    public extraFields?: { [index: string]: Object; };
    public homeTime_Scope?: EVENT_HOMETIME_SCOPE;
    public needPayment?: boolean;
    public isPayed?: boolean;
    public paymentCourseId?: string;
    public paymentPrice?: number;
    public showBeforeTimestamp?: number;
    public myAiOssIds?: string[];

    public constructor(init?: Partial<ClassEventItem>) { (Object as any).assign(this, init); }
}

export class EventClockInGroupDTO
{
    public id?: string;
    public clockInDate?: string;
    public timestamp?: number;
    public canSubmit?: boolean;
    public isToday?: number;
    public dakaCount?: number;

    public constructor(init?: Partial<EventClockInGroupDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventClockInItem extends ClassEventItem
{
    public canSeeOther?: boolean;
    public beginTime?: string;
    public endTime?: string;
    public state?: EVENTCLOCKINSTATE;
    public tags?: string[];
    public targetTotalNum?: number;
    public frequencyConfig?: EventClockInFrequency;
    public eventClockInGroups?: EventClockInGroupDTO[];

    public constructor(init?: Partial<ClassEventClockInItem>) { super(init); (Object as any).assign(this, init); }
}

export class OrderProductDTO
{
    public id?: string;
    public schoolId?: string;
    public catalog?: ORDERPRODUCT_CATALOG;
    public amount?: number;
    public expireOn?: string;
    public productName?: string;
    public price?: number;
    public iosPrice?: number;
    public iapCode?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;

    public constructor(init?: Partial<OrderProductDTO>) { (Object as any).assign(this, init); }
}

export class EventAttachLocalModel
{
    public videoAssetId?: string;
    public imageAssetIds?: string[];
    public fileAssetId?: string;
    public voiceAssetId?: string;

    public constructor(init?: Partial<EventAttachLocalModel>) { (Object as any).assign(this, init); }
}

export class RongCloudOnlineStatus
{
    public userid?: string;
    public status?: string;
    public os?: string;
    public time?: number;
    public clientIp?: string;

    public constructor(init?: Partial<RongCloudOnlineStatus>) { (Object as any).assign(this, init); }
}

export class UploadFileItem
{
    public originname?: string;
    public filedata?: string;

    public constructor(init?: Partial<UploadFileItem>) { (Object as any).assign(this, init); }
}

export class VoteTextContentDTO
{
    public kidId?: string;
    public parentId?: string;
    public parentRole?: string;
    public content?: string;
    public attachment?: EventAttachItem;
    public createOn?: string;
    public userIPAddr?: string;

    public constructor(init?: Partial<VoteTextContentDTO>) { (Object as any).assign(this, init); }
}

export enum VOTEATTACHTYPE
{
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
    FILE = 'FILE',
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public fieldName?: string;

    // @DataMember(Order=3)
    public message?: string;

    // @DataMember(Order=4)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public message?: string;

    // @DataMember(Order=3)
    public stackTrace?: string;

    // @DataMember(Order=4)
    public errors?: ResponseError[];

    // @DataMember(Order=5)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class WeChatUserDTO
{
    public id?: string;
    public userId?: number;
    public unionId?: string;
    public openId?: string;
    public wxAppId?: string;
    public nickName?: string;
    public avatarUrl?: string;
    public gender?: GENDERTYPE;
    public city?: string;
    public province?: string;
    public country?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<WeChatUserDTO>) { (Object as any).assign(this, init); }
}

export class FileListItem
{
    public id?: string;
    public schoolId?: string;
    public createBy?: number;
    public fileName?: string;
    public fileSize?: number;
    public fileExt?: string;
    public isDir?: boolean;
    public parentId?: string;
    public createOn?: string;
    public updateOn?: string;
    public userIPAddr?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public ossId?: string;
    public ossFileUrl?: string;
    public signedOssFileUrl?: string;
    public playSeconds?: number;
    public thumbnilUrl?: string;
    public aspectRatio?: string;

    public constructor(init?: Partial<FileListItem>) { (Object as any).assign(this, init); }
}

export class PosterCatalogDTO
{
    public id?: string;
    public name?: string;
    public parentCatalogName?: string;
    public backgroundImageUrl?: string;
    public templateCount?: number;
    public configItems?: PosterConfigItemDTO[];
    public w?: number;
    public h?: number;
    public createOn?: string;
    public isPublish?: boolean;
    public orderIndex?: number;

    public constructor(init?: Partial<PosterCatalogDTO>) { (Object as any).assign(this, init); }
}

export class PosterTemplateDTO
{
    public id?: string;
    public name?: string;
    public catalogId?: string;
    public useCount?: number;
    public backgroundImageUrl?: string;
    public configItems?: PosterConfigItemDTO[];
    public previewImageUrl?: string;
    public w?: number;
    public h?: number;
    public createOn?: string;
    public isPublish?: boolean;
    public orderIndex?: number;

    public constructor(init?: Partial<PosterTemplateDTO>) { (Object as any).assign(this, init); }
}

export class PosterItemDTO
{
    public id?: string;
    public name?: string;
    public schoolId?: string;
    public createByTeacherUserInfoId?: string;
    public catalogId?: string;
    public templateId?: string;
    public backgroundImageUrl?: string;
    public previewImageUrl?: string;
    public w?: number;
    public h?: number;
    public createOn?: string;
    public configItems?: PosterConfigItemDTO[];
    public publishedImageUrl?: string;
    public isDelete?: boolean;
    public isDraft?: boolean;

    public constructor(init?: Partial<PosterItemDTO>) { (Object as any).assign(this, init); }
}

export class SchoolNameDTO
{
    public id?: string;
    public name?: string;
    public eName?: string;
    public logoUrl?: string;
    public schoolImageUrl?: string;
    public desc?: string;
    public schoolType?: string;
    public province?: string;
    public city?: string;
    public district?: string;
    public phoneNumber?: string;
    public address?: string;
    public location?: GeoCoordinates;

    public constructor(init?: Partial<SchoolNameDTO>) { (Object as any).assign(this, init); }
}

export class SchoolInfoDTO
{
    public id?: string;
    public name?: string;
    public eName?: string;
    public remark?: string;
    public createOn?: string;
    public masterFullName?: string;
    public masterUserName?: string;
    public masterUserId?: number;
    public logoUrl?: string;
    public schoolImageUrl?: string;
    public desc?: string;
    public schoolType?: string;
    public province?: string;
    public city?: string;
    public district?: string;
    public phoneNumber?: string;
    public address?: string;
    public location?: GeoCoordinates;
    public memberBuyType?: STUDENT_MEMBER_BUY_TYPE;
    public isEnforceMember?: boolean;
    public schoolMemberExpireOn?: string;
    public serviceModules?: string[];
    public disabledServiceModules?: string[];
    public miniSiteUrl?: string;

    public constructor(init?: Partial<SchoolInfoDTO>) { (Object as any).assign(this, init); }
}

export class ReportClassSummaryDTO
{
    public classId?: string;
    public kidCount?: number;
    public kidEntityCount?: number;
    public parentCount?: number;
    public allEventCount?: number;
    public parentEventCount?: number;
    public teacherEventCount?: number;
    public eventTaskCount?: number;
    public eventKGTimeCount?: number;
    public eventHomeTimeCount?: number;
    public eventShareCount?: number;
    public eventNotifyCount?: number;
    public eventVoteCount?: number;
    public eventClockInCount?: number;
    public viewRate?: number;
    public likeCount?: number;
    public commentCount?: number;

    public constructor(init?: Partial<ReportClassSummaryDTO>) { (Object as any).assign(this, init); }
}

export class ClassBaseInfoDTO
{
    public id?: string;
    public branchId?: string;
    public className?: string;
    public alias?: string;
    public kidCount?: number;
    public courseId?: string;

    public constructor(init?: Partial<ClassBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export enum ReportValueType
{
    Text = 'Text',
    ImageUrl = 'ImageUrl',
    Int = 'Int',
    Double = 'Double',
}

export enum ReportTextAlign
{
    left = 'left',
    right = 'right',
    center = 'center',
    justify = 'justify',
    start = 'start',
    end = 'end',
}

export class ReportColumn
{
    public title?: string;
    public key?: string;
    public width?: number;
    public type?: ReportValueType;
    public cellAlign?: ReportTextAlign;

    public constructor(init?: Partial<ReportColumn>) { (Object as any).assign(this, init); }
}

export class ReportDailyClassSummaryDTO
{
    public reportDate?: string;
    public kidCount?: number;
    public kidEntityCount?: number;
    public parentCount?: number;
    public allEventCount?: number;
    public parentEventCount?: number;
    public teacherEventCount?: number;
    public eventTaskCount?: number;
    public eventKGTimeCount?: number;
    public eventHomeTimeCount?: number;
    public eventShareCount?: number;
    public eventNotifyCount?: number;
    public eventVoteCount?: number;
    public eventClockInCount?: number;
    public viewRate?: number;
    public likeCount?: number;
    public commentCount?: number;

    public constructor(init?: Partial<ReportDailyClassSummaryDTO>) { (Object as any).assign(this, init); }
}

export class ReportIMSummaryDTO
{
    public classId?: string;
    public parentCount?: number;
    public parentMsgCount?: number;
    public teacherMsgCount?: number;

    public constructor(init?: Partial<ReportIMSummaryDTO>) { (Object as any).assign(this, init); }
}

export class ReportDailyIMSummaryDTO
{
    public reportDate?: string;
    public parentCount?: number;
    public parentMsgCount?: number;
    public teacherMsgCount?: number;

    public constructor(init?: Partial<ReportDailyIMSummaryDTO>) { (Object as any).assign(this, init); }
}

export class ReportClassKidActivityDTO
{
    public kidId?: string;
    public parentRelationSchoolInfoId?: string;
    public loginCount?: number;
    public allLoginCount?: number;
    public days?: number;

    public constructor(init?: Partial<ReportClassKidActivityDTO>) { (Object as any).assign(this, init); }
}

export class StudentBaseInfoDTO
{
    public branchId?: string;
    public id?: string;
    public name?: string;
    public gender?: GENDERTYPE;
    public birthDate?: string;
    public logoUrl?: string;
    public primaryContact?: ParentInfoDTO;
    public secondaryContact?: ParentInfoDTO;
    public classIds?: string[];
    public parentRelationSchoolIds?: string[];

    public constructor(init?: Partial<StudentBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class ReportClassKidActivityDailyDTO
{
    public reportDate?: string;
    public kidCount?: number;
    public parentCount?: number;
    public loginCount?: number;

    public constructor(init?: Partial<ReportClassKidActivityDailyDTO>) { (Object as any).assign(this, init); }
}

export class ReportSchoolKidActivitySummaryDTO
{
    public reportDate?: string;
    public kidCount?: number;
    public parentCount?: number;
    public classKidCount?: number;
    public classParentCount?: number;
    public loginCount?: number;
    public classIds?: string[];

    public constructor(init?: Partial<ReportSchoolKidActivitySummaryDTO>) { (Object as any).assign(this, init); }
}

export class ReportSchoolKidActivityDTO
{
    public classId?: string;
    public kidCount?: number;
    public parentCount?: number;
    public loginCount?: number;

    public constructor(init?: Partial<ReportSchoolKidActivityDTO>) { (Object as any).assign(this, init); }
}

export class ReportDailySchoolTeacherActivitySummaryDTO
{
    public reportDate?: string;
    public teacherCount?: number;
    public allLoginCount?: number;

    public constructor(init?: Partial<ReportDailySchoolTeacherActivitySummaryDTO>) { (Object as any).assign(this, init); }
}

export class ReportMonthSchoolTeacherActivitySummaryDTO
{
    public reportDate?: string;
    public teacherUserInfoId?: string;
    public days?: number;
    public dayRate?: number;
    public loginCount?: number;
    public isTodayLogged?: boolean;

    public constructor(init?: Partial<ReportMonthSchoolTeacherActivitySummaryDTO>) { (Object as any).assign(this, init); }
}

export class ClassTimeSummaryByTeacherIdClassIdDTO
{
    public reportDate?: string;
    public teacherUserInfoId?: string;
    public classId?: string;
    public count?: number;
    public courseHour?: number;
    public price?: number;

    public constructor(init?: Partial<ClassTimeSummaryByTeacherIdClassIdDTO>) { (Object as any).assign(this, init); }
}

export class CourseItem
{
    public id?: string;
    public schoolId?: string;
    public name?: string;
    public colorId?: number;
    public deductionWhenAbsence?: boolean;
    public deductionWhenLeave?: boolean;
    public isOneToOne?: boolean;
    public remark?: string;
    public isDisabled?: boolean;
    public signType?: CourseSignType;
    public saleItemsByCount?: CourseSaleItemDTO[];
    public saleItemsByMonth?: CourseSaleItemDTO[];
    public nextCourseIds?: string[];
    public lastModifyOn?: string;
    public autoSign?: boolean;
    public signByClassTime?: boolean;
    public branchIds?: string[];

    public constructor(init?: Partial<CourseItem>) { (Object as any).assign(this, init); }
}

export class OrgStudentCourseBalanceDTO
{
    public courseId?: string;
    public balanceAmount?: number;
    public balanceGiftAmount?: number;
    public totalPrice?: number;

    public constructor(init?: Partial<OrgStudentCourseBalanceDTO>) { (Object as any).assign(this, init); }
}

export class ClassTimeSummaryByCourseIdDTO
{
    public reportDate?: string;
    public classId?: string;
    public courseId?: string;
    public count?: number;
    public courseHour?: number;
    public price?: number;

    public constructor(init?: Partial<ClassTimeSummaryByCourseIdDTO>) { (Object as any).assign(this, init); }
}

export class ReportDaily_SchoolKidSummaryDTO
{
    public id?: string;
    public reportDate?: string;
    public schoolId?: string;
    public allCount?: number;
    public allNormalCount?: number;
    public todayCount?: number;
    public todayNormalCount?: number;

    public constructor(init?: Partial<ReportDaily_SchoolKidSummaryDTO>) { (Object as any).assign(this, init); }
}

export class ReportAttendanceRate
{
    public reportDate?: string;
    public userCount?: number;
    public attendanceCount?: number;
    public attendanceRate?: number;

    public constructor(init?: Partial<ReportAttendanceRate>) { (Object as any).assign(this, init); }
}

export class SchoolOrderSummaryDTO
{
    public reportDate?: string;
    public schoolId?: string;
    public count?: number;
    public price?: number;
    public allCount?: number;
    public allPrice?: number;

    public constructor(init?: Partial<SchoolOrderSummaryDTO>) { (Object as any).assign(this, init); }
}

export enum Trend
{
    UP = 'UP',
    DOWN = 'DOWN',
}

export class SchoolSummaryByAppReportItem
{
    public appRoute?: string;
    public title?: string;
    public isBold?: boolean;
    public isWarning?: boolean;
    public arrow?: Trend;
    public primaryValue?: string;
    public secondaryValue?: string;

    public constructor(init?: Partial<SchoolSummaryByAppReportItem>) { (Object as any).assign(this, init); }
}

export class SchoolSummaryByAppReportGroup
{
    public title?: string;
    public items?: SchoolSummaryByAppReportItem[];

    public constructor(init?: Partial<SchoolSummaryByAppReportGroup>) { (Object as any).assign(this, init); }
}

export class SchoolSummaryByAppReport
{
    public branchId?: string;
    public groups?: SchoolSummaryByAppReportGroup[];

    public constructor(init?: Partial<SchoolSummaryByAppReport>) { (Object as any).assign(this, init); }
}

export class SchoolSaleLeadSummaryDTO
{
    public reportDate?: string;
    public source?: string;
    public saleCount?: number;
    public demoCount?: number;
    public orderCount?: number;
    public priceAmount?: number;

    public constructor(init?: Partial<SchoolSaleLeadSummaryDTO>) { (Object as any).assign(this, init); }
}

export class SchoolSaleLeadFunnelDTO
{
    public saleCount?: number;
    public followCount?: number;
    public demoCount?: number;
    public signCount?: number;
    public orderCount?: number;
    public changePeriod?: { [index: string]: number; };

    public constructor(init?: Partial<SchoolSaleLeadFunnelDTO>) { (Object as any).assign(this, init); }
}

export class AttendanceUserMapDTO
{
    public id?: string;
    public seqId?: number;
    public parentRelationSchoolInfoId?: string;
    public kidId?: string;
    public cardOwnerType?: ATTENDANCECARDOWNERTYPE;
    public teacherUserInfoId?: string;
    public cardNumber?: string;
    public cardSerialNo?: string;
    public isDisabled?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<AttendanceUserMapDTO>) { (Object as any).assign(this, init); }
}

export class TeacherAttendanceBindItem
{
    public cardSerialNo?: string;
    public bindTime?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<TeacherAttendanceBindItem>) { (Object as any).assign(this, init); }
}

export class AiFaceItem
{
    public configId?: string;
    public imageUrl?: string;
    public ownerRole?: USERROLE;
    public parentRole?: string;
    public createTime?: string;
    public name?: string;
    public userId?: number;
    public enableAttendance?: boolean;

    public constructor(init?: Partial<AiFaceItem>) { (Object as any).assign(this, init); }
}

export class SchoolAttendanceBindItem
{
    public id?: string;
    public cardSerialNo?: string;
    public bindTime?: string;
    public isTeacher?: boolean;
    public ownerType?: ATTENDANCECARDOWNERTYPE;

    public constructor(init?: Partial<SchoolAttendanceBindItem>) { (Object as any).assign(this, init); }
}

export class AttendanceFromTerminalResData
{
    public id?: string;
    public name?: string;
    public logoUrl?: string;
    public isParent?: boolean;
    public isStudent?: boolean;
    public isTeacher?: boolean;
    public showMsg?: string;

    public constructor(init?: Partial<AttendanceFromTerminalResData>) { (Object as any).assign(this, init); }
}

export class AttendanceTeacherTemplateItem
{
    public id?: string;
    public schoolId?: string;
    public name?: string;
    public followSchoolCalendar?: boolean;
    public timeConfigs?: AttendanceTimeConfigDTO[];
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<AttendanceTeacherTemplateItem>) { (Object as any).assign(this, init); }
}

export class AttendanceStudentIndexItem
{
    public studentId?: string;
    public timestamp?: number;

    public constructor(init?: Partial<AttendanceStudentIndexItem>) { (Object as any).assign(this, init); }
}

export class AttendanceParentIndexItem
{
    public parentRelationSchoolInfoId?: string;
    public timestamp?: number;

    public constructor(init?: Partial<AttendanceParentIndexItem>) { (Object as any).assign(this, init); }
}

export class AttendanceParentDataItem
{
    public parentRelationSchoolInfoId?: string;
    public fullName?: string;
    public className?: string;
    public parentRole?: string;
    public kidName?: string;
    public logoUrl?: string;
    public kidLogoUrl?: string;
    public cardSerialNo?: string;
    public cardNumber?: string;
    public enableFace?: boolean;
    public timestamp?: number;
    public aiFaceUrl?: string;

    public constructor(init?: Partial<AttendanceParentDataItem>) { (Object as any).assign(this, init); }
}

export class ParentStudentMapItem
{
    public parentRelationSchoolId?: string;
    public studentId?: string;

    public constructor(init?: Partial<ParentStudentMapItem>) { (Object as any).assign(this, init); }
}

export class AttendanceStudentDataItem
{
    public studentId?: string;
    public kidName?: string;
    public className?: string;
    public kidLogoUrl?: string;
    public cardSerialNo?: string;
    public cardNumber?: string;
    public enableFace?: boolean;
    public timestamp?: number;
    public aiFaceUrl?: string;

    public constructor(init?: Partial<AttendanceStudentDataItem>) { (Object as any).assign(this, init); }
}

export class AttendanceTeacherIndexItem
{
    public teacherUserInfoId?: string;
    public timestamp?: number;

    public constructor(init?: Partial<AttendanceTeacherIndexItem>) { (Object as any).assign(this, init); }
}

export class AttendanceTeacherDataItem
{
    public teacherUserInfoId?: string;
    public fullName?: string;
    public logoUrl?: string;
    public cardSerialNo?: string;
    public cardNumber?: string;
    public enableFace?: boolean;
    public timestamp?: number;
    public aiFaceUrl?: string;
    public attendanceGroup?: string;

    public constructor(init?: Partial<AttendanceTeacherDataItem>) { (Object as any).assign(this, init); }
}

export enum AttendanceCheckState
{
    CHECK_NOTCHECK = 'CHECK_NOTCHECK',
    CHECK_OK = 'CHECK_OK',
    CHECK_TOOLATE = 'CHECK_TOOLATE',
    CHECK_TOOEARLY = 'CHECK_TOOEARLY',
    CHECK_MISS = 'CHECK_MISS',
}

export class AttendanceTeacherDTO
{
    public id?: string;
    public schoolId?: string;
    public dateOn?: string;
    public teacherName?: string;
    public teacherUserName?: string;
    public teacherUserInfoId?: string;
    public teacherAvatarUrl?: string;
    public checkInTime?: string;
    public checkOutTime?: string;
    public checkInState?: AttendanceCheckState;
    public checkOutState?: AttendanceCheckState;
    public lastModifyOn?: string;
    public ruleCheckInTime?: string;
    public ruleCheckOutTime?: string;

    public constructor(init?: Partial<AttendanceTeacherDTO>) { (Object as any).assign(this, init); }
}

export class BaseKidInfoDTO
{
    public id?: string;
    public name?: string;
    public gender?: GENDERTYPE;
    public birthDate?: string;
    public avatarUrl?: string;
    public schoolId?: string;
    public classIds?: string[];
    public classesStatus?: { [index: string]: STUDENT_STATUS; };
    public createOn?: string;
    public state?: KIDACCOUNTSTATE;
    public primaryContact?: ParentInfoDTO;
    public secondaryContact?: ParentInfoDTO;
    public lastLoginOn?: string;
    public lastModifyOn?: string;
    public studentNumber?: string;
    public hobby?: string;
    public kidEntityId?: string;
    public kidEntityUserAuthMapId?: string;
    public studentStatus?: STUDENT_STATUS;
    public branchId?: string;

    public constructor(init?: Partial<BaseKidInfoDTO>) { (Object as any).assign(this, init); }
}

export enum ATTENDANCETYPE
{
    FULL_DAY = 'FULL_DAY',
    FORENOON = 'FORENOON',
    AFTERNOON = 'AFTERNOON',
}

export class AttendanceKidDTO
{
    public kidId?: string;
    public attendanceType?: ATTENDANCETYPE;
    public attendanceMethod?: ATTENDANCEMETHOD;
    public isCheckIn?: boolean;
    public checkUUID?: string;
    public createOn?: string;
    public attendanceDeviceId?: string;
    public attendanceUserMapId?: string;
    public healthTemperature?: string;
    public healthTemperatureStatus?: ATTENDANCE_HEALTH_TEMPERATURE_STATUS;

    public constructor(init?: Partial<AttendanceKidDTO>) { (Object as any).assign(this, init); }
}

export class ClassAttendanceDailyReport
{
    public dateOn?: string;
    public classId?: string;
    public branchId?: string;
    public kidsCount?: number;
    public checkInCount?: number;
    public checkOutCount?: number;
    public leaveCount?: number;

    public constructor(init?: Partial<ClassAttendanceDailyReport>) { (Object as any).assign(this, init); }
}

export class KidAttendance
{
    public kidId?: string;
    public dateOn?: string;
    public hasCheckIn?: boolean;
    public hasCheckOut?: boolean;
    public checkIn?: AttendanceKidDTO;
    public checkOut?: AttendanceKidDTO;

    public constructor(init?: Partial<KidAttendance>) { (Object as any).assign(this, init); }
}

export class ClassAttendanceMonthReport
{
    public dateOn?: string;
    public classId?: string;
    public branchId?: string;
    public workDaysCount?: number;
    public allCheckInDays?: number;
    public allCheckOutDays?: number;
    public hasLeaveDays?: number;

    public constructor(init?: Partial<ClassAttendanceMonthReport>) { (Object as any).assign(this, init); }
}

export class KidAttendanceMonthReport
{
    public kidId?: string;
    public workDaysCount?: number;
    public checkInCount?: number;
    public checkOutCount?: number;
    public leaveCount?: number;

    public constructor(init?: Partial<KidAttendanceMonthReport>) { (Object as any).assign(this, init); }
}

export class AttendanceTeacherLogDTO
{
    public id?: string;
    public schoolId?: string;
    public teacherUserInfoId?: string;
    public checkTime?: string;
    public checkUUID?: string;
    public attendanceDeviceId?: string;
    public isCheckIn?: boolean;
    public healthTemperature?: string;
    public healthTemperatureStatus?: ATTENDANCE_HEALTH_TEMPERATURE_STATUS;

    public constructor(init?: Partial<AttendanceTeacherLogDTO>) { (Object as any).assign(this, init); }
}

export class TeacherAttendanceMonthReportItem
{
    public teacherUserInfoId?: string;
    public teacherName?: string;
    public teacherUserName?: string;
    public teacherAvatarUrl?: string;
    public noWorkDayCount?: number;
    public noWorkDayAttendanceCount?: number;
    public totalCount?: number;
    public attendanceCount?: number;
    public noDataCount?: number;
    public lateInCount?: number;
    public earlyOutCount?: number;
    public notCheckInCount?: number;
    public notCheckOutCount?: number;

    public constructor(init?: Partial<TeacherAttendanceMonthReportItem>) { (Object as any).assign(this, init); }
}

export class TeacherAttendanceMonthReport
{
    public reportDate?: string;
    public data?: TeacherAttendanceMonthReportItem[];

    public constructor(init?: Partial<TeacherAttendanceMonthReport>) { (Object as any).assign(this, init); }
}

export class TeacherAttendanceDailyReportItem
{
    public reportDate?: string;
    public totalCount?: number;
    public attendanceCount?: number;
    public lateInCount?: number;
    public earlyOutCount?: number;
    public noDataCount?: number;
    public teacherdata?: AttendanceTeacherDTO[];

    public constructor(init?: Partial<TeacherAttendanceDailyReportItem>) { (Object as any).assign(this, init); }
}

export enum VACATION_TYPE
{
    WEEKEND = 'WEEKEND',
    HOLIDAY = 'HOLIDAY',
    SUMMER = 'SUMMER',
    WINTER = 'WINTER',
    SCHOOLDEFINE = 'SCHOOLDEFINE',
}

export class VacationItemDTO
{
    public date?: string;
    public vacationType?: VACATION_TYPE;
    public holidayName?: string;
    public vDate?: string;

    public constructor(init?: Partial<VacationItemDTO>) { (Object as any).assign(this, init); }
}

export class SchoolYearCalendarDTO
{
    public schoolId?: string;
    public year?: number;
    public vacationList?: VacationItemDTO[];

    public constructor(init?: Partial<SchoolYearCalendarDTO>) { (Object as any).assign(this, init); }
}

export class ChineseCalendarDefineDTO
{
    public year?: number;
    public holidays?: HolidayItemDTO[];
    public workDays?: string[];

    public constructor(init?: Partial<ChineseCalendarDefineDTO>) { (Object as any).assign(this, init); }
}

export class CommissionPickupDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public teacherUserInfoId?: string;
    public studentId?: string;
    public pickuperRelationName?: string;
    public pickuperRealName?: string;
    public pickuperPhotoOssId?: string;
    public pickuperPhotoUrl?: string;
    public comfirmedByParentRelationSchoolInfoId?: string;
    public createUserIPAddr?: string;
    public confirmedUserIPAddr?: string;
    public createOn?: string;
    public status?: COMMISSIONSTATUS;
    public confirmedOn?: string;
    public canceledOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<CommissionPickupDTO>) { (Object as any).assign(this, init); }
}

export class DailyCareSchoolConfigDTO
{
    public id?: string;
    public schoolId?: string;
    public sleepOn?: string;
    public wakeupOn?: string;
    public disableDailyCareDrinkWater?: boolean;
    public disableDailyCareBreakfast?: boolean;
    public disableDailyCareLunch?: boolean;
    public disableDailyCareSnack?: boolean;
    public disableDailyCareToilet?: boolean;
    public disableDailyCareDiaper?: boolean;
    public disableDailyCareSleep?: boolean;
    public disableDailyCareMilk?: boolean;
    public disableDailyCareTemperature?: boolean;
    public disableDailyCareNote?: boolean;
    public remindDrinkWater?: boolean;
    public remindDrinkWaterPeriod?: number;
    public remindDiaper?: boolean;
    public remindDiaperPeriod?: number;
    public remindToilet?: boolean;
    public remindToiletPeriod?: number;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<DailyCareSchoolConfigDTO>) { (Object as any).assign(this, init); }
}

export class SchoolSettingDTO
{
    public id?: string;
    public schoolType?: SCHOOL_TYPE;
    public lastClassTimeNotifyOn?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public isSendEventDailyHealthy?: boolean;
    public isSendEventDailyHealthyBySms?: boolean;
    public isSendEventDailyHealthyByWechat?: boolean;
    public isSendEventAttendance?: boolean;
    public isSendEventAttendanceBySms?: boolean;
    public isSendEventAttendanceByWechat?: boolean;
    public isSendAuditLeaveRequestByApp?: boolean;
    public isSendAuditLeaveRequestBySms?: boolean;
    public isSendAuditLeaveRequestByWechat?: boolean;
    public isSendClassBillByApp?: boolean;
    public isSendClassBillBySms?: boolean;
    public isSendClassBillByWechat?: boolean;
    public isSendAddClassTimeStudentDemoByApp?: boolean;
    public isSendAddClassTimeStudentDemoBySms?: boolean;
    public isSendAddClassTimeStudentDemoByWechat?: boolean;
    public sendWishNotifyHour?: number;
    public isSendWishBirthdayByApp?: boolean;
    public isSendWishBirthdayBySms?: boolean;
    public isSendWishBirthdayByWechat?: boolean;
    public isSendWishHolidayByApp?: boolean;
    public isSendWishHolidayBySms?: boolean;
    public isSendWishHolidayByWechat?: boolean;
    public kidBlacklistSendNotify?: string[];
    public wechatMsgSuffix?: string;
    public classTimeNotifyHour?: number;
    public isSendClassTimeNotifyByApp?: boolean;
    public isSendClassTimeNotifyBySms?: boolean;
    public isSendClassTimeNotifyByWechat?: boolean;
    public sendClassTimeNotifyBlacklist?: string[];
    public isSendClassTimeSignByApp?: boolean;
    public isSendClassTimeSignBySms?: boolean;
    public isSendClassTimeSignByWechat?: boolean;
    public sendClassTimeSignBlacklist?: string[];
    public isSendClassTimeCommentApp?: boolean;
    public isSendClassTimeCommentBySms?: boolean;
    public isSendClassTimeCommentByWechat?: boolean;
    public sendClassTimeCommentBlacklist?: string[];
    public isSendEventKGTimeByApp?: boolean;
    public isSendEventKGTimeBySms?: boolean;
    public isSendEventKGTimeByWechat?: boolean;
    public isSendEventTaskByApp?: boolean;
    public isSendEventTaskBySms?: boolean;
    public isSendEventTaskByWechat?: boolean;
    public isSendEventLiveClassRoomByApp?: boolean;
    public isSendEventLiveClassRoomBySms?: boolean;
    public isSendEventLiveClassRoomByWechat?: boolean;
    public isSendEventNotifyByApp?: boolean;
    public isSendEventNotifyBySms?: boolean;
    public isSendEventNotifyByWechat?: boolean;
    public isSendEventShareByApp?: boolean;
    public isSendEventShareBySms?: boolean;
    public isSendEventShareByWechat?: boolean;
    public isSendEventEvaluateByApp?: boolean;
    public isSendEventEvaluateBySms?: boolean;
    public isSendEventEvaluateByWechat?: boolean;
    public isSendEventKidRemarkByApp?: boolean;
    public isSendEventKidRemarkBySms?: boolean;
    public isSendEventKidRemarkByWechat?: boolean;
    public isSendEventVoteByApp?: boolean;
    public isSendEventVoteBySms?: boolean;
    public isSendEventVoteByWechat?: boolean;
    public isSendEventClockInByApp?: boolean;
    public isSendEventClockInBySms?: boolean;
    public isSendEventClockInByWechat?: boolean;
    public isSendEventDailyMenuByApp?: boolean;
    public isSendEventDailyMenuBySms?: boolean;
    public isSendEventDailyMenuByWechat?: boolean;
    public isSendEventCommissionPickupByApp?: boolean;
    public isSendEventCommissionPickupBySms?: boolean;
    public isSendEventCommissionPickupByWechat?: boolean;
    public isSendEventKidCheckUpByApp?: boolean;
    public isSendEventKidCheckUpBySms?: boolean;
    public isSendEventKidCheckUpByWechat?: boolean;
    public isSendEventKGTimeUnreadNotifyByWechat?: boolean;
    public sendClassTimeSignModifyBlacklist?: string[];
    public isSendCLassTimeSignModifyBySms?: boolean;
    public isSendClassTimeSignModifyByWechat?: boolean;
    public isSendClassTimeModifyBySms?: boolean;
    public isSendClassTimeModifyByWechat?: boolean;
    public sendClassTimeModifyBlacklist?: string[];
    public isSendCourseOrderNotifyBySms?: boolean;
    public isSendCourseOrderNotifyByWechat?: boolean;
    public sendCourseOrderNotifyBlacklist?: string[];
    public sendEventTaskBlacklist?: string[];
    public isSendEventTaskUnreadNotifyByWechat?: boolean;
    public isSendTeacherCommentByWechat?: boolean;
    public isSendTeacherCommentUnreadNotifyByWechat?: boolean;
    public sendTeacherCommentBlacklist?: string[];
    public isSendTeacherCommentBySms?: boolean;
    public isSendEventNotifyUnreadNotifyByWechat?: boolean;
    public sendEventNotifBlacklist?: string[];
    public paymentType?: string[];
    public alarmKidCourseBalanceMin?: number;
    public alarmKidCourseBalanceMinDays?: number;
    public isClassTimeSignUseAttendance?: boolean;
    public isShowTeacherFullPhoneNumber?: boolean;
    public isShowParentFullPhoneNumber?: boolean;
    public dailyMaxFullPhoneNumberRequest?: number;
    public isCanSetKidFaceByTeacher?: boolean;
    public isCanAddKidOtherParent?: boolean;
    public isShowBalance?: boolean;
    public isShowWatch?: boolean;
    public appSplashImageUrl?: string;
    public appSplashJumpUrl?: string;
    public enableIMClassGroup?: boolean;
    public isTeacherCanRecallParentIM?: boolean;
    public babyCareNotifyHour?: number;
    public babyCareWeekNotifyHour?: number;
    public babyCareWeekNotifyWeekDay?: MyDayOfWeek;
    public isSendBabyCareNotify?: boolean;
    public isSendBabyCareNotifyBySms?: boolean;
    public isSendBabyCareNotifyByWechat?: boolean;
    public isAllowSubmitBabyCareTimeOut?: boolean;
    public checkTimeDailyCareDrinkWater?: number;
    public checkTimeDailyCareMilk?: number;
    public checkTimeDailyCareDiaper?: number;
    public checkTimeDailyCareToilet?: number;
    public babyCareNoteTemplateDaily?: string;
    public babyCareNoteTemplateWeek?: string;
    public disabledPermissionCode?: string[];
    public teacherStudy_WaterMask_IsShowSchoolName?: boolean;
    public teacherStudy_WaterMask_IsShowTeacherName?: boolean;
    public teacherStudy_WaterMask_IsShowTeacherPhoneNumber?: boolean;
    public patrolType?: string[];

    public constructor(init?: Partial<SchoolSettingDTO>) { (Object as any).assign(this, init); }
}

export class DailyCareBatchLogDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public kidIds?: string[];
    public dailyCareIds?: string[];
    public type?: string;
    public teacherUserInfoId?: string;
    public recordDateOn?: string;
    public createOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;

    public constructor(init?: Partial<DailyCareBatchLogDTO>) { (Object as any).assign(this, init); }
}

export class DailyCareItem
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public recordDateOn?: string;
    public teacherUserInfoId?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public dailyCareType?: string;
    public checkTime?: string;
    public waterContent?: string;
    public eatStatus?: DAILYCARE_EAT_STATUS;
    public memo?: string;
    public toiletType?: DAILYCARE_TOILET_TYPE;
    public sleepOn?: string;
    public wakeupOn?: string;
    public milkVol?: number;
    public temperature?: string;
    public note?: string;
    public isInspected?: boolean;
    public inspectedOn?: string;

    public constructor(init?: Partial<DailyCareItem>) { (Object as any).assign(this, init); }
}

export class DailayCareNoticeItem
{
    public kidId?: string;
    public careType?: string;
    public noticeMsg?: string;
    public showOnTimestamp?: number;

    public constructor(init?: Partial<DailayCareNoticeItem>) { (Object as any).assign(this, init); }
}

export class ClassInfoWithTeacherRoleDTO
{
    public classId?: string;
    public className?: string;
    public myTeacherRole?: string;

    public constructor(init?: Partial<ClassInfoWithTeacherRoleDTO>) { (Object as any).assign(this, init); }
}

export enum DAILYCARE_DEVICE_TYPE
{
    MODEL_ONE = 'MODEL_ONE',
}

export class DailyCareDeviceDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public serialNo?: string;
    public deviceType?: DAILYCARE_DEVICE_TYPE;
    public macAddr?: string;
    public registOn?: string;
    public lastHeartbeatOn?: string;
    public lastIPAddr?: string;
    public remark?: string;
    public lastModifyOn?: string;
    public liveChannelIds?: string[];
    public isBYOD?: boolean;
    public expireOn?: string;
    public osType?: OSTYPE;

    public constructor(init?: Partial<DailyCareDeviceDTO>) { (Object as any).assign(this, init); }
}

export class BabyCareBYODDeviceLicenseItem
{
    public id?: string;
    public schoolId?: string;
    public dailyCareDeviceId?: string;
    public createOn?: string;
    public dayCount?: number;
    public activeOn?: string;
    public expiredOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<BabyCareBYODDeviceLicenseItem>) { (Object as any).assign(this, init); }
}

export class BabyCareTaskDTO
{
    public id?: string;
    public schoolId?: string;
    public allocatedClassIds?: string[];
    public taskName?: string;
    public isEnabled?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public studyId?: string;
    public flows?: BabyCareTaskFlowDTO[];

    public constructor(init?: Partial<BabyCareTaskDTO>) { (Object as any).assign(this, init); }
}

export enum DAILYCARE_DVR_STATUS
{
    NONE = 'NONE',
    INIT = 'INIT',
    PROCESSING = 'PROCESSING',
    FINISH = 'FINISH',
    FAILED = 'FAILED',
}

export class BabyCareTaskWorkDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public dailyCareDeviceId?: string;
    public babyCareTaskId?: string;
    public babyCareTaskFlowId?: string;
    public teacherUserInfoId?: string;
    public dateOn?: string;
    public createOn?: string;
    public isInspected?: boolean;
    public status?: DAILYCARE_WORK_STATUS;
    public inspectedOn?: string;
    public videoOssId?: string;
    public videoUrl?: string;
    public videoDuration?: number;
    public dvrStatus?: DAILYCARE_DVR_STATUS;

    public constructor(init?: Partial<BabyCareTaskWorkDTO>) { (Object as any).assign(this, init); }
}

export class DailyMenuDTO
{
    public id?: string;
    public schoolId?: string;
    public dateOn?: string;
    public title?: string;
    public content1?: string;
    public attachment1?: EventAttachDTO;
    public attachs1?: EventAttachItem;
    public content2?: string;
    public attachment2?: EventAttachDTO;
    public attachs2?: EventAttachItem;
    public content3?: string;
    public attachment3?: EventAttachDTO;
    public attachs3?: EventAttachItem;
    public isPublished?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public contents?: DishMenuContentDTO[];
    public nutritions?: Nutrition;
    public createByTeacherId?: string;

    public constructor(init?: Partial<DailyMenuDTO>) { (Object as any).assign(this, init); }
}

export class BabyDailySummaryItem
{
    public icon?: string;
    public name?: string;
    public maxValue?: number;
    public avgValue?: number;
    public value?: number;

    public constructor(init?: Partial<BabyDailySummaryItem>) { (Object as any).assign(this, init); }
}

export class DailyCareSchoolDailySummaryDTO
{
    public reportDate?: string;
    public schoolId?: string;
    public classId?: string;
    public classCount?: number;
    public kidCount?: number;
    public count?: number;

    public constructor(init?: Partial<DailyCareSchoolDailySummaryDTO>) { (Object as any).assign(this, init); }
}

export class BabyCareTaskWorkSchoolDailySummaryDTO
{
    public reportDate?: string;
    public schoolId?: string;
    public classId?: string;
    public classCount?: number;
    public onTimeCount?: number;
    public workCount?: number;
    public flowCount?: number;

    public constructor(init?: Partial<BabyCareTaskWorkSchoolDailySummaryDTO>) { (Object as any).assign(this, init); }
}

export enum CLASSBILL_TYPE
{
    CLASSBILL_TYPE_PER_FIXED = 'CLASSBILL_TYPE_PER_FIXED',
    CLASSBILL_TYPE_FREE = 'CLASSBILL_TYPE_FREE',
}

export enum CLASSBILL_ITEM_STATUS
{
    CLASSBILL_INIT = 'CLASSBILL_INIT',
    CLASSBILL_PAYING = 'CLASSBILL_PAYING',
    CLASSBILL_CANCELED = 'CLASSBILL_CANCELED',
    CLASSBILL_FINISHED = 'CLASSBILL_FINISHED',
    CLASSBILL_TERMINATED = 'CLASSBILL_TERMINATED',
}

export class ClassBillItemDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public name?: string;
    public teacherId?: string;
    public billType?: CLASSBILL_TYPE;
    public billAmount?: number;
    public totalBillAmount?: number;
    public currentTotalBillAmount?: number;
    public kidIds?: string[];
    public paidKidIds?: string[];
    public status?: CLASSBILL_ITEM_STATUS;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<ClassBillItemDTO>) { (Object as any).assign(this, init); }
}

export enum CLASSBILL_PAY_STATUS
{
    WAIT_PAY = 'WAIT_PAY',
    PAID = 'PAID',
    WAIT_REFUND = 'WAIT_REFUND',
    REFUND = 'REFUND',
    DUPLICATE_REFUND = 'DUPLICATE_REFUND',
    WAIT_DUPLICATE_REFUND = 'WAIT_DUPLICATE_REFUND',
}

export class ClassBillLogDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public classBillItemId?: string;
    public kidId?: string;
    public parentRelationSchoolInfoId?: string;
    public userIPAddr?: string;
    public paidAmount?: number;
    public payStatus?: CLASSBILL_PAY_STATUS;
    public orderId?: string;
    public orderNo?: string;
    public createdOn?: string;
    public paidOn?: string;
    public refundOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<ClassBillLogDTO>) { (Object as any).assign(this, init); }
}

export class ClassBillItemInfoDTO
{
    public billData?: ClassBillItemDTO;
    public billLog?: ClassBillLogDTO;

    public constructor(init?: Partial<ClassBillItemInfoDTO>) { (Object as any).assign(this, init); }
}

export class StudentInfoDTO
{
    public id?: string;
    public schoolId?: string;
    public parentKidId?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<StudentInfoDTO>) { (Object as any).assign(this, init); }
}

export class ClassInfoItemDTO
{
    public classId?: string;
    public gradeType?: CLASSGRADETYPE;
    public gradeTypeOnCreate?: CLASSGRADETYPE;
    public createOn?: string;
    public currentTermId?: number;
    public currentSemesterType?: SEMESTERTYPE;
    public name?: string;
    public originalClassName?: string;
    public schoolId?: string;
    public branchId?: string;
    public schoolName?: string;
    public schoolType?: SCHOOL_TYPE;
    public logoUrl?: string;
    public teacherList?: TeacherInfoDTO[];
    public kidList?: KidInfoDTO[];
    public courseId?: string;
    public classRoomId?: string;
    public capacity?: number;
    public courseHour?: number;
    public remark?: string;
    public liveChannelIds?: string[];

    public constructor(init?: Partial<ClassInfoItemDTO>) { (Object as any).assign(this, init); }
}

export class KidRemarkDTO
{
    public id?: string;
    public classId?: string;
    public schoolId?: string;
    public title?: string;
    public remarkType?: KID_REMARK_TYPE;
    public semesterType?: SEMESTERTYPE;
    public authorName?: string;
    public remarks?: KidRemarkItemDTO[];
    public createOn?: string;
    public lastModifyOn?: string;
    public state?: EVALUATESTATE;

    public constructor(init?: Partial<KidRemarkDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventTaskItem extends ClassEventItem
{
    public taskItems?: EventTaskItemDTO[];
    public canSeeOther?: boolean;

    public constructor(init?: Partial<ClassEventTaskItem>) { super(init); (Object as any).assign(this, init); }
}

export class EventClockInItemDTO
{
    public id?: string;
    public clockInGroupId?: string;
    public kidName?: string;
    public kidId?: string;
    public parentRole?: string;
    public parentId?: string;
    public kidAvatarUrl?: string;
    public content?: string;
    public createOn?: string;
    public isDelete?: boolean;
    public createBy?: number;
    public createIPAddr?: string;
    public deleteBy?: number;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public attachment?: EventAttachItem;
    public likeUids?: number[];
    public commentItems?: EventItemCommentItemDTO[];
    public clockInCount?: number;

    public constructor(init?: Partial<EventClockInItemDTO>) { (Object as any).assign(this, init); }
}

export enum KIDAPPLYSTATE
{
    STATE_WAITAPPROVE = 'STATE_WAITAPPROVE',
    STATE_APPROVED = 'STATE_APPROVED',
    STATE_DENYED = 'STATE_DENYED',
}

export class KidApplyInfoDTO
{
    public id?: string;
    public classId?: string;
    public name?: string;
    public avatarUrl?: string;
    public registerPhoneNumber?: string;
    public roleName?: string;
    public applyState?: KIDAPPLYSTATE;
    public createOn?: string;
    public createIPAddr?: string;

    public constructor(init?: Partial<KidApplyInfoDTO>) { (Object as any).assign(this, init); }
}

export class KidGrowthStandard
{
    public axis?: number;
    public sD_L2?: number;
    public sD_L1?: number;
    public sd?: number;
    public sD_H1?: number;
    public sD_H2?: number;

    public constructor(init?: Partial<KidGrowthStandard>) { (Object as any).assign(this, init); }
}

export class ClassRoomItem
{
    public id?: string;
    public branchId?: string;
    public name?: string;

    public constructor(init?: Partial<ClassRoomItem>) { (Object as any).assign(this, init); }
}

export class ClassScheduleItem
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public beginTime?: string;
    public endTime?: string;
    public startMinutes?: number;
    public endMinutes?: number;
    public weekDay?: MyDayOfWeek;
    public step?: ClassTimeScheduleStepType;
    public scheduleType?: ClassTimeScheduleType;

    public constructor(init?: Partial<ClassScheduleItem>) { (Object as any).assign(this, init); }
}

export class ClassTimeDTO
{
    public id?: string;
    public scheduleId?: string;
    public schoolId?: string;
    public classId?: string;
    public beginTime?: string;
    public endTime?: string;
    public startMinute?: number;
    public endMinute?: number;
    public index?: number;
    public signTime?: string;
    public signInfos?: ClassTimeSignInfoDTO[];
    public createOn?: string;
    public lastModifyOn?: string;
    public fixedStudentIds?: string[];
    public temporaryStudentIds?: string[];
    public demoStudentIds?: string[];
    public signTeacherUserInfoId?: string;
    public teacherUserInfoIds?: string[];
    public state?: ClassTimeState;

    public constructor(init?: Partial<ClassTimeDTO>) { (Object as any).assign(this, init); }
}

export class ClassTimeSignInfoKidDTO extends ClassTimeSignInfoDTO
{
    public classTimeId?: string;
    public schoolId?: string;
    public index?: number;
    public scheduleId?: string;
    public beginTime?: string;
    public classId?: string;
    public createOn?: string;
    public endMinute?: number;
    public startMinute?: number;

    public constructor(init?: Partial<ClassTimeSignInfoKidDTO>) { super(init); (Object as any).assign(this, init); }
}

export class SalesLeadDTO
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public studentName?: string;
    public primaryContact?: ParentInfoDTO;
    public gender?: GENDERTYPE;
    public birthDate?: string;
    public schoolName?: string;
    public schoolGrade?: string;
    public secondaryContact?: ParentInfoDTO;
    public homeAddr?: string;
    public intentType?: CUSTOM_INTENT_TYPE;
    public source?: string;
    public remark?: string;
    public tags?: string[];
    public createOn?: string;
    public lastModifyOn?: string;
    public followUpState?: FOLLOWUP_STATE;
    public followUpTeacherUserInfoId?: string;
    public lastFollowUpDate?: string;
    public nextFollowUpDate?: string;
    public followUpResult?: FOLLOWUP_RESULT;
    public kidId?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public deleteBy?: string;

    public constructor(init?: Partial<SalesLeadDTO>) { (Object as any).assign(this, init); }
}

export class SalesFollowUpInfoDTO
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public salesLeadId?: string;
    public followUpMethod?: FOLLOWUP_METHOD;
    public followUpResult?: FOLLOWUP_RESULT;
    public nextFollowUpDate?: string;
    public createByTeacherUserInfoId?: string;
    public followUpContent?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<SalesFollowUpInfoDTO>) { (Object as any).assign(this, init); }
}

export class SalesDemoInfoDTO
{
    public id?: string;
    public schoolId?: string;
    public salesLeadId?: string;
    public classId?: string;
    public courseId?: string;
    public scheduleId?: string;
    public index?: number;
    public beginTime?: string;
    public startMinute?: number;
    public endMinute?: number;
    public teacherUserInfoIds?: string[];
    public demoState?: DEMO_STATE;
    public createByTeacherUserInfoId?: string;
    public userIPAddr?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<SalesDemoInfoDTO>) { (Object as any).assign(this, init); }
}

export class CourseOrderDTO
{
    public orderNo?: string;
    public contractNo?: string;
    public schoolId?: string;
    public branchId?: string;
    public kidId?: string;
    public createOn?: string;
    public payedOn?: string;
    public orderType?: CourseOrderType;
    public orderItems?: CourseOrderItem[];
    public totalPrice?: string;
    public payable?: string;
    public payment?: string;
    public source?: string;
    public createByTeacherUserInfoId?: string;
    public userIPAddr?: string;
    public orderState?: CourseOrderState;
    public isDelete?: boolean;
    public deleteOn?: string;
    public expireOn?: string;
    public signOn?: string;
    public beginDate?: string;
    public remark?: string;
    public attachment?: EventAttachDTO;

    public constructor(init?: Partial<CourseOrderDTO>) { (Object as any).assign(this, init); }
}

export class StatementDetailDTO
{
    public id?: string;
    public branchId?: string;
    public createOn?: string;
    public catalog?: string;
    public isIncoming?: boolean;
    public amount?: string;
    public paymentType?: string;
    public statementAccountId?: string;
    public dateOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public teacherUserInfoId?: string;
    public remark?: string;
    public orderNo?: string;

    public constructor(init?: Partial<StatementDetailDTO>) { (Object as any).assign(this, init); }
}

export class StatementAccountDTO
{
    public id?: string;
    public isDelete?: boolean;
    public name?: string;

    public constructor(init?: Partial<StatementAccountDTO>) { (Object as any).assign(this, init); }
}

export class OrgStudentCourseDTO
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public studentId?: string;
    public courseOrderId?: string;
    public courseId?: string;
    public amount?: number;
    public giftAmount?: number;
    public transferOutAmount?: number;
    public usedAmount?: number;
    public usedGiftAmount?: number;
    public totalPrice?: string;
    public price?: string;
    public auditPrice?: string;
    public balanceGiftAmount?: number;
    public balanceAmount?: number;
    public balance?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public salesTeacherUserInfoId?: string;
    public expireOn?: string;
    public beginDate?: string;
    public type?: CourseSaleItemType;

    public constructor(init?: Partial<OrgStudentCourseDTO>) { (Object as any).assign(this, init); }
}

export class KidCourseBalanceItem
{
    public branchId?: string;
    public orgStudentCourseId?: string;
    public studentId?: string;
    public courseOrderId?: string;
    public courseId?: string;
    public type?: CourseSaleItemType;
    public amount?: number;
    public giftAmount?: number;
    public transferOutAmount?: number;
    public usedAmount?: number;
    public usedGiftAmount?: number;
    public totalPrice?: number;
    public price?: number;
    public auditPrice?: number;
    public balanceGiftAmount?: number;
    public balanceAmount?: number;
    public balance?: number;
    public createOn?: string;
    public expireOn?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<KidCourseBalanceItem>) { (Object as any).assign(this, init); }
}

export class CourseConsumLogDTO
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public studentId?: string;
    public courseId?: string;
    public classTimeId?: string;
    public eventId?: string;
    public orgStudentCourseId?: string;
    public modifyAmount?: number;
    public useGift?: boolean;
    public price?: string;
    public remark?: string;
    public createOn?: string;
    public userIPAddr?: string;
    public source?: COURSE_LOG_SOURCE;
    public classId?: string;
    public signDate?: string;
    public isReversal?: boolean;

    public constructor(init?: Partial<CourseConsumLogDTO>) { (Object as any).assign(this, init); }
}

export class CourseDTO
{
    public id?: string;
    public schoolId?: string;
    public name?: string;
    public isOneToOne?: boolean;
    public colorId?: number;
    public signType?: CourseSignType;
    public saleItemsByCount?: CourseSaleItemDTO[];
    public saleItemsByMonth?: CourseSaleItemDTO[];
    public deductionWhenLeave?: boolean;
    public deductionWhenAbsence?: boolean;
    public isDisabled?: boolean;
    public remark?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<CourseDTO>) { (Object as any).assign(this, init); }
}

export class ParentRelationSchoolInfoDTO
{
    public id?: string;
    public kidEntityUserAuthMapId?: string;
    public userId?: number;
    public schoolId?: string;
    public userProfileId?: string;
    public createOn?: string;
    public createIPAddr?: string;
    public kidId?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public parentRole?: string;

    public constructor(init?: Partial<ParentRelationSchoolInfoDTO>) { (Object as any).assign(this, init); }
}

export class KidEntityUserAuthMapDTO
{
    public id?: string;
    public userId?: number;
    public kidEntityId?: string;
    public isDefault?: boolean;
    public parentRole?: string;
    public isDelete?: boolean;
    public createOn?: string;
    public lastModifyOn?: string;
    public rongCloudUserId?: string;
    public phoneNumber?: string;
    public isActive?: boolean;

    public constructor(init?: Partial<KidEntityUserAuthMapDTO>) { (Object as any).assign(this, init); }
}

export class BuyContentDTO
{
    public classEventData?: ClassEventItem;
    public parentRelationSchoolInfo?: ParentRelationSchoolInfoDTO;
    public kidEntityUserAuthMap?: KidEntityUserAuthMapDTO;

    public constructor(init?: Partial<BuyContentDTO>) { (Object as any).assign(this, init); }
}

export class GrowBookFrontPageTemplateDTO
{
    public id?: string;
    public name?: string;
    public frontPageUrl?: string;
    public backPageUrl?: string;
    public createOn?: string;
    public isEnabled?: boolean;

    public constructor(init?: Partial<GrowBookFrontPageTemplateDTO>) { (Object as any).assign(this, init); }
}

export class SchoolIMConversation
{
    public id?: string;
    public classId?: string;
    public teacherId?: string;
    public parentId?: string;
    public lastMessageOn?: string;

    public constructor(init?: Partial<SchoolIMConversation>) { (Object as any).assign(this, init); }
}

export class SchoolIMHistory
{
    public id?: string;
    public from?: string;
    public profileId?: string;
    public createOn?: string;
    public text?: string;
    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<SchoolIMHistory>) { (Object as any).assign(this, init); }
}

export class IMContactItem
{
    public kidId?: string;
    public parentId?: string;
    public name?: string;
    public rongCloudUserId?: string;
    public avatarUrl?: string;
    public classId?: string;
    public schoolId?: string;
    public className?: string;
    public schoolName?: string;
    public isTeacher?: boolean;
    public teacherRole?: string;
    public parentRole?: string;
    public phoneNumber?: string;

    public constructor(init?: Partial<IMContactItem>) { (Object as any).assign(this, init); }
}

export class GroupItem
{
    public groupId?: string;
    public userRongIMId?: string[];

    public constructor(init?: Partial<GroupItem>) { (Object as any).assign(this, init); }
}

export class SchoolBaseInfo
{
    public id?: string;
    public name?: string;
    public logoUrl?: string;
    public loginBgUrl?: string;

    public constructor(init?: Partial<SchoolBaseInfo>) { (Object as any).assign(this, init); }
}

export class RongIMConvDTO
{
    public convId?: string;
    public toImUserId?: string;
    public msgTime?: string;

    public constructor(init?: Partial<RongIMConvDTO>) { (Object as any).assign(this, init); }
}

export class RongIMHistoryDTO
{
    public id?: string;
    public convId?: string;
    public fromUserId?: string;
    public fromUserName?: string;
    public toUserId?: string;
    public toUserName?: string;
    public objectName?: string;
    public content?: string;
    public channelType?: string;
    public msgTime?: string;
    public source?: string;
    public isRecall?: boolean;
    public recallOn?: string;

    public constructor(init?: Partial<RongIMHistoryDTO>) { (Object as any).assign(this, init); }
}

export class IMCatalogItem
{
    public name?: string;
    public iconUrl?: string;
    public filter?: string;

    public constructor(init?: Partial<IMCatalogItem>) { (Object as any).assign(this, init); }
}

export class IotCatalogDTO
{
    public id?: string;
    public code?: string;
    public name?: string;
    public createOn?: string;

    public constructor(init?: Partial<IotCatalogDTO>) { (Object as any).assign(this, init); }
}

export class IotDeviceAllowcateDTO
{
    public schoolId?: string;
    public userRole?: USERROLE;
    public entityId?: string;
    public userId?: number;
    public fullName?: string;

    public constructor(init?: Partial<IotDeviceAllowcateDTO>) { (Object as any).assign(this, init); }
}

export class IotDeviceDTO
{
    public id?: string;
    public schoolId?: string;
    public catalogId?: string;
    public catalogName?: string;
    public catalogCode?: string;
    public serialNo?: string;
    public deviceId?: string;
    public deviceConfig?: string;
    public createOn?: string;
    public updateOn?: string;
    public lastModifyOn?: string;
    public allocates?: IotDeviceAllowcateDTO[];

    public constructor(init?: Partial<IotDeviceDTO>) { (Object as any).assign(this, init); }
}

export class KidMyLeaveRequestItem
{
    public id?: string;
    public type?: LEAVE_REQUEST_TYPE;
    public isTeacher?: boolean;
    public classId?: string;
    public schoolId?: string;
    public leaveEntityId?: string;
    public leaveRequesterId?: string;
    public createOn?: string;
    public timeBegin?: string;
    public timeEnd?: string;
    public hours?: string;
    public userIPAddr?: string;
    public state?: LEAVESTATE;
    public auditOn?: string;
    public auditMsg?: string;
    public cancelOn?: string;
    public auditorId?: string;
    public auditUserIPAddr?: string;
    public lastModifyOn?: string;
    public leaveSubject?: string;
    public leaveDescribe?: string;

    public constructor(init?: Partial<KidMyLeaveRequestItem>) { (Object as any).assign(this, init); }
}

export class LiveClassRoomDTO
{
    public id?: string;
    public schoolId?: string;
    public roomId?: string;
    public createByTeacherId?: string;
    public roomMasterTeacherId?: string;
    public roomMasterAgoraUId?: number;
    public createOn?: string;
    public openOn?: string;
    public closeOn?: string;
    public lastModifyOn?: string;
    public title?: string;
    public content?: string;
    public totalSeconds?: number;
    public planSeconds?: number;
    public attachment?: EventAttachDTO;
    public kidMembers?: string[];
    public teacherMembers?: string[];
    public playbackUrl?: string;
    public agoraRecordUrl?: string;
    public userIPAddr?: string;
    public roomStatus?: LIVECLASSROOMSTATUS;
    public publishState?: EVENTPUBLISHSTATE;

    public constructor(init?: Partial<LiveClassRoomDTO>) { (Object as any).assign(this, init); }
}

export class PaymentSharingInfoDTO
{
    public id?: string;
    public subMchId?: string;
    public paymentSubMchInfoId?: string;
    public sharingNo?: string;
    public orderId?: string;
    public transactionId?: string;
    public sftSharingNo?: string;
    public amount?: number;
    public sharingState?: ORDER_SHARING_STATE;
    public createOn?: string;
    public processOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<PaymentSharingInfoDTO>) { (Object as any).assign(this, init); }
}

export enum USER_TYPE
{
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}

export class OrderInfoDTO
{
    public id?: string;
    public productId?: string;
    public orderNo?: string;
    public appId?: number;
    public osType?: OSTYPE;
    public schoolId?: string;
    public price?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public payedOn?: string;
    public userIPAddr?: string;
    public userType?: USER_TYPE;
    public userId?: number;
    public kidId?: string;
    public catalog?: ORDERPRODUCT_CATALOG;
    public paymentType?: ORDERPAYMENT_TYPE;
    public amount?: number;
    public productName?: string;
    public orderStatus?: ORDERSTAUS;
    public isNeedShare?: boolean;
    public shareState?: ORDER_SHARE_STATE;
    public transactionResult?: string;
    public weChatUserId?: string;
    public activityType?: MARKETING_ACTIVITY_TYPE;
    public activityOrderId?: string;
    public isRefund?: boolean;
    public refundOrderId?: string;

    public constructor(init?: Partial<OrderInfoDTO>) { (Object as any).assign(this, init); }
}

export class SubMchDrawInfoDTO
{
    public id?: string;
    public withDrawOrderNo?: string;
    public subMchId?: string;
    public paymentSubMchInfoId?: string;
    public fullAmount?: number;
    public deDuctAmount?: number;
    public serviceFeeAmount?: number;
    public drawAmount?: number;
    public drawState?: SUBMCH_DRAW_STATE;
    public sftAcquireOrderNo?: string;
    public bankAccountType?: string;
    public bankAccount?: string;
    public bankAccountName?: string;
    public bankName?: string;
    public createOn?: string;
    public processOn?: string;
    public lastModifyOn?: string;
    public createUserIPAddr?: string;

    public constructor(init?: Partial<SubMchDrawInfoDTO>) { (Object as any).assign(this, init); }
}

export class SubMchAccountHistoryDTO
{
    public id?: string;
    public schoolId?: string;
    public bizOrderId?: string;
    public bizOrderNo?: string;
    public subject?: string;
    public amount?: number;
    public balance?: number;
    public bizType?: SUBMCH_BIZ_TYPE;
    public createOn?: string;
    public userIPAddr?: string;

    public constructor(init?: Partial<SubMchAccountHistoryDTO>) { (Object as any).assign(this, init); }
}

export class RefundOrderInfoDTO
{
    public id?: string;
    public schoolId?: string;
    public orderNo?: string;
    public orderId?: string;
    public refundOrderNo?: string;
    public refundFee?: number;
    public totalFee?: number;
    public refundDesc?: string;
    public paymentType?: ORDERPAYMENT_TYPE;
    public refundStatus?: REFUNDORDERSTATUS;
    public transactionId?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public refundOn?: string;

    public constructor(init?: Partial<RefundOrderInfoDTO>) { (Object as any).assign(this, init); }
}

export class SchoolNotice
{
    public id?: string;
    public createOn?: string;
    public createTimestamp?: number;
    public updateOn?: string;
    public updateTimestamp?: number;
    public isPublish?: boolean;
    public showOn?: string;
    public title?: string;
    public content?: string;
    public createByUserName?: string;
    public userLogoUrl?: string;
    public userProfileId?: string;
    public attachItem?: EventAttachItem;
    public targetTeacherUIds?: number[];

    public constructor(init?: Partial<SchoolNotice>) { (Object as any).assign(this, init); }
}

export class ClassInfoDTO
{
    public classId?: string;
    public gradeType?: CLASSGRADETYPE;
    public isGraduation?: boolean;
    public gradeTypeOnCreate?: CLASSGRADETYPE;
    public createOn?: string;
    public currentTermId?: number;
    public currentSemesterType?: SEMESTERTYPE;
    public name?: string;
    public originalClassName?: string;
    public schoolId?: string;
    public branchId?: string;
    public logoUrl?: string;
    public teacherList?: string[];
    public kidList?: string[];
    public courseId?: string;
    public classRoomId?: string;
    public capacity?: number;
    public courseHour?: number;
    public remark?: string;
    public liveChannelIds?: string[];
    public classStatus?: CLASS_STATUS;

    public constructor(init?: Partial<ClassInfoDTO>) { (Object as any).assign(this, init); }
}

export enum EDU_PARTNER_TYPE
{
    CITY_PARTNER = 'CITY_PARTNER',
    BRAND_PARTNER = 'BRAND_PARTNER',
}

export enum EDU_PARTNER_LEVEL
{
    LEVEL_1 = 1,
    LEVEL_2 = 2,
}

export class SchoolPartnerInfoDTO
{
    public id?: string;
    public name?: string;
    public partnerType?: EDU_PARTNER_TYPE;
    public partnerLevel?: EDU_PARTNER_LEVEL;
    public parentPartnerId?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<SchoolPartnerInfoDTO>) { (Object as any).assign(this, init); }
}

export class SchoolPartnerPermissionConfigDTO
{
    public id?: string;
    public parentId?: string;
    public name?: string;
    public permissionCode?: string;
    public path?: string;
    public isSchoolScope?: boolean;
    public orderIndex?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<SchoolPartnerPermissionConfigDTO>) { (Object as any).assign(this, init); }
}

export class UserAuthDTO
{
    public id?: number;
    public userName?: string;
    public phoneNumber?: string;
    public nickName?: string;
    public displayName?: string;
    public fullName?: string;

    public constructor(init?: Partial<UserAuthDTO>) { (Object as any).assign(this, init); }
}

export class RoleItemDTO
{
    public id?: string;
    public roleCode?: string;
    public name?: string;
    public isDefault?: boolean;
    public memo?: string;
    public permissionIds?: string[];
    public usageArea?: string;

    public constructor(init?: Partial<RoleItemDTO>) { (Object as any).assign(this, init); }
}

export class SchoolUserRoleItem
{
    public id?: string;
    public schoolId?: string;
    public roleCode?: string;
    public roleDesc?: string;
    public userId?: number;
    public teacherName?: string;
    public createOn?: string;

    public constructor(init?: Partial<SchoolUserRoleItem>) { (Object as any).assign(this, init); }
}

export class MedicCareDTO
{
    public id?: string;
    public schoolId?: string;
    public studentId?: string;
    public medicTimeOn?: string;
    public parentCreateOn?: string;
    public teacherSubmitOn?: string;
    public teacherActualTimeOn?: string;
    public lastModifyOn?: string;
    public stat?: MEDICSTAT;
    public parentImageItems?: EventAttachImageItemDTO[];
    public teacherImageItems?: EventAttachImageItemDTO[];
    public medicDesc?: string;
    public parentMemo?: string;
    public teacherMemo?: string;
    public parentRelationSchoolInfoId?: string;
    public teacherUserInfoId?: string;
    public logs?: MedicCareLogDTO[];

    public constructor(init?: Partial<MedicCareDTO>) { (Object as any).assign(this, init); }
}

export class LiveVideoVisitHistoryDTO
{
    public id?: string;
    public classId?: string;
    public kidId?: string;
    public kidName?: string;
    public visitorParentRole?: string;
    public createOn?: string;

    public constructor(init?: Partial<LiveVideoVisitHistoryDTO>) { (Object as any).assign(this, init); }
}

export class SchoolOrgInfoDTO
{
    public id?: string;
    public schoolId?: string;
    public name?: string;
    public deptType?: SCHOOL_DEPT_TYPE;
    public parentId?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public address?: string;
    public phoneNumber?: string;
    public location?: GeoCoordinates;

    public constructor(init?: Partial<SchoolOrgInfoDTO>) { (Object as any).assign(this, init); }
}

export class TopicTestInterceptGroupByKeyWordItem
{
    public keyWordType?: TOPIC_TEST_KEYWORD_TYPE;
    public allCount?: number;
    public count?: number;

    public constructor(init?: Partial<TopicTestInterceptGroupByKeyWordItem>) { (Object as any).assign(this, init); }
}

export class TopicTestInterceptGroupByKeyWordTypeDTO
{
    public keyWordType?: TOPIC_TEST_KEYWORD_TYPE;
    public allCount?: number;
    public count?: number;

    public constructor(init?: Partial<TopicTestInterceptGroupByKeyWordTypeDTO>) { (Object as any).assign(this, init); }
}

export class TopicTestResultItem
{
    public start?: number;
    public end?: number;
    public keyWordType?: TOPIC_TEST_KEYWORD_TYPE;
    public keyWord?: string;

    public constructor(init?: Partial<TopicTestResultItem>) { (Object as any).assign(this, init); }
}

export class TopicTestResult
{
    public content?: string;
    public result?: TopicTestResultItem[];

    public constructor(init?: Partial<TopicTestResult>) { (Object as any).assign(this, init); }
}

export class TopicTestInterceptLogDTO
{
    public id?: string;
    public schoolId?: string;
    public source?: TOPIC_TEST_SOURCE_TYPE;
    public createByUId?: number;
    public createByName?: string;
    public isCreateByTeacher?: boolean;
    public createByTeacherId?: string;
    public createByParentId?: string;
    public createByKidId?: string;
    public keyWordType?: TOPIC_TEST_KEYWORD_TYPE;
    public keyWords?: TopicTestResult[];
    public createOn?: string;
    public auditState?: EVENTAUDITSTATE;
    public publishState?: EVENTPUBLISHSTATE;
    public auditByTeacherId?: string;
    public auditOn?: string;
    public eventId?: string;
    public eventType?: string;
    public eventIds?: string[];
    public liveClassRoomId?: string;
    public eventCommentId?: string;
    public taskItemId?: string;
    public clockInGroupId?: string;
    public clockInItemId?: string;

    public constructor(init?: Partial<TopicTestInterceptLogDTO>) { (Object as any).assign(this, init); }
}

export class SchoolAuditLogDTO
{
    public id?: string;
    public schoolId?: string;
    public operaterType?: SCHOOL_AUDIT_OPERATER_TYPE;
    public serviceModule?: SCHOOL_SERVICE_SUB_MODULE;
    public auditType?: SCHOOL_AUDIT_TYPE;
    public operaterTeacherId?: string;
    public isTeacher?: boolean;
    public entityId?: string;
    public remark?: string;
    public ipAddr?: string;
    public createOn?: string;

    public constructor(init?: Partial<SchoolAuditLogDTO>) { (Object as any).assign(this, init); }
}

export class EvaluateDTO
{
    public id?: string;
    public classId?: string;
    public schoolId?: string;
    public title?: string;
    public evaluateType?: EVALUATETYPE;
    public semesterType?: SEMESTERTYPE;
    public finishTime?: string;
    public createBy?: number;
    public createByUserName?: string;
    public createOn?: string;
    public evaluateState?: EVALUATESTATE;
    public publishOn?: string;
    public items?: EvaluateItemDTO[];
    public targetKids?: string[];

    public constructor(init?: Partial<EvaluateDTO>) { (Object as any).assign(this, init); }
}

export class EvaluateTemplateDTO
{
    public id?: string;
    public name?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public items?: EvaluateItemBaseDTO[];

    public constructor(init?: Partial<EvaluateTemplateDTO>) { (Object as any).assign(this, init); }
}

export class ChartSerialItem
{
    public serial?: string;
    public value?: number;

    public constructor(init?: Partial<ChartSerialItem>) { (Object as any).assign(this, init); }
}

export class ChartData
{
    public title?: string;
    public serials?: ChartSerialItem[];

    public constructor(init?: Partial<ChartData>) { (Object as any).assign(this, init); }
}

export class DailyHealthyDTO
{
    public id?: string;
    public schoolId?: string;
    public kidId?: string;
    public dateOn?: string;
    public temperature?: string;
    public mouthHaveProblem?: boolean;
    public mouthProblem?: string;
    public bodyHaveProblem?: boolean;
    public bodyProblem?: string;
    public remark?: string;
    public teacherUserInfoId?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<DailyHealthyDTO>) { (Object as any).assign(this, init); }
}

export class TeacherAppMenuDTO
{
    public id?: string;
    public permissionCode?: string;
    public name?: string;
    public eName?: string;
    public moduleName?: string;
    public moduleCode?: string;
    public appH5Url?: string;
    public appH5FullScreen?: boolean;
    public appRoute?: string;
    public appMinVersion?: string;
    public appIconUrl?: string;
    public showInAppArea?: string[];
    public appMPEnabled?: boolean;
    public appMPAppId?: string;
    public appMPPath?: string;
    public appMPVersionCode?: string;
    public appMPDownloadUrl?: string;

    public constructor(init?: Partial<TeacherAppMenuDTO>) { (Object as any).assign(this, init); }
}

export class WorkTime
{
    public workDay?: number[];
    public morningBegin?: string;
    public morningEnd?: string;
    public afternoonBegin?: string;
    public afternoonEnd?: string;

    public constructor(init?: Partial<WorkTime>) { (Object as any).assign(this, init); }
}

export class TeacherInSchoolRoleType
{
    public schoolId?: string;
    public schoolName?: string;
    public schoolEName?: string;
    public teacherUserInfoId?: string;
    public roleCodes?: string[];
    public permissionCodes?: string[];
    public schoolTeacherFullName?: string;
    public schoolTeacherRemark?: string;
    public rongCloudToken?: string;
    public rongCloudUserId?: string;
    public branchRole?: { [index: string]: string; };

    public constructor(init?: Partial<TeacherInSchoolRoleType>) { (Object as any).assign(this, init); }
}

export class TeacherMyUserInfo
{
    public uid?: number;
    public displayname?: string;
    public fullname?: string;
    public nickname?: string;
    public username?: string;
    public logourl?: string;
    public profileId?: string;
    public imClientId?: string;
    public imLoginSign?: string;
    public imLoginSignNonce?: string;
    public imLoginSignTimestamp?: number;
    public schoolRoles?: TeacherInSchoolRoleType[];

    public constructor(init?: Partial<TeacherMyUserInfo>) { (Object as any).assign(this, init); }
}

export class TermInfoItem
{
    public termId?: number;
    public startTime?: string;
    public endTime?: string;

    public constructor(init?: Partial<TermInfoItem>) { (Object as any).assign(this, init); }
}

export class SchoolNoticeFeed
{
    public noticeId?: string;
    public createOn?: string;
    public createTimestamp?: number;
    public updateOn?: string;
    public updateTimestamp?: number;
    public isViewed?: boolean;
    public isAcked?: boolean;

    public constructor(init?: Partial<SchoolNoticeFeed>) { (Object as any).assign(this, init); }
}

export class KGLibIndexItemSimple
{
    public libId?: string;
    public timestamp?: number;
    public classId?: string;
    public schoolId?: string;
    public shareType?: KGLIB_SHARETYPE;

    public constructor(init?: Partial<KGLibIndexItemSimple>) { (Object as any).assign(this, init); }
}

export class KGLibItemDTO
{
    public userName?: string;
    public userProfileId?: string;
    public classId?: string;
    public schoolId?: string;
    public schoolName?: string;
    public className?: string;
    public userLogoUrl?: string;
    public kgLibId?: string;
    public kgLibType?: string;
    public timeStamp?: number;
    public createOn?: string;
    public updateOn?: string;
    public title?: string;
    public content?: string;
    public attachItem?: EventAttachItem;
    public shareType?: KGLIB_SHARETYPE;
    public questions?: VoteItemSerial[];
    public tags?: string[];

    public constructor(init?: Partial<KGLibItemDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventTrendParent
{
    public parentId?: string;
    public roleName?: string;
    public isDefault?: boolean;
    public createOn?: string;

    public constructor(init?: Partial<ClassEventTrendParent>) { (Object as any).assign(this, init); }
}

export class ClassEventTrendItemView
{
    public kidId?: string;
    public kidName?: string;
    public kidLogoUrl?: string;
    public parents?: ClassEventTrendParent[];
    public firstCreateOn?: string;

    public constructor(init?: Partial<ClassEventTrendItemView>) { (Object as any).assign(this, init); }
}

export class ClassEventTrendInfo
{
    public trendView?: ClassEventTrendItemView[];
    public trendBook?: ClassEventTrendItemView[];
    public trendTask?: ClassEventTrendItemView[];
    public trendNotifyAck?: ClassEventTrendItemView[];
    public trendShareVisit?: ClassEventTrendItemView[];
    public trendVoteAck?: ClassEventTrendItemView[];

    public constructor(init?: Partial<ClassEventTrendInfo>) { (Object as any).assign(this, init); }
}

export class ClassEventItemForTeacher extends ClassEventItem
{
    public publishState?: EVENTPUBLISHSTATE;
    public isCanPublish?: boolean;
    public isCanUpdate?: boolean;
    public trendData?: ClassEventTrendInfo;

    public constructor(init?: Partial<ClassEventItemForTeacher>) { super(init); (Object as any).assign(this, init); }
}

export class EventBaseDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public createOn?: string;
    public updatedOn?: string;
    public timeStamp?: number;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public createBy?: number;
    public createIPAddr?: string;
    public deleteBy?: number;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public showOn?: string;
    public showBeforeTimestamp?: number;
    public title?: string;
    public content?: string;
    public attachment?: EventAttachDTO;
    public targetKids?: string[];
    public isPartialPublish?: boolean;
    public syncAttach?: boolean;
    public publishState?: EVENTPUBLISHSTATE;
    public auditState?: EVENTAUDITSTATE;
    public auditByUId?: number;
    public teachingThemeId?: string;
    public commentType?: TASKATTACHTYPE;
    public termId?: number;
    public contentTime?: string;
    public needPayment?: boolean;
    public paymentCourseId?: string;
    public paymentPrice?: number;
    public isVisited?: boolean;
    public isAcked?: boolean;
    public eventType?: string;
    public kidId?: string;
    public parentRelationSchoolInfoId?: string;
    public teacherUserInfoId?: string;
    public extraFields?: { [index: string]: Object; };
    public studentTotalCount?: number;
    public isCanPublish?: boolean;
    public isCanUpdate?: boolean;

    public constructor(init?: Partial<EventBaseDTO>) { (Object as any).assign(this, init); }
}

export class TeachingThemeDTO
{
    public id?: string;
    public title?: string;
    public content?: string;
    public createBy?: number;
    public createOn?: string;
    public semesterType?: SEMESTERTYPE;
    public orderIndex?: number;
    public classId?: string;
    public schoolId?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<TeachingThemeDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventTrendItemViewV2
{
    public studentId?: string;
    public firstCreateOn?: string;

    public constructor(init?: Partial<ClassEventTrendItemViewV2>) { (Object as any).assign(this, init); }
}

export class ClassEventTrendInfoV2
{
    public trendView?: ClassEventTrendItemViewV2[];
    public trendBook?: ClassEventTrendItemViewV2[];
    public trendTask?: ClassEventTrendItemViewV2[];
    public trendNotifyAck?: ClassEventTrendItemViewV2[];
    public trendShareVisit?: ClassEventTrendItemViewV2[];
    public trendVoteAck?: ClassEventTrendItemViewV2[];

    public constructor(init?: Partial<ClassEventTrendInfoV2>) { (Object as any).assign(this, init); }
}

export class ClassEventKGTimeDTO extends ClassEventItem
{

    public constructor(init?: Partial<ClassEventKGTimeDTO>) { super(init); (Object as any).assign(this, init); }
}

export class EventFeedDTO
{
    public eventId?: string;
    public kidId?: string;
    public classId?: string;
    public schoolId?: string;
    public eventType?: string;
    public timestamp?: number;
    public isViewed?: boolean;
    public isAcked?: boolean;

    public constructor(init?: Partial<EventFeedDTO>) { (Object as any).assign(this, init); }
}

export class EventTemplateCatalogDTO
{
    public eventClass?: string;
    public catalogs?: string[];
    public version?: number;

    public constructor(init?: Partial<EventTemplateCatalogDTO>) { (Object as any).assign(this, init); }
}

export class EventTemplateRespDTO
{
    public eventClass?: string;
    public version?: number;
    public code?: CONFIGTYPE;
    public hashString?: string;
    public patch?: string;
    public data?: string;

    public constructor(init?: Partial<EventTemplateRespDTO>) { (Object as any).assign(this, init); }
}

export class GrowBookSummaryItem
{
    public kidId?: string;
    public kidName?: string;
    public isOk?: boolean;
    public totalAmount?: number;
    public teacherAmount?: number;
    public parentAmount?: number;
    public bookQueryString?: string;

    public constructor(init?: Partial<GrowBookSummaryItem>) { (Object as any).assign(this, init); }
}

export class GrowBookSummaryDTO
{
    public classId?: string;
    public className?: string;
    public originalClassName?: string;
    public schoolId?: string;
    public schoolName?: string;
    public frontPageUrl?: string;
    public semesterType?: SEMESTERTYPE;
    public termId?: number;
    public items?: GrowBookSummaryItem[];

    public constructor(init?: Partial<GrowBookSummaryDTO>) { (Object as any).assign(this, init); }
}

export class GrowBookClassSummaryDTO
{
    public classId?: string;
    public className?: string;
    public originalClassName?: string;
    public frontPageUrl?: string;
    public semesterType?: SEMESTERTYPE;
    public termId?: number;
    public studentTotalCount?: number;
    public studentParentCount?: number;
    public studentIsOkCount?: number;

    public constructor(init?: Partial<GrowBookClassSummaryDTO>) { (Object as any).assign(this, init); }
}

export class TeacherStudyCatalogDTO
{
    public isPartner?: boolean;
    public id?: string;
    public schoolId?: string;
    public name?: string;
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public createByTeacherId?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<TeacherStudyCatalogDTO>) { (Object as any).assign(this, init); }
}

export class TeacherStudyItemFeedDTO
{
    public id?: string;
    public updateTimestamp?: number;
    public isViewed?: boolean;

    public constructor(init?: Partial<TeacherStudyItemFeedDTO>) { (Object as any).assign(this, init); }
}

export class SchoolKidAttendanceInfo
{
    public avatarUrl?: string;
    public gender?: GENDERTYPE;
    public kidName?: string;
    public isCheckIn?: boolean;
    public isLeave?: boolean;

    public constructor(init?: Partial<SchoolKidAttendanceInfo>) { (Object as any).assign(this, init); }
}

export class SchoolKidAttendanceClassInfo
{
    public className?: string;
    public capacity?: number;
    public kidData?: SchoolKidAttendanceInfo[];

    public constructor(init?: Partial<SchoolKidAttendanceClassInfo>) { (Object as any).assign(this, init); }
}

export class KidAttendanceBindItem
{
    public cardSerialNo?: string;
    public bindTime?: string;
    public kidId?: string;
    public parentRelationSchoolInfoId?: string;
    public parentRole?: string;
    public parentUserId?: number;
    public isForStudent?: boolean;

    public constructor(init?: Partial<KidAttendanceBindItem>) { (Object as any).assign(this, init); }
}

export class KidBaseInfoDTO
{
    public parentRelationId?: string;
    public kidId?: string;
    public kidName?: string;
    public classInfos?: KidClassInfoDTO[];
    public schoolName?: string;
    public schoolId?: string;
    public avatarUrl?: string;
    public gender?: GENDERTYPE;
    public hobby?: string;
    public birthDate?: string;
    public parentRole?: string;
    public kidEntityId?: string;
    public kidEntityName?: string;
    public state?: KIDACCOUNTSTATE;
    public studentStatus?: STUDENT_STATUS;
    public isAppMember?: boolean;
    public hideMemberCenter?: boolean;
    public appMemberExpiredOn?: string;

    public constructor(init?: Partial<KidBaseInfoDTO>) { (Object as any).assign(this, init); }
}

export class ParentMyUserInfo
{
    public uid?: number;
    public displayname?: string;
    public fullname?: string;
    public nickname?: string;
    public username?: string;
    public logourl?: string;
    public roles?: string[];
    public profileId?: string;
    public imClientId?: string;
    public imLoginSign?: string;
    public imLoginSignNonce?: string;
    public imLoginSignTimestamp?: number;
    public birthdate?: string;
    public hobby?: string;
    public gender?: GENDERTYPE;
    public classInfos?: KidClassInfoDTO[];
    public schoolName?: string;
    public rongCloudToken?: string;
    public rongCloudUserId?: string;
    public isAppMember?: boolean;
    public appMemberPayByWxLite?: boolean;
    public hideMemberCenter?: boolean;
    public appMemberExpireOn?: string;
    public isEnforceMember?: boolean;

    public constructor(init?: Partial<ParentMyUserInfo>) { (Object as any).assign(this, init); }
}

export class BindKidItem
{
    public kidId?: string;
    public kidName?: string;
    public birthDate?: string;
    public gender?: GENDERTYPE;
    public phoneNumbers?: string[];

    public constructor(init?: Partial<BindKidItem>) { (Object as any).assign(this, init); }
}

export class KidEntityItem
{
    public kidEntityId?: string;
    public kidEntityName?: string;
    public avatarUrl?: string;
    public birthDate?: string;
    public gender?: GENDERTYPE;

    public constructor(init?: Partial<KidEntityItem>) { (Object as any).assign(this, init); }
}

export class AttendanceKidLogDTO
{
    public schoolId?: string;
    public classId?: string;
    public attendanceType?: ATTENDANCETYPE;
    public attendanceMethod?: ATTENDANCEMETHOD;
    public isCheckIn?: boolean;
    public checkUUID?: string;
    public createOn?: string;
    public attendanceDeviceId?: string;
    public attendanceUserMapId?: string;

    public constructor(init?: Partial<AttendanceKidLogDTO>) { (Object as any).assign(this, init); }
}

export class LiveVideoItem
{
    public name?: string;
    public liveUrl?: string;

    public constructor(init?: Partial<LiveVideoItem>) { (Object as any).assign(this, init); }
}

export class LiveVideoVisitHistory
{
    public kidId?: string;
    public kidName?: string;
    public parentRole?: string;
    public visitOn?: string;

    public constructor(init?: Partial<LiveVideoVisitHistory>) { (Object as any).assign(this, init); }
}

export class LiveMemberDTO
{
    public expireOn?: string;
    public isTrial?: boolean;
    public trialSeconds?: number;
    public lastPaymentOn?: string;

    public constructor(init?: Partial<LiveMemberDTO>) { (Object as any).assign(this, init); }
}

export class BookExtraDTO
{
    public id?: string;
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public createOn?: string;
    public updatedOn?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public createBy?: number;
    public createIPAddr?: string;
    public deleteBy?: number;
    public deleteOn?: string;
    public deleteIPAddr?: string;
    public contentTime?: string;
    public title?: string;
    public content?: string;
    public attachItem?: EventAttachItem;
    public bookExtraConfigId?: string;
    public bookExtraConfigItemId?: string;

    public constructor(init?: Partial<BookExtraDTO>) { (Object as any).assign(this, init); }
}

export class BookOrderItemDTO
{
    public bookOrderId?: string;
    public youzanOrderId?: string;
    public youzanTId?: string;
    public createOn?: string;
    public updateOn?: string;
    public orderStats?: BOOKORDER_STATS;
    public orderStatsStr?: string;
    public orderType?: BOOKORDER_TYPE;
    public orderTypeStr?: string;
    public kidId?: string;
    public kidName?: string;
    public gender?: GENDERTYPE;
    public termId?: number;
    public classId?: string;
    public className?: string;
    public schoolId?: string;
    public schoolName?: string;
    public orderCreateByUId?: number;
    public orderCreateByUserName?: string;
    public createIPAddr?: string;
    public bookPageCount?: number;
    public bookPageCountFull?: number;
    public price?: number;
    public productName?: string;
    public productImageUrl?: string;
    public deliveryAddress?: string;
    public receiverName?: string;
    public deliveryProvince?: string;
    public deliveryCity?: string;
    public receiverTel?: string;
    public pdfStats?: BOOKORDER_PDFSTATS;
    public pdfStatsStr?: string;
    public lastPdfMakeTime?: string;
    public orderDesc?: string;
    public num?: number;
    public payment?: string;
    public totalFee?: string;
    public bookEdition?: BOOKORDER_EDITION;
    public bookEditionStr?: string;
    public bookUrl?: string;
    public bookExtraConfigId?: string;
    public bookCoverName?: string;
    public bookFrontCoverUrl?: string;
    public bookBackCoverUrl?: string;

    public constructor(init?: Partial<BookOrderItemDTO>) { (Object as any).assign(this, init); }
}

export class BookPdfInfo
{
    public fileurl?: string;
    public expireAt?: number;
    public tinyurl?: string;

    public constructor(init?: Partial<BookPdfInfo>) { (Object as any).assign(this, init); }
}

export class BookOrderConfigItemDTO
{
    public bookEdition?: BOOKORDER_EDITION;
    public bookEditionStr?: string;
    public bookEditionTitle?: string;
    public price?: string;
    public pageNumber?: number;
    public editionMainBackgroundUrl?: string;
    public editionDetailImageUrl?: string;
    public maxNum?: number;

    public constructor(init?: Partial<BookOrderConfigItemDTO>) { (Object as any).assign(this, init); }
}

export class ShengPayPreOrderDTO
{
    public schoolName?: string;
    public prepayData?: string;
    public productName?: string;
    public orderNum?: string;
    public price?: number;
    public paymentMethods?: string[];
    public defaultPaymentMethods?: string;

    public constructor(init?: Partial<ShengPayPreOrderDTO>) { (Object as any).assign(this, init); }
}

export class PrePayWechatData
{
    public appId?: string;
    public partnerId?: string;
    public prepayId?: string;
    public package?: string;
    public nonceStr?: string;
    public timestamp?: number;
    public sign?: string;
    public code_url?: string;
    public out_trade_no?: string;

    public constructor(init?: Partial<PrePayWechatData>) { (Object as any).assign(this, init); }
}

export class WatchInfoDTO
{
    public id?: string;
    public watchModal?: string;
    public macAddress?: string;
    public icNumber?: string;
    public schoolId?: string;
    public studentId?: string;
    public watchState?: WATCH_DEVICE_STATE;
    public lastDataRecordOn?: string;
    public steps?: number;
    public distances?: number;
    public calories?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public lastBindOn?: string;
    public lastUnBindOn?: string;
    public scrappedOn?: string;

    public constructor(init?: Partial<WatchInfoDTO>) { (Object as any).assign(this, init); }
}

export class WatchReportDataItem
{
    public dateOn?: string;
    public steps?: number;
    public distances?: number;
    public calories?: number;

    public constructor(init?: Partial<WatchReportDataItem>) { (Object as any).assign(this, init); }
}

export class WechatComponentTemplateItem
{
    public templateName?: string;
    public isEnabled?: boolean;

    public constructor(init?: Partial<WechatComponentTemplateItem>) { (Object as any).assign(this, init); }
}

export class GetKidGrowInfoItem
{
    public month?: number;
    public dataValue?: number;

    public constructor(init?: Partial<GetKidGrowInfoItem>) { (Object as any).assign(this, init); }
}

export class AttendanceHeartbeatDataDTO
{
    public appVersion?: string;
    public teacherDataCount?: number;
    public parentDataCount?: number;
    public studentDataCount?: number;
    public offlineDataCount?: number;
    public aiFaceCount?: number;
    public lastTeacherDataSyncOn?: string;
    public lastParentDataSyncOn?: string;
    public lastStudentDataSyncOn?: string;
    public lastOfflineDataSyncOn?: string;
    public lastHeartbeatOn?: string;
    public ftInitState?: number;
    public ftLastErr?: string;
    public timeDiff?: number;

    public constructor(init?: Partial<AttendanceHeartbeatDataDTO>) { (Object as any).assign(this, init); }
}

export class AttendanceDeviceDTO
{
    public id?: string;
    public branchId?: string;
    public serialNo?: string;
    public deviceType?: AttendanceDevice_Type;
    public attendanceDoorId?: string;
    public direction?: AttendanceDoor_Direction;
    public macAddr?: string;
    public registOn?: string;
    public schoolId?: string;
    public images?: string[];
    public notices?: string[];
    public remark?: string;
    public arcfaceLicense?: string;
    public audioTemplates?: { [index: string]: string; };
    public healthyTimeout?: number;
    public healthyTimeoutSubmit?: boolean;
    public lastHeartbeatData?: AttendanceHeartbeatDataDTO;

    public constructor(init?: Partial<AttendanceDeviceDTO>) { (Object as any).assign(this, init); }
}

export class AttendanceDoorControllerDTO
{
    public id?: string;
    public deviceNo?: string;
    public schoolId?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<AttendanceDoorControllerDTO>) { (Object as any).assign(this, init); }
}

export class AttendanceDoorDTO
{
    public id?: string;
    public schoolId?: string;
    public attendanceDoorControllerId?: string;
    public doorIndex?: number;
    public name?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<AttendanceDoorDTO>) { (Object as any).assign(this, init); }
}

export class BookExtraConfigDTO
{
    public id?: string;
    public createOn?: string;
    public updatedOn?: string;
    public lastModifyOn?: string;
    public deleteOn?: string;
    public name?: string;
    public desc?: string;
    public frontCoverUrl?: string;
    public backCoverUrl?: string;
    public isDelete?: boolean;
    public items?: BookExtraConfigItemDTO[];

    public constructor(init?: Partial<BookExtraConfigDTO>) { (Object as any).assign(this, init); }
}

export class GrowBookConfigDTO
{
    public id?: string;
    public growBookFrontPageTemplateId?: string;
    public semesterType?: SEMESTERTYPE;
    public lastModifyOn?: string;
    public createOn?: string;

    public constructor(init?: Partial<GrowBookConfigDTO>) { (Object as any).assign(this, init); }
}

export class AirIotDataHistoryDTO
{
    public id?: string;
    public mac?: string;
    public battery?: number;
    public humidity?: number;
    public pressure?: number;
    public temperature?: number;
    public tvoc?: number;
    public co2?: number;
    public pm25?: number;
    public pm100?: number;
    public timestamp?: number;
    public createdOn?: string;
    public reportedOn?: string;

    public constructor(init?: Partial<AirIotDataHistoryDTO>) { (Object as any).assign(this, init); }
}

export class AirIotDeviceDTO
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public isShareDevice?: boolean;
    public name?: string;
    public mac?: string;
    public groupId?: string;
    public groupName?: string;
    public version?: string;
    public productId?: string;
    public productName?: string;
    public productEName?: string;
    public reportInterval?: number;
    public collectInterval?: number;
    public isDelete?: boolean;
    public deviceCreatedOn?: string;
    public createdOn?: string;
    public lastModifyOn?: string;
    public deletedOn?: string;
    public lastData?: AirIotDataHistoryDTO;

    public constructor(init?: Partial<AirIotDeviceDTO>) { (Object as any).assign(this, init); }
}

export class AirIotDeviceMapDTO
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public classId?: string;
    public airIotDeviceId?: string;
    public createdOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<AirIotDeviceMapDTO>) { (Object as any).assign(this, init); }
}

export class AirIotDataSummaryItem
{
    public isOutSide?: boolean;
    public classId?: string;
    public airInfo?: string;

    public constructor(init?: Partial<AirIotDataSummaryItem>) { (Object as any).assign(this, init); }
}

export class AirIotDataItem
{
    public classId?: string;
    public className?: string;
    public humidity?: number;
    public pressure?: number;
    public temperature?: number;
    public tvoc?: number;
    public co2?: number;
    public pm25?: number;
    public pm100?: number;

    public constructor(init?: Partial<AirIotDataItem>) { (Object as any).assign(this, init); }
}

export class LiveNodeDTO
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public name?: string;
    public ipAddr?: string;
    public isActive?: boolean;
    public createOn?: string;
    public lastStartUpOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public nodeStatus?: string;
    public lastNodeHeartBeatTime?: string;
    public lastNodeCheckOn?: string;
    public nodeType?: LIVE_NODE_TYPE;
    public liveNodeGBCloudProviderId?: string;
    public sipClientId?: string;
    public sipClientPassword?: string;

    public constructor(init?: Partial<LiveNodeDTO>) { (Object as any).assign(this, init); }
}

export class LiveChannelDTO
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public name?: string;
    public sourceMediaAddr?: string;
    public targetMediaAddr?: string;
    public pullMediaAddr?: string;
    public liveNodeId?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public isRunning?: boolean;
    public isStreaming?: boolean;
    public lastStartOn?: string;
    public lastStopOn?: string;
    public lastStreamingStart?: string;
    public lastStreamingStop?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public dvrType?: DVR_DEVICE_TYPE;
    public channelNumber?: number;
    public vCodec?: string;
    public dvrIPAddress?: string;
    public dvrManagePort?: number;
    public dvrUserName?: string;
    public dvrPassword?: string;
    public remark?: string;
    public gbChannelId?: string;

    public constructor(init?: Partial<LiveChannelDTO>) { (Object as any).assign(this, init); }
}

export class LiveScheduleDTO
{
    public id?: string;
    public name?: string;
    public schoolId?: string;
    public openBySchoolCalendar?: boolean;
    public items?: LiveScheduleItemDTO[];
    public createOn?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;

    public constructor(init?: Partial<LiveScheduleDTO>) { (Object as any).assign(this, init); }
}

export class LiveScheduleClassConfigDTO
{
    public classId?: string;
    public liveScheduleId?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<LiveScheduleClassConfigDTO>) { (Object as any).assign(this, init); }
}

export class LiveScheduleConfigDTO
{
    public id?: string;
    public liveScheduleId?: string;
    public classConfigs?: LiveScheduleClassConfigDTO[];
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<LiveScheduleConfigDTO>) { (Object as any).assign(this, init); }
}

export class PermissionItem
{
    public id?: string;
    public permissionCode?: string;
    public eName?: string;
    public name?: string;
    public path?: string;
    public parentId?: string;
    public isEnabled?: boolean;
    public isMenu?: boolean;
    public showInMenu?: boolean;
    public menuPath?: string;
    public menuIcon?: string;
    public menuExact?: boolean;
    public usageArea?: PERMISSION_USEAGE_AREA;
    public moduleCode?: string;
    public showInAppMenu?: boolean;
    public appH5Url?: string;
    public appH5FullScreen?: boolean;
    public appRoute?: string;
    public appMinVersion?: string;
    public appIconUrl?: string;
    public showInAppArea?: string[];
    public appMPEnabled?: boolean;
    public appMPAppId?: string;
    public appMPPath?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<PermissionItem>) { (Object as any).assign(this, init); }
}

export class MenuDataItem
{
    public path?: string;
    public name?: string;
    public eName?: string;
    public icon?: string;
    public exact?: boolean;
    public hideInMenu?: boolean;
    public hideChildrenInMenu?: boolean;
    public children?: MenuDataItem[];

    public constructor(init?: Partial<MenuDataItem>) { (Object as any).assign(this, init); }
}

export class SystemUserDTO
{
    public userId?: number;
    public userName?: string;
    public displayName?: string;
    public createDate?: string;
    public roles?: string[];

    public constructor(init?: Partial<SystemUserDTO>) { (Object as any).assign(this, init); }
}

export class SystemServiceModuleConfigDTO
{
    public id?: string;
    public moduleCode?: string;
    public moduleName?: string;
    public description?: string;

    public constructor(init?: Partial<SystemServiceModuleConfigDTO>) { (Object as any).assign(this, init); }
}

export class VersionInfoDTO
{
    public id?: string;
    public appId?: number;
    public versionName?: string;
    public versionCode?: string;
    public releaseNote?: string;
    public createOn?: string;
    public isDefault?: boolean;
    public os?: OSTYPE;
    public isApk?: boolean;
    public downloadUrl?: string;
    public md5Hash?: string;
    public packageSize?: string;

    public constructor(init?: Partial<VersionInfoDTO>) { (Object as any).assign(this, init); }
}

export class EventMsgCardDTO
{
    public id?: string;
    public classId?: string;
    public schoolId?: string;
    public buttonText?: string;
    public title?: string;
    public content?: string;
    public imageUrl?: string;
    public linkUrl?: string;
    public author?: string;
    public logoUrl?: string;
    public extraContent?: string;
    public hideOn?: string;

    public constructor(init?: Partial<EventMsgCardDTO>) { (Object as any).assign(this, init); }
}

export class TeacherPointTaskConfigItem
{
    public id?: string;
    public name?: string;
    public desc?: string;
    public linkUrl?: string;
    public iconUrl?: string;
    public code?: string;
    public taskPeriod?: POINT_TASK_PERIOD;
    public maxNumber?: number;
    public pointAmount?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public isEnabled?: boolean;
    public orderIndex?: number;

    public constructor(init?: Partial<TeacherPointTaskConfigItem>) { (Object as any).assign(this, init); }
}

export class TeacherPointItem
{
    public id?: string;
    public schoolId?: string;
    public userId?: number;
    public totalPoint?: number;
    public avaliablePoint?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public isLocked?: boolean;

    public constructor(init?: Partial<TeacherPointItem>) { (Object as any).assign(this, init); }
}

export class TeacherPointLogItem
{
    public id?: string;
    public schoolId?: string;
    public teacherUserInfoId?: string;
    public taskId?: string;
    public userId?: number;
    public source?: string;
    public point?: number;
    public balancePoint?: number;
    public createOn?: string;
    public userIPAddr?: string;

    public constructor(init?: Partial<TeacherPointLogItem>) { (Object as any).assign(this, init); }
}

export class TeacherPointSummaryDTO
{
    public reportDate?: string;
    public teacherUserInfoIds?: string[];
    public addAmount?: number;
    public useAmount?: number;

    public constructor(init?: Partial<TeacherPointSummaryDTO>) { (Object as any).assign(this, init); }
}

export class TeacherPointTaskSummaryDTO
{
    public reportDate?: string;
    public taskId?: string;
    public teacherUserInfoIds?: string[];
    public amount?: number;

    public constructor(init?: Partial<TeacherPointTaskSummaryDTO>) { (Object as any).assign(this, init); }
}

export class TeacherPointDTO
{
    public id?: string;
    public schoolId?: string;
    public userId?: number;
    public totalPoint?: number;
    public avaliablePoint?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public isLocked?: boolean;

    public constructor(init?: Partial<TeacherPointDTO>) { (Object as any).assign(this, init); }
}

export class TeacherPointLogDTO
{
    public id?: string;
    public schoolId?: string;
    public teacherUserInfoId?: string;
    public taskId?: string;
    public sourceType?: POINT_SOURCE_TYPE;
    public source?: string;
    public teacherPointOrderId?: string;
    public point?: number;
    public balancePoint?: number;
    public createOn?: string;
    public userIPAddr?: string;

    public constructor(init?: Partial<TeacherPointLogDTO>) { (Object as any).assign(this, init); }
}

export class TeacherPointOrderDTO
{
    public id?: string;
    public schoolId?: string;
    public teacherUserInfoId?: string;
    public shopType?: POINT_SHOP_TYPE;
    public orderState?: POINT_USE_ORDER_STATE;
    public pointAmount?: number;
    public body?: string;
    public subject?: string;
    public ipAddress?: string;
    public outOrderId?: string;
    public errMsg?: string;
    public createOn?: string;
    public notifyOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<TeacherPointOrderDTO>) { (Object as any).assign(this, init); }
}

export enum REDDOT_TYPE
{
    REDDOT_NUMBER = 'REDDOT_NUMBER',
    REDDOT_DOT = 'REDDOT_DOT',
    REDDOT_TEXT = 'REDDOT_TEXT',
}

export class RedDotItem
{
    public redDotPath?: string;
    public redDotType?: REDDOT_TYPE;
    public redDotCount?: number;
    public redDotText?: string;

    public constructor(init?: Partial<RedDotItem>) { (Object as any).assign(this, init); }
}

export class KidMemberLogDTO
{
    public orderId?: string;
    public createOn?: string;
    public memberBeginOn?: string;
    public memberEndOn?: string;

    public constructor(init?: Partial<KidMemberLogDTO>) { (Object as any).assign(this, init); }
}

export class KidMemberDTO
{
    public id?: string;
    public schoolId?: string;
    public createOn?: string;
    public lastPaymentOn?: string;
    public expireOn?: string;
    public isTrial?: boolean;
    public memberLogs?: KidMemberLogDTO[];

    public constructor(init?: Partial<KidMemberDTO>) { (Object as any).assign(this, init); }
}

export enum INBOXLIKE_ATTACH_TYPE
{
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}

export class InBoxLikeAttach
{
    public attachType?: INBOXLIKE_ATTACH_TYPE;
    public resource?: string;

    public constructor(init?: Partial<InBoxLikeAttach>) { (Object as any).assign(this, init); }
}

export class InBoxEventLikeDTO
{
    public id?: string;
    public appType?: APP_TYPE;
    public senderIsTeacher?: boolean;
    public senderId?: string;
    public recvId?: string;
    public eventId?: string;
    public eventType?: string;
    public eventTitle?: string;
    public attachment?: InBoxLikeAttach;
    public isLike?: boolean;
    public content?: string;
    public isRead?: boolean;
    public createdOn?: string;
    public lastModifyOn?: string;
    public senderLogoUrl?: string;
    public senderName?: string;

    public constructor(init?: Partial<InBoxEventLikeDTO>) { (Object as any).assign(this, init); }
}

export enum MYTASK_TYPE
{
    LEAVEREQUEST = 'LEAVEREQUEST',
    MEDICARE = 'MEDICARE',
    COMMISSIONPICKUP = 'COMMISSIONPICKUP',
}

export enum MYTASK_STATUS
{
    MY_CREATED = 'MY_CREATED',
    MY_ACK = 'MY_ACK',
    FINISHED = 'FINISHED',
}

export class MyTaskItem
{
    public id?: string;
    public taskType?: MYTASK_TYPE;
    public taskStatus?: MYTASK_STATUS;
    public taskData?: string;

    public constructor(init?: Partial<MyTaskItem>) { (Object as any).assign(this, init); }
}

export class SystemMsgItem
{
    public id?: string;
    public title?: string;
    public contnet?: string;
    public imageUrl?: string;
    public messageUrl?: string;
    public createdOn?: string;

    public constructor(init?: Partial<SystemMsgItem>) { (Object as any).assign(this, init); }
}

export class DatavCatalogItem
{
    public categoryKey?: string;
    public categoryValue?: string;
    public id?: string;
    public isDeleted?: number;

    public constructor(init?: Partial<DatavCatalogItem>) { (Object as any).assign(this, init); }
}

export class DatavCatalogPage
{
    public current?: number;
    public hitCount?: boolean;
    public pages?: number;
    public searchCount?: boolean;
    public size?: number;
    public total?: number;
    public records?: DatavCatalogItem[];

    public constructor(init?: Partial<DatavCatalogPage>) { (Object as any).assign(this, init); }
}

export class DataVVisualDetail
{
    public visual?: DataVVisual;
    public config?: DataVConfig;

    public constructor(init?: Partial<DataVVisualDetail>) { (Object as any).assign(this, init); }
}

export class DataVVisualFile
{
    public attachId?: number;
    public domain?: string;
    public link?: string;
    public name?: string;
    public originalName?: string;

    public constructor(init?: Partial<DataVVisualFile>) { (Object as any).assign(this, init); }
}

export class DatavVisualItem
{
    public backgroundUrl?: string;
    public category?: string;
    public createTime?: string;
    public id?: string;
    public isDeleted?: number;
    public password?: string;
    public status?: number;
    public title?: string;
    public updateTime?: string;

    public constructor(init?: Partial<DatavVisualItem>) { (Object as any).assign(this, init); }
}

export class DatavVisualPage
{
    public current?: number;
    public hitCount?: boolean;
    public pages?: number;
    public searchCount?: boolean;
    public size?: number;
    public total?: number;
    public records?: DatavVisualItem[];

    public constructor(init?: Partial<DatavVisualPage>) { (Object as any).assign(this, init); }
}

export class DataVMapItem
{
    public id?: string;
    public name?: string;
    public data?: string;

    public constructor(init?: Partial<DataVMapItem>) { (Object as any).assign(this, init); }
}

export class DataVMapPage
{
    public current?: number;
    public hitCount?: boolean;
    public pages?: number;
    public searchCount?: boolean;
    public size?: number;
    public total?: number;
    public records?: DataVMapItem[];

    public constructor(init?: Partial<DataVMapPage>) { (Object as any).assign(this, init); }
}

export class ActivityProdectBaseDTO
{
    public id?: string;
    public marketingPageId?: string;
    public name?: string;
    public desc?: string;
    public images?: string[];
    public productNumber?: number;
    public originalPrice?: number;
    public orderCount?: number;
    public availableCount?: number;
    public qiangGouPrice?: number;
    public needZhuliCount?: number;
    public memberCount?: number;
    public leaderPrice?: number;
    public memberPrice?: number;

    public constructor(init?: Partial<ActivityProdectBaseDTO>) { (Object as any).assign(this, init); }
}

export class MarketingFeedbackDTO
{
    public id?: string;
    public schoolId?: string;
    public marketingPageId?: string;
    public weChatUserId?: string;
    public studentName?: string;
    public studentBirth?: string;
    public phoneNumber?: string;
    public gender?: string;
    public parentRole?: string;
    public parentName?: string;
    public contactAddress?: string;
    public xiaoquName?: string;
    public schoolName?: string;
    public isMember?: string;
    public extraDatas?: MarketingFeedExtraItem[];
    public wxOpenId?: string;
    public wxUnionId?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public needPayment?: boolean;
    public fee?: number;
    public isPaid?: boolean;
    public userIPAddr?: string;
    public orderInfoId?: string;

    public constructor(init?: Partial<MarketingFeedbackDTO>) { (Object as any).assign(this, init); }
}

export class ActivityPinTuanConfigDTO
{
    public id?: string;
    public schoolId?: string;
    public activityType?: MARKETING_ACTIVITY_TYPE;
    public name?: string;
    public desc?: string;
    public images?: string[];
    public productNumber?: number;
    public orderCount?: number;
    public availableCount?: number;
    public timeout?: number;
    public memberCount?: number;
    public originalPrice?: number;
    public leaderPrice?: number;
    public memberPrice?: number;
    public enableCouTuan?: boolean;
    public enableVirtualTuan?: boolean;
    public virtualTuanHours?: number;
    public virtualTuanMembers?: number;
    public playLimit?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public beginTime?: string;
    public endTime?: string;

    public constructor(init?: Partial<ActivityPinTuanConfigDTO>) { (Object as any).assign(this, init); }
}

export enum PINTUAN_MEMBER_STATUS
{
    WAIT_PAY = 'WAIT_PAY',
    PAID = 'PAID',
    FINISH = 'FINISH',
    REFUND = 'REFUND',
}

export class ActivityPinTuanOrderDTO
{
    public id?: string;
    public schoolId?: string;
    public activityType?: MARKETING_ACTIVITY_TYPE;
    public configId?: string;
    public productId?: string;
    public weChatUserId?: string;
    public createOn?: string;
    public expireOn?: string;
    public finishOn?: string;
    public lastModifyOn?: string;
    public groupStatus?: MARKETING_ACTIVITY_GROUP_STATUS;
    public orderId?: string;
    public price?: number;
    public pinTuanGroupId?: string;
    public isLeader?: boolean;
    public memberStatus?: PINTUAN_MEMBER_STATUS;

    public constructor(init?: Partial<ActivityPinTuanOrderDTO>) { (Object as any).assign(this, init); }
}

export class VirtualPinTuanUser
{
    public name?: string;
    public avatarUrl?: string;

    public constructor(init?: Partial<VirtualPinTuanUser>) { (Object as any).assign(this, init); }
}

export class ActivityPinTuanGroupDTO
{
    public id?: string;
    public schoolId?: string;
    public pinTuanConfigId?: string;
    public leaderWeChatUserId?: string;
    public memberWeChatUserIds?: string[];
    public needMemberAmount?: number;
    public currentMemberAmount?: number;
    public availableMemberAmount?: number;
    public createOn?: string;
    public expireOn?: string;
    public finishOn?: string;
    public lastModifyOn?: string;
    public groupStatus?: MARKETING_ACTIVITY_GROUP_STATUS;
    public isVirtualGroup?: boolean;
    public virtualMembers?: VirtualPinTuanUser[];

    public constructor(init?: Partial<ActivityPinTuanGroupDTO>) { (Object as any).assign(this, init); }
}

export class ActivityOrderBaseDTO
{
    public id?: string;
    public schoolId?: string;
    public activityType?: MARKETING_ACTIVITY_TYPE;
    public configId?: string;
    public productId?: string;
    public weChatUserId?: string;
    public createOn?: string;
    public expireOn?: string;
    public finishOn?: string;
    public lastModifyOn?: string;
    public groupStatus?: MARKETING_ACTIVITY_GROUP_STATUS;
    public orderId?: string;
    public price?: number;
    public spreaderId?: string;
    public needMemberAmount?: number;
    public zhuliWeChatUserIds?: string[];
    public pinTuanGroupId?: string;
    public isLeader?: boolean;
    public memberStatus?: PINTUAN_MEMBER_STATUS;

    public constructor(init?: Partial<ActivityOrderBaseDTO>) { (Object as any).assign(this, init); }
}

export class ActivityQiangGouProductDTO
{
    public id?: string;
    public uuid?: string;
    public name?: string;
    public desc?: string;
    public images?: string[];
    public productNumber?: number;
    public originalPrice?: number;
    public qiangGouPrice?: number;
    public needZhuliCount?: number;
    public orderCount?: number;
    public availableCount?: number;

    public constructor(init?: Partial<ActivityQiangGouProductDTO>) { (Object as any).assign(this, init); }
}

export class ActivityQiangGouConfigDTO
{
    public id?: string;
    public schoolId?: string;
    public activityType?: MARKETING_ACTIVITY_TYPE;
    public limitQiangGouCount?: number;
    public enableLimitZhuli?: boolean;
    public limitZhuliCount?: number;
    public products?: ActivityQiangGouProductDTO[];
    public createOn?: string;
    public lastModifyOn?: string;
    public beginTime?: string;
    public endTime?: string;

    public constructor(init?: Partial<ActivityQiangGouConfigDTO>) { (Object as any).assign(this, init); }
}

export class SpreaderUserInfoDTO
{
    public spreaderId?: string;
    public nickName?: string;
    public logoUrl?: string;

    public constructor(init?: Partial<SpreaderUserInfoDTO>) { (Object as any).assign(this, init); }
}

export class GraphCategory
{
    public name?: string;

    public constructor(init?: Partial<GraphCategory>) { (Object as any).assign(this, init); }
}

export class GraphLink
{
    public source?: string;
    public target?: string;
    public category?: number;

    public constructor(init?: Partial<GraphLink>) { (Object as any).assign(this, init); }
}

export class GraphNode
{
    public id?: string;
    public name?: string;
    public symbol?: string;
    public symbolSize?: number;
    public value?: number;
    public category?: number;
    public type?: string;

    public constructor(init?: Partial<GraphNode>) { (Object as any).assign(this, init); }
}

export enum SPREADER_PRIVATE_GIFT_SOURCE_TYPE
{
    ACTIVITY_ORDER = 'ACTIVITY_ORDER',
    COURSE_ORDER = 'COURSE_ORDER',
    ROLLBACK = 'ROLLBACK',
}

export class PrivateGiftCardDTO
{
    public id?: string;
    public schoolId?: string;
    public entityId?: string;
    public fee?: number;
    public expiredDate?: string;
    public minPrice?: number;
    public spreaderId?: string;
    public sourceType?: SPREADER_PRIVATE_GIFT_SOURCE_TYPE;
    public isUsed?: boolean;
    public usedOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;
    public createOn?: string;
    public lastModifyOn?: string;

    public constructor(init?: Partial<PrivateGiftCardDTO>) { (Object as any).assign(this, init); }
}

export enum SPREADER_LOG_STATE
{
    INIT = 'INIT',
    WAIT_CONFIRM = 'WAIT_CONFIRM',
    CONFIRM_SUCCESS = 'CONFIRM_SUCCESS',
    CONFIRM_FAIL = 'CONFIRM_FAIL',
    REFUND = 'REFUND',
}

export class PrivateGiftPointLogDTO
{
    public id?: string;
    public schoolId?: string;
    public spreaderId?: string;
    public privateGiftPointId?: string;
    public sourceType?: SPREADER_PRIVATE_GIFT_SOURCE_TYPE;
    public source?: string;
    public point?: number;
    public balancePoint?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public userIPAddr?: string;
    public state?: SPREADER_LOG_STATE;

    public constructor(init?: Partial<PrivateGiftPointLogDTO>) { (Object as any).assign(this, init); }
}

export class YouzanBookGoodConfigDTO
{
    public id?: string;
    public name?: string;
    public youzanAlias?: string;
    public youzanItemId?: string;
    public youzanMiniProgramePath?: string;
    public pageMin?: number;
    public pageMax?: number;
    public price?: number;
    public createOn?: string;
    public productImageUrl?: string;
    public bookType?: BOOKORDER_TYPE;
    public bookEdition?: BOOKORDER_EDITION;
    public editionMainBackgroundUrl?: string;
    public editionDetailImageUrl?: string;
    public maxNum?: number;
    public bookEditionTitle?: string;

    public constructor(init?: Partial<YouzanBookGoodConfigDTO>) { (Object as any).assign(this, init); }
}

export class BookOrderKidInfo
{
    public kidId?: string;
    public orderCount?: number;
    public bookNum?: number;
    public createOn?: string;

    public constructor(init?: Partial<BookOrderKidInfo>) { (Object as any).assign(this, init); }
}

export class SftContractItem
{
    public merchantId?: string;
    public electronicContractNo?: string;
    public signUrl?: string;
    public status?: string;

    public constructor(init?: Partial<SftContractItem>) { (Object as any).assign(this, init); }
}

export class OrderIAPCodeDTO
{
    public id?: string;
    public name?: string;
    public code?: string;
    public isDelete?: boolean;
    public createOn?: string;
    public deleteOn?: string;

    public constructor(init?: Partial<OrderIAPCodeDTO>) { (Object as any).assign(this, init); }
}

export class LiveMemberLogDTO
{
    public orderId?: string;
    public createOn?: string;
    public memberBeginOn?: string;
    public memberEndOn?: string;

    public constructor(init?: Partial<LiveMemberLogDTO>) { (Object as any).assign(this, init); }
}

export class LiveMemberDetailDTO
{
    public id?: string;
    public schoolId?: string;
    public createOn?: string;
    public expireOn?: string;
    public isTrial?: boolean;
    public lastPaymentOn?: string;
    public memberLogs?: LiveMemberLogDTO[];

    public constructor(init?: Partial<LiveMemberDetailDTO>) { (Object as any).assign(this, init); }
}

export class KidInfoAccountBalanceHistoryDTO
{
    public id?: string;
    public schoolId?: string;
    public kidId?: string;
    public teacherId?: string;
    public refId?: string;
    public subject?: string;
    public amount?: number;
    public useBalanceAmount?: number;
    public useBalanceGiftAmount?: number;
    public balanceAmount?: number;
    public balanceGiftAmount?: number;
    public bizType?: KIDACCOUNT_BALANCE_BIZTYPE;
    public createOn?: string;
    public userIPAddr?: string;

    public constructor(init?: Partial<KidInfoAccountBalanceHistoryDTO>) { (Object as any).assign(this, init); }
}

export class SchoolOrderProductDTO
{
    public id?: string;
    public catalog?: SCHOOLORDERPRODUCT_CATALOG;
    public amount?: number;
    public productName?: string;
    public price?: number;
    public createOn?: string;
    public lastModifyOn?: string;
    public isDelete?: boolean;
    public deleteOn?: string;

    public constructor(init?: Partial<SchoolOrderProductDTO>) { (Object as any).assign(this, init); }
}

export class SchoolOrderInfoDTO
{
    public id?: string;
    public productId?: string;
    public orderNo?: string;
    public schoolId?: string;
    public price?: number;
    public amount?: number;
    public productName?: string;
    public createOn?: string;
    public lastModifyOn?: string;
    public paidOn?: string;
    public userIPAddr?: string;
    public userId?: number;
    public teacherUserInfoId?: string;
    public catalog?: SCHOOLORDERPRODUCT_CATALOG;
    public paymentType?: ORDERPAYMENT_TYPE;
    public orderStatus?: ORDERSTAUS;
    public transactionId?: string;
    public transactionResult?: string;

    public constructor(init?: Partial<SchoolOrderInfoDTO>) { (Object as any).assign(this, init); }
}

export enum ENTITY_TYPE
{
    NONE = 'NONE',
    TEACHER = 'TEACHER',
    PARENT = 'PARENT',
}

export class SmsLogDTO
{
    public id?: string;
    public phoneNumber?: string;
    public content?: string;
    public vendor?: string;
    public sendCode?: number;
    public sendMsg?: string;
    public sendCount?: number;
    public fee?: number;
    public unit?: string;
    public createOn?: string;
    public sendStatus?: SMS_SEND_STATUS;
    public vendorErrMsg?: string;
    public vendorErrDetail?: string;
    public vendorSid?: string;
    public vendorUid?: string;
    public userReceiveTime?: string;
    public reportOn?: string;
    public lastModifyOn?: string;
    public schoolId?: string;
    public entityType?: ENTITY_TYPE;
    public entityId?: string;

    public constructor(init?: Partial<SmsLogDTO>) { (Object as any).assign(this, init); }
}

export class GetAlbumEventInfoData
{
    public eventTitle?: string;
    public eventContent?: string;
    public fileUrl?: string;
    public kidName?: string;
    public createOn?: number;
    public thumbnailUrl?: string;

    public constructor(init?: Partial<GetAlbumEventInfoData>) { (Object as any).assign(this, init); }
}

export class PageInfo
{
    public location?: number;
    public number?: number;
    public text?: string;

    public constructor(init?: Partial<PageInfo>) { (Object as any).assign(this, init); }
}

export class PropertyBackground
{
    public color?: string;
    public imageUrl?: string;

    public constructor(init?: Partial<PropertyBackground>) { (Object as any).assign(this, init); }
}

export class PageProperty
{
    public height?: number;
    public type?: string;
    public background?: PropertyBackground;

    public constructor(init?: Partial<PageProperty>) { (Object as any).assign(this, init); }
}

export class PageBase
{
    public id?: number;
    public pagination?: PageInfo;
    public property?: PageProperty;

    public constructor(init?: Partial<PageBase>) { (Object as any).assign(this, init); }
}

export class AlbumProfile
{
    public title?: string;
    public type?: string;
    public avatar?: string;
    public studentID?: string;
    public name?: string;
    public grade?: string;
    public cover?: string;
    public backcover?: string;
    public orderID?: string;
    public orderNumber?: number;
    public orderCreatedAt?: string;
    public orderSkuCode?: string;
    public orderTypeName?: string;
    public orderQRCode?: string;
    public authors?: string[];
    public timeInterval?: string;

    public constructor(init?: Partial<AlbumProfile>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyPaginationDistance
{
    public sideDistance?: number;
    public bottomDistance?: number;
    public intervalDistance?: number;

    public constructor(init?: Partial<AlbumPropertyPaginationDistance>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyPaginationNumber
{
    public fontSize?: number;
    public fontHeight?: number;

    public constructor(init?: Partial<AlbumPropertyPaginationNumber>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyPaginationText
{
    public fontSize?: number;
    public fontHeight?: number;

    public constructor(init?: Partial<AlbumPropertyPaginationText>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyPaginationLine
{
    public width?: number;
    public height?: number;

    public constructor(init?: Partial<AlbumPropertyPaginationLine>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyPagination
{
    public distance?: AlbumPropertyPaginationDistance;
    public number?: AlbumPropertyPaginationNumber;
    public text?: AlbumPropertyPaginationText;
    public line?: AlbumPropertyPaginationLine;

    public constructor(init?: Partial<AlbumPropertyPagination>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyPageSize
{
    public pageHeight?: number;
    public pageWidth?: number;
    public feedPageHeight?: number;
    public feedPageWidth?: number;
    public subjectPageWidth?: number;

    public constructor(init?: Partial<AlbumPropertyPageSize>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyDividingLine
{
    public x?: number;
    public width?: number;
    public height?: number;
    public color?: string;

    public constructor(init?: Partial<AlbumPropertyDividingLine>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyFontConfig
{
    public fontName?: string;
    public fontSize?: number;

    public constructor(init?: Partial<AlbumPropertyFontConfig>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyFontsFeedFonts
{
    public markFont?: AlbumPropertyFontConfig;
    public titleFont?: AlbumPropertyFontConfig;
    public quoteFont?: AlbumPropertyFontConfig;
    public tagFont?: AlbumPropertyFontConfig;
    public contentFont?: AlbumPropertyFontConfig;

    public constructor(init?: Partial<AlbumPropertyFontsFeedFonts>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyFontsSubjectFonts
{
    public titleFont?: AlbumPropertyFontConfig;
    public contentFont?: AlbumPropertyFontConfig;

    public constructor(init?: Partial<AlbumPropertyFontsSubjectFonts>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyFontsDirectoryFonts
{
    public headEntryFont?: AlbumPropertyFontConfig;
    public subEntryFont?: AlbumPropertyFontConfig;

    public constructor(init?: Partial<AlbumPropertyFontsDirectoryFonts>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyFontsWishFonts
{
    public contentFont?: AlbumPropertyFontConfig;
    public signatureFont?: AlbumPropertyFontConfig;

    public constructor(init?: Partial<AlbumPropertyFontsWishFonts>) { (Object as any).assign(this, init); }
}

export class AlbumPropertyFonts
{
    public feedFonts?: AlbumPropertyFontsFeedFonts;
    public subjectFonts?: AlbumPropertyFontsSubjectFonts;
    public directoryFonts?: AlbumPropertyFontsDirectoryFonts;
    public wishFonts?: AlbumPropertyFontsWishFonts;
    public contentFont?: AlbumPropertyFontConfig;

    public constructor(init?: Partial<AlbumPropertyFonts>) { (Object as any).assign(this, init); }
}

export class AlbumProperty
{
    public pagination?: AlbumPropertyPagination;
    public pageSize?: AlbumPropertyPageSize;
    public dividingLine?: AlbumPropertyDividingLine;
    public fonts?: AlbumPropertyFonts;

    public constructor(init?: Partial<AlbumProperty>) { (Object as any).assign(this, init); }
}

export class AlbumData
{
    public id?: string;
    public pageNumber?: number;
    public paginatedNumber?: number;
    public createdAt?: string;
    public pages?: PageBase[];
    public profile?: AlbumProfile;
    public property?: AlbumProperty;

    public constructor(init?: Partial<AlbumData>) { (Object as any).assign(this, init); }
}

export class KidAlbumInfoTeacherActivity
{
    public totalcount?: number;
    public unfeedcount?: number;
    public feedcount?: number;

    public constructor(init?: Partial<KidAlbumInfoTeacherActivity>) { (Object as any).assign(this, init); }
}

export class KidAlbumInfoOrder
{
    public orderId?: string;

    public constructor(init?: Partial<KidAlbumInfoOrder>) { (Object as any).assign(this, init); }
}

export class KidAlbumUrl
{
    public title?: string;
    public url?: string;

    public constructor(init?: Partial<KidAlbumUrl>) { (Object as any).assign(this, init); }
}

export enum PURCHASESTATE
{
    NOTIN_PURCHASE_DATE = 'NOTIN_PURCHASE_DATE',
    IN_PURCHASE_DATE = 'IN_PURCHASE_DATE',
}

export class KidAlbumAlbum
{
    public purchaseStage?: PURCHASESTATE;
    public price?: number;

    public constructor(init?: Partial<KidAlbumAlbum>) { (Object as any).assign(this, init); }
}

export class KidAlbumInfo
{
    public teacheractivity?: KidAlbumInfoTeacherActivity;
    public order?: KidAlbumInfoOrder;
    public links?: KidAlbumUrl[];
    public album?: KidAlbumAlbum;

    public constructor(init?: Partial<KidAlbumInfo>) { (Object as any).assign(this, init); }
}

export class UserClassEventsStatItem
{
    public eventType?: string;
    public count?: number;

    public constructor(init?: Partial<UserClassEventsStatItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UserClassEventsStatItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UserClassEventsStats
{
    public items?: UserClassEventsStatItem[];

    public constructor(init?: Partial<UserClassEventsStats>) { (Object as any).assign(this, init); }
}

export class ConfigItemRespDTO
{
    public configName?: string;
    public version?: number;
    public code?: CONFIGTYPE;
    public hashString?: string;
    public patch?: string;
    public data?: string;

    public constructor(init?: Partial<ConfigItemRespDTO>) { (Object as any).assign(this, init); }
}

export class AliyunOssSignItem
{
    public originalKey?: string;
    public key?: string;
    public policy?: string;
    public signature?: string;

    public constructor(init?: Partial<AliyunOssSignItem>) { (Object as any).assign(this, init); }
}

export class TongduibaCreateOrderResData
{
    public bizId?: string;

    public constructor(init?: Partial<TongduibaCreateOrderResData>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointData
{
    public unionId?: string;
    public pointsAmount?: number;

    public constructor(init?: Partial<TongduibaQueryPointData>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointLogDataItem
{
    public unionId?: string;
    public pointsAmount?: number;
    public subject?: string;
    public body?: string;
    public createdAt?: string;
    public transferType?: number;
    public id?: string;

    public constructor(init?: Partial<TongduibaQueryPointLogDataItem>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointLogData
{
    public list?: TongduibaQueryPointLogDataItem[];
    public totalPage?: number;
    public page?: number;
    public pageSize?: number;
    public totalCount?: number;

    public constructor(init?: Partial<TongduibaQueryPointLogData>) { (Object as any).assign(this, init); }
}

export class GrowBookInfoDTO
{
    public kidId?: string;
    public kidName?: string;
    public termId?: number;
    public totalAmount?: number;
    public teacherAmount?: number;
    public momAmount?: number;
    public dadAmount?: number;
    public otherAmount?: number;
    public frontPageUrl?: string;
    public semesterType?: SEMESTERTYPE;
    public items?: GrowBookGroupDTO[];
    public termDateStart?: string;
    public termDateEnd?: string;
    public albumUrl?: string;
    public pageNumber?: number;

    public constructor(init?: Partial<GrowBookInfoDTO>) { (Object as any).assign(this, init); }
}

export class ClassEventVoteItem extends ClassEventItem
{
    public questions?: VoteItemSerial[];
    public isAnonymous?: boolean;
    public canSeeResult?: boolean;
    public endTime?: string;

    public constructor(init?: Partial<ClassEventVoteItem>) { super(init); (Object as any).assign(this, init); }
}

export class SystemNoticeItem
{
    public id?: string;
    public title?: string;
    public content?: string;
    public isLink?: boolean;
    public createBy?: string;
    public createOn?: string;

    public constructor(init?: Partial<SystemNoticeItem>) { (Object as any).assign(this, init); }
}

export class GetVersionCodeData
{
    public os?: OSTYPE;
    public versionName?: string;
    public versionCode?: string;
    public releaseNote?: string;
    public isDefault?: boolean;
    public isApk?: boolean;
    public downloadUrl?: string;
    public packageSize?: string;
    public releaseOn?: string;

    public constructor(init?: Partial<GetVersionCodeData>) { (Object as any).assign(this, init); }
}

export class WeatherDTOS
{
    public cityid?: string;
    public city?: string;
    public update_time?: string;
    public date?: string;
    public tem?: string;
    public tem1?: string;
    public tem2?: string;
    public humidity?: string;
    public wea?: string;
    public air?: string;
    public air_level?: string;
    public air_tips?: string;
    public win?: string;
    public win_speed?: string;
    public sun_level?: string;
    public sun_desc?: string;
    public wear_level?: string;
    public wear_desc?: string;
    public pm25?: string;
    public pm25_desc?: string;

    public constructor(init?: Partial<WeatherDTOS>) { (Object as any).assign(this, init); }
}

export class BabyDailyReportItem
{
    public icon?: string;
    public name?: string;
    public value?: string;
    public memo?: string;

    public constructor(init?: Partial<BabyDailyReportItem>) { (Object as any).assign(this, init); }
}

export class CommonReturn
{
    public ret?: boolean;
    public responseStatus?: ResponseStatus;
    public message?: string;
    public classId?: string;

    public constructor(init?: Partial<CommonReturn>) { (Object as any).assign(this, init); }
}

export class GetClassEventsRes
{
    public classId?: string;
    public totalcount?: number;
    public data?: ClassEventItem[];

    public constructor(init?: Partial<GetClassEventsRes>) { (Object as any).assign(this, init); }
}

export class StorageInfoDTO
{
    public classId?: string;
    public quoteSize?: number;
    public currentSize?: number;
    public classFileItems?: FileDirHaveChildDTO;

    public constructor(init?: Partial<StorageInfoDTO>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StorageInfoDTO'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetFileRes extends CommonReturn
{
    public data?: StorageInfoDTO;

    public constructor(init?: Partial<GetFileRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetRichChartRes
{
    public ret?: boolean;
    public responseStatus?: ResponseStatus;
    public message?: string;
    public data?: { [index: string]: Object; };

    public constructor(init?: Partial<GetRichChartRes>) { (Object as any).assign(this, init); }
}

export class ChangePasswordRes
{
    public ret?: boolean;
    public message?: string;

    public constructor(init?: Partial<ChangePasswordRes>) { (Object as any).assign(this, init); }
}

export class AliyunOssCallbackRes
{
    public filename?: string;
    public fileid?: string;
    public fileurl?: string;
    public thumburl?: string;
    public publisher?: string;
    public publishTime?: string;
    public playseconds?: number;

    public constructor(init?: Partial<AliyunOssCallbackRes>) { (Object as any).assign(this, init); }
}

export class GetAliyunStsTokenRes
{
    public statusCode?: number;
    public accessKeyId?: string;
    public bucketName?: string;
    public uploadHost?: string;
    public policy?: string;
    public signature?: string;
    public expires?: string;
    public dir?: string;
    public callback?: string;

    public constructor(init?: Partial<GetAliyunStsTokenRes>) { (Object as any).assign(this, init); }
}

export class BaseResponse
{
    public code?: number;
    public message?: string;

    public constructor(init?: Partial<BaseResponse>) { (Object as any).assign(this, init); }
}

export class WechatJsSdkConfig
{
    public debug?: boolean;
    public appId?: string;
    public timestamp?: number;
    public nonceStr?: string;
    public signature?: string;
    public jsApiList?: string[];

    public constructor(init?: Partial<WechatJsSdkConfig>) { (Object as any).assign(this, init); }
}

export class GetBindWechatInfoRes extends BaseResponse
{
    public data?: WeChatUserDTO;

    public constructor(init?: Partial<GetBindWechatInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class WechatComponentBindRes extends BaseResponse
{
    public needBindKid?: boolean;
    public needBindSchoolId?: string;
    public bindKidToken?: string;

    public constructor(init?: Partial<WechatComponentBindRes>) { super(init); (Object as any).assign(this, init); }
}

export class TranslateContentRes extends BaseResponse
{
    public translatedText?: string;

    public constructor(init?: Partial<TranslateContentRes>) { super(init); (Object as any).assign(this, init); }
}

export class FileListDirRes extends BaseResponse
{
    public data?: FileListItem[];
    public teacherDatas?: { [index: number]: TeacherBaseInfoDTO; };
    public totalcount?: number;
    public totalsize?: number;
    public totalquota?: number;

    public constructor(init?: Partial<FileListDirRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddPosterCatalogRes extends BaseResponse
{
    public id?: string;

    public constructor(init?: Partial<AddPosterCatalogRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPosterCatalogsRes extends BaseResponse
{
    public data?: PosterCatalogDTO[];

    public constructor(init?: Partial<GetPosterCatalogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddPosterTemplateRes extends BaseResponse
{
    public id?: string;

    public constructor(init?: Partial<AddPosterTemplateRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPosterTemplatesRes extends BaseResponse
{
    public data?: PosterTemplateDTO[];

    public constructor(init?: Partial<GetPosterTemplatesRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddPosterItemRes extends BaseResponse
{
    public id?: string;

    public constructor(init?: Partial<AddPosterItemRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPosterItemRes extends BaseResponse
{
    public data?: PosterItemDTO;
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetPosterItemRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPosterItemsRes extends BaseResponse
{
    public data?: PosterItemDTO[];
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetPosterItemsRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddPublicityRes extends BaseResponse
{
    public id?: string;

    public constructor(init?: Partial<AddPublicityRes>) { super(init); (Object as any).assign(this, init); }
}

export class FindPublicityRes extends BaseResponse
{
    public data?: PublicityDTO;
    public teacherInfo?: TeacherBaseInfoDTO;
    public schoolInfo?: SchoolNameDTO;

    public constructor(init?: Partial<FindPublicityRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPublicityListRes extends BaseResponse
{
    public total?: number;
    public data?: PublicityDTO[];
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };
    public schoolInfo?: SchoolInfoDTO;

    public constructor(init?: Partial<GetPublicityListRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolClassSummaryRes extends BaseResponse
{
    public data?: ReportClassSummaryDTO[];
    public classData?: { [index: string]: ClassBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolClassSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class ReportTableRes
{
    public code?: number;
    public message?: string;
    public columns?: ReportColumn[];
    public data?: { [index:string]: Object; }[];

    public constructor(init?: Partial<ReportTableRes>) { (Object as any).assign(this, init); }
}

export class GetClassSummaryDailyRes extends BaseResponse
{
    public data?: ReportDailyClassSummaryDTO[];

    public constructor(init?: Partial<GetClassSummaryDailyRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolIMSummaryRes extends BaseResponse
{
    public data?: ReportIMSummaryDTO[];
    public classData?: { [index: string]: ClassBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolIMSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailySchoolIMSummaryRes extends BaseResponse
{
    public data?: ReportDailyIMSummaryDTO[];

    public constructor(init?: Partial<GetDailySchoolIMSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassKidActivityRes extends BaseResponse
{
    public data?: ReportClassKidActivityDTO[];
    public kidName?: { [index: string]: string; };
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };
    public parentRole?: { [index: string]: string; };

    public constructor(init?: Partial<GetClassKidActivityRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassKidActivityDailyRes extends BaseResponse
{
    public classKidCount?: number;
    public data?: ReportClassKidActivityDailyDTO[];

    public constructor(init?: Partial<GetClassKidActivityDailyRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidActivityRes extends BaseResponse
{
    public schoolKidCount?: number;
    public classKidCount?: number;
    public schoolActivityData?: ReportSchoolKidActivitySummaryDTO[];
    public classActivityData?: ReportSchoolKidActivityDTO[];
    public classData?: { [index: string]: ClassBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolKidActivityRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidActivitySummaryRes extends BaseResponse
{
    public classActivityData?: ReportSchoolKidActivityDTO[];
    public classData?: { [index: string]: ClassBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolKidActivitySummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidActivitySummaryDailyRes extends BaseResponse
{
    public schoolKidCount?: number;
    public classKidCount?: number;
    public data?: ReportSchoolKidActivitySummaryDTO[];

    public constructor(init?: Partial<GetSchoolKidActivitySummaryDailyRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolTeacherActivityRes extends BaseResponse
{
    public rateDailyTeacherLogin?: number;
    public schoolTeacherCount?: number;
    public schoolData?: ReportDailySchoolTeacherActivitySummaryDTO[];
    public teacherData?: ReportMonthSchoolTeacherActivitySummaryDTO[];
    public teacherInfoData?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolTeacherActivityRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailySchoolTeacherActivitySummaryRes extends BaseResponse
{
    public schoolTeacherCount?: number;
    public data?: ReportDailySchoolTeacherActivitySummaryDTO[];

    public constructor(init?: Partial<GetDailySchoolTeacherActivitySummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailySchoolTeacherActivityRes extends BaseResponse
{
    public data?: ReportMonthSchoolTeacherActivitySummaryDTO[];
    public teacherInfoData?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetDailySchoolTeacherActivityRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMonthSchoolKidAttendanceRes extends BaseResponse
{
    public schoolKidCount?: number;
    public classKidCount?: number;
    public todayKidCount?: number;
    public averageKidCount?: number;

    public constructor(init?: Partial<GetMonthSchoolKidAttendanceRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimeTeacherSummaryRes extends BaseResponse
{
    public data?: ClassTimeSummaryByTeacherIdClassIdDTO[];
    public teacherNames?: { [index: string]: string; };
    public classNames?: { [index: string]: string; };
    public classCourseNames?: { [index: string]: string; };
    public classCourses?: { [index: string]: CourseItem; };

    public constructor(init?: Partial<GetClassTimeTeacherSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetOrgStudentCourseBalanceSummaryRes extends BaseResponse
{
    public data?: OrgStudentCourseBalanceDTO[];
    public courses?: { [index: string]: CourseItem; };

    public constructor(init?: Partial<GetOrgStudentCourseBalanceSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimeClassSummaryRes extends BaseResponse
{
    public data?: ClassTimeSummaryByCourseIdDTO[];
    public classNames?: { [index: string]: string; };
    public courseNames?: { [index: string]: string; };
    public courses?: { [index: string]: CourseItem; };

    public constructor(init?: Partial<GetClassTimeClassSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolDailyKidSummaryRes extends BaseResponse
{
    public data?: ReportDaily_SchoolKidSummaryDTO[];

    public constructor(init?: Partial<GetSchoolDailyKidSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidAttendanceSummaryRes extends BaseResponse
{
    public data?: ReportAttendanceRate[];

    public constructor(init?: Partial<GetSchoolKidAttendanceSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolTeacherAttendanceSummaryRes extends BaseResponse
{
    public data?: ReportAttendanceRate[];

    public constructor(init?: Partial<GetSchoolTeacherAttendanceSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolClassEventSummaryRes extends BaseResponse
{
    public data?: ReportDailyClassSummaryDTO[];

    public constructor(init?: Partial<GetSchoolClassEventSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolOrderSummaryRes extends BaseResponse
{
    public data?: SchoolOrderSummaryDTO[];

    public constructor(init?: Partial<GetSchoolOrderSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolSubMchSummaryRes extends BaseResponse
{
    public data?: SchoolOrderSummaryDTO[];

    public constructor(init?: Partial<GetSchoolSubMchSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolSummaryByAppRes extends BaseResponse
{
    public beginDate?: string;
    public endDate?: string;
    public data?: SchoolSummaryByAppReport[];
    public schoolInfo?: SchoolInfoDTO;

    public constructor(init?: Partial<GetSchoolSummaryByAppRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolSaleLeadSummaryRes extends BaseResponse
{
    public data?: SchoolSaleLeadSummaryDTO[];

    public constructor(init?: Partial<GetSchoolSaleLeadSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolSaleLeadFunnelRes extends BaseResponse
{
    public data?: SchoolSaleLeadFunnelDTO;

    public constructor(init?: Partial<GetSchoolSaleLeadFunnelRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceFromQrcodeRes extends BaseResponse
{

    public constructor(init?: Partial<AttendanceFromQrcodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetAttendanceBindsRes extends BaseResponse
{
    public data?: AttendanceUserMapDTO[];
    public teacherInfoData?: { [index: string]: TeacherBaseInfoDTO; };
    public classData?: { [index: string]: ClassBaseInfoDTO; };
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };
    public parentRole?: { [index: string]: string; };

    public constructor(init?: Partial<GetAttendanceBindsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherAttendanceBindsRes extends BaseResponse
{
    public data?: TeacherAttendanceBindItem;

    public constructor(init?: Partial<GetTeacherAttendanceBindsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolParentAttendanceBindsRes extends BaseResponse
{
    public faceData?: { [index: string]: AiFaceItem; };

    public constructor(init?: Partial<GetSchoolParentAttendanceBindsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidAttendanceBindsRes extends BaseResponse
{
    public cardData?: { [index: string]: SchoolAttendanceBindItem[]; };
    public faceData?: { [index: string]: AiFaceItem; };
    public weChatUserIds?: number[];

    public constructor(init?: Partial<GetSchoolKidAttendanceBindsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolTeacherAttendanceBindsRes extends BaseResponse
{
    public cardData?: { [index: string]: SchoolAttendanceBindItem[]; };
    public faceData?: { [index: string]: AiFaceItem; };
    public weChatUserIds?: number[];

    public constructor(init?: Partial<GetSchoolTeacherAttendanceBindsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolAttendanceBindsRes extends BaseResponse
{
    public sets?: { [index: string]: SchoolAttendanceBindItem; };

    public constructor(init?: Partial<GetSchoolAttendanceBindsRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddAttendanceBindRes extends BaseResponse
{

    public constructor(init?: Partial<AddAttendanceBindRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceFromTerminalRes extends BaseResponse
{
    public data?: AttendanceFromTerminalResData;

    public constructor(init?: Partial<AttendanceFromTerminalRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceGetLoginCodeRes extends BaseResponse
{
    public qrCode?: string;
    public ticket?: string;

    public constructor(init?: Partial<AttendanceGetLoginCodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceCheckLoginTicketRes extends BaseResponse
{
    public status?: number;
    public isConfirm?: boolean;
    public userName?: string;
    public avatarUrl?: string;
    public token?: string;
    public refreshToken?: string;
    public schoolLogoUrl?: string;
    public schoolId?: string;
    public schoolName?: string;
    public otpKey?: string;

    public constructor(init?: Partial<AttendanceCheckLoginTicketRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceDeviceLoginByManageRes extends BaseResponse
{

    public constructor(init?: Partial<AttendanceDeviceLoginByManageRes>) { super(init); (Object as any).assign(this, init); }
}

export class SetTeacherAttendanceTemplateRes extends BaseResponse
{

    public constructor(init?: Partial<SetTeacherAttendanceTemplateRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddAttendanceTeacherTemplateRes extends BaseResponse
{

    public constructor(init?: Partial<AddAttendanceTeacherTemplateRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetAttendanceTeacherTemplateRes extends BaseResponse
{
    public data?: AttendanceTeacherTemplateItem[];

    public constructor(init?: Partial<GetAttendanceTeacherTemplateRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceStudentIndexRes extends BaseResponse
{
    public data?: AttendanceStudentIndexItem[];

    public constructor(init?: Partial<AttendanceStudentIndexRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceParentIndexRes extends BaseResponse
{
    public data?: AttendanceParentIndexItem[];

    public constructor(init?: Partial<AttendanceParentIndexRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceParentDataRes extends BaseResponse
{
    public data?: AttendanceParentDataItem[];
    public parentStudentMaps?: ParentStudentMapItem[];

    public constructor(init?: Partial<AttendanceParentDataRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceStudentDataRes extends BaseResponse
{
    public data?: AttendanceStudentDataItem[];

    public constructor(init?: Partial<AttendanceStudentDataRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceTeacherIndexRes extends BaseResponse
{
    public data?: AttendanceTeacherIndexItem[];

    public constructor(init?: Partial<AttendanceTeacherIndexRes>) { super(init); (Object as any).assign(this, init); }
}

export class AttendanceTeacherDataRes extends BaseResponse
{
    public data?: AttendanceTeacherDataItem[];

    public constructor(init?: Partial<AttendanceTeacherDataRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolAttendanceDeviceRes extends BaseResponse
{
    public name?: string;
    public logoUrl?: string;
    public schoolImageUrl?: string;
    public desc?: string;
    public schoolType?: string;
    public province?: string;
    public city?: string;
    public district?: string;
    public images?: string[];
    public notices?: string[];
    public arcfaceLicense?: string;
    public audioTemplates?: { [index: string]: string; };

    public constructor(init?: Partial<GetSchoolAttendanceDeviceRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherAttendanceRes extends BaseResponse
{
    public data?: AttendanceTeacherDTO[];

    public constructor(init?: Partial<GetTeacherAttendanceRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassAttendanceLogRes extends BaseResponse
{
    public data?: BaseKidInfoDTO[];
    public logs?: { [index: string]: AttendanceKidDTO; };

    public constructor(init?: Partial<GetClassAttendanceLogRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidsAttendanceLogRes extends BaseResponse
{
    public totalCount?: number;
    public data?: AttendanceKidDTO[];
    public classNames?: { [index: string]: string; };
    public kids?: { [index: string]: BaseKidInfoDTO; };

    public constructor(init?: Partial<GetKidsAttendanceLogRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassKidsAttendanceDailyReportRes extends BaseResponse
{
    public classSummary?: ClassAttendanceDailyReport;
    public data?: BaseKidInfoDTO[];
    public kidAttendances?: { [index: string]: KidAttendance; };

    public constructor(init?: Partial<GetClassKidsAttendanceDailyReportRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassAttendanceDailyReportRes extends BaseResponse
{
    public data?: ClassAttendanceDailyReport[];
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetClassAttendanceDailyReportRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassAttendanceMonthReportRes extends BaseResponse
{
    public data?: ClassAttendanceMonthReport[];
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetClassAttendanceMonthReportRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassKidsAttendanceMonthReportRes extends BaseResponse
{
    public data?: KidAttendanceMonthReport[];
    public kids?: { [index: string]: BaseKidInfoDTO; };

    public constructor(init?: Partial<GetClassKidsAttendanceMonthReportRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherAttendanceLogRes extends BaseResponse
{
    public data?: AttendanceTeacherLogDTO[];

    public constructor(init?: Partial<GetTeacherAttendanceLogRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherAttendanceMonthReportRes extends BaseResponse
{
    public data?: TeacherAttendanceMonthReport[];

    public constructor(init?: Partial<GetTeacherAttendanceMonthReportRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherAttendanceMonthReportRateRes extends BaseResponse
{
    public attendanceRate?: number;

    public constructor(init?: Partial<GetTeacherAttendanceMonthReportRateRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherAttendanceDailyReportRes extends BaseResponse
{
    public data?: TeacherAttendanceDailyReportItem[];

    public constructor(init?: Partial<GetTeacherAttendanceDailyReportRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolCalendarByYearRes extends BaseResponse
{
    public workDays?: string[];
    public noWorkDays?: string[];

    public constructor(init?: Partial<GetSchoolCalendarByYearRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolYearCalendarRes extends BaseResponse
{
    public data?: SchoolYearCalendarDTO;

    public constructor(init?: Partial<GetSchoolYearCalendarRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetChineseCalendarDefineRes extends BaseResponse
{
    public data?: ChineseCalendarDefineDTO;

    public constructor(init?: Partial<GetChineseCalendarDefineRes>) { super(init); (Object as any).assign(this, init); }
}

export class SetSchoolYearCalendarRes extends BaseResponse
{

    public constructor(init?: Partial<SetSchoolYearCalendarRes>) { super(init); (Object as any).assign(this, init); }
}

export class SetChineseCalendarDefineRes extends BaseResponse
{

    public constructor(init?: Partial<SetChineseCalendarDefineRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetCommissionPickupRes extends BaseResponse
{
    public data?: CommissionPickupDTO[];
    public studentInfo?: { [index: string]: StudentBaseInfoDTO; };
    public teacherInfoData?: { [index: string]: TeacherBaseInfoDTO; };
    public classData?: { [index: string]: ClassBaseInfoDTO; };
    public parentRole?: { [index: string]: string; };

    public constructor(init?: Partial<GetCommissionPickupRes>) { super(init); (Object as any).assign(this, init); }
}

export class HealthyGateDeviceRecordResp
{
    public msgType?: string;
    public msgID?: string;
    public deviceNo?: string;
    public errorCode?: string;
    public controlState?: string;
    public msg?: string;
    public displayMsg?: string;
    public displayPhoto?: string;

    public constructor(init?: Partial<HealthyGateDeviceRecordResp>) { (Object as any).assign(this, init); }
}

export class AttendanceDeviceGetConfigRes extends BaseResponse
{
    public deviceConfig?: AttendanceDeviceSetConfigItem;

    public constructor(init?: Partial<AttendanceDeviceGetConfigRes>) { super(init); (Object as any).assign(this, init); }
}

export class SchoolBabyCareConfigRes extends BaseResponse
{
    public data?: DailyCareSchoolConfigDTO;
    public schoolConfigs?: SchoolSettingDTO;

    public constructor(init?: Partial<SchoolBabyCareConfigRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyCareBatchLogsRes extends BaseResponse
{
    public data?: DailyCareBatchLogDTO[];

    public constructor(init?: Partial<GetDailyCareBatchLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyCareBasesRes extends BaseResponse
{
    public total?: number;
    public data?: DailyCareItem[];
    public kidInfos?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetDailyCareBasesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyCareNoticeRes extends BaseResponse
{
    public data?: DailayCareNoticeItem[];

    public constructor(init?: Partial<GetDailyCareNoticeRes>) { super(init); (Object as any).assign(this, init); }
}

export class BabyCareDeviceLoginByFaceRes extends BaseResponse
{
    public token?: string;
    public refreshToken?: string;
    public userName?: string;
    public userId?: number;
    public fullName?: string;
    public avatarUrl?: string;
    public schoolId?: string;
    public schoolLogoUrl?: string;
    public schoolName?: string;
    public classInfos?: ClassInfoWithTeacherRoleDTO[];
    public otpKey?: string;

    public constructor(init?: Partial<BabyCareDeviceLoginByFaceRes>) { super(init); (Object as any).assign(this, init); }
}

export class BabyCareDeviceLoginByTeacherRes extends BaseResponse
{

    public constructor(init?: Partial<BabyCareDeviceLoginByTeacherRes>) { super(init); (Object as any).assign(this, init); }
}

export class BabyCareGetLoginCodeRes extends BaseResponse
{
    public qrCode?: string;
    public ticket?: string;

    public constructor(init?: Partial<BabyCareGetLoginCodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class BabyCareCheckLoginTicketRes extends BaseResponse
{
    public status?: number;
    public isConfirm?: boolean;
    public userName?: string;
    public userId?: number;
    public fullName?: string;
    public avatarUrl?: string;
    public token?: string;
    public refreshToken?: string;
    public schoolLogoUrl?: string;
    public schoolId?: string;
    public schoolName?: string;
    public classInfos?: ClassInfoWithTeacherRoleDTO[];
    public otpKey?: string;

    public constructor(init?: Partial<BabyCareCheckLoginTicketRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareDeviceInfoRes extends BaseResponse
{
    public data?: DailyCareDeviceDTO;

    public constructor(init?: Partial<GetBabyCareDeviceInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareBYODDeviceLicenseRes extends BaseResponse
{
    public data?: BabyCareBYODDeviceLicenseItem[];
    public deviceImage?: string;
    public totalAmount?: number;
    public avaliableAmount?: number;
    public usedAmount?: number;

    public constructor(init?: Partial<GetBabyCareBYODDeviceLicenseRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareDevicesRes extends BaseResponse
{
    public total?: number;
    public data?: DailyCareDeviceDTO[];
    public liveChannel?: { [index: string]: string; };
    public licenses?: BabyCareBYODDeviceLicenseItem[];

    public constructor(init?: Partial<GetBabyCareDevicesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareTasksRes extends BaseResponse
{
    public data?: BabyCareTaskDTO[];
    public todayWorkHistory?: { [index: string]: BabyCareTaskWorkDTO; };
    public studyInfos?: { [index: string]: string; };

    public constructor(init?: Partial<GetBabyCareTasksRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareTaskWorksRes extends BaseResponse
{
    public total?: number;
    public data?: BabyCareTaskWorkDTO[];
    public teacherInfoData?: { [index: string]: TeacherBaseInfoDTO; };
    public taskData?: { [index: string]: BabyCareTaskDTO; };

    public constructor(init?: Partial<GetBabyCareTaskWorksRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyDailyReportClassRes extends BaseResponse
{
    public schoolName?: string;
    public schoolIcon?: string;
    public className?: string;
    public reportDate?: string;
    public data?: { [index:string]: Object; }[];
    public kidInfos?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetBabyDailyReportClassRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyWeekReportClassRes extends BaseResponse
{
    public schoolName?: string;
    public schoolIcon?: string;
    public className?: string;
    public reportDate?: string;
    public data?: { [index:string]: Object; }[];
    public kidInfos?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetBabyWeekReportClassRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyDailyReportRes extends BaseResponse
{
    public schoolName?: string;
    public schoolIcon?: string;
    public reportDate?: string;
    public kidName?: string;
    public kidAge?: string;
    public kidLogoUrl?: string;
    public reportItems?: { [index: string]: BabyDailyReportItem[]; };
    public todayNote?: string;
    public nutritions?: Nutrition;
    public data?: DailyMenuDTO;
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public dishs?: { [index: string]: DishItemDTO; };
    public foods?: { [index: string]: IngredientItemDTO; };

    public constructor(init?: Partial<GetBabyDailyReportRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyReportWeekRes extends BaseResponse
{
    public schoolName?: string;
    public schoolIcon?: string;
    public reportDate?: string;
    public kidName?: string;
    public kidAge?: string;
    public kidLogoUrl?: string;
    public summaryItems?: BabyDailySummaryItem[];
    public reportItems?: BabyDailyReportItem[];
    public weekNote?: string;
    public nutritions?: Nutrition;
    public weekNutritions?: Nutrition;

    public constructor(init?: Partial<GetBabyReportWeekRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyCareSchoolDailySummaryRes extends BaseResponse
{
    public data?: DailyCareSchoolDailySummaryDTO[];
    public schoolNames?: { [index: string]: string; };
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetDailyCareSchoolDailySummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBabyCareTaskWorkSchoolDailySummaryRes extends BaseResponse
{
    public data?: BabyCareTaskWorkSchoolDailySummaryDTO[];
    public schoolNames?: { [index: string]: string; };
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetBabyCareTaskWorkSchoolDailySummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolBillItemsRes extends BaseResponse
{
    public data?: ClassBillItemDTO[];
    public classInfo?: { [index: string]: ClassBaseInfoDTO; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolBillItemsRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddSchoolBillItemRes extends BaseResponse
{
    public billItemId?: string;

    public constructor(init?: Partial<AddSchoolBillItemRes>) { super(init); (Object as any).assign(this, init); }
}

export class CancelSchoolBillItemRes extends BaseResponse
{
    public refundOrderNos?: string[];

    public constructor(init?: Partial<CancelSchoolBillItemRes>) { super(init); (Object as any).assign(this, init); }
}

export class TerminateSchoolBillItemRes extends BaseResponse
{

    public constructor(init?: Partial<TerminateSchoolBillItemRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolBillItemLogsRes extends BaseResponse
{
    public data?: ClassBillLogDTO[];
    public studentInfos?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolBillItemLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolBillItemsByParentRes extends BaseResponse
{
    public data?: ClassBillItemInfoDTO[];
    public classInfo?: { [index: string]: ClassBaseInfoDTO; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolBillItemsByParentRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidRes extends BaseResponse
{
    public data?: KidInfoDTO;
    public studentdata?: StudentInfoDTO;

    public constructor(init?: Partial<GetSchoolKidRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidsRes extends BaseResponse
{
    public totalCount?: number;
    public data?: KidInfoDTO[];
    public studentdata?: { [index: string]: StudentInfoDTO; };

    public constructor(init?: Partial<GetSchoolKidsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassInfoRes
{
    public classInfo?: ClassInfoItemDTO;

    public constructor(init?: Partial<GetClassInfoRes>) { (Object as any).assign(this, init); }
}

export class GetKidRemarksRes
{
    public ret?: boolean;
    public message?: string;
    public data?: KidRemarkDTO[];

    public constructor(init?: Partial<GetKidRemarksRes>) { (Object as any).assign(this, init); }
}

export class GetEventTaskByIdRes
{
    public ret?: boolean;
    public message?: string;
    public eventitem?: ClassEventTaskItem;

    public constructor(init?: Partial<GetEventTaskByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventClockInByIdRes extends CommonReturn
{
    public data?: ClassEventClockInItem;

    public constructor(init?: Partial<GetEventClockInByIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetEventClockInGroupItemsByIdRes extends CommonReturn
{
    public data?: EventClockInItemDTO[];

    public constructor(init?: Partial<GetEventClockInGroupItemsByIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidApplyInfosRes extends CommonReturn
{
    public data?: KidApplyInfoDTO[];

    public constructor(init?: Partial<GetKidApplyInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidCheckUpTemplateseRes extends BaseResponse
{
    public data?: KidCheckUpTemplateDTO[];

    public constructor(init?: Partial<GetKidCheckUpTemplateseRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddKidCheckUpRes extends BaseResponse
{
    public data?: KidCheckUpDTO;

    public constructor(init?: Partial<AddKidCheckUpRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddKidCheckUpsRes extends BaseResponse
{
    public data?: KidCheckUpDTO[];

    public constructor(init?: Partial<AddKidCheckUpsRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddKidCheckUpByExcelRes extends BaseResponse
{
    public tmplId?: string;
    public data?: KidCheckUpDTO[];

    public constructor(init?: Partial<AddKidCheckUpByExcelRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidCheckUpsRes extends BaseResponse
{
    public total?: number;
    public data?: KidCheckUpDTO[];
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };
    public saleLead?: { [index: string]: StudentBaseInfoDTO; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetKidCheckUpsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidCheckUpRes extends BaseResponse
{
    public data?: KidCheckUpDTO[];
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };
    public gender?: GENDERTYPE;
    public height?: KidGrowthStandard[];
    public weight?: KidGrowthStandard[];
    public head?: KidGrowthStandard[];

    public constructor(init?: Partial<GetKidCheckUpRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassRoomsRes extends BaseResponse
{
    public data?: ClassRoomItem[];

    public constructor(init?: Partial<GetClassRoomsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetCoursesRes extends BaseResponse
{
    public data?: CourseItem[];

    public constructor(init?: Partial<GetCoursesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassSchedulesRes extends BaseResponse
{
    public data?: ClassScheduleItem[];

    public constructor(init?: Partial<GetClassSchedulesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassSchedulesByDateRes extends BaseResponse
{
    public data?: ClassScheduleItem[];

    public constructor(init?: Partial<GetClassSchedulesByDateRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddClassScheduleRes extends BaseResponse
{
    public scheduleIds?: string[];

    public constructor(init?: Partial<AddClassScheduleRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimeRes extends BaseResponse
{
    public data?: ClassTimeDTO;
    public studentData?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetClassTimeRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimesRes extends BaseResponse
{
    public data?: ClassTimeDTO[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public teacherData?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetClassTimesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimeSignKidsRes extends BaseResponse
{
    public totalCount?: number;
    public data?: ClassTimeSignInfoKidDTO[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public classData?: { [index: string]: ClassBaseInfoDTO; };

    public constructor(init?: Partial<GetClassTimeSignKidsRes>) { super(init); (Object as any).assign(this, init); }
}

export class ClassTimeSignRes extends BaseResponse
{

    public constructor(init?: Partial<ClassTimeSignRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddStudentToChassTimeRes extends BaseResponse
{

    public constructor(init?: Partial<AddStudentToChassTimeRes>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateChassTimeTimeRes extends BaseResponse
{

    public constructor(init?: Partial<UpdateChassTimeTimeRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSalesLeadSummaryRes extends BaseResponse
{
    public toDayFollow?: number;
    public weekFollow?: number;
    public monthFollow?: number;
    public toDayNew?: number;
    public weekNew?: number;
    public monthNew?: number;

    public constructor(init?: Partial<GetSalesLeadSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSalesLeadRes extends BaseResponse
{
    public lead?: SalesLeadDTO;
    public kid?: KidInfoDTO;

    public constructor(init?: Partial<GetSalesLeadRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSalesLeadsRes extends BaseResponse
{
    public leadData?: SalesLeadDTO[];
    public totalCount?: number;

    public constructor(init?: Partial<GetSalesLeadsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSalesFollowUpInfoRes extends BaseResponse
{
    public data?: SalesFollowUpInfoDTO[];
    public leadData?: { [index: string]: SalesLeadDTO; };
    public totalCount?: number;

    public constructor(init?: Partial<GetSalesFollowUpInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSalesDemoInfosRes extends BaseResponse
{
    public data?: SalesDemoInfoDTO[];
    public leadData?: { [index: string]: SalesLeadDTO; };
    public totalCount?: number;

    public constructor(init?: Partial<GetSalesDemoInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class CreateCourseOrderRes extends BaseResponse
{
    public orderNo?: string;

    public constructor(init?: Partial<CreateCourseOrderRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetCourseOrdersRes extends BaseResponse
{
    public totalCount?: number;
    public data?: CourseOrderDTO[];
    public teacherData?: { [index: string]: TeacherBaseInfoDTO; };
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetCourseOrdersRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetStatementDetailsRes extends BaseResponse
{
    public totalCount?: number;
    public totalInCount?: number;
    public totalInAmount?: string;
    public totalOutCount?: number;
    public totalOutAmount?: string;
    public data?: StatementDetailDTO[];
    public teacherData?: { [index: string]: TeacherBaseInfoDTO; };
    public statementAccountData?: { [index: string]: StatementAccountDTO; };

    public constructor(init?: Partial<GetStatementDetailsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidsCoursesRes extends BaseResponse
{
    public totalCount?: number;
    public data?: OrgStudentCourseDTO[];
    public studentdata?: { [index: string]: StudentBaseInfoDTO; };
    public courseData?: { [index: string]: CourseItem; };

    public constructor(init?: Partial<GetSchoolKidsCoursesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidsCourseBalanceRes extends BaseResponse
{
    public sets?: { [index: string]: KidCourseBalanceItem; };

    public constructor(init?: Partial<GetSchoolKidsCourseBalanceRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidCourseBalanceRes extends BaseResponse
{
    public data?: KidCourseBalanceItem[];

    public constructor(init?: Partial<GetSchoolKidCourseBalanceRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidsCourseBalanceeMinRes extends BaseResponse
{
    public totalCount?: number;
    public data?: KidCourseBalanceItem[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public courseData?: { [index: string]: CourseItem; };

    public constructor(init?: Partial<GetSchoolKidsCourseBalanceeMinRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidsByCourseRes extends BaseResponse
{
    public data?: StudentBaseInfoDTO[];

    public constructor(init?: Partial<GetSchoolKidsByCourseRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetCourseConsumDetailRes extends BaseResponse
{
    public data?: CourseConsumLogDTO;
    public teacherData?: { [index: string]: TeacherBaseInfoDTO; };
    public courseData?: CourseDTO;
    public classTimeData?: ClassTimeDTO;
    public studentData?: StudentBaseInfoDTO;
    public studentCourseData?: OrgStudentCourseDTO;
    public buyContentData?: BuyContentDTO;

    public constructor(init?: Partial<GetCourseConsumDetailRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetCourseConsumLogsRes extends BaseResponse
{
    public totalCount?: number;
    public totalConsumPrice?: string;
    public data?: CourseConsumLogDTO[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public courseData?: { [index: string]: CourseOrderItem; };
    public classTimeData?: { [index: string]: ClassTimeDTO; };
    public eventTitleData?: { [index: string]: string; };

    public constructor(init?: Partial<GetCourseConsumLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetCourseConsumLogsForParentRes extends BaseResponse
{
    public data?: CourseConsumLogDTO[];
    public classTimes?: { [index: string]: ClassTimeSignInfoKidDTO; };
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetCourseConsumLogsForParentRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimeCommentScoreTemplatesRes extends BaseResponse
{
    public data?: ClassTimeCommentScoreTemplateDTO[];

    public constructor(init?: Partial<GetClassTimeCommentScoreTemplatesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimeCommentsRes extends BaseResponse
{
    public total?: number;
    public data?: ClassTimeDTO[];
    public commentKid?: { [index: string]: ClassTimeCommentKidDTO; };
    public commentTeacher?: { [index: string]: ClassTimeCommentTeacherDTO; };
    public commentCount?: { [index: string]: number; };
    public className?: { [index: string]: string; };
    public teacherName?: { [index: string]: string; };

    public constructor(init?: Partial<GetClassTimeCommentsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimeCommentKidsRes extends BaseResponse
{
    public total?: number;
    public data?: ClassTimeCommentKidDTO[];
    public classTime?: { [index: string]: ClassTimeDTO; };
    public classInfo?: { [index: string]: ClassBaseInfoDTO; };
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public studentData?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetClassTimeCommentKidsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimeCommentTeachersRes extends BaseResponse
{
    public total?: number;
    public data?: ClassTimeCommentTeacherDTO[];
    public classTime?: { [index: string]: ClassTimeDTO; };
    public className?: { [index: string]: string; };
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public parentInfo?: { [index: string]: ParentRelationSchoolInfoDTO; };

    public constructor(init?: Partial<GetClassTimeCommentTeachersRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetGrowBookFrontPageTemplatesRes
{
    public ret?: boolean;
    public message?: string;
    public data?: GrowBookFrontPageTemplateDTO[];

    public constructor(init?: Partial<GetGrowBookFrontPageTemplatesRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolTeacherChatRes extends CommonReturn
{
    public data?: SchoolIMConversation[];

    public constructor(init?: Partial<GetSchoolTeacherChatRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolTeacherChatHistoryRes extends CommonReturn
{
    public data?: SchoolIMHistory[];

    public constructor(init?: Partial<GetSchoolTeacherChatHistoryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetIMContactsRes extends CommonReturn
{
    public contacts?: IMContactItem[];
    public groupData?: GroupItem[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public classData?: { [index: string]: ClassBaseInfoDTO; };
    public schoolData?: { [index: string]: SchoolBaseInfo; };

    public constructor(init?: Partial<GetIMContactsRes>) { super(init); (Object as any).assign(this, init); }
}

export class RongIMGetSchoolTeacherConvIdsRes extends BaseResponse
{
    public data?: RongIMConvDTO[];

    public constructor(init?: Partial<RongIMGetSchoolTeacherConvIdsRes>) { super(init); (Object as any).assign(this, init); }
}

export class RongIMGetSchoolIMHistoriesRes extends BaseResponse
{
    public data?: RongIMHistoryDTO[];

    public constructor(init?: Partial<RongIMGetSchoolIMHistoriesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherIMCatalogRes extends BaseResponse
{
    public data?: IMCatalogItem[];
    public isGlobalMute?: boolean;
    public muteConvIds?: string[];

    public constructor(init?: Partial<GetTeacherIMCatalogRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetIotCatalogsRes extends BaseResponse
{
    public data?: IotCatalogDTO[];

    public constructor(init?: Partial<GetIotCatalogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetIotDevicesRes extends BaseResponse
{
    public data?: IotDeviceDTO[];

    public constructor(init?: Partial<GetIotDevicesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLeaveRequestByTeacherRes extends BaseResponse
{
    public total?: number;
    public data?: KidMyLeaveRequestItem[];
    public parentRoles?: { [index: string]: string; };
    public studentInfos?: { [index: string]: StudentBaseInfoDTO; };
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetLeaveRequestByTeacherRes>) { super(init); (Object as any).assign(this, init); }
}

export class LeaveRequestConfigRes extends BaseResponse
{
    public subjects?: string[];
    public auditors?: { [index: string]: string; };

    public constructor(init?: Partial<LeaveRequestConfigRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidMyLeaveRequestRes extends BaseResponse
{
    public data?: KidMyLeaveRequestItem[];
    public parentRoles?: { [index: string]: string; };
    public studentInfos?: { [index: string]: StudentBaseInfoDTO; };
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetKidMyLeaveRequestRes>) { super(init); (Object as any).assign(this, init); }
}

export class JoinLiveClassRoomRes extends BaseResponse
{
    public token?: string;
    public appId?: string;
    public rtcUserId?: string;
    public rtmToken?: string;

    public constructor(init?: Partial<JoinLiveClassRoomRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveClassRoomByIdRes extends BaseResponse
{
    public data?: LiveClassRoomDTO;
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public teacherInfoData?: { [index: number]: TeacherBaseInfoDTO; };
    public ossinfos?: { [index: string]: OssInfoDTO; };
    public parentinfos?: { [index: number]: ParentRelationClassInfoDTO; };

    public constructor(init?: Partial<GetLiveClassRoomByIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveClassRoomsBySchoolIdRes extends BaseResponse
{
    public total?: number;
    public data?: LiveClassRoomDTO[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public teacherInfoData?: { [index: string]: TeacherBaseInfoDTO; };
    public ossinfos?: { [index: string]: OssInfoDTO; };
    public parentinfos?: { [index: string]: ParentRelationClassInfoDTO; };

    public constructor(init?: Partial<GetLiveClassRoomsBySchoolIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveClassRoomShareUrlRes extends BaseResponse
{
    public shareUrl?: string;

    public constructor(init?: Partial<GetLiveClassRoomShareUrlRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveClassRoomShareDataRes extends BaseResponse
{
    public data?: LiveClassRoomDTO;
    public teacherInfo?: TeacherBaseInfoDTO;

    public constructor(init?: Partial<GetLiveClassRoomShareDataRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetShengPaySubMchInfoByShengpayAccountNoRes extends BaseResponse
{
    public data?: ShengPaySubMchInfoDTO;

    public constructor(init?: Partial<GetShengPaySubMchInfoByShengpayAccountNoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPaymentSubMchInfoRes extends BaseResponse
{
    public minDrawAmount?: number;
    public data?: PaymentSubMchInfoDTO;
    public shengPaySubMchInfo?: ShengPaySubMchInfoDTO;
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetPaymentSubMchInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPaymentSubMchInfosRes extends BaseResponse
{
    public totalCount?: number;
    public data?: { [index: string]: string; };
    public paymentSubMchInfos?: { [index: string]: PaymentSubMchInfoDTO; };
    public shengPaySubMchInfos?: { [index: string]: ShengPaySubMchInfoDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetPaymentSubMchInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPaymentSharingInfosRes extends BaseResponse
{
    public total?: number;
    public data?: PaymentSharingInfoDTO[];
    public orderData?: { [index: string]: OrderInfoDTO; };
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public wechatUserData?: { [index: string]: WeChatUserDTO; };
    public userNames?: { [index: number]: string; };

    public constructor(init?: Partial<GetPaymentSharingInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSubMchDrawInfosRes extends BaseResponse
{
    public total?: number;
    public data?: SubMchDrawInfoDTO[];

    public constructor(init?: Partial<GetSubMchDrawInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSubMchAccountHistoriesRes extends BaseResponse
{
    public total?: number;
    public data?: SubMchAccountHistoryDTO[];

    public constructor(init?: Partial<GetSubMchAccountHistoriesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetRefundOrderInfosRes extends BaseResponse
{
    public total?: number;
    public data?: RefundOrderInfoDTO[];

    public constructor(init?: Partial<GetRefundOrderInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetQRCodeLoginRes extends CommonReturn
{
    public ticket?: string;
    public qrCode?: string;

    public constructor(init?: Partial<GetQRCodeLoginRes>) { super(init); (Object as any).assign(this, init); }
}

export class CheckQRCodeTicketRes extends CommonReturn
{
    public status?: number;
    public isConfirm?: boolean;
    public userName?: string;
    public avatarUrl?: string;
    public otp?: string;
    public schoolId?: string;

    public constructor(init?: Partial<CheckQRCodeTicketRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolNoticesRes extends CommonReturn
{
    public data?: SchoolNotice[];

    public constructor(init?: Partial<GetSchoolNoticesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerSchoolInfosRes extends BaseResponse
{
    public data?: SchoolNameDTO[];

    public constructor(init?: Partial<GetSchoolPartnerSchoolInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerSchoolInfosByPermissionRes extends BaseResponse
{
    public data?: SchoolNameDTO[];

    public constructor(init?: Partial<GetSchoolPartnerSchoolInfosByPermissionRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerTeachersRes extends BaseResponse
{
    public data?: TeacherInfoDTO[];

    public constructor(init?: Partial<GetSchoolPartnerTeachersRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerKidsRes extends BaseResponse
{
    public total?: number;
    public data?: BaseKidInfoDTO[];
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetSchoolPartnerKidsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerClassInfosRes extends BaseResponse
{
    public total?: number;
    public data?: ClassInfoDTO[];
    public courseNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetSchoolPartnerClassInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerCoursesRes extends BaseResponse
{
    public total?: number;
    public data?: CourseItem[];

    public constructor(init?: Partial<GetSchoolPartnerCoursesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerUserRes extends BaseResponse
{
    public data?: SchoolPartnerUserDTO;
    public partnerInfo?: SchoolPartnerInfoDTO;
    public permissions?: { [index: string]: SchoolPartnerPermissionConfigDTO; };

    public constructor(init?: Partial<GetSchoolPartnerUserRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerUsersRes extends BaseResponse
{
    public data?: SchoolPartnerUserDTO[];
    public users?: { [index: number]: UserAuthDTO; };
    public permissions?: { [index: string]: SchoolPartnerPermissionConfigDTO; };

    public constructor(init?: Partial<GetSchoolPartnerUsersRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerSettingRes extends BaseResponse
{
    public data?: SchoolPartnerSettingDTO;

    public constructor(init?: Partial<GetSchoolPartnerSettingRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerSalesLeadsRes extends BaseResponse
{
    public total?: number;
    public data?: SchoolPartnerSalesLeadDTO[];
    public partnerUsers?: { [index: number]: SchoolPartnerUserBaseDTO; };
    public followUpInfos?: { [index: string]: SchoolPartnerFollowUpInfoDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetSchoolPartnerSalesLeadsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerSalesLeadRes extends BaseResponse
{
    public data?: SchoolPartnerSalesLeadDTO;
    public partnerUsers?: { [index: number]: SchoolPartnerUserBaseDTO; };
    public followUpInfos?: { [index: string]: SchoolPartnerFollowUpInfoDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetSchoolPartnerSalesLeadRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerFollowUpInfosRes extends BaseResponse
{
    public total?: number;
    public data?: SchoolPartnerFollowUpInfoDTO[];
    public partnerUsers?: { [index: number]: SchoolPartnerUserBaseDTO; };
    public salesLeads?: { [index: string]: SchoolPartnerSalesLeadDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetSchoolPartnerFollowUpInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerFollowUpInfoRes extends BaseResponse
{
    public data?: SchoolPartnerFollowUpInfoDTO;
    public partnerUsers?: { [index: number]: SchoolPartnerUserBaseDTO; };
    public salesLead?: SchoolPartnerSalesLeadDTO;
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetSchoolPartnerFollowUpInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetRolesReq extends BaseResponse
{
    public data?: RoleItemDTO[];

    public constructor(init?: Partial<GetRolesReq>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidParentsByKidIdRes extends BaseResponse
{
    public studentdata?: StudentBaseInfoDTO;
    public data?: ParentRelationClassInfoDTO[];

    public constructor(init?: Partial<GetKidParentsByKidIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolClassInfosV2Res extends BaseResponse
{
    public data?: ClassInfoDTO[];
    public schoolInfos?: { [index: string]: SchoolInfoDTO; };
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public teacherData?: { [index: string]: TeacherInfoDTO; };
    public parentData?: { [index: string]: ParentRelationClassInfoDTO; };

    public constructor(init?: Partial<GetSchoolClassInfosV2Res>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolClassInfosByPermissionRes extends BaseResponse
{
    public data?: ClassInfoDTO[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolClassInfosByPermissionRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolClassInfosRes
{
    public data?: ClassInfoItemDTO[];

    public constructor(init?: Partial<GetSchoolClassInfosRes>) { (Object as any).assign(this, init); }
}

export class GetRegLinkRes
{
    public ret?: boolean;
    public message?: string;
    public regurl?: string;

    public constructor(init?: Partial<GetRegLinkRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolUsersRoleRes
{
    public data?: SchoolUserRoleItem[];

    public constructor(init?: Partial<GetSchoolUsersRoleRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolMedicCaresRes extends BaseResponse
{
    public data?: MedicCareDTO[];
    public ossInfo?: { [index: string]: OssInfoDTO; };
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };
    public classInfo?: { [index: string]: ClassBaseInfoDTO; };
    public parentRole?: { [index: string]: string; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolMedicCaresRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolMiniSiteRes extends BaseResponse
{
    public data?: SchoolSiteDTO;

    public constructor(init?: Partial<GetSchoolMiniSiteRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveVideoVisitHistoryRes extends BaseResponse
{
    public total?: number;
    public data?: LiveVideoVisitHistoryDTO[];

    public constructor(init?: Partial<GetLiveVideoVisitHistoryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolChildOrgInfosRes extends BaseResponse
{
    public data?: SchoolOrgInfoDTO[];

    public constructor(init?: Partial<GetSchoolChildOrgInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidsV2Res extends BaseResponse
{
    public totalCount?: number;
    public data?: BaseKidInfoDTO[];
    public parentData?: { [index: string]: ParentRelationClassInfoDTO[]; };

    public constructor(init?: Partial<GetSchoolKidsV2Res>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolTopicTestKeyWordsV2Res extends BaseResponse
{
    public data?: TopicTestSchoolKeyWordV2DTO;
    public countData?: TopicTestInterceptGroupByKeyWordItem[];

    public constructor(init?: Partial<GetSchoolTopicTestKeyWordsV2Res>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolTopicTestKeyWordsRes extends BaseResponse
{
    public data?: TopicTestSchoolKeyWordDTO;
    public countData?: { [index: string]: TopicTestInterceptGroupByKeyWordTypeDTO; };

    public constructor(init?: Partial<GetSchoolTopicTestKeyWordsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTopicTestInterceptLogsRes extends BaseResponse
{
    public total?: number;
    public data?: TopicTestInterceptLogDTO[];
    public userPhoneNames?: { [index: number]: string; };
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };
    public studentInfos?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetTopicTestInterceptLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolAuditLogsRes extends BaseResponse
{
    public total?: number;
    public data?: SchoolAuditLogDTO[];
    public className?: { [index: string]: string; };
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolAuditLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolDishsRes extends BaseResponse
{
    public total?: number;
    public data?: DishItemDTO[];
    public foods?: { [index: string]: IngredientItemDTO; };

    public constructor(init?: Partial<GetSchoolDishsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolIngredientsRes extends BaseResponse
{
    public total?: number;
    public data?: IngredientItemDTO[];

    public constructor(init?: Partial<GetSchoolIngredientsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPatrolAreasRes extends BaseResponse
{
    public total?: number;
    public data?: PatrolAreaDTO[];

    public constructor(init?: Partial<GetPatrolAreasRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPatrolAreaRes extends BaseResponse
{
    public data?: PatrolAreaDTO;

    public constructor(init?: Partial<GetPatrolAreaRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPatrolAreaItemsRes extends BaseResponse
{
    public total?: number;
    public data?: PatrolAreaItemDTO[];
    public area?: { [index: string]: PatrolAreaDTO; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetPatrolAreaItemsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPatrolAreaItemRes extends BaseResponse
{
    public data?: PatrolAreaItemDTO;
    public area?: { [index: string]: PatrolAreaDTO; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetPatrolAreaItemRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPatrolCheckLogsRes extends BaseResponse
{
    public total?: number;
    public data?: PatrolCheckLogDTO[];
    public area?: { [index: string]: PatrolAreaDTO; };
    public areaItem?: { [index: string]: PatrolAreaItemDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetPatrolCheckLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPatrolCheckLogRes extends BaseResponse
{
    public data?: PatrolCheckLogDTO;
    public area?: { [index: string]: PatrolAreaDTO; };
    public areaItem?: { [index: string]: PatrolAreaItemDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetPatrolCheckLogRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpeakingArtCatalogsRes extends BaseResponse
{
    public total?: number;
    public data?: SpeakingArtCatalogDTO[];
    public summary?: { [index: string]: number; };

    public constructor(init?: Partial<GetSpeakingArtCatalogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpeakingArtItemsRes extends BaseResponse
{
    public total?: number;
    public data?: SpeakingArtItemDTO[];
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetSpeakingArtItemsRes>) { super(init); (Object as any).assign(this, init); }
}

export class FillEvaluateInitRes
{
    public ret?: boolean;
    public message?: string;
    public data?: EvaluateDTO;

    public constructor(init?: Partial<FillEvaluateInitRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolEvaluateTemplatesRes extends BaseResponse
{
    public data?: EvaluateTemplateDTO[];

    public constructor(init?: Partial<GetSchoolEvaluateTemplatesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetEvaluateTemplatesRes
{
    public ret?: boolean;
    public message?: string;
    public data?: EvaluateItemBaseDTO[];

    public constructor(init?: Partial<GetEvaluateTemplatesRes>) { (Object as any).assign(this, init); }
}

export class GetTeacherAllSchoolBaseInfoRes
{
    public schoolInfos?: SchoolBaseInfo[];

    public constructor(init?: Partial<GetTeacherAllSchoolBaseInfoRes>) { (Object as any).assign(this, init); }
}

export class GetTeacherClassesV2Res extends BaseResponse
{
    public data?: ClassInfoDTO[];
    public schoolInfos?: { [index: string]: SchoolInfoDTO; };
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public teacherData?: { [index: string]: TeacherInfoDTO; };

    public constructor(init?: Partial<GetTeacherClassesV2Res>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherClassesRes
{
    public data?: ClassInfoItemDTO[];
    public kidApplys?: KidApplyInfoDTO[];

    public constructor(init?: Partial<GetTeacherClassesRes>) { (Object as any).assign(this, init); }
}

export class GetChartSummaryRes
{
    public ret?: boolean;
    public message?: string;
    public stringValues?: string[];
    public reportUrl?: string;

    public constructor(init?: Partial<GetChartSummaryRes>) { (Object as any).assign(this, init); }
}

export class GetChartRes
{
    public ret?: boolean;
    public message?: string;
    public data?: ChartData;

    public constructor(init?: Partial<GetChartRes>) { (Object as any).assign(this, init); }
}

export class CreateClassInfoRes
{
    public ret?: boolean;
    public responseStatus?: ResponseStatus;
    public message?: string;
    public classId?: string;
    public className?: string;
    public logoId?: number;
    public schoolName?: string;

    public constructor(init?: Partial<CreateClassInfoRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolTeacherHandoverInfoRes extends BaseResponse
{
    public data?: { [index: string]: number; };

    public constructor(init?: Partial<GetSchoolTeacherHandoverInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class CreateSchoolTeacherRes
{
    public ret?: boolean;
    public responseStatus?: ResponseStatus;
    public message?: string;
    public userName?: string;
    public fullName?: string;
    public profileId?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<CreateSchoolTeacherRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolTeachersRes
{
    public data?: TeacherInfoDTO[];

    public constructor(init?: Partial<GetSchoolTeachersRes>) { (Object as any).assign(this, init); }
}

export class InitDataRes
{
    public ret?: boolean;
    public message?: string;

    public constructor(init?: Partial<InitDataRes>) { (Object as any).assign(this, init); }
}

export class CreateSchoolInfoRes
{
    public ret?: boolean;
    public responseStatus?: ResponseStatus;
    public message?: string;
    public schoolId?: string;
    public schoolName?: string;

    public constructor(init?: Partial<CreateSchoolInfoRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolInfoRes extends CommonReturn
{
    public data?: SchoolInfoDTO;

    public constructor(init?: Partial<GetSchoolInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolInfosRes extends CommonReturn
{
    public data?: SchoolInfoDTO[];

    public constructor(init?: Partial<GetSchoolInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidDailyHealthiesRes extends BaseResponse
{
    public data?: DailyHealthyDTO[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public teacherData?: { [index: string]: TeacherBaseInfoDTO; };
    public classData?: { [index: string]: ClassBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolKidDailyHealthiesRes>) { super(init); (Object as any).assign(this, init); }
}

export class SetKidDailyHealthyRes extends BaseResponse
{

    public constructor(init?: Partial<SetKidDailyHealthyRes>) { super(init); (Object as any).assign(this, init); }
}

export class SetKidDailyCareRes extends BaseResponse
{

    public constructor(init?: Partial<SetKidDailyCareRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyMenuRes extends BaseResponse
{
    public data?: DailyMenuDTO;
    public nutritions?: Nutrition;
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public dishs?: { [index: string]: DishItemDTO; };
    public foods?: { [index: string]: IngredientItemDTO; };

    public constructor(init?: Partial<GetDailyMenuRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyMenusRes extends BaseResponse
{
    public data?: DailyMenuDTO[];
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public dishs?: { [index: string]: DishItemDTO; };
    public foods?: { [index: string]: IngredientItemDTO; };

    public constructor(init?: Partial<GetDailyMenusRes>) { super(init); (Object as any).assign(this, init); }
}

export class CreateDailyMenuRes extends BaseResponse
{

    public constructor(init?: Partial<CreateDailyMenuRes>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateDailyMenuRes extends BaseResponse
{

    public constructor(init?: Partial<UpdateDailyMenuRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolDailyMenusWeeklyRes extends BaseResponse
{
    public data?: DishMenuWeeklyDTO[];
    public nutritions?: Nutrition;
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };
    public dishs?: { [index: string]: DishItemDTO; };
    public foods?: { [index: string]: IngredientItemDTO; };

    public constructor(init?: Partial<GetSchoolDailyMenusWeeklyRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolSettingRes extends BaseResponse
{
    public data?: SchoolSettingDTO;

    public constructor(init?: Partial<GetSchoolSettingRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherAppMenuRes extends BaseResponse
{
    public data?: TeacherAppMenuDTO[];
    public customIconPermissionCode?: string[];

    public constructor(init?: Partial<GetTeacherAppMenuRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolConfigJobTitleRes extends BaseResponse
{
    public data?: string[];

    public constructor(init?: Partial<GetSchoolConfigJobTitleRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClearParentInfoRes extends BaseResponse
{
    public primaryContact?: ParentInfoDTO;
    public sencondaryContact?: ParentInfoDTO;
    public parentData?: ParentRelationClassInfoDTO[];

    public constructor(init?: Partial<GetClearParentInfoRes>) { super(init); (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId?: string;

    // @DataMember(Order=2)
    public sessionId?: string;

    // @DataMember(Order=3)
    public userName?: string;

    // @DataMember(Order=4)
    public displayName?: string;

    // @DataMember(Order=5)
    public referrerUrl?: string;

    // @DataMember(Order=6)
    public bearerToken?: string;

    // @DataMember(Order=7)
    public refreshToken?: string;

    // @DataMember(Order=8)
    public profileUrl?: string;

    // @DataMember(Order=9)
    public roles?: string[];

    // @DataMember(Order=10)
    public permissions?: string[];

    // @DataMember(Order=11)
    public responseStatus?: ResponseStatus;

    // @DataMember(Order=12)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

export class GetMyWorkTimeRes
{
    public ret?: boolean;
    public isset?: boolean;
    public data?: WorkTime;

    public constructor(init?: Partial<GetMyWorkTimeRes>) { (Object as any).assign(this, init); }
}

export class GetTeacherMyUserInfoRes
{
    public ret?: boolean;
    public data?: TeacherMyUserInfo;
    public termInfos?: TermInfoItem[];
    public schoolConfigs?: { [index: string]: Object; };

    public constructor(init?: Partial<GetTeacherMyUserInfoRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolNoticeFeedsRes extends CommonReturn
{
    public data?: SchoolNoticeFeed[];

    public constructor(init?: Partial<GetSchoolNoticeFeedsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKGLibIndexRes
{
    public data?: KGLibIndexItemSimple[];

    public constructor(init?: Partial<GetKGLibIndexRes>) { (Object as any).assign(this, init); }
}

export class GetKGLibsRes
{
    public data?: KGLibItemDTO[];

    public constructor(init?: Partial<GetKGLibsRes>) { (Object as any).assign(this, init); }
}

export class GetClassEventForTeacherRes
{
    public ret?: boolean;
    public message?: string;
    public data?: ClassEventItemForTeacher;

    public constructor(init?: Partial<GetClassEventForTeacherRes>) { (Object as any).assign(this, init); }
}

export class GetClassEventTrendRes
{
    public ret?: boolean;
    public message?: string;
    public data?: ClassEventTrendInfo;

    public constructor(init?: Partial<GetClassEventTrendRes>) { (Object as any).assign(this, init); }
}

export class GetClassEventsForTeacherRes
{
    public classId?: string;
    public totalcount?: number;
    public data?: ClassEventItemForTeacher[];

    public constructor(init?: Partial<GetClassEventsForTeacherRes>) { (Object as any).assign(this, init); }
}

export class GetEventAiInfoRes extends BaseResponse
{
    public aiOssInfos?: { [index: string]: string; };
    public studentinfos?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetEventAiInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMultiClassEventsForTeacherV2Res extends BaseResponse
{
    public data?: EventBaseDTO[];
    public ossinfos?: { [index: string]: OssInfoDTO; };
    public classinfos?: { [index: string]: KidClassInfoDTO; };
    public teacherinfos?: { [index: string]: TeacherBaseInfoDTO; };
    public parentinfos?: { [index: string]: ParentRelationSchoolInfoDTO; };
    public studentinfos?: { [index: string]: StudentBaseInfoDTO; };
    public kidusermaps?: { [index: string]: KidEntityUserAuthMapDTO; };
    public likeinfos?: { [index: string]: EventLikeUserDTO[]; };
    public commentinfos?: { [index: string]: EventItemCommentItemDTO[]; };
    public themeinfos?: { [index: string]: TeachingThemeDTO; };
    public trendInfos?: { [index: string]: ClassEventTrendInfoV2; };
    public classSemesterTypes?: { [index: string]: { [index:number]: SEMESTERTYPE; }; };

    public constructor(init?: Partial<GetMultiClassEventsForTeacherV2Res>) { super(init); (Object as any).assign(this, init); }
}

export class GetMultiClassEventsForTeacherRes
{
    public ret?: boolean;
    public message?: string;
    public data?: ClassEventItemForTeacher[];

    public constructor(init?: Partial<GetMultiClassEventsForTeacherRes>) { (Object as any).assign(this, init); }
}

export class UpdateEventCommonRes extends CommonReturn
{
    public data?: ClassEventItem;

    public constructor(init?: Partial<UpdateEventCommonRes>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateEventKGTimeRes extends CommonReturn
{
    public data?: ClassEventKGTimeDTO;

    public constructor(init?: Partial<UpdateEventKGTimeRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassEventsFeedRes
{
    public ret?: boolean;
    public message?: string;
    public data?: EventFeedDTO[];

    public constructor(init?: Partial<GetClassEventsFeedRes>) { (Object as any).assign(this, init); }
}

export class TeachingThemeItemDTO
{
    public id?: string;
    public classId?: string;
    public schoolId?: string;
    public title?: string;
    public content?: string;
    public createUserName?: string;
    public createOn?: string;
    public eventsCount?: number;
    public semesterType?: SEMESTERTYPE;
    public semesterStr?: string;

    public constructor(init?: Partial<TeachingThemeItemDTO>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TeachingThemeItemDTO'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetTeachingThemeRes
{
    public data?: TeachingThemeItemDTO[];

    public constructor(init?: Partial<GetTeachingThemeRes>) { (Object as any).assign(this, init); }
}

export class GetEventTemplateCatalogRes
{
    public ret?: boolean;
    public message?: string;
    public data?: EventTemplateCatalogDTO[];

    public constructor(init?: Partial<GetEventTemplateCatalogRes>) { (Object as any).assign(this, init); }
}

export class GetEventTemplatesRes
{
    public ret?: boolean;
    public message?: string;
    public data?: EventTemplateRespDTO;

    public constructor(init?: Partial<GetEventTemplatesRes>) { (Object as any).assign(this, init); }
}

export class EventTemplateItem
{
    public id?: string;
    public title?: string;
    public content?: string;
    public useCount?: number;
    public tags?: string[];
    public imageUri?: string;
    public shareUrl?: string;
    public attachItem?: EventAttachItem;
    public contentExs?: KeyValuePair<string,string>[];

    public constructor(init?: Partial<EventTemplateItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventTemplateItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetEventTemplatesSourceRes
{
    public ret?: boolean;
    public message?: string;
    public data?: EventTemplateItem[];

    public constructor(init?: Partial<GetEventTemplatesSourceRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolGrowBookConfigRes extends BaseResponse
{
    public schoolQRCode?: string;
    public schoolIntro?: SchoolPageExtendDTO;
    public schoolPageExtends?: SchoolPageExtendDTO[];

    public constructor(init?: Partial<GetSchoolGrowBookConfigRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetGrowBooksRes
{
    public ret?: boolean;
    public message?: string;
    public data?: GrowBookSummaryDTO;

    public constructor(init?: Partial<GetGrowBooksRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolGrowBooksRes
{
    public ret?: boolean;
    public message?: string;
    public data?: GrowBookClassSummaryDTO[];

    public constructor(init?: Partial<GetSchoolGrowBooksRes>) { (Object as any).assign(this, init); }
}

export class UpdateClassNameRes
{
    public ret?: boolean;
    public message?: string;
    public responseStatus?: ResponseStatus;
    public data?: ClassInfoItemDTO;

    public constructor(init?: Partial<UpdateClassNameRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolPartnerTeacherStudyCatalogsRes extends BaseResponse
{
    public data?: SchoolPartnerTeacherStudyCatalogDTO[];

    public constructor(init?: Partial<GetSchoolPartnerTeacherStudyCatalogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolPartnerTeacherStudyItemsRes extends BaseResponse
{
    public total?: number;
    public data?: SchoolPartnerTeacherStudyItemDTO[];
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetSchoolPartnerTeacherStudyItemsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherStudyCatalogsRes extends BaseResponse
{
    public data?: TeacherStudyCatalogDTO[];
    public waterMaskConfig?: { [index: string]: Object; };

    public constructor(init?: Partial<GetTeacherStudyCatalogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherStudyItemFeedsRes extends BaseResponse
{
    public data?: TeacherStudyItemFeedDTO[];

    public constructor(init?: Partial<GetTeacherStudyItemFeedsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherStudyItemsRes extends BaseResponse
{
    public total?: number;
    public data?: TeacherStudyItemDTO[];
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };
    public ossInfos?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetTeacherStudyItemsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherStudyCommentItemsRes extends BaseResponse
{
    public data?: TeacherStudyCommentItemDTO[];
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetTeacherStudyCommentItemsRes>) { super(init); (Object as any).assign(this, init); }
}

export class TvGetLoginCodeRes extends BaseResponse
{
    public qrCode?: string;
    public ticket?: string;

    public constructor(init?: Partial<TvGetLoginCodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class TvCheckLoginTicketRes extends BaseResponse
{
    public status?: number;
    public isConfirm?: boolean;
    public userName?: string;
    public avatarUrl?: string;
    public token?: string;
    public refreshToken?: string;
    public schoolLogoUrl?: string;
    public schoolId?: string;
    public schoolName?: string;
    public otpKey?: string;

    public constructor(init?: Partial<TvCheckLoginTicketRes>) { super(init); (Object as any).assign(this, init); }
}

export class TvDeviceLoginByManageRes extends BaseResponse
{

    public constructor(init?: Partial<TvDeviceLoginByManageRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolTVDeviceRes extends BaseResponse
{
    public name?: string;
    public logoUrl?: string;
    public schoolImageUrl?: string;
    public desc?: string;
    public schoolType?: string;
    public province?: string;
    public city?: string;
    public district?: string;
    public dashboardUrls?: string[];
    public slideImages?: string[];
    public notices?: string[];

    public constructor(init?: Partial<GetSchoolTVDeviceRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidAttendanceInfoRes extends BaseResponse
{
    public totalCapacity?: number;
    public totalKidCount?: number;
    public totalFreeCount?: number;
    public classData?: SchoolKidAttendanceClassInfo[];

    public constructor(init?: Partial<GetSchoolKidAttendanceInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidAttendanceBindsRes extends BaseResponse
{
    public data?: KidAttendanceBindItem[];
    public myParentRole?: string;

    public constructor(init?: Partial<GetKidAttendanceBindsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetParentKidsRes
{
    public ret?: boolean;
    public kidList?: KidBaseInfoDTO[];

    public constructor(init?: Partial<GetParentKidsRes>) { (Object as any).assign(this, init); }
}

export class GetParentMyClassInfoRes
{
    public ret?: boolean;
    public userId?: number;
    public userProfileId?: string;
    public data?: KidBaseInfoDTO;

    public constructor(init?: Partial<GetParentMyClassInfoRes>) { (Object as any).assign(this, init); }
}

export class GetParentMyUserInfoRes
{
    public ret?: boolean;
    public data?: ParentMyUserInfo;
    public termInfos?: TermInfoItem[];
    public schoolConfigs?: { [index: string]: Object; };

    public constructor(init?: Partial<GetParentMyUserInfoRes>) { (Object as any).assign(this, init); }
}

export class GetRegInfoFromTokenRes
{
    public ret?: boolean;
    public message?: string;
    public logoUrl?: string;
    public classId?: string;
    public className?: string;
    public teacherName?: string;
    public schoolName?: string;

    public constructor(init?: Partial<GetRegInfoFromTokenRes>) { (Object as any).assign(this, init); }
}

export class GetParentClassesRes
{
    public ret?: boolean;
    public data?: ClassInfoItemDTO;

    public constructor(init?: Partial<GetParentClassesRes>) { (Object as any).assign(this, init); }
}

export class AddKidRemarkCommentRes extends CommonReturn
{
    public data?: KidRemarkDTO;

    public constructor(init?: Partial<AddKidRemarkCommentRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassEventRes
{
    public ret?: boolean;
    public message?: string;
    public data?: ClassEventItem;

    public constructor(init?: Partial<GetClassEventRes>) { (Object as any).assign(this, init); }
}

export class GetMultiClassEventsForParentV2Res extends BaseResponse
{
    public data?: EventBaseDTO[];
    public payedEventIds?: string[];
    public ossinfos?: { [index: string]: OssInfoDTO; };
    public classinfos?: { [index: string]: KidClassInfoDTO; };
    public teacherinfos?: { [index: string]: TeacherBaseInfoDTO; };
    public parentinfos?: { [index: string]: ParentRelationSchoolInfoDTO; };
    public studentinfos?: { [index: string]: StudentBaseInfoDTO; };
    public kidusermaps?: { [index: string]: KidEntityUserAuthMapDTO; };
    public likeinfos?: { [index: string]: EventLikeUserDTO[]; };
    public commentinfos?: { [index: string]: EventItemCommentItemDTO[]; };
    public themeinfos?: { [index: string]: TeachingThemeDTO; };
    public aiOssInfos?: string[];

    public constructor(init?: Partial<GetMultiClassEventsForParentV2Res>) { super(init); (Object as any).assign(this, init); }
}

export class GetMultiClassEventsForParentRes
{
    public ret?: boolean;
    public message?: string;
    public data?: ClassEventItem[];

    public constructor(init?: Partial<GetMultiClassEventsForParentRes>) { (Object as any).assign(this, init); }
}

export class AnswerEventVoteRes extends CommonReturn
{
    public data?: ClassEventItem;

    public constructor(init?: Partial<AnswerEventVoteRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddEventNotifyFeedbackRes extends CommonReturn
{
    public data?: ClassEventItem;

    public constructor(init?: Partial<AddEventNotifyFeedbackRes>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateEventHomeTimeRes extends CommonReturn
{
    public data?: ClassEventItem;

    public constructor(init?: Partial<UpdateEventHomeTimeRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidParentsRes extends BaseResponse
{
    public data?: KidEntityUserAuthMapDTO[];

    public constructor(init?: Partial<GetKidParentsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidEventTinyShareLinkRes extends BaseResponse
{
    public url?: string;

    public constructor(init?: Partial<GetKidEventTinyShareLinkRes>) { super(init); (Object as any).assign(this, init); }
}

export class CheckBindRes extends BaseResponse
{
    public kiddata?: BindKidItem[];
    public kidentitydata?: KidEntityItem[];

    public constructor(init?: Partial<CheckBindRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDailyHealthyRes extends BaseResponse
{
    public data?: DailyHealthyDTO;
    public kidInfo?: StudentBaseInfoDTO;

    public constructor(init?: Partial<GetDailyHealthyRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidAttendanceFromParentRes extends BaseResponse
{
    public data?: AttendanceKidLogDTO[];

    public constructor(init?: Partial<GetKidAttendanceFromParentRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPaymentContentInfoRes extends BaseResponse
{
    public isPayed?: boolean;
    public courseId?: string;
    public courseName?: string;
    public price?: number;
    public balance?: number;

    public constructor(init?: Partial<GetPaymentContentInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetParentClassLiveVideosRes extends BaseResponse
{
    public data?: LiveVideoItem[];
    public visitHistory?: LiveVideoVisitHistory[];
    public memberinfo?: LiveMemberDTO;
    public scheduleconfig?: LiveScheduleItemDTO[];
    public notinschedule?: boolean;
    public payByWxLite?: boolean;

    public constructor(init?: Partial<GetParentClassLiveVideosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMedicCaresRes extends BaseResponse
{
    public data?: MedicCareDTO[];
    public ossInfo?: { [index: string]: OssInfoDTO; };
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };
    public parentRole?: { [index: string]: string; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetMedicCaresRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetExtrabookItemsRes
{
    public ret?: boolean;
    public message?: string;
    public albumUrl?: string;
    public data?: BookExtraDTO[];

    public constructor(init?: Partial<GetExtrabookItemsRes>) { (Object as any).assign(this, init); }
}

export class GetParentIMCatalogRes extends BaseResponse
{
    public data?: IMCatalogItem[];
    public isGlobalMute?: boolean;
    public muteConvIds?: string[];

    public constructor(init?: Partial<GetParentIMCatalogRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetBookOrdersRes
{
    public totalCount?: number;
    public data?: BookOrderItemDTO[];

    public constructor(init?: Partial<GetBookOrdersRes>) { (Object as any).assign(this, init); }
}

export class GetPdfUrlRes
{
    public ret?: boolean;
    public data?: BookPdfInfo;

    public constructor(init?: Partial<GetPdfUrlRes>) { (Object as any).assign(this, init); }
}

export class PrepareBookOrderRes
{
    public ret?: boolean;
    public message?: string;
    public needPrepare?: boolean;
    public pageCount?: number;
    public pageMin?: number;
    public pageMax?: number;
    public data?: BookOrderConfigItemDTO[];

    public constructor(init?: Partial<PrepareBookOrderRes>) { (Object as any).assign(this, init); }
}

export class CreateBookOrderRes
{
    public ret?: boolean;
    public message?: string;
    public shopurl?: string;
    public miniProgramType?: number;
    public isH5?: boolean;
    public noticeMessage?: string;
    public shopCode?: string;

    public constructor(init?: Partial<CreateBookOrderRes>) { (Object as any).assign(this, init); }
}

export class GetOrderProductsRes extends BaseResponse
{
    public data?: OrderProductDTO[];

    public constructor(init?: Partial<GetOrderProductsRes>) { super(init); (Object as any).assign(this, init); }
}

export class PrePayWxliteRes extends BaseResponse
{
    public wxCode?: string;

    public constructor(init?: Partial<PrePayWxliteRes>) { super(init); (Object as any).assign(this, init); }
}

export class PrePayWxliteProcessRes extends BaseResponse
{
    public data?: ShengPayPreOrderDTO;

    public constructor(init?: Partial<PrePayWxliteProcessRes>) { super(init); (Object as any).assign(this, init); }
}

export class PrePayWechatRes extends BaseResponse
{
    public data?: PrePayWechatData;

    public constructor(init?: Partial<PrePayWechatRes>) { super(init); (Object as any).assign(this, init); }
}

export class WatchGetDeviceInfoRes extends BaseResponse
{
    public isBindWatch?: boolean;
    public watchMacAddr?: string;
    public steps?: number;
    public distances?: number;
    public calories?: number;

    public constructor(init?: Partial<WatchGetDeviceInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetWatchInfosRes extends BaseResponse
{
    public total?: number;
    public data?: WatchInfoDTO[];
    public classNameData?: { [index: string]: string; };
    public studentData?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetWatchInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class WatchGetReportDataRes extends BaseResponse
{
    public data?: WatchReportDataItem[];

    public constructor(init?: Partial<WatchGetReportDataRes>) { super(init); (Object as any).assign(this, init); }
}

export class WatchMacVerifyRes extends BaseResponse
{
    public avaliableWatchs?: string[];

    public constructor(init?: Partial<WatchMacVerifyRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassTimesForParentRes extends BaseResponse
{
    public data?: ClassTimeDTO[];
    public courseData?: { [index: string]: string; };
    public classRoomData?: { [index: string]: string; };
    public classData?: { [index: string]: ClassBaseInfoDTO; };

    public constructor(init?: Partial<GetClassTimesForParentRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidCourseBalanceRes extends BaseResponse
{
    public amount?: number;
    public data?: KidCourseBalanceItem[];
    public courseData?: { [index: string]: string; };

    public constructor(init?: Partial<GetKidCourseBalanceRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolNameRes extends CommonReturn
{
    public data?: SchoolNameDTO;

    public constructor(init?: Partial<GetSchoolNameRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetWechatComponentAppIdBySchoolRes extends BaseResponse
{
    public component_appid?: string;
    public authorizer_appid?: string;
    public isShareMP?: boolean;
    public mpNickName?: string;
    public qrCodeUrl?: string;
    public headImageUrl?: string;
    public memberSiteUrl?: string;
    public mpBindUrl?: string;

    public constructor(init?: Partial<GetWechatComponentAppIdBySchoolRes>) { super(init); (Object as any).assign(this, init); }
}

export class WechatComponentCheckTemplateRes extends BaseResponse
{
    public templateStatus?: WechatComponentTemplateItem[];

    public constructor(init?: Partial<WechatComponentCheckTemplateRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidGrowInfoRes extends BaseResponse
{
    public gender?: GENDERTYPE;
    public heightItems?: GetKidGrowInfoItem[];
    public weightItems?: GetKidGrowInfoItem[];
    public headItems?: GetKidGrowInfoItem[];

    public constructor(init?: Partial<GetKidGrowInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddAttendanceCardPreRes extends BaseResponse
{
    public totalCount?: number;
    public validCount?: number;
    public invalidCount?: number;
    public conflictCount?: number;
    public data?: { [index: string]: string; };

    public constructor(init?: Partial<AddAttendanceCardPreRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddAttendanceRepoRes extends BaseResponse
{
    public success_count?: number;

    public constructor(init?: Partial<AddAttendanceRepoRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetAttendanceDeviceRes extends BaseResponse
{
    public data?: AttendanceDeviceDTO[];
    public childOrgInfo?: { [index: string]: string; };
    public doorInfo?: { [index: string]: string; };

    public constructor(init?: Partial<GetAttendanceDeviceRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetAttendanceDoorControllerRes extends BaseResponse
{
    public data?: AttendanceDoorControllerDTO[];

    public constructor(init?: Partial<GetAttendanceDoorControllerRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetAttendanceDoorRes extends BaseResponse
{
    public data?: AttendanceDoorDTO[];

    public constructor(init?: Partial<GetAttendanceDoorRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetExtrabookConfigsRes
{
    public ret?: boolean;
    public message?: string;
    public data?: BookExtraConfigDTO[];

    public constructor(init?: Partial<GetExtrabookConfigsRes>) { (Object as any).assign(this, init); }
}

export class GetGrowBookConfigsRes
{
    public ret?: boolean;
    public message?: string;
    public data?: GrowBookConfigDTO[];

    public constructor(init?: Partial<GetGrowBookConfigsRes>) { (Object as any).assign(this, init); }
}

export class AirIotGetDeviceRes extends BaseResponse
{
    public totalSize?: number;
    public data?: AirIotDeviceDTO[];
    public classBinds?: AirIotDeviceMapDTO[];
    public childOrgInfo?: { [index: string]: string; };

    public constructor(init?: Partial<AirIotGetDeviceRes>) { super(init); (Object as any).assign(this, init); }
}

export class AirIotParentGetDataSummaryRes extends BaseResponse
{
    public data?: AirIotDataSummaryItem[];

    public constructor(init?: Partial<AirIotParentGetDataSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class AirIotParentGetDataRes extends BaseResponse
{
    public outsideHumidity?: number;
    public outsidePressure?: number;
    public outsideTemperature?: number;
    public outsidePm25?: number;
    public indoorData?: AirIotDataItem[];

    public constructor(init?: Partial<AirIotParentGetDataRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddLiveNodeRes extends BaseResponse
{

    public constructor(init?: Partial<AddLiveNodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveNodeRes extends BaseResponse
{
    public data?: LiveNodeDTO[];
    public childOrgInfo?: { [index: string]: string; };

    public constructor(init?: Partial<GetLiveNodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddLiveChannelRes extends BaseResponse
{

    public constructor(init?: Partial<AddLiveChannelRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveChannelsRes extends BaseResponse
{
    public data?: LiveChannelDTO[];
    public childOrgInfo?: { [index: string]: string; };
    public classInfos?: ClassInfoDTO[];

    public constructor(init?: Partial<GetLiveChannelsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveSchedulesRes extends BaseResponse
{
    public data?: LiveScheduleDTO[];

    public constructor(init?: Partial<GetLiveSchedulesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveScheduleConfigRes extends BaseResponse
{
    public data?: LiveScheduleConfigDTO;

    public constructor(init?: Partial<GetLiveScheduleConfigRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetClassLiveScheduleRes extends BaseResponse
{
    public data?: LiveScheduleItemDTO[];

    public constructor(init?: Partial<GetClassLiveScheduleRes>) { super(init); (Object as any).assign(this, init); }
}

export class ParseSchoolFromExcelRes extends BaseResponse
{
    public data?: ExcelParserSheet[];

    public constructor(init?: Partial<ParseSchoolFromExcelRes>) { super(init); (Object as any).assign(this, init); }
}

export class ParseSchoolFromJsonRes extends BaseResponse
{
    public data?: ParseFromJsonResult[];

    public constructor(init?: Partial<ParseSchoolFromJsonRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPermissionRes extends BaseResponse
{
    public data?: PermissionItem[];

    public constructor(init?: Partial<GetPermissionRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetManageSiteMenuRes extends BaseResponse
{
    public data?: MenuDataItem[];

    public constructor(init?: Partial<GetManageSiteMenuRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSystemUsersRes extends BaseResponse
{
    public data?: SystemUserDTO[];

    public constructor(init?: Partial<GetSystemUsersRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSystemServiceModuleConfigsRes extends BaseResponse
{
    public data?: SystemServiceModuleConfigDTO[];

    public constructor(init?: Partial<GetSystemServiceModuleConfigsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSystemServiceSubModuleConfigsRes extends BaseResponse
{
    public data?: SystemServiceSubModuleConfigDTO[];

    public constructor(init?: Partial<GetSystemServiceSubModuleConfigsRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddSystemServiceSubModuleConfigsRes extends BaseResponse
{
    public id?: string;

    public constructor(init?: Partial<AddSystemServiceSubModuleConfigsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetIMServiceAccountSignRes
{
    public ret?: boolean;
    public message?: string;
    public rongCloudUserId?: string;
    public rongCloudToken?: string;

    public constructor(init?: Partial<GetIMServiceAccountSignRes>) { (Object as any).assign(this, init); }
}

export class GetVersionInfosRes extends BaseResponse
{
    public data?: VersionInfoDTO[];

    public constructor(init?: Partial<GetVersionInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetEventMsgCardRes extends BaseResponse
{
    public totalCount?: number;
    public data?: EventMsgCardDTO[];
    public schoolNames?: { [index: string]: string; };
    public classNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetEventMsgCardRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherPointTaskConfigsRes extends BaseResponse
{
    public data?: TeacherPointTaskConfigItem[];
    public myFinishedTaskCount?: { [index: string]: number; };

    public constructor(init?: Partial<GetTeacherPointTaskConfigsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherPointByUserIdRes extends BaseResponse
{
    public data?: TeacherPointItem;

    public constructor(init?: Partial<GetTeacherPointByUserIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetTeacherPointLogsByUserIdRes extends BaseResponse
{
    public data?: TeacherPointLogItem[];
    public taskConfigs?: { [index: string]: string; };

    public constructor(init?: Partial<GetTeacherPointLogsByUserIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolContractsRes extends BaseResponse
{
    public total?: number;
    public data?: SchoolContractDTO[];

    public constructor(init?: Partial<GetSchoolContractsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPointShopUrlRes extends BaseResponse
{
    public targetUrl?: string;

    public constructor(init?: Partial<GetPointShopUrlRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetGetTeacherPointSummaryRes extends BaseResponse
{
    public teacherCount?: number;
    public addAmount?: number;
    public useAmount?: number;
    public data?: TeacherPointSummaryDTO[];

    public constructor(init?: Partial<GetGetTeacherPointSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetGetTeacherPointTaskSummaryRes extends BaseResponse
{
    public data?: TeacherPointTaskSummaryDTO[];

    public constructor(init?: Partial<GetGetTeacherPointTaskSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetGetTeacherPointsRes extends BaseResponse
{
    public total?: number;
    public data?: TeacherPointDTO[];
    public teacherNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetGetTeacherPointsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetGetTeacherPointLogsRes extends BaseResponse
{
    public total?: number;
    public data?: TeacherPointLogDTO[];
    public teacherNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetGetTeacherPointLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetGetTeacherPointOrdersRes extends BaseResponse
{
    public total?: number;
    public data?: TeacherPointOrderDTO[];
    public teacherNames?: { [index: string]: string; };

    public constructor(init?: Partial<GetGetTeacherPointOrdersRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetRedDotRes extends BaseResponse
{
    public redItems?: RedDotItem[];

    public constructor(init?: Partial<GetRedDotRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolKidMembersRes extends BaseResponse
{
    public total?: number;
    public data?: KidMemberDTO[];
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };
    public orderInfo?: { [index: string]: OrderInfoDTO; };

    public constructor(init?: Partial<GetSchoolKidMembersRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLikeAndCommentListRes extends BaseResponse
{
    public data?: InBoxEventLikeDTO[];

    public constructor(init?: Partial<GetLikeAndCommentListRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMyTaskListRes extends BaseResponse
{
    public data?: MyTaskItem[];
    public ossInfo?: { [index: string]: OssInfoDTO; };
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };
    public classInfo?: { [index: string]: ClassBaseInfoDTO; };
    public parentRole?: { [index: string]: string; };
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetMyTaskListRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSystemMsgListRes extends BaseResponse
{
    public data?: SystemMsgItem[];

    public constructor(init?: Partial<GetSystemMsgListRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetDatavCatalogPageRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DatavCatalogPage;

    public constructor(init?: Partial<GetDatavCatalogPageRes>) { (Object as any).assign(this, init); }
}

export class GetDatavCatalogRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DatavCatalogItem[];

    public constructor(init?: Partial<GetDatavCatalogRes>) { (Object as any).assign(this, init); }
}

export class GetDatavCatalogDetailRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DatavCatalogItem;

    public constructor(init?: Partial<GetDatavCatalogDetailRes>) { (Object as any).assign(this, init); }
}

export class AddDatavCatalogRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DatavCatalogItem;

    public constructor(init?: Partial<AddDatavCatalogRes>) { (Object as any).assign(this, init); }
}

export class CopyDataVVisualRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: string;

    public constructor(init?: Partial<CopyDataVVisualRes>) { (Object as any).assign(this, init); }
}

export class AddDataVVisualRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DataVVisual;

    public constructor(init?: Partial<AddDataVVisualRes>) { (Object as any).assign(this, init); }
}

export class GetDataVVisualDetailRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DataVVisualDetail;

    public constructor(init?: Partial<GetDataVVisualDetailRes>) { (Object as any).assign(this, init); }
}

export class AddDataVVisualFileRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DataVVisualFile;

    public constructor(init?: Partial<AddDataVVisualFileRes>) { (Object as any).assign(this, init); }
}

export class SaveDataVVisualDetailRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;

    public constructor(init?: Partial<SaveDataVVisualDetailRes>) { (Object as any).assign(this, init); }
}

export class GetDataVVisuaListRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DatavVisualPage;

    public constructor(init?: Partial<GetDataVVisuaListRes>) { (Object as any).assign(this, init); }
}

export class GetDataVDBListRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DataVMapPage;

    public constructor(init?: Partial<GetDataVDBListRes>) { (Object as any).assign(this, init); }
}

export class GetDataVMapListRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DataVMapPage;

    public constructor(init?: Partial<GetDataVMapListRes>) { (Object as any).assign(this, init); }
}

export class GetDataVMapDetailRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DataVMapItem;

    public constructor(init?: Partial<GetDataVMapDetailRes>) { (Object as any).assign(this, init); }
}

export class AddDataVMapRes
{
    public code?: number;
    public msg?: string;
    public success?: boolean;
    public data?: DataVMapItem;

    public constructor(init?: Partial<AddDataVMapRes>) { (Object as any).assign(this, init); }
}

export class GetMarketingPageTemplatesRes extends BaseResponse
{
    public data?: MarketingPageTemplateDTO[];
    public totalCount?: number;

    public constructor(init?: Partial<GetMarketingPageTemplatesRes>) { super(init); (Object as any).assign(this, init); }
}

export class SaveMarketingPageTemplateRes extends BaseResponse
{
    public id?: string;

    public constructor(init?: Partial<SaveMarketingPageTemplateRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingPageTemplateDetailRes extends BaseResponse
{
    public data?: MarketingPageTemplateDTO;

    public constructor(init?: Partial<GetMarketingPageTemplateDetailRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingPagesRes extends BaseResponse
{
    public data?: MarketingPageDTO[];
    public totalCount?: number;
    public productDatas?: { [index: string]: ActivityProdectBaseDTO; };

    public constructor(init?: Partial<GetMarketingPagesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingPageDetailRes extends BaseResponse
{
    public currentTime?: string;
    public data?: MarketingPageDTO;

    public constructor(init?: Partial<GetMarketingPageDetailRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddMarketingFeedbackRes extends BaseResponse
{
    public needPayment?: boolean;
    public wxCode?: string;

    public constructor(init?: Partial<AddMarketingFeedbackRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingFeedbacksRes extends BaseResponse
{
    public total?: number;
    public data?: MarketingFeedbackDTO[];
    public weChatUserDatas?: { [index: string]: WeChatUserDTO; };

    public constructor(init?: Partial<GetMarketingFeedbacksRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingPinTuanRes extends BaseResponse
{
    public currentTime?: string;
    public myWeChatUserId?: string;
    public configData?: ActivityPinTuanConfigDTO;
    public orderData?: ActivityPinTuanOrderDTO;
    public myGroupData?: ActivityPinTuanGroupDTO;
    public weChatUserDatas?: { [index: string]: WeChatUserDTO; };

    public constructor(init?: Partial<GetMarketingPinTuanRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingPinTuanGroupsRes extends BaseResponse
{
    public currentTime?: string;
    public data?: ActivityPinTuanGroupDTO[];
    public weChatUserDatas?: { [index: string]: WeChatUserDTO; };

    public constructor(init?: Partial<GetMarketingPinTuanGroupsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingSchoolPinTuanGroupsRes extends BaseResponse
{
    public total?: number;
    public data?: ActivityPinTuanGroupDTO[];
    public pageDatas?: { [index: string]: MarketingPageDTO; };
    public productDatas?: { [index: string]: ActivityProdectBaseDTO; };
    public orderDatas?: { [index: string]: ActivityOrderBaseDTO; };
    public weChatUserDatas?: { [index: string]: WeChatUserDTO; };

    public constructor(init?: Partial<GetMarketingSchoolPinTuanGroupsRes>) { super(init); (Object as any).assign(this, init); }
}

export class JoinMarketingPinTuanGroupRes extends BaseResponse
{
    public activityOrderId?: string;
    public pintuanGroupId?: string;

    public constructor(init?: Partial<JoinMarketingPinTuanGroupRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingQiangGouRes extends BaseResponse
{
    public currentTime?: string;
    public myWeChatUserId?: string;
    public data?: ActivityQiangGouConfigDTO;
    public orderDatas?: ActivityOrderBaseDTO[];
    public weChatUserDatas?: { [index: string]: WeChatUserDTO; };

    public constructor(init?: Partial<GetMarketingQiangGouRes>) { super(init); (Object as any).assign(this, init); }
}

export class MarketingQiangGouRes extends BaseResponse
{

    public constructor(init?: Partial<MarketingQiangGouRes>) { super(init); (Object as any).assign(this, init); }
}

export class AddMarketingZhuliRes extends BaseResponse
{

    public constructor(init?: Partial<AddMarketingZhuliRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingPrePayCodeFromWxLiteRes extends BaseResponse
{
    public prepayData?: string;
    public wxCode?: string;
    public orderId?: string;
    public schoolName?: string;
    public productName?: string;
    public orderNo?: string;
    public orderNum?: string;
    public price?: number;

    public constructor(init?: Partial<GetMarketingPrePayCodeFromWxLiteRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingPrePayCodeRes extends BaseResponse
{
    public prePayData?: string;
    public wxCode?: string;
    public orderId?: string;

    public constructor(init?: Partial<GetMarketingPrePayCodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetWeixinPayGoldPlanRes extends BaseResponse
{
    public jumpTitle?: string;
    public jumpOutUrl?: string;
    public data?: OrderInfoDTO;

    public constructor(init?: Partial<GetWeixinPayGoldPlanRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingParentOrdersRes extends BaseResponse
{
    public currentTime?: string;
    public myWeChatUserId?: string;
    public data?: ActivityOrderBaseDTO[];
    public productDatas?: { [index: string]: ActivityProdectBaseDTO; };
    public pinTuanGroups?: { [index: string]: ActivityPinTuanGroupDTO; };

    public constructor(init?: Partial<GetMarketingParentOrdersRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingSchoolOrdersRes extends BaseResponse
{
    public total?: number;
    public data?: ActivityOrderBaseDTO[];
    public weChatUserDatas?: { [index: string]: WeChatUserDTO; };
    public productDatas?: { [index: string]: ActivityProdectBaseDTO; };
    public pageDatas?: { [index: string]: MarketingPageDTO; };
    public spreaderDatas?: { [index: string]: SpreaderUserInfoDTO; };
    public pinTuanGroups?: { [index: string]: ActivityPinTuanGroupDTO; };

    public constructor(init?: Partial<GetMarketingSchoolOrdersRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingPageCountRes extends BaseResponse
{
    public totalpv?: number;
    public totalshare?: number;
    public totalorder?: number;
    public dailypv?: { [index: string]: number; };

    public constructor(init?: Partial<GetMarketingPageCountRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPageViewCountRes extends BaseResponse
{
    public totalpv?: number;
    public dailypv?: { [index: string]: number; };

    public constructor(init?: Partial<GetPageViewCountRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMarketingOrderGraphRes extends BaseResponse
{
    public categories?: GraphCategory[];
    public links?: GraphLink[];
    public nodes?: GraphNode[];

    public constructor(init?: Partial<GetMarketingOrderGraphRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetMySpreaderIdRes extends BaseResponse
{
    public spreaderUserInfo?: SpreaderUserInfoDTO;
    public shortenUrl?: string;

    public constructor(init?: Partial<GetMySpreaderIdRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPrivateGiftForKidRes extends BaseResponse
{
    public pointTotal?: number;
    public cards?: PrivateGiftCardDTO[];

    public constructor(init?: Partial<GetPrivateGiftForKidRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPrivateGiftSummaryRes extends BaseResponse
{
    public pointToday?: number;
    public point?: number;
    public pointWaitConfirm?: number;
    public orderToday?: number;
    public orderTotal?: number;
    public customerToday?: number;
    public customerTotal?: number;
    public card?: number;

    public constructor(init?: Partial<GetPrivateGiftSummaryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPrivateGiftSummaryByDateRes extends BaseResponse
{
    public point?: number;
    public pointWaitConfirm?: number;
    public orderTotal?: number;
    public customerTotal?: number;
    public card?: number;

    public constructor(init?: Partial<GetPrivateGiftSummaryByDateRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPrivateGiftPointLogsRes extends BaseResponse
{
    public data?: PrivateGiftPointLogDTO[];

    public constructor(init?: Partial<GetPrivateGiftPointLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetPrivateGiftCardLogsRes extends BaseResponse
{
    public data?: PrivateGiftCardDTO[];

    public constructor(init?: Partial<GetPrivateGiftCardLogsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetYouzanBookGoodConfigsRes
{
    public ret?: boolean;
    public message?: string;
    public data?: YouzanBookGoodConfigDTO[];

    public constructor(init?: Partial<GetYouzanBookGoodConfigsRes>) { (Object as any).assign(this, init); }
}

export class GetBookOrderKidInfoRes extends BaseResponse
{
    public kidData?: { [index: string]: BookOrderKidInfo; };

    public constructor(init?: Partial<GetBookOrderKidInfoRes>) { super(init); (Object as any).assign(this, init); }
}

export class QuerySftContractRes extends BaseResponse
{
    public contracts?: SftContractItem[];

    public constructor(init?: Partial<QuerySftContractRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetOrderIAPCodesRes extends BaseResponse
{
    public data?: OrderIAPCodeDTO[];

    public constructor(init?: Partial<GetOrderIAPCodesRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetOrderInfosRes extends BaseResponse
{
    public totalCount?: number;
    public data?: OrderInfoDTO[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };
    public wechatUserData?: { [index: string]: WeChatUserDTO; };
    public userNames?: { [index: number]: string; };

    public constructor(init?: Partial<GetOrderInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetLiveMemberRes extends BaseResponse
{
    public totalCount?: number;
    public data?: LiveMemberDetailDTO[];
    public studentData?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetLiveMemberRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetKidInfoBalanceAccountHistoryRes extends BaseResponse
{
    public balanceAmount?: number;
    public balanceGiftAmount?: number;
    public data?: KidInfoAccountBalanceHistoryDTO[];

    public constructor(init?: Partial<GetKidInfoBalanceAccountHistoryRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolOrderProductsRes extends BaseResponse
{
    public data?: SchoolOrderProductDTO[];

    public constructor(init?: Partial<GetSchoolOrderProductsRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolOrderInfosRes extends BaseResponse
{
    public total?: number;
    public data?: SchoolOrderInfoDTO[];
    public teacherInfos?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetSchoolOrderInfosRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolSmsBalanceRes extends BaseResponse
{
    public balanceAmount?: number;
    public alertAmount?: number;
    public alertPhoneNumber?: string;

    public constructor(init?: Partial<GetSchoolSmsBalanceRes>) { super(init); (Object as any).assign(this, init); }
}

export class UpdateSchoolSmsBalanceAlertRes extends BaseResponse
{
    public balanceAmount?: number;
    public alertAmount?: number;
    public alertPhoneNumber?: string;

    public constructor(init?: Partial<UpdateSchoolSmsBalanceAlertRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetSchoolSmsSendLogRes extends BaseResponse
{
    public total?: number;
    public data?: SmsLogDTO[];

    public constructor(init?: Partial<GetSchoolSmsSendLogRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetAlbumEventInfoRes
{
    public code?: number;
    public message?: string;
    public data?: GetAlbumEventInfoData;

    public constructor(init?: Partial<GetAlbumEventInfoRes>) { (Object as any).assign(this, init); }
}

export class GetKidAlbumDataRes
{
    public code?: number;
    public message?: string;
    public data?: AlbumData;

    public constructor(init?: Partial<GetKidAlbumDataRes>) { (Object as any).assign(this, init); }
}

export class GetKidAlbumInfoRes
{
    public code?: number;
    public message?: string;
    public data?: KidAlbumInfo;

    public constructor(init?: Partial<GetKidAlbumInfoRes>) { (Object as any).assign(this, init); }
}

export class GetKidRemarkRes
{
    public ret?: boolean;
    public message?: string;
    public data?: KidRemarkDTO;

    public constructor(init?: Partial<GetKidRemarkRes>) { (Object as any).assign(this, init); }
}

export class GetClassEventsReadStatsRes
{
    public data?: UserClassEventsStats;

    public constructor(init?: Partial<GetClassEventsReadStatsRes>) { (Object as any).assign(this, init); }
}

export class GetUserConfigRes
{
    public ret?: boolean;
    public message?: string;
    public data?: ConfigItemRespDTO[];

    public constructor(init?: Partial<GetUserConfigRes>) { (Object as any).assign(this, init); }
}

export class GetAliyunStsTokenV2Res
{
    public statusCode?: number;
    public accessKeyId?: string;
    public bucketName?: string;
    public uploadHost?: string;
    public expires?: string;
    public dir?: string;
    public callback?: string;
    public signItems?: AliyunOssSignItem[];

    public constructor(init?: Partial<GetAliyunStsTokenV2Res>) { (Object as any).assign(this, init); }
}

export class CheckUrlRes
{
    public pageUrl?: string;
    public pageTitle?: string;
    public pageIcon?: string;

    public constructor(init?: Partial<CheckUrlRes>) { (Object as any).assign(this, init); }
}

export class GetStudentBaseInfoRes
{
    public schoolId?: string;
    public kidInfo?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetStudentBaseInfoRes>) { (Object as any).assign(this, init); }
}

export class GetClassBaseInfoRes
{
    public classInfo?: { [index: string]: ClassBaseInfoDTO; };

    public constructor(init?: Partial<GetClassBaseInfoRes>) { (Object as any).assign(this, init); }
}

export class GetOSSBaseInfoRes
{
    public ossInfo?: { [index: string]: OssInfoDTO; };

    public constructor(init?: Partial<GetOSSBaseInfoRes>) { (Object as any).assign(this, init); }
}

export class GetTeacherInfoRes
{
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };

    public constructor(init?: Partial<GetTeacherInfoRes>) { (Object as any).assign(this, init); }
}

export class GetSchoolGradeNameRes
{
    public ret?: boolean;
    public message?: string;
    public gradeMap?: GradeNameMap[];

    public constructor(init?: Partial<GetSchoolGradeNameRes>) { (Object as any).assign(this, init); }
}

export class ClassEventShareRes
{
    public code?: number;
    public message?: string;
    public qrUrl?: string;
    public data?: ClassEventItem;

    public constructor(init?: Partial<ClassEventShareRes>) { (Object as any).assign(this, init); }
}

export class ClassEventTaskItemRes
{
    public code?: number;
    public message?: string;
    public url?: string;
    public qrUrl?: string;
    public data?: ClassEventTaskItem;

    public constructor(init?: Partial<ClassEventTaskItemRes>) { (Object as any).assign(this, init); }
}

export class TongduibaCreateOrderRes
{
    public code?: number;
    public msg?: string;
    public data?: TongduibaCreateOrderResData;

    public constructor(init?: Partial<TongduibaCreateOrderRes>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointRes
{
    public code?: number;
    public msg?: string;
    public data?: TongduibaQueryPointData;

    public constructor(init?: Partial<TongduibaQueryPointRes>) { (Object as any).assign(this, init); }
}

export class TongduibaQueryPointLogRes
{
    public code?: number;
    public msg?: string;
    public data?: TongduibaQueryPointLogData;

    public constructor(init?: Partial<TongduibaQueryPointLogRes>) { (Object as any).assign(this, init); }
}

export class YouzanNotifyRes
{
    public code?: number;
    public msg?: string;

    public constructor(init?: Partial<YouzanNotifyRes>) { (Object as any).assign(this, init); }
}

export class GetExtrabookConfigRes
{
    public ret?: boolean;
    public message?: string;
    public data?: BookExtraConfigDTO;

    public constructor(init?: Partial<GetExtrabookConfigRes>) { (Object as any).assign(this, init); }
}

export class GetKidGrowBooksRes
{
    public ret?: boolean;
    public message?: string;
    public data?: GrowBookInfoDTO[];

    public constructor(init?: Partial<GetKidGrowBooksRes>) { (Object as any).assign(this, init); }
}

export class AddEventTaskItemRes extends CommonReturn
{
    public data?: ClassEventTaskItem;

    public constructor(init?: Partial<AddEventTaskItemRes>) { super(init); (Object as any).assign(this, init); }
}

export class GetEventKGTimeByIdRes
{
    public ret?: boolean;
    public message?: string;
    public eventdto?: ClassEventKGTimeDTO;

    public constructor(init?: Partial<GetEventKGTimeByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventEvaluateByIdRes
{
    public ret?: boolean;
    public message?: string;
    public evaluateDTO?: EvaluateDTO;

    public constructor(init?: Partial<GetEventEvaluateByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEvaluatesRes
{
    public ret?: boolean;
    public message?: string;
    public data?: EvaluateDTO[];

    public constructor(init?: Partial<GetEvaluatesRes>) { (Object as any).assign(this, init); }
}

export class GetEvaluatesV2Res extends BaseResponse
{
    public data?: EvaluateDTO[];
    public teacherInfo?: { [index: string]: TeacherBaseInfoDTO; };
    public studentInfo?: { [index: string]: StudentBaseInfoDTO; };

    public constructor(init?: Partial<GetEvaluatesV2Res>) { super(init); (Object as any).assign(this, init); }
}

export class GetEventKidRemarkByIdRes
{
    public ret?: boolean;
    public message?: string;
    public kidremarkDTO?: KidRemarkDTO;

    public constructor(init?: Partial<GetEventKidRemarkByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventNotifyByIdRes
{
    public ret?: boolean;
    public message?: string;
    public eventitem?: ClassEventItem;

    public constructor(init?: Partial<GetEventNotifyByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventShareByIdRes
{
    public ret?: boolean;
    public message?: string;
    public eventitem?: ClassEventItem;

    public constructor(init?: Partial<GetEventShareByIdRes>) { (Object as any).assign(this, init); }
}

export class GetEventVoteByIdRes
{
    public ret?: boolean;
    public message?: string;
    public eventitem?: ClassEventVoteItem;

    public constructor(init?: Partial<GetEventVoteByIdRes>) { (Object as any).assign(this, init); }
}

export class GetSystemNoticeRes
{
    public data?: SystemNoticeItem[];

    public constructor(init?: Partial<GetSystemNoticeRes>) { (Object as any).assign(this, init); }
}

export class GetTermInfoRes
{
    public ret?: boolean;
    public message?: string;
    public data?: TermInfoItem[];

    public constructor(init?: Partial<GetTermInfoRes>) { (Object as any).assign(this, init); }
}

export class CheckVerifyCodeRes extends BaseResponse
{
    public token?: string;

    public constructor(init?: Partial<CheckVerifyCodeRes>) { super(init); (Object as any).assign(this, init); }
}

export class SendVerifyCodeRes
{
    public ret?: boolean;
    public message?: string;
    public needBindKid?: boolean;
    public bindKidUrl?: string;
    public schoolInfos?: SchoolBaseInfo[];

    public constructor(init?: Partial<SendVerifyCodeRes>) { (Object as any).assign(this, init); }
}

export class GetVersionCodeRes
{
    public needUpgrade?: boolean;
    public isForce?: boolean;
    public gotoMarket?: boolean;
    public data?: GetVersionCodeData;

    public constructor(init?: Partial<GetVersionCodeRes>) { (Object as any).assign(this, init); }
}

export class SchoolWeatherRes
{
    public ret?: boolean;
    public message?: string;
    public fromcache?: boolean;
    public data?: WeatherDTOS;

    public constructor(init?: Partial<SchoolWeatherRes>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles?: string[];

    // @DataMember(Order=2)
    public allPermissions?: string[];

    // @DataMember(Order=3)
    public meta?: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus?: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles?: string[];

    // @DataMember(Order=2)
    public allPermissions?: string[];

    // @DataMember(Order=3)
    public meta?: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus?: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @Route("/class/{ClassId}/setlogo", "POST, OPTIONS")
export class SetPersonLogoReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public logoId?: number;

    public constructor(init?: Partial<SetPersonLogoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPersonLogoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取一条信息流公告
*/
// @Route("/class/event/msgcard/{EventId}", "GET, OPTIONS")
// @Api(Description="获取一条信息流公告")
export class AddEventMsgCardPageViewReq implements IReturn<CommonReturn>
{
    public eventId?: string;

    public constructor(init?: Partial<AddEventMsgCardPageViewReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventMsgCardPageViewReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 家长获取所有消息接口
*/
// @Route("/kid/{KidId}/events", "GET, OPTIONS")
// @Api(Description="家长获取所有消息接口")
export class GetClassEventsByKidReq extends CommonRequest implements IReturn<GetClassEventsRes>
{
    public pagesize?: number;
    public isGetNewer?: boolean;
    public currentNewestId?: string;
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    public constructor(init?: Partial<GetClassEventsByKidReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassEventsByKidReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventsRes(); }
}

// @Route("/file/copyfrompersontoclass", "POST, OPTIONS")
export class CopyFromPersonToClassReq implements IReturn<CommonReturn>
{
    public classid?: string;
    public fileIds?: { [index: string]: string[]; };
    public dirName?: string;

    public constructor(init?: Partial<CopyFromPersonToClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CopyFromPersonToClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/file/list", "GET, OPTIONS")
export class GetFileReq implements IReturn<GetFileRes>
{
    public filetype?: FILETYPE;
    public classid?: string;

    public constructor(init?: Partial<GetFileReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFileReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetFileRes(); }
}

export class RebuildAlbumCacheReq
{
    public kidId?: string;
    public eventId?: string;
    public classId?: string;
    public termId?: number;
    public configId?: string;
    public source?: string;

    public constructor(init?: Partial<RebuildAlbumCacheReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RebuildAlbumCacheReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 获取报表
*/
// @Route("/school/{SchoolId}/richchart/{ChartType}/", "GET, OPTIONS")
// @Api(Description="获取报表")
export class GetRichChartReq implements IReturn<GetRichChartRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 报表类型
    */
    // @ApiMember(DataType="string", Description="报表类型", IsRequired=true, Name="ChartType", ParameterType="path")
    public chartType?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", Name="ClassId", ParameterType="query")
    public classId?: string;

    public constructor(init?: Partial<GetRichChartReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRichChartReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRichChartRes(); }
}

// @Route("/system/resetpassword", "POST, OPTIONS")
export class ResetPasswordReq implements IReturn<ChangePasswordRes>
{
    public userName?: string;
    public verifyCode?: string;
    public newPassword?: string;

    public constructor(init?: Partial<ResetPasswordReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResetPasswordReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChangePasswordRes(); }
}

// @Route("/system/osscallback", "POST, GET, OPTIONS")
export class AliyunOssCallbackReq implements IReturn<AliyunOssCallbackRes>
{
    public bucket?: string;
    public filename?: string;
    public etag?: string;
    public size?: string;
    public mimeType?: string;
    public imageHeight?: string;
    public imageWidth?: string;
    public imageFormat?: string;
    public uid?: string;
    public schoolid?: string;
    public classid?: string;
    public playseconds?: string;
    public filehash?: string;
    public uploadsrc?: string;
    public copyto?: string;
    public targetdir?: string;
    public originalname?: string;
    public vheight?: string;
    public vwidth?: string;
    public ossid?: string;

    public constructor(init?: Partial<AliyunOssCallbackReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AliyunOssCallbackReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AliyunOssCallbackRes(); }
}

// @Route("/system/clearcache", "GET, OPTIONS")
export class GetClearCacheReq implements IReturn<CommonReturn>
{
    public key?: string;

    public constructor(init?: Partial<GetClearCacheReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClearCacheReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/system/ststoken", "GET, OPTIONS")
export class GetAliyunStsTokenReq implements IReturn<GetAliyunStsTokenRes>
{

    public constructor(init?: Partial<GetAliyunStsTokenReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAliyunStsTokenReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAliyunStsTokenRes(); }
}

// @Route("/system/versioninfo", "POST, OPTIONS")
export class CreateVersionInfoReq implements IReturn<CommonReturn>
{
    public os?: OSTYPE;
    public appId?: number;
    public versionName?: string;
    public versionCode?: string;
    public releaseNote?: string;
    public isDefault?: boolean;
    public isApk?: boolean;
    public downloadUrl?: string;
    public packageSize?: string;
    public md5hash?: string;
    public authKey?: string;
    public channels?: ChannelVersionItem[];

    public constructor(init?: Partial<CreateVersionInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateVersionInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/system/systemnotice", "POST, OPTIONS")
export class CreateSystemNoticeReq implements IReturn<CommonReturn>
{
    public title?: string;
    public content?: string;
    public isLink?: boolean;

    public constructor(init?: Partial<CreateSystemNoticeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateSystemNoticeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/system/systemnotice/{Id}", "DELETE, OPTIONS")
export class DeleteSystemNoticeReq implements IReturn<CommonReturn>
{
    public id?: string;

    public constructor(init?: Partial<DeleteSystemNoticeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSystemNoticeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/system/changepassword", "POST, OPTIONS")
export class ChangePasswordReq implements IReturn<ChangePasswordRes>
{
    public oldPassword?: string;
    public newPassword?: string;

    public constructor(init?: Partial<ChangePasswordReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChangePasswordReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ChangePasswordRes(); }
}

// @Route("/share/{classid}/tongzhidan", "GET, OPTIONS")
export class GetTongzhidanShareImg
{
    public classid?: string;

    public constructor(init?: Partial<GetTongzhidanShareImg>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTongzhidanShareImg'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/system/ossfiltercallback", "POST, OPTIONS")
export class AliyunOssFilterCallbackReq
{
    public checksum?: string;
    public content?: string;

    public constructor(init?: Partial<AliyunOssFilterCallbackReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AliyunOssFilterCallbackReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/system/mnscallback", "POST, OPTIONS")
export class AliyunMnsCallbackReq
{
    public topicOwner?: string;
    public topicName?: string;
    public subscriber?: string;
    public subscriptionName?: string;
    public messageId?: string;
    public message?: string;
    public messageMD5?: string;
    public messagePublishTime?: string;

    public constructor(init?: Partial<AliyunMnsCallbackReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AliyunMnsCallbackReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Schema Migrate
*/
// @Route("/system/schemamigrate", "GET, OPTIONS")
// @Api(Description="Schema Migrate")
export class UpdateDBSchemaReq implements IReturn<CommonReturn>
{

    public constructor(init?: Partial<UpdateDBSchemaReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateDBSchemaReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* Fix用户的feed
*/
// @Route("/system/fixuserfeed", "POST, OPTIONS")
// @Api(Description="Fix用户的feed")
export class FixUserFeedReq implements IReturn<CommonReturn>
{

    public constructor(init?: Partial<FixUserFeedReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FixUserFeedReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 初始化订单code
*/
// @Route("/system/initordercode", "GET, OPTIONS")
// @Api(Description="初始化订单code")
export class InitOrderCodeReq implements IReturn<CommonReturn>
{

    public constructor(init?: Partial<InitOrderCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InitOrderCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/system/init", "GET, OPTIONS")
export class InitSystemReq implements IReturn<CommonReturn>
{
    public code?: string;
    public username?: string;

    public constructor(init?: Partial<InitSystemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InitSystemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 通知客户端刷新配置
*/
// @Route("/system/config/broacastupdate", "POST, OPTIONS")
// @Api(Description="通知客户端刷新配置")
export class BroacastConfigUpdateReq implements IReturn<CommonReturn>
{
    /**
    * ConfigId
    */
    // @ApiMember(DataType="string", Description="ConfigId", IsRequired=true, Name="ConfigId", ParameterType="query")
    public configId?: string;

    public constructor(init?: Partial<BroacastConfigUpdateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BroacastConfigUpdateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改登录名（手机）
*/
// @Route("/system/changeusername", "POST, OPTIONS")
// @Api(Description="修改登录名（手机）")
export class ChangeUserNameReq implements IReturn<CommonReturn>
{
    public originalUserName?: string;
    public userId?: number;
    public newUserName?: string;

    public constructor(init?: Partial<ChangeUserNameReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChangeUserNameReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改家长为学生的主帐号
*/
// @Route("/system/changeparenttodefault", "POST, OPTIONS")
// @Api(Description="修改家长为学生的主帐号")
export class ChangeParentToDefaultReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public kidId?: string;
    public userId?: number;

    public constructor(init?: Partial<ChangeParentToDefaultReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChangeParentToDefaultReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

// @Route("/wechat/jsticket", "POST, OPTIONS")
export class GetWechatJsTicketReq implements IReturn<WechatJsSdkConfig>
{
    public url?: string;
    public jsApiList?: string[];

    public constructor(init?: Partial<GetWechatJsTicketReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWechatJsTicketReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WechatJsSdkConfig(); }
}

/**
* 获取绑定的微信
*/
// @Route("/system/getbindwechat", "GET, OPTIONS")
// @Api(Description="获取绑定的微信")
export class GetBindWechatInfoReq implements IReturn<GetBindWechatInfoRes>
{
    public appId?: number;

    public constructor(init?: Partial<GetBindWechatInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBindWechatInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBindWechatInfoRes(); }
}

/**
* 绑定微信
*/
// @Route("/system/bindwechat", "POST, OPTIONS")
// @Api(Description="绑定微信")
export class BindWechatReq implements IReturn<BaseResponse>
{
    public appId?: number;
    public code?: string;
    public wxappid?: string;

    public constructor(init?: Partial<BindWechatReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BindWechatReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 小程序绑定微信
*/
// @Route("/system/bindwechatbyminiprograme", "POST, OPTIONS")
// @Api(Description="小程序绑定微信")
export class BindWechatByMiniProgrameCodeReq implements IReturn<WechatComponentBindRes>
{
    public appId?: number;
    public code?: string;
    public wxappid?: string;
    public encryptedData?: string;
    public iv?: string;
    public phoneNumber?: string;
    public verifyCode?: string;

    public constructor(init?: Partial<BindWechatByMiniProgrameCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BindWechatByMiniProgrameCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WechatComponentBindRes(); }
}

/**
* 解除绑定微信
*/
// @Route("/system/unbindwechat", "POST, OPTIONS")
// @Api(Description="解除绑定微信")
export class UnBindWechatReq implements IReturn<BaseResponse>
{
    public appId?: number;

    public constructor(init?: Partial<UnBindWechatReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnBindWechatReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 翻译文本
*/
// @Route("/translate/event", "POST, OPTIONS")
// @Api(Description="翻译文本")
export class TranslateContentReq implements IReturn<TranslateContentRes>
{
    public sourceText?: string;
    public targetLang?: string;

    public constructor(init?: Partial<TranslateContentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TranslateContentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TranslateContentRes(); }
}

/**
* 搜索网盘文件
*/
// @Route("/file/{SchoolId}/searchfiles", "GET, OPTIONS")
// @Api(Description="搜索网盘文件")
export class FileSearchReq implements IReturn<FileListDirRes>
{
    public schoolId?: string;
    public keyword?: string;

    public constructor(init?: Partial<FileSearchReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FileSearchReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FileListDirRes(); }
}

/**
* 列出目录下文件列表
*/
// @Route("/file/{SchoolId}/files", "GET, OPTIONS")
// @Api(Description="列出目录下文件列表")
export class FileListDirReq implements IReturn<FileListDirRes>
{
    public schoolId?: string;
    public parentId?: string;

    public constructor(init?: Partial<FileListDirReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FileListDirReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FileListDirRes(); }
}

/**
* 创建文件
*/
// @Route("/file/{SchoolId}/createfile", "POST, OPTIONS")
// @Api(Description="创建文件")
export class FileCreateReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public parentId?: string;
    public ossIds?: { [index: string]: string; };

    public constructor(init?: Partial<FileCreateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FileCreateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 创建目录
*/
// @Route("/file/{SchoolId}/createdir", "POST, OPTIONS")
// @Api(Description="创建目录")
export class FileCreateDirReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public dirname?: string;
    public parentId?: string;

    public constructor(init?: Partial<FileCreateDirReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FileCreateDirReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 重命名
*/
// @Route("/file/{SchoolId}/rename", "POST, OPTIONS")
// @Api(Description="重命名")
export class FileRenameReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public fileid?: string;
    public newname?: string;

    public constructor(init?: Partial<FileRenameReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FileRenameReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除文件或目录
*/
// @Route("/file/{SchoolId}/delete", "POST, OPTIONS")
// @Api(Description="删除文件或目录")
export class FileDeleteReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public fileids?: string[];

    public constructor(init?: Partial<FileDeleteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FileDeleteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 移动文件或目录
*/
// @Route("/file/{SchoolId}/move", "POST, OPTIONS")
// @Api(Description="移动文件或目录")
export class FileMoveReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public fileids?: string[];
    public parentId?: string;

    public constructor(init?: Partial<FileMoveReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FileMoveReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 创建更新海报子栏目
*/
// @Route("/poster/Catalog/update", "POST, OPTIONS")
// @Api(Description="创建更新海报子栏目")
export class AddPosterCatalogReq implements IReturn<AddPosterCatalogRes>
{
    public id?: string;
    public name?: string;
    public parentCatalogName?: string;
    public backgroundImageUrl?: string;
    public configItems?: PosterConfigItemDTO[];
    public w?: number;
    public h?: number;
    public isPublish?: boolean;
    public orderIndex?: number;

    public constructor(init?: Partial<AddPosterCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddPosterCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddPosterCatalogRes(); }
}

/**
* 获取海报子栏目列表
*/
// @Route("/poster/Catalogs", "GET, OPTIONS")
// @Api(Description="获取海报子栏目列表")
export class GetPosterCatalogsReq implements IReturn<GetPosterCatalogsRes>
{
    public parentCatalogName?: string;
    public ids?: string[];
    public isPublish?: boolean;

    public constructor(init?: Partial<GetPosterCatalogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPosterCatalogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPosterCatalogsRes(); }
}

/**
* 创建更新海报模板
*/
// @Route("/poster/template/update", "POST, OPTIONS")
// @Api(Description="创建更新海报模板")
export class AddPosterTemplateReq implements IReturn<AddPosterTemplateRes>
{
    public id?: string;
    public name?: string;
    public catalogId?: string;
    public backgroundImageUrl?: string;
    public configItems?: PosterConfigItemDTO[];
    public w?: number;
    public h?: number;
    public previewImageUrl?: string;
    public isPublish?: boolean;
    public orderIndex?: number;

    public constructor(init?: Partial<AddPosterTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddPosterTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddPosterTemplateRes(); }
}

/**
* 获取海报子栏目海报模板列表
*/
// @Route("/poster/templates", "GET, OPTIONS")
// @Api(Description="获取海报子栏目海报模板列表")
export class GetPosterTemplatesReq implements IReturn<GetPosterTemplatesRes>
{
    public schoolId?: string;
    public name?: string;
    public catalogIds?: string[];
    public isPublish?: boolean;

    public constructor(init?: Partial<GetPosterTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPosterTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPosterTemplatesRes(); }
}

/**
* 创建更新海报
*/
// @Route("/poster/item/update", "POST, OPTIONS")
// @Api(Description="创建更新海报")
export class AddPosterItemReq implements IReturn<AddPosterItemRes>
{
    public id?: string;
    public name?: string;
    public schoolId?: string;
    public catalogId?: string;
    public templateId?: string;
    public configItems?: PosterConfigItemDTO[];
    public publishedImageUrl?: string;
    public isDelete?: boolean;
    public isDraft?: boolean;

    public constructor(init?: Partial<AddPosterItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddPosterItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddPosterItemRes(); }
}

/**
* 获取海报
*/
// @Route("/poster/item", "GET, OPTIONS")
// @Api(Description="获取海报")
export class GetPosterItemReq implements IReturn<GetPosterItemRes>
{
    public id?: string;
    public schoolId?: string;

    public constructor(init?: Partial<GetPosterItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPosterItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPosterItemRes(); }
}

/**
* 获取海报列表
*/
// @Route("/poster/items", "GET, OPTIONS")
// @Api(Description="获取海报列表")
export class GetPosterItemsReq implements IReturn<GetPosterItemsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetPosterItemsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPosterItemsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPosterItemsRes(); }
}

/**
* 创建更新公众号文章
*/
// @Route("/school/{SchoolId}/Publicity/update", "POST, OPTIONS")
// @Api(Description="创建更新公众号文章")
export class AddPublicityReq implements IReturn<AddPublicityRes>
{
    public schoolId?: string;
    public data?: PublicityDTO;
    public isPublish?: boolean;

    public constructor(init?: Partial<AddPublicityReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddPublicityReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddPublicityRes(); }
}

/**
* 获取公众号文章
*/
// @Route("/school/{SchoolId}/Publicity/{Id}", "GET, OPTIONS")
// @Api(Description="获取公众号文章")
export class FindPublicityReq implements IReturn<FindPublicityRes>
{
    public id?: string;
    public schoolId?: string;

    public constructor(init?: Partial<FindPublicityReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FindPublicityReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FindPublicityRes(); }
}

/**
* 获取公众号文章列表
*/
// @Route("/school/{SchoolId}/Publicity/list", "GET, OPTIONS")
// @Api(Description="获取公众号文章列表")
export class GetPublicityListReq implements IReturn<GetPublicityListRes>
{
    public schoolId?: string;
    public createOn?: string[];
    public createByTeacherUserInfoId?: string;
    public title?: string;
    public isDraft?: boolean;
    public lastId?: string;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetPublicityListReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPublicityListReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPublicityListRes(); }
}

/**
* 学校班级动态 班级维度
*/
// @Route("/report/school/{SchoolId}/class/event", "GET")
// @Api(Description="学校班级动态 班级维度")
export class GetSchoolClassSummaryReq implements IReturn<GetSchoolClassSummaryRes>
{
    public schoolPartnerInfoId?: string;
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public branchIds?: string[];
    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetSchoolClassSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolClassSummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolClassSummaryRes(); }
}

/**
* 学校班级动态 班级维度
*/
// @Route("/report/app/school/{SchoolId}/class/id", "GET, OPTIONS")
// @Api(Description="学校班级动态 班级维度")
export class GetSchoolClassSummaryForAppReq implements IReturn<ReportTableRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetSchoolClassSummaryForAppReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolClassSummaryForAppReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportTableRes(); }
}

/**
* 学校班级动态 日维度、月维度
*/
// @Route("/report/school/{SchoolId}/class/daily", "GET")
// @Api(Description="学校班级动态 日维度、月维度")
export class GetClassSummaryDailyReq implements IReturn<GetClassSummaryDailyRes>
{
    public schoolPartnerInfoId?: string;
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public branchIds?: string[];
    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetClassSummaryDailyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassSummaryDailyReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetClassSummaryDailyRes(); }
}

/**
* 学校聊天 班级维度
*/
// @Route("/report/school/{SchoolId}/im/class", "GET")
// @Api(Description="学校聊天 班级维度")
export class GetSchoolIMSummaryReq implements IReturn<GetSchoolIMSummaryRes>
{
    public schoolPartnerInfoId?: string;
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public branchIds?: string[];
    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetSchoolIMSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolIMSummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolIMSummaryRes(); }
}

/**
* 学校聊天 班级维度
*/
// @Route("/report/app/school/{SchoolId}/im/class", "GET, OPTIONS")
// @Api(Description="学校聊天 班级维度")
export class GetSchoolIMSummaryForAppReq implements IReturn<ReportTableRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetSchoolIMSummaryForAppReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolIMSummaryForAppReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportTableRes(); }
}

/**
* 学校聊天 日维度、月维度
*/
// @Route("/report/school/{SchoolId}/im/daily", "GET")
// @Api(Description="学校聊天 日维度、月维度")
export class GetDailySchoolIMSummaryReq implements IReturn<GetDailySchoolIMSummaryRes>
{
    public schoolPartnerInfoId?: string;
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public branchIds?: string[];
    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetDailySchoolIMSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailySchoolIMSummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDailySchoolIMSummaryRes(); }
}

/**
* 班级学生活跃 学生维度
*/
// @Route("/report/school/{SchoolId}/kidactivity/class/{ClassId}/kid", "GET, OPTIONS")
// @Api(Description="班级学生活跃 学生维度")
export class GetClassKidActivityReq implements IReturn<GetClassKidActivityRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetClassKidActivityReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassKidActivityReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassKidActivityRes(); }
}

/**
* 班级学生活跃 学生维度
*/
// @Route("/report/app/school/{SchoolId}/kidactivity/class/{ClassId}/kid", "GET, OPTIONS")
// @Api(Description="班级学生活跃 学生维度")
export class GetClassKidActivityForAppReq implements IReturn<ReportTableRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetClassKidActivityForAppReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassKidActivityForAppReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportTableRes(); }
}

/**
* 班级学生活跃 日维度、月维度
*/
// @Route("/report/school/{SchoolId}/kidactivity/class/{ClassId}/kid", "GET, OPTIONS")
// @Api(Description="班级学生活跃 日维度、月维度")
export class GetClassKidActivityDailyReq implements IReturn<GetClassKidActivityDailyRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetClassKidActivityDailyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassKidActivityDailyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassKidActivityDailyRes(); }
}

/**
* 学校学生活跃 APP
*/
// @Route("/report/school/{SchoolId}/kidactivity", "GET, OPTIONS")
// @Api(Description="学校学生活跃 APP")
export class GetSchoolKidActivityReq implements IReturn<GetSchoolKidActivityRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public branchIds?: string[];
    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    public constructor(init?: Partial<GetSchoolKidActivityReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidActivityReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidActivityRes(); }
}

/**
* 学校学生活跃 班级维度
*/
// @Route("/report/school/{SchoolId}/kidactivity/class", "GET")
// @Api(Description="学校学生活跃 班级维度")
export class GetSchoolKidActivitySummaryReq implements IReturn<GetSchoolKidActivitySummaryRes>
{
    public schoolPartnerInfoId?: string;
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public branchIds?: string[];
    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetSchoolKidActivitySummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidActivitySummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolKidActivitySummaryRes(); }
}

/**
* 学校学生活跃 班级维度
*/
// @Route("/report/app/school/{SchoolId}/kidactivity/class", "GET, OPTIONS")
// @Api(Description="学校学生活跃 班级维度")
export class GetSchoolKidActivitySummaryForAppReq implements IReturn<ReportTableRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetSchoolKidActivitySummaryForAppReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidActivitySummaryForAppReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReportTableRes(); }
}

/**
* 学校学生活跃 日维度、月维度
*/
// @Route("/report/school/{SchoolId}/kidactivity/daily", "GET")
// @Api(Description="学校学生活跃 日维度、月维度")
export class GetSchoolKidActivitySummaryDailyReq implements IReturn<GetSchoolKidActivitySummaryDailyRes>
{
    public schoolPartnerInfoId?: string;
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public branchIds?: string[];
    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetSchoolKidActivitySummaryDailyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidActivitySummaryDailyReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolKidActivitySummaryDailyRes(); }
}

/**
* 学校教师活跃 APP
*/
// @Route("/report/school/{SchoolId}/teacheractivity", "GET, OPTIONS")
// @Api(Description="学校教师活跃 APP")
export class GetSchoolTeacherActivityReq implements IReturn<GetSchoolTeacherActivityRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    public constructor(init?: Partial<GetSchoolTeacherActivityReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTeacherActivityReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolTeacherActivityRes(); }
}

/**
* 学校教师活跃 日维度、月维度
*/
// @Route("/report/school/{SchoolId}/teacheractivity/daily", "GET")
// @Api(Description="学校教师活跃 日维度、月维度")
export class GetDailySchoolTeacherActivitySummaryReq implements IReturn<GetDailySchoolTeacherActivitySummaryRes>
{
    public schoolPartnerInfoId?: string;
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public branchIds?: string[];
    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetDailySchoolTeacherActivitySummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailySchoolTeacherActivitySummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDailySchoolTeacherActivitySummaryRes(); }
}

/**
* 学校教师活跃 教师维度
*/
// @Route("/report/school/{SchoolId}/teacheractivity/teacher", "GET")
// @Api(Description="学校教师活跃 教师维度")
export class GetDailySchoolTeacherActivityReq implements IReturn<GetDailySchoolTeacherActivityRes>
{
    public schoolPartnerInfoId?: string;
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public branchIds?: string[];
    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    /**
    * 维度
    */
    // @ApiMember(DataType="string", Description="维度", Name="Dimension", ParameterType="query")
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetDailySchoolTeacherActivityReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailySchoolTeacherActivityReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDailySchoolTeacherActivityRes(); }
}

/**
* 获取机构学员考勤人数
*/
// @Route("/report/school/{SchoolId}/kidattendance/month", "GET, OPTIONS")
// @Api(Description="获取机构学员考勤人数")
export class GetMonthSchoolKidAttendanceReq implements IReturn<GetMonthSchoolKidAttendanceRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 开始时间
    */
    // @ApiMember(DataType="string", Description="开始时间", Name="BeginTime", ParameterType="query")
    public beginTime?: string;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="EndTime", ParameterType="query")
    public endTime?: string;

    public constructor(init?: Partial<GetMonthSchoolKidAttendanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMonthSchoolKidAttendanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMonthSchoolKidAttendanceRes(); }
}

/**
* 获取机构教师业绩报表
*/
// @Route("/report/school/{SchoolId}/classtime/teacher", "GET")
// @Api(Description="获取机构教师业绩报表")
export class GetClassTimeTeacherSummaryReq implements IReturn<GetClassTimeTeacherSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public branchIds?: string[];
    public beginTime?: string[];
    public classId?: string;
    public courseId?: string;
    public teacherUserInfoId?: string;
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetClassTimeTeacherSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimeTeacherSummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetClassTimeTeacherSummaryRes(); }
}

/**
* 获取机构课包剩余
*/
// @Route("/report/school/{SchoolId}/course/class", "GET")
// @Api(Description="获取机构课包剩余")
export class GetOrgStudentCourseBalanceSummaryReq implements IReturn<GetOrgStudentCourseBalanceSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public branchIds?: string[];
    public courseId?: string;

    public constructor(init?: Partial<GetOrgStudentCourseBalanceSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrgStudentCourseBalanceSummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetOrgStudentCourseBalanceSummaryRes(); }
}

/**
* 获取机构课消报表
*/
// @Route("/report/school/{SchoolId}/clastime/class", "GET")
// @Api(Description="获取机构课消报表")
export class GetClassTimeClassSummaryReq implements IReturn<GetClassTimeClassSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public branchIds?: string[];
    public beginTime?: string[];
    public classId?: string;
    public courseId?: string;
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetClassTimeClassSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimeClassSummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetClassTimeClassSummaryRes(); }
}

/**
* 导出通过json解析的结果
*/
// @Route("/report/parsejson/excel", "POST, OPTIONS")
// @Api(Description="导出通过json解析的结果")
export class ExportFromJsonReq implements IReturn<Blob>
{
    public fileName?: string;
    public data?: { [index:string]: Object; }[];

    public constructor(init?: Partial<ExportFromJsonReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportFromJsonReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取机构学生总数
*/
// @Route("/report/school/{SchoolId}/daily/summary/kid", "GET, OPTIONS")
// @Api(Description="获取机构学生总数")
export class GetSchoolDailyKidSummaryReq implements IReturn<GetSchoolDailyKidSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public dimension?: REPORT_DIMENSION;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolDailyKidSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolDailyKidSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolDailyKidSummaryRes(); }
}

/**
* 获取机构学生考勤率
*/
// @Route("/report/school/{SchoolId}/daily/summary/kid", "GET, OPTIONS")
// @Api(Description="获取机构学生考勤率")
export class GetSchoolKidAttendanceSummaryReq implements IReturn<GetSchoolKidAttendanceSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public dimension?: REPORT_DIMENSION;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolKidAttendanceSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidAttendanceSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidAttendanceSummaryRes(); }
}

/**
* 获取机构教师考勤率
*/
// @Route("/report/school/{SchoolId}/daily/summary/teacher", "GET, OPTIONS")
// @Api(Description="获取机构教师考勤率")
export class GetSchoolTeacherAttendanceSummaryReq implements IReturn<GetSchoolTeacherAttendanceSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public dimension?: REPORT_DIMENSION;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolTeacherAttendanceSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTeacherAttendanceSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolTeacherAttendanceSummaryRes(); }
}

/**
* 获取机构发布的动态数
*/
// @Route("/report/school/{SchoolId}/daily/summary/event", "GET, OPTIONS")
// @Api(Description="获取机构发布的动态数")
export class GetSchoolClassEventSummaryReq implements IReturn<GetSchoolClassEventSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public dimension?: REPORT_DIMENSION;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolClassEventSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolClassEventSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolClassEventSummaryRes(); }
}

/**
* 获取机构订单数
*/
// @Route("/report/school/{SchoolId}/daily/summary/order", "GET, OPTIONS")
// @Api(Description="获取机构订单数")
export class GetSchoolOrderSummaryReq implements IReturn<GetSchoolOrderSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public dimension?: REPORT_DIMENSION;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolOrderSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolOrderSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolOrderSummaryRes(); }
}

/**
* 获取机构分润数
*/
// @Route("/report/school/{SchoolId}/daily/summary/submch", "GET, OPTIONS")
// @Api(Description="获取机构分润数")
export class GetSchoolSubMchSummaryReq implements IReturn<GetSchoolSubMchSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public dimension?: REPORT_DIMENSION;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolSubMchSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolSubMchSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolSubMchSummaryRes(); }
}

/**
* 获取机构App端报表
*/
// @Route("/report/school/{SchoolId}/summary/app", "GET")
// @Api(Description="获取机构App端报表")
export class GetSchoolSummaryByAppReq implements IReturn<GetSchoolSummaryByAppRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public dimension?: REPORT_DIMENSION;
    public reportDate?: string;

    public constructor(init?: Partial<GetSchoolSummaryByAppReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolSummaryByAppReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolSummaryByAppRes(); }
}

/**
* 获取机构营销报表线索概况
*/
// @Route("/report/school/{SchoolId}/salelead/summary", "GET")
// @Api(Description="获取机构营销报表线索概况")
export class GetSchoolSaleLeadSummaryReq implements IReturn<GetSchoolSaleLeadSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public branchIds?: string[];
    public dimension?: REPORT_DIMENSION;
    // @Validate(Validator="NotEmpty", Message="BeginDate 不可为空")
    public beginDate?: string;

    // @Validate(Validator="NotEmpty", Message="EndDate 不可为空")
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolSaleLeadSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolSaleLeadSummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolSaleLeadSummaryRes(); }
}

/**
* 获取机构营销报表 线索来源
*/
// @Route("/report/school/{SchoolId}/saleleadsource/summary", "GET")
// @Api(Description="获取机构营销报表 线索来源")
export class GetSchoolSaleLeadSourceSummaryReq implements IReturn<GetSchoolSaleLeadSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public branchIds?: string[];
    public dimension?: REPORT_DIMENSION;
    public source?: string;
    // @Validate(Validator="NotEmpty", Message="BeginDate 不可为空")
    public beginDate?: string;

    // @Validate(Validator="NotEmpty", Message="EndDate 不可为空")
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolSaleLeadSourceSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolSaleLeadSourceSummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolSaleLeadSummaryRes(); }
}

/**
* 获取机构营销报表 线索订单来源
*/
// @Route("/report/school/{SchoolId}/courseordersource/summary", "GET")
// @Api(Description="获取机构营销报表 线索订单来源")
export class GetSchoolCourseOrderSourceSummaryReq implements IReturn<GetSchoolSaleLeadSummaryRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public branchIds?: string[];
    public dimension?: REPORT_DIMENSION;
    public source?: string;
    // @Validate(Validator="NotEmpty", Message="BeginDate 不可为空")
    public beginDate?: string;

    // @Validate(Validator="NotEmpty", Message="EndDate 不可为空")
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolCourseOrderSourceSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolCourseOrderSourceSummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolSaleLeadSummaryRes(); }
}

/**
* 获取机构营销报表 线索订单漏斗图
*/
// @Route("/report/school/{SchoolId}/courseordersource/summary", "GET")
// @Api(Description="获取机构营销报表 线索订单漏斗图")
export class GetSchoolSaleLeadFunnelReq implements IReturn<GetSchoolSaleLeadFunnelRes>
{
    public schoolPartnerInfoId?: string;
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public branchIds?: string[];
    public source?: string;
    // @Validate(Validator="NotEmpty", Message="BeginDate 不可为空")
    public beginDate?: string;

    // @Validate(Validator="NotEmpty", Message="EndDate 不可为空")
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolSaleLeadFunnelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolSaleLeadFunnelReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolSaleLeadFunnelRes(); }
}

/**
* 教师二维码考勤
*/
// @Route("/attendance/attendanceteacherfromqrcode", "POST, OPTIONS")
// @Api(Description="教师二维码考勤")
export class AttendanceTeacherFromQrcodeReq implements IReturn<AttendanceFromQrcodeRes>
{
    public serial?: string;
    public otp?: string;
    public uuid?: string;
    public schoolId?: string;

    public constructor(init?: Partial<AttendanceTeacherFromQrcodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceTeacherFromQrcodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceFromQrcodeRes(); }
}

/**
* 取消绑卡信息
*/
// @Route("/attendance/school/{SchoolId}/bindcards/delete", "DELETE, OPTIONS")
// @Api(Description="取消绑卡信息")
export class DeleteAttendanceBindReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public userMapId?: string;
    public cardSerialNo?: string;

    public constructor(init?: Partial<DeleteAttendanceBindReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteAttendanceBindReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校所有的绑卡信息
*/
// @Route("/attendance/school/{SchoolId}/bindcards", "GET, OPTIONS")
// @Api(Description="获取学校所有的绑卡信息")
export class GetAttendanceBindsReq implements IReturn<GetAttendanceBindsRes>
{
    public schoolId?: string;
    public userName?: string;
    public kidId?: string;
    public teacherUserInfoId?: string;
    public cardOwnerType?: ATTENDANCECARDOWNERTYPE;
    public cardNumber?: string;
    public cardSerialNo?: string;
    public lastModifyOn?: string[];

    public constructor(init?: Partial<GetAttendanceBindsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAttendanceBindsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAttendanceBindsRes(); }
}

/**
* 获取老师的绑卡信息
*/
// @Route("/attendance/teacherbindcards", "GET, OPTIONS")
// @Api(Description="获取老师的绑卡信息")
export class GetTeacherAttendanceBindsReq implements IReturn<GetTeacherAttendanceBindsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetTeacherAttendanceBindsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherAttendanceBindsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherAttendanceBindsRes(); }
}

/**
* 获取学校的所有家长的绑卡信息
*/
// @Route("/attendance/school/{SchoolId}/parentbindcards", "GET, OPTIONS")
// @Api(Description="获取学校的所有家长的绑卡信息")
export class GetSchoolParentAttendanceBindsReq implements IReturn<GetSchoolParentAttendanceBindsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolParentAttendanceBindsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolParentAttendanceBindsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolParentAttendanceBindsRes(); }
}

/**
* 获取学校的所有学生的绑卡信息
*/
// @Route("/attendance/school/{SchoolId}/kidsbindcards", "GET, OPTIONS")
// @Api(Description="获取学校的所有学生的绑卡信息")
export class GetSchoolKidAttendanceBindsReq implements IReturn<GetSchoolKidAttendanceBindsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolKidAttendanceBindsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidAttendanceBindsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidAttendanceBindsRes(); }
}

/**
* 获取学校的所有教师的绑卡信息
*/
// @Route("/attendance/school/{SchoolId}/teacherbindcards", "GET, OPTIONS")
// @Api(Description="获取学校的所有教师的绑卡信息")
export class GetSchoolTeacherAttendanceBindsReq implements IReturn<GetSchoolTeacherAttendanceBindsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolTeacherAttendanceBindsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTeacherAttendanceBindsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolTeacherAttendanceBindsRes(); }
}

/**
* 获取学校的所有教师的绑卡信息
*/
// @Route("/attendance/school/{SchoolId}/bindcards", "GET, OPTIONS")
// @Api(Description="获取学校的所有教师的绑卡信息")
export class GetSchoolAttendanceBindsReq implements IReturn<GetSchoolAttendanceBindsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolAttendanceBindsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolAttendanceBindsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolAttendanceBindsRes(); }
}

/**
* 更新绑卡到老师
*/
// @Route("/attendance/updatebindtoteacher", "POST, OPTIONS")
// @Api(Description="更新绑卡到老师")
export class UpdateKidAttendanceBindTeacherReq implements IReturn<AddAttendanceBindRes>
{
    public schoolId?: string;
    public newCardSerialNo?: string;

    public constructor(init?: Partial<UpdateKidAttendanceBindTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidAttendanceBindTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddAttendanceBindRes(); }
}

/**
* 绑卡到老师
*/
// @Route("/attendance/bindtoteacher", "POST, OPTIONS")
// @Api(Description="绑卡到老师")
export class AddKidAttendanceBindTeacherReq implements IReturn<AddAttendanceBindRes>
{
    public schoolId?: string;
    public cardSerialNo?: string;

    public constructor(init?: Partial<AddKidAttendanceBindTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidAttendanceBindTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddAttendanceBindRes(); }
}

/**
* 教师为教师补考勤
*/
// @Route("/attendance/teacherattendancebyteacher", "POST, OPTIONS")
// @Api(Description="教师为教师补考勤")
export class AttendanceTeacherByTeacherReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public teacherUserInfoIds?: string[];
    public attendanceDate?: string;
    public isCheckIn?: boolean;

    public constructor(init?: Partial<AttendanceTeacherByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceTeacherByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 教师为学生补考勤
*/
// @Route("/attendance/kidattendancebyteacher", "POST, OPTIONS")
// @Api(Description="教师为学生补考勤")
export class AttendanceKidByTeacherReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public kidIds?: string[];
    public attendanceDate?: string;

    public constructor(init?: Partial<AttendanceKidByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceKidByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 从终端机上发起打卡请求
*/
// @Route("/attendance/attendancefromterminal", "POST, OPTIONS")
// @Api(Description="从终端机上发起打卡请求")
export class AttendanceFromTerminalReq implements IReturn<AttendanceFromTerminalRes>
{
    public card?: string;
    public mac?: string;
    public serial?: string;
    public uuid?: string;
    public isSync?: boolean;
    public checkTime?: string;
    public attendanceMethod?: ATTENDANCEMETHOD;
    public healthTemperature?: string;
    public healthTemperatureStatus?: ATTENDANCE_HEALTH_TEMPERATURE_STATUS;

    public constructor(init?: Partial<AttendanceFromTerminalReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceFromTerminalReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceFromTerminalRes(); }
}

/**
* 获取登录二维码
*/
// @Route("/attendance/getloginqrcode", "POST, OPTIONS")
// @Api(Description="获取登录二维码")
export class AttendanceGetLoginCodeReq implements IReturn<AttendanceGetLoginCodeRes>
{
    public serialNo?: string;

    public constructor(init?: Partial<AttendanceGetLoginCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceGetLoginCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceGetLoginCodeRes(); }
}

/**
* 刷新登录二维码的状态
*/
// @Route("/attendance/getloginqrcodestatus", "POST, OPTIONS")
// @Api(Description="刷新登录二维码的状态")
export class AttendanceCheckLoginTicketReq implements IReturn<AttendanceCheckLoginTicketRes>
{
    public serialNo?: string;
    public ticket?: string;

    public constructor(init?: Partial<AttendanceCheckLoginTicketReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceCheckLoginTicketReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceCheckLoginTicketRes(); }
}

/**
* 考勤管理员在移动端授权登录
*/
// @Route("/attendance/loginbymanager", "POST, OPTIONS")
// @Api(Description="考勤管理员在移动端授权登录")
export class AttendanceDeviceLoginByManageReq implements IReturn<AttendanceDeviceLoginByManageRes>
{
    public schoolId?: string;
    public serialNo?: string;
    public ticket?: string;
    public act?: number;

    public constructor(init?: Partial<AttendanceDeviceLoginByManageReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceDeviceLoginByManageReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceDeviceLoginByManageRes(); }
}

/**
* 设置教师的考勤时段
*/
// @Route("/attendance/{SchoolId}/setteachertemplate", "POST, OPTIONS")
// @Api(Description="设置教师的考勤时段")
export class SetTeacherAttendanceTemplateReq implements IReturn<SetTeacherAttendanceTemplateRes>
{
    public schoolId?: string;
    public teacherUserInfoId?: string;
    public attendanceTemplateId?: string;

    public constructor(init?: Partial<SetTeacherAttendanceTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetTeacherAttendanceTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetTeacherAttendanceTemplateRes(); }
}

/**
* 增加学校的考勤时段
*/
// @Route("/attendance/{SchoolId}/teachertemplate", "POST, OPTIONS")
// @Api(Description="增加学校的考勤时段")
export class AddAttendanceTeacherTemplateReq implements IReturn<AddAttendanceTeacherTemplateRes>
{
    public templateId?: string;
    public schoolId?: string;
    public name?: string;
    public followSchoolCalendar?: boolean;
    public timeConfigs?: AttendanceTimeConfigDTO[];

    public constructor(init?: Partial<AddAttendanceTeacherTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddAttendanceTeacherTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddAttendanceTeacherTemplateRes(); }
}

/**
* 获取学校的考勤时段
*/
// @Route("/attendance/{SchoolId}/teachertemplates", "GET, OPTIONS")
// @Api(Description="获取学校的考勤时段")
export class GetAttendanceTeacherTemplateReq implements IReturn<GetAttendanceTeacherTemplateRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetAttendanceTeacherTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAttendanceTeacherTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAttendanceTeacherTemplateRes(); }
}

/**
* 获取学校所有学生的索引信息
*/
// @Route("/attendance/{SchoolId}/studentindex", "GET, OPTIONS")
// @Api(Description="获取学校所有学生的索引信息")
export class AttendanceStudentIndexReq implements IReturn<AttendanceStudentIndexRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<AttendanceStudentIndexReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceStudentIndexReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceStudentIndexRes(); }
}

/**
* 获取学校所有家长的索引信息
*/
// @Route("/attendance/{SchoolId}/parentindex", "GET, OPTIONS")
// @Api(Description="获取学校所有家长的索引信息")
export class AttendanceParentIndexReq implements IReturn<AttendanceParentIndexRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<AttendanceParentIndexReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceParentIndexReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceParentIndexRes(); }
}

/**
* 获取学校家长的详细信息
*/
// @Route("/attendance/{SchoolId}/parentinfos", "POST, OPTIONS")
// @Api(Description="获取学校家长的详细信息")
export class AttendanceParentDataReq implements IReturn<AttendanceParentDataRes>
{
    public schoolId?: string;
    public parentRelationSchoolInfoIds?: string[];

    public constructor(init?: Partial<AttendanceParentDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceParentDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceParentDataRes(); }
}

/**
* 获取学校学生的详细信息
*/
// @Route("/attendance/{SchoolId}/studentinfos", "POST, OPTIONS")
// @Api(Description="获取学校学生的详细信息")
export class AttendanceStudentDataReq implements IReturn<AttendanceStudentDataRes>
{
    public schoolId?: string;
    public studentIds?: string[];

    public constructor(init?: Partial<AttendanceStudentDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceStudentDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceStudentDataRes(); }
}

/**
* 获取学校所有老师的索引信息
*/
// @Route("/attendance/{SchoolId}/teacherindex", "GET, OPTIONS")
// @Api(Description="获取学校所有老师的索引信息")
export class AttendanceTeacherIndexReq implements IReturn<AttendanceTeacherIndexRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<AttendanceTeacherIndexReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceTeacherIndexReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceTeacherIndexRes(); }
}

/**
* 获取学校老师的详细信息
*/
// @Route("/attendance/{SchoolId}/teacherinfos", "POST, OPTIONS")
// @Api(Description="获取学校老师的详细信息")
export class AttendanceTeacherDataReq implements IReturn<AttendanceTeacherDataRes>
{
    public schoolId?: string;
    public teacherUserInfoIds?: string[];

    public constructor(init?: Partial<AttendanceTeacherDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceTeacherDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceTeacherDataRes(); }
}

/**
* 终端机获取显示信息
*/
// @Route("/attendance/device/{Serial}", "GET, OPTIONS")
// @Api(Description="终端机获取显示信息")
export class GetSchoolAttendanceDeviceReq implements IReturn<GetSchoolAttendanceDeviceRes>
{
    public serial?: string;

    public constructor(init?: Partial<GetSchoolAttendanceDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolAttendanceDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolAttendanceDeviceRes(); }
}

/**
* 更新终端机显示的图片及公告
*/
// @Route("/attendance/school/{SchoolId}/device/{DeviceId}", "POST, OPTIONS")
// @Api(Description="更新终端机显示的图片及公告")
export class UpdateAttendanceDeviceReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public deviceId?: string;
    public images?: string[];
    public notices?: string[];
    public remark?: string;
    public audioTemplates?: { [index: string]: string; };
    public healthyTimeout?: number;
    public healthyTimeoutSubmit?: boolean;

    public constructor(init?: Partial<UpdateAttendanceDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateAttendanceDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 终端机心跳请求
*/
// @Route("/attendance/heartbeatfromterminal", "POST, OPTIONS")
// @Api(Description="终端机心跳请求")
export class AttendanceHeartbeatFromTerminalReq implements IReturn<BaseResponse>
{
    public serial?: string;
    public now?: string;
    public ver?: string;
    public mac?: string;
    public teacherDataCount?: number;
    public parentDataCount?: number;
    public studentDataCount?: number;
    public offlineDataCount?: number;
    public aiFaceCount?: number;
    public teacherSyncTimestamp?: number;
    public parentSyncTimestamp?: number;
    public studentSyncTimestamp?: number;
    public offlineSyncTimestamp?: number;
    public ftInitState?: number;
    public ftLastErr?: string;

    public constructor(init?: Partial<AttendanceHeartbeatFromTerminalReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceHeartbeatFromTerminalReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 通道机心跳请求
*/
// @Api(Description="通道机心跳请求")
export class AttendanceHeartbeatFromChannelReq
{
    public serial?: string;
    public id?: string;
    public key?: string;
    public status?: string;
    public now?: string;
    public ver?: string;
    public reader?: number;
    public door?: number;
    public panel?: number;
    public doorStatus?: number;
    public ipAddr?: string;

    public constructor(init?: Partial<AttendanceHeartbeatFromChannelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceHeartbeatFromChannelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 通道机刷卡请求
*/
// @Api(Description="通道机刷卡请求")
export class AttendanceFromChannelReq
{
    public type?: string;
    public serial?: string;
    public id?: string;
    public reader?: string;
    public status?: string;
    public index?: string;
    public mac?: string;
    public indexEvent?: string;
    public indexAlarm?: string;
    public door?: string;
    public eventType?: CHANNEL_EVENTTYPE;
    public eventTime?: string;
    public card?: string;
    public value?: string;
    public valueType?: string;

    public constructor(init?: Partial<AttendanceFromChannelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceFromChannelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 列取教师出勤信息
*/
// @Route("/attendance/school/{SchoolId}/teacher/attendance", "GET, OPTIONS")
// @Api(Description="列取教师出勤信息")
export class GetTeacherAttendanceReq implements IReturn<GetTeacherAttendanceRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public teacherUserInfoId?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetTeacherAttendanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherAttendanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherAttendanceRes(); }
}

/**
* 列取指定班级的学生出勤
*/
// @Route("/attendance/school/{SchoolId}/class/{ClassId}/attendance", "GET, OPTIONS")
// @Api(Description="列取指定班级的学生出勤")
export class GetClassAttendanceLogReq implements IReturn<GetClassAttendanceLogRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="ClassId不可为空")
    public classId?: string;

    // @Validate(Validator="NotEmpty", Message="DateOn不可为空")
    public dateOn?: string;

    public constructor(init?: Partial<GetClassAttendanceLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassAttendanceLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassAttendanceLogRes(); }
}

/**
* 列取学生出勤
*/
// @Route("/attendance/school/{SchoolId}/kid/attendance", "GET, OPTIONS")
// @Api(Description="列取学生出勤")
export class GetKidsAttendanceLogReq implements IReturn<GetKidsAttendanceLogRes>
{
    public schoolId?: string;
    public dateOn?: string;
    public pageSize?: number;

    public constructor(init?: Partial<GetKidsAttendanceLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidsAttendanceLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidsAttendanceLogRes(); }
}

/**
* 列取班级学生出勤日统计
*/
// @Route("/attendance/school/{SchoolId}/class/attendance/kids", "GET, OPTIONS")
// @Api(Description="列取班级学生出勤日统计")
export class GetClassKidsAttendanceDailyReportReq implements IReturn<GetClassKidsAttendanceDailyReportRes>
{
    public schoolId?: string;
    public classId?: string;
    public dateOn?: string;

    public constructor(init?: Partial<GetClassKidsAttendanceDailyReportReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassKidsAttendanceDailyReportReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassKidsAttendanceDailyReportRes(); }
}

/**
* 班级出勤日报
*/
// @Route("/attendance/school/{SchoolId}/class/attendance/dailyreport", "GET, OPTIONS")
// @Api(Description="班级出勤日报")
export class GetClassAttendanceDailyReportReq implements IReturn<GetClassAttendanceDailyReportRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public dateOn?: string;

    public constructor(init?: Partial<GetClassAttendanceDailyReportReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassAttendanceDailyReportReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassAttendanceDailyReportRes(); }
}

/**
* 班级出勤月报
*/
// @Route("/attendance/school/{SchoolId}/class/attendance/monthreport", "GET, OPTIONS")
// @Api(Description="班级出勤月报")
export class GetClassAttendanceMonthReportReq implements IReturn<GetClassAttendanceMonthReportRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public dateOn?: string;

    public constructor(init?: Partial<GetClassAttendanceMonthReportReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassAttendanceMonthReportReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassAttendanceMonthReportRes(); }
}

/**
* 班级学生出勤月报导出excel
*/
// @Route("/attendance/school/{SchoolId}/class/attendance/monthreport/excel", "GET, OPTIONS")
// @Api(Description="班级学生出勤月报导出excel")
export class GetClassKidsAttendanceMonthReportExcelReq implements IReturn<Blob>
{
    public schoolId?: string;
    public branchIds?: string[];
    public classId?: string;
    public dateOn?: string;
    public checkGroupMaxDays?: number;

    public constructor(init?: Partial<GetClassKidsAttendanceMonthReportExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassKidsAttendanceMonthReportExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 班级学生出勤月报
*/
// @Route("/attendance/school/{SchoolId}/class/{ClassId}/attendance/monthreport", "GET, OPTIONS")
// @Api(Description="班级学生出勤月报")
export class GetClassKidsAttendanceMonthReportReq implements IReturn<GetClassKidsAttendanceMonthReportRes>
{
    public schoolId?: string;
    public classId?: string;
    public dateOn?: string;

    public constructor(init?: Partial<GetClassKidsAttendanceMonthReportReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassKidsAttendanceMonthReportReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassKidsAttendanceMonthReportRes(); }
}

/**
* 列取教师出勤详情
*/
// @Route("/attendance/school/{SchoolId}/teacher/attendancelog", "GET, OPTIONS")
// @Api(Description="列取教师出勤详情")
export class GetTeacherAttendanceLogReq implements IReturn<GetTeacherAttendanceLogRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public teacherUserInfoId?: string;
    public beginDate?: string;
    public endDate?: string;
    public lastId?: string;
    public pageSize?: number;

    public constructor(init?: Partial<GetTeacherAttendanceLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherAttendanceLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherAttendanceLogRes(); }
}

/**
* 教师考勤月报
*/
// @Route("/attendance/school/{SchoolId}/teacher/monthreport", "GET, OPTIONS")
// @Api(Description="教师考勤月报")
export class GetTeacherAttendanceMonthReportReq implements IReturn<GetTeacherAttendanceMonthReportRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public reportDateStart?: string;
    public reportDateEnd?: string;

    public constructor(init?: Partial<GetTeacherAttendanceMonthReportReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherAttendanceMonthReportReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherAttendanceMonthReportRes(); }
}

/**
* 教师考勤月报
*/
// @Route("/attendance/school/{SchoolId}/teacher/monthreport", "GET, OPTIONS")
// @Api(Description="教师考勤月报")
export class GetTeacherAttendanceMonthReportRateReq implements IReturn<GetTeacherAttendanceMonthReportRateRes>
{
    public schoolId?: string;
    public reportDateStart?: string;
    public reportDateEnd?: string;

    public constructor(init?: Partial<GetTeacherAttendanceMonthReportRateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherAttendanceMonthReportRateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherAttendanceMonthReportRateRes(); }
}

/**
* 教师考勤月报
*/
// @Route("/attendance/school/{SchoolId}/teacher/monthreport/excel", "GET, OPTIONS")
// @Api(Description="教师考勤月报")
export class GetTeacherAttendanceMonthReportExcelReq implements IReturn<Blob>
{
    public schoolId?: string;
    public branchIds?: string[];
    public reportDateStart?: string;

    public constructor(init?: Partial<GetTeacherAttendanceMonthReportExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherAttendanceMonthReportExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 教师考勤日报
*/
// @Route("/attendance/school/{SchoolId}/teacher/dailyreport", "GET, OPTIONS")
// @Api(Description="教师考勤日报")
export class GetTeacherAttendanceDailyReportReq implements IReturn<GetTeacherAttendanceDailyReportRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public reportDateStart?: string;
    public reportDateEnd?: string;

    public constructor(init?: Partial<GetTeacherAttendanceDailyReportReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherAttendanceDailyReportReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherAttendanceDailyReportRes(); }
}

/**
* 获取学校的原始校历
*/
// @Route("/attendance/{SchoolId}/calendar/{Year}", "GET, OPTIONS")
// @Api(Description="获取学校的原始校历")
export class GetSchoolCalendarByYearReq implements IReturn<GetSchoolCalendarByYearRes>
{
    public schoolId?: string;
    public year?: number;

    public constructor(init?: Partial<GetSchoolCalendarByYearReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolCalendarByYearReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolCalendarByYearRes(); }
}

/**
* 获取学校的假期信息
*/
// @Route("/attendance/{SchoolId}/yearcalendar/{Year}", "GET, OPTIONS")
// @Api(Description="获取学校的假期信息")
export class GetSchoolYearCalendarReq implements IReturn<GetSchoolYearCalendarRes>
{
    public schoolId?: string;
    public year?: number;

    public constructor(init?: Partial<GetSchoolYearCalendarReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolYearCalendarReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolYearCalendarRes(); }
}

/**
* 获取法定假日信息
*/
// @Route("/attendance/chinesecalendar/{Year}", "GET, OPTIONS")
// @Api(Description="获取法定假日信息")
export class GetChineseCalendarDefineReq implements IReturn<GetChineseCalendarDefineRes>
{
    public year?: number;

    public constructor(init?: Partial<GetChineseCalendarDefineReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetChineseCalendarDefineReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetChineseCalendarDefineRes(); }
}

/**
* 设置学校的假期信息
*/
// @Route("/attendance/{SchoolId}/yearcalendar/{Year}", "POST, OPTIONS")
// @Api(Description="设置学校的假期信息")
export class SetSchoolYearCalendarReq implements IReturn<SetSchoolYearCalendarRes>
{
    public schoolId?: string;
    public year?: number;
    public workDays?: string[];
    public noWorkDays?: string[];

    public constructor(init?: Partial<SetSchoolYearCalendarReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetSchoolYearCalendarReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetSchoolYearCalendarRes(); }
}

/**
* 设置法定假日信息
*/
// @Route("/attendance/chinesecalendar/{Year}", "POST, OPTIONS")
// @Api(Description="设置法定假日信息")
export class SetChineseCalendarDefineReq implements IReturn<SetChineseCalendarDefineRes>
{
    public year?: number;
    public holidays?: HolidayItemDTO[];
    public workDays?: string[];

    public constructor(init?: Partial<SetChineseCalendarDefineReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetChineseCalendarDefineReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetChineseCalendarDefineRes(); }
}

export class AttendanceTerminalLoadDataReq
{
    public schoolId?: string;
    public serial?: string;
    public ownerType?: number;
    public isLoadAll?: boolean;

    public constructor(init?: Partial<AttendanceTerminalLoadDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceTerminalLoadDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 教师获取代接送列表
*/
// @Route("/attendance/school/{SchoolId}/teacher/commissionpickups", "GET, OPTIONS")
// @Api(Description="教师获取代接送列表")
export class GetCommissionPickupByTeacherReq implements IReturn<GetCommissionPickupRes>
{
    public schoolId?: string;
    public classId?: string;
    public lastId?: string;

    public constructor(init?: Partial<GetCommissionPickupByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCommissionPickupByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCommissionPickupRes(); }
}

/**
* 教师创建代接送
*/
// @Route("/attendance/school/{SchoolId}/teacher/commissionpickups", "POST, OPTIONS")
// @Api(Description="教师创建代接送")
export class CreateCommissionPickupReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public studentId?: string;
    public pickuperRelationName?: string;
    public pickuperRealName?: string;
    public pickuperPhotoOssId?: string;

    public constructor(init?: Partial<CreateCommissionPickupReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateCommissionPickupReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 教师取消代接送
*/
// @Route("/attendance/school/{SchoolId}/teacher/cancelcommissionpickup", "POST, OPTIONS")
// @Api(Description="教师取消代接送")
export class CancelCommissionPickupReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public pickupId?: string;

    public constructor(init?: Partial<CancelCommissionPickupReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelCommissionPickupReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 教师提醒代接送
*/
// @Route("/attendance/school/{SchoolId}/teacher/notifycommissionpickup", "POST, OPTIONS")
// @Api(Description="教师提醒代接送")
export class NotifyCommissionParentReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public pickupId?: string;

    public constructor(init?: Partial<NotifyCommissionParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NotifyCommissionParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 福盈带健康码人脸头回调
*/
// @Route("/attendance/fuyingcb", "POST, OPTIONS")
// @Api(Description="福盈带健康码人脸头回调")
export class AttendanceFuyinCallbackReq implements IReturn<HealthyGateDeviceRecordResp>
{

    public constructor(init?: Partial<AttendanceFuyinCallbackReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceFuyinCallbackReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HealthyGateDeviceRecordResp(); }
}

/**
* Unibui回调
*/
// @Route("/attendance/unibuicb", "POST, OPTIONS")
// @Api(Description="Unibui回调")
export class AttendanceUniubiCallbackReq
{
    public eventCode?: number;
    public eventGuid?: string;
    public eventMsg?: string;

    public constructor(init?: Partial<AttendanceUniubiCallbackReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceUniubiCallbackReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 添加考勤闸机学校配置
*/
// @Route("/attendance/attendanceschoolconfigs", "POST, OPTIONS")
// @Api(Description="添加考勤闸机学校配置")
export class AddAttendanceSchoolConfigReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public uniubiAppId?: string;
    public uniubiAppKey?: string;
    public uniubiAppSecret?: string;

    public constructor(init?: Partial<AddAttendanceSchoolConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddAttendanceSchoolConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加考勤闸机控制器
*/
// @Route("/attendance/attendancedoorcontrollers", "POST, OPTIONS")
// @Api(Description="添加考勤闸机控制器")
export class AddAttendanceDoorControllerReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不能为空")
    public schoolId?: string;

    public id?: string;
    // @Validate(Validator="NotEmpty", Message="DeviceNo不能为空")
    public deviceNo?: string;

    public constructor(init?: Partial<AddAttendanceDoorControllerReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddAttendanceDoorControllerReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加考勤闸机门
*/
// @Route("/attendance/attendancedoors", "POST, OPTIONS")
// @Api(Description="添加考勤闸机门")
export class AddAttendanceDoorReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不能为空")
    public schoolId?: string;

    public id?: string;
    // @Validate(Validator="NotEmpty", Message="AttendanceDoorControllerId不能为空")
    public attendanceDoorControllerId?: string;

    // @Validate(Validator="NotEmpty", Message="DoorIndex不能为空")
    public doorIndex: number;

    // @Validate(Validator="NotEmpty", Message="DoorName不能为空")
    public name?: string;

    public constructor(init?: Partial<AddAttendanceDoorReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddAttendanceDoorReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取设备当前配置
*/
// @Route("/attendance/getdeviceconfig", "POST, OPTIONS")
// @Api(Description="获取设备当前配置")
export class AttendanceDeviceGetConfigReq implements IReturn<AttendanceDeviceGetConfigRes>
{
    public deviceId?: string;

    public constructor(init?: Partial<AttendanceDeviceGetConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceDeviceGetConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceDeviceGetConfigRes(); }
}

/**
* 设备重启请求
*/
// @Route("/attendance/devicereboot", "POST, OPTIONS")
// @Api(Description="设备重启请求")
export class AttendanceDeviceRebootReq implements IReturn<BaseResponse>
{
    public deviceId?: string;

    public constructor(init?: Partial<AttendanceDeviceRebootReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceDeviceRebootReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设备开门请求
*/
// @Route("/attendance/opendoor", "POST, OPTIONS")
// @Api(Description="设备开门请求")
export class AttendanceDeviceOpenDoorReq implements IReturn<BaseResponse>
{
    public deviceId?: string;

    public constructor(init?: Partial<AttendanceDeviceOpenDoorReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceDeviceOpenDoorReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设备ui请求
*/
// @Route("/attendance/deviceui", "POST, OPTIONS")
// @Api(Description="设备ui请求")
export class AttendanceDeviceSetUiReq implements IReturn<BaseResponse>
{
    public deviceId?: string;
    public uiType?: string;
    public uiUrl?: string;

    public constructor(init?: Partial<AttendanceDeviceSetUiReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceDeviceSetUiReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设备设置配置请求
*/
// @Route("/attendance/setdeviceconfig", "POST, OPTIONS")
// @Api(Description="设备设置配置请求")
export class AttendanceDeviceSetConfigReq extends AttendanceDeviceSetConfigItem implements IReturn<BaseResponse>
{
    public deviceId?: string;

    public constructor(init?: Partial<AttendanceDeviceSetConfigReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceDeviceSetConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设备升级请求
*/
// @Route("/attendance/deviceupgrade", "POST, OPTIONS")
// @Api(Description="设备升级请求")
export class AttendanceDeviceUpgradeReq implements IReturn<BaseResponse>
{
    public deviceId?: string;
    public updateUrl?: string;
    public updateType?: string;
    public updateKey?: string;

    public constructor(init?: Partial<AttendanceDeviceUpgradeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceDeviceUpgradeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare配置
*/
// @Route("/babycare/{SchoolId}/setconfig", "POST, OPTIONS")
// @Api(Description="添加babycare配置")
export class SetBabyCareConfigReq implements IReturn<SchoolBabyCareConfigRes>
{
    public schoolId?: string;
    public configKey?: string;
    public configValue?: string;

    public constructor(init?: Partial<SetBabyCareConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SchoolBabyCareConfigRes(); }
}

/**
* 获取学校babycare配置
*/
// @Route("/babycare/{SchoolId}/config", "GET, OPTIONS")
// @Api(Description="获取学校babycare配置")
export class GetBabyCareConfigReq implements IReturn<SchoolBabyCareConfigRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetBabyCareConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SchoolBabyCareConfigRes(); }
}

/**
* 获取批量操作日志
*/
// @Route("/babycare/{SchoolId}/batchlogs", "GET, OPTIONS")
// @Api(Description="获取批量操作日志")
export class GetDailyCareBatchLogsReq implements IReturn<GetDailyCareBatchLogsRes>
{
    public schoolId?: string;
    public classId?: string;

    public constructor(init?: Partial<GetDailyCareBatchLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyCareBatchLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyCareBatchLogsRes(); }
}

/**
* 删除批量操作
*/
// @Route("/babycare/{SchoolId}/batchlog/delete", "POST, OPTIONS")
// @Api(Description="删除批量操作")
export class DeleteDailyCareBatchLogReq implements IReturn<BaseResponse>
{
    public batchLogId?: string;
    public schoolId?: string;
    public classId?: string;

    public constructor(init?: Partial<DeleteDailyCareBatchLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDailyCareBatchLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare喝水
*/
// @Route("/babycare/{SchoolId}/watercares", "POST, OPTIONS")
// @Api(Description="添加babycare喝水")
export class AddBabyCareWaterReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareWaterReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareWaterReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare厕所
*/
// @Route("/babycare/{SchoolId}/toiletcares", "POST, OPTIONS")
// @Api(Description="添加babycare厕所")
export class AddBabyCareToiletReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public toiletType?: DAILYCARE_TOILET_TYPE;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareToiletReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareToiletReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare尿布
*/
// @Route("/babycare/{SchoolId}/diapercares", "POST, OPTIONS")
// @Api(Description="添加babycare尿布")
export class AddBabyCareDiaperReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public toiletType?: DAILYCARE_TOILET_TYPE;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareDiaperReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareDiaperReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare喝奶
*/
// @Route("/babycare/{SchoolId}/milkcares", "POST, OPTIONS")
// @Api(Description="添加babycare喝奶")
export class AddBabyCareMilkReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public milkVol?: number;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareMilkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareMilkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare体温
*/
// @Route("/babycare/{SchoolId}/tempcares", "POST, OPTIONS")
// @Api(Description="添加babycare体温")
export class AddBabyCareTempReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public checkTime?: string;
    public temp?: string;
    public memo?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareTempReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareTempReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare早点
*/
// @Route("/babycare/{SchoolId}/breakfastcares", "POST, OPTIONS")
// @Api(Description="添加babycare早点")
export class SetBabyCareBreakfast implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public eatStatus?: DAILYCARE_EAT_STATUS;
    public memo?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareBreakfast>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareBreakfast'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare午餐
*/
// @Route("/babycare/{SchoolId}/lunchcares", "POST, OPTIONS")
// @Api(Description="添加babycare午餐")
export class SetBabyCareLunch implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public eatStatus?: DAILYCARE_EAT_STATUS;
    public memo?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareLunch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareLunch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare午点
*/
// @Route("/babycare/{SchoolId}/snackcares", "POST, OPTIONS")
// @Api(Description="添加babycare午点")
export class SetBabyCareSnack implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public kidIds?: string[];
    public eatStatus?: DAILYCARE_EAT_STATUS;
    public memo?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareSnack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareSnack'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare午睡
*/
// @Route("/babycare/{SchoolId}/sleepcares", "POST, OPTIONS")
// @Api(Description="添加babycare午睡")
export class SetBabyCareSleep implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public sleepOn?: string;
    public wakeupOn?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareSleep>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareSleep'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare 日点评
*/
// @Route("/babycare/{SchoolId}/notecares", "POST, OPTIONS")
// @Api(Description="添加babycare 日点评")
export class SetBabyCareNote implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public note?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareNote>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareNote'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare 周点评
*/
// @Route("/babycare/{SchoolId}/weeknotecares", "POST, OPTIONS")
// @Api(Description="添加babycare 周点评")
export class SetBabyCareNoteWeek implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public note?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<SetBabyCareNoteWeek>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareNoteWeek'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校babycare单人数据
*/
// @Route("/babycare/{SchoolId}/kidcares", "GET, OPTIONS")
// @Api(Description="获取学校babycare单人数据")
export class GetKidDailyCareBasesReq implements IReturn<GetDailyCareBasesRes>
{
    public schoolId?: string;
    public classId?: string;
    public recordDateOn?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetKidDailyCareBasesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidDailyCareBasesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyCareBasesRes(); }
}

/**
* 设置学校babycare检查状态
*/
// @Route("/babycare/{SchoolId}/cares/inspected", "POST, OPTIONS")
// @Api(Description="设置学校babycare检查状态")
export class SetDailyCareInspectedReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public ids?: string[];
    public isInspected?: boolean;

    public constructor(init?: Partial<SetDailyCareInspectedReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetDailyCareInspectedReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设置学校BabyCareTaskWork检查状态
*/
// @Route("/babycare/{SchoolId}/careworks/inspected", "POST, OPTIONS")
// @Api(Description="设置学校BabyCareTaskWork检查状态")
export class SetBabyCareTaskWorksInspectedReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public ids?: string[];
    public isInspected?: boolean;

    public constructor(init?: Partial<SetBabyCareTaskWorksInspectedReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetBabyCareTaskWorksInspectedReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校babycare数据
*/
// @Route("/babycare/{SchoolId}/cares", "GET, OPTIONS")
// @Api(Description="获取学校babycare数据")
export class GetDailyCareBasesReq implements IReturn<GetDailyCareBasesRes>
{
    public schoolId?: string;
    public classId?: string;
    public recordDateOn?: string;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetDailyCareBasesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyCareBasesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyCareBasesRes(); }
}

/**
* 获取学校babycare定时提示信息
*/
// @Route("/babycare/{SchoolId}/carenotice", "GET, OPTIONS")
// @Api(Description="获取学校babycare定时提示信息")
export class GetDailyCareNoticeReq implements IReturn<GetDailyCareNoticeRes>
{
    public schoolId?: string;
    public classId?: string;

    public constructor(init?: Partial<GetDailyCareNoticeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyCareNoticeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyCareNoticeRes(); }
}

/**
* 人脸识别登录
*/
// @Route("/babycare/{SchoolId}/loginbyface", "POST, OPTIONS")
// @Api(Description="人脸识别登录")
export class BabyCareDeviceLoginByFaceReq implements IReturn<BabyCareDeviceLoginByFaceRes>
{
    public serialNo?: string;
    public featureData?: string;

    public constructor(init?: Partial<BabyCareDeviceLoginByFaceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BabyCareDeviceLoginByFaceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BabyCareDeviceLoginByFaceRes(); }
}

/**
* 教师在移动端授权登录care
*/
// @Route("/babycare/loginbymanager", "POST, OPTIONS")
// @Api(Description="教师在移动端授权登录care")
export class BabyCareDeviceLoginByTeacherReq implements IReturn<BabyCareDeviceLoginByTeacherRes>
{
    public schoolId?: string;
    public serialNo?: string;
    public ticket?: string;
    public act?: number;

    public constructor(init?: Partial<BabyCareDeviceLoginByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BabyCareDeviceLoginByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BabyCareDeviceLoginByTeacherRes(); }
}

/**
* 获取Babycare登录二维码
*/
// @Route("/babycare/getloginqrcode", "POST, OPTIONS")
// @Api(Description="获取Babycare登录二维码")
export class BabyCareGetLoginCodeReq implements IReturn<BabyCareGetLoginCodeRes>
{
    public serialNo?: string;

    public constructor(init?: Partial<BabyCareGetLoginCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BabyCareGetLoginCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BabyCareGetLoginCodeRes(); }
}

/**
* 刷新babycare登录二维码的状态
*/
// @Route("/babycare/getloginqrcodestatus", "POST, OPTIONS")
// @Api(Description="刷新babycare登录二维码的状态")
export class BabyCareCheckLoginTicketReq implements IReturn<BabyCareCheckLoginTicketRes>
{
    public serialNo?: string;
    public ticket?: string;

    public constructor(init?: Partial<BabyCareCheckLoginTicketReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BabyCareCheckLoginTicketReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BabyCareCheckLoginTicketRes(); }
}

/**
* 获取学校babycare设备信息
*/
// @Route("/babycare/{SchoolId}/device/{SerialNo}", "GET, OPTIONS")
// @Api(Description="获取学校babycare设备信息")
export class GetBabyCareDeviceInfoReq implements IReturn<GetBabyCareDeviceInfoRes>
{
    public schoolId?: string;
    public serialNo?: string;

    public constructor(init?: Partial<GetBabyCareDeviceInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareDeviceInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareDeviceInfoRes(); }
}

/**
* 获取学校babycare设备BYOD授权信息
*/
// @Route("/babycare/{SchoolId}/byodlicenses", "GET, OPTIONS")
// @Api(Description="获取学校babycare设备BYOD授权信息")
export class GetBabyCareBYODDeviceLicenseReq implements IReturn<GetBabyCareBYODDeviceLicenseRes>
{
    public schoolId?: string;
    public serialNo?: string;

    public constructor(init?: Partial<GetBabyCareBYODDeviceLicenseReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareBYODDeviceLicenseReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareBYODDeviceLicenseRes(); }
}

/**
* 添加babycare设备BYOD授权
*/
// @Route("/babycare/{SchoolId}/byodlicenses", "POST, OPTIONS")
// @Api(Description="添加babycare设备BYOD授权")
export class AddBabyCareBYODDeviceLicenseReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public deviceCount?: number;
    public dayCount?: number;

    public constructor(init?: Partial<AddBabyCareBYODDeviceLicenseReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareBYODDeviceLicenseReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 绑定babycare设备BYOD授权
*/
// @Route("/babycare/{SchoolId}/byoddevices", "POST, OPTIONS")
// @Api(Description="绑定babycare设备BYOD授权")
export class AddBabyCareBYODDeviceReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public licenseId?: string;
    public serialNo?: string;
    public macAddr?: string;

    public constructor(init?: Partial<AddBabyCareBYODDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareBYODDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加babycare设备
*/
// @Route("/babycare/{SchoolId}/devices", "POST, OPTIONS")
// @Api(Description="添加babycare设备")
export class AddBabyCareDeviceReq implements IReturn<BaseResponse>
{
    public id?: string;
    public schoolId?: string;
    public serialNo?: string;
    public macAddr?: string;
    public remark?: string;
    public liveChannelIds?: string[];

    public constructor(init?: Partial<AddBabyCareDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取已注册的babycare设备
*/
// @Route("/babycare/devices", "GET, OPTIONS")
// @Api(Description="获取已注册的babycare设备")
export class GetBabyCareDevicesReq implements IReturn<GetBabyCareDevicesRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public serialNo?: string;
    public macAddr?: string;
    public remark?: string;
    public isBYOD?: boolean;
    public osType?: OSTYPE;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetBabyCareDevicesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareDevicesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareDevicesRes(); }
}

/**
* 导入学校babycare任务列表
*/
// @Route("/babycare/{SchoolId}/tasks/import", "POST, OPTIONS")
// @Api(Description="导入学校babycare任务列表")
export class ImportBabyCareTasksReq implements IReturn<BaseResponse>
{
    public schoolId?: string;

    public constructor(init?: Partial<ImportBabyCareTasksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportBabyCareTasksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校babycare任务列表
*/
// @Route("/babycare/{SchoolId}/tasks", "GET, OPTIONS")
// @Api(Description="获取学校babycare任务列表")
export class GetBabyCareTasksReq implements IReturn<GetBabyCareTasksRes>
{
    public schoolId?: string;
    public classId?: string;
    public showTodayHistory?: boolean;
    public dateOn?: string;
    public taskName?: string;
    public isEnabled?: boolean;

    public constructor(init?: Partial<GetBabyCareTasksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareTasksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareTasksRes(); }
}

/**
* 导出学校babycare任务结果
*/
// @Route("/babycare/{SchoolId}/tasks/excel", "GET, OPTIONS")
// @Api(Description="导出学校babycare任务结果")
export class ExportBabyCareTasksReq implements IReturn<Blob>
{
    public schoolId?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<ExportBabyCareTasksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportBabyCareTasksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取学校BabyCareTaskWork数据
*/
// @Route("/babycare/{SchoolId}/caretaskworks", "GET, OPTIONS")
// @Api(Description="获取学校BabyCareTaskWork数据")
export class GetBabyCareTaskWorksReq implements IReturn<GetBabyCareTaskWorksRes>
{
    public schoolId?: string;
    public classId?: string;
    public status?: DAILYCARE_WORK_STATUS;
    public dailyCareDeviceId?: string;
    public babyCareTaskId?: string;
    public babyCareTaskFlowId?: string;
    public babyCareTaskFlowIds?: string[];
    public teacherUserInfoId?: string;
    public dateOn?: string[];
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetBabyCareTaskWorksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareTaskWorksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyCareTaskWorksRes(); }
}

/**
* 添加学校babycare任务
*/
// @Route("/babycare/{SchoolId}/tasks", "POST, OPTIONS")
// @Api(Description="添加学校babycare任务")
export class AddBabyCareTaskReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public babyCareTaskId?: string;
    public isEnabled?: boolean;
    public classIds?: string[];
    public studyId?: string;
    public flows?: BabyCareTaskFlowDTO[];
    public taskName?: string;

    public constructor(init?: Partial<AddBabyCareTaskReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareTaskReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加学校babycare任务子流程
*/
// @Route("/babycare/{SchoolId}/task/{BabyCareTaskId}/flows", "POST, OPTIONS")
// @Api(Description="添加学校babycare任务子流程")
export class AddBabyCareFlowReq extends BaseResponse implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public babyCareTaskId?: string;
    public flow?: BabyCareTaskFlowDTO;

    public constructor(init?: Partial<AddBabyCareFlowReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareFlowReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 执行学校babycare任务子流程
*/
// @Route("/babycare/{SchoolId}/task/{BabyCareTaskId}/dowork", "POST, OPTIONS")
// @Api(Description="执行学校babycare任务子流程")
export class AddBabyCareTaskWorkReq extends BaseResponse implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public babyCareTaskId?: string;
    public babyCareFlowId?: string;
    public dailyCareDeviceId?: string;
    public teacherUserInfoId?: string;

    public constructor(init?: Partial<AddBabyCareTaskWorkReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AddBabyCareTaskWorkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除某项babycare
*/
// @Route("/babycare/{SchoolId}/care/delete", "DELETE, OPTIONS")
// @Api(Description="删除某项babycare")
export class DeleteBabyCareReq extends BaseResponse implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public babyCareId?: string;

    public constructor(init?: Partial<DeleteBabyCareReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteBabyCareReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校班级日报数据
*/
// @Route("/babycare/report/daily/class", "GET, OPTIONS")
// @Api(Description="获取学校班级日报数据")
export class GetBabyDailyReportClassReq implements IReturn<GetBabyDailyReportClassRes>
{
    public schoolId?: string;
    public classId?: string;
    public recordDateOn?: string;

    public constructor(init?: Partial<GetBabyDailyReportClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyDailyReportClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyDailyReportClassRes(); }
}

/**
* 获取学校班级日报数据
*/
// @Route("/babycare/report/daily/class/bysign", "GET, OPTIONS")
// @Api(Description="获取学校班级日报数据")
export class GetBabyDailyReportClassBySignReq implements IReturn<GetBabyDailyReportClassRes>
{
    public schoolId?: string;
    public classId?: string;
    public dateOn?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetBabyDailyReportClassBySignReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyDailyReportClassBySignReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyDailyReportClassRes(); }
}

/**
* 获取学校班级周报数据
*/
// @Route("/babycare/report/week/class", "GET, OPTIONS")
// @Api(Description="获取学校班级周报数据")
export class GetBabyWeekReportClassReq implements IReturn<GetBabyWeekReportClassRes>
{
    public schoolId?: string;
    public classId?: string;
    public recordDateOn?: string;

    public constructor(init?: Partial<GetBabyWeekReportClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyWeekReportClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyWeekReportClassRes(); }
}

/**
* 获取学校班级周报数据
*/
// @Route("/babycare/report/week/class/bysign", "GET, OPTIONS")
// @Api(Description="获取学校班级周报数据")
export class GetBabyWeekReportClassBySignReq implements IReturn<GetBabyWeekReportClassRes>
{
    public schoolId?: string;
    public classId?: string;
    public dateOn?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetBabyWeekReportClassBySignReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyWeekReportClassBySignReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyWeekReportClassRes(); }
}

/**
* 获取宝贝日报数据
*/
// @Route("/babycare/report/daily", "GET, OPTIONS")
// @Api(Description="获取宝贝日报数据")
export class GetBabyDailyReportReq implements IReturn<GetBabyDailyReportRes>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public dateOn?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetBabyDailyReportReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyDailyReportReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyDailyReportRes(); }
}

/**
* 获取宝贝日报数据
*/
// @Route("/babycare/report/week", "GET, OPTIONS")
// @Api(Description="获取宝贝日报数据")
export class GetBabyReportWeekReq implements IReturn<GetBabyReportWeekRes>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public dateOn?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetBabyReportWeekReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyReportWeekReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBabyReportWeekRes(); }
}

/**
* 获取学校每日照顾报表
*/
// @Route("/babycare/report/cares/daily", "GET")
// @Api(Description="获取学校每日照顾报表")
export class GetDailyCareSchoolDailySummaryReq implements IReturn<GetDailyCareSchoolDailySummaryRes>
{
    public schoolPartnerInfoId?: string;
    public schoolId?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetDailyCareSchoolDailySummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyCareSchoolDailySummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetDailyCareSchoolDailySummaryRes(); }
}

/**
* 获取学校每日流程报表
*/
// @Route("/babycare/report/flows/daily", "GET")
// @Api(Description="获取学校每日流程报表")
export class GetBabyCareTaskWorkSchoolDailySummaryReq implements IReturn<GetBabyCareTaskWorkSchoolDailySummaryRes>
{
    public schoolPartnerInfoId?: string;
    public schoolId?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetBabyCareTaskWorkSchoolDailySummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBabyCareTaskWorkSchoolDailySummaryReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBabyCareTaskWorkSchoolDailySummaryRes(); }
}

/**
* 获取当前学校所有的收款
*/
// @Route("/classbill/billitems/{SchoolId}", "GET, OPTIONS")
// @Api(Description="获取当前学校所有的收款")
export class GetSchoolBillItemsReq implements IReturn<GetSchoolBillItemsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolBillItemsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolBillItemsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolBillItemsRes(); }
}

/**
* 创建班级收款
*/
// @Route("/classbill/billitem", "POST, OPTIONS")
// @Api(Description="创建班级收款")
export class AddSchoolBillItemReq implements IReturn<AddSchoolBillItemRes>
{
    public schoolId?: string;
    public classId?: string;
    public kidIds?: string[];
    public name?: string;
    public amount?: number;

    public constructor(init?: Partial<AddSchoolBillItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolBillItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddSchoolBillItemRes(); }
}

/**
* 取消班级收款
*/
// @Route("/classbill/billitem/{BillItemId}/cancel", "POST, OPTIONS")
// @Api(Description="取消班级收款")
export class CancelSchoolBillItemReq implements IReturn<CancelSchoolBillItemRes>
{
    public schoolId?: string;
    public billItemId?: string;

    public constructor(init?: Partial<CancelSchoolBillItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelSchoolBillItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancelSchoolBillItemRes(); }
}

/**
* 提前终止班级收款
*/
// @Route("/classbill/billitem/{BillItemId}/terminate", "POST, OPTIONS")
// @Api(Description="提前终止班级收款")
export class TerminateSchoolBillItemReq implements IReturn<TerminateSchoolBillItemRes>
{
    public schoolId?: string;
    public billItemId?: string;

    public constructor(init?: Partial<TerminateSchoolBillItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TerminateSchoolBillItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TerminateSchoolBillItemRes(); }
}

/**
* 获取班级收款的详情
*/
// @Route("/classbill/billitem/{BillItemId}", "GET, OPTIONS")
// @Api(Description="获取班级收款的详情")
export class GetSchoolBillItemLogsReq implements IReturn<GetSchoolBillItemLogsRes>
{
    public schoolId?: string;
    public billItemId?: string;

    public constructor(init?: Partial<GetSchoolBillItemLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolBillItemLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolBillItemLogsRes(); }
}

/**
* 家长获取当前学校所有的收款
*/
// @Route("/classbill/parent_billitems", "GET, OPTIONS")
// @Api(Description="家长获取当前学校所有的收款")
export class GetSchoolBillItemsByParentReq implements IReturn<GetSchoolBillItemsByParentRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetSchoolBillItemsByParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolBillItemsByParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolBillItemsByParentRes(); }
}

/**
* 老师提醒家长查看班级收款
*/
// @Route("/classbill/requireack/{ClassBillId}", "POST, OPTIONS")
// @Api(Description="老师提醒家长查看班级收款")
export class RequireAckClassBillReq implements IReturn<BaseResponse>
{
    /**
    * ClassBillId
    */
    // @ApiMember(DataType="string", Description="ClassBillId", IsRequired=true, Name="ClassBillId", ParameterType="path")
    public classBillId?: string;

    /**
    * KidId(空为班级所有小孩)
    */
    // @ApiMember(DataType="string", Description="KidId(空为班级所有小孩)", Name="KidId", ParameterType="query")
    public kidId?: string;

    public kidIds?: string[];

    public constructor(init?: Partial<RequireAckClassBillReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RequireAckClassBillReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校的单个学生基本信息
*/
// @Route("/school/{SchoolId}/kid/{KidId}", "GET, OPTIONS")
// @Api(Description="获取学校的单个学生基本信息")
export class GetSchoolKidReq implements IReturn<GetSchoolKidRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetSchoolKidReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidRes(); }
}

/**
* 获取指定班级的指定类型学生
*/
// @Route("/school/{SchoolId}/class/{ClassId}/kids", "GET, OPTIONS")
// @Api(Description="获取指定班级的指定类型学生")
export class GetClassKidsReq implements IReturn<GetSchoolKidsRes>
{
    public schoolId?: string;
    public classId?: string;
    public classesStatus?: STUDENT_STATUS[];
    public lastModifyOn?: string;

    public constructor(init?: Partial<GetClassKidsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassKidsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidsRes(); }
}

/**
* 获取学校的学生基本信息
*/
// @Route("/school/{SchoolId}/kids", "GET, OPTIONS")
// @Api(Description="获取学校的学生基本信息")
export class GetSchoolKidsReq implements IReturn<GetSchoolKidsRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public classId?: string;
    public name?: string;
    public gender?: GENDERTYPE;
    public state?: KIDACCOUNTSTATE;
    public phoneNumber?: string;
    public birthDate?: string[];
    public createOn?: string[];
    public lastLoginOn?: string[];
    public lastModifyOn?: string[];
    public studentNumber?: string;
    public hobby?: string;
    public studentStatus?: STUDENT_STATUS;
    public pageSize?: number;
    public page?: number;

    public constructor(init?: Partial<GetSchoolKidsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidsRes(); }
}

/**
* 获取班级的基本信息
*/
// @Route("/class/{ClassId}", "GET, OPTIONS")
// @Api(Description="获取班级的基本信息")
export class GetClassInfoReq implements IReturn<GetClassInfoRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    public constructor(init?: Partial<GetClassInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassInfoRes(); }
}

/**
* 增加评语项目
*/
// @Route("/class/{ClassId}/kidremark", "POST, OPTIONS")
// @Api(Description="增加评语项目")
export class AddKidRemarkReq implements IReturn<CommonReturn>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 学期类型
    */
    // @ApiMember(DataType="string", Description="学期类型", IsRequired=true, Name="SemesterType", ParameterType="query")
    public semsterType: SEMESTERTYPE;

    /**
    * 评语标题
    */
    // @ApiMember(DataType="string", Description="评语标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 发布者名称
    */
    // @ApiMember(DataType="string", Description="发布者名称", Name="AuthorName", ParameterType="query")
    public authorName?: string;

    public remarkType?: KID_REMARK_TYPE;
    public targetKids?: string[];

    public constructor(init?: Partial<AddKidRemarkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidRemarkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取班级评语列表
*/
// @Route("/class/{ClassId}/kidremarks", "GET, OPTIONS")
// @Api(Description="获取班级评语列表")
export class GetKidRemarksReq implements IReturn<GetKidRemarksRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    public constructor(init?: Partial<GetKidRemarksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidRemarksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidRemarksRes(); }
}

/**
* 删除班级评语
*/
// @Route("/class/{ClassId}/kidremark/delete", "DELETE, OPTIONS")
// @Api(Description="删除班级评语")
export class DeleteKidRemarkReq implements IReturn<BaseResponse>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    public kidRemarkId?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<DeleteKidRemarkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteKidRemarkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 填写评语内容
*/
// @Route("/class/{ClassId}/kidremark/{KidRemarkId}", "POST, OPTIONS")
// @Api(Description="填写评语内容")
export class SetKidRemarkReq implements IReturn<CommonReturn>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 评语id
    */
    // @ApiMember(DataType="string", Description="评语id", IsRequired=true, Name="KidRemarkId", ParameterType="path")
    public kidRemarkId?: string;

    public remarkItems?: KidRemarkItemDTO[];

    public constructor(init?: Partial<SetKidRemarkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetKidRemarkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 发布评语项目给家长
*/
// @Route("/class/{ClassId}/kidremark/{KidRemarkId}/publish", "POST, OPTIONS")
// @Api(Description="发布评语项目给家长")
export class PublishKidRemarkReq implements IReturn<CommonReturn>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 评语id
    */
    // @ApiMember(DataType="string", Description="评语id", IsRequired=true, Name="KidRemarkId", ParameterType="path")
    public kidRemarkId?: string;

    public constructor(init?: Partial<PublishKidRemarkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PublishKidRemarkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 导出投票结果到excel
*/
// @Route("/class/event/vote/excel{eventid}", "GET, OPTIONS")
// @Api(Description="导出投票结果到excel")
export class ExportEventVoteIdReq implements IReturn<Blob>
{
    public eventid?: string;

    public constructor(init?: Partial<ExportEventVoteIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportEventVoteIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 发布成长任务
*/
// @Route("/class/event/tasks", "POST, OPTIONS")
// @Api(Description="发布成长任务")
export class AddEventTaskReq implements IReturn<CommonReturn>
{
    public targets?: TargetClassAndMember[];
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    /**
    * 家长是否相互可见
    */
    // @ApiMember(DataType="boolean", Description="家长是否相互可见", Name="CanSeeOther", ParameterType="query")
    public canSeeOther?: boolean;

    public endTime?: string;
    /**
    * 主题Id
    */
    // @ApiMember(DataType="string", Description="主题Id", Name="ThemeId", ParameterType="query")
    public themeId?: string;

    /**
    * 家长可回复的形式
    */
    // @ApiMember(DataType="string", Description="家长可回复的形式", Name="CommentType", ParameterType="query")
    public commentType?: TASKATTACHTYPE;

    public isPartialPublish?: boolean;
    /**
    * NeedPayment
    */
    // @ApiMember(DataType="boolean", Description="NeedPayment", Name="NeedPayment", ParameterType="query")
    public needPayment?: boolean;

    /**
    * PaymentPrice
    */
    // @ApiMember(DataType="integer", Description="PaymentPrice", Name="PaymentPrice", ParameterType="query")
    public paymentPrice?: number;

    public constructor(init?: Partial<AddEventTaskReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventTaskReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取一条成长任务
*/
// @Route("/class/{classId}/event/task/{eventid}/teacher", "GET, OPTIONS")
// @Api(Description="获取一条成长任务")
export class GetEventTaskForTeacherByIdReq implements IReturn<GetEventTaskByIdRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="classId", ParameterType="path")
    public classId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid?: string;

    public constructor(init?: Partial<GetEventTaskForTeacherByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventTaskForTeacherByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventTaskByIdRes(); }
}

/**
* 发布打卡任务
*/
// @Route("/class/event/clockin", "POST, OPTIONS")
// @Api(Description="发布打卡任务")
export class AddEventClockInReq implements IReturn<CommonReturn>
{
    public targets?: TargetClassAndMember[];
    public title?: string;
    public desc?: string;
    public canSeeOther?: boolean;
    public tags?: string[];
    public beginTime?: string;
    public endTime?: string;
    public frequencyConfig?: EventClockInFrequency;
    public attachItem?: EventAttachItem;
    public enableSyncAttach?: boolean;
    public publishTime?: string;
    public isPartialPublish?: boolean;

    public constructor(init?: Partial<AddEventClockInReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventClockInReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 结束打卡任务
*/
// @Route("/class/event/clockin/{ClockInId}/finished", "POST, OPTIONS")
// @Api(Description="结束打卡任务")
export class UpdateEventClockInFinishedReq implements IReturn<CommonReturn>
{
    public clockInId?: string;
    public classId?: string;
    public isFinished?: boolean;

    public constructor(init?: Partial<UpdateEventClockInFinishedReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEventClockInFinishedReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 读取一条打卡消息
*/
// @Route("/class/event/clockin/{eventid}/teacher", "GET, OPTIONS")
// @Api(Description="读取一条打卡消息")
export class GetEventClockInForTeacherByIdReq implements IReturn<GetEventClockInByIdRes>
{
    public classId?: string;
    public eventId?: string;

    public constructor(init?: Partial<GetEventClockInForTeacherByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventClockInForTeacherByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventClockInByIdRes(); }
}

/**
* 读取某次打卡消息
*/
// @Route("/class/event/clockin/{eventid}/{groupid}/teacher", "GET, OPTIONS")
// @Api(Description="读取某次打卡消息")
export class GetEventClockInGroupForTeacherByIdReq implements IReturn<GetEventClockInGroupItemsByIdRes>
{
    public classId?: string;
    public eventId?: string;
    public groupId?: string;

    public constructor(init?: Partial<GetEventClockInGroupForTeacherByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventClockInGroupForTeacherByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventClockInGroupItemsByIdRes(); }
}

/**
* 获取家长申请列表
*/
// @Route("/class/{ClassId}/getkidapplyinfos", "GET, OPTIONS")
// @Api(Description="获取家长申请列表")
export class GetKidApplyInfosReq implements IReturn<GetKidApplyInfosRes>
{
    public classId?: string;

    public constructor(init?: Partial<GetKidApplyInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidApplyInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidApplyInfosRes(); }
}

/**
* 允许家长申请
*/
// @Route("/class/{ClassId}/kidapplys/allow", "POST, OPTIONS")
// @Api(Description="允许家长申请")
export class KidApplysAllowReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public ids?: string[];

    public constructor(init?: Partial<KidApplysAllowReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'KidApplysAllowReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 拒绝家长申请
*/
// @Route("/class/{ClassId}/kidapplys/deny", "POST, OPTIONS")
// @Api(Description="拒绝家长申请")
export class KidApplysDenyReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public ids?: string[];

    public constructor(init?: Partial<KidApplysDenyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'KidApplysDenyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加体检模板
*/
// @Route("/school/{SchoolId}/{ClassId}/kidcheckuptemplate/add", "POST")
// @Api(Description="添加体检模板")
export class AddKidCheckUpTemplateReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: KidCheckUpTemplateDTO;

    public constructor(init?: Partial<AddKidCheckUpTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidCheckUpTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取体检模板
*/
// @Route("/school/{SchoolId}/{ClassId}/kidcheckuptemplates", "GET")
// @Api(Description="获取体检模板")
export class GetKidCheckUpTemplateseReq implements IReturn<GetKidCheckUpTemplateseRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public constructor(init?: Partial<GetKidCheckUpTemplateseReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidCheckUpTemplateseReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetKidCheckUpTemplateseRes(); }
}

/**
* 添加体检数据
*/
// @Route("/school/{SchoolId}/{ClassId}/kidcheckup/publish", "POST")
// @Api(Description="添加体检数据")
export class PublishKidCheckUpReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="Id不可为空")
    public id?: string;

    public constructor(init?: Partial<PublishKidCheckUpReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PublishKidCheckUpReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加体检数据
*/
// @Route("/school/{SchoolId}/{ClassId}/kidcheckup/add", "POST")
// @Api(Description="添加体检数据")
export class AddKidCheckUpReq implements IReturn<AddKidCheckUpRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: KidCheckUpDTO;

    public constructor(init?: Partial<AddKidCheckUpReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidCheckUpReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddKidCheckUpRes(); }
}

/**
* 添加体检数据
*/
// @Route("/school/{SchoolId}/{ClassId}/kidcheckups/add", "POST")
// @Api(Description="添加体检数据")
export class AddKidCheckUpsReq implements IReturn<AddKidCheckUpsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: KidCheckUpDTO[];

    public constructor(init?: Partial<AddKidCheckUpsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidCheckUpsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddKidCheckUpsRes(); }
}

/**
* 添加体检数据
*/
// @Route("/school/{SchoolId}/{ClassId}/kidcheckup/excel", "POST")
// @Api(Description="添加体检数据")
export class AddKidCheckUpByExcelReq implements IReturn<AddKidCheckUpByExcelRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public constructor(init?: Partial<AddKidCheckUpByExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidCheckUpByExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddKidCheckUpByExcelRes(); }
}

/**
* 获取体检数据
*/
// @Route("/school/{SchoolId}/{ClassId}/kidcheckups/excel", "GET")
// @Api(Description="获取体检数据")
export class GetKidCheckUpsExcelReq implements IReturn<Blob>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="ClassId不可为空")
    public classId?: string;

    // @Validate(Validator="NotEmpty", Message="TmplId不可为空")
    public tmplId?: string;

    public constructor(init?: Partial<GetKidCheckUpsExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidCheckUpsExcelReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取体检数据
*/
// @Route("/school/{SchoolId}/{ClassId}/kidcheckups", "POST")
// @Api(Description="获取体检数据")
export class GetKidCheckUpsReq implements IReturn<GetKidCheckUpsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public classId?: string;
    public kidName?: string;
    public kidId?: string;
    public state?: EVALUATESTATE;
    public reportOn?: string[];
    public createByTeacherId?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public termId?: number;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetKidCheckUpsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidCheckUpsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidCheckUpsRes(); }
}

/**
* 获取体检数据
*/
// @Route("/school/{SchoolId}/kidcheckups", "POST")
// @Api(Description="获取体检数据")
export class GetKidCheckUpReq implements IReturn<GetKidCheckUpRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="KidId不可为空")
    public kidId?: string;

    public constructor(init?: Partial<GetKidCheckUpReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidCheckUpReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidCheckUpRes(); }
}

/**
* 添加班级
*/
// @Route("/eduaffair/{SchoolId}/classroom", "POST, OPTIONS")
// @Api(Description="添加班级")
export class AddClassRoomReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public branchId?: string;
    public name?: string;

    public constructor(init?: Partial<AddClassRoomReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddClassRoomReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取所有班级
*/
// @Route("/eduaffair/{SchoolId}/classrooms", "GET, OPTIONS")
// @Api(Description="获取所有班级")
export class GetClassRoomsReq implements IReturn<GetClassRoomsRes>
{
    public schoolId?: string;
    public branchIds?: string[];

    public constructor(init?: Partial<GetClassRoomsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassRoomsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassRoomsRes(); }
}

/**
* 更新班级
*/
// @Route("/eduaffair/{SchoolId}/classroom/{ClassRoomId}", "POST, OPTIONS")
// @Api(Description="更新班级")
export class UpdateClassRoomsReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public branchId?: string;
    public classRoomId?: string;
    public name?: string;

    public constructor(init?: Partial<UpdateClassRoomsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateClassRoomsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除班级
*/
// @Route("/eduaffair/{SchoolId}/classroom/{ClassRoomId}", "DELETE, OPTIONS")
// @Api(Description="删除班级")
export class DeleteClassRoomReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classRoomId?: string;

    public constructor(init?: Partial<DeleteClassRoomReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteClassRoomReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取所有课程
*/
// @Route("/eduaffair/{SchoolId}/courses", "GET, OPTIONS")
// @Api(Description="获取所有课程")
export class GetCoursesReq implements IReturn<GetCoursesRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public includeMonth?: boolean;

    public constructor(init?: Partial<GetCoursesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCoursesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCoursesRes(); }
}

/**
* 导出添加课程模板
*/
// @Route("/eduaffair/{SchoolId}/course/excel", "GET, OPTIONS")
// @Api(Description="导出添加课程模板")
export class ExportCourseTemplatesReq implements IReturn<Blob>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public constructor(init?: Partial<ExportCourseTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportCourseTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 添加修改课程
*/
// @Route("/eduaffair/{SchoolId}/course", "POST, OPTIONS")
// @Api(Description="添加修改课程")
export class AddCourseReq implements IReturn<BaseResponse>
{
    public id?: string;
    public schoolId?: string;
    public name?: string;
    public colorId?: number;
    public deductionWhenAbsence?: boolean;
    public deductionWhenLeave?: boolean;
    public isOneToOne?: boolean;
    public remark?: string;
    public saleItemsByCount?: CourseSaleItemDTO[];
    public saleItemsByMonth?: CourseSaleItemDTO[];
    public autoSign?: boolean;
    public signByClassTime?: boolean;
    public branchIds?: string[];

    public constructor(init?: Partial<AddCourseReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddCourseReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新课程的下一级课程列表
*/
// @Route("/eduaffair/{SchoolId}/course/nextids", "POST, OPTIONS")
// @Api(Description="更新课程的下一级课程列表")
export class UpdateCourseNextIdsReq implements IReturn<BaseResponse>
{
    public id?: string;
    public schoolId?: string;
    public nextCourseIds?: string[];

    public constructor(init?: Partial<UpdateCourseNextIdsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateCourseNextIdsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 禁用启用课程
*/
// @Route("/eduaffair/{SchoolId}/course/disable", "POST, OPTIONS")
// @Api(Description="禁用启用课程")
export class SetCourseDisableReq implements IReturn<BaseResponse>
{
    public id?: string;
    public schoolId?: string;
    public isDisabled?: boolean;

    public constructor(init?: Partial<SetCourseDisableReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetCourseDisableReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除课程
*/
// @Route("/eduaffair/{SchoolId}/course/delete", "DELETE, OPTIONS")
// @Api(Description="删除课程")
export class CourseDeleteReq implements IReturn<BaseResponse>
{
    public id?: string;
    public schoolId?: string;

    public constructor(init?: Partial<CourseDeleteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CourseDeleteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取所有排课
*/
// @Route("/eduaffair/{SchoolId}/classschedules/{ClassId}", "GET, OPTIONS")
// @Api(Description="获取所有排课")
export class GetClassSchedulesReq implements IReturn<GetClassSchedulesRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public classId?: string;

    public constructor(init?: Partial<GetClassSchedulesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassSchedulesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassSchedulesRes(); }
}

/**
* 检查排课是否可添加
*/
// @Route("/eduaffair/{SchoolId}/getclassschedulebydate/{ClassId}", "GET, OPTIONS")
// @Api(Description="检查排课是否可添加")
export class GetClassSchedulesByDateReq implements IReturn<GetClassSchedulesByDateRes>
{
    public schoolId?: string;
    public classId?: string;
    public beginTime?: string;
    public startMinutes?: number;
    public endMinutes?: number;

    public constructor(init?: Partial<GetClassSchedulesByDateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassSchedulesByDateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassSchedulesByDateRes(); }
}

/**
* 添加排课
*/
// @Route("/eduaffair/{SchoolId}/classschedules/{ClassId}", "POST, OPTIONS")
// @Api(Description="添加排课")
export class AddClassScheduleReq implements IReturn<AddClassScheduleRes>
{
    public schoolId?: string;
    public classId?: string;
    public beginTime?: string;
    public endTime?: string;
    public startMinutes?: number;
    public endMinutes?: number;
    public weekDays?: MyDayOfWeek[];
    public step?: ClassTimeScheduleStepType;
    public scheduleType?: ClassTimeScheduleType;

    public constructor(init?: Partial<AddClassScheduleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddClassScheduleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddClassScheduleRes(); }
}

/**
* 删除排课
*/
// @Route("/eduaffair/{SchoolId}/classschedules/{ClassId}", "DELETE, OPTIONS")
// @Api(Description="删除排课")
export class DeleteClassScheduleReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public scheduleId?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<DeleteClassScheduleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteClassScheduleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取指定的课时信息
*/
// @Route("/eduaffair/{SchoolId}/classtime", "POST, OPTIONS")
// @Api(Description="获取指定的课时信息")
export class GetClassTimeReq implements IReturn<GetClassTimeRes>
{
    public schoolId?: string;
    public classId?: string;
    public scheduleId?: string;
    public index?: number;
    public dateOn?: string;

    public constructor(init?: Partial<GetClassTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassTimeRes(); }
}

/**
* 获取所有课时
*/
// @Route("/eduaffair/{SchoolId}/classtimes", "POST, OPTIONS")
// @Api(Description="获取所有课时")
export class GetClassTimesReq implements IReturn<GetClassTimesRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public classId?: string;
    public startTime?: string;
    public endTime?: string;

    public constructor(init?: Partial<GetClassTimesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassTimesRes(); }
}

/**
* 获取需要补课的学生
*/
// @Route("/eduaffair/{SchoolId}/classtimesignkids", "GET, OPTIONS")
// @Api(Description="获取需要补课的学生")
export class GetClassTimeSignKidsReq implements IReturn<GetClassTimeSignKidsRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public classId?: string;
    public beginTime?: string[];
    public isMakeUp?: boolean;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetClassTimeSignKidsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimeSignKidsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassTimeSignKidsRes(); }
}

/**
* 课时禁用与启动
*/
// @Route("/eduaffair/{SchoolId}/classtime/cancel", "POST, OPTIONS")
// @Api(Description="课时禁用与启动")
export class ClassTimeCancelReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public scheduleId?: string;
    public index?: number;
    public state?: ClassTimeState;

    public constructor(init?: Partial<ClassTimeCancelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClassTimeCancelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 上课签到
*/
// @Route("/eduaffair/{SchoolId}/classtime/updatetime", "POST, OPTIONS")
// @Api(Description="上课签到")
export class ClassTimeSignReq implements IReturn<ClassTimeSignRes>
{
    public schoolId?: string;
    public scheduleId?: string;
    public index?: number;
    public signInfos?: ClassTimeSignInfoDTO[];
    public teacherUserInfoIds?: string[];

    public constructor(init?: Partial<ClassTimeSignReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClassTimeSignReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClassTimeSignRes(); }
}

/**
* 冲正指定的课消记录
*/
// @Route("/eduaffair/{SchoolId}/courseconsumlog/reversal", "POST, OPTIONS")
// @Api(Description="冲正指定的课消记录")
export class RollbackClassTimeSignInfoReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public logIds?: string[];
    public source?: COURSE_LOG_SOURCE;
    public remark?: string;

    public constructor(init?: Partial<RollbackClassTimeSignInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RollbackClassTimeSignInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 手动修改指定课包的余额
*/
// @Route("/eduaffair/{SchoolId}/kidcoursebalance/update", "POST, OPTIONS")
// @Api(Description="手动修改指定课包的余额")
export class UpdateKidCourseBalanceReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public kidId?: string;
    public orgStudentCourseId?: string;
    public amount?: number;
    public giftAmount?: number;
    public payment?: number;
    public paymentType?: string;
    public remark?: string;

    public constructor(init?: Partial<UpdateKidCourseBalanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidCourseBalanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加学生到课时
*/
// @Route("/eduaffair/{SchoolId}/classtime/addstudent", "POST, OPTIONS")
// @Api(Description="添加学生到课时")
export class AddStudentToChassTimeReq implements IReturn<AddStudentToChassTimeRes>
{
    public schoolId?: string;
    public scheduleId?: string;
    public index?: number;
    public makeUpKids?: KidClassTimeChange[];
    public studentType?: ClassTimeStudentType;
    public makeUpState?: ClassTimeSignState;

    public constructor(init?: Partial<AddStudentToChassTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddStudentToChassTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddStudentToChassTimeRes(); }
}

/**
* 分配学生到课时
*/
// @Route("/eduaffair/{SchoolId}/classtime/allocatestudents", "POST, OPTIONS")
// @Api(Description="分配学生到课时")
export class AllocateStudentsToChassTimeReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public scheduleId?: string;
    public index?: number;
    public kidIds?: string[];
    public studentType?: ClassTimeStudentType;
    public isAllocateIn?: boolean;

    public constructor(init?: Partial<AllocateStudentsToChassTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AllocateStudentsToChassTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新某个课时的上课时间
*/
// @Route("/eduaffair/{SchoolId}/classtime/updatetime", "POST, OPTIONS")
// @Api(Description="更新某个课时的上课时间")
export class UpdateChassTimeTimeReq implements IReturn<UpdateChassTimeTimeRes>
{
    public schoolId?: string;
    public scheduleId?: string;
    public index?: number;
    public beginTime?: string;
    public startMinute?: number;
    public endMinute?: number;
    public teacherIds?: string[];

    public constructor(init?: Partial<UpdateChassTimeTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateChassTimeTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateChassTimeTimeRes(); }
}

/**
* 创建课程班级
*/
// @Route("/eduaffair/{SchoolId}/class", "POST, OPTIONS")
// @Api(Description="创建课程班级")
export class CreateTeachClassReq implements IReturn<BaseResponse>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * BranchId
    */
    // @ApiMember(DataType="string", Description="BranchId", Name="BranchId", ParameterType="query")
    public branchId?: string;

    public classId?: string;
    /**
    * 班级名称
    */
    // @ApiMember(DataType="string", Description="班级名称", IsRequired=true, Name="ClassName", ParameterType="query")
    public className?: string;

    public courseId?: string;
    public classRoomId?: string;
    public capacity?: number;
    public courseHour?: number;
    public remark?: string;
    public teacherIds?: string[];

    public constructor(init?: Partial<CreateTeachClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateTeachClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 导出添加班级模板
*/
// @Route("/eduaffair/{SchoolId}/class/excel", "GET, OPTIONS")
// @Api(Description="导出添加班级模板")
export class ExportCreateClassTemplatesReq implements IReturn<Blob>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public constructor(init?: Partial<ExportCreateClassTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportCreateClassTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取销售线索快速统计
*/
// @Route("/eduaffair/{SchoolId}/salesleads", "GET, OPTIONS")
// @Api(Description="获取销售线索快速统计")
export class GetSalesLeadSummaryReq implements IReturn<GetSalesLeadSummaryRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public followUpTeacherUserInfoId?: string;

    public constructor(init?: Partial<GetSalesLeadSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSalesLeadSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSalesLeadSummaryRes(); }
}

/**
* 获取某个销售线索
*/
// @Route("/eduaffair/{SchoolId}/salesleads", "GET, OPTIONS")
// @Api(Description="获取某个销售线索")
export class GetSalesLeadReq implements IReturn<GetSalesLeadRes>
{
    public schoolId?: string;
    public salesLeadId?: string;

    public constructor(init?: Partial<GetSalesLeadReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSalesLeadReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSalesLeadRes(); }
}

/**
* 获取销售线索
*/
// @Route("/eduaffair/{SchoolId}/salesleads", "GET, OPTIONS")
// @Api(Description="获取销售线索")
export class GetSalesLeadsReq implements IReturn<GetSalesLeadsRes>
{
    public fastFilterType?: SALESLEAD_FASTFILTER_TYPE;
    public schoolId?: string;
    public branchIds?: string[];
    public showFlag?: number;
    public studentName?: string;
    public phoneNumber?: string;
    public gender?: GENDERTYPE;
    public birthDate?: string[];
    public schoolName?: string;
    public schoolGrade?: string;
    public homeAddr?: string;
    public intentType?: CUSTOM_INTENT_TYPE;
    public source?: string;
    public remark?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public followUpState?: FOLLOWUP_STATE;
    public followUpTeacherUserInfoId?: string;
    public lastFollowUpDate?: string[];
    public nextFollowUpDate?: string[];
    public followUpResult?: FOLLOWUP_RESULT;
    public isDelete?: boolean;
    public deleteOn?: string[];
    public deleteBy?: string;
    public kidInfoId?: string;
    public isMember?: boolean;
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSalesLeadsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSalesLeadsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSalesLeadsRes(); }
}

/**
* 创建销售线索
*/
// @Route("/eduaffair/{SchoolId}/saleslead", "POST, OPTIONS")
// @Api(Description="创建销售线索")
export class CreateSalesLeadReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public branchId?: string;
    public studentName?: string;
    public gender?: GENDERTYPE;
    public homeAddr?: string;
    public schoolName?: string;
    public schoolGrade?: string;
    public primaryContact?: ParentInfoDTO;
    public secondaryContact?: ParentInfoDTO;
    public birthDate?: string;
    public followUpState?: FOLLOWUP_STATE;
    public followUpTeacherUserInfoId?: string;
    public intentType?: CUSTOM_INTENT_TYPE;
    public remark?: string;
    public source?: string;
    public tags?: string[];

    public constructor(init?: Partial<CreateSalesLeadReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateSalesLeadReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新销售线索
*/
// @Route("/eduaffair/{SchoolId}/saleslead/{SalesLeadId}", "POST, OPTIONS")
// @Api(Description="更新销售线索")
export class UpdateSalesLeadReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public branchId?: string;
    public salesLeadId?: string;
    public studentName?: string;
    public gender?: GENDERTYPE;
    public homeAddr?: string;
    public schoolName?: string;
    public schoolGrade?: string;
    public primaryContact?: ParentInfoDTO;
    public secondaryContact?: ParentInfoDTO;
    public birthDate?: string;
    public followUpState?: FOLLOWUP_STATE;
    public followUpTeacherUserInfoId?: string;
    public intentType?: CUSTOM_INTENT_TYPE;
    public remark?: string;
    public source?: string;
    public tags?: string[];

    public constructor(init?: Partial<UpdateSalesLeadReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSalesLeadReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 批量分配销售线索
*/
// @Route("/eduaffair/{SchoolId}/salesleads", "POST, OPTIONS")
// @Api(Description="批量分配销售线索")
export class UpdateSalesLeadsReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public salesLeadIds?: string[];
    public followUpState?: FOLLOWUP_STATE;
    public followUpTeacherUserInfoId?: string;

    public constructor(init?: Partial<UpdateSalesLeadsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSalesLeadsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 批量删除销售线索
*/
// @Route("/eduaffair/{SchoolId}/salesleads", "POST, OPTIONS")
// @Api(Description="批量删除销售线索")
export class DeleteSalesLeadsReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public salesLeadIds?: string[];
    public isDelete?: boolean;
    public remark?: string;

    public constructor(init?: Partial<DeleteSalesLeadsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSalesLeadsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取销售跟进记录
*/
// @Route("/eduaffair/{SchoolId}/salesfollowupinfos", "GET, OPTIONS")
// @Api(Description="获取销售跟进记录")
export class GetSalesFollowUpInfoReq implements IReturn<GetSalesFollowUpInfoRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public studentName?: string;
    public salesLeadId?: string;
    public followUpMethod?: FOLLOWUP_METHOD;
    public nextFollowUpDate?: string[];
    public followUpResult?: FOLLOWUP_RESULT;
    public createByTeacherUserInfoId?: string;
    public followUpContent?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSalesFollowUpInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSalesFollowUpInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSalesFollowUpInfoRes(); }
}

/**
* 获取试听记录
*/
// @Route("/eduaffair/{SchoolId}/salesdemoinfos", "GET, OPTIONS")
// @Api(Description="获取试听记录")
export class GetSalesDemoInfosReq implements IReturn<GetSalesDemoInfosRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public studentName?: string;
    public salesLeadId?: string;
    public classId?: string;
    public courseId?: string;
    public beginTime?: string[];
    public demoState?: DEMO_STATE;
    public createByTeacherUserInfoId?: string;
    public teacherUserInfoId?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSalesDemoInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSalesDemoInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSalesDemoInfosRes(); }
}

/**
* 取消试听记录
*/
// @Route("/eduaffair/{SchoolId}/salesdemoinfo/cancel", "POST, OPTIONS")
// @Api(Description="取消试听记录")
export class CancelSalesDemoInfoReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public salesDemoInfoId?: string;

    public constructor(init?: Partial<CancelSalesDemoInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelSalesDemoInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 创建销售跟进记录
*/
// @Route("/eduaffair/{SchoolId}/salesfollowupinfo", "POST, OPTIONS")
// @Api(Description="创建销售跟进记录")
export class CreateSalesFollowUpInfoReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public salesLeadId?: string;
    public followUpMethod?: FOLLOWUP_METHOD;
    public followUpContent?: string;
    public nextFollowUpDate?: string;
    public followUpResult?: FOLLOWUP_RESULT;

    public constructor(init?: Partial<CreateSalesFollowUpInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateSalesFollowUpInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新销售跟进记录
*/
// @Route("/eduaffair/{SchoolId}/salesfollowupinfo/{SalesFollowUpInfoId}", "POST, OPTIONS")
// @Api(Description="更新销售跟进记录")
export class UpdateSalesFollowUpInfoReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public salesFollowUpInfoId?: string;
    public followUpMethod?: FOLLOWUP_METHOD;
    public followUpContent?: string;
    public nextFollowUpDate?: string;
    public followUpResult?: FOLLOWUP_RESULT;

    public constructor(init?: Partial<UpdateSalesFollowUpInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSalesFollowUpInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 从线索入班
*/
// @Route("/eduaffair/{SchoolId}/createkid", "POST, OPTIONS")
// @Api(Description="从线索入班")
export class CreateKidBySalesLeadReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public branchId?: string;
    public salesLeadId?: string;
    public classId?: string;

    public constructor(init?: Partial<CreateKidBySalesLeadReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateKidBySalesLeadReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 创建报名订单
*/
// @Route("/eduaffair/{SchoolId}/courseorder", "POST, OPTIONS")
// @Api(Description="创建报名订单")
export class CreateCourseOrderReq implements IReturn<CreateCourseOrderRes>
{
    public schoolId?: string;
    public branchId?: string;
    public kidId?: string;
    public salesLeadId?: string;
    public courseOrderType?: CourseOrderType;
    public orderItem?: CourseOrderItem[];
    public totalPrice?: number;
    public payable?: number;
    public payment?: number;
    public amount?: number;
    public giftAmount?: number;
    public paymentType?: string;
    public expireOn?: string;
    public source?: string;
    public statementCatalog?: string;
    public classIds?: string[];
    public contractNo?: string;
    public remark?: string;
    public attachment?: EventAttachItem;
    public beginDate?: string;
    public signOn?: string;
    public giftPointPayment?: number;
    public giftCardPayment?: string;

    public constructor(init?: Partial<CreateCourseOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateCourseOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateCourseOrderRes(); }
}

/**
* 获取自增ID
*/
// @Route("/eduaffair/{SchoolId}/nextsequence", "GET, OPTIONS")
// @Api(Description="获取自增ID")
export class GetNextSequenceValueReq implements IReturn<CreateCourseOrderRes>
{
    public schoolId?: string;
    public sequenceName?: string;
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetNextSequenceValueReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetNextSequenceValueReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateCourseOrderRes(); }
}

/**
* 修改报名订单附件
*/
// @Route("/eduaffair/{SchoolId}/courseorder/update", "POST, OPTIONS")
// @Api(Description="修改报名订单附件")
export class UpdateCourseOrderRemarkReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public kidId?: string;
    public orderNo?: string;
    public source?: string;
    public contractNo?: string;
    public remark?: string;
    public attachment?: EventAttachItem;
    public expireOn?: string;
    public signOn?: string;

    public constructor(init?: Partial<UpdateCourseOrderRemarkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateCourseOrderRemarkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 创建报名订单的支付
*/
// @Route("/eduaffair/{SchoolId}/courseorder", "POST, OPTIONS")
// @Api(Description="创建报名订单的支付")
export class CreateCourseOrderPaymentReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public courseOrderId?: string;
    public amount?: number;
    public paymentType?: string;
    public remark?: string;

    public constructor(init?: Partial<CreateCourseOrderPaymentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateCourseOrderPaymentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 创建或修改收支明细
*/
// @Route("/eduaffair/{SchoolId}/courseorder", "POST, OPTIONS")
// @Api(Description="创建或修改收支明细")
export class CreateStatementDetailReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public branchId?: string;
    public statementDetailId?: string;
    public dateOn?: string;
    public statementCatalog?: string;
    public isIncoming?: boolean;
    public isDelete?: boolean;
    public amount?: number;
    public paymentType?: string;
    public remark?: string;

    public constructor(init?: Partial<CreateStatementDetailReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateStatementDetailReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除报名订单
*/
// @Route("/eduaffair/{SchoolId}/courseorder/{OrderNo}", "DELETE, OPTIONS")
// @Api(Description="删除报名订单")
export class DeleteCourseOrderReq implements IReturn<BaseResponse>
{
    public orderNo?: string;
    public schoolId?: string;
    public kidId?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<DeleteCourseOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteCourseOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取报名订单列表
*/
// @Route("/eduaffair/{SchoolId}/courseorders", "GET, OPTIONS")
// @Api(Description="获取报名订单列表")
export class GetCourseOrdersReq implements IReturn<GetCourseOrdersRes>
{
    public orderNo?: string;
    public contractNo?: string;
    public schoolId?: string;
    public branchIds?: string[];
    public kidName?: string;
    public kidId?: string;
    public createOn?: string[];
    public payedOn?: string[];
    public orderType?: CourseOrderType;
    public courseId?: string;
    public totalPrice?: number[];
    public payable?: number[];
    public payment?: number[];
    public source?: string;
    public createByTeacher?: string;
    public orderState?: CourseOrderState;
    public isDelete?: boolean;
    public deleteOn?: string[];
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetCourseOrdersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCourseOrdersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCourseOrdersRes(); }
}

/**
* 获取收支明细列表
*/
// @Route("/eduaffair/{SchoolId}/statementdetails", "GET, OPTIONS")
// @Api(Description="获取收支明细列表")
export class GetStatementDetailsReq implements IReturn<GetStatementDetailsRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public courseOrderId?: string;
    public statementCatalog?: string;
    public isIncoming?: boolean;
    public isDelete?: boolean;
    public amount?: number[];
    public statementAccountId?: string;
    public paymentType?: string;
    public remark?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public deleteOn?: string[];
    public dateOn?: string[];
    public userIPAddr?: string;
    public teacherUserInfoId?: string;
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetStatementDetailsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetStatementDetailsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetStatementDetailsRes(); }
}

/**
* 获取学校的学生报读信息
*/
// @Route("/school/{SchoolId}/kidscourses", "GET, OPTIONS")
// @Api(Description="获取学校的学生报读信息")
export class GetSchoolKidsCoursesReq implements IReturn<GetSchoolKidsCoursesRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public kidName?: string;
    public studentId?: string;
    public courseOrderId?: string;
    public courseId?: string;
    public amount?: number[];
    public giftAmount?: number[];
    public transferOutAmount?: number[];
    public usedAmount?: number[];
    public usedGiftAmount?: number[];
    public totalPrice?: number[];
    public price?: number[];
    public auditPrice?: number[];
    public balanceGiftAmount?: number[];
    public balanceAmount?: number[];
    public balance?: number[];
    public salesTeacherUserInfoId?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public beginDate?: string[];
    public expireOn?: string[];
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSchoolKidsCoursesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidsCoursesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidsCoursesRes(); }
}

/**
* 获取学校的学生某个课程的课时剩余信息
*/
// @Route("/school/{SchoolId}/kidscoursebalance", "GET, OPTIONS")
// @Api(Description="获取学校的学生某个课程的课时剩余信息")
export class GetSchoolKidsCourseBalanceReq implements IReturn<GetSchoolKidsCourseBalanceRes>
{
    public schoolId?: string;
    public courseId?: string;
    public studentIds?: string[];
    public beginDate?: string;

    public constructor(init?: Partial<GetSchoolKidsCourseBalanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidsCourseBalanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidsCourseBalanceRes(); }
}

/**
* 获取学校的某个学生所有课程的课时剩余信息
*/
// @Route("/school/{SchoolId}/kidcoursebalance/{StudentId}", "GET, OPTIONS")
// @Api(Description="获取学校的某个学生所有课程的课时剩余信息")
export class GetSchoolKidCourseBalanceReq implements IReturn<GetSchoolKidCourseBalanceRes>
{
    public schoolId?: string;
    public studentId?: string;

    public constructor(init?: Partial<GetSchoolKidCourseBalanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidCourseBalanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidCourseBalanceRes(); }
}

/**
* 获取学校的学生课程聚合后的课时信息
*/
// @Route("/school/{SchoolId}/kidscoursebalancemin", "GET, OPTIONS")
// @Api(Description="获取学校的学生课程聚合后的课时信息")
export class GetSchoolKidsCourseBalanceeMinReq implements IReturn<GetSchoolKidsCourseBalanceeMinRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public type?: CourseSaleItemType;
    public kidName?: string;
    public studentId?: string;
    public courseId?: string;
    public balance?: number[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSchoolKidsCourseBalanceeMinReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidsCourseBalanceeMinReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidsCourseBalanceeMinRes(); }
}

/**
* 获取学校的学生
*/
// @Route("/school/{SchoolId}/course/kids", "GET, OPTIONS")
// @Api(Description="获取学校的学生")
export class GetSchoolKidsByCourseReq implements IReturn<GetSchoolKidsByCourseRes>
{
    public schoolId?: string;
    public courseId?: string;

    public constructor(init?: Partial<GetSchoolKidsByCourseReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidsByCourseReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidsByCourseRes(); }
}

/**
* 获取课消记录详情
*/
// @Route("/eduaffair/{SchoolId}/courseconsum/{CourseConsumId}", "GET, OPTIONS")
// @Api(Description="获取课消记录详情")
export class GetCourseConsumDetailReq implements IReturn<GetCourseConsumDetailRes>
{
    public schoolId?: string;
    public courseConsumId?: string;

    public constructor(init?: Partial<GetCourseConsumDetailReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCourseConsumDetailReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCourseConsumDetailRes(); }
}

/**
* 获取课消记录列表
*/
// @Route("/eduaffair/{SchoolId}/courseconsumlogs", "GET, OPTIONS")
// @Api(Description="获取课消记录列表")
export class GetCourseConsumLogsReq implements IReturn<GetCourseConsumLogsRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public kidName?: string;
    public studentId?: string;
    public courseId?: string;
    public classTimeId?: string;
    public orgStudentCourseId?: string;
    public modifyAmount?: number[];
    public useGift?: boolean;
    public price?: number[];
    public remark?: string;
    public createOn?: string[];
    public source?: COURSE_LOG_SOURCE;
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetCourseConsumLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCourseConsumLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCourseConsumLogsRes(); }
}

/**
* 获取课消记录列表
*/
// @Route("/eduaffair/{SchoolId}/courseconsumlogsforparent", "GET, OPTIONS")
// @Api(Description="获取课消记录列表")
export class GetCourseConsumLogsForParentReq implements IReturn<GetCourseConsumLogsForParentRes>
{
    public schoolId?: string;
    public studentId?: string;
    public orgStudentCourseId?: string;
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetCourseConsumLogsForParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCourseConsumLogsForParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCourseConsumLogsForParentRes(); }
}

/**
* 获取课后点评模板
*/
// @Route("/eduaffair/{SchoolId}/classtimecommentscoretemplates", "GET, OPTIONS")
// @Api(Description="获取课后点评模板")
export class GetClassTimeCommentScoreTemplatesReq implements IReturn<GetClassTimeCommentScoreTemplatesRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public constructor(init?: Partial<GetClassTimeCommentScoreTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimeCommentScoreTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassTimeCommentScoreTemplatesRes(); }
}

/**
* 添加或修改课后点评模板
*/
// @Route("/eduaffair/{SchoolId}/classtimecommentscoretemplate/add", "POST, OPTIONS")
// @Api(Description="添加或修改课后点评模板")
export class AddClassTimeCommentScoreTemplateReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: ClassTimeCommentScoreTemplateDTO;

    public constructor(init?: Partial<AddClassTimeCommentScoreTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddClassTimeCommentScoreTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取课后点评的课时列表
*/
// @Route("/eduaffair/{SchoolId}/classtimecomments", "GET, OPTIONS")
// @Api(Description="获取课后点评的课时列表")
export class GetClassTimeCommentsReq extends CommonRequest implements IReturn<GetClassTimeCommentsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public classId?: string;
    public kidId?: string;
    public beginTime?: string[];
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetClassTimeCommentsReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimeCommentsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassTimeCommentsRes(); }
}

/**
* 获取学生的课后点评列表
*/
// @Route("/eduaffair/{SchoolId}/classtimecommentkids", "GET, OPTIONS")
// @Api(Description="获取学生的课后点评列表")
export class GetClassTimeCommentKidsReq extends CommonRequest implements IReturn<GetClassTimeCommentKidsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public classId?: string;
    public classTimeId?: string;
    public kidName?: string;
    public kidId?: string;
    public createByTeacherId?: string;
    public beginTime?: string[];
    public createOn?: string[];
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetClassTimeCommentKidsReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimeCommentKidsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassTimeCommentKidsRes(); }
}

/**
* 添加或修改学生的课后点评
*/
// @Route("/eduaffair/{SchoolId}/classtimecommentkid/add", "POST, OPTIONS")
// @Api(Description="添加或修改学生的课后点评")
export class AddClassTimeCommentKidReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: ClassTimeCommentKidDTO;

    public constructor(init?: Partial<AddClassTimeCommentKidReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddClassTimeCommentKidReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取教师的课后点评列表
*/
// @Route("/eduaffair/{SchoolId}/classtimecommentteachers", "GET, OPTIONS")
// @Api(Description="获取教师的课后点评列表")
export class GetClassTimeCommentTeachersReq extends CommonRequest implements IReturn<GetClassTimeCommentTeachersRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public classId?: string;
    public classTimeId?: string;
    public teacherId?: string;
    public kidName?: string;
    public kidId?: string;
    public beginTime?: string[];
    public createOn?: string[];
    public lastId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetClassTimeCommentTeachersReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimeCommentTeachersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassTimeCommentTeachersRes(); }
}

/**
* 添加或修改教师的课后点评
*/
// @Route("/eduaffair/{SchoolId}/classtimecommentteacher/add", "POST, OPTIONS")
// @Api(Description="添加或修改教师的课后点评")
export class AddClassTimeCommentTeacherReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: ClassTimeCommentTeacherDTO;

    public constructor(init?: Partial<AddClassTimeCommentTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddClassTimeCommentTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取成长手册封面模板配置
*/
// @Route("/system/growbooks/templates", "GET, OPTIONS")
// @Api(Description="获取成长手册封面模板配置")
export class GetGrowBookFrontPageTemplatesReq implements IReturn<GetGrowBookFrontPageTemplatesRes>
{

    public constructor(init?: Partial<GetGrowBookFrontPageTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGrowBookFrontPageTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGrowBookFrontPageTemplatesRes(); }
}

/**
* 获取聊天会话列表
*/
// @Route("/school/{SchoolId}/teacher/{TeacherProfileId}/phonenumber", "POST, OPTIONS")
// @Api(Description="获取聊天会话列表")
export class GetSchoolTeacherChatReq implements IReturn<GetSchoolTeacherChatRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolTeacherChatReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTeacherChatReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolTeacherChatRes(); }
}

/**
* 获取聊天会话记录
*/
// @Route("/school/{SchoolId}/teacher/{TeacherProfileId}/phonenumber", "POST, OPTIONS")
// @Api(Description="获取聊天会话记录")
export class GetSchoolTeacherChatHistoryReq implements IReturn<GetSchoolTeacherChatHistoryRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public conversationId?: string;
    public createOn?: string;
    public limit?: number;

    public constructor(init?: Partial<GetSchoolTeacherChatHistoryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTeacherChatHistoryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolTeacherChatHistoryRes(); }
}

/**
* 教师端获取联系人
*/
// @Route("/teacher/im/contacts", "GET, OPTIONS")
// @Api(Description="教师端获取联系人")
export class GetTeacherIMContactsReq implements IReturn<GetIMContactsRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * ClassId
    */
    // @ApiMember(DataType="string", Description="ClassId", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    public isHeadMode?: boolean;

    public constructor(init?: Partial<GetTeacherIMContactsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherIMContactsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIMContactsRes(); }
}

/**
* 获取指定教师的用户信息
*/
// @Route("/teacher/im/teachers", "POST, OPTIONS")
// @Api(Description="获取指定教师的用户信息")
export class GetIMContactTeachersReq implements IReturn<GetIMContactsRes>
{
    public teacherIds?: string[];

    public constructor(init?: Partial<GetIMContactTeachersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIMContactTeachersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIMContactsRes(); }
}

/**
* 获取指定家长的用户信息
*/
// @Route("/teacher/im/parents", "POST, OPTIONS")
// @Api(Description="获取指定家长的用户信息")
export class GetIMContactParentsReq implements IReturn<GetIMContactsRes>
{
    public parents?: ParentRelation[];

    public constructor(init?: Partial<GetIMContactParentsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIMContactParentsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIMContactsRes(); }
}

/**
* 获取教师的聊天会话列表
*/
// @Route("/school/{SchoolId}/teacher/{TeacherId}/rongimconv", "GET, OPTIONS")
// @Api(Description="获取教师的聊天会话列表")
export class RongIMGetSchoolTeacherConvIdsReq implements IReturn<RongIMGetSchoolTeacherConvIdsRes>
{
    public schoolId?: string;
    public teacherId?: string;

    public constructor(init?: Partial<RongIMGetSchoolTeacherConvIdsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RongIMGetSchoolTeacherConvIdsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RongIMGetSchoolTeacherConvIdsRes(); }
}

/**
* 获取会话历史
*/
// @Route("/school/{SchoolId}/conv/{ConvId}/rongimhistory", "GET, OPTIONS")
// @Api(Description="获取会话历史")
export class RongIMGetSchoolIMHistoriesReq implements IReturn<RongIMGetSchoolIMHistoriesRes>
{
    public schoolId?: string;
    public convId?: string;
    public msgTime?: string;
    public pageSize?: number;

    public constructor(init?: Partial<RongIMGetSchoolIMHistoriesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RongIMGetSchoolIMHistoriesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RongIMGetSchoolIMHistoriesRes(); }
}

/**
* 教师端设置免打扰
*/
// @Route("/teacher/im/setmute", "POST, OPTIONS")
// @Api(Description="教师端设置免打扰")
export class SetTeacherIMMuteReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public isGlobalMute?: boolean;
    public conversationId?: string;
    public enableMute?: boolean;

    public constructor(init?: Partial<SetTeacherIMMuteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetTeacherIMMuteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 教师端获取目录
*/
// @Route("/teacher/im/catalog", "GET, OPTIONS")
// @Api(Description="教师端获取目录")
export class GetTeacherIMCatalogReq implements IReturn<GetTeacherIMCatalogRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetTeacherIMCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherIMCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherIMCatalogRes(); }
}

/**
* 获取设备目录
*/
// @Route("/iot/iotcatalogs", "GET, OPTIONS")
// @Api(Description="获取设备目录")
export class GetIotCatalogsReq implements IReturn<GetIotCatalogsRes>
{

    public constructor(init?: Partial<GetIotCatalogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIotCatalogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIotCatalogsRes(); }
}

/**
* 获取所有iot设备列表
*/
// @Route("/iot/iotdevices", "GET, OPTIONS")
// @Api(Description="获取所有iot设备列表")
export class GetAllIotDevicesReq implements IReturn<GetIotDevicesRes>
{
    public lastId?: string;
    public pageSize?: number;

    public constructor(init?: Partial<GetAllIotDevicesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAllIotDevicesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIotDevicesRes(); }
}

/**
* 获取iot设备列表
*/
// @Route("/iot/iotdevices", "GET, OPTIONS")
// @Api(Description="获取iot设备列表")
export class GetIotDevicesReq implements IReturn<GetIotDevicesRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetIotDevicesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIotDevicesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIotDevicesRes(); }
}

/**
* 获取已分配给我的iot设备列表
*/
// @Route("/iot/iotmydevices", "GET, OPTIONS")
// @Api(Description="获取已分配给我的iot设备列表")
export class GetMyAllocatedDevicesReq implements IReturn<GetIotDevicesRes>
{
    public schoolId?: string;
    public catalogCode?: string;
    public userRole?: USERROLE;

    public constructor(init?: Partial<GetMyAllocatedDevicesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMyAllocatedDevicesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIotDevicesRes(); }
}

/**
* 绑定iot设备到教师
*/
// @Route("/iot/iotdeviceallocatetoteacher", "POST, OPTIONS")
// @Api(Description="绑定iot设备到教师")
export class IotDeviceAllocateByTeacherReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public deviceId?: string;
    public isAllowcate?: boolean;

    public constructor(init?: Partial<IotDeviceAllocateByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IotDeviceAllocateByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 新增教师请假/补卡
*/
// @Route("/leaverequest/teachercreate", "POST, OPTIONS")
// @Api(Description="新增教师请假/补卡")
export class CreateTeacherLeaveRequestReq implements IReturn<BaseResponse>
{
    public type?: LEAVE_REQUEST_TYPE;
    public isCheckIn?: boolean;
    public schoolId?: string;
    public subject?: string;
    public desc?: string;
    public timeBegin?: string;
    public timeEnd?: string;
    public leaveHours?: string;
    public auditorId?: string;

    public constructor(init?: Partial<CreateTeacherLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateTeacherLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 修改教师请假
*/
// @Route("/leaverequest/teacherupdate", "POST, OPTIONS")
// @Api(Description="修改教师请假")
export class UpdateTeacherLeaveRequestReq implements IReturn<BaseResponse>
{
    public leaveRequestId?: string;
    public subject?: string;
    public desc?: string;
    public timeBegin?: string;
    public timeEnd?: string;
    public leaveHours?: string;
    public auditorId?: string;

    public constructor(init?: Partial<UpdateTeacherLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeacherLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 取消教师请假
*/
// @Route("/leaverequest/teachercancel", "POST, OPTIONS")
// @Api(Description="取消教师请假")
export class CancelTeacherLeaveRequestReq implements IReturn<BaseResponse>
{
    public leaveRequestId?: string;
    public schoolId?: string;

    public constructor(init?: Partial<CancelTeacherLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelTeacherLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 教师审核请假
*/
// @Route("/leaverequest/audit", "POST, OPTIONS")
// @Api(Description="教师审核请假")
export class AuditLeaveRequestReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public leaveRequestId?: string;
    public teacherUserInfoId?: string;
    public auditMsg?: string;
    public isApprove?: boolean;

    public constructor(init?: Partial<AuditLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AuditLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 教师获取请假列表
*/
// @Route("/leaverequest/teacherrequests", "GET, OPTIONS")
// @Api(Description="教师获取请假列表")
export class GetLeaveRequestByTeacherReq implements IReturn<GetLeaveRequestByTeacherRes>
{
    public schoolId?: string;
    public classId?: string;
    public auditorId?: string;
    public kidName?: string;
    public leaveEntityId?: string;
    public isTeacher?: boolean;
    public requestDate?: string;
    public type?: LEAVE_REQUEST_TYPE;
    public state?: LEAVESTATE;
    public createOn?: string[];
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetLeaveRequestByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLeaveRequestByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLeaveRequestByTeacherRes(); }
}

/**
* 教师获取请假配置
*/
// @Route("/leaverequest/teacherconfig", "GET, OPTIONS")
// @Api(Description="教师获取请假配置")
export class GetTeacherLeaveRequestConfigReq implements IReturn<LeaveRequestConfigRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetTeacherLeaveRequestConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherLeaveRequestConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LeaveRequestConfigRes(); }
}

/**
* 教师获取自己发起的请假列表
*/
// @Route("/leaverequest/teacherrequests", "GET, OPTIONS")
// @Api(Description="教师获取自己发起的请假列表")
export class GetTeacherMyLeaveRequestReq implements IReturn<GetKidMyLeaveRequestRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetTeacherMyLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherMyLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidMyLeaveRequestRes(); }
}

/**
* 加入直播
*/
// @Route("/liveclass/join", "POST, OPTIONS")
// @Api(Description="加入直播")
export class JoinLiveClassRoomReq implements IReturn<JoinLiveClassRoomRes>
{
    public schoolId?: string;
    public kidId?: string;
    public channelName?: string;

    public constructor(init?: Partial<JoinLiveClassRoomReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'JoinLiveClassRoomReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new JoinLiveClassRoomRes(); }
}

/**
* 创建直播
*/
// @Route("/liveclass/rooms", "POST, OPTIONS")
// @Api(Description="创建直播")
export class CreateLiveClassRoomReq implements IReturn<BaseResponse>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * MasterTeacherId
    */
    // @ApiMember(DataType="string", Description="MasterTeacherId", IsRequired=true, Name="MasterTeacherId", ParameterType="query")
    public masterTeacherId?: string;

    /**
    * Title
    */
    // @ApiMember(DataType="string", Description="Title", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * Content
    */
    // @ApiMember(DataType="string", Description="Content", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    /**
    * KidMembers
    */
    // @ApiMember(DataType="array", Description="KidMembers", Name="KidMembers", ParameterType="query")
    public kidMembers?: string[];

    /**
    * TeacherMembers
    */
    // @ApiMember(DataType="array", Description="TeacherMembers", Name="TeacherMembers", ParameterType="query")
    public teacherMembers?: string[];

    public attachment?: EventAttachItem;
    /**
    * OpenOn
    */
    // @ApiMember(DataType="string", Description="OpenOn", Format="date-time", Name="OpenOn", ParameterType="query")
    public openOn?: string;

    /**
    * Duration
    */
    // @ApiMember(DataType="integer", Description="Duration", IsRequired=true, Name="Duration", ParameterType="query")
    public duration: number;

    public constructor(init?: Partial<CreateLiveClassRoomReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateLiveClassRoomReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 修改直播
*/
// @Route("/liveclass/room/{LiveRoomId}", "POST, OPTIONS")
// @Api(Description="修改直播")
export class UpdateLiveClassRoomReq implements IReturn<BaseResponse>
{
    /**
    * LiveRoomId
    */
    // @ApiMember(DataType="string", Description="LiveRoomId", IsRequired=true, Name="LiveRoomId", ParameterType="path")
    public liveRoomId?: string;

    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * Title
    */
    // @ApiMember(DataType="string", Description="Title", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * Content
    */
    // @ApiMember(DataType="string", Description="Content", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachment?: EventAttachItem;

    public constructor(init?: Partial<UpdateLiveClassRoomReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateLiveClassRoomReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 取消直播
*/
// @Route("/liveclass/room/{LiveRoomId}", "DELETE, OPTIONS")
// @Api(Description="取消直播")
export class CancelLiveClassRoomReq implements IReturn<BaseResponse>
{
    /**
    * LiveRoomId
    */
    // @ApiMember(DataType="string", Description="LiveRoomId", IsRequired=true, Name="LiveRoomId", ParameterType="path")
    public liveRoomId?: string;

    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public constructor(init?: Partial<CancelLiveClassRoomReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelLiveClassRoomReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 关闭进行中的直播
*/
// @Route("/liveclass/room/{LiveRoomId}/close", "POST, OPTIONS")
// @Api(Description="关闭进行中的直播")
export class CloseLiveClassRoomReq implements IReturn<BaseResponse>
{
    /**
    * LiveRoomId
    */
    // @ApiMember(DataType="string", Description="LiveRoomId", IsRequired=true, Name="LiveRoomId", ParameterType="path")
    public liveRoomId?: string;

    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public constructor(init?: Partial<CloseLiveClassRoomReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CloseLiveClassRoomReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取直播详情
*/
// @Route("/liveclass/room/{LiveRoomId}", "GET, OPTIONS")
// @Api(Description="获取直播详情")
export class GetLiveClassRoomByIdReq implements IReturn<GetLiveClassRoomByIdRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * LiveRoomId
    */
    // @ApiMember(DataType="string", Description="LiveRoomId", IsRequired=true, Name="LiveRoomId", ParameterType="path")
    public liveRoomId?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", Name="KidId", ParameterType="query")
    public kidId?: string;

    public constructor(init?: Partial<GetLiveClassRoomByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveClassRoomByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveClassRoomByIdRes(); }
}

/**
* 获取直播列表
*/
// @Route("/liveclass/rooms", "GET, OPTIONS")
// @Api(Description="获取直播列表")
export class GetLiveClassRoomsBySchoolIdReq implements IReturn<GetLiveClassRoomsBySchoolIdRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * IsTeacher
    */
    // @ApiMember(DataType="boolean", Description="IsTeacher", IsRequired=true, Name="IsTeacher", ParameterType="query")
    public isTeacher: boolean;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", Name="KidId", ParameterType="query")
    public kidId?: string;

    public createByTeacherId?: string;
    public roomMasterTeacherId?: string;
    public createOn?: string[];
    public openOn?: string[];
    public closeOn?: string[];
    public title?: string;
    public content?: string;
    public roomStatus?: LIVECLASSROOMSTATUS;
    /**
    * LastLiveId
    */
    // @ApiMember(DataType="string", Description="LastLiveId", Name="LastLiveId", ParameterType="query")
    public lastLiveId?: string;

    /**
    * PageSize
    */
    // @ApiMember(DataType="integer", Description="PageSize", Name="PageSize", ParameterType="query")
    public pageSize?: number;

    public pageIndex?: number;

    public constructor(init?: Partial<GetLiveClassRoomsBySchoolIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveClassRoomsBySchoolIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveClassRoomsBySchoolIdRes(); }
}

/**
* 获取直播课程分享地址
*/
// @Route("/liveclass/room/share", "GET, OPTIONS")
// @Api(Description="获取直播课程分享地址")
export class GetLiveClassRoomShareUrlReq implements IReturn<GetLiveClassRoomShareUrlRes>
{
    public schoolId?: string;
    public liveRoomId?: string;

    public constructor(init?: Partial<GetLiveClassRoomShareUrlReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveClassRoomShareUrlReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveClassRoomShareUrlRes(); }
}

/**
* 获取直播课程分享数据
*/
// @Route("/liveclass/room/sharedata", "GET, OPTIONS")
// @Api(Description="获取直播课程分享数据")
export class GetLiveClassRoomShareDataReq implements IReturn<GetLiveClassRoomShareDataRes>
{
    public schoolId?: string;
    public liveRoomId?: string;
    public nonce?: string;
    public sign?: string;

    public constructor(init?: Partial<GetLiveClassRoomShareDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveClassRoomShareDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveClassRoomShareDataRes(); }
}

/**
* 通过手机号获取子商户帐户接口
*/
// @Route("/payment/getpaymentsubmchinfo/phonenumber", "GET, OPTIONS")
// @Api(Description="通过手机号获取子商户帐户接口")
export class GetShengPaySubMchInfoByShengpayAccountNoReq implements IReturn<GetShengPaySubMchInfoByShengpayAccountNoRes>
{
    public phoneNumber?: string;
    public mToken?: string;

    public constructor(init?: Partial<GetShengPaySubMchInfoByShengpayAccountNoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetShengPaySubMchInfoByShengpayAccountNoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetShengPaySubMchInfoByShengpayAccountNoRes(); }
}

/**
* 创建子商户接口
*/
// @Route("/payment/createpaymentsubmchinfo", "POST, OPTIONS")
// @Api(Description="创建子商户接口")
export class CreatePaymentSubMchInfoReq implements IReturn<BaseResponse>
{
    public data?: PaymentSubMchInfoDTO;
    public shengpayData?: ShengPaySubMchInfoDTO;

    public constructor(init?: Partial<CreatePaymentSubMchInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreatePaymentSubMchInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校子商户信息
*/
// @Route("/payment/paymentsubmchinfo", "GET, OPTIONS")
// @Api(Description="获取学校子商户信息")
export class GetPaymentSubMchInfoReq implements IReturn<GetPaymentSubMchInfoRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetPaymentSubMchInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPaymentSubMchInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPaymentSubMchInfoRes(); }
}

/**
* 请求提现
*/
// @Route("/payment/submchdrawinfo/draw", "POST, OPTIONS")
// @Api(Description="请求提现")
export class SubMchWithDrawReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public subMchId?: string;
    public amount?: number;

    public constructor(init?: Partial<SubMchWithDrawReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubMchWithDrawReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 手动检查提现结果
*/
// @Route("/payment/submchdrawinfo/drawquery", "POST, OPTIONS")
// @Api(Description="手动检查提现结果")
export class SubMchWithDrawQueryReq implements IReturn<BaseResponse>
{
    public drawOrderId?: string;

    public constructor(init?: Partial<SubMchWithDrawQueryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubMchWithDrawQueryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取子商户列表接口
*/
// @Route("/payment/paymentsubmchinfos", "GET, OPTIONS")
// @Api(Description="获取子商户列表接口")
export class GetPaymentSubMchInfosReq implements IReturn<GetPaymentSubMchInfosRes>
{
    public schoolId?: string;
    public subMchId?: string;
    public balanceWaitSharingAmount?: number[];
    public balanceWaitDrawAmount?: number[];
    public balanceAvaliableAmount?: number[];
    public subMchType?: SUBMCH_TYPE;
    public applyState?: SUBMCH_STATE;
    public subjectType?: SUBJECT_TYPE;
    public applyType?: APPLY_TYPE;
    public createOn?: string[];
    public isDelete?: boolean;
    public phoneNo?: string;
    public merchantName?: string;
    public organizationInfoNumber?: string;
    public idDocName?: string;
    public idDocNumber?: string;
    public merchantShortName?: string;
    public bankAccountType?: BANKACCOUNT_TYPE;
    public bankAccount?: string;
    public bankName?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetPaymentSubMchInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPaymentSubMchInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPaymentSubMchInfosRes(); }
}

/**
* 获取学校的分润记录
*/
// @Route("/payment/paymentsharinginfos", "GET, OPTIONS")
// @Api(Description="获取学校的分润记录")
export class GetPaymentSharingInfosReq implements IReturn<GetPaymentSharingInfosRes>
{
    public schoolId?: string;
    public subMchId?: string;
    public sharingNo?: string;
    public orderId?: string;
    public transactionId?: string;
    public sftSharingNo?: string;
    public sharingState?: ORDER_SHARING_STATE;
    public amount?: number[];
    public createOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetPaymentSharingInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPaymentSharingInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPaymentSharingInfosRes(); }
}

/**
* 获取学校的分润记录
*/
// @Route("/payment/paymentsharinginfos", "GET, OPTIONS")
// @Api(Description="获取学校的分润记录")
export class GetPaymentSharingInfosReqExcel implements IReturn<Blob>
{
    public schoolId?: string;
    public subMchId?: string;
    public sharingNo?: string;
    public orderId?: string;
    public transactionId?: string;
    public sftSharingNo?: string;
    public sharingState?: ORDER_SHARING_STATE;
    public amount?: number[];
    public createOn?: string[];

    public constructor(init?: Partial<GetPaymentSharingInfosReqExcel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPaymentSharingInfosReqExcel'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取学校的提现记录
*/
// @Route("/payment/submchdrawinfos", "GET, OPTIONS")
// @Api(Description="获取学校的提现记录")
export class GetSubMchDrawInfosReq implements IReturn<GetSubMchDrawInfosRes>
{
    public schoolId?: string;
    public subMchId?: string;
    public withDrawOrderNo?: string;
    public sftAcquireOrderNo?: string;
    public bankName?: string;
    public bankAccount?: string;
    public bankAccountName?: string;
    public drawState?: SUBMCH_DRAW_STATE;
    public fullAmount?: number[];
    public createOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSubMchDrawInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubMchDrawInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSubMchDrawInfosRes(); }
}

/**
* 获取学校的提现记录
*/
// @Route("/payment/submchdrawinfos/excel", "GET, OPTIONS")
// @Api(Description="获取学校的提现记录")
export class GetSubMchDrawInfosReqExcel implements IReturn<Blob>
{
    public schoolId?: string;
    public subMchId?: string;
    public withDrawOrderNo?: string;
    public sftAcquireOrderNo?: string;
    public bankName?: string;
    public bankAccount?: string;
    public bankAccountName?: string;
    public drawState?: SUBMCH_DRAW_STATE;
    public fullAmount?: number[];
    public createOn?: string[];

    public constructor(init?: Partial<GetSubMchDrawInfosReqExcel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubMchDrawInfosReqExcel'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取学校的账户流水
*/
// @Route("/payment/submch/historys", "GET, OPTIONS")
// @Api(Description="获取学校的账户流水")
export class GetSubMchAccountHistoriesReq implements IReturn<GetSubMchAccountHistoriesRes>
{
    public schoolId?: string;
    public amount?: number[];
    public bizType?: SUBMCH_BIZ_TYPE;
    public createOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSubMchAccountHistoriesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubMchAccountHistoriesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSubMchAccountHistoriesRes(); }
}

/**
* 获取学校的账户流水
*/
// @Route("/payment/submch/historys/excel", "GET, OPTIONS")
// @Api(Description="获取学校的账户流水")
export class GetSubMchAccountHistoriesReqExcel implements IReturn<Blob>
{
    public schoolId?: string;
    public amount?: number[];
    public bizType?: SUBMCH_BIZ_TYPE;
    public createOn?: string[];

    public constructor(init?: Partial<GetSubMchAccountHistoriesReqExcel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubMchAccountHistoriesReqExcel'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取学校的账户退款记录
*/
// @Route("/payment/submch/refundorderinfos", "GET, OPTIONS")
// @Api(Description="获取学校的账户退款记录")
export class GetRefundOrderInfosReq implements IReturn<GetRefundOrderInfosRes>
{
    public schoolId?: string;
    public orderNo?: string;
    public orderId?: string;
    public refundOrderNo?: string;
    public refundFee?: number[];
    public totalFee?: number[];
    public paymentType?: ORDERPAYMENT_TYPE;
    public refundStatus?: REFUNDORDERSTATUS;
    public transactionId?: string;
    public createOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetRefundOrderInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRefundOrderInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRefundOrderInfosRes(); }
}

/**
* 获取学校的账户退款记录
*/
// @Route("/payment/submch/refundorderinfos/excel", "GET, OPTIONS")
// @Api(Description="获取学校的账户退款记录")
export class GetRefundOrderInfosReqExcel implements IReturn<Blob>
{
    public schoolId?: string;
    public orderNo?: string;
    public orderId?: string;
    public refundOrderNo?: string;
    public refundFee?: number[];
    public totalFee?: number[];
    public paymentType?: ORDERPAYMENT_TYPE;
    public refundStatus?: REFUNDORDERSTATUS;
    public transactionId?: string;
    public createOn?: string[];

    public constructor(init?: Partial<GetRefundOrderInfosReqExcel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRefundOrderInfosReqExcel'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 查询盛付通分账状态
*/
// @Route("/payment/submchsharingquery", "GET, OPTIONS")
// @Api(Description="查询盛付通分账状态")
export class SubMchRemoteSharingQuery implements IReturn<BaseResponse>
{
    public mchSharingNo?: string;

    public constructor(init?: Partial<SubMchRemoteSharingQuery>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubMchRemoteSharingQuery'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 查询盛付通余额
*/
// @Route("/payment/submchbalance", "GET, OPTIONS")
// @Api(Description="查询盛付通余额")
export class SubMchRemoteBalanceReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public subMchId?: string;
    public orderId?: string;

    public constructor(init?: Partial<SubMchRemoteBalanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubMchRemoteBalanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 订单主动退款
*/
// @Route("/payment/submchorderrefund", "POST, OPTIONS")
// @Api(Description="订单主动退款")
export class SubMchOrderRefundReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public orderId?: string;
    public refundDesc?: string;

    public constructor(init?: Partial<SubMchOrderRefundReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubMchOrderRefundReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 请求二维码登录地址
*/
// @Route("/system/qrlogin/generateqrloginurl", "GET, OPTIONS")
// @Api(Description="请求二维码登录地址")
export class GetQRCodeLoginReq implements IReturn<GetQRCodeLoginRes>
{

    public constructor(init?: Partial<GetQRCodeLoginReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetQRCodeLoginReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetQRCodeLoginRes(); }
}

/**
* 请求二维码ticket状态
*/
// @Route("/system/qrlogin/ticketstatus", "POST, OPTIONS")
// @Api(Description="请求二维码ticket状态")
export class CheckQRCodeTicketReq implements IReturn<CheckQRCodeTicketRes>
{
    public ticket?: string;

    public constructor(init?: Partial<CheckQRCodeTicketReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckQRCodeTicketReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CheckQRCodeTicketRes(); }
}

/**
* 发布园所通知
*/
// @Route("/school/{SchoolId}/notice", "POST, OPTIONS")
// @Api(Description="发布园所通知")
export class AddSchoolNoticeReq implements IReturn<CommonReturn>
{
    public targetTeacherUIds?: number[];
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="ShowOn", ParameterType="query")
    public showOn?: string;

    public constructor(init?: Partial<AddSchoolNoticeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolNoticeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新园所通知
*/
// @Route("/school/{SchoolId}/notice/{Id}", "POST, OPTIONS")
// @Api(Description="更新园所通知")
export class UpdateSchoolNoticeReq implements IReturn<CommonReturn>
{
    public id?: string;
    public targetTeacherUIds?: number[];
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="ShowOn", ParameterType="query")
    public showOn?: string;

    public constructor(init?: Partial<UpdateSchoolNoticeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolNoticeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 删除园所通知
*/
// @Route("/school/{SchoolId}/notice/{Id}", "DELETE, OPTIONS")
// @Api(Description="删除园所通知")
export class DeleteSchoolNoticeReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public id?: string;

    public constructor(init?: Partial<DeleteSchoolNoticeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSchoolNoticeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 发布园所通知
*/
// @Route("/school/{SchoolId}/notice/{Id}", "DELETE, OPTIONS")
// @Api(Description="发布园所通知")
export class PublishSchoolNoticeReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public id?: string;

    public constructor(init?: Partial<PublishSchoolNoticeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PublishSchoolNoticeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取园所通知
*/
// @Route("/school/{SchoolId}/notices", "GET, OPTIONS")
// @Api(Description="获取园所通知")
export class GetSchoolNoticesReq implements IReturn<GetSchoolNoticesRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolNoticesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolNoticesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolNoticesRes(); }
}

/**
* 园所通知已阅
*/
// @Route("/school/{SchoolId}/notice/{Id}/ack", "POST, OPTIONS")
// @Api(Description="园所通知已阅")
export class AddSchoolNoticeFeedbackReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public id?: string;

    public constructor(init?: Partial<AddSchoolNoticeFeedbackReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolNoticeFeedbackReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 园所通知已阅取消
*/
// @Route("/school/{SchoolId}/notice/{Id}/ackdel", "POST, OPTIONS")
// @Api(Description="园所通知已阅取消")
export class RemoveSchoolNoticeFeedbackReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public id?: string;

    public constructor(init?: Partial<RemoveSchoolNoticeFeedbackReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveSchoolNoticeFeedbackReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取集团学校列表
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/schools/", "GET")
// @Api(Description="获取集团学校列表")
export class GetSchoolPartnerSchoolInfosReq implements IReturn<GetSchoolPartnerSchoolInfosRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    public constructor(init?: Partial<GetSchoolPartnerSchoolInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerSchoolInfosReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolPartnerSchoolInfosRes(); }
}

/**
* 获取集团学校列表
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/schools/permission", "GET")
// @Api(Description="获取集团学校列表")
export class GetSchoolPartnerSchoolInfosByPermissionReq implements IReturn<GetSchoolPartnerSchoolInfosByPermissionRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotNull", Message="Permission 不能为空")
    public permission: PERMISSION_TYPE;

    public constructor(init?: Partial<GetSchoolPartnerSchoolInfosByPermissionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerSchoolInfosByPermissionReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolPartnerSchoolInfosByPermissionRes(); }
}

/**
* 获取教师列表
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/{SchoolId}/teachers", "GET")
// @Api(Description="获取教师列表")
export class GetSchoolPartnerTeachersReq implements IReturn<GetSchoolPartnerTeachersRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolPartnerTeachersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerTeachersReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolPartnerTeachersRes(); }
}

/**
* 获取学员列表
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/{SchoolId}/kids", "POST")
// @Api(Description="获取学员列表")
export class GetSchoolPartnerKidsReq implements IReturn<GetSchoolPartnerKidsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public branchIds?: string[];
    public classId?: string;
    public name?: string;
    public gender?: GENDERTYPE;
    public state?: KIDACCOUNTSTATE;
    public phoneNumber?: string;
    public birthDate?: string[];
    public createOn?: string[];
    public lastLoginOn?: string[];
    public lastModifyOn?: string[];
    public studentNumber?: string;
    public hobby?: string;
    public studentStatus?: STUDENT_STATUS;
    public memberExpireOn?: string[];
    public accountBalanceAmount?: number[];
    public accountBalanceGiftAmount?: number[];
    public sort?: { [index: string]: ORDER_DIRECTION; };
    // @Validate(Validator="NotNull", Message="PageIndex 不能为空")
    public pageIndex: number;

    // @Validate(Validator="NotNull", Message="PageSize 不能为空")
    public pageSize: number;

    public constructor(init?: Partial<GetSchoolPartnerKidsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerKidsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerKidsRes(); }
}

/**
* 获取班级列表
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/{SchoolId}/class", "POST")
// @Api(Description="获取班级列表")
export class GetSchoolPartnerClassInfosReq implements IReturn<GetSchoolPartnerClassInfosRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public branchIds?: string[];
    public name?: string;
    public alias?: string;
    public isDelete?: boolean;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public courseId?: string;
    public remark?: string;
    public classStatus?: CLASS_STATUS;
    public sort?: { [index: string]: ORDER_DIRECTION; };
    // @Validate(Validator="NotNull", Message="PageIndex 不能为空")
    public pageIndex: number;

    // @Validate(Validator="NotNull", Message="PageSize 不能为空")
    public pageSize: number;

    public constructor(init?: Partial<GetSchoolPartnerClassInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerClassInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerClassInfosRes(); }
}

/**
* 获取课程列表
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/{SchoolId}/class", "POST")
// @Api(Description="获取课程列表")
export class GetSchoolPartnerCoursesReq implements IReturn<GetSchoolPartnerCoursesRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public name?: string;
    public signType?: CourseSignType;
    public isDisabled?: boolean;
    public isDelete?: boolean;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public remark?: string;
    public sort?: { [index: string]: ORDER_DIRECTION; };
    // @Validate(Validator="NotNull", Message="PageIndex 不能为空")
    public pageIndex: number;

    // @Validate(Validator="NotNull", Message="PageSize 不能为空")
    public pageSize: number;

    public constructor(init?: Partial<GetSchoolPartnerCoursesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerCoursesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerCoursesRes(); }
}

/**
* 添加修改集团用户信息
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/user", "POST")
// @Api(Description="添加修改集团用户信息")
export class AddSchoolPartnerUserReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotEmpty", Message="手机号 不能为空")
    public phoneNumber?: string;

    // @Validate(Validator="NotNull", Message="Data 不能为空")
    public data: SchoolPartnerUserDTO;

    public constructor(init?: Partial<AddSchoolPartnerUserReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolPartnerUserReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取集团用户信息
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/myuserinfo/", "GET")
// @Api(Description="获取集团用户信息")
export class GetSchoolPartnerUserReq implements IReturn<GetSchoolPartnerUserRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    public constructor(init?: Partial<GetSchoolPartnerUserReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerUserReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolPartnerUserRes(); }
}

/**
* 获取集团用户列表
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/users/", "POST")
// @Api(Description="获取集团用户列表")
export class GetSchoolPartnerUsersReq implements IReturn<GetSchoolPartnerUsersRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    public sort?: { [index: string]: ORDER_DIRECTION; };

    public constructor(init?: Partial<GetSchoolPartnerUsersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerUsersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerUsersRes(); }
}

/**
* 获取集团用户配置
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/setting", "GET")
// @Api(Description="获取集团用户配置")
export class GetSchoolPartnerSettingReq implements IReturn<GetSchoolPartnerSettingRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    public constructor(init?: Partial<GetSchoolPartnerSettingReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerSettingReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolPartnerSettingRes(); }
}

/**
* 获取集团用户配置
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/setting", "POST")
// @Api(Description="获取集团用户配置")
export class UpdateSchoolPartnerSettingReq implements IReturn<GetSchoolPartnerSettingRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    public data?: SchoolPartnerSettingDTO;

    public constructor(init?: Partial<UpdateSchoolPartnerSettingReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolPartnerSettingReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerSettingRes(); }
}

/**
* 更新集团用户配置
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/setting", "POST")
// @Api(Description="更新集团用户配置")
export class UpdateSchoolPartnerSettingWithKVReq implements IReturn<GetSchoolPartnerSettingRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public settingKey?: string;

    public settingValue?: string;

    public constructor(init?: Partial<UpdateSchoolPartnerSettingWithKVReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolPartnerSettingWithKVReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerSettingRes(); }
}

/**
* 添加修改集团线索
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/sales/saleslead/update", "POST")
// @Api(Description="添加修改集团线索")
export class AddSchoolPartnerSalesLeadReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotNull", Message="Data 不能为空")
    public data: SchoolPartnerSalesLeadDTO;

    public constructor(init?: Partial<AddSchoolPartnerSalesLeadReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolPartnerSalesLeadReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取集团线索列表
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/sales/salesleads", "POST")
// @Api(Description="获取集团线索列表")
export class GetSchoolPartnerSalesLeadsReq implements IReturn<GetSchoolPartnerSalesLeadsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    public merchantName?: string;
    public merchantShortName?: string;
    public address?: string;
    public linkMan?: string;
    public linkManPhone?: string;
    public score?: number;
    public remark?: string;
    public tag?: string;
    public source?: string;
    public type?: string;
    public merchantType?: string;
    public merchantLevel?: string;
    public state?: string;
    public createBy?: number;
    public createOn?: string[];
    public lastModifyBy?: number;
    public lastModifyOn?: string[];
    public assignmentOn?: string[];
    public followUpUserId?: number;
    public lastFollowUpOn?: string[];
    public nextFollowUpOn?: string[];
    public isDelete?: boolean;
    public deleteBy?: number;
    public deleteOn?: string[];
    public sort?: { [index: string]: ORDER_DIRECTION; };
    // @Validate(Validator="NotNull", Message="PageIndex 不能为空")
    public pageIndex: number;

    // @Validate(Validator="NotNull", Message="PageSize 不能为空")
    public pageSize: number;

    public constructor(init?: Partial<GetSchoolPartnerSalesLeadsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerSalesLeadsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerSalesLeadsRes(); }
}

/**
* 获取集团线索
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/sales/saleslead/{SalesLeadId}", "GET")
// @Api(Description="获取集团线索")
export class GetSchoolPartnerSalesLeadReq implements IReturn<GetSchoolPartnerSalesLeadRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotEmpty", Message="SalesLeadId 不能为空")
    public salesLeadId?: string;

    public constructor(init?: Partial<GetSchoolPartnerSalesLeadReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerSalesLeadReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolPartnerSalesLeadRes(); }
}

/**
* 添加修改集团线索
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/sales/followup/update", "POST")
// @Api(Description="添加修改集团线索")
export class AddSchoolPartnerFollowUpInfoReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotNull", Message="Data 不能为空")
    public data: SchoolPartnerFollowUpInfoDTO;

    public constructor(init?: Partial<AddSchoolPartnerFollowUpInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolPartnerFollowUpInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取集团线索列表
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/sales/followup", "POST")
// @Api(Description="获取集团线索列表")
export class GetSchoolPartnerFollowUpInfosReq implements IReturn<GetSchoolPartnerFollowUpInfosRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    public schoolPartnerSalesLeadId?: string;
    public schoolPartnerSalesLeadName?: string;
    public linkMan?: string;
    public linkManPhone?: string;
    public followUpMethod?: string;
    public followUpType?: string;
    public followUpOn?: string[];
    public nextFollowUpOn?: string[];
    public followUpContent?: string;
    public createBy?: number;
    public createOn?: string[];
    public lastModifyBy?: number;
    public lastModifyOn?: string[];
    public sort?: { [index: string]: ORDER_DIRECTION; };
    // @Validate(Validator="NotNull", Message="PageIndex 不能为空")
    public pageIndex: number;

    // @Validate(Validator="NotNull", Message="PageSize 不能为空")
    public pageSize: number;

    public constructor(init?: Partial<GetSchoolPartnerFollowUpInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerFollowUpInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerFollowUpInfosRes(); }
}

/**
* 获取集团线索
*/
// @Route("/schoolpartner/{SchoolPartnerInfoId}/sales/followup/{FollowUpId}", "GET")
// @Api(Description="获取集团线索")
export class GetSchoolPartnerFollowUpInfoReq implements IReturn<GetSchoolPartnerFollowUpInfoRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolPartnerInfoId 不能为空")
    public schoolPartnerInfoId?: string;

    // @Validate(Validator="NotEmpty", Message="FollowUpId 不能为空")
    public followUpId?: string;

    public constructor(init?: Partial<GetSchoolPartnerFollowUpInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerFollowUpInfoReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolPartnerFollowUpInfoRes(); }
}

/**
* 记录教师的登录日志
*/
// @Route("/teacher/loginlog", "POST, OPTIONS")
// @Api(Description="记录教师的登录日志")
export class AddTeacherLoginLogReq implements IReturn<BaseResponse>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public constructor(init?: Partial<AddTeacherLoginLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddTeacherLoginLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取应用角色列表
*/
// @Route("/aaa/app/roles", "GET, OPTIONS")
// @Api(Description="获取应用角色列表")
export class GetAppRolesReq implements IReturn<GetRolesReq>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetAppRolesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAppRolesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRolesReq(); }
}

/**
* 导出添加教师模板为excel
*/
// @Route("/school/{SchoolId}/teacherexcelfile", "GET, OPTIONS")
// @Api(Description="导出添加教师模板为excel")
export class ExportAddSchoolTeacherTemplatesReq implements IReturn<Blob>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public constructor(init?: Partial<ExportAddSchoolTeacherTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportAddSchoolTeacherTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取学生的家长信息
*/
// @Route("/v2/school/{SchoolId}/kidparents/{KidId}", "GET, OPTIONS")
// @Api(Description="获取学生的家长信息")
export class GetKidParentsByKidIdReq implements IReturn<GetKidParentsByKidIdRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    public constructor(init?: Partial<GetKidParentsByKidIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidParentsByKidIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidParentsByKidIdRes(); }
}

/**
* 获取班级列表V2
*/
// @Route("/v2/school/{SchoolId}/classes", "GET, OPTIONS")
// @Api(Description="获取班级列表V2")
export class GetSchoolClassInfosV2Req implements IReturn<GetSchoolClassInfosV2Res>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public branchIds?: string[];

    public constructor(init?: Partial<GetSchoolClassInfosV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolClassInfosV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolClassInfosV2Res(); }
}

/**
* 统一获取班级列表V3
*/
// @Route("/v3/school/{SchoolId}/classes", "GET, OPTIONS")
// @Api(Description="统一获取班级列表V3")
export class GetSchoolClassInfosV3Req implements IReturn<GetSchoolClassInfosV2Res>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public branchIds?: string[];

    public constructor(init?: Partial<GetSchoolClassInfosV3Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolClassInfosV3Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolClassInfosV2Res(); }
}

/**
* 通过指定权限获取班级列表V2
*/
// @Route("/v2/school/{SchoolId}/classesbypermission", "GET, OPTIONS")
// @Api(Description="通过指定权限获取班级列表V2")
export class GetSchoolClassInfosByPermissionReq implements IReturn<GetSchoolClassInfosByPermissionRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public permission?: PERMISSION_TYPE;

    public constructor(init?: Partial<GetSchoolClassInfosByPermissionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolClassInfosByPermissionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolClassInfosByPermissionRes(); }
}

/**
* 获取班级列表
*/
// @Route("/school/{SchoolId}/classes", "GET, OPTIONS")
// @Api(Description="获取班级列表")
export class GetSchoolClassInfosReq implements IReturn<GetSchoolClassInfosRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolClassInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolClassInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolClassInfosRes(); }
}

/**
* 更新园所自定义年级信息
*/
// @Route("/school/{SchoolId}/gradename", "POST, OPTIONS")
// @Api(Description="更新园所自定义年级信息")
export class UpdateSchoolGradeNameReq implements IReturn<CommonReturn>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public gradeMap?: GradeNameMap[];

    public constructor(init?: Partial<UpdateSchoolGradeNameReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolGradeNameReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改家长联系手机
*/
// @Route("/class/{ClassId}/kid/{KidId}/registrphonenumber", "POST, OPTIONS")
// @Api(Description="修改家长联系手机")
export class UpdateKidRegistrPhoneNumberReq implements IReturn<CommonReturn>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * 家长手机号码
    */
    // @ApiMember(DataType="string", Description="家长手机号码", IsRequired=true, Name="RegistrPhoneNumber", ParameterType="query")
    public registrPhoneNumber?: string;

    public constructor(init?: Partial<UpdateKidRegistrPhoneNumberReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidRegistrPhoneNumberReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改教师手机
*/
// @Route("/school/{SchoolId}/teacher/{TeacherId}/phonenumber", "POST, OPTIONS")
// @Api(Description="修改教师手机")
export class UpdateSchoolTeacherPhoneNumberReq implements IReturn<CommonReturn>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 教师Id
    */
    // @ApiMember(DataType="string", Description="教师Id", IsRequired=true, Name="TeacherId", ParameterType="path")
    public teacherId?: string;

    /**
    * 手机号码
    */
    // @ApiMember(DataType="string", Description="手机号码", IsRequired=true, Name="PhoneNumber", ParameterType="query")
    public phoneNumber?: string;

    public constructor(init?: Partial<UpdateSchoolTeacherPhoneNumberReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolTeacherPhoneNumberReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改教师的发布权限
*/
// @Route("/school/{SchoolId}/teacher/{TeacherId}/needpublishaudit", "POST, OPTIONS")
// @Api(Description="修改教师的发布权限")
export class UpdateSchoolTeacherNeedPublishAuditReq implements IReturn<CommonReturn>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 教师Id
    */
    // @ApiMember(DataType="string", Description="教师Id", IsRequired=true, Name="TeacherId", ParameterType="path")
    public teacherId?: string;

    /**
    * 是否需要审核
    */
    // @ApiMember(DataType="boolean", Description="是否需要审核", IsRequired=true, Name="NeedPublishAudit", ParameterType="query")
    public needPublishAudit: boolean;

    public constructor(init?: Partial<UpdateSchoolTeacherNeedPublishAuditReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolTeacherNeedPublishAuditReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 生成邀请注册链接
*/
// @Route("/class/{ClassId}/getreglink", "GET, OPTIONS")
// @Api(Description="生成邀请注册链接")
export class GetRegLinkReq implements IReturn<GetRegLinkRes>
{
    public classId?: string;

    public constructor(init?: Partial<GetRegLinkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRegLinkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRegLinkRes(); }
}

/**
* 获取学校的权限分配数据
*/
// @Route("/school/{SchoolId}/roles", "GET, OPTIONS")
// @Api(Description="获取学校的权限分配数据")
export class GetSchoolUsersRoleReq implements IReturn<GetSchoolUsersRoleRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolUsersRoleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolUsersRoleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolUsersRoleRes(); }
}

/**
* 授权用户到权限
*/
// @Route("/school/{SchoolId}/assignuserstorole", "POST, OPTIONS")
// @Api(Description="授权用户到权限")
export class GrantUsersToRoleReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public roleCode?: string;
    public userIds?: number[];

    public constructor(init?: Partial<GrantUsersToRoleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GrantUsersToRoleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 授权权限到用户
*/
// @Route("/school/{SchoolId}/assignrolestouser", "POST, OPTIONS")
// @Api(Description="授权权限到用户")
export class AssignRolesToUserReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public userId?: number;
    public roleCodes?: string[];

    public constructor(init?: Partial<AssignRolesToUserReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRolesToUserReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 学生转班
*/
// @Route("/school/kidmove/{KidId}/{ToClassId}", "POST, OPTIONS")
// @Api(Description="学生转班")
export class KidMoveToClassReq implements IReturn<CommonReturn>
{
    /**
    * 学校id
    */
    // @ApiMember(DataType="string", Description="学校id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ToClassId", ParameterType="path")
    public toClassId?: string;

    public constructor(init?: Partial<KidMoveToClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'KidMoveToClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 教师确认用药申请
*/
// @Route("/school/{SchoolId}/healthy/mediccare/{MedicCareId}/accept", "POST, OPTIONS")
// @Api(Description="教师确认用药申请")
export class AcceptMedicCardReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public teacherUserInfoId?: string;
    public medicCareId?: string;
    public studentId?: string;
    public actualTime?: string;
    public teacherMemo?: string;
    public teacherImageItems?: EventAttachImageItemDTO[];

    public constructor(init?: Partial<AcceptMedicCardReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AcceptMedicCardReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 园长列取本校用药申请
*/
// @Route("/school/{SchoolId}/healthy/mediccares/daily", "GET, OPTIONS")
// @Api(Description="园长列取本校用药申请")
export class GetSchoolMedicCaresReq implements IReturn<GetSchoolMedicCaresRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolMedicCaresReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolMedicCaresReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolMedicCaresRes(); }
}

/**
* 教师列取用药申请
*/
// @Route("/school/{SchoolId}/healthy/mediccares/dailyexcel", "GET, OPTIONS")
// @Api(Description="教师列取用药申请")
export class GetSchoolMedicCaresToExcelReq implements IReturn<Blob>
{
    public schoolId?: string;
    public branchIds?: string[];
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolMedicCaresToExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolMedicCaresToExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 教师列取本班用药申请
*/
// @Route("/school/{SchoolId}/healthy/mediccares/class", "GET, OPTIONS")
// @Api(Description="教师列取本班用药申请")
export class GetClassMediccaresReq implements IReturn<GetSchoolMedicCaresRes>
{
    public schoolId?: string;
    public classId?: string;
    public pageSize?: number;
    public lastId?: string;

    public constructor(init?: Partial<GetClassMediccaresReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassMediccaresReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolMedicCaresRes(); }
}

/**
* 修改学校微官网数据
*/
// @Route("/minisite/{SchoolId}/{ItemType}", "POST, OPTIONS")
// @Api(Description="修改学校微官网数据")
export class UpdateSchoolMiniSiteItemReq implements IReturn<GetSchoolMiniSiteRes>
{
    public schoolId?: string;
    public itemType?: SCHOOL_MINISITE_ITEMTYPE;
    public data?: SchoolSiteContentItemDTO[];

    public constructor(init?: Partial<UpdateSchoolMiniSiteItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolMiniSiteItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolMiniSiteRes(); }
}

/**
* 修改学校微官网数据
*/
// @Route("/minisite/{SchoolId}/desc/{ItemType}", "POST, OPTIONS")
// @Api(Description="修改学校微官网数据")
export class UpdateSchoolMiniSiteDescReq implements IReturn<GetSchoolMiniSiteRes>
{
    public schoolId?: string;
    public itemType?: SCHOOL_MINISITE_ITEMTYPE;
    public data?: string;

    public constructor(init?: Partial<UpdateSchoolMiniSiteDescReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolMiniSiteDescReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolMiniSiteRes(); }
}

/**
* 修改学校微官网数据
*/
// @Route("/minisite/{SchoolId}", "POST, OPTIONS")
// @Api(Description="修改学校微官网数据")
export class UpdateSchoolMiniSiteReq implements IReturn<GetSchoolMiniSiteRes>
{
    public schoolId?: string;
    public data?: SchoolSiteDTO;

    public constructor(init?: Partial<UpdateSchoolMiniSiteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolMiniSiteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolMiniSiteRes(); }
}

/**
* 校长获取爱心视频浏览日志
*/
// @Route("/live/{SchoolId}/{ClassId}/livehistory/", "GET, OPTIONS")
// @Api(Description="校长获取爱心视频浏览日志")
export class GetLiveVideoVisitHistoryReq implements IReturn<GetLiveVideoVisitHistoryRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public classId?: string;
    public createOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetLiveVideoVisitHistoryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveVideoVisitHistoryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveVideoVisitHistoryRes(); }
}

/**
* 获取学校组织架构
*/
// @Route("/school/{SchoolId}/orgs", "GET, OPTIONS")
// @Api(Description="获取学校组织架构")
export class GetSchoolChildOrgInfosReq implements IReturn<GetSchoolChildOrgInfosRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolChildOrgInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolChildOrgInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolChildOrgInfosRes(); }
}

/**
* 增加学校组织架构部门
*/
// @Route("/school/{SchoolId}/orgs", "POST, OPTIONS")
// @Api(Description="增加学校组织架构部门")
export class AddSchoolChildOrgInfoReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public parentId?: string;
    public name?: string;
    public deptType?: SCHOOL_DEPT_TYPE;
    public address?: string;
    public phoneNumber?: string;
    public location?: GeoCoordinates;

    public constructor(init?: Partial<AddSchoolChildOrgInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolChildOrgInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 修改学校组织架构部门
*/
// @Route("/school/{SchoolId}/org/{OrgId}", "POST, OPTIONS")
// @Api(Description="修改学校组织架构部门")
export class UpdateSchoolChildOrgInfoReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public parentId?: string;
    public name?: string;
    public orgId?: string;
    public address?: string;
    public phoneNumber?: string;
    public location?: GeoCoordinates;

    public constructor(init?: Partial<UpdateSchoolChildOrgInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolChildOrgInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除学校组织架构部门
*/
// @Route("/school/{SchoolId}/org/{OrgId}", "DELETE, OPTIONS")
// @Api(Description="删除学校组织架构部门")
export class DeleteSchoolChildOrgInfoReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public orgId?: string;

    public constructor(init?: Partial<DeleteSchoolChildOrgInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSchoolChildOrgInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 搜索学校的学生
*/
// @Route("/v2/school/{SchoolId}/kids", "POST, OPTIONS")
// @Api(Description="搜索学校的学生")
export class GetSchoolKidsV2Req implements IReturn<GetSchoolKidsV2Res>
{
    public schoolId?: string;
    public branchIds?: string[];
    public classId?: string;
    public name?: string;
    public gender?: GENDERTYPE;
    public state?: KIDACCOUNTSTATE;
    public phoneNumber?: string;
    public birthDate?: string[];
    public createOn?: string[];
    public lastLoginOn?: string[];
    public lastModifyOn?: string[];
    public studentNumber?: string;
    public hobby?: string;
    public studentStatus?: STUDENT_STATUS;
    public pageSize?: number;
    public pageIndex?: number;
    public onlyShowData?: boolean;

    public constructor(init?: Partial<GetSchoolKidsV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidsV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidsV2Res(); }
}

export class TopicTestReq
{
    public payload?: BaseSource;

    public constructor(init?: Partial<TopicTestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TopicTestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 获得学校舆情关键词设置V2
*/
// @Route("/school/{SchoolId}/topictest/keywordsv2", "GET, OPTIONS")
// @Api(Description="获得学校舆情关键词设置V2")
export class GetSchoolTopicTestKeyWordsV2Req implements IReturn<GetSchoolTopicTestKeyWordsV2Res>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolTopicTestKeyWordsV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTopicTestKeyWordsV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolTopicTestKeyWordsV2Res(); }
}

/**
* 获得学校舆情关键词设置
*/
// @Route("/school/{SchoolId}/topictest/keywords", "GET, OPTIONS")
// @Api(Description="获得学校舆情关键词设置")
export class GetSchoolTopicTestKeyWordsReq implements IReturn<GetSchoolTopicTestKeyWordsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolTopicTestKeyWordsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTopicTestKeyWordsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolTopicTestKeyWordsRes(); }
}

/**
* 更新学校舆情关键词设置
*/
// @Route("/school/{SchoolId}/topictest/keywordsv2", "POST, OPTIONS")
// @Api(Description="更新学校舆情关键词设置")
export class UpdateSchoolTopicTestKeyWordsV2Req implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public data?: TopicTestSchoolKeyWordV2DTO;

    public constructor(init?: Partial<UpdateSchoolTopicTestKeyWordsV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolTopicTestKeyWordsV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新学校舆情关键词设置
*/
// @Route("/school/{SchoolId}/topictest/keywords", "POST, OPTIONS")
// @Api(Description="更新学校舆情关键词设置")
export class UpdateSchoolTopicTestKeyWordsReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public data?: TopicTestSchoolKeyWordDTO;

    public constructor(init?: Partial<UpdateSchoolTopicTestKeyWordsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolTopicTestKeyWordsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获得学校舆情列表
*/
// @Route("/school/{SchoolId}/topictest/list", "POST, OPTIONS")
// @Api(Description="获得学校舆情列表")
export class GetTopicTestInterceptLogsReq implements IReturn<GetTopicTestInterceptLogsRes>
{
    public schoolId?: string;
    public createOn?: string[];
    public auditState?: EVENTAUDITSTATE;
    public source?: TOPIC_TEST_SOURCE_TYPE;
    public keyWordType?: TOPIC_TEST_KEYWORD_TYPE;
    public publishState?: EVENTPUBLISHSTATE;
    public lastId?: string;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetTopicTestInterceptLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTopicTestInterceptLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTopicTestInterceptLogsRes(); }
}

/**
* 学校舆情审核
*/
// @Route("/school/{SchoolId}/topictest/audit", "POST, OPTIONS")
// @Api(Description="学校舆情审核")
export class AuditTopicTestInterceptReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public id?: string;
    public publishState?: EVENTPUBLISHSTATE;

    public constructor(init?: Partial<AuditTopicTestInterceptReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AuditTopicTestInterceptReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 学校获取操作日志
*/
// @Route("/school/{SchoolId}/audit/logs", "GET, OPTIONS")
// @Api(Description="学校获取操作日志")
export class GetSchoolAuditLogsReq implements IReturn<GetSchoolAuditLogsRes>
{
    public schoolId?: string;
    public operaterType?: SCHOOL_AUDIT_OPERATER_TYPE;
    public serviceModule?: SCHOOL_SERVICE_SUB_MODULE;
    public auditType?: SCHOOL_AUDIT_TYPE;
    public operaterTeacherId?: string;
    public isTeacher?: boolean;
    public entityId?: string;
    public kidName?: string;
    public remark?: string;
    public createOn?: string[];
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetSchoolAuditLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolAuditLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolAuditLogsRes(); }
}

/**
* 获取食谱库
*/
// @Route("/school/{SchoolId}/foodmenu/dishs", "GET")
// @Api(Description="获取食谱库")
export class GetSchoolDishsReq implements IReturn<GetSchoolDishsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public dishName?: string;
    public dishType?: DishType;
    public lastId?: string;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetSchoolDishsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolDishsReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolDishsRes(); }
}

/**
* 获取食材库
*/
// @Route("/school/{SchoolId}/foodmenu/foods", "GET")
// @Api(Description="获取食材库")
export class GetSchoolIngredientsReq implements IReturn<GetSchoolIngredientsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public ingredientName?: string;
    public ingredientType?: IngredientType;
    public lastId?: string;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetSchoolIngredientsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolIngredientsReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolIngredientsRes(); }
}

/**
* 添加食谱
*/
// @Route("/school/{SchoolId}/foodmenu/dish/add", "POST")
// @Api(Description="添加食谱")
export class AddSchoolDishReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: DishItemDTO;

    public constructor(init?: Partial<AddSchoolDishReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolDishReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加食材
*/
// @Route("/school/{SchoolId}/foodmenu/dish/add", "POST")
// @Api(Description="添加食材")
export class AddSchoolIngredientReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: IngredientItemDTO;

    public constructor(init?: Partial<AddSchoolIngredientReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolIngredientReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加周食谱
*/
// @Route("/school/{SchoolId}/foodmenu/dishweek/add", "POST")
// @Api(Description="添加周食谱")
export class AddSchoolDishMenuWeeklyReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: DishMenuWeeklyDTO;

    public constructor(init?: Partial<AddSchoolDishMenuWeeklyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolDishMenuWeeklyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新巡检区域
*/
// @Route("/patrol/{SchoolId}/patrolarea/update", "POST")
// @Api(Description="更新巡检区域")
export class AddPatrolAreaReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: PatrolAreaDTO;

    public constructor(init?: Partial<AddPatrolAreaReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddPatrolAreaReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新巡检指标
*/
// @Route("/patrol/{SchoolId}/patrolareaitem/update", "POST")
// @Api(Description="更新巡检指标")
export class AddPatrolAreaItemReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: PatrolAreaItemDTO;

    public constructor(init?: Partial<AddPatrolAreaItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddPatrolAreaItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新巡检记录
*/
// @Route("/patrol/{SchoolId}/patrochecklog/update", "POST")
// @Api(Description="更新巡检记录")
export class AddPatroCheckLogReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: PatrolCheckLogDTO;

    public constructor(init?: Partial<AddPatroCheckLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddPatroCheckLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取巡检区域列表
*/
// @Route("/patrol/{SchoolId}/patrolareas", "POST")
// @Api(Description="获取巡检区域列表")
export class GetPatrolAreasReq implements IReturn<GetPatrolAreasRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public title?: string;
    public remark?: string;
    public isEnable?: boolean;
    public createByTeacherId?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public sort?: { [index: string]: ORDER_DIRECTION; };
    // @Validate(Validator="NotNull", Message="PageIndex 不能为空")
    public pageIndex: number;

    // @Validate(Validator="NotNull", Message="PageSize 不能为空")
    public pageSize: number;

    public constructor(init?: Partial<GetPatrolAreasReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatrolAreasReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPatrolAreasRes(); }
}

/**
* 获取巡检区域详情
*/
// @Route("/patrol/{SchoolId}/patrolarea", "GET")
// @Api(Description="获取巡检区域详情")
export class GetPatrolAreaReq implements IReturn<GetPatrolAreaRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="Id 不能为空")
    public id?: string;

    public constructor(init?: Partial<GetPatrolAreaReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatrolAreaReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatrolAreaRes(); }
}

/**
* 获取巡检指标列表
*/
// @Route("/patrol/{SchoolId}/patrolareaitems", "POST")
// @Api(Description="获取巡检指标列表")
export class GetPatrolAreaItemsReq implements IReturn<GetPatrolAreaItemsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public patrolAreaId?: string;
    public patrolAreaTitle?: string;
    public patrolType?: string;
    public title?: string;
    public subTilte?: string;
    public remark?: string;
    public isEnable?: boolean;
    public createByTeacherId?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public lastCheckResult?: PATRO_CHECK_RESULT;
    public lastCheckOn?: string[];
    public lastCheckByTeacherId?: string;
    public sort?: { [index: string]: ORDER_DIRECTION; };
    // @Validate(Validator="NotNull", Message="PageIndex 不能为空")
    public pageIndex: number;

    // @Validate(Validator="NotNull", Message="PageSize 不能为空")
    public pageSize: number;

    public constructor(init?: Partial<GetPatrolAreaItemsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatrolAreaItemsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPatrolAreaItemsRes(); }
}

/**
* 获取巡检指标详情
*/
// @Route("/patrol/{SchoolId}/patrolareaitem", "GET")
// @Api(Description="获取巡检指标详情")
export class GetPatrolAreaItemReq implements IReturn<GetPatrolAreaItemRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="Id 不能为空")
    public id?: string;

    public constructor(init?: Partial<GetPatrolAreaItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatrolAreaItemReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatrolAreaItemRes(); }
}

/**
* 获取巡检记录列表
*/
// @Route("/patrol/{SchoolId}/patrolchecklogs", "POST")
// @Api(Description="获取巡检记录列表")
export class GetPatrolCheckLogsReq implements IReturn<GetPatrolCheckLogsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public patrolAreaId?: string;
    public patrolAreaTitle?: string;
    public patrolAreaItemId?: string;
    public patrolAreaItemTitle?: string;
    public patrolType?: string;
    public remark?: string;
    public checkOn?: string[];
    public checkResult?: PATRO_CHECK_RESULT;
    public createByTeacherId?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public sort?: { [index: string]: ORDER_DIRECTION; };
    // @Validate(Validator="NotNull", Message="PageIndex 不能为空")
    public pageIndex: number;

    // @Validate(Validator="NotNull", Message="PageSize 不能为空")
    public pageSize: number;

    public constructor(init?: Partial<GetPatrolCheckLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatrolCheckLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPatrolCheckLogsRes(); }
}

/**
* 获取巡检记录列表
*/
// @Route("/patrol/{SchoolId}/patrolchecklog", "GET")
// @Api(Description="获取巡检记录列表")
export class GetPatrolCheckLogReq implements IReturn<GetPatrolCheckLogRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="Id 不能为空")
    public id?: string;

    public constructor(init?: Partial<GetPatrolCheckLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPatrolCheckLogReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPatrolCheckLogRes(); }
}

/**
* 更新话术库分组
*/
// @Route("/speakingart/{SchoolId}/catalog/update", "POST")
// @Api(Description="更新话术库分组")
export class AddSpeakingArtCatalogReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: SpeakingArtCatalogDTO;

    public constructor(init?: Partial<AddSpeakingArtCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSpeakingArtCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新话术库
*/
// @Route("/speakingart/{SchoolId}/item/update", "POST")
// @Api(Description="更新话术库")
export class AddSpeakingArtItemReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public data?: SpeakingArtItemDTO;

    public constructor(init?: Partial<AddSpeakingArtItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSpeakingArtItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取话术库栏目
*/
// @Route("/speakingart/{SchoolId}/catalogs", "GET")
// @Api(Description="获取话术库栏目")
export class GetSpeakingArtCatalogsReq implements IReturn<GetSpeakingArtCatalogsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public constructor(init?: Partial<GetSpeakingArtCatalogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSpeakingArtCatalogsReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSpeakingArtCatalogsRes(); }
}

/**
* 获取话术库栏目
*/
// @Route("/speakingart/{SchoolId}/items", "POST")
// @Api(Description="获取话术库栏目")
export class GetSpeakingArtItemsReq implements IReturn<GetSpeakingArtItemsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不能为空")
    public schoolId?: string;

    public speakingArtCatalogId?: string;
    public title?: string;
    public content?: string;
    public createBy?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public sort?: { [index: string]: ORDER_DIRECTION; };
    // @Validate(Validator="NotNull", Message="PageIndex 不能为空")
    public pageIndex: number;

    // @Validate(Validator="NotNull", Message="PageSize 不能为空")
    public pageSize: number;

    public constructor(init?: Partial<GetSpeakingArtItemsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSpeakingArtItemsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSpeakingArtItemsRes(); }
}

/**
* 填写一条评估项目
*/
// @Route("/class/{ClassId}/evaluate/{EvaluateId}/fillitem", "POST, OPTIONS")
// @Api(Description="填写一条评估项目")
export class FillEvaluateItemReq implements IReturn<FillEvaluateInitRes>
{
    public classId?: string;
    public evaluateId?: string;
    public evaluateItemId?: string;
    public kidScores?: EvaluateItemKidScoreDTO[];

    public constructor(init?: Partial<FillEvaluateItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FillEvaluateItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FillEvaluateInitRes(); }
}

/**
* 开始填写评估项目
*/
// @Route("/class/{ClassId}/evaluate/{EvaluateId}/startfill", "POST, OPTIONS")
// @Api(Description="开始填写评估项目")
export class FillEvaluateInitReq implements IReturn<FillEvaluateInitRes>
{
    public classId?: string;
    public evaluateId?: string;

    public constructor(init?: Partial<FillEvaluateInitReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FillEvaluateInitReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FillEvaluateInitRes(); }
}

/**
* 解析excel中的评估项
*/
// @Route("/school/{SchoolId}/parseevaluateitembyexcel", "POST, OPTIONS")
// @Api(Description="解析excel中的评估项")
export class ParseEvaluateItemsFromExcelReq implements IReturn<BaseResponse>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public constructor(init?: Partial<ParseEvaluateItemsFromExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParseEvaluateItemsFromExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 导出园所级的评估模板为excel
*/
// @Route("/school/{SchoolId}/evaluatetemplates/excel", "GET, OPTIONS")
// @Api(Description="导出园所级的评估模板为excel")
export class ExportEvaluateTemplatesReq implements IReturn<Blob>
{
    public schoolId?: string;

    public constructor(init?: Partial<ExportEvaluateTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportEvaluateTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 添加或修改园所级的评估模板
*/
// @Route("/school/{SchoolId}/evaluatetemplates", "POST, OPTIONS")
// @Api(Description="添加或修改园所级的评估模板")
export class AddSchoolEvaluateTemplateReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public templateId?: string;
    public name?: string;
    public items?: EvaluateItemBaseDTO[];

    public constructor(init?: Partial<AddSchoolEvaluateTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolEvaluateTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取园所级的评估模板
*/
// @Route("/school/{SchoolId}/evaluatetemplates", "GET, OPTIONS")
// @Api(Description="获取园所级的评估模板")
export class GetSchoolEvaluateTemplatesReq implements IReturn<GetSchoolEvaluateTemplatesRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolEvaluateTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolEvaluateTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolEvaluateTemplatesRes(); }
}

/**
* 获取园所级的评估模板
*/
// @Route("/school/{SchoolId}/evaluatetemplates", "GET, OPTIONS")
// @Api(Description="获取园所级的评估模板")
export class GetEvaluateTemplatesReq implements IReturn<GetEvaluateTemplatesRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetEvaluateTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEvaluateTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEvaluateTemplatesRes(); }
}

/**
* 删除园所级的评估模板
*/
// @Route("/school/{SchoolId}/evaluatetemplate/{TemplateId}/delete", "DELETE, OPTIONS")
// @Api(Description="删除园所级的评估模板")
export class DeleteEvaluateTemplateReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public templateId?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<DeleteEvaluateTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEvaluateTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 保存评估结果
*/
// @Route("/class/{ClassId}/evaluate/{EvaluateId}/release", "POST, OPTIONS")
// @Api(Description="保存评估结果")
export class ReleaseEvaluateReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public evaluateId?: string;

    public constructor(init?: Partial<ReleaseEvaluateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReleaseEvaluateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 增加评估项目
*/
// @Route("/class/{ClassId}/evaluate", "POST, OPTIONS")
// @Api(Description="增加评估项目")
export class AddEvaluateReq implements IReturn<CommonReturn>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 评估类型
    */
    // @ApiMember(DataType="string", Description="评估类型", IsRequired=true, Name="EvaluateType", ParameterType="query")
    public evaluateType: EVALUATETYPE;

    /**
    * 学期类型
    */
    // @ApiMember(DataType="string", Description="学期类型", IsRequired=true, Name="SemesterType", ParameterType="query")
    public semesterType: SEMESTERTYPE;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="FinishTime", ParameterType="query")
    public finishTime?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 所选择的评估项列表
    */
    // @ApiMember(DataType="array", Description="所选择的评估项列表", IsRequired=true, Name="EvaluateIds", ParameterType="query")
    public evaluateIds: string[];

    public targetKids?: string[];

    public constructor(init?: Partial<AddEvaluateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEvaluateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 增加园所级的评估模板
*/
// @Route("/school/{SchoolId}/evaluatetemplate", "POST, OPTIONS")
// @Api(Description="增加园所级的评估模板")
export class AddEvaluateTemplateReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public templateId?: string;
    public title?: string;
    public subTitle?: string;
    public desc?: string;

    public constructor(init?: Partial<AddEvaluateTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEvaluateTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 增加平台级的评估模板
*/
// @Route("/school/evaluatetemplate", "POST, OPTIONS")
// @Api(Description="增加平台级的评估模板")
export class AddGlobalEvaluateTemplateReq implements IReturn<CommonReturn>
{
    public title?: string;
    public subTitle?: string;
    public desc?: string;

    public constructor(init?: Partial<AddGlobalEvaluateTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddGlobalEvaluateTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新园所级的评估模板
*/
// @Route("/school/{SchoolId}/evaluatetemplate/{Id}", "POST, OPTIONS")
// @Api(Description="更新园所级的评估模板")
export class UpdateEvaluateTemplateReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public templateId?: string;
    public id?: string;
    public title?: string;
    public subTitle?: string;
    public desc?: string;

    public constructor(init?: Partial<UpdateEvaluateTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEvaluateTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新平台级的评估模板
*/
// @Route("/school/evaluatetemplate/{Id}", "POST, OPTIONS")
// @Api(Description="更新平台级的评估模板")
export class UpdateGlobalEvaluateTemplateReq implements IReturn<CommonReturn>
{
    public id?: string;
    public title?: string;
    public subTitle?: string;
    public desc?: string;

    public constructor(init?: Partial<UpdateGlobalEvaluateTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateGlobalEvaluateTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取教师的所有学校基本信息
*/
// @Route("/teacher/baseschools", "GET, OPTIONS")
// @Api(Description="获取教师的所有学校基本信息")
export class GetTeacherAllSchoolBaseInfoReq implements IReturn<GetTeacherAllSchoolBaseInfoRes>
{

    public constructor(init?: Partial<GetTeacherAllSchoolBaseInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherAllSchoolBaseInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherAllSchoolBaseInfoRes(); }
}

/**
* 获取教师的所有班级的信息V2
*/
// @Route("/v2/teacher/classes", "GET, OPTIONS")
// @Api(Description="获取教师的所有班级的信息V2")
export class GetTeacherClassesV2Req implements IReturn<GetTeacherClassesV2Res>
{
    public schoolId?: string;
    public classId?: string;
    public branchIds?: string[];

    public constructor(init?: Partial<GetTeacherClassesV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherClassesV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherClassesV2Res(); }
}

/**
* 获取教师的所有班级的信息
*/
// @Route("/teacher/classes", "GET, OPTIONS")
// @Api(Description="获取教师的所有班级的信息")
export class GetTeacherClassesReq implements IReturn<GetTeacherClassesRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetTeacherClassesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherClassesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherClassesRes(); }
}

/**
* 老师提醒家长查看消息
*/
// @Route("/class/event/requireack/{EventId}", "PUT, OPTIONS")
// @Api(Description="老师提醒家长查看消息")
export class RequireAckEventReq implements IReturn<CommonReturn>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * KidId(空为班级所有小孩)
    */
    // @ApiMember(DataType="string", Description="KidId(空为班级所有小孩)", Name="KidId", ParameterType="query")
    public kidId?: string;

    public kidIds?: string[];

    public constructor(init?: Partial<RequireAckEventReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RequireAckEventReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取报表摘要
*/
// @Route("/school/{SchoolId}/chart/{ChartType}/", "GET, OPTIONS")
// @Api(Description="获取报表摘要")
export class GetChartSummaryReq implements IReturn<GetChartSummaryRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 报表类型
    */
    // @ApiMember(DataType="string", Description="报表类型", IsRequired=true, Name="ChartType", ParameterType="path")
    public chartType?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", Name="ClassId", ParameterType="query")
    public classId?: string;

    public constructor(init?: Partial<GetChartSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetChartSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetChartSummaryRes(); }
}

/**
* 获取报表
*/
// @Route("/school/{SchoolId}/chart/{ChartType}/", "GET, OPTIONS")
// @Api(Description="获取报表")
export class GetChartReq implements IReturn<GetChartRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 报表类型
    */
    // @ApiMember(DataType="string", Description="报表类型", IsRequired=true, Name="ChartType", ParameterType="path")
    public chartType?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", Name="ClassId", ParameterType="query")
    public classId?: string;

    public constructor(init?: Partial<GetChartReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetChartReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetChartRes(); }
}

/**
* 将教师分配到指定的班级
*/
// @Route("/school/{SchoolId}/teacher/allocate/{ClassId}", "POST, OPTIONS")
// @Api(Description="将教师分配到指定的班级")
export class AllocateSchoolTeacherToClassReq implements IReturn<CommonReturn>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 教师id
    */
    // @ApiMember(DataType="string", Description="教师id", IsRequired=true, Name="TeacherId", ParameterType="query")
    public teacherId?: string;

    /**
    * 教师角色
    */
    // @ApiMember(DataType="string", Description="教师角色", IsRequired=true, Name="TeacherRole", ParameterType="query")
    public teacherRole?: string;

    /**
    * 操作方向（分配/解除分配）
    */
    // @ApiMember(DataType="boolean", Description="操作方向（分配/解除分配）", IsRequired=true, Name="IsAllocateIn", ParameterType="query")
    public isAllocateIn: boolean;

    public constructor(init?: Partial<AllocateSchoolTeacherToClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AllocateSchoolTeacherToClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 创建班级
*/
// @Route("/school/{SchoolId}/class", "POST, OPTIONS")
// @Api(Description="创建班级")
export class CreateClassInfoReq implements IReturn<CreateClassInfoRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 分校区id
    */
    // @ApiMember(DataType="string", Description="分校区id", Name="BranchId", ParameterType="query")
    public branchId?: string;

    /**
    * 班级名称
    */
    // @ApiMember(DataType="string", Description="班级名称", IsRequired=true, Name="ClassName", ParameterType="query")
    public className?: string;

    /**
    * 年级
    */
    // @ApiMember(DataType="string", Description="年级", IsRequired=true, Name="GradeType", ParameterType="query")
    public gradeType: CLASSGRADETYPE;

    public logoId?: number;
    /**
    * 班级别名
    */
    // @ApiMember(DataType="string", Description="班级别名", Name="ClassNameAlias", ParameterType="query")
    public classNameAlias?: string;

    public constructor(init?: Partial<CreateClassInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateClassInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateClassInfoRes(); }
}

/**
* 删除班级
*/
// @Route("/school/{SchoolId}/class/{ClassId}", "DELETE, OPTIONS")
// @Api(Description="删除班级")
export class DeleteClassInfoReq implements IReturn<BaseResponse>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 班级ID
    */
    // @ApiMember(DataType="string", Description="班级ID", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    public isDelete?: boolean;

    public constructor(init?: Partial<DeleteClassInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteClassInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除教师
*/
// @Route("/school/{SchoolId}/teacher", "DELETE, OPTIONS")
// @Api(Description="删除教师")
export class DeleteSchoolTeacherReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public teacherId?: string;
    public isDelete?: boolean;
    public isHidden?: boolean;

    public constructor(init?: Partial<DeleteSchoolTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSchoolTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取员工交接信息
*/
// @Route("/school/{SchoolId}/teacher/handover/info", "GET")
// @Api(Description="获取员工交接信息")
export class GetSchoolTeacherHandoverInfoReq implements IReturn<GetSchoolTeacherHandoverInfoRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="TeacherId 不可为空")
    public teacherId?: string;

    public constructor(init?: Partial<GetSchoolTeacherHandoverInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTeacherHandoverInfoReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolTeacherHandoverInfoRes(); }
}

/**
* 员工交接
*/
// @Route("/school/{SchoolId}/teacher/handover/info", "POST")
// @Api(Description="员工交接")
export class SchoolTeacherHandoverReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="TeacherId 不可为空")
    public teacherId?: string;

    // @Validate(Validator="NotEmpty", Message="ToTeacherId 不可为空")
    public toTeacherId?: string;

    public constructor(init?: Partial<SchoolTeacherHandoverReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SchoolTeacherHandoverReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 创建教师
*/
// @Route("/school/{SchoolId}/teacher", "POST, OPTIONS")
// @Api(Description="创建教师")
export class CreateSchoolTeacherReq implements IReturn<CreateSchoolTeacherRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public branchRole?: { [index: string]: string; };
    /**
    * JobTitle
    */
    // @ApiMember(DataType="string", Description="JobTitle", Name="JobTitle", ParameterType="query")
    public jobTitle?: string;

    /**
    * 手机号码
    */
    // @ApiMember(DataType="string", Description="手机号码", IsRequired=true, Name="PhoneNumber", ParameterType="query")
    public phoneNumber?: string;

    /**
    * 全名
    */
    // @ApiMember(DataType="string", Description="全名", IsRequired=true, Name="FullName", ParameterType="query")
    public fullName?: string;

    /**
    * 备注
    */
    // @ApiMember(DataType="string", Description="备注", Name="Remark", ParameterType="query")
    public remark?: string;

    public isHidden?: boolean;
    /**
    * 教师职务
    */
    // @ApiMember(DataType="string", Description="教师职务", Name="TeacherRole", ParameterType="query")
    public teacherRole?: string;

    public roleCodes?: string[];

    public constructor(init?: Partial<CreateSchoolTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateSchoolTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateSchoolTeacherRes(); }
}

/**
* 更新老师的职务
*/
// @Route("/school/{SchoolId}/teacher/{TeacherId}/teacherRole", "POST, OPTIONS")
// @Api(Description="更新老师的职务")
export class UpdateSchoolTeacherRoleReq implements IReturn<CommonReturn>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public teacherUserInfoId?: string;
    public teacherRole?: string;

    public constructor(init?: Partial<UpdateSchoolTeacherRoleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolTeacherRoleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新老师所属校区
*/
// @Route("/school/{SchoolId}/teacher/{TeacherId}/branchid", "POST, OPTIONS")
// @Api(Description="更新老师所属校区")
export class UpdateSchoolTeacherBranchIdReq implements IReturn<BaseResponse>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public teacherUserInfoId?: string;
    public branchRole?: { [index: string]: string; };

    public constructor(init?: Partial<UpdateSchoolTeacherBranchIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolTeacherBranchIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新老师的顺序
*/
// @Route("/school/{SchoolId}/teacher/{TeacherId}/orderindex", "POST, OPTIONS")
// @Api(Description="更新老师的顺序")
export class UpdateSchoolTeacherOrderIndexReq implements IReturn<CommonReturn>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public teacherId?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<UpdateSchoolTeacherOrderIndexReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolTeacherOrderIndexReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取园长的所有班级信息
*/
// @Route("/head/classes", "GET, OPTIONS")
// @Api(Description="获取园长的所有班级信息")
export class GetHeadClassesReq implements IReturn<GetTeacherClassesRes>
{

    public constructor(init?: Partial<GetHeadClassesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetHeadClassesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherClassesRes(); }
}

/**
* 获取教师列表
*/
// @Route("/school/{SchoolId}/teachers", "GET, OPTIONS")
// @Api(Description="获取教师列表")
export class GetSchoolTeachersReq implements IReturn<GetSchoolTeachersRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public branchIds?: string[];
    public showDeleted?: boolean;

    public constructor(init?: Partial<GetSchoolTeachersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTeachersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolTeachersRes(); }
}

/**
* 修改教师姓名
*/
// @Route("/school/{SchoolId}/teacher/{TeacherId}/fullname", "POST, OPTIONS")
// @Api(Description="修改教师姓名")
export class UpdateSchoolTeacherFullNameReq implements IReturn<CommonReturn>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 教师Id
    */
    // @ApiMember(DataType="string", Description="教师Id", IsRequired=true, Name="TeacherId", ParameterType="path")
    public teacherId?: string;

    /**
    * 全名
    */
    // @ApiMember(DataType="string", Description="全名", IsRequired=true, Name="FullName", ParameterType="query")
    public fullName?: string;

    public remark?: string;

    public constructor(init?: Partial<UpdateSchoolTeacherFullNameReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolTeacherFullNameReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/school/inittestdata", "POST, OPTIONS")
export class InitDataReq implements IReturn<InitDataRes>
{
    public amount?: number;
    public startPhone?: number;

    public constructor(init?: Partial<InitDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InitDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InitDataRes(); }
}

/**
* 创建园所
*/
// @Route("/school", "POST, OPTIONS")
// @Api(Description="创建园所")
export class CreateSchoolInfoReq implements IReturn<CreateSchoolInfoRes>
{
    /**
    * 园所名称
    */
    // @ApiMember(DataType="string", Description="园所名称", IsRequired=true, Name="SchoolName", ParameterType="query")
    public schoolName?: string;

    public schoolEName?: string;
    public remark?: string;
    /**
    * 园所管理员手机号码
    */
    // @ApiMember(DataType="string", Description="园所管理员手机号码", IsRequired=true, Name="MasterPhoneNumber", ParameterType="query")
    public masterPhoneNumber?: string;

    /**
    * 园所管理员全名
    */
    // @ApiMember(DataType="string", Description="园所管理员全名", IsRequired=true, Name="MasterFullName", ParameterType="query")
    public masterFullName?: string;

    /**
    * 区
    */
    // @ApiMember(DataType="string", Description="区", IsRequired=true, Name="District", ParameterType="query")
    public district?: string;

    /**
    * 城市
    */
    // @ApiMember(DataType="string", Description="城市", IsRequired=true, Name="City", ParameterType="query")
    public city?: string;

    /**
    * 省份
    */
    // @ApiMember(DataType="string", Description="省份", IsRequired=true, Name="Province", ParameterType="query")
    public province?: string;

    /**
    * 学校类型
    */
    // @ApiMember(DataType="string", Description="学校类型", IsRequired=true, Name="SchoolType", ParameterType="query")
    public schoolType: SCHOOL_TYPE;

    public constructor(init?: Partial<CreateSchoolInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateSchoolInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateSchoolInfoRes(); }
}

/**
* 修改园所
*/
// @Route("/school/update", "POST, OPTIONS")
// @Api(Description="修改园所")
export class UpdateSchoolInfoReq implements IReturn<CommonReturn>
{
    /**
    * 园所Id
    */
    // @ApiMember(DataType="string", Description="园所Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 园所名称
    */
    // @ApiMember(DataType="string", Description="园所名称", IsRequired=true, Name="SchoolName", ParameterType="query")
    public schoolName?: string;

    public schoolEName?: string;
    public remark?: string;
    /**
    * 园所管理员手机号码
    */
    // @ApiMember(DataType="string", Description="园所管理员手机号码", IsRequired=true, Name="MasterPhoneNumber", ParameterType="query")
    public masterPhoneNumber?: string;

    /**
    * 园所管理员全名
    */
    // @ApiMember(DataType="string", Description="园所管理员全名", IsRequired=true, Name="MasterFullName", ParameterType="query")
    public masterFullName?: string;

    /**
    * 区
    */
    // @ApiMember(DataType="string", Description="区", IsRequired=true, Name="District", ParameterType="query")
    public district?: string;

    /**
    * 城市
    */
    // @ApiMember(DataType="string", Description="城市", IsRequired=true, Name="City", ParameterType="query")
    public city?: string;

    /**
    * 省份
    */
    // @ApiMember(DataType="string", Description="省份", IsRequired=true, Name="Province", ParameterType="query")
    public province?: string;

    /**
    * 学校类型
    */
    // @ApiMember(DataType="string", Description="学校类型", IsRequired=true, Name="SchoolType", ParameterType="query")
    public schoolType: SCHOOL_TYPE;

    public serviceModules?: string[];

    public constructor(init?: Partial<UpdateSchoolInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改园所禁用的服务模块
*/
// @Route("/school/update", "POST, OPTIONS")
// @Api(Description="修改园所禁用的服务模块")
export class UpdateSchoolDisabledServiceModulesReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public disabledServiceModules?: string[];

    public constructor(init?: Partial<UpdateSchoolDisabledServiceModulesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolDisabledServiceModulesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取园所信息
*/
// @Route("/school", "GET, OPTIONS")
// @Api(Description="获取园所信息")
export class GetSchoolInfoReq implements IReturn<GetSchoolInfoRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolInfoRes(); }
}

/**
* 获取园所列表
*/
// @Route("/schools", "GET, OPTIONS")
// @Api(Description="获取园所列表")
export class GetSchoolInfosReq implements IReturn<GetSchoolInfosRes>
{

    public constructor(init?: Partial<GetSchoolInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolInfosRes(); }
}

/**
* 客户端扫码动作
*/
// @Route("/system/qrlogin/clientcheck", "POST, OPTIONS")
// @Api(Description="客户端扫码动作")
export class ConfirmQRCodeLoginReq implements IReturn<CommonReturn>
{
    public ticket?: string;
    public act?: number;

    public constructor(init?: Partial<ConfirmQRCodeLoginReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConfirmQRCodeLoginReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取学生的晨检数据
*/
// @Route("/school/{SchoolId}/healthies/excel", "GET, OPTIONS")
// @Api(Description="获取学生的晨检数据")
export class GetSchoolKidDailyHealthiesMonthReportExcelReq implements IReturn<Blob>
{
    public schoolId?: string;
    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolKidDailyHealthiesMonthReportExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidDailyHealthiesMonthReportExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取学生的晨检数据
*/
// @Route("/school/{SchoolId}/healthies", "GET, OPTIONS")
// @Api(Description="获取学生的晨检数据")
export class GetSchoolKidDailyHealthiesReq implements IReturn<GetSchoolKidDailyHealthiesRes>
{
    public schoolId?: string;
    public dateOn?: string;
    public beginDate?: string;
    public endDate?: string;
    public pageSize?: number;
    public lastId?: string;

    public constructor(init?: Partial<GetSchoolKidDailyHealthiesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidDailyHealthiesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidDailyHealthiesRes(); }
}

/**
* 设置学生的晨检数据
*/
// @Route("/school/{SchoolId}/healthy/dailycheck", "POST, OPTIONS")
// @Api(Description="设置学生的晨检数据")
export class SetKidDailyHealthyReq implements IReturn<SetKidDailyHealthyRes>
{
    public schoolId?: string;
    public kidId?: string;
    public temperature?: string;
    public mouthHaveProblem?: boolean;
    public mouthProblem?: string;
    public bodyHaveProblem?: boolean;
    public bodyProblem?: string;
    public remark?: string;

    public constructor(init?: Partial<SetKidDailyHealthyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetKidDailyHealthyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetKidDailyHealthyRes(); }
}

/**
* 设置学生的照顾数据
*/
// @Route("/school/{SchoolId}/care", "POST, OPTIONS")
// @Api(Description="设置学生的照顾数据")
export class SetKidDailyCareReq implements IReturn<SetKidDailyCareRes>
{
    public schoolId?: string;
    public kidId?: string;
    public drinkWaterAmount?: number;
    public peeCount?: number;
    public pooCount?: number;
    public remark?: string;

    public constructor(init?: Partial<SetKidDailyCareReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetKidDailyCareReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetKidDailyCareRes(); }
}

/**
* 教师读取单条食谱
*/
// @Route("/school/{SchoolId}/dailymenuteacher/{MenuId}", "GET, OPTIONS")
// @Api(Description="教师读取单条食谱")
export class GetDailyMenuFromTeacherReq implements IReturn<GetDailyMenuRes>
{
    public schoolId?: string;
    public menuId?: string;

    public constructor(init?: Partial<GetDailyMenuFromTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyMenuFromTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyMenuRes(); }
}

/**
* 教师读取食谱列表
*/
// @Route("/school/{SchoolId}/dailymenusteacher", "GET, OPTIONS")
// @Api(Description="教师读取食谱列表")
export class GetDailyMenusFromTeacherReq implements IReturn<GetDailyMenusRes>
{
    public schoolId?: string;
    public lastReadId?: string;
    public isPublished?: boolean;
    public pageSize?: number;

    public constructor(init?: Partial<GetDailyMenusFromTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyMenusFromTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyMenusRes(); }
}

/**
* 创建食谱
*/
// @Route("/school/{SchoolId}/dailymenu", "POST, OPTIONS")
// @Api(Description="创建食谱")
export class CreateDailyMenuReq implements IReturn<CreateDailyMenuRes>
{
    public schoolId?: string;
    public title?: string;
    public content1?: string;
    public attach1?: EventAttachItem;
    public content2?: string;
    public attach2?: EventAttachItem;
    public content3?: string;
    public attach3?: EventAttachItem;
    public dateOn?: string;

    public constructor(init?: Partial<CreateDailyMenuReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateDailyMenuReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateDailyMenuRes(); }
}

/**
* 创建食谱
*/
// @Route("/school/{SchoolId}/dailymenuv2", "POST")
// @Api(Description="创建食谱")
export class CreateDailyMenuV2Req implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public title?: string;
    public dateOn?: string;
    public nutritions?: Nutrition;
    public contents?: DishMenuContentDTO[];

    public constructor(init?: Partial<CreateDailyMenuV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateDailyMenuV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新食谱
*/
// @Route("/school/{SchoolId}/dailymenu/{MenuId}", "POST, OPTIONS")
// @Api(Description="更新食谱")
export class UpdateDailyMenuV2Req implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public menuId?: string;
    public title?: string;
    public dateOn?: string;
    public nutritions?: Nutrition;
    public contents?: DishMenuContentDTO[];

    public constructor(init?: Partial<UpdateDailyMenuV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateDailyMenuV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除食谱
*/
// @Route("/school/{SchoolId}/dailymenu/{MenuId}", "DELETE, OPTIONS")
// @Api(Description="删除食谱")
export class DeleteDailyMenuReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public menuId?: string;

    public constructor(init?: Partial<DeleteDailyMenuReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDailyMenuReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新食谱
*/
// @Route("/school/{SchoolId}/dailymenu/{MenuId}", "POST, OPTIONS")
// @Api(Description="更新食谱")
export class UpdateDailyMenuReq implements IReturn<UpdateDailyMenuRes>
{
    public schoolId?: string;
    public menuId?: string;
    public title?: string;
    public content1?: string;
    public attach1?: EventAttachItem;
    public content2?: string;
    public attach2?: EventAttachItem;
    public content3?: string;
    public attach3?: EventAttachItem;
    public dateOn?: string;

    public constructor(init?: Partial<UpdateDailyMenuReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateDailyMenuReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateDailyMenuRes(); }
}

/**
* 获取周的食谱列表
*/
// @Route("/school/{SchoolId}/dailymenu/weekly", "GET")
// @Api(Description="获取周的食谱列表")
export class GetSchoolDailyMenusWeeklyReq implements IReturn<GetSchoolDailyMenusWeeklyRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId 不可为空")
    public schoolId?: string;

    public beginDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetSchoolDailyMenusWeeklyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolDailyMenusWeeklyReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolDailyMenusWeeklyRes(); }
}

/**
* 获得学校平台设置
*/
// @Route("/system/{SchoolId}/setting", "GET, OPTIONS")
// @Api(Description="获得学校平台设置")
export class GetSchoolSettingReq implements IReturn<GetSchoolSettingRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolSettingReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolSettingReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolSettingRes(); }
}

/**
* 更新学校平台设置
*/
// @Route("/system/{SchoolId}/setting", "POST, OPTIONS")
// @Api(Description="更新学校平台设置")
export class UpdateSchoolSettingReq implements IReturn<GetSchoolSettingRes>
{
    public schoolId?: string;
    public settingKey?: string;
    public settingValue?: string;

    public constructor(init?: Partial<UpdateSchoolSettingReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolSettingReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolSettingRes(); }
}

/**
* 自定义教师APP菜单
*/
// @Route("/system/{SchoolId}/customappmenudata", "POST, OPTIONS")
// @Api(Description="自定义教师APP菜单")
export class SaveTeacherCustomAppMenuReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public customIconPermissionCode?: string[];

    public constructor(init?: Partial<SaveTeacherCustomAppMenuReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveTeacherCustomAppMenuReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获得教师APP菜单
*/
// @Route("/system/{SchoolId}/appmenudata", "GET, OPTIONS")
// @Api(Description="获得教师APP菜单")
export class GetTeacherAppMenuReq implements IReturn<GetTeacherAppMenuRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetTeacherAppMenuReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherAppMenuReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherAppMenuRes(); }
}

/**
* 获取员工职务配置信息
*/
// @Route("/school/{SchoolId}/config/jobtitles", "GET, OPTIONS")
// @Api(Description="获取员工职务配置信息")
export class GetSchoolConfigJobTitleReq implements IReturn<GetSchoolConfigJobTitleRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolConfigJobTitleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolConfigJobTitleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolConfigJobTitleRes(); }
}

/**
* 添加员工职务配置信息
*/
// @Route("/school/{SchoolId}/config/jobtitles", "POST, OPTIONS")
// @Api(Description="添加员工职务配置信息")
export class AddSchoolConfigJobTitleReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public jobTitle?: string;

    public constructor(init?: Partial<AddSchoolConfigJobTitleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolConfigJobTitleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除员工职务配置信息
*/
// @Route("/school/{SchoolId}/config/jobtitles", "DELETE, OPTIONS")
// @Api(Description="删除员工职务配置信息")
export class DeleteSchoolConfigJobTitleReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public jobTitle?: string;

    public constructor(init?: Partial<DeleteSchoolConfigJobTitleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSchoolConfigJobTitleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取家长的电话号码
*/
// @Route("/school/{SchoolId}/parentinfo", "POST, OPTIONS")
// @Api(Description="获取家长的电话号码")
export class GetClearParentInfoReq implements IReturn<GetClearParentInfoRes>
{
    public schoolId?: string;
    public kidId?: string;
    public module?: string;

    public constructor(init?: Partial<GetClearParentInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClearParentInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClearParentInfoRes(); }
}

// @Route("/auth/requesttokentest", "GET, OPTIONS")
export class GetNewAuthTokenTestReq implements IReturn<CommonReturn>
{
    public streamName?: string;
    public sourceName?: string;

    public constructor(init?: Partial<GetNewAuthTokenTestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetNewAuthTokenTestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/auth/requesttoken", "POST, OPTIONS")
export class GetNewAuthTokenReq implements IReturn<AuthenticateResponse>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public constructor(init?: Partial<GetNewAuthTokenReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetNewAuthTokenReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

/**
* 修改自己的用户名
*/
// @Route("/teacher/my/name", "POST, OPTIONS")
// @Api(Description="修改自己的用户名")
export class UpdateTeacherNameReq implements IReturn<CommonReturn>
{
    public nickname?: string;
    public fullname?: string;

    public constructor(init?: Partial<UpdateTeacherNameReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeacherNameReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/my/worktime", "POST, OPTIONS")
export class SetWorkTimeReq implements IReturn<CommonReturn>
{
    public workday?: number[];
    public morningbegin?: string;
    public morningend?: string;
    public afternoonbegin?: string;
    public afternoonend?: string;

    public constructor(init?: Partial<SetWorkTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetWorkTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/my/worktime", "GET, OPTIONS")
export class GetMyWorkTimeReq implements IReturn<GetMyWorkTimeRes>
{

    public constructor(init?: Partial<GetMyWorkTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMyWorkTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMyWorkTimeRes(); }
}

/**
* 园所通知已读
*/
// @Route("/school/{SchoolId}/notice/{Id}/view", "POST, OPTIONS")
// @Api(Description="园所通知已读")
export class AddSchoolNoticeFeedViewHistoryReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public id?: string;

    public constructor(init?: Partial<AddSchoolNoticeFeedViewHistoryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolNoticeFeedViewHistoryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/my/worktime", "DELETE, OPTIONS")
export class DeleteWorkTimeReq implements IReturn<CommonReturn>
{

    public constructor(init?: Partial<DeleteWorkTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteWorkTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取教师自己的用户信息
*/
// @Route("/teacher/my/userinfo", "GET, OPTIONS")
// @Api(Description="获取教师自己的用户信息")
export class GetTeacherMyUserInfoReq extends CommonRequest implements IReturn<GetTeacherMyUserInfoRes>
{
    /**
    * 极光AppKey
    */
    // @ApiMember(DataType="string", Description="极光AppKey", Name="JPushAppKey", ParameterType="query")
    public jPushAppKey?: string;

    /**
    * 极光设备id
    */
    // @ApiMember(DataType="string", Description="极光设备id", Name="JPushRegId", ParameterType="query")
    public jPushRegId?: string;

    /**
    * MobPushAppKey
    */
    // @ApiMember(DataType="string", Description="MobPushAppKey", Name="MobPushAppKey", ParameterType="query")
    public mobPushAppKey?: string;

    /**
    * MobPush设备id
    */
    // @ApiMember(DataType="string", Description="MobPush设备id", Name="MobPushRegId", ParameterType="query")
    public mobPushRegId?: string;

    public constructor(init?: Partial<GetTeacherMyUserInfoReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherMyUserInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherMyUserInfoRes(); }
}

/**
* 获取园所通知Feed
*/
// @Route("/school/{SchoolId}/noticefeeds", "GET, OPTIONS")
// @Api(Description="获取园所通知Feed")
export class GetSchoolNoticeFeedsReq implements IReturn<GetSchoolNoticeFeedsRes>
{
    public schoolId?: string;
    public begin?: number;

    public constructor(init?: Partial<GetSchoolNoticeFeedsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolNoticeFeedsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolNoticeFeedsRes(); }
}

/**
* 获取园所通知
*/
// @Route("/school/{SchoolId}/notices", "POST, OPTIONS")
// @Api(Description="获取园所通知")
export class GetSchoolNoticesByIdReq implements IReturn<GetSchoolNoticesRes>
{
    public schoolId?: string;
    public noticeIds?: string[];

    public constructor(init?: Partial<GetSchoolNoticesByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolNoticesByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolNoticesRes(); }
}

/**
* 复制一条园本库内容
*/
// @Route("/class/kglib/copy/{LibId}", "POST, OPTIONS")
// @Api(Description="复制一条园本库内容")
export class CopyKGLibItemReq implements IReturn<CommonReturn>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * LibId
    */
    // @ApiMember(DataType="string", Description="LibId", IsRequired=true, Name="LibId", ParameterType="path")
    public libId?: string;

    public constructor(init?: Partial<CopyKGLibItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CopyKGLibItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加通知
*/
// @Route("/class/kglib/notifys", "POST, OPTIONS")
// @Api(Description="添加通知")
export class AddKGLibNotifyReq implements IReturn<CommonReturn>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * Tags
    */
    // @ApiMember(DataType="array", Description="Tags", IsRequired=true, Name="Tags", ParameterType="query")
    public tags: string[];

    /**
    * 可见范围
    */
    // @ApiMember(DataType="string", Description="可见范围", IsRequired=true, Name="ShareType", ParameterType="query")
    public shareType: KGLIB_SHARETYPE;

    public constructor(init?: Partial<AddKGLibNotifyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKGLibNotifyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加分享
*/
// @Route("/class/kglib/shares", "POST, OPTIONS")
// @Api(Description="添加分享")
export class AddKGLibShareReq implements IReturn<CommonReturn>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * Tags
    */
    // @ApiMember(DataType="array", Description="Tags", IsRequired=true, Name="Tags", ParameterType="query")
    public tags: string[];

    /**
    * 可见范围
    */
    // @ApiMember(DataType="string", Description="可见范围", IsRequired=true, Name="ShareType", ParameterType="query")
    public shareType: KGLIB_SHARETYPE;

    public constructor(init?: Partial<AddKGLibShareReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKGLibShareReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加成长任务
*/
// @Route("/class/kglib/tasks", "POST, OPTIONS")
// @Api(Description="添加成长任务")
export class AddKGLibTaskReq implements IReturn<CommonReturn>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * Tags
    */
    // @ApiMember(DataType="array", Description="Tags", IsRequired=true, Name="Tags", ParameterType="query")
    public tags: string[];

    /**
    * 可见范围
    */
    // @ApiMember(DataType="string", Description="可见范围", IsRequired=true, Name="ShareType", ParameterType="query")
    public shareType: KGLIB_SHARETYPE;

    public constructor(init?: Partial<AddKGLibTaskReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKGLibTaskReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加在园生活
*/
// @Route("/class/kglib/kgtimes", "POST, OPTIONS")
// @Api(Description="添加在园生活")
export class AddKGLibKGTimeReq implements IReturn<CommonReturn>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * Tags
    */
    // @ApiMember(DataType="array", Description="Tags", IsRequired=true, Name="Tags", ParameterType="query")
    public tags: string[];

    /**
    * 可见范围
    */
    // @ApiMember(DataType="string", Description="可见范围", IsRequired=true, Name="ShareType", ParameterType="query")
    public shareType: KGLIB_SHARETYPE;

    public constructor(init?: Partial<AddKGLibKGTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKGLibKGTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加投票
*/
// @Route("/class/kglib/votes", "POST, OPTIONS")
// @Api(Description="添加投票")
export class AddKGLibVoteReq implements IReturn<CommonReturn>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public questions?: VoteItemSerial[];
    /**
    * Tags
    */
    // @ApiMember(DataType="array", Description="Tags", IsRequired=true, Name="Tags", ParameterType="query")
    public tags: string[];

    /**
    * 可见范围
    */
    // @ApiMember(DataType="string", Description="可见范围", IsRequired=true, Name="ShareType", ParameterType="query")
    public shareType: KGLIB_SHARETYPE;

    public constructor(init?: Partial<AddKGLibVoteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKGLibVoteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新园本库
*/
// @Route("/class/kglib/common/{LibId}", "POST, OPTIONS")
// @Api(Description="更新园本库")
export class UpdateKGLibCommonReq implements IReturn<CommonReturn>
{
    /**
    * LibId
    */
    // @ApiMember(DataType="string", Description="LibId", IsRequired=true, Name="LibId", ParameterType="path")
    public libId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * Tags
    */
    // @ApiMember(DataType="array", Description="Tags", IsRequired=true, Name="Tags", ParameterType="query")
    public tags: string[];

    /**
    * 可见范围
    */
    // @ApiMember(DataType="string", Description="可见范围", IsRequired=true, Name="ShareType", ParameterType="query")
    public shareType: KGLIB_SHARETYPE;

    public constructor(init?: Partial<UpdateKGLibCommonReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKGLibCommonReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新园本库投票
*/
// @Route("/class/kglib/vote/{LibId}", "POST, OPTIONS")
// @Api(Description="更新园本库投票")
export class UpdateKGLibVoteReq implements IReturn<CommonReturn>
{
    /**
    * LibId
    */
    // @ApiMember(DataType="string", Description="LibId", IsRequired=true, Name="LibId", ParameterType="path")
    public libId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public voteItemSerials?: VoteItemSerial[];
    /**
    * Tags
    */
    // @ApiMember(DataType="array", Description="Tags", IsRequired=true, Name="Tags", ParameterType="query")
    public tags: string[];

    /**
    * 可见范围
    */
    // @ApiMember(DataType="string", Description="可见范围", IsRequired=true, Name="ShareType", ParameterType="query")
    public shareType: KGLIB_SHARETYPE;

    public constructor(init?: Partial<UpdateKGLibVoteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKGLibVoteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 删除园本库
*/
// @Route("/class/kglib/common/{LibId}", "DELETE, OPTIONS")
// @Api(Description="删除园本库")
export class DeleteKGLibCommonReq implements IReturn<CommonReturn>
{
    /**
    * LibId
    */
    // @ApiMember(DataType="string", Description="LibId", IsRequired=true, Name="LibId", ParameterType="path")
    public libId?: string;

    public constructor(init?: Partial<DeleteKGLibCommonReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteKGLibCommonReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取园本库索引
*/
// @Route("/class/kglib/index", "GET, OPTIONS")
// @Api(Description="获取园本库索引")
export class GetKGLibIndexReq implements IReturn<GetKGLibIndexRes>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    public constructor(init?: Partial<GetKGLibIndexReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKGLibIndexReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKGLibIndexRes(); }
}

/**
* 批量获取园本库内容
*/
// @Route("/class/kglibs", "POST, OPTIONS")
// @Api(Description="批量获取园本库内容")
export class GetKGLibsReq implements IReturn<GetKGLibsRes>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * kgLibIds
    */
    // @ApiMember(DataType="array", Description="kgLibIds", IsRequired=true, Name="kgLibIds", ParameterType="query")
    public kgLibIds: string[];

    public constructor(init?: Partial<GetKGLibsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKGLibsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKGLibsRes(); }
}

/**
* 修改某个消息所属的主题
*/
// @Route("/class/{ClassId}/event/{EventId}/changetheme", "POST, OPTIONS")
// @Api(Description="修改某个消息所属的主题")
export class ChangeEventThemeReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public eventId?: string;
    public themeId?: string;

    public constructor(init?: Partial<ChangeEventThemeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChangeEventThemeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 教师在园时光添加评论
*/
// @Route("/class/event/kgtime/{EventId}/tcomment", "POST, OPTIONS")
// @Api(Description="教师在园时光添加评论")
export class AddEventKGTimeCommentTeacherReq implements IReturn<GetClassEventForTeacherRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * 回复哪个用户id，非回复留空
    */
    // @ApiMember(DataType="string", Description="回复哪个用户id，非回复留空", IsRequired=true, Name="ReplyTo", ParameterType="query")
    public replyTo?: string;

    /**
    * 是否是语音回复
    */
    // @ApiMember(DataType="boolean", Description="是否是语音回复", IsRequired=true, Name="IsAudio", ParameterType="query")
    public isAudio: boolean;

    /**
    * 文字回复内容
    */
    // @ApiMember(DataType="string", Description="文字回复内容", Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<AddEventKGTimeCommentTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventKGTimeCommentTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventForTeacherRes(); }
}

/**
* 教师家庭时光点赞
*/
// @Route("/class/event/hometime/{EventId}/tlike", "POST, OPTIONS")
// @Api(Description="教师家庭时光点赞")
export class AddEventHomeTimeLikeTeacherReq implements IReturn<GetClassEventForTeacherRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * 是点赞还是取消点赞
    */
    // @ApiMember(DataType="boolean", Description="是点赞还是取消点赞", IsRequired=true, Name="IsLike", ParameterType="query")
    public isLike: boolean;

    public constructor(init?: Partial<AddEventHomeTimeLikeTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventHomeTimeLikeTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventForTeacherRes(); }
}

/**
* 教师在园时光点赞
*/
// @Route("/class/event/kgtime/{EventId}/tlike", "POST, OPTIONS")
// @Api(Description="教师在园时光点赞")
export class AddEventKGTimeLikeTeacherReq implements IReturn<GetClassEventForTeacherRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * 是点赞还是取消点赞
    */
    // @ApiMember(DataType="boolean", Description="是点赞还是取消点赞", IsRequired=true, Name="IsLike", ParameterType="query")
    public isLike: boolean;

    public constructor(init?: Partial<AddEventKGTimeLikeTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventKGTimeLikeTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventForTeacherRes(); }
}

/**
* 获取消息的统计信息
*/
// @Route("/class/{ClassId}/event/{EventId}/trend", "GET, OPTIONS")
// @Api(Description="获取消息的统计信息")
export class GetClassEventTrendReq implements IReturn<GetClassEventTrendRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    public constructor(init?: Partial<GetClassEventTrendReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassEventTrendReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventTrendRes(); }
}

/**
* 教师获取所有消息接口
*/
// @Route("/class/{ClassId}/events", "GET, OPTIONS")
// @Api(Description="教师获取所有消息接口")
export class GetClassEventsByTeacherReq extends CommonRequest implements IReturn<GetClassEventsForTeacherRes>
{
    public pagesize?: number;
    public isGetNewer?: boolean;
    public currentNewestId?: string;
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    public constructor(init?: Partial<GetClassEventsByTeacherReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassEventsByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventsForTeacherRes(); }
}

/**
* 获取单条消息接口
*/
// @Route("/class/eventforteacher/{EventId}", "GET, OPTIONS")
// @Api(Description="获取单条消息接口")
export class GetClassEventForTeacherReq implements IReturn<GetClassEventForTeacherRes>
{
    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    public constructor(init?: Partial<GetClassEventForTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassEventForTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventForTeacherRes(); }
}

/**
* 教师获取单条动态中的AI检测信息
*/
// @Route("/class/event/aiinfoteacher/{eventid}", "GET, OPTIONS")
// @Api(Description="教师获取单条动态中的AI检测信息")
export class GetEventAiInfoByTeacherReq implements IReturn<GetEventAiInfoRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventId?: string;

    public constructor(init?: Partial<GetEventAiInfoByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventAiInfoByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventAiInfoRes(); }
}

/**
* 获取教师多条消息接口V2
*/
// @Route("/v2/teacher/events", "POST, OPTIONS")
// @Api(Description="获取教师多条消息接口V2")
export class GetMultiClassEventsForTeacherV2Req implements IReturn<GetMultiClassEventsForTeacherV2Res>
{
    /**
    * EventId列表
    */
    // @ApiMember(DataType="array", Description="EventId列表", IsRequired=true, Name="EventIds", ParameterType="query")
    public eventIds: string[];

    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public constructor(init?: Partial<GetMultiClassEventsForTeacherV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMultiClassEventsForTeacherV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMultiClassEventsForTeacherV2Res(); }
}

/**
* 获取教师多条消息接口
*/
// @Route("/teacher/events", "POST, OPTIONS")
// @Api(Description="获取教师多条消息接口")
export class GetMultiClassEventsForTeacherReq implements IReturn<GetMultiClassEventsForTeacherRes>
{
    /**
    * EventId列表
    */
    // @ApiMember(DataType="array", Description="EventId列表", IsRequired=true, Name="EventIds", ParameterType="query")
    public eventIds: string[];

    public constructor(init?: Partial<GetMultiClassEventsForTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMultiClassEventsForTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMultiClassEventsForTeacherRes(); }
}

/**
* 发布通知
*/
// @Route("/class/event/notifys", "POST, OPTIONS")
// @Api(Description="发布通知")
export class AddEventNotifyReq implements IReturn<CommonReturn>
{
    public targets?: TargetClassAndMember[];
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    public publishTime?: string;
    public isPartialPublish?: boolean;

    public constructor(init?: Partial<AddEventNotifyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventNotifyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 发布分享消息
*/
// @Route("/class/event/shares", "POST, OPTIONS")
// @Api(Description="发布分享消息")
export class AddEventShareReq implements IReturn<CommonReturn>
{
    public targets?: TargetClassAndMember[];
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    public isPartialPublish?: boolean;

    public constructor(init?: Partial<AddEventShareReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventShareReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 发布已保存的动态
*/
// @Route("/class/event/permitpublish", "POST, OPTIONS")
// @Api(Description="发布已保存的动态")
export class PublishEventReq implements IReturn<CommonReturn>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="query")
    public eventId?: string;

    public constructor(init?: Partial<PublishEventReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PublishEventReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 审核动态内容
*/
// @Route("/class/event/permitpublish", "POST, OPTIONS")
// @Api(Description="审核动态内容")
export class PermitEventPublishReq implements IReturn<CommonReturn>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="query")
    public eventId?: string;

    public constructor(init?: Partial<PermitEventPublishReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PermitEventPublishReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 审核打回动态内容
*/
// @Route("/class/event/permitpublish", "POST, OPTIONS")
// @Api(Description="审核打回动态内容")
export class DenyEventPublishReq implements IReturn<CommonReturn>
{
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="query")
    public eventId?: string;

    public constructor(init?: Partial<DenyEventPublishReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DenyEventPublishReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 发布在园时光
*/
// @Route("/class/event/kgtimes", "POST, OPTIONS")
// @Api(Description="发布在园时光")
export class AddEventKGTimeReq implements IReturn<CommonReturn>
{
    public targets?: TargetClassAndMember[];
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    /**
    * 主题Id
    */
    // @ApiMember(DataType="string", Description="主题Id", Name="ThemeId", ParameterType="query")
    public themeId?: string;

    /**
    * IsOnlySave
    */
    // @ApiMember(DataType="boolean", Description="IsOnlySave", Name="IsOnlySave", ParameterType="query")
    public isOnlySave?: boolean;

    /**
    * IsPartialPublish
    */
    // @ApiMember(DataType="boolean", Description="IsPartialPublish", Name="IsPartialPublish", ParameterType="query")
    public isPartialPublish?: boolean;

    /**
    * NeedPayment
    */
    // @ApiMember(DataType="boolean", Description="NeedPayment", Name="NeedPayment", ParameterType="query")
    public needPayment?: boolean;

    /**
    * PaymentPrice
    */
    // @ApiMember(DataType="integer", Description="PaymentPrice", Name="PaymentPrice", ParameterType="query")
    public paymentPrice?: number;

    public constructor(init?: Partial<AddEventKGTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventKGTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 发布投票类消息
*/
// @Route("/class/event/vote", "POST, OPTIONS")
// @Api(Description="发布投票类消息")
export class AddEventVoteReq implements IReturn<CommonReturn>
{
    public targets?: TargetClassAndMember[];
    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public title?: string;
    public content?: string;
    public enableSyncAttach?: boolean;
    public publishTime?: string;
    public isAnonymous?: boolean;
    public canSeeResult?: boolean;
    public endTime?: string;
    public questions?: VoteItemSerial[];
    public isPartialPublish?: boolean;

    public constructor(init?: Partial<AddEventVoteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventVoteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新通知
*/
// @Route("/class/event/notifys/{EventId}", "PUT, OPTIONS")
// @Api(Description="更新通知")
export class UpdateEventNotifyReq implements IReturn<UpdateEventCommonRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    public constructor(init?: Partial<UpdateEventNotifyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEventNotifyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEventCommonRes(); }
}

/**
* 更新在园时光
*/
// @Route("/class/event/kgtimes/{EventId}", "PUT, OPTIONS")
// @Api(Description="更新在园时光")
export class UpdateEventKGTimeReq implements IReturn<UpdateEventKGTimeRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    /**
    * 主题Id
    */
    // @ApiMember(DataType="string", Description="主题Id", Name="ThemeId", ParameterType="query")
    public themeId?: string;

    public constructor(init?: Partial<UpdateEventKGTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEventKGTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEventKGTimeRes(); }
}

/**
* 更新分享消息
*/
// @Route("/class/event/shares/{EventId}", "PUT, OPTIONS")
// @Api(Description="更新分享消息")
export class UpdateEventShareReq implements IReturn<UpdateEventCommonRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    public constructor(init?: Partial<UpdateEventShareReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEventShareReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEventCommonRes(); }
}

/**
* 更新成长任务
*/
// @Route("/class/event/tasks/{EventId}", "PUT, OPTIONS")
// @Api(Description="更新成长任务")
export class UpdateEventTaskReq implements IReturn<UpdateEventCommonRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    /**
    * 主题Id
    */
    // @ApiMember(DataType="string", Description="主题Id", Name="ThemeId", ParameterType="query")
    public themeId?: string;

    /**
    * 家长是否相互可见
    */
    // @ApiMember(DataType="boolean", Description="家长是否相互可见", Name="CanSeeOther", ParameterType="query")
    public canSeeOther?: boolean;

    /**
    * 家长可回复的形式
    */
    // @ApiMember(DataType="string", Description="家长可回复的形式", Name="CommentType", ParameterType="query")
    public commentType?: TASKATTACHTYPE;

    public constructor(init?: Partial<UpdateEventTaskReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEventTaskReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEventCommonRes(); }
}

/**
* 获取教师动态feed列表
*/
// @Route("/feeds/teacherevent", "GET, OPTIONS")
// @Api(Description="获取教师动态feed列表")
export class GetClassEventsFeedByTeacherReq implements IReturn<GetClassEventsFeedRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    public isHeadMode?: boolean;
    public classIds?: string[];

    public constructor(init?: Partial<GetClassEventsFeedByTeacherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassEventsFeedByTeacherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventsFeedRes(); }
}

/**
* 主题排序
*/
// @Route("/class/{ClassId}/teachingtheme/{ThemeId}/reorder", "POST, OPTIONS")
// @Api(Description="主题排序")
export class ReorderTeachingThemeReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public themeId?: string;
    public newOrderPos?: number;

    public constructor(init?: Partial<ReorderTeachingThemeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReorderTeachingThemeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取所有主题列表
*/
// @Route("/class/teachingthemes", "GET, OPTIONS")
// @Api(Description="获取所有主题列表")
export class GetTeachingThemeReq implements IReturn<GetTeachingThemeRes>
{
    public classId?: string;
    public isHeadMode?: boolean;

    public constructor(init?: Partial<GetTeachingThemeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeachingThemeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeachingThemeRes(); }
}

/**
* 删除一条主题
*/
// @Route("/class/{ClassId}/teachingtheme/{ThemeId}", "DELETE, OPTIONS")
// @Api(Description="删除一条主题")
export class DeleteTeachingThemeReq implements IReturn<CommonReturn>
{
    public themeId?: string;
    public classId?: string;

    public constructor(init?: Partial<DeleteTeachingThemeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteTeachingThemeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改一条主题
*/
// @Route("/class/{ClassId}/teachingtheme/{ThemeId}", "POST, OPTIONS")
// @Api(Description="修改一条主题")
export class UpdateTeachingThemeReq implements IReturn<CommonReturn>
{
    public themeId?: string;
    public classId?: string;
    public title?: string;
    public content?: string;
    public semestrType?: SEMESTERTYPE;

    public constructor(init?: Partial<UpdateTeachingThemeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeachingThemeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 创建一条主题
*/
// @Route("/class/{ClassId}/teachingtheme", "POST, OPTIONS")
// @Api(Description="创建一条主题")
export class AddTeachingThemeReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public title?: string;
    public content?: string;
    public semestrType?: SEMESTERTYPE;

    public constructor(init?: Partial<AddTeachingThemeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddTeachingThemeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取模板目录
*/
// @Route("/class/event/templatecatalogs", "GET, OPTIONS")
// @Api(Description="获取模板目录")
export class GetEventTemplateCatalogReq implements IReturn<GetEventTemplateCatalogRes>
{

    public constructor(init?: Partial<GetEventTemplateCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventTemplateCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventTemplateCatalogRes(); }
}

/**
* 客户端获取模板
*/
// @Route("/class/event/templates/{EventClass}", "GET, OPTIONS")
// @Api(Description="客户端获取模板")
export class GetEventTemplatesReq implements IReturn<GetEventTemplatesRes>
{
    /**
    * EventClass
    */
    // @ApiMember(DataType="string", Description="EventClass", IsRequired=true, Name="EventClass", ParameterType="path")
    public eventClass?: string;

    /**
    * Version
    */
    // @ApiMember(DataType="integer", Description="Version", Name="Version", ParameterType="query")
    public version?: number;

    public constructor(init?: Partial<GetEventTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventTemplatesRes(); }
}

/**
* 后台管理删除模板
*/
// @Route("/class/event/templatessource", "DELETE, OPTIONS")
// @Api(Description="后台管理删除模板")
export class DeleteEventTemplatesSourceReq implements IReturn<CommonReturn>
{
    /**
    * EventTemplateId
    */
    // @ApiMember(DataType="string", Description="EventTemplateId", IsRequired=true, Name="EventTemplateId", ParameterType="query")
    public eventTemplateId?: string;

    public constructor(init?: Partial<DeleteEventTemplatesSourceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEventTemplatesSourceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 后台管理获取模板
*/
// @Route("/class/event/templatessource/{EventClass}", "GET, OPTIONS")
// @Api(Description="后台管理获取模板")
export class GetEventTemplatesSourceReq implements IReturn<GetEventTemplatesSourceRes>
{
    /**
    * EventClass
    */
    // @ApiMember(DataType="string", Description="EventClass", IsRequired=true, Name="EventClass", ParameterType="path")
    public eventClass?: string;

    public constructor(init?: Partial<GetEventTemplatesSourceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventTemplatesSourceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventTemplatesSourceRes(); }
}

/**
* Release模板
*/
// @Route("/class/event/templaterelease", "POST, OPTIONS")
// @Api(Description="Release模板")
export class ReleaseEventTemplateReq implements IReturn<CommonReturn>
{
    public eventClass?: string;

    public constructor(init?: Partial<ReleaseEventTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReleaseEventTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新模板目录
*/
// @Route("/class/event/templatecatalogs", "POST, OPTIONS")
// @Api(Description="更新模板目录")
export class UpdateEventTemplateCatalogReq implements IReturn<CommonReturn>
{
    public eventClass?: string;
    public catalogs?: string[];

    public constructor(init?: Partial<UpdateEventTemplateCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEventTemplateCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加通知模板
*/
// @Route("/class/event/templates/notify", "POST, OPTIONS")
// @Api(Description="添加通知模板")
export class AddEventNotifyTemplatesReq implements IReturn<CommonReturn>
{
    public title?: string;
    public content?: string;
    public imageUri?: string;
    public tags?: string[];
    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<AddEventNotifyTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventNotifyTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加成长任务模板
*/
// @Route("/class/event/templates/task", "POST, OPTIONS")
// @Api(Description="添加成长任务模板")
export class AddEventTaskTemplatesReq implements IReturn<CommonReturn>
{
    public title?: string;
    public content?: string;
    public imageUri?: string;
    public tags?: string[];
    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<AddEventTaskTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventTaskTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加投票类模板
*/
// @Route("/class/event/templates/vote", "POST, OPTIONS")
// @Api(Description="添加投票类模板")
export class AddEventVoteTemplatesReq implements IReturn<CommonReturn>
{
    public title?: string;
    public content?: string;
    public imageUri?: string;
    public tags?: string[];
    public questions?: VoteItemSerial[];

    public constructor(init?: Partial<AddEventVoteTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventVoteTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加打卡类模板
*/
// @Route("/class/event/templates/clockin", "POST, OPTIONS")
// @Api(Description="添加打卡类模板")
export class AddEventClockInTemplatesReq implements IReturn<CommonReturn>
{
    public title?: string;
    public content?: string;
    public imageUri?: string;
    public tags?: string[];
    public period?: number;
    public frequencyConfig?: EventClockInFrequency;
    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<AddEventClockInTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventClockInTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加模板
*/
// @Route("/class/event/templates/addevent", "POST, OPTIONS")
// @Api(Description="添加模板")
export class AddEventTemplateReq implements IReturn<CommonReturn>
{
    public eventClass?: string;
    public title?: string;
    public content?: string;
    public imageUri?: string;
    public tags?: string[];
    public shareUrl?: string;
    public attachItem?: EventAttachItem;
    public contentExs?: KeyValuePair<string,string>[];

    public constructor(init?: Partial<AddEventTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 导出教学模板为excel
*/
// @Route("/class/event/templates/exporttoexcel", "POST, OPTIONS")
// @Api(Description="导出教学模板为excel")
export class EventTemplateExportToExcelReq implements IReturn<Blob>
{
    public eventClass?: string;

    public constructor(init?: Partial<EventTemplateExportToExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventTemplateExportToExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 通过excel批量添加教学模板
*/
// @Route("/class/event/templates/addfromexcel", "POST, OPTIONS")
// @Api(Description="通过excel批量添加教学模板")
export class EventTemplateImportFromExcelReq implements IReturn<CommonReturn>
{
    public eventClass?: string;

    public constructor(init?: Partial<EventTemplateImportFromExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventTemplateImportFromExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 园长获取学期成长手册配置
*/
// @Route("/school/{SchoolId}/growbook/{TermId}/config", "GET, OPTIONS")
// @Api(Description="园长获取学期成长手册配置")
export class GetSchoolGrowBookConfigReq implements IReturn<GetSchoolGrowBookConfigRes>
{
    public schoolId?: string;
    public termId?: number;

    public constructor(init?: Partial<GetSchoolGrowBookConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolGrowBookConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolGrowBookConfigRes(); }
}

/**
* 更新学期成长手册配置
*/
// @Route("/school/{SchoolId}/growbook/{TermId}/config", "POST, OPTIONS")
// @Api(Description="更新学期成长手册配置")
export class UpdateSchoolGrowBookConfigReq implements IReturn<BaseResponse>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public schoolQRCode?: string;
    public schoolIntro?: SchoolPageExtendDTO;
    public schoolPageExtends?: SchoolPageExtendDTO[];

    public constructor(init?: Partial<UpdateSchoolGrowBookConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolGrowBookConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 教师获取班级学期成长手册汇总
*/
// @Route("/class/{ClassId}/growbooks/{TermId}", "GET, OPTIONS")
// @Api(Description="教师获取班级学期成长手册汇总")
export class GetGrowBooksReq implements IReturn<GetGrowBooksRes>
{
    public classId?: string;
    public termId?: number;

    public constructor(init?: Partial<GetGrowBooksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGrowBooksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGrowBooksRes(); }
}

/**
* 重建学生学期成长集
*/
// @Route("/kid/{KidId}/growbooks/{TermId}/rebuild", "POST, OPTIONS")
// @Api(Description="重建学生学期成长集")
export class KidGrowBookReBuildReq implements IReturn<BaseResponse>
{
    public kidId?: string;
    public termId?: number;

    public constructor(init?: Partial<KidGrowBookReBuildReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'KidGrowBookReBuildReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 园长获取学期成长手册汇总
*/
// @Route("/school/{SchoolId}/growbooks/{TermId}", "GET, OPTIONS")
// @Api(Description="园长获取学期成长手册汇总")
export class GetSchoolGrowBooksReq implements IReturn<GetSchoolGrowBooksRes>
{
    public schoolId?: string;
    public termId?: number;

    public constructor(init?: Partial<GetSchoolGrowBooksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolGrowBooksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolGrowBooksRes(); }
}

/**
* 添加学生到班级
*/
// @Route("/school/{SchoolId}/kid", "POST, OPTIONS")
// @Api(Description="添加学生到班级")
export class AddKidToClassReq implements IReturn<CommonReturn>
{
    /**
    * 学校id
    */
    // @ApiMember(DataType="string", Description="学校id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public branchId?: string;
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 学生姓名
    */
    // @ApiMember(DataType="string", Description="学生姓名", IsRequired=true, Name="Name", ParameterType="query")
    public name?: string;

    /**
    * 出生日期
    */
    // @ApiMember(DataType="string", Description="出生日期", IsRequired=true, Name="BirthDate", ParameterType="query")
    public birthDate?: string;

    public gender?: GENDERTYPE;
    public primaryContact?: ParentInfoDTO;
    public secondaryContact?: ParentInfoDTO;

    public constructor(init?: Partial<AddKidToClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidToClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 移除班级学生
*/
// @Route("/school/{SchoolId}/class/{ClassId}/kid", "DELETE, OPTIONS")
// @Api(Description="移除班级学生")
export class KidLeaveClassReq implements IReturn<BaseResponse>
{
    /**
    * 学校id
    */
    // @ApiMember(DataType="string", Description="学校id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", Name="KidId", ParameterType="query")
    public kidId?: string;

    public studentStatus?: STUDENT_STATUS;

    public constructor(init?: Partial<KidLeaveClassReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'KidLeaveClassReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 移除学校学生
*/
// @Route("/school/{SchoolId}/kid/{KidId}", "DELETE, OPTIONS")
// @Api(Description="移除学校学生")
export class KidLeaveSchoolReq implements IReturn<BaseResponse>
{
    /**
    * 学校id
    */
    // @ApiMember(DataType="string", Description="学校id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", Name="KidId", ParameterType="query")
    public kidId?: string;

    public studentStatus?: STUDENT_STATUS;

    public constructor(init?: Partial<KidLeaveSchoolReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'KidLeaveSchoolReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 修改学生信息
*/
// @Route("/school/{SchoolId}/kid/update", "POST, OPTIONS")
// @Api(Description="修改学生信息")
export class UpdateKidReq implements IReturn<BaseResponse>
{
    /**
    * 学校id
    */
    // @ApiMember(DataType="string", Description="学校id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * 学生姓名
    */
    // @ApiMember(DataType="string", Description="学生姓名", IsRequired=true, Name="Name", ParameterType="query")
    public name?: string;

    /**
    * 出生日期
    */
    // @ApiMember(DataType="string", Description="出生日期", IsRequired=true, Name="BirthDate", ParameterType="query")
    public birthDate?: string;

    public gender?: GENDERTYPE;
    public primaryContact?: ParentInfoDTO;
    public secondaryContact?: ParentInfoDTO;

    public constructor(init?: Partial<UpdateKidReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 发布菜单
*/
// @Route("/school/{SchoolId}/dailymenu/{MenuId}/publish", "POST, OPTIONS")
// @Api(Description="发布菜单")
export class PublishDailyMenuReq implements IReturn<CommonReturn>
{
    public schoolId?: string;
    public menuId?: string;
    public classId?: string[];

    public constructor(init?: Partial<PublishDailyMenuReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PublishDailyMenuReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 发布评估结果
*/
// @Route("/class/{ClassId}/evaluate/{EvaluateId}/publish", "POST, OPTIONS")
// @Api(Description="发布评估结果")
export class PublishEvaluateReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public evaluateId?: string;

    public constructor(init?: Partial<PublishEvaluateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PublishEvaluateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 删除评估
*/
// @Route("/class/{ClassId}/evaluate/{EvaluateId}/delete", "DELETE, OPTIONS")
// @Api(Description="删除评估")
export class DeleteEvaluateReq implements IReturn<BaseResponse>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    public evaluateId?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<DeleteEvaluateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEvaluateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新班级名称
*/
// @Route("/class/{ClassId}/changename", "POST, OPTIONS")
// @Api(Description="更新班级名称")
export class UpdateClassNameReq implements IReturn<UpdateClassNameRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 班级编号
    */
    // @ApiMember(DataType="string", Description="班级编号", IsRequired=true, Name="ClassName", ParameterType="query")
    public className?: string;

    /**
    * 自定义班级名称
    */
    // @ApiMember(DataType="string", Description="自定义班级名称", Name="ClassNameAlias", ParameterType="query")
    public classNameAlias?: string;

    public constructor(init?: Partial<UpdateClassNameReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateClassNameReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateClassNameRes(); }
}

/**
* 更新园所介绍
*/
// @Route("/school/{SchoolId}/updatedesc", "POST, OPTIONS")
// @Api(Description="更新园所介绍")
export class UpdateSchoolDescReq implements IReturn<CommonReturn>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 园所介绍
    */
    // @ApiMember(DataType="string", Description="园所介绍", IsRequired=true, Name="Desc", ParameterType="query")
    public desc?: string;

    /**
    * 联系电话
    */
    // @ApiMember(DataType="string", Description="联系电话", IsRequired=true, Name="PhoneNumber", ParameterType="query")
    public phoneNumber?: string;

    /**
    * 园所地址
    */
    // @ApiMember(DataType="string", Description="园所地址", IsRequired=true, Name="Address", ParameterType="query")
    public address?: string;

    public location?: GeoCoordinates;

    public constructor(init?: Partial<UpdateSchoolDescReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolDescReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 导出添加学生到班级模板为excel
*/
// @Route("/class/{ClassId}/addkidbyexcel", "GET, OPTIONS")
// @Api(Description="导出添加学生到班级模板为excel")
export class ExportAddKidToClassTemplatesReq implements IReturn<Blob>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    public constructor(init?: Partial<ExportAddKidToClassTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportAddKidToClassTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 导出添加线索模板为excel
*/
// @Route("/class/{ClassId}/addsalesleadbyexcel", "GET, OPTIONS")
// @Api(Description="导出添加线索模板为excel")
export class ExportAddSalesLeadTemplatesReq implements IReturn<Blob>
{
    public schoolId?: string;

    public constructor(init?: Partial<ExportAddSalesLeadTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportAddSalesLeadTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 开放评估项目
*/
// @Route("/class/{ClassId}/evaluate/{EvaluateId}/open", "POST, OPTIONS")
// @Api(Description="开放评估项目")
export class OpenEvaluateReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public evaluateId?: string;

    public constructor(init?: Partial<OpenEvaluateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OpenEvaluateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改评估项目
*/
// @Route("/class/{ClassId}/evaluate/{EvaluateId}", "POST, OPTIONS")
// @Api(Description="修改评估项目")
export class UpdateEvaluateReq implements IReturn<CommonReturn>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 评估id
    */
    // @ApiMember(DataType="string", Description="评估id", IsRequired=true, Name="EvaluateId", ParameterType="path")
    public evaluateId?: string;

    /**
    * 评估类型
    */
    // @ApiMember(DataType="string", Description="评估类型", IsRequired=true, Name="EvaluateType", ParameterType="query")
    public evaluateType: EVALUATETYPE;

    /**
    * 学期类型
    */
    // @ApiMember(DataType="string", Description="学期类型", IsRequired=true, Name="SemesterType", ParameterType="query")
    public semesterType: SEMESTERTYPE;

    /**
    * 结束时间
    */
    // @ApiMember(DataType="string", Description="结束时间", Name="FinishTime", ParameterType="query")
    public finishTime?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 所选择的评估项列表
    */
    // @ApiMember(DataType="array", Description="所选择的评估项列表", IsRequired=true, Name="EvaluateIds", ParameterType="query")
    public evaluateIds: string[];

    public targetKids?: string[];

    public constructor(init?: Partial<UpdateEvaluateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEvaluateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 按KidId批量提交评估项目
*/
// @Route("/school/{SchoolId}/student/{StudentId}/addtoclass", "POST, OPTIONS")
// @Api(Description="按KidId批量提交评估项目")
export class AddStudentToCLassesReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public studentId?: string;
    public classIds?: string[];

    public constructor(init?: Partial<AddStudentToCLassesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddStudentToCLassesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 按KidId批量提交评估项目
*/
// @Route("/class/{ClassId}/evaluate/{EvaluateId}/fill/{KidId}", "POST, OPTIONS")
// @Api(Description="按KidId批量提交评估项目")
export class FillEvaluateByKidReq implements IReturn<FillEvaluateInitRes>
{
    public classId?: string;
    public evaluateId?: string;
    public kidId?: string;
    public evaluateItemScores?: EvaluateItemWithScoreDTO[];

    public constructor(init?: Partial<FillEvaluateByKidReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FillEvaluateByKidReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FillEvaluateInitRes(); }
}

/**
* 获取教培栏目
*/
// @Route("/teacherstudy/teacherstudy/partner/catalogs/", "GET, OPTIONS")
// @Api(Description="获取教培栏目")
export class GetSchoolPartnerTeacherStudyCatalogsReq implements IReturn<GetSchoolPartnerTeacherStudyCatalogsRes>
{
    public schoolPartnerInfoId?: string;

    public constructor(init?: Partial<GetSchoolPartnerTeacherStudyCatalogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerTeacherStudyCatalogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerTeacherStudyCatalogsRes(); }
}

/**
* 更新教培栏目
*/
// @Route("/teacherstudy/teacherstudy/partner/catalogs/", "POST")
// @Api(Description="更新教培栏目")
export class AddSchoolPartnerTeacherStudyCatalogReq implements IReturn<BaseResponse>
{
    public schoolPartnerInfoId?: string;
    public data?: SchoolPartnerTeacherStudyCatalogDTO;

    public constructor(init?: Partial<AddSchoolPartnerTeacherStudyCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolPartnerTeacherStudyCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新教培内容
*/
// @Route("/teacherstudy/teacherstudy/partner/catalogs/", "POST")
// @Api(Description="更新教培内容")
export class AddSchoolPartnerTeacherStudyItemReq implements IReturn<BaseResponse>
{
    public schoolPartnerInfoId?: string;
    public data?: SchoolPartnerTeacherStudyItemDTO;

    public constructor(init?: Partial<AddSchoolPartnerTeacherStudyItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolPartnerTeacherStudyItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 分页获取教培内容
*/
// @Route("/teacherstudy/teacherstudy/partner/items", "POST")
// @Api(Description="分页获取教培内容")
export class GetSchoolPartnerTeacherStudyItemsReq implements IReturn<GetSchoolPartnerTeacherStudyItemsRes>
{
    public schoolPartnerInfoId?: string;
    public schoolId?: string;
    public studyId?: string;
    public teacherStudyCatalogId?: string;
    public title?: string;
    public content?: string;
    public publishState?: EVENTPUBLISHSTATE;
    public isDelete?: boolean;
    public createBy?: number;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;
    public onlyShowData?: boolean;

    public constructor(init?: Partial<GetSchoolPartnerTeacherStudyItemsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolPartnerTeacherStudyItemsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolPartnerTeacherStudyItemsRes(); }
}

/**
* 获取教培栏目
*/
// @Route("/teacherstudy/teacherstudy/catalogs/{SchoolId}", "GET, OPTIONS")
// @Api(Description="获取教培栏目")
export class GetTeacherStudyCatalogsReq implements IReturn<GetTeacherStudyCatalogsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetTeacherStudyCatalogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherStudyCatalogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherStudyCatalogsRes(); }
}

/**
* 新增或修改教培栏目
*/
// @Route("/teacherstudy/teacherstudy/catalog/add", "POST, OPTIONS")
// @Api(Description="新增或修改教培栏目")
export class AddOrReplaceTeacherStudyCatalogReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public catalogId?: string;
    public name?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<AddOrReplaceTeacherStudyCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddOrReplaceTeacherStudyCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除教培栏目
*/
// @Route("/teacherstudy/teacherstudy/catalog/delete", "POST, OPTIONS")
// @Api(Description="删除教培栏目")
export class UpdateTeacherStudyCatalogDeleteReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public catalogId?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<UpdateTeacherStudyCatalogDeleteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeacherStudyCatalogDeleteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取教培内容的feed
*/
// @Route("/teacherstudy/teacherstudy/feed", "POST, OPTIONS")
// @Api(Description="获取教培内容的feed")
export class GetTeacherStudyItemFeedsReq implements IReturn<GetTeacherStudyItemFeedsRes>
{
    public schoolId?: string;
    public branchIds?: string[];

    public constructor(init?: Partial<GetTeacherStudyItemFeedsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherStudyItemFeedsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherStudyItemFeedsRes(); }
}

/**
* 根据id获取教培内容
*/
// @Route("/teacherstudy/teacherstudy/item/ids", "POST, OPTIONS")
// @Api(Description="根据id获取教培内容")
export class GetTeacherStudyItemByIdsReq implements IReturn<GetTeacherStudyItemsRes>
{
    public schoolId?: string;
    public studyIds?: string[];

    public constructor(init?: Partial<GetTeacherStudyItemByIdsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherStudyItemByIdsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherStudyItemsRes(); }
}

/**
* 分页获取教培内容
*/
// @Route("/teacherstudy/teacherstudy/items", "POST, OPTIONS")
// @Api(Description="分页获取教培内容")
export class GetTeacherStudyItemsReq implements IReturn<GetTeacherStudyItemsRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public studyId?: string;
    public teacherStudyCatalogId?: string;
    public title?: string;
    public content?: string;
    public publishState?: EVENTPUBLISHSTATE;
    public isDelete?: boolean;
    public deleteByTeacherId?: string;
    public createByTeacherId?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;
    public onlyShowData?: boolean;

    public constructor(init?: Partial<GetTeacherStudyItemsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherStudyItemsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherStudyItemsRes(); }
}

/**
* 添加教培内容
*/
// @Route("/teacherstudy/teacherstudy/item/add", "POST, OPTIONS")
// @Api(Description="添加教培内容")
export class AddTeacherStudyItemReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public branchIds?: string[];
    public teacherStudyCatalogId?: string;
    public title?: string;
    public content?: string;
    public attachment?: EventAttachDTO;
    public publishState?: EVENTPUBLISHSTATE;
    public openOn?: string;
    public closeOn?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<AddTeacherStudyItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddTeacherStudyItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新教培内容
*/
// @Route("/teacherstudy/teacherstudy/item/update", "POST, OPTIONS")
// @Api(Description="更新教培内容")
export class UpdateTeacherStudyItemReq implements IReturn<BaseResponse>
{
    public studyId?: string;
    public schoolId?: string;
    public branchIds?: string[];
    public teacherStudyCatalogId?: string;
    public title?: string;
    public content?: string;
    public attachment?: EventAttachDTO;
    public openOn?: string;
    public closeOn?: string;
    public orderIndex?: number;

    public constructor(init?: Partial<UpdateTeacherStudyItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeacherStudyItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新教培内容发布状态
*/
// @Route("/teacherstudy/teacherstudy/item/publish", "POST, OPTIONS")
// @Api(Description="更新教培内容发布状态")
export class UpdateTeacherStudyItemPublishStateReq implements IReturn<BaseResponse>
{
    public studyId?: string;
    public schoolId?: string;
    public publishState?: EVENTPUBLISHSTATE;

    public constructor(init?: Partial<UpdateTeacherStudyItemPublishStateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeacherStudyItemPublishStateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除教培内容
*/
// @Route("/teacherstudy/teacherstudy/item/delete", "POST, OPTIONS")
// @Api(Description="删除教培内容")
export class UpdateTeacherStudyItemDeleteReq implements IReturn<BaseResponse>
{
    public studyId?: string;
    public schoolId?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<UpdateTeacherStudyItemDeleteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeacherStudyItemDeleteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 点赞教培内容
*/
// @Route("/teacherstudy/teacherstudy/item/like", "POST, OPTIONS")
// @Api(Description="点赞教培内容")
export class UpdateTeacherStudyItemLikeReq implements IReturn<BaseResponse>
{
    public studyId?: string;
    public schoolId?: string;
    public isLike?: boolean;

    public constructor(init?: Partial<UpdateTeacherStudyItemLikeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeacherStudyItemLikeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 已阅教培内容
*/
// @Route("/teacherstudy/teacherstudy/item/view", "POST, OPTIONS")
// @Api(Description="已阅教培内容")
export class AddTeacherStudyItemViewReq implements IReturn<BaseResponse>
{
    public studyId?: string;
    public schoolId?: string;

    public constructor(init?: Partial<AddTeacherStudyItemViewReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddTeacherStudyItemViewReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取教培内容评论
*/
// @Route("/teacherstudy/teacherstudy/comment/{SchoolId}/{StudyId}", "GET, OPTIONS")
// @Api(Description="获取教培内容评论")
export class GetTeacherStudyCommentItemsReq implements IReturn<GetTeacherStudyCommentItemsRes>
{
    public studyId?: string;
    public schoolId?: string;

    public constructor(init?: Partial<GetTeacherStudyCommentItemsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherStudyCommentItemsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherStudyCommentItemsRes(); }
}

/**
* 添加教培内容评论
*/
// @Route("/teacherstudy/teacherstudy/comment/add", "POST, OPTIONS")
// @Api(Description="添加教培内容评论")
export class AddTeacherStudyItemCommentReq implements IReturn<BaseResponse>
{
    public studyId?: string;
    public schoolId?: string;
    public content?: string;
    public replyToItemId?: string;

    public constructor(init?: Partial<AddTeacherStudyItemCommentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddTeacherStudyItemCommentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除教培内容评论
*/
// @Route("/teacherstudy/teacherstudy/comment/delete", "POST, OPTIONS")
// @Api(Description="删除教培内容评论")
export class UpdateTeacherStudyItemCommentDeleteReq implements IReturn<BaseResponse>
{
    public studyId?: string;
    public schoolId?: string;
    public commentId?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<UpdateTeacherStudyItemCommentDeleteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeacherStudyItemCommentDeleteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class EventTemplateVoteItem extends EventTemplateItem
{
    public questions?: VoteItemSerial[];

    public constructor(init?: Partial<EventTemplateVoteItem>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'EventTemplateVoteItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class EventTemplateClockInItem extends EventTemplateItem
{
    public frequencyConfig?: EventClockInFrequency;
    public period?: number;

    public constructor(init?: Partial<EventTemplateClockInItem>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'EventTemplateClockInItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class VoteItemItemDTO
{
    public id?: string;
    public itemContent?: string;
    public itemAttachment?: EventAttachItem;
    public selectedInfos?: VoteItemSelectedUserDTO[];

    public constructor(init?: Partial<VoteItemItemDTO>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VoteItemItemDTO'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AttendanceTerminalDumpDBReq
{
    public serial?: string;
    public dumppath?: string;

    public constructor(init?: Partial<AttendanceTerminalDumpDBReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceTerminalDumpDBReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AttendanceTerminalDumpLogReq
{
    public serial?: string;
    public dumppath?: string;

    public constructor(init?: Partial<AttendanceTerminalDumpLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceTerminalDumpLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AttendanceTerminalUpgradeReq
{
    public versionCode?: number;
    public versionName?: string;
    public downloadUrl?: string;
    public md5Hash?: string;
    public packageSize?: string;

    public constructor(init?: Partial<AttendanceTerminalUpgradeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceTerminalUpgradeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AttendanceShowQrcodeSucess
{
    public serial?: string;
    public name?: string;
    public logoUrl?: string;
    public isTeacher?: boolean;
    public showMsg?: string;
    public uuid?: string;
    public takePhoto?: boolean;
    public createOn?: string;

    public constructor(init?: Partial<AttendanceShowQrcodeSucess>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceShowQrcodeSucess'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ClientAttendanceTeacher
{
    public teacherId?: string;
    public schoolId?: string;
    public fullName?: string;
    public logoUrl?: string;
    public cardNumber?: string;
    public enableFace?: number;
    public timestamp?: number;

    public constructor(init?: Partial<ClientAttendanceTeacher>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClientAttendanceTeacher'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ClientOfflineLogs
{
    public logId?: number;
    public card?: string;
    public uUID?: string;
    public checkTimestamp?: number;

    public constructor(init?: Partial<ClientOfflineLogs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClientOfflineLogs'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ClassEventClockInItem_ExtraFields
{
    public canSeeOther?: boolean;
    public beginTime?: string;
    public endTime?: string;
    public state?: EVENTCLOCKINSTATE;
    public tags?: string[];
    public targetTotalNum?: number;
    public todayGroupId?: string;
    public targetTodayNum?: number;
    public times?: number;
    public frequencyConfig?: EventClockInFrequency;

    public constructor(init?: Partial<ClassEventClockInItem_ExtraFields>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClassEventClockInItem_ExtraFields'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UnknowTypes
{
    public data?: AttachVideoItem;
    public data2?: EventAttachItem;
    public data3?: AttachLinkItem;
    public data4?: AttachImageItem[];
    public data5?: ClassEventClockInItem_ExtraFields;
    public data6?: AttachFileItem;
    public data7?: TeacherStudyItemDTO_ExtraFields;

    public constructor(init?: Partial<UnknowTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnknowTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 获取登录二维码
*/
// @Route("/tv/getloginqrcode", "POST, OPTIONS")
// @Api(Description="获取登录二维码")
export class TvGetLoginCodeReq implements IReturn<TvGetLoginCodeRes>
{
    public serialNo?: string;

    public constructor(init?: Partial<TvGetLoginCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TvGetLoginCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TvGetLoginCodeRes(); }
}

/**
* 刷新登录二维码的状态
*/
// @Route("/tv/getloginqrcodestatus", "POST, OPTIONS")
// @Api(Description="刷新登录二维码的状态")
export class TvCheckLoginTicketReq implements IReturn<TvCheckLoginTicketRes>
{
    public serialNo?: string;
    public ticket?: string;

    public constructor(init?: Partial<TvCheckLoginTicketReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TvCheckLoginTicketReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TvCheckLoginTicketRes(); }
}

/**
* 考勤管理员在移动端授权登录
*/
// @Route("/tv/loginbymanager", "POST, OPTIONS")
// @Api(Description="考勤管理员在移动端授权登录")
export class TvDeviceLoginByManageReq implements IReturn<TvDeviceLoginByManageRes>
{
    public schoolId?: string;
    public serialNo?: string;
    public ticket?: string;
    public act?: number;

    public constructor(init?: Partial<TvDeviceLoginByManageReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TvDeviceLoginByManageReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TvDeviceLoginByManageRes(); }
}

/**
* TV获取显示信息
*/
// @Route("/tv/device/{Serial}", "GET, OPTIONS")
// @Api(Description="TV获取显示信息")
export class GetSchoolTVDeviceReq implements IReturn<GetSchoolTVDeviceRes>
{
    public serial?: string;

    public constructor(init?: Partial<GetSchoolTVDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolTVDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolTVDeviceRes(); }
}

/**
* TV获取出勤信息
*/
// @Route("/tv/attendance/{SchoolId}", "GET, OPTIONS")
// @Api(Description="TV获取出勤信息")
export class GetSchoolKidAttendanceInfoReq implements IReturn<GetSchoolKidAttendanceInfoRes>
{
    public serial?: string;
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolKidAttendanceInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidAttendanceInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidAttendanceInfoRes(); }
}

/**
* 新增学生请假/补卡
*/
// @Route("/leaverequest/kidcreate", "POST, OPTIONS")
// @Api(Description="新增学生请假/补卡")
export class CreateKidLeaveRequestReq implements IReturn<BaseResponse>
{
    public type?: LEAVE_REQUEST_TYPE;
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public subject?: string;
    public desc?: string;
    public timeBegin?: string;
    public timeEnd?: string;
    public leaveHours?: string;
    public auditorId?: string;
    public byTeacher?: boolean;

    public constructor(init?: Partial<CreateKidLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateKidLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 取消学生请假
*/
// @Route("/leaverequest/kidcancel", "POST, OPTIONS")
// @Api(Description="取消学生请假")
export class CancelKidLeaveRequestReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public leaveRequestId?: string;
    public kidId?: string;
    public byTeacher?: boolean;

    public constructor(init?: Partial<CancelKidLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelKidLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 修改学生请假
*/
// @Route("/leaverequest/kidupdate", "POST, OPTIONS")
// @Api(Description="修改学生请假")
export class UpdateKidLeaveRequestReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public leaveRequestId?: string;
    public subject?: string;
    public desc?: string;
    public timeBegin?: string;
    public timeEnd?: string;
    public leaveHours?: string;
    public auditorId?: string;
    public byTeacher?: boolean;

    public constructor(init?: Partial<UpdateKidLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 学生获取请假配置
*/
// @Route("/leaverequest/kidconfig", "GET, OPTIONS")
// @Api(Description="学生获取请假配置")
export class GetKidLeaveRequestConfigReq implements IReturn<LeaveRequestConfigRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetKidLeaveRequestConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidLeaveRequestConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LeaveRequestConfigRes(); }
}

/**
* 学生获取请假列表
*/
// @Route("/leaverequest/kidrequests", "GET, OPTIONS")
// @Api(Description="学生获取请假列表")
export class GetKidMyLeaveRequestReq implements IReturn<GetKidMyLeaveRequestRes>
{
    public kidId?: string;
    public schoolId?: string;

    public constructor(init?: Partial<GetKidMyLeaveRequestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidMyLeaveRequestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidMyLeaveRequestRes(); }
}

/**
* 学生二维码考勤
*/
// @Route("/attendance/attendanceparentfromqrcode", "POST, OPTIONS")
// @Api(Description="学生二维码考勤")
export class AttendanceParentFromQrcodeReq implements IReturn<AttendanceFromQrcodeRes>
{
    public serial?: string;
    public kidId?: string;
    public otp?: string;
    public uuid?: string;

    public constructor(init?: Partial<AttendanceParentFromQrcodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceParentFromQrcodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AttendanceFromQrcodeRes(); }
}

/**
* 绑卡到学生
*/
// @Route("/attendance/bindtokid", "POST, OPTIONS")
// @Api(Description="绑卡到学生")
export class AddKidAttendanceBindKidReq implements IReturn<AddAttendanceBindRes>
{
    public schoolId?: string;
    public kidId?: string;
    public cardSerialNo?: string;
    public isForStudent?: boolean;

    public constructor(init?: Partial<AddKidAttendanceBindKidReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidAttendanceBindKidReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddAttendanceBindRes(); }
}

/**
* 更新绑卡到学生
*/
// @Route("/attendance/updatebindtokid", "POST, OPTIONS")
// @Api(Description="更新绑卡到学生")
export class UpdateKidAttendanceBindKidReq implements IReturn<AddAttendanceBindRes>
{
    public schoolId?: string;
    public kidId?: string;
    public newCardSerialNo?: string;
    public isForStudent?: boolean;

    public constructor(init?: Partial<UpdateKidAttendanceBindKidReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidAttendanceBindKidReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddAttendanceBindRes(); }
}

/**
* 获取学生的绑卡信息
*/
// @Route("/attendance/kidbindcards", "GET, OPTIONS")
// @Api(Description="获取学生的绑卡信息")
export class GetKidAttendanceBindsReq implements IReturn<GetKidAttendanceBindsRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetKidAttendanceBindsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidAttendanceBindsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidAttendanceBindsRes(); }
}

/**
* 家长获取代接送列表
*/
// @Route("/attendance/school/{SchoolId}/parent/commissionpickups", "GET, OPTIONS")
// @Api(Description="家长获取代接送列表")
export class GetCommissionPickupByParentReq implements IReturn<GetCommissionPickupRes>
{
    public schoolId?: string;
    public studentId?: string;
    public lastId?: string;

    public constructor(init?: Partial<GetCommissionPickupByParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCommissionPickupByParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCommissionPickupRes(); }
}

/**
* 家长确认代接送
*/
// @Route("/attendance/school/{SchoolId}/parent/confirmcommissionpickup", "POST, OPTIONS")
// @Api(Description="家长确认代接送")
export class ConfirmCommissionPickupReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public studentId?: string;
    public pickupId?: string;

    public constructor(init?: Partial<ConfirmCommissionPickupReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConfirmCommissionPickupReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 首次登陆完善信息
*/
// @Route("/kid/{KidId}/initdata", "POST, OPTIONS")
// @Api(Description="首次登陆完善信息")
export class InitKidDataReq implements IReturn<CommonReturn>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * 家长角色名
    */
    // @ApiMember(DataType="string", Description="家长角色名", IsRequired=true, Name="rolename", ParameterType="query")
    public rolename?: string;

    /**
    * 生日
    */
    // @ApiMember(DataType="string", Description="生日", IsRequired=true, Name="birthdate", ParameterType="query")
    public birthdate?: string;

    public constructor(init?: Partial<InitKidDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InitKidDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新孩子的兴趣爱好
*/
// @Route("/kid/{KidId}/hobby", "POST, OPTIONS")
// @Api(Description="更新孩子的兴趣爱好")
export class UpdateKidHobbyReq extends CommonRequest implements IReturn<CommonReturn>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * 兴趣爱好
    */
    // @ApiMember(DataType="string", Description="兴趣爱好", IsRequired=true, Name="Hobby", ParameterType="query")
    public hobby?: string;

    public constructor(init?: Partial<UpdateKidHobbyReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidHobbyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新孩子的生日
*/
// @Route("/kid/{KidId}/birthdate", "POST, OPTIONS")
// @Api(Description="更新孩子的生日")
export class UpdateKidBirthDateReq implements IReturn<CommonReturn>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * 生日
    */
    // @ApiMember(DataType="string", Description="生日", IsRequired=true, Name="birthdate", ParameterType="query")
    public birthdate?: string;

    public constructor(init?: Partial<UpdateKidBirthDateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidBirthDateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取家长名下所有孩子信息
*/
// @Route("/parent/my/kidlist", "GET, OPTIONS")
// @Api(Description="获取家长名下所有孩子信息")
export class GetParentKidsReq implements IReturn<GetParentKidsRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetParentKidsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParentKidsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetParentKidsRes(); }
}

/**
* 获取家长的用户信息
*/
// @Route("/parent/my/classinfo/{ClassId}", "GET, OPTIONS")
// @Api(Description="获取家长的用户信息")
export class GetParentMyClassInfoReq implements IReturn<GetParentMyClassInfoRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    public kidId?: string;

    public constructor(init?: Partial<GetParentMyClassInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParentMyClassInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetParentMyClassInfoRes(); }
}

/**
* 记录家长的登录日志
*/
// @Route("/parent/loginlog/{KidId}", "GET, OPTIONS")
// @Api(Description="记录家长的登录日志")
export class AddParentLoginLogReq implements IReturn<BaseResponse>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public constructor(init?: Partial<AddParentLoginLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddParentLoginLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取家长的用户信息
*/
// @Route("/parent/my/userinfo/{KidId}", "GET, OPTIONS")
// @Api(Description="获取家长的用户信息")
export class GetParentMyUserInfoReq implements IReturn<GetParentMyUserInfoRes>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * 极光AppKey
    */
    // @ApiMember(DataType="string", Description="极光AppKey", Name="JPushAppKey", ParameterType="query")
    public jPushAppKey?: string;

    /**
    * 极光设备id
    */
    // @ApiMember(DataType="string", Description="极光设备id", Name="JPushRegId", ParameterType="query")
    public jPushRegId?: string;

    /**
    * MobPushAppKey
    */
    // @ApiMember(DataType="string", Description="MobPushAppKey", Name="MobPushAppKey", ParameterType="query")
    public mobPushAppKey?: string;

    /**
    * MobPush设备id
    */
    // @ApiMember(DataType="string", Description="MobPush设备id", Name="MobPushRegId", ParameterType="query")
    public mobPushRegId?: string;

    public constructor(init?: Partial<GetParentMyUserInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParentMyUserInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetParentMyUserInfoRes(); }
}

/**
* 根据注册token查询班级信息
*/
// @Route("/class/reginfo/{Token}", "GET, OPTIONS")
// @Api(Description="根据注册token查询班级信息")
export class GetRegInfoFromTokenReq implements IReturn<GetRegInfoFromTokenRes>
{
    public token?: string;

    public constructor(init?: Partial<GetRegInfoFromTokenReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRegInfoFromTokenReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRegInfoFromTokenRes(); }
}

/**
* 查询自己的申请信息
*/
// @Route("/class/{ClassId}/reginfo{Token}", "GET, OPTIONS")
// @Api(Description="查询自己的申请信息")
export class GetRegInfoReq implements IReturn<GetRegInfoFromTokenRes>
{
    public classId?: string;
    public component_appid?: string;
    public authorizer_appid?: string;
    public userOpenId?: string;
    public token?: string;

    public constructor(init?: Partial<GetRegInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRegInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRegInfoFromTokenRes(); }
}

/**
* 获取注册验证码
*/
// @Route("/class/sendregparentcode", "GET, OPTIONS")
// @Api(Description="获取注册验证码")
export class GetRegMobileVerifyCodeReq implements IReturn<CommonReturn>
{
    public phoneNumber?: string;

    public constructor(init?: Partial<GetRegMobileVerifyCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRegMobileVerifyCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 家长注册激活
*/
// @Route("/class/{ClassId}/registerparent", "POST, OPTIONS")
// @Api(Description="家长注册激活")
export class RegisterParentReq implements IReturn<CommonReturn>
{
    public classId?: string;
    public roleName?: string;
    public kidName?: string;
    public birthDate?: string;
    public gender?: GENDERTYPE;
    public avatarUrl?: string;
    public authorizer_appid?: string;
    public userOpenId?: string;
    public phoneNumber?: string;
    public verifyCode?: string;
    public token?: string;
    public component_appid?: string;

    public constructor(init?: Partial<RegisterParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegisterParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取班级的信息
*/
// @Route("/parent/{KidId}/class", "GET, OPTIONS")
// @Api(Description="获取班级的信息")
export class GetParentClassesReq implements IReturn<GetParentClassesRes>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    public constructor(init?: Partial<GetParentClassesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParentClassesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetParentClassesRes(); }
}

/**
* 家长填写评语的评论
*/
// @Route("/class/{ClassId}/kidremark/{KidRemarkId}/comment", "POST, OPTIONS")
// @Api(Description="家长填写评语的评论")
export class AddKidRemarkCommentReq implements IReturn<AddKidRemarkCommentRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 评语id
    */
    // @ApiMember(DataType="string", Description="评语id", IsRequired=true, Name="KidRemarkId", ParameterType="path")
    public kidRemarkId?: string;

    /**
    * kidid
    */
    // @ApiMember(DataType="string", Description="kidid", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * 评论内容
    */
    // @ApiMember(DataType="string", Description="评论内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public constructor(init?: Partial<AddKidRemarkCommentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidRemarkCommentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddKidRemarkCommentRes(); }
}

/**
* 获取单条消息接口
*/
// @Route("/class/event/{EventId}", "GET, OPTIONS")
// @Api(Description="获取单条消息接口")
export class GetClassEventReq implements IReturn<GetClassEventRes>
{
    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    public constructor(init?: Partial<GetClassEventReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassEventReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventRes(); }
}

/**
* 家长获取单条动态中的AI检测信息
*/
// @Route("/class/event/aiinfoparent/{eventid}", "GET, OPTIONS")
// @Api(Description="家长获取单条动态中的AI检测信息")
export class GetEventAiInfoByParentReq implements IReturn<GetEventAiInfoRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventId?: string;

    public constructor(init?: Partial<GetEventAiInfoByParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventAiInfoByParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventAiInfoRes(); }
}

/**
* 搜索动态
*/
// @Route("/parent/searchevents", "GET, OPTIONS")
// @Api(Description="搜索动态")
export class SearchEventReq implements IReturn<GetMultiClassEventsForParentV2Res>
{
    /**
    * 关键字
    */
    // @ApiMember(DataType="string", Description="关键字", IsRequired=true, Name="Keyword", ParameterType="query")
    public keyword?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public constructor(init?: Partial<SearchEventReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchEventReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMultiClassEventsForParentV2Res(); }
}

/**
* 获取家长多条消息接口V2
*/
// @Route("/v2/parent/events", "POST, OPTIONS")
// @Api(Description="获取家长多条消息接口V2")
export class GetMultiClassEventsForParentV2Req implements IReturn<GetMultiClassEventsForParentV2Res>
{
    /**
    * EventId列表
    */
    // @ApiMember(DataType="array", Description="EventId列表", IsRequired=true, Name="EventIds", ParameterType="query")
    public eventIds: string[];

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    public constructor(init?: Partial<GetMultiClassEventsForParentV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMultiClassEventsForParentV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMultiClassEventsForParentV2Res(); }
}

/**
* 获取家长多条消息接口
*/
// @Route("/parent/events", "POST, OPTIONS")
// @Api(Description="获取家长多条消息接口")
export class GetMultiClassEventsForParentReq implements IReturn<GetMultiClassEventsForParentRes>
{
    /**
    * EventId列表
    */
    // @ApiMember(DataType="array", Description="EventId列表", IsRequired=true, Name="EventIds", ParameterType="query")
    public eventIds: string[];

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    public constructor(init?: Partial<GetMultiClassEventsForParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMultiClassEventsForParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMultiClassEventsForParentRes(); }
}

/**
* 获取家长动态feed列表
*/
// @Route("/feeds/parentevent", "GET, OPTIONS")
// @Api(Description="获取家长动态feed列表")
export class GetClassEventsFeedByParentReq implements IReturn<GetClassEventsFeedRes>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    public constructor(init?: Partial<GetClassEventsFeedByParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassEventsFeedByParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventsFeedRes(); }
}

/**
* 更新家庭时光
*/
// @Route("/class/event/hometimes/{EventId}", "PUT, OPTIONS")
// @Api(Description="更新家庭时光")
export class UpdateEventHomeTimeReq implements IReturn<UpdateEventCommonRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<UpdateEventHomeTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEventHomeTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEventCommonRes(); }
}

/**
* 发布家庭时光
*/
// @Route("/class/event/hometimes", "POST, OPTIONS")
// @Api(Description="发布家庭时光")
export class AddEventHomeTimeReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * 班级Id,如果为空则为全园广播消息
    */
    // @ApiMember(DataType="string", Description="班级Id,如果为空则为全园广播消息", Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    /**
    * 发布时间
    */
    // @ApiMember(DataType="string", Description="发布时间", Name="PublishTime", ParameterType="query")
    public publishTime?: string;

    public attachItem?: EventAttachItem;
    /**
    * 可见范围
    */
    // @ApiMember(DataType="string", Description="可见范围", IsRequired=true, Name="Scope", ParameterType="query")
    public scope: EVENT_HOMETIME_SCOPE;

    public constructor(init?: Partial<AddEventHomeTimeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventHomeTimeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 投票消息家长投票
*/
// @Route("/class/event/vote/{eventid}", "POST, OPTIONS")
// @Api(Description="投票消息家长投票")
export class AnswerEventVoteReq implements IReturn<AnswerEventVoteRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    public userVoteAnswer?: { [index: string]: string; };
    public userAttachAnswer?: { [index: string]: EventAttachDTO; };

    public constructor(init?: Partial<AnswerEventVoteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AnswerEventVoteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnswerEventVoteRes(); }
}

export class AddEventViewLogReq
{
    public userId?: number;
    public kidId?: string;
    public eventId?: string;
    public userIPAddr?: string;

    public constructor(init?: Partial<AddEventViewLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventViewLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 添加分享类消息访问日志
*/
// @Route("/class/event/share/{eventid}/viewlog", "POST, OPTIONS")
// @Api(Description="添加分享类消息访问日志")
export class AddEventShareViewLogReq implements IReturn<CommonReturn>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid?: string;

    public kidId?: string;

    public constructor(init?: Partial<AddEventShareViewLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventShareViewLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 通知消息家长确认已阅
*/
// @Route("/class/event/notify/{eventid}/feedback", "POST, OPTIONS")
// @Api(Description="通知消息家长确认已阅")
export class AddEventNotifyFeedbackReq implements IReturn<AddEventNotifyFeedbackRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid?: string;

    public kidId?: string;
    public status?: NOTIFY_FEEDBACK_STATUS;

    public constructor(init?: Partial<AddEventNotifyFeedbackReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventNotifyFeedbackReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddEventNotifyFeedbackRes(); }
}

/**
* 家长在园时光点赞
*/
// @Route("/class/event/kgtime/{EventId}/plike", "POST, OPTIONS")
// @Api(Description="家长在园时光点赞")
export class AddEventKGTimeLikeParentReq implements IReturn<UpdateEventKGTimeRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * 是点赞还是取消点赞
    */
    // @ApiMember(DataType="boolean", Description="是点赞还是取消点赞", IsRequired=true, Name="IsLike", ParameterType="query")
    public isLike: boolean;

    public constructor(init?: Partial<AddEventKGTimeLikeParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventKGTimeLikeParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEventKGTimeRes(); }
}

/**
* 家长家庭时光点赞
*/
// @Route("/class/event/hometime/{EventId}/plike", "POST, OPTIONS")
// @Api(Description="家长家庭时光点赞")
export class AddEventHomeTimeLikeParentReq implements IReturn<UpdateEventHomeTimeRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    public kidId?: string;
    /**
    * 是点赞还是取消点赞
    */
    // @ApiMember(DataType="boolean", Description="是点赞还是取消点赞", IsRequired=true, Name="IsLike", ParameterType="query")
    public isLike: boolean;

    public constructor(init?: Partial<AddEventHomeTimeLikeParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventHomeTimeLikeParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEventHomeTimeRes(); }
}

/**
* 家长在园时光添加评论
*/
// @Route("/class/event/kgtime/{EventId}/pcomment", "POST, OPTIONS")
// @Api(Description="家长在园时光添加评论")
export class AddEventKGTimeCommentParentReq implements IReturn<UpdateEventKGTimeRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * 回复哪个用户id，非回复留空
    */
    // @ApiMember(DataType="string", Description="回复哪个用户id，非回复留空", IsRequired=true, Name="ReplyTo", ParameterType="query")
    public replyTo?: string;

    /**
    * 是否是语音回复
    */
    // @ApiMember(DataType="boolean", Description="是否是语音回复", IsRequired=true, Name="IsAudio", ParameterType="query")
    public isAudio: boolean;

    /**
    * 文字回复内容
    */
    // @ApiMember(DataType="string", Description="文字回复内容", Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<AddEventKGTimeCommentParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventKGTimeCommentParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEventKGTimeRes(); }
}

/**
* 添加学生的其他家人账号
*/
// @Route("/parent/kidentity/{KidEntityId}/addparent", "POST, OPTIONS")
// @Api(Description="添加学生的其他家人账号")
export class AddKidOtherParentReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    /**
    * 小孩id
    */
    // @ApiMember(DataType="string", Description="小孩id", IsRequired=true, Name="KidEntityId", ParameterType="path")
    public kidEntityId?: string;

    /**
    * 家人角色名
    */
    // @ApiMember(DataType="string", Description="家人角色名", IsRequired=true, Name="RoleName", ParameterType="query")
    public roleName?: string;

    /**
    * 家人手机号
    */
    // @ApiMember(DataType="string", Description="家人手机号", IsRequired=true, Name="PhoneNumber", ParameterType="query")
    public phoneNumber?: string;

    public constructor(init?: Partial<AddKidOtherParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidOtherParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学生的所有家人账号
*/
// @Route("/parent/kidentity/{KidEntityId}/parents", "GET, OPTIONS")
// @Api(Description="获取学生的所有家人账号")
export class GetKidParentsReq implements IReturn<GetKidParentsRes>
{
    /**
    * 小孩id
    */
    // @ApiMember(DataType="string", Description="小孩id", IsRequired=true, Name="KidEntityId", ParameterType="path")
    public kidEntityId?: string;

    public constructor(init?: Partial<GetKidParentsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidParentsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidParentsRes(); }
}

/**
* 删除学生的其他家人账号
*/
// @Route("/parent/kidentity/{KidEntityId}/parent", "DELETE, OPTIONS")
// @Api(Description="删除学生的其他家人账号")
export class DeleteKidOtherParentReq implements IReturn<BaseResponse>
{
    /**
    * 小孩id
    */
    // @ApiMember(DataType="string", Description="小孩id", IsRequired=true, Name="KidEntityId", ParameterType="path")
    public kidEntityId?: string;

    /**
    * 家人UserId
    */
    // @ApiMember(DataType="integer", Description="家人UserId", IsRequired=true, Name="UserId", ParameterType="query")
    public userId: number;

    public constructor(init?: Partial<DeleteKidOtherParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteKidOtherParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取用户分享的成长任务数据链接
*/
// @Route("/eventtask/sharelink/{KidId}/{TaskId}/{TaskItemId}", "POST, OPTIONS")
// @Api(Description="获取用户分享的成长任务数据链接")
export class GetKidEventTaskShareLinkReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * TaskId
    */
    // @ApiMember(DataType="string", Description="TaskId", IsRequired=true, Name="TaskId", ParameterType="path")
    public taskId?: string;

    /**
    * TaskItemId
    */
    // @ApiMember(DataType="string", Description="TaskItemId", IsRequired=true, Name="TaskItemId", ParameterType="path")
    public taskItemId?: string;

    public constructor(init?: Partial<GetKidEventTaskShareLinkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidEventTaskShareLinkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取用户分享的短链
*/
// @Route("/event/tinysharelink/{EventId}/{KidId}", "GET, OPTIONS")
// @Api(Description="获取用户分享的短链")
export class GetKidEventTinyShareLinkReq implements IReturn<GetKidEventTinyShareLinkRes>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * ItemId
    */
    // @ApiMember(DataType="string", Description="ItemId", Name="ItemId", ParameterType="query")
    public itemId?: string;

    public constructor(init?: Partial<GetKidEventTinyShareLinkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidEventTinyShareLinkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidEventTinyShareLinkRes(); }
}

/**
* 获取用户分享的数据链接
*/
// @Route("/event/sharelink/{EventId}/{KidId}", "POST, OPTIONS")
// @Api(Description="获取用户分享的数据链接")
export class GetKidEventShareLinkReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * ItemId
    */
    // @ApiMember(DataType="string", Description="ItemId", Name="ItemId", ParameterType="query")
    public itemId?: string;

    public constructor(init?: Partial<GetKidEventShareLinkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidEventShareLinkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取一条成长任务
*/
// @Route("/class/{classId}/event/task/{eventid}", "GET, OPTIONS")
// @Api(Description="获取一条成长任务")
export class GetEventTaskByIdReq implements IReturn<GetEventTaskByIdRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="classId", ParameterType="path")
    public classId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid?: string;

    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="kidId", ParameterType="query")
    public kidId?: string;

    public constructor(init?: Partial<GetEventTaskByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventTaskByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventTaskByIdRes(); }
}

/**
* 在打卡任务中添加一条打卡记录
*/
// @Route("/class/event/clockin/{ClockInId}", "POST, OPTIONS")
// @Api(Description="在打卡任务中添加一条打卡记录")
export class AddEventClockInItemReq implements IReturn<CommonReturn>
{
    public eventId?: string;
    public classId?: string;
    public kidId?: string;
    public content?: string;
    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<AddEventClockInItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventClockInItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 读取一条打卡消息
*/
// @Route("/class/event/clockin/{eventid}", "GET, OPTIONS")
// @Api(Description="读取一条打卡消息")
export class GetEventClockInByIdReq implements IReturn<GetEventClockInByIdRes>
{
    public classId?: string;
    public eventId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetEventClockInByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventClockInByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventClockInByIdRes(); }
}

/**
* 读取某次打卡消息
*/
// @Route("/class/event/clockin/{eventid}/{groupid}", "GET, OPTIONS")
// @Api(Description="读取某次打卡消息")
export class GetEventClockInGroupByIdReq implements IReturn<GetEventClockInGroupItemsByIdRes>
{
    public classId?: string;
    public eventId?: string;
    public groupId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetEventClockInGroupByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventClockInGroupByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventClockInGroupItemsByIdRes(); }
}

/**
* 获取kidid中的待注册小孩
*/
// @Route("/school/checkbindkidentity", "POST, OPTIONS")
// @Api(Description="获取kidid中的待注册小孩")
export class CheckBindReq implements IReturn<CheckBindRes>
{
    /**
    * PhoneNumber
    */
    // @ApiMember(DataType="string", Description="PhoneNumber", IsRequired=true, Name="PhoneNumber", ParameterType="query")
    public phoneNumber?: string;

    /**
    * Token
    */
    // @ApiMember(DataType="string", Description="Token", IsRequired=true, Name="Token", ParameterType="query")
    public token?: string;

    /**
    * MToken
    */
    // @ApiMember(DataType="string", Description="MToken", IsRequired=true, Name="MToken", ParameterType="query")
    public mToken?: string;

    public constructor(init?: Partial<CheckBindReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckBindReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CheckBindRes(); }
}

/**
* 绑定kidid中的待注册小孩
*/
// @Route("/school/bindkidentity", "POST, OPTIONS")
// @Api(Description="绑定kidid中的待注册小孩")
export class KidBindReq implements IReturn<BaseResponse>
{
    /**
    * PhoneNumber
    */
    // @ApiMember(DataType="string", Description="PhoneNumber", IsRequired=true, Name="PhoneNumber", ParameterType="query")
    public phoneNumber?: string;

    /**
    * Token
    */
    // @ApiMember(DataType="string", Description="Token", IsRequired=true, Name="Token", ParameterType="query")
    public token?: string;

    /**
    * MToken
    */
    // @ApiMember(DataType="string", Description="MToken", IsRequired=true, Name="MToken", ParameterType="query")
    public mToken?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * KidEntityId
    */
    // @ApiMember(DataType="string", Description="KidEntityId", Name="KidEntityId", ParameterType="query")
    public kidEntityId?: string;

    /**
    * Name
    */
    // @ApiMember(DataType="string", Description="Name", Name="Name", ParameterType="query")
    public name?: string;

    /**
    * AvatarUrl
    */
    // @ApiMember(DataType="string", Description="AvatarUrl", Name="AvatarUrl", ParameterType="query")
    public avatarUrl?: string;

    /**
    * BirthDate
    */
    // @ApiMember(DataType="string", Description="BirthDate", Name="BirthDate", ParameterType="query")
    public birthDate?: string;

    /**
    * Gender
    */
    // @ApiMember(DataType="string", Description="Gender", Name="Gender", ParameterType="query")
    public gender?: GENDERTYPE;

    /**
    * ParentRole
    */
    // @ApiMember(DataType="string", Description="ParentRole", Name="ParentRole", ParameterType="query")
    public parentRole?: string;

    public constructor(init?: Partial<KidBindReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'KidBindReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 家长单条学生的晨检数据
*/
// @Route("/school/{SchoolId}/mykidhealthy/{DailyHealthyId}", "GET, OPTIONS")
// @Api(Description="家长单条学生的晨检数据")
export class GetParentDailyHealthyReq implements IReturn<GetDailyHealthyRes>
{
    public schoolId?: string;
    public kidId?: string;
    public dailyHealthyId?: string;

    public constructor(init?: Partial<GetParentDailyHealthyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParentDailyHealthyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyHealthyRes(); }
}

/**
* 家长自己学生的晨检数据
*/
// @Route("/school/{SchoolId}/mykidhealthies", "GET, OPTIONS")
// @Api(Description="家长自己学生的晨检数据")
export class GetSchoolMyKidDailyHealthiesReq implements IReturn<GetSchoolKidDailyHealthiesRes>
{
    public schoolId?: string;
    public kidId?: string;
    public pageSize?: number;
    public lastId?: string;

    public constructor(init?: Partial<GetSchoolMyKidDailyHealthiesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolMyKidDailyHealthiesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolKidDailyHealthiesRes(); }
}

/**
* 家长读取单条食谱
*/
// @Route("/school/{SchoolId}/dailymenuparent/{MenuId}", "GET, OPTIONS")
// @Api(Description="家长读取单条食谱")
export class GetDailyMenuFromParentReq implements IReturn<GetDailyMenuRes>
{
    public schoolId?: string;
    public menuId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetDailyMenuFromParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyMenuFromParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyMenuRes(); }
}

/**
* 家长读取食谱列表
*/
// @Route("/school/{SchoolId}/dailymenusparent", "GET, OPTIONS")
// @Api(Description="家长读取食谱列表")
export class GetDailyMenusFromParentReq implements IReturn<GetDailyMenusRes>
{
    public schoolId?: string;
    public lastReadId?: string;
    public pageSize?: number;

    public constructor(init?: Partial<GetDailyMenusFromParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDailyMenusFromParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDailyMenusRes(); }
}

/**
* 家长读取考勤详情
*/
// @Route("/school/{SchoolId}/kidattendance/{EventId}", "GET, OPTIONS")
// @Api(Description="家长读取考勤详情")
export class GetKidAttendanceFromParentReq implements IReturn<GetKidAttendanceFromParentRes>
{
    public schoolId?: string;
    public eventId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetKidAttendanceFromParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidAttendanceFromParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidAttendanceFromParentRes(); }
}

/**
* 家长端确认购买内容
*/
// @Route("/paymentcontent/event/{EventId}/confirm", "POST, OPTIONS")
// @Api(Description="家长端确认购买内容")
export class BuyPaymentContentReq implements IReturn<BaseResponse>
{
    public eventId?: string;
    public studentId?: string;

    public constructor(init?: Partial<BuyPaymentContentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BuyPaymentContentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 家长端查看内容购买信息
*/
// @Route("/paymentcontent/event/{EventId}/info", "POST, OPTIONS")
// @Api(Description="家长端查看内容购买信息")
export class GetPaymentContentInfoReq implements IReturn<GetPaymentContentInfoRes>
{
    public eventId?: string;
    public studentId?: string;

    public constructor(init?: Partial<GetPaymentContentInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPaymentContentInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPaymentContentInfoRes(); }
}

/**
* 上传家长爱心视频播放记录
*/
// @Route("/live/parentplaystate", "POST, OPTIONS")
// @Api(Description="上传家长爱心视频播放记录")
export class PostParentClassLiveVideoPlayStateReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public kidId?: string;
    public classId?: string;
    public playSeconds?: number;

    public constructor(init?: Partial<PostParentClassLiveVideoPlayStateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostParentClassLiveVideoPlayStateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 家长获取爱心视频地址列表
*/
// @Route("/live/parentlives", "GET, OPTIONS")
// @Api(Description="家长获取爱心视频地址列表")
export class GetParentClassLiveVideosReq implements IReturn<GetParentClassLiveVideosRes>
{
    public schoolId?: string;
    public classId?: string;
    public kidId?: string;
    public pageSize?: number;
    public skipVisitLog?: boolean;

    public constructor(init?: Partial<GetParentClassLiveVideosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParentClassLiveVideosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetParentClassLiveVideosRes(); }
}

/**
* 家长获取用药申请
*/
// @Route("/school/{SchoolId}/healthy/mediccaresbystudent", "GET, OPTIONS")
// @Api(Description="家长获取用药申请")
export class GetMedicCaresByStudentReq implements IReturn<GetMedicCaresRes>
{
    public schoolId?: string;
    public studentId?: string;

    public constructor(init?: Partial<GetMedicCaresByStudentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMedicCaresByStudentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMedicCaresRes(); }
}

/**
* 家长创建用药申请
*/
// @Route("/school/{SchoolId}/healthy/mediccares", "POST, OPTIONS")
// @Api(Description="家长创建用药申请")
export class AddMedicCareReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public studentId?: string;
    public medicDesc?: string;
    public medicTimeOn?: string;
    public parentMemo?: string;
    public parentImageItems?: EventAttachImageItemDTO[];

    public constructor(init?: Partial<AddMedicCareReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddMedicCareReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 家长修改用药申请
*/
// @Route("/school/{SchoolId}/healthy/mediccare/{MedicCareId}", "POST, OPTIONS")
// @Api(Description="家长修改用药申请")
export class UpdateMedicCareReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public medicCareId?: string;
    public studentId?: string;
    public medicDesc?: string;
    public medicTimeOn?: string;
    public parentMemo?: string;
    public parentImageItems?: EventAttachImageItemDTO[];

    public constructor(init?: Partial<UpdateMedicCareReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateMedicCareReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 家长修改资源的角度
*/
// @Route("/growbook/KidId/{KidId}/EventId/{EventId}/Rotate", "POST, OPTIONS")
// @Api(Description="家长修改资源的角度")
export class UpdateGrowBookItemRotateReq implements IReturn<CommonReturn>
{
    public kidId?: string;
    public eventId?: string;
    public ossId?: string;
    public rotate?: number;

    public constructor(init?: Partial<UpdateGrowBookItemRotateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateGrowBookItemRotateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 家长修改资源的排序
*/
// @Route("/growbook/KidId/{KidId}/EventId/{EventId}/OrderIndex", "POST, OPTIONS")
// @Api(Description="家长修改资源的排序")
export class UpdateGrowBookItemOrderIndexReq implements IReturn<CommonReturn>
{
    public kidId?: string;
    public eventId?: string;
    public ossId?: string;
    public newPosition?: number;

    public constructor(init?: Partial<UpdateGrowBookItemOrderIndexReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateGrowBookItemOrderIndexReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 家长添加资源进成长册
*/
// @Route("/growbook/KidId/{KidId}", "POST, OPTIONS")
// @Api(Description="家长添加资源进成长册")
export class AddItemToGrowBookByParentReq implements IReturn<CommonReturn>
{
    public kidId?: string;
    public eventId?: string;
    public ossId?: string;

    public constructor(init?: Partial<AddItemToGrowBookByParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddItemToGrowBookByParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 家长删除成长册中整个动态
*/
// @Route("/growbook/KidId/{KidId}/{EventId}", "DELETE, OPTIONS")
// @Api(Description="家长删除成长册中整个动态")
export class RemoveEventFromGrowBookByParentReq implements IReturn<CommonReturn>
{
    public kidId?: string;
    public eventId?: string;

    public constructor(init?: Partial<RemoveEventFromGrowBookByParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveEventFromGrowBookByParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 家长删除成长册中资源
*/
// @Route("/growbook/KidId/{KidId}", "DELETE, OPTIONS")
// @Api(Description="家长删除成长册中资源")
export class RemoveItemFromGrowBookByParentReq implements IReturn<CommonReturn>
{
    public kidId?: string;
    public eventId?: string;
    public ossId?: string;

    public constructor(init?: Partial<RemoveItemFromGrowBookByParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveItemFromGrowBookByParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取用户分享的相册链接
*/
// @Route("/album/kidid/{Kidid}/sharelink", "POST, OPTIONS")
// @Api(Description="获取用户分享的相册链接")
export class GetKidAlbumShareLinkReq implements IReturn<CommonReturn>
{
    /**
    * Kidid
    */
    // @ApiMember(DataType="string", Description="Kidid", IsRequired=true, Name="Kidid", ParameterType="path")
    public kidid?: string;

    /**
    * Termid
    */
    // @ApiMember(DataType="integer", Description="Termid", IsRequired=true, Name="Termid", ParameterType="query")
    public termid: number;

    public constructor(init?: Partial<GetKidAlbumShareLinkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidAlbumShareLinkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取内容集合
*/
// @Route("/extrabook/bookitem/{KidId}/{ConfigId}", "GET, OPTIONS")
// @Api(Description="获取内容集合")
export class GetExtrabookItemsReq implements IReturn<GetExtrabookItemsRes>
{
    public configId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetExtrabookItemsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetExtrabookItemsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetExtrabookItemsRes(); }
}

/**
* 修改内容
*/
// @Route("/extrabook/bookitems/{BookId}", "PUT, OPTIONS")
// @Api(Description="修改内容")
export class UpdateExtrabookItemReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="BookId", ParameterType="path")
    public bookId?: string;

    /**
    * 内容时间
    */
    // @ApiMember(DataType="string", Description="内容时间", Name="ContentTime", ParameterType="query")
    public contentTime?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<UpdateExtrabookItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateExtrabookItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加内容
*/
// @Route("/extrabook/bookitems", "POST, OPTIONS")
// @Api(Description="添加内容")
export class AddExtrabookItemReq implements IReturn<CommonReturn>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * 班级Id
    */
    // @ApiMember(DataType="string", Description="班级Id", Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 学校Id
    */
    // @ApiMember(DataType="string", Description="学校Id", IsRequired=true, Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * 内容时间
    */
    // @ApiMember(DataType="string", Description="内容时间", Name="ContentTime", ParameterType="query")
    public contentTime?: string;

    /**
    * 标题
    */
    // @ApiMember(DataType="string", Description="标题", IsRequired=true, Name="Title", ParameterType="query")
    public title?: string;

    /**
    * 内容
    */
    // @ApiMember(DataType="string", Description="内容", IsRequired=true, Name="Content", ParameterType="query")
    public content?: string;

    public attachItem?: EventAttachItem;
    /**
    * BookExtraConfigId
    */
    // @ApiMember(DataType="string", Description="BookExtraConfigId", IsRequired=true, Name="BookExtraConfigId", ParameterType="query")
    public bookExtraConfigId?: string;

    /**
    * BookExtraConfigItemId
    */
    // @ApiMember(DataType="string", Description="BookExtraConfigItemId", IsRequired=true, Name="BookExtraConfigItemId", ParameterType="query")
    public bookExtraConfigItemId?: string;

    public constructor(init?: Partial<AddExtrabookItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddExtrabookItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 家长端获取联系人
*/
// @Route("/parent/im/contacts", "GET, OPTIONS")
// @Api(Description="家长端获取联系人")
export class GetParentIMContactsReq implements IReturn<GetIMContactsRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    public constructor(init?: Partial<GetParentIMContactsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParentIMContactsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIMContactsRes(); }
}

/**
* 家长端设置免打扰
*/
// @Route("/parent/im/setmute", "POST, OPTIONS")
// @Api(Description="家长端设置免打扰")
export class SetParentIMMuteReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public kidId?: string;
    public isGlobalMute?: boolean;
    public conversationId?: string;
    public enableMute?: boolean;

    public constructor(init?: Partial<SetParentIMMuteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetParentIMMuteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 家长端获取目录
*/
// @Route("/parent/im/catalog", "GET, OPTIONS")
// @Api(Description="家长端获取目录")
export class GetParentIMCatalogReq implements IReturn<GetParentIMCatalogRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetParentIMCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParentIMCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetParentIMCatalogRes(); }
}

/**
* 举报
*/
// @Route("/im/jubao", "POST, OPTIONS")
// @Api(Description="举报")
export class AddIMJubaoReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public targetConversationId?: string;
    public rongIMId?: string;

    public constructor(init?: Partial<AddIMJubaoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddIMJubaoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取成长集我的订单列表
*/
// @Route("/shop/bookkidorders", "GET, OPTIONS")
// @Api(Description="获取成长集我的订单列表")
export class GetKidBookOrdersReq implements IReturn<GetBookOrdersRes>
{
    public kidId?: string;

    public constructor(init?: Partial<GetKidBookOrdersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidBookOrdersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBookOrdersRes(); }
}

/**
* 发送kid pdf地址到邮件
*/
// @Route("/shop/sendkidpdfmail", "POST, OPTIONS")
// @Api(Description="发送kid pdf地址到邮件")
export class SendKidPdfMailReq implements IReturn<CommonReturn>
{
    public bookOrderId?: string;
    public targetMail?: string;

    public constructor(init?: Partial<SendKidPdfMailReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendKidPdfMailReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取kid pdf地址
*/
// @Route("/shop/getkidpdfurl", "GET, OPTIONS")
// @Api(Description="获取kid pdf地址")
export class GetKidPdfUrlReq implements IReturn<GetPdfUrlRes>
{
    public bookOrderId?: string;

    public constructor(init?: Partial<GetKidPdfUrlReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidPdfUrlReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPdfUrlRes(); }
}

/**
* 用户下单准备接口
*/
// @Route("/shop/prepareorder", "POST, OPTIONS")
// @Api(Description="用户下单准备接口")
export class PrepareBookOrderReq implements IReturn<PrepareBookOrderRes>
{
    /**
    * Kidid
    */
    // @ApiMember(DataType="string", Description="Kidid", IsRequired=true, Name="Kidid", ParameterType="query")
    public kidid?: string;

    /**
    * Termid
    */
    // @ApiMember(DataType="integer", Description="Termid", IsRequired=true, Name="Termid", ParameterType="query")
    public termid: number;

    /**
    * ExtraBookConfigId
    */
    // @ApiMember(DataType="string", Description="ExtraBookConfigId", IsRequired=true, Name="ExtraBookConfigId", ParameterType="query")
    public extraBookConfigId?: string;

    /**
    * 类型
    */
    // @ApiMember(DataType="string", Description="类型", IsRequired=true, Name="OrderType", ParameterType="query")
    public orderType: BOOKORDER_TYPE;

    public constructor(init?: Partial<PrepareBookOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PrepareBookOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PrepareBookOrderRes(); }
}

/**
* 用户下单接口
*/
// @Route("/shop/createorder", "POST, OPTIONS")
// @Api(Description="用户下单接口")
export class CreateBookOrderReq implements IReturn<CreateBookOrderRes>
{
    /**
    * Kidid
    */
    // @ApiMember(DataType="string", Description="Kidid", IsRequired=true, Name="Kidid", ParameterType="query")
    public kidid?: string;

    /**
    * Termid
    */
    // @ApiMember(DataType="integer", Description="Termid", IsRequired=true, Name="Termid", ParameterType="query")
    public termid: number;

    /**
    * ExtraBookConfigId
    */
    // @ApiMember(DataType="string", Description="ExtraBookConfigId", IsRequired=true, Name="ExtraBookConfigId", ParameterType="query")
    public extraBookConfigId?: string;

    /**
    * 类型
    */
    // @ApiMember(DataType="string", Description="类型", IsRequired=true, Name="OrderType", ParameterType="query")
    public orderType: BOOKORDER_TYPE;

    /**
    * 型号
    */
    // @ApiMember(DataType="string", Description="型号", IsRequired=true, Name="BookEdition", ParameterType="query")
    public bookEdition: BOOKORDER_EDITION;

    /**
    * 购买数量
    */
    // @ApiMember(DataType="string", Description="购买数量", IsRequired=true, Name="Num", ParameterType="query")
    public num: number;

    public constructor(init?: Partial<CreateBookOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateBookOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateBookOrderRes(); }
}

/**
* 获取家长端支付产品信息接口
*/
// @Route("/payment/parentproducts", "GET, OPTIONS")
// @Api(Description="获取家长端支付产品信息接口")
export class GetParentOrderProductsReq implements IReturn<GetOrderProductsRes>
{
    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public catalog?: ORDERPRODUCT_CATALOG;

    public constructor(init?: Partial<GetParentOrderProductsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetParentOrderProductsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderProductsRes(); }
}

/**
* 小程序订单预处理接口
*/
// @Route("/payment/prepaywxlite", "POST, OPTIONS")
// @Api(Description="小程序订单预处理接口")
export class PrePayWxliteReq extends CommonRequest implements IReturn<PrePayWxliteRes>
{
    public productId?: string;
    public schoolId?: string;
    public kidId?: string;
    public productCatalog?: ORDERPRODUCT_CATALOG;

    public constructor(init?: Partial<PrePayWxliteReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'PrePayWxliteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PrePayWxliteRes(); }
}

/**
* 小程序创建订单接口
*/
// @Route("/payment/prepaywxliteprocess", "POST, OPTIONS")
// @Api(Description="小程序创建订单接口")
export class PrePayWxliteProcessReq implements IReturn<PrePayWxliteProcessRes>
{
    public wxCode?: string;
    public code?: string;

    public constructor(init?: Partial<PrePayWxliteProcessReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PrePayWxliteProcessReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PrePayWxliteProcessRes(); }
}

/**
* 微信创建订单接口
*/
// @Route("/payment/prepaywechat", "POST, OPTIONS")
// @Api(Description="微信创建订单接口")
export class PrePayWechatReq extends CommonRequest implements IReturn<PrePayWechatRes>
{
    public productId?: string;
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<PrePayWechatReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'PrePayWechatReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PrePayWechatRes(); }
}

/**
* IOS验证支付结果
*/
// @Route("/payment/iosverifyreceipt", "POST, OPTIONS")
// @Api(Description="IOS验证支付结果")
export class AppstoreVerifyReceiptReq extends CommonRequest implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public kidId?: string;
    public receiptData?: string;

    public constructor(init?: Partial<AppstoreVerifyReceiptReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AppstoreVerifyReceiptReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class UNKnowEventItemCommentItemDTOList
{
    public commentItems?: EventItemCommentItemDTO[];

    public constructor(init?: Partial<UNKnowEventItemCommentItemDTOList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UNKnowEventItemCommentItemDTOList'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UnKnowListEventLikeUserDTO
{
    public data?: EventLikeUserDTO[];

    public constructor(init?: Partial<UnKnowListEventLikeUserDTO>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnKnowListEventLikeUserDTO'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UnKnowEnumTASKATTACHTYPE
{
    public data?: TASKATTACHTYPE;

    public constructor(init?: Partial<UnKnowEnumTASKATTACHTYPE>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnKnowEnumTASKATTACHTYPE'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UnKnowEnumCLASSGRADETYPE
{
    /**
    * data
    */
    // @ApiMember(DataType="string", Description="data", IsRequired=true, Name="data", ParameterType="path")
    public data: CLASSGRADETYPE;

    public constructor(init?: Partial<UnKnowEnumCLASSGRADETYPE>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnKnowEnumCLASSGRADETYPE'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UnknowAttachVideoItem
{
    public data?: AttachVideoItem;
    public data2?: EventAttachItem;
    public data3?: AttachLinkItem;
    public data4?: AttachFileItem;

    public constructor(init?: Partial<UnknowAttachVideoItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnknowAttachVideoItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UnknowListAttachImageItem
{
    public data?: AttachImageItem[];
    public data2?: TeacherInfoDTO[];
    public data3?: TeacherClassInfoDTO[];
    public data4?: KidInfoDTO[];

    public constructor(init?: Partial<UnknowListAttachImageItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnknowListAttachImageItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 获取当前绑定的设备及时点数据
*/
// @Route("/watch/device", "GET, OPTIONS")
// @Api(Description="获取当前绑定的设备及时点数据")
export class WatchGetDeviceInfoReq implements IReturn<WatchGetDeviceInfoRes>
{
    public schoolId?: string;
    public studentId?: string;

    public constructor(init?: Partial<WatchGetDeviceInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WatchGetDeviceInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WatchGetDeviceInfoRes(); }
}

/**
* 设备手环设备
*/
// @Route("/watch/getwatchinfos", "POST, OPTIONS")
// @Api(Description="设备手环设备")
export class GetWatchInfosReq implements IReturn<GetWatchInfosRes>
{
    public schoolId?: string;
    public branchIds?: string[];
    public macAddress?: string;
    public icNumber?: string;
    public studentId?: string;
    public watchModal?: string;
    public watchState?: WATCH_DEVICE_STATE;
    public lastDataRecordOn?: string[];
    public steps?: number[];
    public distances?: number[];
    public calories?: number[];
    public createOn?: string[];
    public lastBindOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetWatchInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWatchInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetWatchInfosRes(); }
}

/**
* 设备绑定管理
*/
// @Route("/watch/bindbyic", "POST, OPTIONS")
// @Api(Description="设备绑定管理")
export class WatchBindByICNumberReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public studentId?: string;
    public watchICNumber?: string;

    public constructor(init?: Partial<WatchBindByICNumberReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WatchBindByICNumberReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设备绑定管理
*/
// @Route("/watch/bind", "POST, OPTIONS")
// @Api(Description="设备绑定管理")
export class WatchBindReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public studentId?: string;
    public watchMacAddress?: string;
    public isBind?: boolean;

    public constructor(init?: Partial<WatchBindReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WatchBindReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设备历史数据
*/
// @Route("/watch/devicereport", "GET, OPTIONS")
// @Api(Description="设备历史数据")
export class WatchGetReportDataReq implements IReturn<WatchGetReportDataRes>
{
    public schoolId?: string;
    public studentId?: string;

    public constructor(init?: Partial<WatchGetReportDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WatchGetReportDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WatchGetReportDataRes(); }
}

/**
* 设备mac有效性验证
*/
// @Route("/watch/verifymac", "POST, OPTIONS")
// @Api(Description="设备mac有效性验证")
export class WatchMacVerifyReq implements IReturn<WatchMacVerifyRes>
{
    public schoolId?: string;
    public studentId?: string;
    public watchMacs?: string[];

    public constructor(init?: Partial<WatchMacVerifyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WatchMacVerifyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WatchMacVerifyRes(); }
}

/**
* 系统注册手环
*/
// @Route("/watch/register", "POST, OPTIONS")
// @Api(Description="系统注册手环")
export class WatchRegisterReq implements IReturn<BaseResponse>
{
    public watchModal?: string;
    public macAddress?: WatchMacInfo[];

    public constructor(init?: Partial<WatchRegisterReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WatchRegisterReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设置为可绑定状态
*/
// @Route("/watch/setcanbind", "POST, OPTIONS")
// @Api(Description="设置为可绑定状态")
export class WatchSetCanBindReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public macAddress?: string[];

    public constructor(init?: Partial<WatchSetCanBindReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WatchSetCanBindReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 创建销售线索
*/
// @Route("/saleslead/{SchoolId}", "POST, OPTIONS")
// @Api(Description="创建销售线索")
export class CreateSalesLeadByParentReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public studentName?: string;
    public gender?: GENDERTYPE;
    public primaryContact?: ParentInfoDTO;
    public birthDate?: string;
    public remark?: string;
    public source?: string;
    public tags?: string[];

    public constructor(init?: Partial<CreateSalesLeadByParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateSalesLeadByParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 家长获取学生的所有课时
*/
// @Route("/eduaffair/{SchoolId}/{KidId}/classtimes", "GET, OPTIONS")
// @Api(Description="家长获取学生的所有课时")
export class GetClassTimesForParentReq implements IReturn<GetClassTimesForParentRes>
{
    public schoolId?: string;
    public kidId?: string;
    public startTime?: string;
    public endTime?: string;

    public constructor(init?: Partial<GetClassTimesForParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassTimesForParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassTimesForParentRes(); }
}

/**
* 获取学生课程的课时剩余信息
*/
// @Route("/school/{SchoolId}/kid/{KidId}/coursebalance", "GET, OPTIONS")
// @Api(Description="获取学生课程的课时剩余信息")
export class GetKidCourseBalanceReq implements IReturn<GetKidCourseBalanceRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetKidCourseBalanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidCourseBalanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidCourseBalanceRes(); }
}

/**
* 获取园所信息
*/
// @Route("/schoolname/{SchoolId}", "GET, OPTIONS")
// @Api(Description="获取园所信息")
export class GetSchoolNameByParentReq implements IReturn<GetSchoolNameRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolNameByParentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolNameByParentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolNameRes(); }
}

/**
* 微信公众号接入验证
*/
// @Route("/wechat/eventnotify", "GET, OPTIONS")
// @Api(Description="微信公众号接入验证")
export class WechatMPEventNotifyCheckReq
{
    public signature?: string;
    public timestamp?: string;
    public nonce?: string;
    public echostr?: string;

    public constructor(init?: Partial<WechatMPEventNotifyCheckReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WechatMPEventNotifyCheckReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 微信公众号事件推送
*/
// @Route("/wechat/eventnotify", "POST, OPTIONS")
// @Api(Description="微信公众号事件推送")
export class WechatMPEventNotifyReq
{

    public constructor(init?: Partial<WechatMPEventNotifyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WechatMPEventNotifyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 获取授权方的appid
*/
// @Route("/wechatmp/authorizerappid", "GET, OPTIONS")
// @Api(Description="获取授权方的appid")
export class GetWechatComponentAppIdBySchoolReq implements IReturn<GetWechatComponentAppIdBySchoolRes>
{
    public component_appid?: string;
    public schoolId?: string;

    public constructor(init?: Partial<GetWechatComponentAppIdBySchoolReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWechatComponentAppIdBySchoolReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetWechatComponentAppIdBySchoolRes(); }
}

/**
* 微信H5用户激活绑定
*/
// @Route("/wechatmp/userbind", "POST, OPTIONS")
// @Api(Description="微信H5用户激活绑定")
export class WechatComponentBindReq implements IReturn<WechatComponentBindRes>
{
    public schoolId?: string;
    public phoneNumber?: string;
    public verifyCode?: string;
    public authorizer_OpenId?: string;
    public component_appid?: string;
    public authorizer_appid?: string;

    public constructor(init?: Partial<WechatComponentBindReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WechatComponentBindReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WechatComponentBindRes(); }
}

/**
* 检查微信公众号模版消息配置
*/
// @Route("/wechatmp/checktemplate", "POST, OPTIONS")
// @Api(Description="检查微信公众号模版消息配置")
export class WechatComponentCheckTemplateReq implements IReturn<WechatComponentCheckTemplateRes>
{
    public schoolId?: string;
    public isUpdate?: boolean;

    public constructor(init?: Partial<WechatComponentCheckTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WechatComponentCheckTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WechatComponentCheckTemplateRes(); }
}

/**
* 获取学生的身体成长信息
*/
// @Route("/kidgrow/{SchoolId}/info/{KidId}", "GET, OPTIONS")
// @Api(Description="获取学生的身体成长信息")
export class GetKidGrowInfoReq implements IReturn<GetKidGrowInfoRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetKidGrowInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidGrowInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidGrowInfoRes(); }
}

/**
* 增加客户端配置项
*/
// @Route("/system/configs", "POST, OPTIONS")
// @Api(Description="增加客户端配置项")
export class CreateConfigInfoReq implements IReturn<CommonReturn>
{
    /**
    * AppId
    */
    // @ApiMember(DataType="integer", Description="AppId", IsRequired=true, Name="AppId", ParameterType="query")
    public appId: number;

    /**
    * 终端类型
    */
    // @ApiMember(DataType="array", Description="终端类型", Name="Platforms", ParameterType="query")
    public platforms?: OSTYPE[];

    /**
    * 客户端版本限制
    */
    // @ApiMember(DataType="string", Description="客户端版本限制", IsRequired=true, Name="AppVersion", ParameterType="query")
    public appVersion?: string;

    /**
    * 配置项名
    */
    // @ApiMember(DataType="string", Description="配置项名", IsRequired=true, Name="ConfigName", ParameterType="query")
    public configName?: string;

    /**
    * 所属渠道
    */
    // @ApiMember(DataType="array", Description="所属渠道", Name="Channels", ParameterType="query")
    public channels?: string[];

    /**
    * 配置项
    */
    // @ApiMember(DataType="string", Description="配置项", IsRequired=true, Name="ConfigValue", ParameterType="query")
    public configValue?: string;

    public constructor(init?: Partial<CreateConfigInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateConfigInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改客户端配置项
*/
// @Route("/system/config/{ConfigId}", "POST, OPTIONS")
// @Api(Description="修改客户端配置项")
export class AddConfigItemReq implements IReturn<CommonReturn>
{
    /**
    * ConfigId
    */
    // @ApiMember(DataType="string", Description="ConfigId", IsRequired=true, Name="ConfigId", ParameterType="path")
    public configId?: string;

    /**
    * 配置项
    */
    // @ApiMember(DataType="string", Description="配置项", IsRequired=true, Name="ConfigValue", ParameterType="query")
    public configValue?: string;

    public constructor(init?: Partial<AddConfigItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddConfigItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取客户端所有配置项
*/
// @Route("/system/configs", "GET, OPTIONS")
// @Api(Description="获取客户端所有配置项")
export class GetConfigInfoReq implements IReturn<CommonReturn>
{

    public constructor(init?: Partial<GetConfigInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetConfigInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 验证卡库Excel文件
*/
// @Route("/attendance/validcardsexcel/{SchoolId}", "POST, OPTIONS")
// @Api(Description="验证卡库Excel文件")
export class AddAttendanceCardPreReq implements IReturn<AddAttendanceCardPreRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<AddAttendanceCardPreReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddAttendanceCardPreReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddAttendanceCardPreRes(); }
}

/**
* 分配卡号段给学校
*/
// @Route("/attendance/allocatecardstoschool/{SchoolId}", "POST, OPTIONS")
// @Api(Description="分配卡号段给学校")
export class AllocateCardToSchoolReq implements IReturn<BaseResponse>
{
    public cardSerialNumbers?: string[];
    public schoolId?: string;

    public constructor(init?: Partial<AllocateCardToSchoolReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AllocateCardToSchoolReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加卡到卡库
*/
// @Route("/attendance/addtorepo", "POST, OPTIONS")
// @Api(Description="添加卡到卡库")
export class AddAttendanceRepoReq implements IReturn<AddAttendanceRepoRes>
{
    public cards?: { [index: string]: string; };
    public schoolId?: string;

    public constructor(init?: Partial<AddAttendanceRepoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddAttendanceRepoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddAttendanceRepoRes(); }
}

/**
* 设备保存配置
*/
// @Route("/attendance/deviceconfig", "POST, OPTIONS")
// @Api(Description="设备保存配置")
export class AttendanceSaveDeviceConfigReq implements IReturn<BaseResponse>
{
    public serial?: string;
    public deviceConfig?: string;

    public constructor(init?: Partial<AttendanceSaveDeviceConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceSaveDeviceConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设备读取配置
*/
// @Route("/attendance/deviceconfig/{Serial}", "GET, OPTIONS")
// @Api(Description="设备读取配置")
export class AttendanceGetDeviceConfigReq implements IReturn<BaseResponse>
{
    public serial?: string;

    public constructor(init?: Partial<AttendanceGetDeviceConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceGetDeviceConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设备修改校区
*/
// @Route("/attendance/changedevicebranchid", "POST, OPTIONS")
// @Api(Description="设备修改校区")
export class AttendanceChangeDeviceBranchIdReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public branchId?: string;
    public deviceId?: string;

    public constructor(init?: Partial<AttendanceChangeDeviceBranchIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceChangeDeviceBranchIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 注册设备
*/
// @Route("/attendance/regdevice", "POST, OPTIONS")
// @Api(Description="注册设备")
export class AttendanceRegDeviceReq implements IReturn<BaseResponse>
{
    public id?: string;
    public schoolId?: string;
    public branchId?: string;
    public mac?: string;
    public serial?: string;
    public remark?: string;
    public arcfaceLicense?: string;
    public deviceType?: AttendanceDevice_Type;
    public attendanceDoorId?: string;
    public direction?: AttendanceDoor_Direction;

    public constructor(init?: Partial<AttendanceRegDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AttendanceRegDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 列取设备
*/
// @Route("/attendance/devices/{SchoolId}", "GET, OPTIONS")
// @Api(Description="列取设备")
export class GetAttendanceDeviceReq implements IReturn<GetAttendanceDeviceRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetAttendanceDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAttendanceDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAttendanceDeviceRes(); }
}

/**
* 列取设备
*/
// @Route("/attendance/devices/{SchoolId}/getattendancedoorcontroller", "GET")
// @Api(Description="列取设备")
export class GetAttendanceDoorControllerReq implements IReturn<GetAttendanceDoorControllerRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetAttendanceDoorControllerReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAttendanceDoorControllerReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetAttendanceDoorControllerRes(); }
}

/**
* 列取设备
*/
// @Route("/attendance/devices/{SchoolId}/getattendancedoor", "GET")
// @Api(Description="列取设备")
export class GetAttendanceDoorReq implements IReturn<GetAttendanceDoorRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetAttendanceDoorReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAttendanceDoorReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetAttendanceDoorRes(); }
}

/**
* 导出设备数据库
*/
// @Route("/attendance/devices/dumpdb", "POST, OPTIONS")
// @Api(Description="导出设备数据库")
export class DumpDeviceDBReq implements IReturn<BaseResponse>
{
    public deviceId?: string;

    public constructor(init?: Partial<DumpDeviceDBReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DumpDeviceDBReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 导出设备数据库
*/
// @Route("/attendance/devices/dumplog", "POST, OPTIONS")
// @Api(Description="导出设备数据库")
export class DumpDeviceLogReq implements IReturn<BaseResponse>
{
    public deviceId?: string;

    public constructor(init?: Partial<DumpDeviceLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DumpDeviceLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 刷新设备数据库
*/
// @Route("/attendance/devices/refreshdb", "POST, OPTIONS")
// @Api(Description="刷新设备数据库")
export class RefreshDeviceDBReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public isLoadAll?: boolean;
    public ownerType?: ATTENDANCECARDOWNERTYPE;

    public constructor(init?: Partial<RefreshDeviceDBReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RefreshDeviceDBReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新考勤终端机App版本
*/
// @Route("/attendance/devices/upgradeapp", "POST, OPTIONS")
// @Api(Description="更新考勤终端机App版本")
export class UpgradeDeviceAppReq implements IReturn<BaseResponse>
{
    public schoolIds?: string[];
    public deviceSerials?: string[];
    public versionId?: string;

    public constructor(init?: Partial<UpgradeDeviceAppReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpgradeDeviceAppReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加配置
*/
// @Route("/extrabook/bookconfigs", "POST, OPTIONS")
// @Api(Description="添加配置")
export class AddExtrabookConfigReq implements IReturn<CommonReturn>
{
    public name?: string;
    public desc?: string;
    public frontCoverUrl?: string;
    public backCoverUrl?: string;

    public constructor(init?: Partial<AddExtrabookConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddExtrabookConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改配置
*/
// @Route("/extrabook/bookconfig/{BookConfigId}", "POST, OPTIONS")
// @Api(Description="修改配置")
export class UpdateExtrabookConfigReq implements IReturn<CommonReturn>
{
    public bookConfigId?: string;
    public name?: string;
    public desc?: string;
    public frontCoverUrl?: string;
    public backCoverUrl?: string;

    public constructor(init?: Partial<UpdateExtrabookConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateExtrabookConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加配置子项
*/
// @Route("/extrabook/bookconfig/{BookConfigId}/items", "POST, OPTIONS")
// @Api(Description="添加配置子项")
export class AddExtrabookConfigItemReq implements IReturn<CommonReturn>
{
    public bookConfigId?: string;
    public name?: string;
    public title?: string;
    public content?: string;
    public backgroundUrl?: string;
    public fontColor?: string;

    public constructor(init?: Partial<AddExtrabookConfigItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddExtrabookConfigItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改配置子项
*/
// @Route("/extrabook/bookconfig/{BookConfigId}/item/{BookConfigItemId}", "POST, OPTIONS")
// @Api(Description="修改配置子项")
export class UpdateExtrabookConfigItemReq implements IReturn<CommonReturn>
{
    public bookConfigId?: string;
    public bookConfigItemId?: string;
    public name?: string;
    public title?: string;
    public content?: string;
    public backgroundUrl?: string;
    public fontColor?: string;

    public constructor(init?: Partial<UpdateExtrabookConfigItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateExtrabookConfigItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 配置子项排序
*/
// @Route("/extrabook/bookconfig/{BookConfigId}/reorder", "POST, OPTIONS")
// @Api(Description="配置子项排序")
export class ReorderExtrabookConfigItemReq implements IReturn<CommonReturn>
{
    public bookConfigId?: string;
    public bookConfigItemId?: string;
    public newOrderPos?: number;

    public constructor(init?: Partial<ReorderExtrabookConfigItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReorderExtrabookConfigItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 配置删除
*/
// @Route("/extrabook/bookconfig/{BookConfigId}/delete", "DELETE, OPTIONS")
// @Api(Description="配置删除")
export class DeleteExtrabookConfigReq implements IReturn<CommonReturn>
{
    public bookConfigId?: string;

    public constructor(init?: Partial<DeleteExtrabookConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteExtrabookConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 配置子项删除
*/
// @Route("/extrabook/bookconfig/{BookConfigId}/item/{BookConfigItemId}/delete", "DELETE, OPTIONS")
// @Api(Description="配置子项删除")
export class DeleteExtrabookConfigItemReq implements IReturn<CommonReturn>
{
    public bookConfigId?: string;
    public bookConfigItemId?: string;

    public constructor(init?: Partial<DeleteExtrabookConfigItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteExtrabookConfigItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取所有配置
*/
// @Route("/extrabook/bookconfigs", "GET, OPTIONS")
// @Api(Description="获取所有配置")
export class GetExtrabookConfigsReq implements IReturn<GetExtrabookConfigsRes>
{

    public constructor(init?: Partial<GetExtrabookConfigsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetExtrabookConfigsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetExtrabookConfigsRes(); }
}

/**
* 添加成长手册封面模板配置
*/
// @Route("/system/growbooks/template", "POST, OPTIONS")
// @Api(Description="添加成长手册封面模板配置")
export class AddGrowBookFrontPageTemplateReq implements IReturn<CommonReturn>
{
    public name?: string;
    public frontPageUrl?: string;
    public backPageUrl?: string;

    public constructor(init?: Partial<AddGrowBookFrontPageTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddGrowBookFrontPageTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新成长手册配置
*/
// @Route("/class/growbooks/config", "POST, OPTIONS")
// @Api(Description="更新成长手册配置")
export class UpdateGrowBookConfigReq implements IReturn<CommonReturn>
{
    public semesterType?: SEMESTERTYPE;
    public growBookFrontPageTemplateId?: string;

    public constructor(init?: Partial<UpdateGrowBookConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateGrowBookConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取成长手册配置
*/
// @Route("/class/growbooks/configs", "GET, OPTIONS")
// @Api(Description="获取成长手册配置")
export class GetGrowBookConfigsReq implements IReturn<GetGrowBookConfigsRes>
{

    public constructor(init?: Partial<GetGrowBookConfigsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGrowBookConfigsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGrowBookConfigsRes(); }
}

/**
* 初始化用户的feed
*/
// @Route("/system/inituserfeed", "POST, OPTIONS")
// @Api(Description="初始化用户的feed")
export class InitUserFeedReq implements IReturn<CommonReturn>
{
    /**
    * UserId
    */
    // @ApiMember(DataType="integer", Description="UserId", IsRequired=true, Name="UserId", ParameterType="query")
    public userId: number;

    /**
    * ClassId
    */
    // @ApiMember(DataType="string", Description="ClassId", IsRequired=true, Name="ClassId", ParameterType="query")
    public classId?: string;

    /**
    * IsTeacher
    */
    // @ApiMember(DataType="boolean", Description="IsTeacher", IsRequired=true, Name="IsTeacher", ParameterType="query")
    public isTeacher: boolean;

    public kidId?: string;

    public constructor(init?: Partial<InitUserFeedReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InitUserFeedReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加设备目录
*/
// @Route("/iot/iotcatalogs", "POST, OPTIONS")
// @Api(Description="添加设备目录")
export class AddIotCatalogReq implements IReturn<BaseResponse>
{
    public name?: string;
    public code?: string;

    public constructor(init?: Partial<AddIotCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddIotCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加设备
*/
// @Route("/iot/iotdevices", "POST, OPTIONS")
// @Api(Description="添加设备")
export class AddIotDeviceReq implements IReturn<BaseResponse>
{
    public id?: string;
    public schoolId?: string;
    public catalogId?: string;
    public serialNo?: string;
    public deviceId?: string;
    public deviceModel?: string;
    public deviceConfig?: string;

    public constructor(init?: Partial<AddIotDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddIotDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设置空气检测设备所属班级
*/
// @Route("/iot/allocateairdevicestoclass", "POST, OPTIONS")
// @Api(Description="设置空气检测设备所属班级")
export class SetAirIotDeviceMapReq implements IReturn<BaseResponse>
{
    public airDeviceId?: string;
    public schoolId?: string;
    public branchId?: string;
    public classId?: string;
    public isAllowcate?: boolean;

    public constructor(init?: Partial<SetAirIotDeviceMapReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetAirIotDeviceMapReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设置空气检测设备所属学校
*/
// @Route("/iot/allocateairdevices", "POST, OPTIONS")
// @Api(Description="设置空气检测设备所属学校")
export class SetAirIotDeviceSchoolReq implements IReturn<BaseResponse>
{
    public airDeviceId?: string;
    public schoolId?: string;
    public branchId?: string;

    public constructor(init?: Partial<SetAirIotDeviceSchoolReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetAirIotDeviceSchoolReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 空气检测设备删除
*/
// @Route("/iot/airdevices/delete", "POST, OPTIONS")
// @Api(Description="空气检测设备删除")
export class SetAirDeviceDeleteReq implements IReturn<BaseResponse>
{
    public airDeviceId?: string;
    public isDelete?: boolean;

    public constructor(init?: Partial<SetAirDeviceDeleteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetAirDeviceDeleteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 空气检测设备更新SN
*/
// @Route("/iot/airdevices/updatesn", "POST, OPTIONS")
// @Api(Description="空气检测设备更新SN")
export class UpdateAirDeviceSnReq implements IReturn<BaseResponse>
{
    public mac?: string;

    public constructor(init?: Partial<UpdateAirDeviceSnReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateAirDeviceSnReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加空气检测设备
*/
// @Route("/iot/airdevices", "POST, OPTIONS")
// @Api(Description="添加空气检测设备")
export class AirIotBindDeviceReq implements IReturn<BaseResponse>
{
    public deviceToken?: string;
    public deviceType?: number;
    public isShareDevice?: boolean;

    public constructor(init?: Partial<AirIotBindDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AirIotBindDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取空气检测设备列表
*/
// @Route("/iot/airdevices", "GET, OPTIONS")
// @Api(Description="获取空气检测设备列表")
export class AirIotGetDeviceReq implements IReturn<AirIotGetDeviceRes>
{
    public schoolId?: string;
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<AirIotGetDeviceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AirIotGetDeviceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AirIotGetDeviceRes(); }
}

/**
* 家长获取空气质量简介
*/
// @Route("/iot/airdatasummary", "GET, OPTIONS")
// @Api(Description="家长获取空气质量简介")
export class AirIotParentGetDataSummaryReq implements IReturn<AirIotParentGetDataSummaryRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<AirIotParentGetDataSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AirIotParentGetDataSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AirIotParentGetDataSummaryRes(); }
}

/**
* 获取空气质量详情
*/
// @Route("/iot/airdatainfo", "GET, OPTIONS")
// @Api(Description="获取空气质量详情")
export class AirIotParentGetDataReq implements IReturn<AirIotParentGetDataRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<AirIotParentGetDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AirIotParentGetDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AirIotParentGetDataRes(); }
}

/**
* 修改边缘服务器校区
*/
// @Route("/live/livenode/{NodeId}", "POST, OPTIONS")
// @Api(Description="修改边缘服务器校区")
export class UpdateLiveNodeBranchIdReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public branchId?: string;
    public nodeId?: string;

    public constructor(init?: Partial<UpdateLiveNodeBranchIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateLiveNodeBranchIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加边缘服务器节点
*/
// @Route("/live/livenode", "POST, OPTIONS")
// @Api(Description="添加边缘服务器节点")
export class AddLiveNodeReq implements IReturn<AddLiveNodeRes>
{
    public schoolId?: string;
    public branchId?: string;
    public liveNodeType?: LIVE_NODE_TYPE;
    public name?: string;
    public isActive?: boolean;
    public liveNodeCloudProviderId?: string;

    public constructor(init?: Partial<AddLiveNodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddLiveNodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddLiveNodeRes(); }
}

/**
* 获取边缘服务器节点
*/
// @Route("/live/livenodes", "GET, OPTIONS")
// @Api(Description="获取边缘服务器节点")
export class GetLiveNodeReq implements IReturn<GetLiveNodeRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetLiveNodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveNodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveNodeRes(); }
}

/**
* 添加监控视频通道
*/
// @Route("/live/livechannel", "POST, OPTIONS")
// @Api(Description="添加监控视频通道")
export class AddLiveChannelReq implements IReturn<AddLiveChannelRes>
{
    public schoolId?: string;
    public id?: string;
    public name?: string;
    public liveNodeId?: string;
    public dvrType?: DVR_DEVICE_TYPE;
    public channelNumber?: number;
    public vCodec?: string;
    public dvrIPAddress?: string;
    public dvrManagePort?: number;
    public dvrUserName?: string;
    public dvrPassword?: string;
    public remark?: string;

    public constructor(init?: Partial<AddLiveChannelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddLiveChannelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddLiveChannelRes(); }
}

/**
* 获取监控视频通道
*/
// @Route("/live/livechannels", "GET, OPTIONS")
// @Api(Description="获取监控视频通道")
export class GetLiveChannelsReq implements IReturn<GetLiveChannelsRes>
{
    public schoolId?: string;
    public isFromWeb?: boolean;

    public constructor(init?: Partial<GetLiveChannelsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveChannelsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveChannelsRes(); }
}

/**
* 启动或停止监控视频通道
*/
// @Route("/live/controllivechannel", "POST, OPTIONS")
// @Api(Description="启动或停止监控视频通道")
export class ControlLiveChannelsReq implements IReturn<BaseResponse>
{
    public liveChannelId?: string;
    public isStart?: boolean;

    public constructor(init?: Partial<ControlLiveChannelsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ControlLiveChannelsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 绑定监控视频通道
*/
// @Route("/live/bindlivechannel", "POST, OPTIONS")
// @Api(Description="绑定监控视频通道")
export class BindLiveChannelsReq implements IReturn<BaseResponse>
{
    public liveChannelIds?: string[];
    public schoolId?: string;
    public classId?: string;

    public constructor(init?: Partial<BindLiveChannelsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BindLiveChannelsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除监控视频通道
*/
// @Route("/live/deletelivechannel", "POST, OPTIONS")
// @Api(Description="删除监控视频通道")
export class DeleteLiveChannelReq implements IReturn<BaseResponse>
{
    public liveChannelId?: string;
    public schoolId?: string;

    public constructor(init?: Partial<DeleteLiveChannelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteLiveChannelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取摄像头时间段
*/
// @Route("/live/schedules", "GET, OPTIONS")
// @Api(Description="获取摄像头时间段")
export class GetLiveSchedulesReq implements IReturn<GetLiveSchedulesRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetLiveSchedulesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveSchedulesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveSchedulesRes(); }
}

/**
* 创建摄像头时间段
*/
// @Route("/live/createschedule", "POST, OPTIONS")
// @Api(Description="创建摄像头时间段")
export class CreateLiveScheduleReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public name?: string;
    public openBySchoolCalendar?: boolean;
    public items?: LiveScheduleItemDTO[];

    public constructor(init?: Partial<CreateLiveScheduleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateLiveScheduleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 修改摄像头时间段
*/
// @Route("/live/updateschedule", "POST, OPTIONS")
// @Api(Description="修改摄像头时间段")
export class UpdateLiveScheduleReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public name?: string;
    public scheduleId?: string;
    public openBySchoolCalendar?: boolean;
    public items?: LiveScheduleItemDTO[];

    public constructor(init?: Partial<UpdateLiveScheduleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateLiveScheduleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除摄像头时间段
*/
// @Route("/live/deleteschedule", "DELETE, OPTIONS")
// @Api(Description="删除摄像头时间段")
export class DeleteLiveScheduleReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public scheduleId?: string;

    public constructor(init?: Partial<DeleteLiveScheduleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteLiveScheduleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设置学校或班级摄像头时间段配置
*/
// @Route("/live/setscheduleconfig", "POST, OPTIONS")
// @Api(Description="设置学校或班级摄像头时间段配置")
export class SetLiveScheduleConfigReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public classId?: string;
    public scheduleId?: string;

    public constructor(init?: Partial<SetLiveScheduleConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetLiveScheduleConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校摄像头时间段配置
*/
// @Route("/live/scheduleconfigs", "GET, OPTIONS")
// @Api(Description="获取学校摄像头时间段配置")
export class GetLiveScheduleConfigReq implements IReturn<GetLiveScheduleConfigRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetLiveScheduleConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveScheduleConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveScheduleConfigRes(); }
}

/**
* 获取班级摄像头时间段配置
*/
// @Route("/live/class/{ClassId}/scheduleconfigs", "GET, OPTIONS")
// @Api(Description="获取班级摄像头时间段配置")
export class GetClassLiveScheduleReq implements IReturn<GetClassLiveScheduleRes>
{
    public schoolId?: string;
    public classId?: string;

    public constructor(init?: Partial<GetClassLiveScheduleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassLiveScheduleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassLiveScheduleRes(); }
}

/**
* 腾讯云推流事件通知
*/
// @Route("/live/tencentlivestreamstate", "POST, OPTIONS")
// @Api(Description="腾讯云推流事件通知")
export class TencentLiveStreamStateNotify
{
    public appid?: number;
    public app?: string;
    public appname?: string;
    public stream_id?: string;
    public channel_id?: string;
    public event_time?: number;
    public event_type?: string;
    public sequence?: string;
    public node?: string;
    public user_ip?: string;
    public stream_param?: string;
    public push_duration?: string;
    public errcode?: number;
    public errmsg?: string;
    public set_id?: number;
    public t?: number;
    public sign?: string;

    public constructor(init?: Partial<TencentLiveStreamStateNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TencentLiveStreamStateNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* localvideosave
*/
// @Route("/live/localvideosave", "POST, OPTIONS")
// @Api(Description="localvideosave")
export class AddLocalVideoSaveReq implements IReturn<BaseResponse>
{
    public namespaceId?: string;
    public gbId?: string;
    public channelId?: string;
    public start?: number;
    public end?: number;
    public isStart?: boolean;

    public constructor(init?: Partial<AddLocalVideoSaveReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddLocalVideoSaveReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 创建云端Provider
*/
// @Route("/live/createcloudprovider", "POST, OPTIONS")
// @Api(Description="创建云端Provider")
export class CreateCloudProviderReq implements IReturn<BaseResponse>
{
    public name?: string;
    public provider?: GB_CLOUD_PROVIDER;
    public cloudRegion?: GB_CLOUD_REGION;
    public playDomain?: string;

    public constructor(init?: Partial<CreateCloudProviderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateCloudProviderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* qiniurecordstop
*/
// @Route("/live/qiniurecordstop", "POST, OPTIONS")
// @Api(Description="qiniurecordstop")
export class QiniuRecordStopReq implements IReturn<BaseResponse>
{
    public namespaceId?: string;
    public streamId?: string;

    public constructor(init?: Partial<QiniuRecordStopReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QiniuRecordStopReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 七牛视频监控回调
*/
// @Route("/live/qiniucallback", "POST, OPTIONS")
// @Api(Description="七牛视频监控回调")
export class QiniuLiveCallbackNotify extends QiniuLiveCallbackDTO
{

    public constructor(init?: Partial<QiniuLiveCallbackNotify>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'QiniuLiveCallbackNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 上传excel解析学校
*/
// @Route("/school/{SchoolId}/parseexcel", "POST, OPTIONS")
// @Api(Description="上传excel解析学校")
export class ParseSchoolFromExcelReq implements IReturn<ParseSchoolFromExcelRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public ruleAllowExistsPhoneNumber?: boolean;
    public ruleAllowTeacherClassNameError?: boolean;
    public ruleAllowExistsCourseName?: boolean;
    public ruleAllowKidClassNameError?: boolean;
    public ruleAllowKidClassNameEmpty?: boolean;
    public ruleSyncMode?: boolean;
    public syncModeKidDistinctByName?: boolean;
    public syncModeKidDistinctByBirthDate?: boolean;
    public syncModeKidDistinctByGender?: boolean;
    public syncModeKidDistinctByPhoneNumber?: boolean;

    public constructor(init?: Partial<ParseSchoolFromExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParseSchoolFromExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ParseSchoolFromExcelRes(); }
}

/**
* 通过json验证学校数据
*/
// @Route("/school/{SchoolId}/parseexcel/check", "POST, OPTIONS")
// @Api(Description="通过json验证学校数据")
export class CheckSchoolFromJsonReq implements IReturn<ParseSchoolFromExcelRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public ruleAllowExistsPhoneNumber?: boolean;
    public ruleAllowTeacherClassNameError?: boolean;
    public ruleAllowExistsCourseName?: boolean;
    public ruleAllowKidClassNameError?: boolean;
    public ruleAllowKidClassNameEmpty?: boolean;
    public ruleSyncMode?: boolean;
    public syncModeKidDistinctByName?: boolean;
    public syncModeKidDistinctByBirthDate?: boolean;
    public syncModeKidDistinctByGender?: boolean;
    public syncModeKidDistinctByPhoneNumber?: boolean;
    public data?: ExcelParserSheet[];

    public constructor(init?: Partial<CheckSchoolFromJsonReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckSchoolFromJsonReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ParseSchoolFromExcelRes(); }
}

/**
* 通过json解析学校
*/
// @Route("/school/{SchoolId}/parseexcel/json", "POST, OPTIONS")
// @Api(Description="通过json解析学校")
export class ParseSchoolFromJsonReq implements IReturn<ParseSchoolFromJsonRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public ruleSyncMode?: boolean;
    public data?: ExcelParserSheet[];

    public constructor(init?: Partial<ParseSchoolFromJsonReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ParseSchoolFromJsonReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ParseSchoolFromJsonRes(); }
}

/**
* 导出通过json解析学校的结果
*/
// @Route("/school/{SchoolId}/parseexcel/excel", "POST, OPTIONS")
// @Api(Description="导出通过json解析学校的结果")
export class ExportSchoolFromJsonReq implements IReturn<Blob>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public currentStep?: number;
    public data?: ExcelParserSheet[];

    public constructor(init?: Partial<ExportSchoolFromJsonReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportSchoolFromJsonReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

// @Route("/ai/initface", "GET, OPTIONS")
export class SchoolFaceInitReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public isface?: number;

    public constructor(init?: Partial<SchoolFaceInitReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SchoolFaceInitReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加学期信息
*/
// @Route("/system/terminfos", "POST, OPTIONS")
// @Api(Description="添加学期信息")
export class AddTermInfoReq implements IReturn<CommonReturn>
{
    public termId?: number;
    public startTime?: string;
    public endTime?: string;

    public constructor(init?: Partial<AddTermInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddTermInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 添加应用权限项
*/
// @Route("/aaa/app/permission", "POST, OPTIONS")
// @Api(Description="添加应用权限项")
export class AddAppPermissionReq implements IReturn<BaseResponse>
{
    public eName?: string;
    public name?: string;
    public permissionCode?: string;
    public path?: string;
    public isMenu?: boolean;
    public showInMenu?: boolean;
    public menuPath?: string;
    public menuIcon?: string;
    public menuExact?: boolean;
    public usageArea?: PERMISSION_USEAGE_AREA;
    public orderIndex?: number;
    public moduleCode?: string;
    public showInAppMenu?: boolean;
    public appH5Url?: string;
    public appH5FullScreen?: boolean;
    public appRoute?: string;
    public appMinVersion?: string;
    public appIconUrl?: string;
    public showInAppArea?: string[];
    public appMPEnabled?: boolean;
    public appMPAppId?: string;
    public appMPPath?: string;

    public constructor(init?: Partial<AddAppPermissionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddAppPermissionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加系统权限项
*/
// @Route("/aaa/sys/permission", "POST, OPTIONS")
// @Api(Description="添加系统权限项")
export class AddSystemPermissionReq implements IReturn<BaseResponse>
{
    public name?: string;
    public permissionCode?: string;
    public path?: string;
    public isMenu?: boolean;
    public showInMenu?: boolean;
    public menuPath?: string;
    public menuIcon?: string;
    public menuExact?: boolean;
    public orderIndex?: number;

    public constructor(init?: Partial<AddSystemPermissionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSystemPermissionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 修改应用权限项
*/
// @Route("/aaa/app/permission/update", "POST, OPTIONS")
// @Api(Description="修改应用权限项")
export class UpdateAppPermissionReq implements IReturn<BaseResponse>
{
    public permissionId?: string;
    public eName?: string;
    public name?: string;
    public path?: string;
    public isMenu?: boolean;
    public showInMenu?: boolean;
    public menuPath?: string;
    public menuIcon?: string;
    public menuExact?: boolean;
    public usageArea?: PERMISSION_USEAGE_AREA;
    public orderIndex?: number;
    public moduleCode?: string;
    public showInAppMenu?: boolean;
    public appH5Url?: string;
    public appH5FullScreen?: boolean;
    public appRoute?: string;
    public appMinVersion?: string;
    public appIconUrl?: string;
    public showInAppArea?: string[];
    public appMPEnabled?: boolean;
    public appMPAppId?: string;
    public appMPPath?: string;

    public constructor(init?: Partial<UpdateAppPermissionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateAppPermissionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 修改系统权限项
*/
// @Route("/aaa/sys/permission/update", "POST, OPTIONS")
// @Api(Description="修改系统权限项")
export class UpdateSystemPermissionReq implements IReturn<BaseResponse>
{
    public permissionId?: string;
    public name?: string;
    public path?: string;
    public isMenu?: boolean;
    public showInMenu?: boolean;
    public menuPath?: string;
    public menuIcon?: string;
    public menuExact?: boolean;
    public orderIndex?: number;

    public constructor(init?: Partial<UpdateSystemPermissionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSystemPermissionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加应用角色
*/
// @Route("/aaa/app/role", "POST, OPTIONS")
// @Api(Description="添加应用角色")
export class AddAppRoleReq implements IReturn<BaseResponse>
{
    public name?: string;
    public roleCode?: string;
    public permissionIds?: string[];
    public memo?: string;
    public roleUsageArea?: PERMISSION_USEAGE_AREA;

    public constructor(init?: Partial<AddAppRoleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddAppRoleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新应用角色
*/
// @Route("/aaa/app/role/update", "POST, OPTIONS")
// @Api(Description="更新应用角色")
export class UpdateAppRoleReq implements IReturn<BaseResponse>
{
    public roleConfigId?: string;
    public name?: string;
    public permissionIds?: string[];
    public memo?: string;

    public constructor(init?: Partial<UpdateAppRoleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateAppRoleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除应用角色
*/
// @Route("/aaa/app/role/delete", "POST, OPTIONS")
// @Api(Description="删除应用角色")
export class DeleteAppRoleReq implements IReturn<BaseResponse>
{
    public roleConfigId?: string;

    public constructor(init?: Partial<DeleteAppRoleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteAppRoleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加系统角色
*/
// @Route("/aaa/sys/role", "POST, OPTIONS")
// @Api(Description="添加系统角色")
export class AddSystemRoleReq implements IReturn<BaseResponse>
{
    public name?: string;
    public roleCode?: string;
    public permissionIds?: string[];
    public memo?: string;

    public constructor(init?: Partial<AddSystemRoleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSystemRoleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新系统角色
*/
// @Route("/aaa/sys/role/update", "POST, OPTIONS")
// @Api(Description="更新系统角色")
export class UpdateSystemRoleReq implements IReturn<BaseResponse>
{
    public roleConfigId?: string;
    public name?: string;
    public permissionIds?: string[];
    public memo?: string;

    public constructor(init?: Partial<UpdateSystemRoleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSystemRoleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除系统角色
*/
// @Route("/aaa/sys/role/delete", "POST, OPTIONS")
// @Api(Description="删除系统角色")
export class DeleteSystemRoleReq implements IReturn<BaseResponse>
{
    public roleConfigId?: string;

    public constructor(init?: Partial<DeleteSystemRoleReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSystemRoleReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取系统角色列表
*/
// @Route("/aaa/sys/roles", "GET, OPTIONS")
// @Api(Description="获取系统角色列表")
export class GetSysRolesReq implements IReturn<GetRolesReq>
{

    public constructor(init?: Partial<GetSysRolesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSysRolesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRolesReq(); }
}

/**
* 获取系统权限项
*/
// @Route("/aaa/sys/permissions", "GET, OPTIONS")
// @Api(Description="获取系统权限项")
export class GetSystemPermissionReq implements IReturn<GetPermissionRes>
{
    public useCache?: boolean;

    public constructor(init?: Partial<GetSystemPermissionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSystemPermissionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPermissionRes(); }
}

/**
* 获取应用权限项
*/
// @Route("/aaa/app/permissions", "GET, OPTIONS")
// @Api(Description="获取应用权限项")
export class GetAppPermissionReq implements IReturn<GetPermissionRes>
{
    public schoolId?: string;
    public useCache?: boolean;

    public constructor(init?: Partial<GetAppPermissionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAppPermissionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPermissionRes(); }
}

/**
* 获得管理后台菜单
*/
// @Route("/system/{SchoolId}/menudata", "GET, OPTIONS")
// @Api(Description="获得管理后台菜单")
export class GetManageSiteAppMenuReq implements IReturn<GetManageSiteMenuRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetManageSiteAppMenuReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetManageSiteAppMenuReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetManageSiteMenuRes(); }
}

/**
* 获取系统管理员
*/
// @Route("/aaa/sys/systemusers", "GET, OPTIONS")
// @Api(Description="获取系统管理员")
export class GetSystemUsersReq implements IReturn<GetSystemUsersRes>
{

    public constructor(init?: Partial<GetSystemUsersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSystemUsersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSystemUsersRes(); }
}

/**
* 吊销用户的token
*/
// @Route("/aaa/sys/addusertokenblock", "POST, OPTIONS")
// @Api(Description="吊销用户的token")
export class AddUserTokenBlockReq implements IReturn<BaseResponse>
{
    public userId?: number;

    public constructor(init?: Partial<AddUserTokenBlockReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddUserTokenBlockReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取系统服务模块
*/
// @Route("/aaa/sys/systemservicemoduleconfigs", "GET, OPTIONS")
// @Api(Description="获取系统服务模块")
export class GetSystemServiceModuleConfigsReq implements IReturn<GetSystemServiceModuleConfigsRes>
{
    public useCache?: boolean;

    public constructor(init?: Partial<GetSystemServiceModuleConfigsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSystemServiceModuleConfigsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSystemServiceModuleConfigsRes(); }
}

/**
* 获取系统服务子模块
*/
// @Route("/aaa/sys/systemservicemoduleconfigs/submodules", "GET, OPTIONS")
// @Api(Description="获取系统服务子模块")
export class GetSystemServiceSubModuleConfigsReq implements IReturn<GetSystemServiceSubModuleConfigsRes>
{
    public useCache?: boolean;

    public constructor(init?: Partial<GetSystemServiceSubModuleConfigsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSystemServiceSubModuleConfigsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSystemServiceSubModuleConfigsRes(); }
}

/**
* 添加修改系统服务子模块
*/
// @Route("/aaa/sys/systemservicemoduleconfigs/submodules/add", "POST, OPTIONS")
// @Api(Description="添加修改系统服务子模块")
export class AddSystemServiceSubModuleConfigsReq implements IReturn<AddSystemServiceSubModuleConfigsRes>
{
    public data?: SystemServiceSubModuleConfigDTO;

    public constructor(init?: Partial<AddSystemServiceSubModuleConfigsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSystemServiceSubModuleConfigsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddSystemServiceSubModuleConfigsRes(); }
}

/**
* 设置系统管理员
*/
// @Route("/aaa/sys/systemuser", "POST, OPTIONS")
// @Api(Description="设置系统管理员")
export class SetSystemUserReq implements IReturn<BaseResponse>
{
    public userName?: string;
    public roles?: string[];

    public constructor(init?: Partial<SetSystemUserReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetSystemUserReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除系统管理员
*/
// @Route("/aaa/sys/systemuser", "DELETE, OPTIONS")
// @Api(Description="删除系统管理员")
export class DeleteSystemUserReq implements IReturn<BaseResponse>
{
    public userId?: number;

    public constructor(init?: Partial<DeleteSystemUserReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSystemUserReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 同步用户群信息
*/
// @Route("/im/syncgroup", "POST, OPTIONS")
// @Api(Description="同步用户群信息")
export class SyncIMGroupReq implements IReturn<BaseResponse>
{
    public id?: string;
    public isteacher?: boolean;
    public schoolid?: string;

    public constructor(init?: Partial<SyncIMGroupReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SyncIMGroupReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取IM账号签名
*/
// @Route("/system/im/getsign", "POST, OPTIONS")
// @Api(Description="获取IM账号签名")
export class GetIMServiceAccountSignReq implements IReturn<GetIMServiceAccountSignRes>
{
    public clientId?: string;

    public constructor(init?: Partial<GetIMServiceAccountSignReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIMServiceAccountSignReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIMServiceAccountSignRes(); }
}

/**
* 获取所有版本列表
*/
// @Route("/system/versioninfos", "GET, OPTIONS")
// @Api(Description="获取所有版本列表")
export class GetVersionInfosReq implements IReturn<GetVersionInfosRes>
{

    public constructor(init?: Partial<GetVersionInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetVersionInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetVersionInfosRes(); }
}

export class UpdateDeviceAliasAndTagReq
{
    public appKey?: string;
    public deviceId?: string;
    public userId?: number;
    public classIds?: string[];
    public schoolIds?: string[];

    public constructor(init?: Partial<UpdateDeviceAliasAndTagReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateDeviceAliasAndTagReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class InitUserEventFeedReq
{
    public userId?: number;
    public classId?: string;
    public isTeacher?: boolean;
    public kidId?: string;

    public constructor(init?: Partial<InitUserEventFeedReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InitUserEventFeedReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 发布信息流公告
*/
// @Route("/class/event/msgcard", "POST, OPTIONS")
// @Api(Description="发布信息流公告")
export class AddEventMsgCardReq implements IReturn<CommonReturn>
{
    public buttonText?: string;
    public title?: string;
    public content?: string;
    public imageUrl?: string;
    public linkUrl?: string;
    public author?: string;
    public logoUrl?: string;
    public extraContent?: string;
    public hideOn?: string;
    public schoolIds?: string[];
    public classIds?: string[];
    public kidIds?: string[];

    public constructor(init?: Partial<AddEventMsgCardReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventMsgCardReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取信息流公告
*/
// @Route("/class/event/msgcard", "GET, OPTIONS")
// @Api(Description="获取信息流公告")
export class GetEventMsgCardReq implements IReturn<GetEventMsgCardRes>
{
    public schoolId?: string;
    public classId?: string;
    public termId?: number;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetEventMsgCardReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventMsgCardReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventMsgCardRes(); }
}

export class PointTaskReq
{
    public schoolId?: string;
    public isTeacher?: boolean;
    public uId?: string;
    public actionCode?: string;
    public actionTime?: string;
    public userIPAddr?: string;

    public constructor(init?: Partial<PointTaskReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PointTaskReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class InBoxEventLikeReq
{
    public appType?: APP_TYPE;
    public senderIsTeacher?: boolean;
    public senderId?: string;
    public recvId?: string;
    public eventId?: string;
    public isLike?: boolean;
    public content?: string;
    public createdOn?: string;
    public isAdd?: boolean;

    public constructor(init?: Partial<InBoxEventLikeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InBoxEventLikeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UpdateRedDotReq
{
    public schoolId?: string;
    public targets?: RedDotNotifyTarget[];
    public routePaths?: string[];
    public isReset?: boolean;
    public amount?: number;

    public constructor(init?: Partial<UpdateRedDotReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateRedDotReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class FanoutEventReq
{
    public eventId?: string;
    public cmd?: string;
    public myUserId?: number;
    public userIPAddr?: string;

    public constructor(init?: Partial<FanoutEventReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FanoutEventReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 创建教师积分规则
*/
// @Route("/point/teacher/configs", "POST, OPTIONS")
// @Api(Description="创建教师积分规则")
export class AddTeacherPointTaskConfigReq implements IReturn<BaseResponse>
{
    /**
    * 规则名
    */
    // @ApiMember(DataType="string", Description="规则名", IsRequired=true, Name="Name", ParameterType="query")
    public name?: string;

    /**
    * 描述
    */
    // @ApiMember(DataType="string", Description="描述", IsRequired=true, Name="Desc", ParameterType="query")
    public desc?: string;

    /**
    * 代码
    */
    // @ApiMember(DataType="string", Description="代码", IsRequired=true, Name="Code", ParameterType="query")
    public code?: string;

    /**
    * 跳转链接
    */
    // @ApiMember(DataType="string", Description="跳转链接", Name="LinkUrl", ParameterType="query")
    public linkUrl?: string;

    /**
    * IconUrl
    */
    // @ApiMember(DataType="string", Description="IconUrl", Name="IconUrl", ParameterType="query")
    public iconUrl?: string;

    /**
    * 周期最大次数
    */
    // @ApiMember(DataType="integer", Description="周期最大次数", IsRequired=true, Name="MaxNumber", ParameterType="query")
    public maxNumber: number;

    /**
    * 奖励点数
    */
    // @ApiMember(DataType="integer", Description="奖励点数", IsRequired=true, Name="PointAmount", ParameterType="query")
    public pointAmount: number;

    /**
    * 周期
    */
    // @ApiMember(DataType="string", Description="周期", IsRequired=true, Name="TaskPeriod", ParameterType="query")
    public taskPeriod: POINT_TASK_PERIOD;

    /**
    * 是否启用
    */
    // @ApiMember(DataType="boolean", Description="是否启用", IsRequired=true, Name="IsEnabled", ParameterType="query")
    public isEnabled: boolean;

    public orderIndex?: number;

    public constructor(init?: Partial<AddTeacherPointTaskConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddTeacherPointTaskConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新教师积分规则
*/
// @Route("/point/teacher/config/{TaskId}", "POST, OPTIONS")
// @Api(Description="更新教师积分规则")
export class UpdateTeacherPointTaskConfigReq implements IReturn<BaseResponse>
{
    /**
    * TaskId
    */
    // @ApiMember(DataType="string", Description="TaskId", IsRequired=true, Name="TaskId", ParameterType="path")
    public taskId?: string;

    /**
    * 规则名
    */
    // @ApiMember(DataType="string", Description="规则名", IsRequired=true, Name="Name", ParameterType="query")
    public name?: string;

    /**
    * 描述
    */
    // @ApiMember(DataType="string", Description="描述", IsRequired=true, Name="Desc", ParameterType="query")
    public desc?: string;

    /**
    * 代码
    */
    // @ApiMember(DataType="string", Description="代码", IsRequired=true, Name="Code", ParameterType="query")
    public code?: string;

    /**
    * 跳转链接
    */
    // @ApiMember(DataType="string", Description="跳转链接", Name="LinkUrl", ParameterType="query")
    public linkUrl?: string;

    /**
    * IconUrl
    */
    // @ApiMember(DataType="string", Description="IconUrl", Name="IconUrl", ParameterType="query")
    public iconUrl?: string;

    /**
    * 周期最大次数
    */
    // @ApiMember(DataType="integer", Description="周期最大次数", IsRequired=true, Name="MaxNumber", ParameterType="query")
    public maxNumber: number;

    /**
    * 奖励点数
    */
    // @ApiMember(DataType="integer", Description="奖励点数", IsRequired=true, Name="PointAmount", ParameterType="query")
    public pointAmount: number;

    /**
    * 周期
    */
    // @ApiMember(DataType="string", Description="周期", IsRequired=true, Name="TaskPeriod", ParameterType="query")
    public taskPeriod: POINT_TASK_PERIOD;

    /**
    * 是否启用
    */
    // @ApiMember(DataType="boolean", Description="是否启用", IsRequired=true, Name="IsEnabled", ParameterType="query")
    public isEnabled: boolean;

    public orderIndex?: number;

    public constructor(init?: Partial<UpdateTeacherPointTaskConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateTeacherPointTaskConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取教师积分规则
*/
// @Route("/point/teacher/configs", "GET, OPTIONS")
// @Api(Description="获取教师积分规则")
export class GetTeacherPointTaskConfigsReq implements IReturn<GetTeacherPointTaskConfigsRes>
{
    public showMyCount?: boolean;

    public constructor(init?: Partial<GetTeacherPointTaskConfigsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherPointTaskConfigsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherPointTaskConfigsRes(); }
}

/**
* 获取教师积分
*/
// @Route("/point/teacher/point", "GET, OPTIONS")
// @Api(Description="获取教师积分")
export class GetTeacherPointByUserIdReq implements IReturn<GetTeacherPointByUserIdRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetTeacherPointByUserIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherPointByUserIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherPointByUserIdRes(); }
}

/**
* 获取教师积分Log
*/
// @Route("/point/teacher/pointlogs", "GET, OPTIONS")
// @Api(Description="获取教师积分Log")
export class GetTeacherPointLogsByUserIdReq implements IReturn<GetTeacherPointLogsByUserIdRes>
{
    public schoolId?: string;
    public pageSize?: number;
    public lastId?: string;

    public constructor(init?: Partial<GetTeacherPointLogsByUserIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherPointLogsByUserIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherPointLogsByUserIdRes(); }
}

/**
* 手动运行 ClassTimeSignByTime Job
*/
// @Route("/manage/apitestreq", "POST, OPTIONS")
// @Api(Description="手动运行 ClassTimeSignByTime Job")
export class ManageApiTestReq implements IReturn<BaseResponse>
{
    public api?: string;
    public payload?: { [index: string]: string; };

    public constructor(init?: Partial<ManageApiTestReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageApiTestReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取学校合同列表
*/
// @Route("/manage/schoolcontracts", "GET, OPTIONS")
// @Api(Description="获取学校合同列表")
export class GetSchoolContractsReq implements IReturn<GetSchoolContractsRes>
{
    public schoolId?: string;
    public merchantName?: string;
    public legalName?: string;
    public address?: string;
    public linkMan?: string;
    public linkManPhone?: string;
    public contractNo?: string;
    public contractValue?: number[];
    public firstYearValue?: number[];
    public nextYearValue?: number[];
    public userAmount?: number[];
    public beginDate?: string[];
    public endDate?: string[];
    public myLinkMan?: string;
    public isDelete?: boolean;
    public lastModifyOn?: string[];
    public createOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSchoolContractsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolContractsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolContractsRes(); }
}

/**
* 添加或修改学校合同
*/
// @Route("/manage/schoolcontract", "POST, OPTIONS")
// @Api(Description="添加或修改学校合同")
export class UpdateSchoolContractReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public data?: SchoolContractDTO;

    public constructor(init?: Partial<UpdateSchoolContractReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolContractReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设置学校的会员有效期
*/
// @Route("/school/{SchoolId}/setvipmember", "POST, OPTIONS")
// @Api(Description="设置学校的会员有效期")
export class SetSchoolMemberBuyTypeReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public memberBuyType?: STUDENT_MEMBER_BUY_TYPE;
    public isEnforceMember?: boolean;
    public schoolMemberExpiredOn?: string;

    public constructor(init?: Partial<SetSchoolMemberBuyTypeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetSchoolMemberBuyTypeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取积分商城地址
*/
// @Route("/point/getshopurl", "GET, OPTIONS")
// @Api(Description="获取积分商城地址")
export class GetPointShopUrlReq implements IReturn<GetPointShopUrlRes>
{
    public schoolId?: string;
    public redirectUrl?: string;
    public studentId?: string;

    public constructor(init?: Partial<GetPointShopUrlReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPointShopUrlReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPointShopUrlRes(); }
}

/**
* 积分统计
*/
// @Route("/point/summary", "GET, OPTIONS")
// @Api(Description="积分统计")
export class GetGetTeacherPointSummaryReq implements IReturn<GetGetTeacherPointSummaryRes>
{
    public schoolId?: string;
    public teacherId?: string;
    public beginDate?: string;
    public endDate?: string;
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetGetTeacherPointSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGetTeacherPointSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGetTeacherPointSummaryRes(); }
}

/**
* 积分统计按来源
*/
// @Route("/point/summary/task", "GET, OPTIONS")
// @Api(Description="积分统计按来源")
export class GetGetTeacherPointTaskSummaryReq implements IReturn<GetGetTeacherPointTaskSummaryRes>
{
    public schoolId?: string;
    public teacherId?: string;
    public beginDate?: string;
    public endDate?: string;
    public dimension?: REPORT_DIMENSION;

    public constructor(init?: Partial<GetGetTeacherPointTaskSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGetTeacherPointTaskSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGetTeacherPointTaskSummaryRes(); }
}

/**
* 列表显示老师当前积分
*/
// @Route("/point/teacherpoints", "GET, OPTIONS")
// @Api(Description="列表显示老师当前积分")
export class GetGetTeacherPointsReq implements IReturn<GetGetTeacherPointsRes>
{
    public schoolId?: string;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetGetTeacherPointsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGetTeacherPointsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGetTeacherPointsRes(); }
}

/**
* 列表显示老师积分流水
*/
// @Route("/point/teacherpointlogs", "GET, OPTIONS")
// @Api(Description="列表显示老师积分流水")
export class GetGetTeacherPointLogsReq implements IReturn<GetGetTeacherPointLogsRes>
{
    public schoolId?: string;
    public teacherId?: string;
    public taskId?: string;
    public sourceType?: POINT_SOURCE_TYPE;
    public source?: string;
    public point?: number[];
    public createOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetGetTeacherPointLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGetTeacherPointLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGetTeacherPointLogsRes(); }
}

/**
* 列表显示老师积分兑换流水
*/
// @Route("/point/teacherpointorders", "GET, OPTIONS")
// @Api(Description="列表显示老师积分兑换流水")
export class GetGetTeacherPointOrdersReq implements IReturn<GetGetTeacherPointOrdersRes>
{
    public schoolId?: string;
    public teacherId?: string;
    public shopType?: POINT_SHOP_TYPE;
    public orderState?: POINT_USE_ORDER_STATE;
    public pointAmount?: number[];
    public body?: string;
    public subject?: string;
    public createOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetGetTeacherPointOrdersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGetTeacherPointOrdersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGetTeacherPointOrdersRes(); }
}

/**
* 获取红点信息
*/
// @Route("/school/{SchoolId}/reddotcount", "GET, OPTIONS")
// @Api(Description="获取红点信息")
export class GetRedDotReq implements IReturn<GetRedDotRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetRedDotReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRedDotReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRedDotRes(); }
}

/**
* 获取学生会员记录
*/
// @Route("/school/{SchoolId}/kid/memberlogs", "GET")
// @Api(Description="获取学生会员记录")
export class GetSchoolKidMembersReq implements IReturn<GetSchoolKidMembersRes>
{
    // @Validate(Validator="NotEmpty", Message="Type不可为空")
    public type: KID_MEMBER_TYPE;

    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    public kidName?: string;
    public kidId?: string;
    public classId?: string;
    public createOn?: string[];
    public expireOn?: string[];
    public lastPaymentOn?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSchoolKidMembersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolKidMembersReq'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSchoolKidMembersRes(); }
}

/**
* 修改学生会员记录
*/
// @Route("/school/{SchoolId}/kid/memberlog/edit", "POST, OPTIONS")
// @Api(Description="修改学生会员记录")
export class UpdateSchoolKidMemberReq implements IReturn<BaseResponse>
{
    // @Validate(Validator="NotEmpty", Message="Type不可为空")
    public type: KID_MEMBER_TYPE;

    // @Validate(Validator="NotEmpty", Message="SchoolId不可为空")
    public schoolId?: string;

    // @Validate(Validator="NotEmpty", Message="KidId不可为空")
    public kidId?: string;

    // @Validate(Validator="NotEmpty", Message="ExpireOn不可为空")
    public expireOn?: string;

    public constructor(init?: Partial<UpdateSchoolKidMemberReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolKidMemberReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取点赞与评论列表
*/
// @Route("/msg/eventlikelist", "GET, OPTIONS")
// @Api(Description="获取点赞与评论列表")
export class GetLikeAndCommentListReq implements IReturn<GetLikeAndCommentListRes>
{
    public schoolId?: string;
    public kidId?: string;
    public pageSize?: number;
    public isUnread?: boolean;
    public lastId?: string;

    public constructor(init?: Partial<GetLikeAndCommentListReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLikeAndCommentListReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLikeAndCommentListRes(); }
}

/**
* 获取我的任务列表V2
*/
// @Route("/msg/mytasklistv2", "GET, OPTIONS")
// @Api(Description="获取我的任务列表V2")
export class GetMyTaskListV2Req implements IReturn<GetMyTaskListRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetMyTaskListV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMyTaskListV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMyTaskListRes(); }
}

/**
* 获取我的任务列表
*/
// @Route("/msg/mytasklist", "GET, OPTIONS")
// @Api(Description="获取我的任务列表")
export class GetMyTaskListReq implements IReturn<GetMyTaskListRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetMyTaskListReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMyTaskListReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMyTaskListRes(); }
}

/**
* 获取系统消息列表
*/
// @Route("/msg/mytasklist", "GET, OPTIONS")
// @Api(Description="获取系统消息列表")
export class GetSystemMsgListReq implements IReturn<GetSystemMsgListRes>
{
    public schoolId?: string;
    public kidId?: string;
    public pageSize?: number;
    public lastId?: string;

    public constructor(init?: Partial<GetSystemMsgListReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSystemMsgListReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSystemMsgListRes(); }
}

/**
* 获取目录列表分页
*/
// @Route("/datav/category/page", "GET, OPTIONS")
// @Api(Description="获取目录列表分页")
export class GetDatavCatalogPageReq implements IReturn<GetDatavCatalogPageRes>
{

    public constructor(init?: Partial<GetDatavCatalogPageReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDatavCatalogPageReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDatavCatalogPageRes(); }
}

/**
* 获取目录列表
*/
// @Route("/datav/category/list", "GET, OPTIONS")
// @Api(Description="获取目录列表")
export class GetDatavCatalogReq implements IReturn<GetDatavCatalogRes>
{
    public func?: number;

    public constructor(init?: Partial<GetDatavCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDatavCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDatavCatalogRes(); }
}

/**
* 获取目录详情
*/
// @Route("/datav/category/detail", "GET, OPTIONS")
// @Api(Description="获取目录详情")
export class GetDatavCatalogDetailReq implements IReturn<GetDatavCatalogDetailRes>
{
    public categoryKey?: string;
    public categoryValue?: string;
    public id?: string;
    public isDeleted?: number;

    public constructor(init?: Partial<GetDatavCatalogDetailReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDatavCatalogDetailReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDatavCatalogDetailRes(); }
}

/**
* 新增目录
*/
// @Route("/datav/category/save", "POST, OPTIONS")
// @Api(Description="新增目录")
export class AddDatavCatalogReq implements IReturn<AddDatavCatalogRes>
{
    public categoryKey?: string;
    public categoryValue?: string;

    public constructor(init?: Partial<AddDatavCatalogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddDatavCatalogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddDatavCatalogRes(); }
}

/**
* 复制大屏
*/
// @Route("/datav/visual/copy", "POST, OPTIONS")
// @Api(Description="复制大屏")
export class CopyDataVVisualReq implements IReturn<CopyDataVVisualRes>
{
    public id?: string;

    public constructor(init?: Partial<CopyDataVVisualReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CopyDataVVisualReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CopyDataVVisualRes(); }
}

/**
* 新增大屏
*/
// @Route("/datav/visual/save", "POST, OPTIONS")
// @Api(Description="新增大屏")
export class AddDataVVisualReq implements IReturn<AddDataVVisualRes>
{
    public visual?: DataVVisual;
    public config?: DataVConfig;

    public constructor(init?: Partial<AddDataVVisualReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddDataVVisualReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddDataVVisualRes(); }
}

/**
* 获取大屏详情
*/
// @Route("/datav/visual/detail", "GET, OPTIONS")
// @Api(Description="获取大屏详情")
export class GetDataVVisualDetailReq implements IReturn<GetDataVVisualDetailRes>
{
    public id?: string;

    public constructor(init?: Partial<GetDataVVisualDetailReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDataVVisualDetailReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDataVVisualDetailRes(); }
}

/**
* 上传大屏缩略图
*/
// @Route("/datav/visual/put-file", "POST, OPTIONS")
// @Api(Description="上传大屏缩略图")
export class AddDataVVisualFileReq implements IReturn<AddDataVVisualFileRes>
{

    public constructor(init?: Partial<AddDataVVisualFileReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddDataVVisualFileReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddDataVVisualFileRes(); }
}

/**
* 保存大盘配置
*/
// @Route("/datav/visual/update", "POST, OPTIONS")
// @Api(Description="保存大盘配置")
export class SaveDataVVisualDetailReq implements IReturn<SaveDataVVisualDetailRes>
{
    public visual?: DataVVisual;
    public config?: DataVConfig;

    public constructor(init?: Partial<SaveDataVVisualDetailReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveDataVVisualDetailReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaveDataVVisualDetailRes(); }
}

/**
* 获取大屏列表
*/
// @Route("/datav/visual/list", "GET, OPTIONS")
// @Api(Description="获取大屏列表")
export class GetDataVVisuaListReq implements IReturn<GetDataVVisuaListRes>
{
    public category?: string;

    public constructor(init?: Partial<GetDataVVisuaListReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDataVVisuaListReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDataVVisuaListRes(); }
}

/**
* 获取db列表
*/
// @Route("/datav/db/list", "GET, OPTIONS")
// @Api(Description="获取db列表")
export class GetDataVDBListReq implements IReturn<GetDataVDBListRes>
{

    public constructor(init?: Partial<GetDataVDBListReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDataVDBListReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDataVDBListRes(); }
}

/**
* 获取地图列表
*/
// @Route("/datav/map/list", "GET, OPTIONS")
// @Api(Description="获取地图列表")
export class GetDataVMapListReq implements IReturn<GetDataVMapListRes>
{

    public constructor(init?: Partial<GetDataVMapListReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDataVMapListReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDataVMapListRes(); }
}

/**
* 获取地图详情
*/
// @Route("/datav/map/detail", "GET, OPTIONS")
// @Api(Description="获取地图详情")
export class GetDataVMapDetailReq implements IReturn<GetDataVMapDetailRes>
{
    public id?: string;

    public constructor(init?: Partial<GetDataVMapDetailReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDataVMapDetailReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDataVMapDetailRes(); }
}

/**
* 获取地图Data详情
*/
// @Route("/datav/map/data", "GET, OPTIONS")
// @Api(Description="获取地图Data详情")
export class GetDataVMapDataReq
{
    public id?: string;

    public constructor(init?: Partial<GetDataVMapDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDataVMapDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 新增地图详情
*/
// @Route("/datav/map/save", "POST, OPTIONS")
// @Api(Description="新增地图详情")
export class AddDataVMapReq implements IReturn<AddDataVMapRes>
{
    public name?: string;
    public data?: string;

    public constructor(init?: Partial<AddDataVMapReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddDataVMapReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddDataVMapRes(); }
}

/**
* 学校获取营销模板
*/
// @Route("/marketing/publictemplates", "GET, OPTIONS")
// @Api(Description="学校获取营销模板")
export class GetMarketingPageTemplatesReq implements IReturn<GetMarketingPageTemplatesRes>
{
    public tags?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetMarketingPageTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPageTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPageTemplatesRes(); }
}

/**
* 系统管理员获取营销模板
*/
// @Route("/marketing/alltemplates", "GET, OPTIONS")
// @Api(Description="系统管理员获取营销模板")
export class GetAllMarketingPageTemplatesReq implements IReturn<GetMarketingPageTemplatesRes>
{
    public tags?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetAllMarketingPageTemplatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAllMarketingPageTemplatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPageTemplatesRes(); }
}

/**
* 系统管理保存营销模板
*/
// @Route("/marketing/alltemplates", "POST, OPTIONS")
// @Api(Description="系统管理保存营销模板")
export class SaveMarketingPageTemplateReq implements IReturn<SaveMarketingPageTemplateRes>
{
    public data?: MarketingPageTemplateDTO;

    public constructor(init?: Partial<SaveMarketingPageTemplateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveMarketingPageTemplateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaveMarketingPageTemplateRes(); }
}

/**
* 获取营销模板详情
*/
// @Route("/marketing/template", "GET, OPTIONS")
// @Api(Description="获取营销模板详情")
export class GetMarketingPageTemplateDetailReq implements IReturn<GetMarketingPageTemplateDetailRes>
{
    public templateId?: string;

    public constructor(init?: Partial<GetMarketingPageTemplateDetailReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPageTemplateDetailReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPageTemplateDetailRes(); }
}

/**
* 获取学校营销列表
*/
// @Route("/marketing/pages", "GET, OPTIONS")
// @Api(Description="获取学校营销列表")
export class GetMarketingPagesReq implements IReturn<GetMarketingPagesRes>
{
    public schoolId?: string;
    public title?: string;
    public pageMode?: string;
    public isPublish?: boolean;
    public isDelete?: boolean;
    public beginAt?: string[];
    public endAt?: string[];
    public needPayment?: boolean;
    public fee?: number[];
    public tags?: string[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetMarketingPagesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPagesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPagesRes(); }
}

/**
* 学校设置营销页面的发布状态
*/
// @Route("/marketing/page", "POST, OPTIONS")
// @Api(Description="学校设置营销页面的发布状态")
export class UpdateMarketingPageStatusReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public pageId?: string;
    public isPublish?: boolean;
    public isDelete?: boolean;

    public constructor(init?: Partial<UpdateMarketingPageStatusReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateMarketingPageStatusReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 学校获取我的营销页面详情
*/
// @Route("/marketing/page", "GET, OPTIONS")
// @Api(Description="学校获取我的营销页面详情")
export class GetMarketingPageDetailReq implements IReturn<GetMarketingPageDetailRes>
{
    public schoolId?: string;
    public pageId?: string;
    public templateId?: string;
    public viewMode?: string;

    public constructor(init?: Partial<GetMarketingPageDetailReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPageDetailReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPageDetailRes(); }
}

/**
* 学校保存我的营销页面详情
*/
// @Route("/marketing/pages", "POST, OPTIONS")
// @Api(Description="学校保存我的营销页面详情")
export class SaveMarketingPageReq implements IReturn<SaveMarketingPageTemplateRes>
{
    public data?: MarketingPageDTO;

    public constructor(init?: Partial<SaveMarketingPageReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveMarketingPageReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaveMarketingPageTemplateRes(); }
}

/**
* 家长填写活动表单
*/
// @Route("/marketing/page/feedback", "POST, OPTIONS")
// @Api(Description="家长填写活动表单")
export class AddMarketingFeedbackReq implements IReturn<AddMarketingFeedbackRes>
{
    public marketingPageId?: string;
    public studentName?: string;
    public studentBirth?: string;
    public gender?: string;
    public xiaoquName?: string;
    public contactAddress?: string;
    public parentName?: string;
    public parentRole?: string;
    public phoneNumber?: string;
    public schoolName?: string;
    public isMember?: string;
    public extraDatas?: MarketingFeedExtraItem[];
    public codetype?: string;
    public verifyCode?: string;
    public spreaderId?: string;

    public constructor(init?: Partial<AddMarketingFeedbackReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddMarketingFeedbackReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddMarketingFeedbackRes(); }
}

/**
* 获取家长填写活动表单
*/
// @Route("/marketing/page/feedbacks", "GET, OPTIONS")
// @Api(Description="获取家长填写活动表单")
export class GetMarketingFeedbacksReq implements IReturn<GetMarketingFeedbacksRes>
{
    public schoolId?: string;
    public marketingPageId?: string;
    public studentName?: string;
    public studentBirth?: string[];
    public phoneNumber?: string;
    public gender?: string;
    public parentRole?: string;
    public parentName?: string;
    public contactAddress?: string;
    public xiaoquName?: string;
    public schoolName?: string;
    public isMember?: string;
    public extraDatas?: string;
    public createOn?: string[];
    public needPayment?: boolean;
    public fee?: number[];
    public isPaid?: boolean;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetMarketingFeedbacksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingFeedbacksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingFeedbacksRes(); }
}

/**
* 获取拼团活动详情
*/
// @Route("/marketing/page/pintuan", "GET, OPTIONS")
// @Api(Description="获取拼团活动详情")
export class GetMarketingPinTuanReq implements IReturn<GetMarketingPinTuanRes>
{
    public marketingPageId?: string;
    public pinTuanGroupId?: string;

    public constructor(init?: Partial<GetMarketingPinTuanReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPinTuanReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPinTuanRes(); }
}

/**
* 家长获取拼团列表
*/
// @Route("/marketing/page/pintuan/groups", "POST, OPTIONS")
// @Api(Description="家长获取拼团列表")
export class GetMarketingPinTuanGroupsReq implements IReturn<GetMarketingPinTuanGroupsRes>
{
    public pinTuanConfigId?: string;
    public lastId?: string;
    public pageSize?: number;

    public constructor(init?: Partial<GetMarketingPinTuanGroupsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPinTuanGroupsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPinTuanGroupsRes(); }
}

/**
* 学校获取拼团列表
*/
// @Route("/marketing/page/pintuangroups", "GET, OPTIONS")
// @Api(Description="学校获取拼团列表")
export class GetMarketingSchoolPinTuanGroupsReq implements IReturn<GetMarketingSchoolPinTuanGroupsRes>
{
    public schoolId?: string;
    public marketingPageId?: string;
    public leaderWeChatUserId?: string;
    public memberWeChatUserId?: string;
    public createOn?: string[];
    public expireOn?: string[];
    public finishOn?: string[];
    public groupStatus?: MARKETING_ACTIVITY_GROUP_STATUS;
    public isVirtualGroup?: boolean;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetMarketingSchoolPinTuanGroupsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingSchoolPinTuanGroupsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingSchoolPinTuanGroupsRes(); }
}

/**
* 家长参与拼团活动
*/
// @Route("/marketing/page/pintuan", "POST, OPTIONS")
// @Api(Description="家长参与拼团活动")
export class JoinMarketingPinTuanGroupReq implements IReturn<JoinMarketingPinTuanGroupRes>
{
    public marketingPageId?: string;
    public pinTuanGroupId?: string;
    public isLeader?: boolean;
    public spreaderId?: string;
    public sourceWeChatUserId?: string;

    public constructor(init?: Partial<JoinMarketingPinTuanGroupReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'JoinMarketingPinTuanGroupReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new JoinMarketingPinTuanGroupRes(); }
}

/**
* 获取抢购活动详情
*/
// @Route("/marketing/page/qianggou", "GET, OPTIONS")
// @Api(Description="获取抢购活动详情")
export class GetMarketingQiangGouReq implements IReturn<GetMarketingQiangGouRes>
{
    public marketingPageId?: string;
    public activityOrderId?: string;

    public constructor(init?: Partial<GetMarketingQiangGouReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingQiangGouReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingQiangGouRes(); }
}

/**
* 家长参与抢购活动
*/
// @Route("/marketing/page/qianggou", "POST, OPTIONS")
// @Api(Description="家长参与抢购活动")
export class MarketingQiangGouReq implements IReturn<MarketingQiangGouRes>
{
    public marketingPageId?: string;
    public productId?: string;
    public spreaderId?: string;
    public sourceWeChatUserId?: string;

    public constructor(init?: Partial<MarketingQiangGouReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarketingQiangGouReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MarketingQiangGouRes(); }
}

/**
* 家长活动助力（砍一刀）
*/
// @Route("/marketing/page/zhuli", "POST, OPTIONS")
// @Api(Description="家长活动助力（砍一刀）")
export class AddMarketingZhuliReq implements IReturn<AddMarketingZhuliRes>
{
    public marketingPageId?: string;
    public activityOrderId?: string;

    public constructor(init?: Partial<AddMarketingZhuliReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddMarketingZhuliReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddMarketingZhuliRes(); }
}

/**
* 家长活动支付请求prepaydata（通过小程序）
*/
// @Route("/marketing/page/orderprepayfromwxlite", "POST, OPTIONS")
// @Api(Description="家长活动支付请求prepaydata（通过小程序）")
export class GetMarketingPrePayCodeFromWxLiteReq implements IReturn<GetMarketingPrePayCodeFromWxLiteRes>
{
    public marketingPageId?: string;
    public activityOrderId?: string;
    public paymentFrom?: string;
    public code?: string;

    public constructor(init?: Partial<GetMarketingPrePayCodeFromWxLiteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPrePayCodeFromWxLiteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPrePayCodeFromWxLiteRes(); }
}

/**
* 家长活动支付请求prepaydata
*/
// @Route("/marketing/page/orderprepay", "POST, OPTIONS")
// @Api(Description="家长活动支付请求prepaydata")
export class GetMarketingPrePayCodeReq implements IReturn<GetMarketingPrePayCodeRes>
{
    public marketingPageId?: string;
    public activityOrderId?: string;
    public paymentFrom?: string;

    public constructor(init?: Partial<GetMarketingPrePayCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPrePayCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPrePayCodeRes(); }
}

/**
* 家长获取支付结果
*/
// @Route("/marketing/page/goldplan", "GET, OPTIONS")
// @Api(Description="家长获取支付结果")
export class GetWeixinPayGoldPlanReq implements IReturn<GetWeixinPayGoldPlanRes>
{
    public subMchId?: string;
    public outTradeNo?: string;
    public checkCode?: string;

    public constructor(init?: Partial<GetWeixinPayGoldPlanReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWeixinPayGoldPlanReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetWeixinPayGoldPlanRes(); }
}

/**
* 家长获取自己的订单列表
*/
// @Route("/marketing/order/my", "GET, OPTIONS")
// @Api(Description="家长获取自己的订单列表")
export class GetMarketingParentOrdersReq implements IReturn<GetMarketingParentOrdersRes>
{
    public schoolId?: string;
    public createOn?: string[];

    public constructor(init?: Partial<GetMarketingParentOrdersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingParentOrdersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingParentOrdersRes(); }
}

/**
* 获取家长的订单列表
*/
// @Route("/marketing/page/orders", "GET, OPTIONS")
// @Api(Description="获取家长的订单列表")
export class GetMarketingSchoolOrdersReq implements IReturn<GetMarketingSchoolOrdersRes>
{
    public activityType?: MARKETING_ACTIVITY_TYPE;
    public schoolId?: string;
    public marketingPageId?: string;
    public productId?: string;
    public weChatUserId?: string;
    public spreaderId?: string;
    public createOn?: string[];
    public expireOn?: string[];
    public finishOn?: string[];
    public groupStatus?: MARKETING_ACTIVITY_GROUP_STATUS;
    public orderId?: string;
    public price?: number[];
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetMarketingSchoolOrdersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingSchoolOrdersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingSchoolOrdersRes(); }
}

/**
* 批量确认订单
*/
// @Route("/marketing/order/confirm", "POST, OPTIONS")
// @Api(Description="批量确认订单")
export class MarketingOrdersConfirmReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public orderIds?: string[];
    public isAllow?: boolean;

    public constructor(init?: Partial<MarketingOrdersConfirmReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarketingOrdersConfirmReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 批量确认拼团
*/
// @Route("/marketing/pintuangroup/confirm", "POST, OPTIONS")
// @Api(Description="批量确认拼团")
export class MarketingPinTuanGroupConfirmReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public groupIds?: string[];
    public isAllow?: boolean;

    public constructor(init?: Partial<MarketingPinTuanGroupConfirmReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarketingPinTuanGroupConfirmReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 导出活动订单详情
*/
// @Route("/marketing/page/orders/excel", "GET, OPTIONS")
// @Api(Description="导出活动订单详情")
export class GetMarketingActivityOrderToExcelReq implements IReturn<Blob>
{
    public schoolId?: string;
    public activityType?: MARKETING_ACTIVITY_TYPE;
    public marketingPageId?: string;

    public constructor(init?: Partial<GetMarketingActivityOrderToExcelReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingActivityOrderToExcelReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

export class ActivityOrderRefundNotifyReq
{
    public orderId?: string;

    public constructor(init?: Partial<ActivityOrderRefundNotifyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ActivityOrderRefundNotifyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 活动统计计数
*/
// @Route("/marketing/page/count", "GET, OPTIONS")
// @Api(Description="活动统计计数")
export class GetMarketingPageCountReq implements IReturn<GetMarketingPageCountRes>
{
    public marketingPageId?: string;
    public realTime?: number;

    public constructor(init?: Partial<GetMarketingPageCountReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingPageCountReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingPageCountRes(); }
}

/**
* 页面计数
*/
// @Route("/common/page/count", "GET, OPTIONS")
// @Api(Description="页面计数")
export class GetPageViewCountReq implements IReturn<GetPageViewCountRes>
{
    public pageId?: string;
    public realTime?: number;

    public constructor(init?: Partial<GetPageViewCountReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPageViewCountReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPageViewCountRes(); }
}

/**
* 活动订单人物关系图
*/
// @Route("/marketing/page/ordergraph", "GET, OPTIONS")
// @Api(Description="活动订单人物关系图")
export class GetMarketingOrderGraphReq implements IReturn<GetMarketingOrderGraphRes>
{
    public schoolId?: string;
    public marketingPageId?: string;

    public constructor(init?: Partial<GetMarketingOrderGraphReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMarketingOrderGraphReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMarketingOrderGraphRes(); }
}

/**
* 获取自己的推广ID
*/
// @Route("/marketing/spreader/myid", "GET, OPTIONS")
// @Api(Description="获取自己的推广ID")
export class GetMySpreaderIdReq implements IReturn<GetMySpreaderIdRes>
{
    public schoolId?: string;
    public kidId?: string;
    public marketingPageId?: string;

    public constructor(init?: Partial<GetMySpreaderIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMySpreaderIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMySpreaderIdRes(); }
}

/**
* 获取学生当前可用的学校积分、点券奖励
*/
// @Route("/privategift/kid", "GET, OPTIONS")
// @Api(Description="获取学生当前可用的学校积分、点券奖励")
export class GetPrivateGiftForKidReq implements IReturn<GetPrivateGiftForKidRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetPrivateGiftForKidReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPrivateGiftForKidReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPrivateGiftForKidRes(); }
}

/**
* 获取学校积分奖励统计
*/
// @Route("/privategift/summary", "GET, OPTIONS")
// @Api(Description="获取学校积分奖励统计")
export class GetPrivateGiftSummaryReq implements IReturn<GetPrivateGiftSummaryRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetPrivateGiftSummaryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPrivateGiftSummaryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPrivateGiftSummaryRes(); }
}

/**
* 按日期获取学校积分奖励统计
*/
// @Route("/privategift/summary/date", "GET, OPTIONS")
// @Api(Description="按日期获取学校积分奖励统计")
export class GetPrivateGiftSummaryByDateReq implements IReturn<GetPrivateGiftSummaryByDateRes>
{
    public schoolId?: string;
    public kidId?: string;
    public createOn?: string[];

    public constructor(init?: Partial<GetPrivateGiftSummaryByDateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPrivateGiftSummaryByDateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPrivateGiftSummaryByDateRes(); }
}

/**
* 获取积分日志
*/
// @Route("/privategift/point/logs", "GET, OPTIONS")
// @Api(Description="获取积分日志")
export class GetPrivateGiftPointLogsReq implements IReturn<GetPrivateGiftPointLogsRes>
{
    public schoolId?: string;
    public kidId?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];

    public constructor(init?: Partial<GetPrivateGiftPointLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPrivateGiftPointLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPrivateGiftPointLogsRes(); }
}

/**
* 获取点券日志
*/
// @Route("/privategift/card/logs", "GET, OPTIONS")
// @Api(Description="获取点券日志")
export class GetPrivateGiftCardLogsReq implements IReturn<GetPrivateGiftCardLogsRes>
{
    public schoolId?: string;
    public kidId?: string;
    public createOn?: string[];

    public constructor(init?: Partial<GetPrivateGiftCardLogsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPrivateGiftCardLogsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPrivateGiftCardLogsRes(); }
}

/**
* 手动虚拟成团
*/
// @Route("/marketing/processpintuanvirtualmember", "POST, OPTIONS")
// @Api(Description="手动虚拟成团")
export class ProcessPinTuanVirtualMemberReq implements IReturn<BaseResponse>
{
    public groupId?: string;

    public constructor(init?: Partial<ProcessPinTuanVirtualMemberReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcessPinTuanVirtualMemberReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取有赞成长册集商品配置
*/
// @Route("/shop/youzanbookgoodconfigs", "GET, OPTIONS")
// @Api(Description="获取有赞成长册集商品配置")
export class GetYouzanBookGoodConfigsReq implements IReturn<GetYouzanBookGoodConfigsRes>
{
    public bookType?: BOOKORDER_TYPE;

    public constructor(init?: Partial<GetYouzanBookGoodConfigsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetYouzanBookGoodConfigsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetYouzanBookGoodConfigsRes(); }
}

/**
* 获取成长集信息
*/
// @Route("/shop/school/{SchoolId}/kidbookorders", "GET, OPTIONS")
// @Api(Description="获取成长集信息")
export class GetBookOrderKidInfoReq implements IReturn<GetBookOrderKidInfoRes>
{
    public schoolId?: string;
    public termId?: number;
    public kidIds?: string[];
    public filterOrderState?: BOOKORDER_STATS;

    public constructor(init?: Partial<GetBookOrderKidInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookOrderKidInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBookOrderKidInfoRes(); }
}

/**
* 获取成长集订单列表
*/
// @Route("/shop/bookorders", "GET, OPTIONS")
// @Api(Description="获取成长集订单列表")
export class GetBookOrdersReq implements IReturn<GetBookOrdersRes>
{
    public pageSize?: number;
    public pageIndex?: number;
    public schoolId?: string;
    public filterOrderState?: string;

    public constructor(init?: Partial<GetBookOrdersReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookOrdersReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBookOrdersRes(); }
}

/**
* 创建pdf生成任务
*/
// @Route("/shop/makepdffile", "POST, OPTIONS")
// @Api(Description="创建pdf生成任务")
export class MakeOrderPdfReq implements IReturn<CommonReturn>
{
    public bookOrderId?: string;
    public refreshCache?: boolean;

    public constructor(init?: Partial<MakeOrderPdfReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MakeOrderPdfReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 获取pdf地址
*/
// @Route("/shop/getpdfurl", "GET, OPTIONS")
// @Api(Description="获取pdf地址")
export class GetPdfUrlReq implements IReturn<GetPdfUrlRes>
{
    public bookOrderId?: string;

    public constructor(init?: Partial<GetPdfUrlReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPdfUrlReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPdfUrlRes(); }
}

/**
* 添加有赞成长册集商品配置
*/
// @Route("/shop/youzanbookgoodconfigs", "POST, OPTIONS")
// @Api(Description="添加有赞成长册集商品配置")
export class CreateYouzanBookGoodConfigReq implements IReturn<CommonReturn>
{
    /**
    * 名称
    */
    // @ApiMember(DataType="string", Description="名称", IsRequired=true, Name="Name", ParameterType="query")
    public name?: string;

    /**
    * 有赞商品别名
    */
    // @ApiMember(DataType="string", Description="有赞商品别名", IsRequired=true, Name="YouzanAlias", ParameterType="query")
    public youzanAlias?: string;

    /**
    * 小程序路径
    */
    // @ApiMember(DataType="string", Description="小程序路径", IsRequired=true, Name="YouzanMiniProgramePath", ParameterType="query")
    public youzanMiniProgramePath?: string;

    /**
    * 最小页数
    */
    // @ApiMember(DataType="integer", Description="最小页数", IsRequired=true, Name="PageMin", ParameterType="query")
    public pageMin: number;

    /**
    * 最大页数
    */
    // @ApiMember(DataType="integer", Description="最大页数", IsRequired=true, Name="PageMax", ParameterType="query")
    public pageMax: number;

    /**
    * 商品单价
    */
    // @ApiMember(DataType="number", Description="商品单价", IsRequired=true, Name="Price", ParameterType="query")
    public price: number;

    /**
    * 有赞商品id
    */
    // @ApiMember(DataType="string", Description="有赞商品id", IsRequired=true, Name="YouzanItemId", ParameterType="query")
    public youzanItemId?: string;

    /**
    * 商品图片url
    */
    // @ApiMember(DataType="string", Description="商品图片url", IsRequired=true, Name="ProductImageUrl", ParameterType="query")
    public productImageUrl?: string;

    /**
    * 相册类型
    */
    // @ApiMember(DataType="string", Description="相册类型", IsRequired=true, Name="BookType", ParameterType="query")
    public bookType: BOOKORDER_TYPE;

    /**
    * 相册型号
    */
    // @ApiMember(DataType="string", Description="相册型号", IsRequired=true, Name="BookEdition", ParameterType="query")
    public bookEdition: BOOKORDER_EDITION;

    /**
    * 下单页面主背景图URL
    */
    // @ApiMember(DataType="string", Description="下单页面主背景图URL", IsRequired=true, Name="EditionMainBackgroundUrl", ParameterType="query")
    public editionMainBackgroundUrl?: string;

    /**
    * 下单页面详情图片url
    */
    // @ApiMember(DataType="string", Description="下单页面详情图片url", IsRequired=true, Name="EditionDetailImageUrl", ParameterType="query")
    public editionDetailImageUrl?: string;

    /**
    * 单次最大可购买数量
    */
    // @ApiMember(DataType="integer", Description="单次最大可购买数量", IsRequired=true, Name="MaxNum", ParameterType="query")
    public maxNum: number;

    /**
    * 商品标题
    */
    // @ApiMember(DataType="string", Description="商品标题", IsRequired=true, Name="BookEditionTitle", ParameterType="query")
    public bookEditionTitle?: string;

    public constructor(init?: Partial<CreateYouzanBookGoodConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateYouzanBookGoodConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新有赞成长册集商品配置
*/
// @Route("/shop/youzanbookgoodconfig/{ConfigId}", "POST, OPTIONS")
// @Api(Description="更新有赞成长册集商品配置")
export class UpdateYouzanBookGoodConfigReq implements IReturn<CommonReturn>
{
    /**
    * ConfigId
    */
    // @ApiMember(DataType="string", Description="ConfigId", IsRequired=true, Name="ConfigId", ParameterType="path")
    public configId?: string;

    /**
    * 名称
    */
    // @ApiMember(DataType="string", Description="名称", IsRequired=true, Name="Name", ParameterType="query")
    public name?: string;

    /**
    * 有赞商品别名
    */
    // @ApiMember(DataType="string", Description="有赞商品别名", IsRequired=true, Name="YouzanAlias", ParameterType="query")
    public youzanAlias?: string;

    /**
    * 小程序路径
    */
    // @ApiMember(DataType="string", Description="小程序路径", IsRequired=true, Name="YouzanMiniProgramePath", ParameterType="query")
    public youzanMiniProgramePath?: string;

    /**
    * 最小页数
    */
    // @ApiMember(DataType="integer", Description="最小页数", IsRequired=true, Name="PageMin", ParameterType="query")
    public pageMin: number;

    /**
    * 最大页数
    */
    // @ApiMember(DataType="integer", Description="最大页数", IsRequired=true, Name="PageMax", ParameterType="query")
    public pageMax: number;

    /**
    * 商品单价
    */
    // @ApiMember(DataType="number", Description="商品单价", IsRequired=true, Name="Price", ParameterType="query")
    public price: number;

    /**
    * 有赞商品id
    */
    // @ApiMember(DataType="string", Description="有赞商品id", IsRequired=true, Name="YouzanItemId", ParameterType="query")
    public youzanItemId?: string;

    /**
    * 商品图片url
    */
    // @ApiMember(DataType="string", Description="商品图片url", IsRequired=true, Name="ProductImageUrl", ParameterType="query")
    public productImageUrl?: string;

    /**
    * 相册类型
    */
    // @ApiMember(DataType="string", Description="相册类型", IsRequired=true, Name="BookType", ParameterType="query")
    public bookType: BOOKORDER_TYPE;

    /**
    * 相册型号
    */
    // @ApiMember(DataType="string", Description="相册型号", IsRequired=true, Name="BookEdition", ParameterType="query")
    public bookEdition: BOOKORDER_EDITION;

    /**
    * 下单页面主背景图URL
    */
    // @ApiMember(DataType="string", Description="下单页面主背景图URL", IsRequired=true, Name="EditionMainBackgroundUrl", ParameterType="query")
    public editionMainBackgroundUrl?: string;

    /**
    * 下单页面详情图片url
    */
    // @ApiMember(DataType="string", Description="下单页面详情图片url", IsRequired=true, Name="EditionDetailImageUrl", ParameterType="query")
    public editionDetailImageUrl?: string;

    /**
    * 单次最大可购买数量
    */
    // @ApiMember(DataType="integer", Description="单次最大可购买数量", IsRequired=true, Name="MaxNum", ParameterType="query")
    public maxNum: number;

    /**
    * 商品标题
    */
    // @ApiMember(DataType="string", Description="商品标题", IsRequired=true, Name="BookEditionTitle", ParameterType="query")
    public bookEditionTitle?: string;

    public constructor(init?: Partial<UpdateYouzanBookGoodConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateYouzanBookGoodConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 修改子商户分润
*/
// @Route("/payment/paymentsubmchinfo/shareconfig", "POST, OPTIONS")
// @Api(Description="修改子商户分润")
export class UpdatePaymentSubMchInfoShareConfigReq implements IReturn<BaseResponse>
{
    public id?: string;
    public shareConfigs?: PaymentSubMchShareConfigDTO[];

    public constructor(init?: Partial<UpdatePaymentSubMchInfoShareConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatePaymentSubMchInfoShareConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 查询子商户进件及入驻状态
*/
// @Route("/payment/queryenterstatus", "POST, OPTIONS")
// @Api(Description="查询子商户进件及入驻状态")
export class QuerySftEnterStatusReq implements IReturn<BaseResponse>
{
    public id?: string;

    public constructor(init?: Partial<QuerySftEnterStatusReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuerySftEnterStatusReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 查询子商户盛付通电子合同
*/
// @Route("/payment/querysftcontract", "POST, OPTIONS")
// @Api(Description="查询子商户盛付通电子合同")
export class QuerySftContractReq implements IReturn<QuerySftContractRes>
{
    public id?: string;

    public constructor(init?: Partial<QuerySftContractReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuerySftContractReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QuerySftContractRes(); }
}

/**
* 查询子商户申请状态
*/
// @Route("/payment/queryregisterstatus", "POST, OPTIONS")
// @Api(Description="查询子商户申请状态")
export class QuerySftRegisterStatusReq implements IReturn<BaseResponse>
{
    public id?: string;

    public constructor(init?: Partial<QuerySftRegisterStatusReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QuerySftRegisterStatusReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 上传子商户图片接口
*/
// @Route("/payment/uploadbizimage", "POST, OPTIONS")
// @Api(Description="上传子商户图片接口")
export class UploadBizImageToBankReq implements IReturn<BaseResponse>
{
    public id?: string;

    public constructor(init?: Partial<UploadBizImageToBankReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadBizImageToBankReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 重置子商户接口审核状态
*/
// @Route("/payment/resetpaymentsubmchinfoapplystate", "POST, OPTIONS")
// @Api(Description="重置子商户接口审核状态")
export class ResetPaymentSubMchInfoApplyStateReq implements IReturn<BaseResponse>
{
    public id?: string;

    public constructor(init?: Partial<ResetPaymentSubMchInfoApplyStateReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResetPaymentSubMchInfoApplyStateReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 审核子商户接口
*/
// @Route("/payment/applypaymentsubmchinfo", "POST, OPTIONS")
// @Api(Description="审核子商户接口")
export class ApplyPaymentSubMchInfoReq implements IReturn<BaseResponse>
{
    public id?: string;
    public applyDesc?: string;
    public isSendToBank?: boolean;

    public constructor(init?: Partial<ApplyPaymentSubMchInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ApplyPaymentSubMchInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 设置子商户是否分帐方
*/
// @Route("/payment/paymentsubmchinfo/sharereceiver", "POST, OPTIONS")
// @Api(Description="设置子商户是否分帐方")
export class PaymentSubMchInfoSetShareReceiverReq implements IReturn<BaseResponse>
{
    public id?: string;
    public subMchId?: string;
    public isShareReceiver?: boolean;

    public constructor(init?: Partial<PaymentSubMchInfoSetShareReceiverReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PaymentSubMchInfoSetShareReceiverReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 添加苹果IAP代码配置
*/
// @Route("/payment/iapcode", "POST, OPTIONS")
// @Api(Description="添加苹果IAP代码配置")
export class AddOrderIAPCodeReq implements IReturn<BaseResponse>
{
    public name?: string;
    public code?: string;

    public constructor(init?: Partial<AddOrderIAPCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddOrderIAPCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除苹果IAP代码配置
*/
// @Route("/payment/iapcode/{IapCodeId}", "DELETE, OPTIONS")
// @Api(Description="删除苹果IAP代码配置")
export class DeleteOrderIAPCodeReq implements IReturn<BaseResponse>
{
    public iapCodeId?: string;

    public constructor(init?: Partial<DeleteOrderIAPCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteOrderIAPCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取苹果IAP代码配置
*/
// @Route("/payment/iapcodes", "GET, OPTIONS")
// @Api(Description="获取苹果IAP代码配置")
export class GetOrderIAPCodesReq implements IReturn<GetOrderIAPCodesRes>
{

    public constructor(init?: Partial<GetOrderIAPCodesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrderIAPCodesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderIAPCodesRes(); }
}

/**
* 获取支付产品信息接口
*/
// @Route("/payment/products", "GET, OPTIONS")
// @Api(Description="获取支付产品信息接口")
export class GetOrderProductsReq implements IReturn<GetOrderProductsRes>
{
    public schoolId?: string;
    public catalog?: ORDERPRODUCT_CATALOG;

    public constructor(init?: Partial<GetOrderProductsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrderProductsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderProductsRes(); }
}

/**
* 添加支付产品信息接口
*/
// @Route("/payment/products", "POST, OPTIONS")
// @Api(Description="添加支付产品信息接口")
export class AddOrderProductReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public productName?: string;
    public iapCode?: string;
    public catalog?: ORDERPRODUCT_CATALOG;
    public iosPrice?: number;
    public price?: number;
    public amount?: number;
    public expireOn?: string;

    public constructor(init?: Partial<AddOrderProductReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddOrderProductReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新支付产品信息接口
*/
// @Route("/payment/product/{ProductId}", "POST, OPTIONS")
// @Api(Description="更新支付产品信息接口")
export class UpdateOrderProductReq implements IReturn<BaseResponse>
{
    public productId?: string;
    public productName?: string;
    public iapCode?: string;
    public iosPrice?: number;
    public price?: number;
    public amount?: number;
    public expireOn?: string;

    public constructor(init?: Partial<UpdateOrderProductReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateOrderProductReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 重试盛付通分账
*/
// @Route("/payment/order/retryshengpaysharing", "POST, OPTIONS")
// @Api(Description="重试盛付通分账")
export class RetryShengPaySharingReq implements IReturn<BaseResponse>
{
    public orderId?: string;

    public constructor(init?: Partial<RetryShengPaySharingReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RetryShengPaySharingReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 删除支付产品信息接口
*/
// @Route("/payment/product/{ProductId}", "DELETE, OPTIONS")
// @Api(Description="删除支付产品信息接口")
export class DeleteOrderProductReq implements IReturn<BaseResponse>
{
    public productId?: string;

    public constructor(init?: Partial<DeleteOrderProductReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteOrderProductReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取订单详情列表
*/
// @Route("/payment/orders", "GET, OPTIONS")
// @Api(Description="获取订单详情列表")
export class GetOrderInfosReq implements IReturn<GetOrderInfosRes>
{
    public schoolId?: string;
    public orderNo?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public payedOn?: string[];
    public userId?: number;
    public kidName?: string;
    public kidId?: string;
    public catalog?: ORDERPRODUCT_CATALOG;
    public paymentType?: ORDERPAYMENT_TYPE;
    public productName?: string;
    public orderStatus?: string;
    public transactionId?: string;
    public shareState?: ORDER_SHARE_STATE;
    public isRefund?: boolean;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetOrderInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrderInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderInfosRes(); }
}

/**
* 获取订单详情列表
*/
// @Route("/payment/orders/excel", "GET, OPTIONS")
// @Api(Description="获取订单详情列表")
export class GetOrderInfosReqExcel implements IReturn<Blob>
{
    public schoolId?: string;
    public orderNo?: string;
    public createOn?: string[];
    public lastModifyOn?: string[];
    public payedOn?: string[];
    public userId?: number;
    public kidId?: string;
    public catalog?: ORDERPRODUCT_CATALOG;
    public paymentType?: ORDERPAYMENT_TYPE;
    public productName?: string;
    public orderStatus?: string;
    public transactionId?: string;
    public shareState?: ORDER_SHARE_STATE;

    public constructor(init?: Partial<GetOrderInfosReqExcel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrderInfosReqExcel'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Blob(); }
}

/**
* 获取学生会员列表
*/
// @Route("/payment/orders", "GET, OPTIONS")
// @Api(Description="获取学生会员列表")
export class GetLiveMemberReq implements IReturn<GetLiveMemberRes>
{
    public schoolId?: string;
    public kidId?: string;
    public createOn?: string[];
    public expireOn?: string[];
    public lastPaymentOn?: string[];
    public isTrial?: boolean;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetLiveMemberReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLiveMemberReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLiveMemberRes(); }
}

/**
* 微信通知回调接口
*/
// @Route("/payment/wechatnotify", "POST, OPTIONS")
// @Api(Description="微信通知回调接口")
export class WeChatPayOrderReq
{

    public constructor(init?: Partial<WeChatPayOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WeChatPayOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 微信支付通知回调接口MOCK
*/
// @Route("/payment/tobwechatnotifymock", "POST, OPTIONS")
// @Api(Description="微信支付通知回调接口MOCK")
export class WeChatSchoolPayOrderMockReq implements IReturn<BaseResponse>
{
    public dto?: WeChatPayOrderDataV3Mock;

    public constructor(init?: Partial<WeChatSchoolPayOrderMockReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WeChatSchoolPayOrderMockReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 微信通知回调接口
*/
// @Route("/payment/tobwechatnotify", "POST, OPTIONS")
// @Api(Description="微信通知回调接口")
export class WeChatSchoolPayOrderReq
{

    public constructor(init?: Partial<WeChatSchoolPayOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WeChatSchoolPayOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 盛付通子商户创建通知回调接口
*/
// @Route("/payment/shengpaysubmchapplynotify", "POST, OPTIONS")
// @Api(Description="盛付通子商户创建通知回调接口")
export class ShengPayCreateSubMchNotify
{

    public constructor(init?: Partial<ShengPayCreateSubMchNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShengPayCreateSubMchNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 盛付通子商户提现通知回调接口MOCK
*/
// @Route("/payment/shengpaysubmchwithdrawmocknotify", "POST, OPTIONS")
// @Api(Description="盛付通子商户提现通知回调接口MOCK")
export class ShengPaySubMchWithDrawMockNotify implements IReturn<BaseResponse>
{
    public dto?: SftWithDrawNotify;

    public constructor(init?: Partial<ShengPaySubMchWithDrawMockNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShengPaySubMchWithDrawMockNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 盛付通子商户提现通知回调接口
*/
// @Route("/payment/shengpaysubmchwithdrawnotify", "POST, OPTIONS")
// @Api(Description="盛付通子商户提现通知回调接口")
export class ShengPaySubMchWithDrawNotify
{

    public constructor(init?: Partial<ShengPaySubMchWithDrawNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShengPaySubMchWithDrawNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 盛付通支付退款通知回调接口MOCK
*/
// @Route("/payment/shengpayrefundnotifymock", "POST, OPTIONS")
// @Api(Description="盛付通支付退款通知回调接口MOCK")
export class ShengPayRefundMockNotify implements IReturn<BaseResponse>
{
    public dto?: ShengPayOrderRefundOrderDTO;

    public constructor(init?: Partial<ShengPayRefundMockNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShengPayRefundMockNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 盛付通支付通知回调接口MOCK
*/
// @Route("/payment/shengpaynotifymock", "POST, OPTIONS")
// @Api(Description="盛付通支付通知回调接口MOCK")
export class ShengPayOrderMockNotify implements IReturn<BaseResponse>
{
    public dto?: ShengPayOrderDTO;

    public constructor(init?: Partial<ShengPayOrderMockNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShengPayOrderMockNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 盛付通退款通知回调接口
*/
// @Route("/payment/shengpayrefundnotify", "POST, OPTIONS")
// @Api(Description="盛付通退款通知回调接口")
export class ShengPayRefundNotify
{

    public constructor(init?: Partial<ShengPayRefundNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShengPayRefundNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 盛付通支付通知回调接口
*/
// @Route("/payment/shengpaynotify", "POST, OPTIONS")
// @Api(Description="盛付通支付通知回调接口")
export class ShengPayOrderNotify
{

    public constructor(init?: Partial<ShengPayOrderNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShengPayOrderNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 更新学生储值账户余额
*/
// @Route("/school/{SchoolId}/updatekidinfobalance", "POST, OPTIONS")
// @Api(Description="更新学生储值账户余额")
export class UpdateKidInfoAccountBalanceReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public kidId?: string;
    public bizType?: KIDACCOUNT_BALANCE_BIZTYPE;
    public amount?: number;
    public subject?: string;
    public refId?: string;

    public constructor(init?: Partial<UpdateKidInfoAccountBalanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidInfoAccountBalanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 学生储值账户充值
*/
// @Route("/school/{SchoolId}/addkidinfobalance", "POST, OPTIONS")
// @Api(Description="学生储值账户充值")
export class AddKidInfoAccountBalanceReq implements IReturn<BaseResponse>
{
    public schoolId?: string;
    public kidId?: string;
    public amount?: number;
    public giftAmount?: number;
    public subject?: string;

    public constructor(init?: Partial<AddKidInfoAccountBalanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddKidInfoAccountBalanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 学生储值账户记录
*/
// @Route("/school/{SchoolId}/kidinfobalancehis", "GET, OPTIONS")
// @Api(Description="学生储值账户记录")
export class GetKidInfoBalanceAccountHistoryReq implements IReturn<GetKidInfoBalanceAccountHistoryRes>
{
    public schoolId?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetKidInfoBalanceAccountHistoryReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidInfoBalanceAccountHistoryReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidInfoBalanceAccountHistoryRes(); }
}

/**
* tob添加支付产品信息接口
*/
// @Route("/payment/tobproducts", "POST, OPTIONS")
// @Api(Description="tob添加支付产品信息接口")
export class AddSchoolOrderProductReq implements IReturn<BaseResponse>
{
    public productName?: string;
    public catalog?: SCHOOLORDERPRODUCT_CATALOG;
    public price?: number;
    public amount?: number;

    public constructor(init?: Partial<AddSchoolOrderProductReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSchoolOrderProductReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* tob获取支付产品信息接口
*/
// @Route("/payment/tobproducts", "GET, OPTIONS")
// @Api(Description="tob获取支付产品信息接口")
export class GetSchoolOrderProductsReq implements IReturn<GetSchoolOrderProductsRes>
{
    public catalog?: SCHOOLORDERPRODUCT_CATALOG;

    public constructor(init?: Partial<GetSchoolOrderProductsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolOrderProductsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolOrderProductsRes(); }
}

/**
* tob获取支付产品信息接口
*/
// @Route("/payment/toballproducts", "GET, OPTIONS")
// @Api(Description="tob获取支付产品信息接口")
export class GetAllSchoolOrderProductsReq implements IReturn<GetSchoolOrderProductsRes>
{

    public constructor(init?: Partial<GetAllSchoolOrderProductsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAllSchoolOrderProductsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolOrderProductsRes(); }
}

/**
* 微信创建订单接口
*/
// @Route("/payment/tobprepaywechat", "POST, OPTIONS")
// @Api(Description="微信创建订单接口")
export class PrePayWechatSchoolReq implements IReturn<PrePayWechatRes>
{
    public productId?: string;
    public schoolId?: string;

    public constructor(init?: Partial<PrePayWechatSchoolReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PrePayWechatSchoolReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PrePayWechatRes(); }
}

/**
* tob删除支付产品信息接口
*/
// @Route("/payment/tobproduct/{ProductId}", "DELETE, OPTIONS")
// @Api(Description="tob删除支付产品信息接口")
export class DeleteSchoolOrderProductReq implements IReturn<BaseResponse>
{
    public productId?: string;

    public constructor(init?: Partial<DeleteSchoolOrderProductReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSchoolOrderProductReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* tob获取订单列表
*/
// @Route("/sms/tob/getschoolorderinfos", "GET, OPTIONS")
// @Api(Description="tob获取订单列表")
export class GetSchoolOrderInfosReq implements IReturn<GetSchoolOrderInfosRes>
{
    public schoolId?: string;
    public orderNo?: string;
    public productId?: string;
    public price?: number[];
    public amount?: number[];
    public productName?: string;
    public createOn?: string[];
    public paidOn?: string[];
    public teacherUserInfoId?: string;
    public catalog?: SCHOOLORDERPRODUCT_CATALOG;
    public paymentType?: ORDERPAYMENT_TYPE;
    public orderStatus?: ORDERSTAUS;
    public pageSize?: number;
    public pageIndex?: number;

    public constructor(init?: Partial<GetSchoolOrderInfosReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolOrderInfosReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolOrderInfosRes(); }
}

/**
* tob获取短信剩余量
*/
// @Route("/sms/tobgetbalance", "GET, OPTIONS")
// @Api(Description="tob获取短信剩余量")
export class GetSchoolSmsBalanceReq implements IReturn<GetSchoolSmsBalanceRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolSmsBalanceReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolSmsBalanceReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolSmsBalanceRes(); }
}

/**
* tob更新短信余额预警手机号
*/
// @Route("/sms/tob/updatebalancealert", "POST, OPTIONS")
// @Api(Description="tob更新短信余额预警手机号")
export class UpdateSchoolSmsBalanceAlertReq implements IReturn<UpdateSchoolSmsBalanceAlertRes>
{
    public schoolId?: string;
    public alertAmount?: number;
    public alertPhoneNumber?: string;

    public constructor(init?: Partial<UpdateSchoolSmsBalanceAlertReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSchoolSmsBalanceAlertReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateSchoolSmsBalanceAlertRes(); }
}

/**
* tob查询短信发送记录
*/
// @Route("/sms/tob/getschoolsmssendlogreq", "GET, OPTIONS")
// @Api(Description="tob查询短信发送记录")
export class GetSchoolSmsSendLogReq implements IReturn<GetSchoolSmsSendLogRes>
{
    public schoolId?: string;
    public phoneNumber?: string;
    public content?: string;
    public vendor?: string;
    public sendMsg?: string;
    public sendStatus?: SMS_SEND_STATUS;
    public createOn?: string[];
    public pageIndex?: number;
    public pageSize?: number;

    public constructor(init?: Partial<GetSchoolSmsSendLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolSmsSendLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolSmsSendLogRes(); }
}

/**
* 获取用户相册单条动态
*/
// @Route("/album/feed/{feedid}", "GET, OPTIONS")
// @Api(Description="获取用户相册单条动态")
export class GetAlbumEventInfoReq implements IReturn<GetAlbumEventInfoRes>
{
    /**
    * feedid
    */
    // @ApiMember(DataType="string", Description="feedid", IsRequired=true, Name="feedid", ParameterType="path")
    public feedid?: string;

    /**
    * restype
    */
    // @ApiMember(DataType="string", Description="restype", IsRequired=true, Name="restype", ParameterType="query")
    public restype?: string;

    /**
    * sign
    */
    // @ApiMember(DataType="string", Description="sign", IsRequired=true, Name="sign", ParameterType="query")
    public sign?: string;

    /**
    * nonce
    */
    // @ApiMember(DataType="string", Description="nonce", IsRequired=true, Name="nonce", ParameterType="query")
    public nonce?: string;

    public configid?: string;

    public constructor(init?: Partial<GetAlbumEventInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAlbumEventInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAlbumEventInfoRes(); }
}

/**
* 获取用户分享的相册数据
*/
// @Route("/album/kidid/{Kidid}/sharedata", "GET, OPTIONS")
// @Api(Description="获取用户分享的相册数据")
export class GetKidAlbumShareDataReq implements IReturn<GetKidAlbumDataRes>
{
    /**
    * Kidid
    */
    // @ApiMember(DataType="string", Description="Kidid", IsRequired=true, Name="Kidid", ParameterType="path")
    public kidid?: string;

    /**
    * Termid
    */
    // @ApiMember(DataType="integer", Description="Termid", IsRequired=true, Name="Termid", ParameterType="query")
    public termid: number;

    /**
    * sign
    */
    // @ApiMember(DataType="string", Description="sign", IsRequired=true, Name="sign", ParameterType="query")
    public sign?: string;

    /**
    * nonce
    */
    // @ApiMember(DataType="string", Description="nonce", IsRequired=true, Name="nonce", ParameterType="query")
    public nonce?: string;

    public constructor(init?: Partial<GetKidAlbumShareDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidAlbumShareDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidAlbumDataRes(); }
}

/**
* 获取用户相册数据
*/
// @Route("/album/kidid/{Kidid}/data", "GET, OPTIONS")
// @Api(Description="获取用户相册数据")
export class GetKidAlbumDataReq implements IReturn<GetKidAlbumDataRes>
{
    /**
    * Kidid
    */
    // @ApiMember(DataType="string", Description="Kidid", IsRequired=true, Name="Kidid", ParameterType="path")
    public kidid?: string;

    /**
    * Termid
    */
    // @ApiMember(DataType="integer", Description="Termid", IsRequired=true, Name="Termid", ParameterType="query")
    public termid: number;

    /**
    * sign
    */
    // @ApiMember(DataType="string", Description="sign", IsRequired=true, Name="sign", ParameterType="query")
    public sign?: string;

    /**
    * nonce
    */
    // @ApiMember(DataType="string", Description="nonce", IsRequired=true, Name="nonce", ParameterType="query")
    public nonce?: string;

    /**
    * configid
    */
    // @ApiMember(DataType="string", Description="configid", Name="configid", ParameterType="query")
    public configid?: string;

    /**
    * bookorderid
    */
    // @ApiMember(DataType="string", Description="bookorderid", Name="bookorderid", ParameterType="query")
    public bookorderid?: string;

    public constructor(init?: Partial<GetKidAlbumDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidAlbumDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidAlbumDataRes(); }
}

/**
* 获取用户相册信息
*/
// @Route("/album/kidid/{Kidid}/info", "GET, OPTIONS")
// @Api(Description="获取用户相册信息")
export class GetKidAlbumInfoReq implements IReturn<GetKidAlbumInfoRes>
{
    /**
    * Kidid
    */
    // @ApiMember(DataType="string", Description="Kidid", IsRequired=true, Name="Kidid", ParameterType="path")
    public kidid?: string;

    /**
    * Termid
    */
    // @ApiMember(DataType="integer", Description="Termid", IsRequired=true, Name="Termid", ParameterType="query")
    public termid: number;

    /**
    * sign
    */
    // @ApiMember(DataType="string", Description="sign", IsRequired=true, Name="sign", ParameterType="query")
    public sign?: string;

    /**
    * nonce
    */
    // @ApiMember(DataType="string", Description="nonce", IsRequired=true, Name="nonce", ParameterType="query")
    public nonce?: string;

    /**
    * configid
    */
    // @ApiMember(DataType="string", Description="configid", Name="configid", ParameterType="query")
    public configid?: string;

    public constructor(init?: Partial<GetKidAlbumInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidAlbumInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidAlbumInfoRes(); }
}

/**
* 获取单条评语
*/
// @Route("/class/{ClassId}/kidremark/{KidRemarkId}", "GET, OPTIONS")
// @Api(Description="获取单条评语")
export class GetKidRemarkReq implements IReturn<GetKidRemarkRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    public kidId?: string;
    /**
    * 评语id
    */
    // @ApiMember(DataType="string", Description="评语id", IsRequired=true, Name="KidRemarkId", ParameterType="path")
    public kidRemarkId?: string;

    public constructor(init?: Partial<GetKidRemarkReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidRemarkReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidRemarkRes(); }
}

/**
* 获取用户动态分类未读计数
*/
// @Route("/class/{ClassId}/eventsunreadcount/{KidId}", "GET, OPTIONS")
// @Api(Description="获取用户动态分类未读计数")
export class GetClassEventsReadStatsReq implements IReturn<GetClassEventsReadStatsRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    public constructor(init?: Partial<GetClassEventsReadStatsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassEventsReadStatsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassEventsReadStatsRes(); }
}

export class ConfigCacheItem
{
    public configName?: string;
    public version?: number;

    public constructor(init?: Partial<ConfigCacheItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConfigCacheItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 获取客户端配置项
*/
// @Route("/system/configsbyuser", "POST, OPTIONS")
// @Api(Description="获取客户端配置项")
export class GetUserConfigReq implements IReturn<GetUserConfigRes>
{
    /**
    * AppId
    */
    // @ApiMember(DataType="integer", Description="AppId", IsRequired=true, Name="AppId", ParameterType="query")
    public appId: number;

    /**
    * 终端类型
    */
    // @ApiMember(DataType="string", Description="终端类型", IsRequired=true, Name="Platform", ParameterType="query")
    public platform: OSTYPE;

    /**
    * 客户端版本
    */
    // @ApiMember(DataType="string", Description="客户端版本", IsRequired=true, Name="AppVersion", ParameterType="query")
    public appVersion?: string;

    /**
    * 渠道名称
    */
    // @ApiMember(DataType="string", Description="渠道名称", IsRequired=true, Name="Channel", ParameterType="query")
    public channel?: string;

    public currentConfig?: ConfigCacheItem[];

    public constructor(init?: Partial<GetUserConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetUserConfigRes(); }
}

// @Route("/system/ststoken", "POST, OPTIONS")
export class GetAliyunStsTokenV2Req implements IReturn<GetAliyunStsTokenV2Res>
{
    public schoolId?: string;
    /**
    * Keys
    */
    // @ApiMember(DataType="array", Description="Keys", IsRequired=true, Name="Keys", ParameterType="query")
    public keys: string[];

    public constructor(init?: Partial<GetAliyunStsTokenV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAliyunStsTokenV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAliyunStsTokenV2Res(); }
}

// @Route("/system/verinfo", "GET, OPTIONS")
export class GetSystemVersionReq
{

    public constructor(init?: Partial<GetSystemVersionReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSystemVersionReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/system/checkurl", "POST, OPTIONS")
export class CheckUrlReq implements IReturn<CheckUrlRes>
{
    public url?: string;

    public constructor(init?: Partial<CheckUrlReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckUrlReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CheckUrlRes(); }
}

/**
* 获取学生基础信息
*/
// @Api(Description="获取学生基础信息")
export class GetStudentBaseInfoReq implements IReturn<GetStudentBaseInfoRes>
{
    public schoolId?: string;
    public kidIds?: string[];

    public constructor(init?: Partial<GetStudentBaseInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetStudentBaseInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetStudentBaseInfoRes(); }
}

/**
* 获取班级基础信息
*/
// @Api(Description="获取班级基础信息")
export class GetClassBaseInfoReq implements IReturn<GetClassBaseInfoRes>
{
    public schoolId?: string;
    public classIds?: string[];

    public constructor(init?: Partial<GetClassBaseInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClassBaseInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetClassBaseInfoRes(); }
}

/**
* 获取OSS基础信息
*/
// @Api(Description="获取OSS基础信息")
export class GetOSSBaseInfoReq implements IReturn<GetOSSBaseInfoRes>
{
    public schoolId?: string;
    public ossIds?: string[];

    public constructor(init?: Partial<GetOSSBaseInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOSSBaseInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOSSBaseInfoRes(); }
}

/**
* 获取教师基础信息
*/
// @Api(Description="获取教师基础信息")
export class GetTeacherInfoReq implements IReturn<GetTeacherInfoRes>
{
    public schoolId?: string;
    public teacherIds?: string[];

    public constructor(init?: Partial<GetTeacherInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTeacherInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTeacherInfoRes(); }
}

export class VoteItemBaseDTO
{
    public id?: string;
    public titleAttachment?: EventAttachItem;
    public title?: string;
    public isRequired?: boolean;

    public constructor(init?: Partial<VoteItemBaseDTO>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VoteItemBaseDTO'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class VoteItemMultiDTO extends VoteItemBaseDTO
{
    public selectItems?: VoteItemItemDTO[];
    public maxNumbers?: number;

    public constructor(init?: Partial<VoteItemMultiDTO>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'VoteItemMultiDTO'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class VoteItemSingleDTO extends VoteItemBaseDTO
{
    public selectItems?: VoteItemItemDTO[];

    public constructor(init?: Partial<VoteItemSingleDTO>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'VoteItemSingleDTO'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ClassInfoUpdateNotify
{
    public classId?: string;

    public constructor(init?: Partial<ClassInfoUpdateNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClassInfoUpdateNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class NewEventNotify
{
    public eventType?: string;
    public classId?: string;
    public className?: string;
    public schoolName?: string;
    public teacherName?: string;
    public title?: string;
    public eventId?: string;

    public constructor(init?: Partial<NewEventNotify>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NewEventNotify'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class CommonUNKnowEnum
{
    public configtype?: CONFIGTYPE;
    public userrole?: USERROLE;
    public clockiN_FREQUENCY?: CLOCKIN_FREQUENCY;
    public eventclockinstate?: EVENTCLOCKINSTATE;
    public parentaccountstatus?: PARENTACCOUNTSTATUS;
    public parenT_ROLE_TYPE?: PARENT_ROLE_TYPE;
    public kidaccountstate?: KIDACCOUNTSTATE;
    public oSTYPE?: OSTYPE;
    public bookordeR_STATS?: BOOKORDER_STATS;
    public evaluatetype?: EVALUATETYPE;
    public eventauditstate?: EVENTAUDITSTATE;
    public eventpublishstate?: EVENTPUBLISHSTATE;
    public bookordeR_PDFSTATS?: BOOKORDER_PDFSTATS;
    public data1?: FILETYPE;
    public userrolelse?: USERROLE;
    public cOMMISSIONSTATUS?: COMMISSIONSTATUS;
    public data?: TASKATTACHTYPE;
    public sEMESTERTYPE?: SEMESTERTYPE;
    public sTUDENT_STATUS?: STUDENT_STATUS;
    public cLASSGRADETYPE?: CLASSGRADETYPE;
    public eVALUATESTATE?: EVALUATESTATE;
    public kidRemarkItemDTO?: KidRemarkItemDTO;
    public lEAVESTATE?: LEAVESTATE;
    public mEDICSTAT?: MEDICSTAT;
    public data11?: AttachVideoItem;
    public data2?: EventAttachItem;
    public data3?: AttachLinkItem;
    public data4?: AttachFileItem;
    public data5?: AttachVoiceItem;
    public data6?: EventLikeUserDTO;
    public data7?: EventItemCommentItemDTO;
    public data8?: ParentInfoDTO;
    public eventTaskItemDTO?: EventTaskItemDTO;
    public eventItemCommentItemDTO?: EventItemCommentItemDTO;
    public kidRemarkItemCommentDTO?: KidRemarkItemCommentDTO;
    public bookExtraConfigItemDTO?: BookExtraConfigItemDTO;
    public growBookGroupDTO?: GrowBookGroupDTO;
    public voteItemSerial?: VoteItemSerial;
    public evaluateItemDTO?: EvaluateItemDTO;
    public data12?: AttachImageItem[];
    public data22?: TeacherInfoDTO[];
    public data23?: TeacherClassInfoDTO[];
    public data24?: KidInfoDTO[];
    public eventAttachDTO?: EventAttachDTO;
    public medicCareLogDTO?: MedicCareLogDTO;
    public parentRelationClassInfoDTO?: ParentRelationClassInfoDTO;
    public eventAttachVideoItemDTO?: EventAttachVideoItemDTO;
    public eventAttachLinkItemDTO?: EventAttachLinkItemDTO;
    public eventAttachAudioItemDTO?: EventAttachAudioItemDTO;
    public evaluateItemKidScoreDTO?: EvaluateItemKidScoreDTO;
    public evaluateItemBaseDTO?: EvaluateItemBaseDTO;
    public eventAttachFileItemDTO?: EventAttachFileItemDTO;
    public classEventClockInItem?: ClassEventClockInItem;
    public eventClockInGroupDTO?: EventClockInGroupDTO;
    public voteItemMultiDTO?: VoteItemMultiDTO;
    public voteItemBaseDTO?: VoteItemBaseDTO;
    public voteItemSingleDTO?: VoteItemSingleDTO;
    public eventClockInFrequency?: EventClockInFrequency;
    public voteItemItemDTO?: VoteItemItemDTO;
    public voteItemSelectedUserDTO?: VoteItemSelectedUserDTO;
    public orderProductDTO?: OrderProductDTO;
    public configCacheItem?: ConfigCacheItem;
    public userClassEventsStatItem?: UserClassEventsStatItem;
    public eventTemplateItem?: EventTemplateItem;
    public teachingThemeItemDTO?: TeachingThemeItemDTO;
    public classInfoUpdateNotify?: ClassInfoUpdateNotify;
    public newEventNotify?: NewEventNotify;
    public eventAttachLocalDTO?: EventAttachLocalModel;

    public constructor(init?: Partial<CommonUNKnowEnum>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommonUNKnowEnum'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 获取园所年级信息
*/
// @Route("/school/{SchoolId}/gradename", "GET, OPTIONS")
// @Api(Description="获取园所年级信息")
export class GetSchoolGradeNameReq implements IReturn<GetSchoolGradeNameRes>
{
    /**
    * 园所id
    */
    // @ApiMember(DataType="string", Description="园所id", IsRequired=true, Name="SchoolId", ParameterType="path")
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolGradeNameReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolGradeNameReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolGradeNameRes(); }
}

/**
* 获取所有permission并输出为dart
*/
// @Route("/aaa/app/allpermissions", "GET, OPTIONS")
// @Api(Description="获取所有permission并输出为dart")
export class GetAllAppPermissionsReq
{

    public constructor(init?: Partial<GetAllAppPermissionsReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAllAppPermissionsReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 美洽推送通知
*/
// @Route("/meiqia/push/{app}", "POST, OPTIONS")
// @Api(Description="美洽推送通知")
export class MeiQiaPushReq
{
    public app?: string;

    public constructor(init?: Partial<MeiQiaPushReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MeiQiaPushReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 融云在线状态
*/
// @Route("/notify/rongcloud/onlinestatus", "POST")
export class RongCloudOnlineStatusReq extends Array<RongCloudOnlineStatus> implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<RongCloudOnlineStatusReq>) { super(); (Object as any).assign(this, init); }
    public getTypeName() { return 'RongCloudOnlineStatusReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class RongCloudMsgRouteQueue
{
    public fromUserId?: string;
    public toUserId?: string;
    public objectName?: string;
    public content?: string;
    public channelType?: string;
    public msgTimestamp?: string;
    public msgUID?: string;
    public sensitiveType?: number;
    public source?: string;
    public groupUserIds?: string[];

    public constructor(init?: Partial<RongCloudMsgRouteQueue>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RongCloudMsgRouteQueue'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 融云消息路由
*/
// @Route("/notify/rongcloud/recvmsg", "POST")
// @Api(Description="融云消息路由")
export class RongCloudMsgRouteReq implements IReturn<BaseResponse>
{
    public nonce?: string;
    public signTimestamp?: string;
    public signature?: string;
    public fromUserId?: string;
    public toUserId?: string;
    public objectName?: string;
    public content?: string;
    public channelType?: string;
    public msgTimestamp?: string;
    public msgUID?: string;
    public sensitiveType?: number;
    public source?: string;
    public groupUserIds?: string[];

    public constructor(init?: Partial<RongCloudMsgRouteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RongCloudMsgRouteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 获取用户分享的数据
*/
// @Route("/event/sharedata/{EventId}/{KidId}", "GET, OPTIONS")
// @Api(Description="获取用户分享的数据")
export class GetKidEventShareDataReq implements IReturn<ClassEventShareRes>
{
    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * EventId
    */
    // @ApiMember(DataType="string", Description="EventId", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * ItemId
    */
    // @ApiMember(DataType="string", Description="ItemId", Name="ItemId", ParameterType="query")
    public itemId?: string;

    /**
    * sign
    */
    // @ApiMember(DataType="string", Description="sign", IsRequired=true, Name="sign", ParameterType="query")
    public sign?: string;

    /**
    * nonce
    */
    // @ApiMember(DataType="string", Description="nonce", IsRequired=true, Name="nonce", ParameterType="query")
    public nonce?: string;

    public constructor(init?: Partial<GetKidEventShareDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidEventShareDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClassEventShareRes(); }
}

/**
* 获取用户分享的成长任务数据
*/
// @Route("/eventtask/sharedata/{TaskId}/{TaskItemId}", "GET, OPTIONS")
// @Api(Description="获取用户分享的成长任务数据")
export class GetKidEventTaskShareDataReq implements IReturn<ClassEventTaskItemRes>
{
    /**
    * TaskId
    */
    // @ApiMember(DataType="string", Description="TaskId", IsRequired=true, Name="TaskId", ParameterType="path")
    public taskId?: string;

    /**
    * TaskItemId
    */
    // @ApiMember(DataType="string", Description="TaskItemId", IsRequired=true, Name="TaskItemId", ParameterType="path")
    public taskItemId?: string;

    /**
    * sign
    */
    // @ApiMember(DataType="string", Description="sign", IsRequired=true, Name="sign", ParameterType="query")
    public sign?: string;

    /**
    * nonce
    */
    // @ApiMember(DataType="string", Description="nonce", IsRequired=true, Name="nonce", ParameterType="query")
    public nonce?: string;

    public constructor(init?: Partial<GetKidEventTaskShareDataReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidEventTaskShareDataReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClassEventTaskItemRes(); }
}

/**
* 获得学校微官网数据
*/
// @Route("/minisite/{SchoolId}", "GET, OPTIONS")
// @Api(Description="获得学校微官网数据")
export class GetSchoolMiniSiteReq implements IReturn<GetSchoolMiniSiteRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<GetSchoolMiniSiteReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSchoolMiniSiteReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSchoolMiniSiteRes(); }
}

/**
* 通兑吧加积分
*/
// @Route("/tongduiba/addpoint", "GET, OPTIONS")
// @Api(Description="通兑吧加积分")
export class TongduibaAddPointReq implements IReturn<TongduibaCreateOrderRes>
{
    public appKey?: string;
    public timestamp?: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;
    public pointsAmount?: number;
    public body?: string;
    public subject?: string;
    public orderId?: string;

    public constructor(init?: Partial<TongduibaAddPointReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaAddPointReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TongduibaCreateOrderRes(); }
}

/**
* 通兑吧下单
*/
// @Route("/tongduiba/createorder", "GET, OPTIONS")
// @Api(Description="通兑吧下单")
export class TongduibaCreateOrderReq implements IReturn<TongduibaCreateOrderRes>
{
    public appKey?: string;
    public timestamp?: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;
    public pointsAmount?: number;
    public body?: string;
    public subject?: string;
    public orderId?: string;

    public constructor(init?: Partial<TongduibaCreateOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaCreateOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TongduibaCreateOrderRes(); }
}

/**
* 通兑吧下单结果通知
*/
// @Route("/tongduiba/notifyorder", "GET, OPTIONS")
// @Api(Description="通兑吧下单结果通知")
export class TongduibaNotifyOrderReq
{
    public appKey?: string;
    public timestamp?: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;
    public status?: number;
    public bizId?: string;
    public errorMessage?: string;
    public orderId?: string;

    public constructor(init?: Partial<TongduibaNotifyOrderReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaNotifyOrderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* 通兑吧查用户积分
*/
// @Route("/tongduiba/querypoint", "GET, OPTIONS")
// @Api(Description="通兑吧查用户积分")
export class TongduibaQueryPointReq implements IReturn<TongduibaQueryPointRes>
{
    public appKey?: string;
    public timestamp?: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;

    public constructor(init?: Partial<TongduibaQueryPointReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaQueryPointReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TongduibaQueryPointRes(); }
}

/**
* 通兑吧查用户积分记录
*/
// @Route("/tongduiba/querypointlog", "GET, OPTIONS")
// @Api(Description="通兑吧查用户积分记录")
export class TongduibaQueryPointLogReq implements IReturn<TongduibaQueryPointLogRes>
{
    public appKey?: string;
    public timestamp?: number;
    public nonceStr?: string;
    public sign?: string;
    public unionId?: string;
    public page?: number;
    public pageSize?: number;

    public constructor(init?: Partial<TongduibaQueryPointLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TongduibaQueryPointLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TongduibaQueryPointLogRes(); }
}

/**
* 有赞通知
*/
// @Route("/notify/youzan", "POST")
// @Api(Description="有赞通知")
export class YouzanNotifyReq implements IReturn<YouzanNotifyRes>
{

    public constructor(init?: Partial<YouzanNotifyReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'YouzanNotifyReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new YouzanNotifyRes(); }
}

/**
* 获取配置
*/
// @Route("/extrabook/bookconfig/{ConfigId}", "GET, OPTIONS")
// @Api(Description="获取配置")
export class GetExtrabookConfigReq implements IReturn<GetExtrabookConfigRes>
{
    public configId?: string;

    public constructor(init?: Partial<GetExtrabookConfigReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetExtrabookConfigReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetExtrabookConfigRes(); }
}

/**
* 获取某个学生所有成长册详情
*/
// @Route("/growbook/KidId/{KidId}/all", "GET, OPTIONS")
// @Api(Description="获取某个学生所有成长册详情")
export class GetKidGrowBooksReq implements IReturn<GetKidGrowBooksRes>
{
    public kidId?: string;

    public constructor(init?: Partial<GetKidGrowBooksReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKidGrowBooksReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetKidGrowBooksRes(); }
}

/**
* 成长任务中删除一个任务
*/
// @Route("/class/event/task/{TaskId}/{ItemId}", "DELETE, OPTIONS")
// @Api(Description="成长任务中删除一个任务")
export class DeleteEventTaskItemReq implements IReturn<CommonReturn>
{
    public kidId?: string;
    public taskId?: string;
    public itemId?: string;

    public constructor(init?: Partial<DeleteEventTaskItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEventTaskItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 成长任务中更新一个任务
*/
// @Route("/class/event/task/{TaskId}/{ItemId}", "POST, OPTIONS")
// @Api(Description="成长任务中更新一个任务")
export class UpdateEventTaskItemReq implements IReturn<AddEventTaskItemRes>
{
    public kidId?: string;
    public taskId?: string;
    public itemId?: string;
    public content?: string;
    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<UpdateEventTaskItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateEventTaskItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddEventTaskItemRes(); }
}

/**
* 成长任务中发布一个任务
*/
// @Route("/class/event/task/{TaskId}", "POST, OPTIONS")
// @Api(Description="成长任务中发布一个任务")
export class AddEventTaskItemReq implements IReturn<AddEventTaskItemRes>
{
    public kidId?: string;
    public taskId?: string;
    public content?: string;
    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<AddEventTaskItemReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventTaskItemReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddEventTaskItemRes(); }
}

/**
* 给成长任务中的某个任务点赞
*/
// @Route("/class/event/task/{TaskId}/{TaskItemId}/like", "POST, OPTIONS")
// @Api(Description="给成长任务中的某个任务点赞")
export class AddEventTaskItemLikeReq implements IReturn<AddEventTaskItemRes>
{
    public kidId?: string;
    public taskId?: string;
    public taskItemId?: string;
    public isLike?: boolean;

    public constructor(init?: Partial<AddEventTaskItemLikeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventTaskItemLikeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddEventTaskItemRes(); }
}

/**
* 给成长任务中某个任务评论
*/
// @Route("/class/event/task/{TaskId}/{TaskItemId}/comment", "POST, OPTIONS")
// @Api(Description="给成长任务中某个任务评论")
export class AddEventTaskItemCommentReq implements IReturn<AddEventTaskItemRes>
{
    public kidId?: string;
    public taskId?: string;
    public taskItemId?: string;
    public replyTo?: number;
    public isAudio?: boolean;
    public content?: string;
    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<AddEventTaskItemCommentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventTaskItemCommentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddEventTaskItemRes(); }
}

/**
* 删除一条消息
*/
// @Route("/class/{ClassId}/event/{EventId}", "DELETE, OPTIONS")
// @Api(Description="删除一条消息")
export class DeleteClassEventReq implements IReturn<CommonReturn>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    public constructor(init?: Partial<DeleteClassEventReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteClassEventReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 在园时光删除评论
*/
// @Route("/class/event/kgtime/{EventId}/comment/{CommentId}", "DELETE, OPTIONS")
// @Api(Description="在园时光删除评论")
export class DeleteEventKGTimeCommentReq implements IReturn<UpdateEventKGTimeRes>
{
    /**
    * SchoolId
    */
    // @ApiMember(DataType="string", Description="SchoolId", Name="SchoolId", ParameterType="query")
    public schoolId?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", Name="KidId", ParameterType="query")
    public kidId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="EventId", ParameterType="path")
    public eventId?: string;

    /**
    * 评论id
    */
    // @ApiMember(DataType="string", Description="评论id", IsRequired=true, Name="CommentId", ParameterType="path")
    public commentId?: string;

    public constructor(init?: Partial<DeleteEventKGTimeCommentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEventKGTimeCommentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UpdateEventKGTimeRes(); }
}

/**
* 获取在园时光单条详情
*/
// @Route("/class/event/kgtime/{eventid}", "GET, OPTIONS")
// @Api(Description="获取在园时光单条详情")
export class GetEventKGTimeByIdReq implements IReturn<GetEventKGTimeByIdRes>
{
    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid?: string;

    public kidId?: string;

    public constructor(init?: Partial<GetEventKGTimeByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventKGTimeByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventKGTimeByIdRes(); }
}

/**
* 获取发展评估单条详情
*/
// @Route("/class/{ClassId}/event/evaluate/{eventid}", "GET, OPTIONS")
// @Api(Description="获取发展评估单条详情")
export class GetEventEvaluateByIdReq implements IReturn<GetEventEvaluateByIdRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid?: string;

    public kidId?: string;

    public constructor(init?: Partial<GetEventEvaluateByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventEvaluateByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventEvaluateByIdRes(); }
}

/**
* 获取评估项目
*/
// @Route("/class/{ClassId}/evaluates", "GET, OPTIONS")
// @Api(Description="获取评估项目")
export class GetEvaluatesReq implements IReturn<GetEvaluatesRes>
{
    public classId?: string;

    public constructor(init?: Partial<GetEvaluatesReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEvaluatesReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEvaluatesRes(); }
}

/**
* 获取评估项目
*/
// @Route("/class/{ClassId}/evaluatesv2", "GET, OPTIONS")
// @Api(Description="获取评估项目")
export class GetEvaluatesV2Req implements IReturn<GetEvaluatesV2Res>
{
    public classId?: string;

    public constructor(init?: Partial<GetEvaluatesV2Req>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEvaluatesV2Req'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEvaluatesV2Res(); }
}

/**
* 获取学生评语单条详情
*/
// @Route("/class/{ClassId}/event/kidremark/{eventid}", "GET, OPTIONS")
// @Api(Description="获取学生评语单条详情")
export class GetEventKidRemarkByIdReq implements IReturn<GetEventKidRemarkByIdRes>
{
    /**
    * 班级id
    */
    // @ApiMember(DataType="string", Description="班级id", IsRequired=true, Name="ClassId", ParameterType="path")
    public classId?: string;

    /**
    * 消息id
    */
    // @ApiMember(DataType="string", Description="消息id", IsRequired=true, Name="eventid", ParameterType="path")
    public eventid?: string;

    /**
    * KidId
    */
    // @ApiMember(DataType="string", Description="KidId", IsRequired=true, Name="KidId", ParameterType="query")
    public kidId?: string;

    public constructor(init?: Partial<GetEventKidRemarkByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventKidRemarkByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventKidRemarkByIdRes(); }
}

/**
* 读取一条通知
*/
// @Route("/class/event/notify/{eventid}", "GET, OPTIONS")
// @Api(Description="读取一条通知")
export class GetEventNotifyByIdReq implements IReturn<GetEventNotifyByIdRes>
{
    public eventid?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetEventNotifyByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventNotifyByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventNotifyByIdRes(); }
}

/**
* 获取一条分享
*/
// @Route("/class/event/share/{eventid}", "GET, OPTIONS")
// @Api(Description="获取一条分享")
export class GetEventShareByIdReq implements IReturn<GetEventShareByIdRes>
{
    public eventid?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetEventShareByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventShareByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventShareByIdRes(); }
}

/**
* 读取一条投票消息
*/
// @Route("/class/event/vote/{eventid}", "GET, OPTIONS")
// @Api(Description="读取一条投票消息")
export class GetEventVoteByIdReq implements IReturn<GetEventVoteByIdRes>
{
    public eventid?: string;
    public kidId?: string;

    public constructor(init?: Partial<GetEventVoteByIdReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventVoteByIdReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEventVoteByIdRes(); }
}

/**
* 给打卡任务中某个打卡点赞
*/
// @Route("/class/event/clockin/{ClockInId}/{ClockInItemId}/like", "POST, OPTIONS")
// @Api(Description="给打卡任务中某个打卡点赞")
export class AddEventClockInItemLikeReq implements IReturn<CommonReturn>
{
    public kidId?: string;
    public clockInId?: string;
    public groupId?: string;
    public clockInItemId?: string;
    public isLike?: boolean;

    public constructor(init?: Partial<AddEventClockInItemLikeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventClockInItemLikeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 给打卡任务中某个打卡评论
*/
// @Route("/class/event/clockin/{ClockInId}/{ClockInItemId}/comment", "POST, OPTIONS")
// @Api(Description="给打卡任务中某个打卡评论")
export class AddEventClockInItemCommentReq implements IReturn<CommonReturn>
{
    public clockInId?: string;
    public groupId?: string;
    public clockInItemId?: string;
    public replyTo?: number;
    public isAudio?: boolean;
    public content?: string;
    public attachItem?: EventAttachItem;

    public constructor(init?: Partial<AddEventClockInItemCommentReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEventClockInItemCommentReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/auth/SaveMiniProgrameCode", "POST, OPTIONS")
export class SaveMiniProgrameCodeReq implements IReturn<BaseResponse>
{
    public wxAppId?: string;
    public code?: string;

    public constructor(init?: Partial<SaveMiniProgrameCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveMiniProgrameCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新孩子的姓名
*/
// @Route("/kid/{KidId}/name", "POST, OPTIONS")
// @Api(Description="更新孩子的姓名")
export class UpdateKidNameReq implements IReturn<CommonReturn>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    public fullname?: string;

    public constructor(init?: Partial<UpdateKidNameReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidNameReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

/**
* 更新学生的联系人信息
*/
// @Route("/kid/{KidId}/contact", "POST, OPTIONS")
// @Api(Description="更新学生的联系人信息")
export class UpdateKidContactReq extends CommonRequest implements IReturn<BaseResponse>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    public primaryContact?: ParentInfoDTO;
    public secondaryContact?: ParentInfoDTO;

    public constructor(init?: Partial<UpdateKidContactReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidContactReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

/**
* 更新孩子的性别
*/
// @Route("/kid/{KidId}/gender", "POST, OPTIONS")
// @Api(Description="更新孩子的性别")
export class UpdateKidGenderReq extends CommonRequest implements IReturn<CommonReturn>
{
    /**
    * 学生id
    */
    // @ApiMember(DataType="string", Description="学生id", IsRequired=true, Name="KidId", ParameterType="path")
    public kidId?: string;

    /**
    * 性别
    */
    // @ApiMember(DataType="string", Description="性别", IsRequired=true, Name="gender", ParameterType="query")
    public gender: GENDERTYPE;

    public constructor(init?: Partial<UpdateKidGenderReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateKidGenderReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/file/uploadmulti", "POST, OPTIONS")
export class UploadMultiFileReq implements IReturn<CommonReturn>
{
    public filetype?: FILETYPE;
    public classid?: string;
    public kidid?: string;
    public schoolid?: string;
    public files?: UploadFileItem[];

    public constructor(init?: Partial<UploadMultiFileReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadMultiFileReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/file/upload", "POST, OPTIONS")
export class UploadFileReq implements IReturn<CommonReturn>
{
    public filetype?: FILETYPE;
    public classid?: string;
    public parentid?: number;
    public originname?: string;

    public constructor(init?: Partial<UploadFileReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadFileReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/system/systemnotices", "GET, OPTIONS")
export class GetSystemNoticeReq implements IReturn<GetSystemNoticeRes>
{

    public constructor(init?: Partial<GetSystemNoticeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSystemNoticeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSystemNoticeRes(); }
}

/**
* 获取学期信息
*/
// @Route("/system/terminfos", "GET, OPTIONS")
// @Api(Description="获取学期信息")
export class GetTermInfoReq implements IReturn<GetTermInfoRes>
{

    public constructor(init?: Partial<GetTermInfoReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTermInfoReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTermInfoRes(); }
}

// @Route("/class/event/{eventid}", "POST, OPTIONS")
export class EventViewLogReq implements IReturn<CommonReturn>
{
    public eventid?: string;

    public constructor(init?: Partial<EventViewLogReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventViewLogReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommonReturn(); }
}

// @Route("/system/checkverifycode", "POST, OPTIONS")
export class CheckVerifyCodeReq implements IReturn<CheckVerifyCodeRes>
{
    public phonenumber?: string;
    public codetype?: string;
    public code?: string;

    public constructor(init?: Partial<CheckVerifyCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckVerifyCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CheckVerifyCodeRes(); }
}

// @Route("/system/yunpianreport", "POST, OPTIONS")
export class YunpianSmsSendReportReq
{
    public sms_status?: string;

    public constructor(init?: Partial<YunpianSmsSendReportReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'YunpianSmsSendReportReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/system/sendverifycode", "POST, OPTIONS")
export class SendVerifyCodeReq extends CommonRequest implements IReturn<SendVerifyCodeRes>
{
    public phonenumber?: string;
    public codetype?: string;
    public nc?: string;
    public sessionid?: string;
    public sig?: string;
    public token?: string;
    public scene?: string;
    public captchaId?: number;
    public ticket?: string;
    public randstr?: string;
    public checkUnbindKid?: boolean;

    public constructor(init?: Partial<SendVerifyCodeReq>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SendVerifyCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendVerifyCodeRes(); }
}

// @Route("/system/versioninfo", "GET, OPTIONS")
export class GetVersionCodeReq implements IReturn<GetVersionCodeRes>
{
    public appid?: number;
    public os?: string;
    public versionname?: string;
    public versioncode?: string;
    public channelname?: string;

    public constructor(init?: Partial<GetVersionCodeReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetVersionCodeReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetVersionCodeRes(); }
}

// @Route("/school/{SchoolId}/weather", "GET, OPTIONS")
export class SchoolWeatherReq implements IReturn<SchoolWeatherRes>
{
    public schoolId?: string;

    public constructor(init?: Partial<SchoolWeatherReq>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SchoolWeatherReq'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SchoolWeatherRes(); }
}

export class VoteItemTextDTO extends VoteItemBaseDTO
{
    public userInputed?: VoteTextContentDTO[];

    public constructor(init?: Partial<VoteItemTextDTO>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'VoteItemTextDTO'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class VoteItemAttachDTO extends VoteItemBaseDTO
{
    public userAttach?: VoteTextContentDTO[];
    public attachType?: VOTEATTACHTYPE;
    public mustNumbers?: number;

    public constructor(init?: Partial<VoteItemAttachDTO>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'VoteItemAttachDTO'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

/**
* Sign In
*/
// @Route("/auth")
// @Route("/auth/{provider}")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="AuthProvider, e.g. credentials")
    public provider?: string;

    // @DataMember(Order=2)
    public state?: string;

    // @DataMember(Order=3)
    public oauth_token?: string;

    // @DataMember(Order=4)
    public oauth_verifier?: string;

    // @DataMember(Order=5)
    public userName?: string;

    // @DataMember(Order=6)
    public password?: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView?: string;

    // @DataMember(Order=10)
    public nonce?: string;

    // @DataMember(Order=11)
    public uri?: string;

    // @DataMember(Order=12)
    public response?: string;

    // @DataMember(Order=13)
    public qop?: string;

    // @DataMember(Order=14)
    public nc?: string;

    // @DataMember(Order=15)
    public cnonce?: string;

    // @DataMember(Order=17)
    public accessToken?: string;

    // @DataMember(Order=18)
    public accessTokenSecret?: string;

    // @DataMember(Order=19)
    public scope?: string;

    // @DataMember(Order=20)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName?: string;

    // @DataMember(Order=2)
    public permissions?: string[];

    // @DataMember(Order=3)
    public roles?: string[];

    // @DataMember(Order=4)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName?: string;

    // @DataMember(Order=2)
    public permissions?: string[];

    // @DataMember(Order=3)
    public roles?: string[];

    // @DataMember(Order=4)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

